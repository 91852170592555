import React, { useState } from "react";
import "./MainLyricItem.css";
import {
  Box,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Flex,
  Tooltip,
  Button,
  Text,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Badge,
} from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa6";
import { BsCheck2All, BsHeadphones, BsTrash } from "react-icons/bs";
import LyricBeatPlayer from "../Players/LyricBeatPlayer/LyricBeatPlayer";
import LyricRecordingPlayer from "../Players/LyricRecordingPlayer/LyricRecordingPlayer";
import { useAddLyricToRelease } from "../../Context/AddLyricToRelease";
import { useLyricsPanel } from "../../Context/LyricsPanelContext";
import { API_ENDPOINT } from "../../Config/config";
import axios from "axios";
import { TbMicrophone } from "react-icons/tb";
import { VscReferences } from "react-icons/vsc";
import { MdDeleteForever } from "react-icons/md";
import { useCurrentLyric } from "../../Context/CurrentLyricContext";
import { useAddBeatToLyric } from "../../Context/AddBeatToLyricContext";
import { useAddRecordingToLyric } from "../../Context/AddRecordingToLyricContext";
import MobileLyricsPanel from "../DetailsPanel/LyricsPanel/MobileLyricsPanel";

const MobileMainLyricItem = ({
  colorMode,
  lyric,
  fetchLyrics,
  lyricId,
  isMobileView,
  userId,
  userName,
  guestToken,
}) => {
  const [activeTab, setActiveTab] = useState("beat");
  const { setAddLyric } = useAddLyricToRelease();
  const { setLyricsPanel } = useLyricsPanel();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // State to track if save is in progress
  const [pendingStatus, setPendingStatus] = useState("");
  const [status, setStatus] = useState(""); // To track the current status
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { currentLyric, setCurrentLyric } = useCurrentLyric();
  const [isMobilePanelModalOpen, setIsMobilePaneleModalOpen] = useState(false);

  const toBeats = (event) => {
    event.stopPropagation(); // Stop the event from propagating further

    setAddLyric("AddLyricToRelease");
    setLyricsPanel(null);
  };

  const handleInnerClick = (event) => {
    event.stopPropagation();
  };

  const onDeleteClick = (event) => {
    event.stopPropagation(); // Prevent triggering the onClick of parent elements
    onOpen(); // Open the modal
  };

  const handleSaveStatus = async () => {
    setIsSaving(true);
    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/lyrics/${lyricId}/status`,
        { status: pendingStatus }
      );
      if (response.status === 200) {
        console.log("Status updated successfully");
        setStatus(response.data.status); // Update the actual status
        fetchLyrics(); // Refresh the list to reflect the new status
      }
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setIsSaving(false);
      setIsStatusChangeModalOpen(false); // Close the modal after handling the request
    }
  };

  const handleDeleteLyric = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/lyrics/${lyricId}`
      );
      if (response.status === 200) {
        console.log("Lyric deleted successfully");
        fetchLyrics();
      }
    } catch (error) {
      console.error("Error deleting lyric:", error);
    } finally {
      setIsLoading(false);
      onClose(); // Close the modal
    }
  };

  const getBackgroundColor = () => {
    switch (lyric?.status) {
      case "Draft":
        return colorMode === "light"
          ? "rgba(242, 60, 73, 1)" // Light mode color for Draft
          : "rgba(242, 60, 73, 0.84)"; // Dark mode color for Draft
      case "Recorded":
        return colorMode === "light"
          ? "rgba(26, 213, 152, 0.9)" // Light mode color for Recorded
          : "rgba(26, 213, 152, 0.4)"; // Dark mode color for Recorded
      case "Reference":
        return colorMode === "light"
          ? "rgba(33, 126, 253, 1)" // Light mode color for Reference
          : "rgba(0, 144, 255, 0.4)"; // Dark mode color for Reference
      default:
        return colorMode === "light"
          ? "default-light-color" // Default color for light mode
          : "default-dark-color"; // Default color for dark mode
    }
  };

  const getColorColor = () => {
    switch (lyric?.status) {
      case "Draft":
      case "Reference":
        return colorMode === "light" ? "white" : "dark-text-color";
      case "Recorded":
        return colorMode === "light" ? "rgba(5, 15, 30, 1)" : "white";
      default:
        return colorMode === "light"
          ? "default-light-text-color"
          : "default-dark-text-color";
    }
  };

  const backgroundColor = getBackgroundColor();
  const colorColor = getColorColor();

  const handleStatusMenuClick = (newStatus) => {
    setPendingStatus(newStatus);
    setIsStatusChangeModalOpen(true);
  };

  const { setAddBeatToLyric } = useAddBeatToLyric();
  const { setAddRecordingToLyric } = useAddRecordingToLyric();

  const handleaddbeatclick = () => {
    setAddBeatToLyric("AddBeatToLyric");
    setLyricsPanel(null);
  };

  const handleaddRecordingclick = (event) => {
    event.stopPropagation();
    setAddRecordingToLyric("AddRecordingToLyric");
    setLyricsPanel(null);
  };

  const refreshBeatLink = () => {
    setCurrentLyric({ ...currentLyric, beat: null });
  };

  const refreshRecordingLink = () => {
    setCurrentLyric({ ...currentLyric, recording: null });
  };

  const toMobileyricPanel = (event) => {
    event.stopPropagation(); // Stop the event from propagating further
    setCurrentLyric(lyric);

    setLyricsPanel("Lyrics");
    setAddLyric(null);
    setAddBeatToLyric(null);
    setIsMobilePaneleModalOpen(true); // Correctly use the setter function here
  };

  return (
    <Box
      onClick={toMobileyricPanel}
      boxShadow={"md"}
      className={`lyric-conainter ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Box
        className={`lyric-item-top ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Menu
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          {({ isOpen }) => (
            <>
              <Flex alignItems="center" gap={2}>
                <Tooltip
                  placement="top"
                  borderRadius={"7px"}
                  label={"Set Lyric Status"}
                >
                  <MenuButton
                    style={{ background: backgroundColor, color: colorColor }}
                    m={1}
                    w={"100%"}
                    size={"sm"}
                    onClick={handleInnerClick}
                    as={Button}
                    rightIcon={
                      <FaChevronRight
                        style={{
                          transition: "transform 0.2s",
                          transform: isOpen ? "rotate(90deg)" : "none",
                        }}
                      />
                    }
                  >
                    {lyric.status || status || "Set Status"}{" "}
                  </MenuButton>
                </Tooltip>
              </Flex>
              <MenuList
                p={2}
                bg={colorMode === "light" ? "white" : "black"}
                zIndex={"9999"}
              >
                <MenuItem
                  onClick={(event) => {
                    handleInnerClick(event);
                    handleStatusMenuClick("Recorded");
                  }}
                  p={2}
                  sx={{
                    "&:hover": {
                      bg:
                        colorMode === "light"
                          ? "rgba(90, 238, 158, 0.23)"
                          : "rgba(90, 238, 158, 0.23)",
                    },
                  }}
                  bg={colorMode === "light" ? "white" : "black"}
                >
                  <Flex alignItems={"center"} gap={"5px"}>
                    <Icon as={TbMicrophone} />
                    <Text className="menu-text">Recorded</Text>
                  </Flex>
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    handleInnerClick(event);
                    handleStatusMenuClick("Reference");
                  }}
                  p={2}
                  sx={{
                    "&:hover": {
                      bg:
                        colorMode === "light"
                          ? "rgba(33, 126, 253, 1)"
                          : "rgba(0, 144, 255, 0.4)",
                    },
                  }}
                  bg={colorMode === "light" ? "white" : "black"}
                >
                  <Flex alignItems={"center"} gap={"5px"}>
                    <Icon as={VscReferences} />
                    <Text className="menu-text">Reference</Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
      <Box
        className={`lyric-pad ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Text className="lyricText">{lyric.lyricsText}</Text>
      </Box>
      <Box className="lyric-players-tabs">
        <Flex alignItems={"center"} gap={"5px"}>
          <Tooltip placement="top" borderRadius={"7px"} label={"Paired Beat"}>
            <Button
              colorScheme={activeTab === "beat" ? "blue" : "gray"}
              onClick={(event) => {
                handleInnerClick(event);
                setActiveTab("beat");
              }}
              w={"100%"}
              size={"xs"}
            >
              <Flex alignItems={"center"} gap={"3px"}>
                <Icon as={BsHeadphones} />
                <Text>Beat</Text>
              </Flex>
            </Button>
          </Tooltip>

          <Tooltip
            placement="top"
            borderRadius={"7px"}
            label={"Paired Recording"}
          >
            <Button
              colorScheme={activeTab === "recording" ? "blue" : "gray"}
              onClick={(event) => {
                handleInnerClick(event);
                setActiveTab("recording");
              }}
              w={"100%"}
              size={"xs"}
            >
              <Flex alignItems={"center"} gap={"3px"}>
                <Icon as={TbMicrophone} />
                <Text> Recording</Text>
              </Flex>
            </Button>
          </Tooltip>
        </Flex>
      </Box>
      <Box className="lyric-players-section">
        {activeTab === "beat" &&
          (lyric?.beat ? (
            <LyricBeatPlayer
              refreshBeatLink={refreshBeatLink}
              colorMode={colorMode}
              lyricId={lyricId}
              currentLyric={currentLyric}
              beat={lyric.beat}
              isMobileView={isMobileView}
              setCurrentLyric={setCurrentLyric}
            />
          ) : (
            <Button
              onClick={(event) => {
                event.stopPropagation(); // Correctly stop event propagation
                handleaddbeatclick(); // Call the function without passing the event
              }}
              w={"100%"}
            >
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={BsHeadphones} />
                <Text>Add Beat</Text>
              </Flex>
            </Button>
          ))}

        {activeTab === "recording" &&
          (lyric?.recording ? (
            <LyricRecordingPlayer
              lyricId={lyricId}
              recording={lyric.recording}
              colorMode={colorMode}
              isMobileView={isMobileView}
              refreshRecordingLink={refreshRecordingLink}
              currentLyric={currentLyric}
              setCurrentLyric={setCurrentLyric}
            />
          ) : (
            <Button onClick={handleaddRecordingclick} w={"100%"}>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={TbMicrophone} />
                <Text>Add Recording</Text>
              </Flex>
            </Button>
          ))}
      </Box>
      <Box className="lyric-bottom">
        <Flex alignItems={"center"} gap={"8px"}>
          <Tooltip
            placement="bottom"
            borderRadius={"7px"}
            label={"Delete Lyric"}
          >
            <IconButton
              onClick={onDeleteClick}
              colorScheme="red"
              variant={"outline"}
              w={"50%"}
              icon={<BsTrash />}
              size={"xs"}
            />
          </Tooltip>
          {!lyric.release ? (
            <>
              <Tooltip
                placement="top"
                borderRadius={"7px"}
                label={"Add Lyric to Release"}
              >
                <Button
                  onClick={toBeats}
                  colorScheme="green"
                  size={"xs"}
                  w={"100%"}
                >
                  <Flex alignItems={"center"} gap={"5px"}>
                    <Text className="addtoreleasebtn">Add to Release</Text>
                  </Flex>
                </Button>
              </Tooltip>
            </>
          ) : (
            <Box className="badger">
              <Tooltip
                placement="top"
                borderRadius={"7px"}
                label={"Lyric is Released!"}
              >
                <Badge
                  p={1}
                  w={"100%"}
                  colorScheme="green"
                  variant="subtle"
                  className="richmod"
                  borderRadius={"7px"}
                  h={"100%"}
                >
                  <Flex
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"3px"}
                  >
                    <Icon as={BsCheck2All} />
                    <Text fontSize={"9px"}>Released</Text>
                  </Flex>
                </Badge>
              </Tooltip>
            </Box>
          )}
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={MdDeleteForever} />
              <Text>Delete Lyric</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete the lyric {lyric.title}?
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
              <Button w={"100%"} colorScheme="gray" onClick={onClose}>
                Cancel
              </Button>
              <Button w={"100%"} colorScheme="red" onClick={handleDeleteLyric}>
                Delete Lyric
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        bg="black"
        isOpen={isStatusChangeModalOpen}
        onClose={() => setIsStatusChangeModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Change Status</ModalHeader>
          <ModalBody bg={colorMode === "light" ? "white" : "black"}>
            Are you sure you want to change the status to "{pendingStatus}"?
          </ModalBody>
          <ModalFooter>
            <Button
              w={"100%"}
              colorScheme="red"
              mr={3}
              onClick={() => setIsStatusChangeModalOpen(false)}
            >
              Cancel
            </Button>
            <Button w={"100%"} colorScheme="green" onClick={handleSaveStatus}>
              Change Status
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Drawer
        isOpen={isMobilePanelModalOpen}
        placement="bottom"
        onClose={() => setIsMobilePaneleModalOpen(false)}
      >
        <DrawerOverlay />
        <DrawerContent
          borderTopRightRadius={"12px"}
          borderTopLeftRadius={"12px"}
          h={"650px"}
        >
          <DrawerCloseButton ml={5} />
          <MobileLyricsPanel
            currentLyric={currentLyric}
            colorMode={colorMode}
            userId={userId}
            userName={userName}
          />
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MobileMainLyricItem;
