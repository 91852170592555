import React, { useState, useRef } from "react";
import {
  Button,
  Input,
  useToast,
  VStack,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { API_ENDPOINT } from "../../../Config/config";
import axios from "axios";

const RecordingAssetUpload = ({
  selectedTableDataId,
  userName,
  userId,
  colorMode,
}) => {
  const [files, setFiles] = useState([]);
  const [titles, setTitles] = useState([]);
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    const generatedTitles = selectedFiles.map(
      (file) => file.name.replace(/\.[^/.]+$/, "") // Removes file extension
    );
    setTitles(generatedTitles);
  };

  const uploadRecordingAssets = async () => {
    if (files.length === 0 || titles.some((title) => title.trim() === "")) {
      toast({
        title: "Error",
        description: "Please select files and ensure all files have titles.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("files", file);
    });
    formData.append("titles", JSON.stringify(titles));
    formData.append("userId", userId);
    formData.append("name", userName);

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/assets/table/${selectedTableDataId}/recording`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      toast({
        title: "Upload successful",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Reset state
      setFiles([]);
      setTitles([]);
    } catch (error) {
      toast({
        title: "Upload failed",
        description: error.response?.data?.message || "An error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <VStack spacing={4} align="stretch">
      <Input
        ref={fileInputRef}
        type="file"
        accept="audio/*"
        onChange={handleFileChange}
        multiple
        hidden
      />
      <Button onClick={() => fileInputRef.current.click()}>
        Select Recording Assets
      </Button>
      <HStack spacing={4} wrap="wrap">
        {titles.map((title, index) => (
          <Tag
            key={index}
            borderRadius="full"
            variant="solid"
            colorScheme="blue"
          >
            <TagLabel>{title}</TagLabel>
            <TagCloseButton
              onClick={() => {
                setTitles(titles.filter((_, i) => i !== index));
                setFiles(files.filter((_, i) => i !== index));
              }}
            />
          </Tag>
        ))}
      </HStack>
      <Button
        colorScheme="blue"
        isLoading={isUploading}
        onClick={uploadRecordingAssets}
        disabled={isUploading}
      >
        Upload
      </Button>
    </VStack>
  );
};

export default RecordingAssetUpload;
