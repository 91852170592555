import React from "react";
import "./MessageTable.css";
import { Box } from "@chakra-ui/react";
import ChannelMessages from "./ChannelMessages";
import DirectMessages from "./DirectMessages";
import { useDirectMessageDisplay } from "../../../Context/DirectMessageDisplayContext";
import NewDirectMessages from "./NewDirectMessages";

const MessageTable = ({
  colorMode,
  userName,
  userId,
  isMobileView,
  selectedChannel,
  selectedDirectMessage,
  refreshSelectedChannel,
  guestToken,
}) => {
  const { directMessages } = useDirectMessageDisplay();

  return (
    <Box
      className={`MessageTable-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      boxShadow={"md"}
    >
      {!directMessages && (
        <ChannelMessages
          userName={userName}
          userId={userId}
          isMobileView={isMobileView}
          colorMode={colorMode}
          selectedChannel={selectedChannel}
          refreshSelectedChannel={refreshSelectedChannel}
          guestToken={guestToken}
        />
      )}

      {selectedDirectMessage && (
        <>
          <DirectMessages
            userName={userName}
            userId={userId}
            isMobileView={isMobileView}
            colorMode={colorMode}
            selectedDirectMessage={selectedDirectMessage}
          />
        </>
      )}
      {directMessages && !selectedDirectMessage && (
        <>
          <NewDirectMessages
            userName={userName}
            userId={userId}
            isMobileView={isMobileView}
            colorMode={colorMode}
            refreshSelectedChannel={refreshSelectedChannel}
          />
        </>
      )}
    </Box>
  );
};

export default MessageTable;
