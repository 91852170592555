import React from "react";
import { Flex } from "@chakra-ui/react";
import AllLyricsComments from "./AllLyricsComments";
import LyricsCommentSendInput from "./LyricsCommentSendInput";

const CurrentLyricComments = ({
  userId,
  currentLyric,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  lyricId,
  guestToken,
}) => {
  return (
    <Flex className="CurrentBeatComments-main">
      <Flex>
        <AllLyricsComments
          userId={userId}
          currentLyric={currentLyric}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          lyricId={lyricId}
        />
      </Flex>
      <Flex>
        <LyricsCommentSendInput
          userId={userId}
          currentLyric={currentLyric}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          lyricId={lyricId}
          guestToken={guestToken}
        />
      </Flex>
    </Flex>
  );
};

export default CurrentLyricComments;
