import React from "react";
import { Flex, Tag, Avatar, TagLabel } from "@chakra-ui/react";
import "./MutualSpaceLinkedArtworkItem.css";

const MutualSpaceLinkedArtworkItem = ({ artwork, colorMode }) => {
  return (
    <Flex alignItems={"center"} gap={"10px"}>
      <Tag size="sm" colorScheme="teal" borderRadius="full">
        <Avatar
          src={artwork.imageUrl}
          size="xs"
          name="Segun Adebayo"
          ml={-1}
          mr={2}
        />
        <TagLabel className="artworktag">Artwork</TagLabel>
      </Tag>
    </Flex>
  );
};

export default MutualSpaceLinkedArtworkItem;
