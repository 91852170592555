import React, { useState, useEffect, useId } from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Text,
  Tooltip,
  SimpleGrid,
  Spinner,
  Center,
  MenuButton,
  MenuList,
  MenuItem,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  IconButton,
  useToast,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Input,
  Menu,
} from "@chakra-ui/react";
import { BsPlusLg, BsSpotify } from "react-icons/bs";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { CheckCircleIcon, DownloadIcon } from "@chakra-ui/icons";
import "./ReleasesTable.css";
import { BiCalendar, BiTrash } from "react-icons/bi";
import { GrSchedules } from "react-icons/gr";
import AddNewReleaseModal from "./AddNewReleaseModal";
import MainReleaseItem from "./MainReleaseItem";
import { useCurrentRelease } from "../../../Context/CurrentReleaseContext";
import { BsSearch } from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa6";

const ReleasesTable = ({
  isMobileView,
  colorMode,
  userName,
  userId,
  guestToken,
  openDetailsPanelSettings,
  closedetailsPanelSetting,
}) => {
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [releases, setReleases] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { currentRelease, setCurrentRelease } = useCurrentRelease();
  const toast = useToast();

  const fetchReleases = async () => {
    setIsLoading(true);
    try {
      const endpoint = guestToken
        ? `${API_ENDPOINT}api/release/all`
        : `${API_ENDPOINT}api/release/user/${userId}`;

      const response = await axios.get(endpoint);
      let fetchedReleases = response.data.data;

      if (guestToken) {
        fetchedReleases = fetchedReleases.filter(
          (release) => release.mock === "true"
        );
      }

      setReleases(fetchedReleases);

      if (fetchedReleases.length > 0) {
        const enhancedRelease = {
          ...fetchedReleases[0],
        };
        setCurrentRelease(enhancedRelease);
      } else {
        closedetailsPanelSetting();
      }
    } catch (error) {
      toast({
        title: "Error fetching releases",
        description:
          error.response?.data?.message || "Could not fetch releases.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error fetching releases:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReleases();
  }, [toast, setCurrentRelease]);

  const [activeReleaseId, setActiveReleaseId] = useState(null);

  const inputBg = useColorModeValue(
    "rgba(247, 250, 252, 0.43)",
    "rgba(66, 69, 99, 0.23)"
  );
  const taskBg = useColorModeValue("gray.50", "gray.700");

  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");

  return (
    <Flex
      className={`ReleasesTable-main-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        borderBottom={".5px solid grey"}
        alignItems={"center"}
        justifyContent={"space-between"}
        pb={2}
      >
        <Flex
          w={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"20px"}
        >
          <Flex alignItems={"end"} gap={"20px"}>
            <Flex alignItems={"center"} gap={"8px"}>
              <Icon fontSize={"25px"} as={CheckCircleIcon} />
              <Text className="planning-title-text">Releases</Text>
            </Flex>
            <Button variant={"ghost"} size={isMobileView ? "xs" : "sm"}>
              <Flex gap={"5px"} alignItems={"center"}>
                <Icon as={BiCalendar} />
                <Text fontSize={"11px"}>Calendar</Text>
              </Flex>
            </Button>
          </Flex>
          <Flex alignItems={"center"} gap={"20px"}>
            <Button variant={"outline"} size={isMobileView ? "xs" : "sm"}>
              <Flex gap={"5px"} alignItems={"end"}>
                <Icon as={GrSchedules} />
                <Text fontSize={"12px"}>Scheduled</Text>
              </Flex>
            </Button>
            <Button variant={"outline"} size={isMobileView ? "xs" : "sm"}>
              <Flex gap={"5px"} alignItems={"end"}>
                <Icon color={"green.400"} as={BsSpotify} />
                <Text fontSize={"12px"}>Released</Text>
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex alignItems={"center"} gap={"10px"} mt={4}>
        <Tooltip placement="top" borderRadius={"7px"} label={"New Release"}>
          <Button
            size={isMobileView ? "xs" : "sm"}
            onClick={(e) => {
              e.stopPropagation();
              setIsNewModalOpen(true);
            }}
          >
            <Flex gap={"5px"} alignItems={"end"}>
              <Icon as={BsPlusLg} />
              <Text fontSize={"12px"}>New</Text>
            </Flex>
          </Button>
        </Tooltip>

        <InputGroup size="sm" w="100%">
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={BsSearch} color="gray.300" />}
          />
          <Input
            bg={inputBg}
            borderColor={borderColor}
            placeholder="Search..."
            sx={{
              "::placeholder": {
                fontSize: "12px",
              },
            }}
            _hover={{
              bg: hoverBg,
              borderColor: "gray.400",
            }}
            borderRadius={"5px"}
            size={"sm"}
            w={"100%"}
          />
        </InputGroup>
        <Tooltip placement="top" borderRadius={"7px"} label={"Artist Filter"}>
          <Menu
            border={
              colorMode === "light"
                ? "2px solid rgba(153, 178, 198, 0.23)"
                : "2px solid rgba(43, 46, 54, 1)"
            }
            bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          >
            {({ isOpen }) => (
              <>
                <Flex alignItems="center" gap={2}>
                  <MenuButton
                    fontSize={"12px"}
                    size={"sm"}
                    as={Button}
                    rightIcon={
                      <FaChevronRight
                        style={{
                          transition: "transform 0.2s",
                          transform: isOpen ? "rotate(90deg)" : "none",
                          fontSize: "11px",
                        }}
                      />
                    }
                  >
                    {"Filter Artist"}
                  </MenuButton>
                </Flex>
                <MenuList
                  p={1}
                  border={
                    colorMode === "light"
                      ? "2px solid rgba(153, 178, 198, 0.23)"
                      : "2px solid rgba(43, 46, 54, 1)"
                  }
                  bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                >
                  <MenuItem
                    bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                    _hover={{
                      bg:
                        colorMode === "light"
                          ? "rgba(241, 244, 249, 1)"
                          : "rgba(42, 49, 70, 1)",
                    }}
                  >
                    <Text fontSize={"13px"}>Artist</Text>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </Tooltip>
      </Flex>
      <Box mt={4}>
        {isLoading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : releases.length > 0 ? (
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
            {releases.map((release) => (
              <Box key={release._id}>
                <MainReleaseItem
                  releases={releases}
                  fetchReleases={fetchReleases}
                  release={release}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                  openDetailsPanelSettings={openDetailsPanelSettings}
                  isActive={activeReleaseId === release._id}
                  setActiveReleaseId={setActiveReleaseId}
                  userId={userId}
                />
              </Box>
            ))}
          </SimpleGrid>
        ) : (
          <Center>
            <Text>No releases found</Text>
          </Center>
        )}
      </Box>

      <Modal isOpen={isNewModalOpen} onClose={() => setIsNewModalOpen(false)}>
        <ModalOverlay />
        <ModalContent
          sx={{
            width: isMobileView ? "100%" : "80%", // Full width for mobile view, otherwise 80%
            height: isMobileView ? "auto" : "500px", // Full height for mobile view
            maxHeight: isMobileView ? "auto" : "500px", // Full height for mobile view
            maxWidth: isMobileView ? "100%" : "700px", // Full width for mobile view, otherwise max 700px
          }}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Add Release</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddNewReleaseModal
              userId={userId}
              userName={userName}
              setIsNewModalOpen={setIsNewModalOpen}
              fetchReleases={fetchReleases}
              colorMode={colorMode}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ReleasesTable;
