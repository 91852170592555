import { createContext, useState, useContext } from "react";

const ReleasePanelContext = createContext();

export const useReleasePanel = () => useContext(ReleasePanelContext);

export const ReleasePanelProvider = ({ children }) => {
  const [releasePanel, setReleasePanel] = useState(null);

  return (
    <ReleasePanelContext.Provider value={{ releasePanel, setReleasePanel }}>
      {children}
    </ReleasePanelContext.Provider>
  );
};
