import { createContext, useState, useContext } from "react";

const EmptyBeatContext = createContext();

export const useEmptyBeat = () => useContext(EmptyBeatContext);

export const EmptyBeatProvider = ({ children }) => {
  const [beatsEmpty, setIsBeatsEmpty] = useState(true);

  return (
    <EmptyBeatContext.Provider value={{ beatsEmpty, setIsBeatsEmpty }}>
      {children}
    </EmptyBeatContext.Provider>
  );
};
