import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import AssetLyricsItem from "../../LyricItems/AssetLyric/AssetLyricsItem";

const LyricsAssetsTable = ({
  userName,
  userId,
  colorMode,
  selectedTableDataId,
  openDetailsPanelSettings,
  guestToken,
  isMobileView,
}) => {
  const [lyricAssets, setLyricAssets] = useState([]);

  const fetchLyricAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/assets/table/${selectedTableDataId}/lyrics`
      );
      setLyricAssets(response.data.lyricAssets); // Set the fetched beat assets
    } catch (error) {
      console.error("Error fetching beat assets:", error);
    }
  };

  useEffect(() => {
    if (selectedTableDataId) {
      fetchLyricAssets();
    }
  }, [selectedTableDataId]);

  return (
    <Box mt={4}>
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={3}>
        {lyricAssets.length > 0 ? (
          lyricAssets.map((lyricAsset) => (
            <Box key={lyricAsset?._id}>
              <AssetLyricsItem
                userName={userName}
                userId={userId}
                colorMode={colorMode}
                lyricAsset={lyricAsset}
                lyricAssetId={lyricAsset._id}
                selectedTableDataId={selectedTableDataId}
                openDetailsPanelSettings={openDetailsPanelSettings}
                guestToken={guestToken}
                isMobileView={isMobileView}
                fetchLyricAssets={fetchLyricAssets}
              />
            </Box>
          ))
        ) : (
          <Text>No Lyrics found.</Text>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default LyricsAssetsTable;
