import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  useToast,
  Avatar,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import "./AllRecordingComments.css";
import { BiComment } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";

const AllRecordingComments = ({
  userId,
  recordingAsset,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  recordingId,
  fetchRecordingData,
  comments,
}) => {
  const formatESTDate = (dateString) => {
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/New_York",
    }).format(new Date(dateString));
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={1}
      mt={2}
      h={"240px"}
      overflowY={"auto"}
      w={"100%"}
    >
      {comments.length > 0 ? (
        comments.map((comment) => (
          <Box
            className={`comment-item-container ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
            key={comment._id}
            borderRadius="md"
          >
            <Flex>
              <Avatar size={"xs"} name={comment.sender.name} mr={2} />
            </Flex>
            <Flex
              className={`comment-item-surround ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
              alignItems={"center"}
            >
              <Flex flexDirection={"column"}>
                <Text className="sendingtime" color="gray.500">
                  {formatESTDate(comment.dateSent)}
                </Text>
                <Text className="main-comment-text">{comment.text}</Text>
              </Flex>
              <Flex>
                <IconButton colorScheme="red" size={"xs"} icon={<BsTrash />} />
              </Flex>
            </Flex>
          </Box>
        ))
      ) : (
        <Flex
          mt={2}
          p={4}
          color={"rgba(150, 158, 167, 1)"}
          alignItems={"center"}
          justifyContent={"space-between"}
          cursor={"pointer"}
          gap={"10px"}
          borderRadius={"7px"}
        >
          <Flex gap={"20px"} flexDirection={"column"} w={"100%"}>
            <Flex justifyContent={"center"} w={"100%"}>
              <Icon fontSize={"34px"} as={BiComment} />
            </Flex>
            <Text
              textAlign={"center"}
              fontSize={"13px"}
              color={colorMode === "light" ? "rgba(50, 51, 56, 1)" : "white"}
            >
              Post a comment below{" "}
            </Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default AllRecordingComments;
