import React, { useState, useEffect } from "react";
import {
  Box,
  Input,
  Text,
  useToast,
  useColorModeValue,
  Button,
  Flex,
  IconButton,
  Tooltip,
  Avatar,
} from "@chakra-ui/react";
import axios from "axios";
import { BsX } from "react-icons/bs";
import { API_ENDPOINT } from "../../../Config/config";
import "../AssetsTable/GrantAccesstoAssets.css";

const GrantChannelAccess = ({
  colorMode,
  userId,
  userName,
  selectedChannelId,
  selectedChannelName,
  setInviteModalOpen,
  refreshSelectedChannel,
}) => {
  const toast = useToast(); // Initialize useToast hook
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const inputBg = useColorModeValue("gray.50", "gray.700");

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_ENDPOINT}api/users`);
      const usersData = response.data.data;
      const filteredUsers = usersData.filter((user) => user._id !== userId);
      setUsers(filteredUsers);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleEmailSearch = () => {
    const matchedUsers = users.filter(
      (user) => user.email.toLowerCase() === searchTerm.toLowerCase()
    );
    setSearchResults(matchedUsers);
    setHasSearched(true);
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    const matchedUser = users.find(
      (user) => user.name.toLowerCase() === value.toLowerCase()
    );

    if (!matchedUser) {
      setSelectedUser(null);
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    setSelectedUser(null);
    setSearchResults([]);
    setHasSearched(false);
    setInviteModalOpen(false);
  };

  const grantUserAccessToChannel = async (user) => {
    try {
      setLoading(true); // Start loading

      await axios.patch(
        `${API_ENDPOINT}api/channels/${selectedChannelId}/users/access`,
        {
          userId: user._id,
          userName: user.name,
        }
      );

      // Show success toast message
      toast({
        title: "Access Granted",
        description: `${user.name} now has access to ${selectedChannelName}.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setLoading(false); // Stop loading
      handleClear(); // Clear the form or close the modal
      refreshSelectedChannel();
    } catch (error) {
      console.error("Error granting access:", error);

      // Show error toast message
      toast({
        title: "Error granting access",
        description: "Failed to grant access. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false); // Ensure loading is stopped in case of error
    }
  };

  return (
    <Box>
      <Text mb={2} className="enterdetails">
        Enter details to add a user to #{selectedChannelName}.
      </Text>
      {!hasSearched && (
        <>
          <Flex alignItems={"center"} gap={"10px"}>
            <Input
              placeholder="@contributor, or user@email.com"
              bg={inputBg}
              borderColor={borderColor}
              value={searchTerm}
              onChange={handleSearchChange}
              boxShadow={"md"}
              size={"sm"}
              borderRadius={"5px"}
            />
            {searchTerm && (
              <Flex
                alignItems="center"
                height="100%"
                zIndex={"9999"}
                gap={"10px"}
              >
                <Button
                  fontSize={"sm"}
                  size={"sm"}
                  colorScheme="green"
                  onClick={handleEmailSearch}
                >
                  Search
                </Button>
                <IconButton
                  aria-label="Clear Search"
                  icon={<BsX />}
                  colorScheme="red"
                  size={"xs"}
                  onClick={handleClear}
                />
              </Flex>
            )}
          </Flex>
        </>
      )}
      <Box mt={4} className="searched-user-result">
        {hasSearched &&
          (searchResults.length > 0 ? (
            searchResults.map((user) => (
              <Flex
                className={`user-searched-items ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
                key={user._id}
              >
                <Flex alignItems={"center"} gap={"10px"}>
                  <Avatar size={"sm"} name={user.name} />
                  <Text className="usersname">{user.name}</Text>
                </Flex>

                <Flex alignItems={"center"} gap={"10px"}>
                  <Tooltip
                    placement="top"
                    borderRadius={"7px"}
                    label={"Request Access"}
                  >
                    <Button
                      onClick={() => grantUserAccessToChannel(user)} // Attach the function here
                      isLoading={loading}
                      size={"xs"}
                      colorScheme="cyan"
                      variant={"outline"}
                    >
                      Grant User
                    </Button>
                  </Tooltip>
                  <IconButton
                    aria-label="Clear Search"
                    icon={<BsX />}
                    colorScheme="red"
                    size={"xs"}
                    onClick={handleClear}
                  />
                </Flex>
              </Flex>
            ))
          ) : (
            <Text>No user with the email "{searchTerm}" found</Text>
          ))}
      </Box>
    </Box>
  );
};

export default GrantChannelAccess;
