import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Input,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  SimpleGrid,
  Portal,
  Icon,
} from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa6";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { BsPlus } from "react-icons/bs";
import { FaX } from "react-icons/fa6";

const ReleaseStatusItem = ({
  colorMode,
  release,
  releaseId,
  fetchReleases,
}) => {
  const [newStatusInput, setNewStatusInput] = useState("");
  const [customStatuses, setCustomStatuses] = useState([]);
  const [statusColors, setStatusColors] = useState({});
  const toast = useToast();
  const [allCustomStatuses, setAllCustomStatuses] = useState([]);

  const colorOptions = [
    "#E53E3E", // Red.500
    "#3182CE", // Blue.500
    "#38A169", // Green.500
    "#DD6B20", // Orange.500
    "#805AD5", // Purple.500
    colorMode === "light" ? "rgba(251, 170, 74, 1)" : "rgba(252, 187, 107, 1)", // Yellow.500
    "#4FD1C5", // Teal.500
    "#ED64A6", // Pink.500
    "#718096", // Gray.500
    "#F687B3", // Pink.300 - Lighter for contrast in dark mode
  ];

  const handleAddCustomStatus = async () => {
    const trimmedStatus = newStatusInput.trim();
    if (!trimmedStatus) return;

    try {
      await axios.post(
        `${API_ENDPOINT}api/release/${releaseId}/customstatuses`,
        { status: trimmedStatus }
      );
      setNewStatusInput("");

      toast({
        title: "Status Added",
        description: "New custom status has been successfully added.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      fetchReleases();
    } catch (error) {
      console.error("Error adding custom status:", error);
      toast({
        title: "Error",
        description: "Could not add new status. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchAllCustomStatuses = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/release/customstatuses/all`
      );
      if (response.data) {
        setAllCustomStatuses(response.data.data || []);
        const colors = {};
        response.data.data.forEach((status) => {
          colors[status.status] = status.color || "transparent";
        });
        setStatusColors(colors);
      }
    } catch (error) {
      console.error("Error fetching all custom statuses:", error);
      toast({
        title: "Error",
        description: "Failed to load all custom statuses.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchAllCustomStatuses();
  }, []);

  const handleUpdateColor = async (status, color) => {
    try {
      const response = await axios.patch(
        `${API_ENDPOINT}api/release/${releaseId}/customstatuses/${status}`,
        { color }
      );
      if (response.data) {
        toast({
          title: "Color Updated",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
      }
      fetchReleases();
    } catch (error) {
      console.error("Error updating status color:", error);
      toast({
        title: "Error",
        description: "There was an error updating the status color.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateStatus = async (newStatus) => {
    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/release/${releaseId}/status`,
        { newStatus }
      );
      toast({
        title: "Status Updated",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      fetchReleases();
    } catch (error) {
      console.error("Error updating Writing To status:", error);
      toast({
        title: "Error",
        description: "There was an error updating the Writing To status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <Flex w={"100%"} alignItems="center" gap={2}>
            <MenuButton
              w={"100%"}
              fontSize={"12px"}
              size={"sm"}
              as={Button}
              rightIcon={
                <FaChevronRight
                  style={{
                    transition: "transform 0.2s",
                    transform: isOpen ? "rotate(90deg)" : "none",
                  }}
                />
              }
              color={
                colorMode === "light"
                  ? !release?.status
                    ? "rgba(6, 21, 43, 1)"
                    : "white"
                  : !release?.status
                  ? "white"
                  : "white"
              }
              backgroundColor={statusColors[release?.status]}
              _hover={{
                backgroundColor: release?.status?.color,
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {release.status || "Status"}
            </MenuButton>
          </Flex>
          <MenuList
            p={1}
            border={
              colorMode === "light"
                ? "2px solid rgba(153, 178, 198, 0.23)"
                : "2px solid rgba(43, 46, 54, 1)"
            }
          >
            <Flex alignItems={"center"} gap={"5px"} p={1}>
              <Input
                borderRadius={"3px"}
                placeholder="Add Status..."
                size={"xs"}
                width={"100%"}
                onChange={(e) => setNewStatusInput(e.target.value)}
                value={newStatusInput}
                onClick={(e) => e.stopPropagation()}
              />
              <Tooltip
                label="Add Status"
                hasArrow
                placement="top"
                closeOnClick={false}
              >
                <IconButton
                  icon={<BsPlus />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddCustomStatus();
                  }}
                  size={"xs"}
                  colorScheme="green"
                />
              </Tooltip>
            </Flex>
            {allCustomStatuses.map((status, index) => (
              <MenuItem onClick={() => updateStatus(status.status)} key={index}>
                <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
                  <Popover>
                    <PopoverTrigger>
                      <Box
                        as="button" // Ensures the box can be focused and interacted with like a button
                        w="20px"
                        h="20px"
                        border="1px solid" // Optional: adds a border to the square
                        borderColor="gray.300" // Optional: sets the border color
                        backgroundColor={status.color || "transparent"}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent w="auto">
                        <SimpleGrid columns={5} spacing={2} p={2}>
                          {colorOptions.map((color, colorIndex) => (
                            <Box
                              key={colorIndex}
                              w="20px"
                              h="20px"
                              bg={color}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleUpdateColor(status.status, color);
                              }}
                            />
                          ))}
                        </SimpleGrid>
                      </PopoverContent>
                    </Portal>
                  </Popover>

                  <Flex
                    w={"100%"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={"5px"}
                  >
                    <Text className="statusbeat">{status.status}</Text>
                    <Icon
                      // onClick={() => handleDeleteCustomStatus(status.status)} // Ensure proper propagation and data handling
                      color={"red"}
                      as={FaX}
                      cursor="pointer"
                    />
                  </Flex>
                </Flex>
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default ReleaseStatusItem;
