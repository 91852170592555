import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  CircularProgress,
  useToast,
  SimpleGrid,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../../Config/config";
import "./LyricsUploadModal.css";

const LyricsUploadModal = ({
  setIsAddLyricsModalOpen,
  currentRelease,
  colorMode,
}) => {
  const [lyricsData, setLyricsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const [selectedLyric, setSelectedLyric] = useState(null);
  const [addingLyrics, setAddingLyrics] = useState(false);

  useEffect(() => {
    const fetchLyricsData = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT}api/mutualspace/lyrics/all`
        );
        if (response.data.status === "success") {
          setLyricsData(response.data.lyrics);
        } else {
          toast({
            title: "Error fetching lyrics",
            description: response.data.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error fetching lyrics",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchLyricsData();
  }, [toast]);

  const handleSelectLyric = (lyric) => {
    setSelectedLyric(lyric);
  };

  const handleBack = () => {
    setSelectedLyric(null);
  };

  const handleAddLyricsToRelease = async () => {
    setAddingLyrics(true);
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/release/${currentRelease._id}/addLyric`,
        selectedLyric
      );
      if (response.data.status === "success") {
        toast({
          title: "Success",
          description: "Lyric added to release successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setSelectedLyric(null); // Optionally go back to the list
        setIsAddLyricsModalOpen();
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setAddingLyrics(false);
    }
  };

  return (
    <Box h={"100%"} overflowX={"auto"}>
      {loading ? (
        <CircularProgress isIndeterminate color="green.300" />
      ) : selectedLyric ? (
        <Box p={4} borderWidth={1} borderRadius="lg" mb={4}>
          <Text fontSize="xl" fontWeight="bold">
            {selectedLyric.title}
          </Text>
          <Text mt={2}>{selectedLyric.lyricsText}</Text>
          <Text mt={2}>
            <strong>Upload Date:</strong>{" "}
            {new Date(selectedLyric.uploadDate).toLocaleDateString()}
          </Text>
          {/* Displaying linked beats */}
          {selectedLyric.linkedBeats &&
            selectedLyric.linkedBeats.length > 0 && (
              <Box mt={4}>
                <Text fontSize="lg" fontWeight="bold">
                  Linked Beats:
                </Text>
                {selectedLyric.linkedBeats.map((beat) => (
                  <Text key={beat._id} mt={2}>
                    <strong>Title:</strong> {beat.title}
                    <br />
                    <strong>Producer:</strong> {beat.producer}
                  </Text>
                ))}
              </Box>
            )}
          {/* Displaying linked recordings */}
          {selectedLyric.linkedRecordings &&
            selectedLyric.linkedRecordings.length > 0 && (
              <Box mt={4}>
                <Text fontSize="lg" fontWeight="bold">
                  Linked Recordings:
                </Text>
                {selectedLyric.linkedRecordings.map((recording) => (
                  <Text key={recording._id} mt={2}>
                    <strong>Title:</strong> {recording.title}
                  </Text>
                ))}
              </Box>
            )}
          {/* Displaying songwriters */}
          {selectedLyric.songWriters &&
            selectedLyric.songWriters.length > 0 && (
              <Box mt={4}>
                <Text fontSize="lg" fontWeight="bold">
                  Songwriters:
                </Text>
                {selectedLyric.songWriters.map((writer) => (
                  <Text key={writer._id} mt={2}>
                    <strong>Name:</strong> {writer.user.name}
                  </Text>
                ))}
              </Box>
            )}
          <Flex mt={4} justifyContent="space-between">
            <Button onClick={handleBack}>Back</Button>
            <Button
              colorScheme="blue"
              onClick={handleAddLyricsToRelease}
              isLoading={addingLyrics}
            >
              Add Lyrics to Release
            </Button>
          </Flex>
        </Box>
      ) : (
        <Box className="LyricsUploadModal-main-container">
          <SimpleGrid mt={2} columns={{ base: 1, md: 2 }} spacing={4}>
            {lyricsData.map((lyric) => (
              <Box
                key={lyric._id}
                p={4}
                borderWidth={1}
                borderRadius="lg"
                mb={4}
                onClick={() => handleSelectLyric(lyric)}
                cursor="pointer"
              >
                <Text fontSize="xl" fontWeight="bold">
                  {lyric.title}
                </Text>
                <Text mt={2}>
                  {lyric.lyricsText.length > 200
                    ? `${lyric.lyricsText.substring(0, 200)}...`
                    : lyric.lyricsText}
                </Text>
                <Text mt={2}>
                  <strong>Upload Date:</strong>{" "}
                  {new Date(lyric.uploadDate).toLocaleDateString()}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
};

export default LyricsUploadModal;
