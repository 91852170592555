import { Box, Flex, Image } from "@chakra-ui/react";
import "./Hero2.css";
import mainLandingLight from "../../Svggs/mainkainlight.svg";
import mainLandingDark from "../../Svggs/mainmaindarkdark.svg";

const Hero2 = ({ colorMode }) => {
  const gradient = {
    light: "linear(to-b, rgba(255, 255, 255, 0), white 90%)",
    dark: "linear(to-b, rgba(26, 32, 44, 0), rgba(5, 15, 30, 1) 90%)",
  };

  return (
    <Flex
      className={`Hero2-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      flexDirection="column"
      position="relative"
      overflow="hidden"
    >
      <Image
        className="home-img"
        src={colorMode === "light" ? mainLandingLight : mainLandingDark}
        alt="Home image"
        objectFit="cover"
      />
      <Box
        className="gradient-overlay"
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        height="50%" // Larger height for a smoother transition
        bgGradient={colorMode === "light" ? gradient.light : gradient.dark}
        zIndex="2" // Ensures the gradient is above the image
      />
    </Flex>
  );
};

export default Hero2;
