import { createContext, useState, useContext } from "react";

const BeatPanelContext = createContext();

export const useBeatPanel = () => useContext(BeatPanelContext);

export const BeatPanelProvider = ({ children }) => {
  const [beatPanel, setBeatPanel] = useState(null);

  return (
    <BeatPanelContext.Provider value={{ beatPanel, setBeatPanel }}>
      {children}
    </BeatPanelContext.Provider>
  );
};
