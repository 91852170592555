import React from "react";
import "./Storage.css";
import TracksSection from "../../Components/StoragePage/TracksSection";
import { Box } from "@chakra-ui/react";

const Storage = ({ colorMode }) => {
  return (
    <Box
      className={`main-storage-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <TracksSection colorMode={colorMode} />
    </Box>
  );
};

export default Storage;
