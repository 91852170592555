import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import "./ProductsDropdown.css";
import { MdBuild, MdLyrics, MdProductionQuantityLimits } from "react-icons/md";
import { TiStarFullOutline } from "react-icons/ti";
import { BsPersonWorkspace } from "react-icons/bs";

const ProductsDropdown = ({ colorMode, onMouseEnter, onMouseLeave }) => {
  const [iconColors, setIconColors] = useState({});

  useEffect(() => {
    const icons = ["MdBuild", "MdLyrics", "BsPersonWorkspace"];
    const newIconColors = {};
    icons.forEach((icon, index) => {
      const color = `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")}`;
      newIconColors[icon] = color;
    });
    setIconColors(newIconColors);
  }, []);

  return (
    <Flex
      className={`ProductsDropdown-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Flex
        className={`ProductsDropdown-surround ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex w={"100%"} flexDirection={"column"} gap={"10px"}>
          <Flex
            borderBottom={".5px solid rgba(65, 77, 85, 1)"}
            pb={3}
            className="Productstitledspace"
            alignItems={"center"}
            gap={"10px"}
          >
            <Icon fontSize={"27px"} as={TiStarFullOutline} />
            <Text
              className={`ProductsDropdowntitledspacetext ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              Product
            </Text>
          </Flex>
          <Box
            className={`Features-item ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
            _hover={{ cursor: "auto" }}
          >
            <Flex justifyContent={"center"} alignItems={"center"} gap={"10px"}>
              <Icon
                opacity={"50%"}
                p={1}
                className="feature-icon"
                fontSize={"43px"}
                as={BsPersonWorkspace}
                color={iconColors["BsPersonWorkspace"]}
              />
              <Flex
                flexDirection={"column"}
                gap={"2px"}
                justifyContent={"start"}
                alignItems={"start"}
              >
                <Text
                  className={`texthead ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  MutualSpaces
                </Text>
                <Text
                  className={`textsub ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  Manage Teams and Projects in one place
                </Text>
              </Flex>
            </Flex>
          </Box>
          <Box
            className={`Features-item ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
            _hover={{ cursor: "auto" }}
          >
            <Flex justifyContent={"center"} alignItems={"center"} gap={"10px"}>
              <Icon
                opacity={"50%"}
                p={1}
                className="feature-icon"
                fontSize={"43px"}
                as={MdBuild}
                color={iconColors["MdBuild"]}
              />
              <Flex
                flexDirection={"column"}
                gap={"2px"}
                justifyContent={"start"}
                alignItems={"start"}
              >
                <Text
                  className={`texthead ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  Release Builder
                </Text>
                <Text
                  className={`textsub ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  Collect All Assets for a Release
                </Text>
              </Flex>
            </Flex>
          </Box>
          <Box
            className={`Features-item ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
            _hover={{ cursor: "auto" }}
          >
            <Flex justifyContent={"center"} alignItems={"center"} gap={"10px"}>
              <Icon
                opacity={"50%"}
                p={1}
                className="feature-icon"
                fontSize={"43px"}
                as={MdLyrics}
                color={iconColors["MdLyrics"]}
              />
              <Flex
                flexDirection={"column"}
                gap={"2px"}
                justifyContent={"start"}
                alignItems={"start"}
              >
                <Text
                  className={`texthead ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  Lyrics Transcriber
                </Text>
                <Text
                  className={`textsub ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  Lyrics From Uploaded Recordings
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProductsDropdown;
