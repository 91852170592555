import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Avatar,
  MenuList,
  MenuItem,
  MenuButton,
  Menu,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../../../Config/config";

const OwnerItem = ({
  task,
  taskId,
  board,
  boardId,
  userId,
  colorMode,
  mutualSpaceId,
  isSidebarCollapsed,
  fetchTasks,
  key,
  itemId,
  item,
  fetchItems,
  guestToken,
}) => {
  const toast = useToast();
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_ENDPOINT}api/users`);
        const allUsers = response.data.data;
        setUsers(allUsers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    updateOwner(user);
  };

  const updateOwner = async (user) => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/owner`,
        {
          owner: {
            userId: user._id,
            name: user.name,
          },
        }
      );
      toast({
        title: "Owner Updated",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-left",
      });
      fetchItems();
    } catch (error) {
      toast({
        title: "Failed to update owner",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <Menu w={"100%"}>
      <MenuButton bg={"none"} as={Button} size="xs" w="100%">
        {!item?.owner?.name ? (
          <Avatar size={"sm"} />
        ) : (
          <Avatar name={item?.owner?.name} size={"sm"} />
        )}
      </MenuButton>
      <MenuList h={"150px"} overflowX={"auto"}>
        {isLoading ? (
          <Spinner />
        ) : (
          users.map((user, index) => (
            <MenuItem
              isDisabled={!!guestToken}
              key={index}
              onClick={() => handleSelectUser(user)}
            >
              <Flex alignItems="center">
                <Avatar size="xs" name={user.name} src={user.avatar} mr={2} />
                {user.name}
              </Flex>
            </MenuItem>
          ))
        )}
      </MenuList>
    </Menu>
  );
};

export default OwnerItem;
