import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Text,
  Tooltip,
  Center,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  IconButton,
  useToast,
  CircularProgress,
  CircularProgressLabel,
  useColorModeValue,
  Image,
  Tag,
  TagLabel,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import "./ReleaseDateItem.css";
import { CalendarIcon } from "@chakra-ui/icons";

const ReleaseDateItem = ({ colorMode, release, fetchReleases, releaseId }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    const day = date.getDate();
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // special case for 11th-13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const formattedDay = `${day}${daySuffix(day)}`;
    return `${formattedDate.split(" ")[0]} ${formattedDay}, ${
      formattedDate.split(" ")[1]
    }`;
  };

  return (
    <Flex>
      {release.schedule ? (
        <Button
          size={"sm"}
          variant={"outline"}
          className={`ReleaseDateItem-surround ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Flex alignItems={"center"} gap={"7px"}>
            <Icon as={CalendarIcon} />
            <Text>{formatDate(release.schedule)}</Text>
          </Flex>
        </Button>
      ) : (
        <Button
          size={"sm"}
          variant={"outline"}
          className={`ReleaseDateItem-surround ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Flex alignItems={"center"} gap={"7px"}>
            <Icon as={CalendarIcon} />
            <Text>Schedule </Text>
          </Flex>
        </Button>
      )}
    </Flex>
  );
};

export default ReleaseDateItem;
