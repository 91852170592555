import {
  Avatar,
  Box,
  Flex,
  Icon,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import "./MainCommentItem.css";
import { BsHeart, BsTrash } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa6";
import { API_ENDPOINT } from "../../../Config/config";
import axios from "axios";

const MainCommentItem = ({
  comment,
  colorMode,
  userId,
  userName,
  recordingId,
  beatId,
  artworkId,
  lyricId,
  releaseId,
  onDelete,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleDelete = async () => {
    setIsLoading(true);

    let deleteEndpoint = `${API_ENDPOINT}api/`;
    if (recordingId) {
      deleteEndpoint += `recording/${recordingId}/comment/${comment.id}`;
    } else if (beatId) {
      deleteEndpoint += `beat/${beatId}/comment/${comment.id}`;
    } else if (releaseId) {
      deleteEndpoint += `release/${releaseId}/comment/${comment.id}`;
    } else if (artworkId) {
      deleteEndpoint += `artwork/${artworkId}/comment/${comment.id}`;
    } else if (lyricId) {
      deleteEndpoint += `lyric/${lyricId}/comment/${comment.id}`;
    } else {
      console.error("No valid ID found for deletion.");
      setIsLoading(false);
      return;
    }

    try {
      await axios.delete(deleteEndpoint);
      console.log("Comment deleted successfully");
      onDelete(comment.id); // Update the parent component's state
      toast({
        title: "Comment deleted!",
        description: "Your comment has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Unable to delete comment.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error deleting comment", error);
    }

    setIsLoading(false);
    onClose();
  };

  return (
    <Box
      className={`comment-item-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Box>
        <Avatar name={comment?.name} size={"xs"} />
      </Box>
      <Flex
        w={"100%"}
        flexDirection={"column"}
        justifyContent={"start"}
        alignItems={"start"}
        gap={"5px"}
      >
        <Box
          className={`comment-item-surround ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Text className="main-comment-text">{comment?.text}</Text>
        </Box>
        <Box>
          <Flex alignItems={"center"} gap={"10px"}>
            <Icon
              _hover={{ cursor: "pointer", opacity: "80%" }}
              fontSize={"13px"}
              color="red.300"
              as={BsTrash}
              onClick={onOpen}
            />
            <Icon
              _hover={{ cursor: "pointer", opacity: "80%" }}
              fontSize={"13px"}
              size={"xxs"}
              as={BsHeart}
            />
            <Icon
              _hover={{ cursor: "pointer", opacity: "80%" }}
              fontSize={"13px"}
              size={"xxs"}
              as={FaRegCopy}
            />
          </Flex>
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Delete Comment</ModalHeader>
          <ModalBody>Are you sure you want to delete this comment?</ModalBody>
          <ModalFooter>
            <Button w={"100%"} colorScheme="gray" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              w={"100%"}
              colorScheme="red"
              onClick={handleDelete}
              isLoading={isLoading}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MainCommentItem;
