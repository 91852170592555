import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Heading,
  useToast,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuGroup,
  Avatar,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { API_ENDPOINT } from "../../Config/config";
import axios from "axios";
import { useMutualSpace } from "../../Context/MutualSpaceContext";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon, EditIcon } from "@chakra-ui/icons";
import { GrTemplate } from "react-icons/gr";
import { BsPlusLg, BsSearch, BsThreeDots, BsTrash } from "react-icons/bs";
import { FaIcons } from "react-icons/fa";

const MutualSpaceSideBarSection = ({
  isMobileView,
  isSidebarCollapsed,
  colorMode,
  userId,
  userName,
  guestToken,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [mutualSpaces, setMutualSpaces] = useState([]);
  const { selectedMutualSpace, updateSelectedMutualSpace } = useMutualSpace();
  const [newestMutualSpaceName, setNewestMutualSpaceName] = useState("");
  const [isNewBoardModalOpen, setIsNewBoardModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const fetchMutualSpaces = async () => {
    try {
      const endpoint = guestToken
        ? `${API_ENDPOINT}api/mutualspaces/all`
        : `${API_ENDPOINT}api/mutualspaces/access/${userId}`;

      const response = await axios.get(endpoint);
      let spaces = response.data.data;

      if (guestToken) {
        spaces = spaces.filter((space) => space.mock === "true");
      }

      if (spaces.length > 0) {
        spaces.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setMutualSpaces(spaces);
        updateSelectedMutualSpace(spaces[0]); // Set the first mutual space as default
        setNewestMutualSpaceName(spaces[0].mutualSpaceName);
      }
    } catch (error) {
      console.error("Error fetching mutual spaces:", error);
    }
  };

  useEffect(() => {
    fetchMutualSpaces();
  }, [userId, guestToken]);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setSelectedSpaceId(getMutualSpaceIdFromPath());
  }, [location]);

  const getMutualSpaceIdFromPath = () => {
    const pathRegex = /\/board\/(\w+)/;
    const match = location.pathname.match(pathRegex);
    return match ? match[1] : null;
  };

  const [selectedSpaceId, setSelectedSpaceId] = useState(
    getMutualSpaceIdFromPath()
  );

  const navigateToBoard = (mutualSpaceId, boardId) => {
    navigate(`/mutualspace/${mutualSpaceId}/board/${boardId}`);
  };

  const handleSelectMutualSpace = (mutualSpace) => {
    updateSelectedMutualSpace(mutualSpace);
    navigate(`/mutualspace/${mutualSpace._id}`);
  };

  const MutualSpaceIcon = ({ mutualSpaceName }) => {
    const initial = selectedMutualSpace.mutualSpaceName.charAt(0);

    const charToColor = (char) => {
      const alphabetPosition = char.toUpperCase().charCodeAt(0) - 64;
      const hue = (alphabetPosition * 137) % 360;
      const saturation = 50 + (alphabetPosition % 51);
      const lightness = 50 + ((alphabetPosition * 3) % 26);
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    const color = charToColor(initial);

    return (
      <Avatar
        h={isMobileView ? "30px" : "20px"}
        w={isMobileView ? "100%" : "20px"}
        borderRadius={"6px"}
        size={isMobileView ? "md" : "sm"}
        name={initial}
        bg={color}
        fontWeight={"900"}
      />
    );
  };

  const openNewBoardModal = () => {
    if (selectedMutualSpace && selectedMutualSpace._id) {
      setIsNewBoardModalOpen(true);
    } else {
      toast({
        title: "Error",
        description: "You must select a mutual space first.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex mt={isMobileView ? 2 : 2} flexDirection={"column"}>
      <Flex
        mt={isMobileView ? 2 : 2}
        p={isMobileView ? 0 : 1}
        alignItems={isMobileView ? "start" : "center"}
        justifyContent={isMobileView ? "start" : "space-between"}
        cursor={"pointer"}
        gap={isMobileView ? "" : "10px"}
        w={"100%"}
      >
        <Menu
          w={"100%"}
          p={isMobileView ? 0 : 1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          onOpen={handleToggleMenu}
          onClose={handleToggleMenu}
        >
          <MenuButton
            textAlign={"start"}
            w={"100%"}
            _hover={{
              transform: "none",
              bg:
                colorMode === "light"
                  ? "rgba(241, 244, 249, 1)"
                  : "rgba(56, 68, 85, 1)",
            }}
            size={"sm"}
            bg={"none"}
            as={Button}
            rightIcon={
              !isMobileView &&
              (isMenuOpen ? (
                <ChevronUpIcon fontSize={"20px"} />
              ) : (
                <ChevronDownIcon fontSize={"20px"} />
              ))
            }
            className={`dropdowntitles ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
          >
            {isMobileView ? (
              <Box w={"100%"}>
                {newestMutualSpaceName && (
                  <MutualSpaceIcon mutualSpaceName={newestMutualSpaceName} />
                )}
              </Box>
            ) : (
              <>
                <Flex alignItems={isMobileView ? "start" : "center"} gap={2}>
                  {newestMutualSpaceName && (
                    <MutualSpaceIcon mutualSpaceName={newestMutualSpaceName} />
                  )}
                  {!isMobileView && (
                    <>
                      <Text fontSize={"13px"}>
                        {selectedMutualSpace?.mutualSpaceName ||
                          "Mutual Space Name here"}
                      </Text>
                    </>
                  )}
                </Flex>
              </>
            )}
          </MenuButton>
          <MenuList
            bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
            zIndex={9999}
          >
            <MenuGroup
              bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
              fontSize={"12px"}
              title="My MutualSpaces"
            >
              <MenuDivider />
              {mutualSpaces.map((mutualSpace) => (
                <MenuItem
                  fontSize={"13px"}
                  bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                  key={mutualSpace._id}
                  _hover={{
                    bg:
                      colorMode === "light"
                        ? "rgba(241, 244, 249, 1)"
                        : "rgba(42, 49, 70, 1)",
                  }}
                  onClick={() => handleSelectMutualSpace(mutualSpace)}
                >
                  {mutualSpace.mutualSpaceName}
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuList>
        </Menu>
        {!isMobileView && (
          <>
            <Flex>
              <Menu
                w={"100%"}
                p={1}
                border={
                  colorMode === "light"
                    ? "2px solid rgba(153, 178, 198, 0.23)"
                    : "2px solid rgba(43, 46, 54, 1)"
                }
                bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
              >
                <MenuButton
                  textAlign={"start"}
                  w={"100%"}
                  _hover={{
                    transform: "none",
                    bg:
                      colorMode === "light"
                        ? "rgba(241, 244, 249, 1)"
                        : "rgba(56, 68, 85, 1)",
                  }}
                  size={"sm"}
                  bg={"none"}
                  as={IconButton}
                  icon={<BsThreeDots />}
                  className={`dropdowntitles ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                />

                <MenuList
                  bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                  zIndex={9999}
                >
                  <MenuGroup
                    bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                    fontSize={"13px"}
                  >
                    <MenuItem
                      fontSize={"13px"}
                      bg={
                        colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"
                      }
                      _hover={{
                        bg:
                          colorMode === "light"
                            ? "rgba(241, 244, 249, 1)"
                            : "rgba(42, 49, 70, 1)",
                      }}
                      icon={<EditIcon />}
                    >
                      Rename Mutualspace
                    </MenuItem>
                    <MenuItem
                      fontSize={"13px"}
                      bg={
                        colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"
                      }
                      _hover={{
                        bg:
                          colorMode === "light"
                            ? "rgba(241, 244, 249, 1)"
                            : "rgba(42, 49, 70, 1)",
                      }}
                      icon={<FaIcons />}
                      isDisabled
                    >
                      Change Icon
                    </MenuItem>
                  </MenuGroup>
                  <MenuDivider />

                  <MenuGroup
                    bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                  >
                    <MenuItem
                      bg={
                        colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"
                      }
                      _hover={{
                        bg:
                          colorMode === "light"
                            ? "rgba(241, 244, 249, 1)"
                            : "rgba(42, 49, 70, 1)",
                      }}
                      w={"100%"}
                      onClick={(e) => {
                        e.stopPropagation();
                        {
                          !guestToken && setIsDeleteModalOpen(true); // Prevent triggering parent's onClick
                        }
                      }}
                      color={colorMode === "light" ? "red.500" : "red.300"}
                    >
                      <Flex alignItems={"center"} gap={"5px"}>
                        <Icon as={BsTrash} />
                        <Text fontSize={"12px"}>Delete Workspace</Text>
                      </Flex>
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </Flex>
          </>
        )}
      </Flex>
      <Flex flexDirection={"column"} mt={2}>
        <Flex
          w={"100%"}
          p={1}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
        >
          {!isMobileView && (
            <>
              <InputGroup size="sm" w="100%">
                <InputLeftElement
                  pointerEvents="none" // Makes the element non-interactive
                  children={<Icon as={BsSearch} color="gray.300" />} // The search icon
                />
                <Input
                  placeholder="Search..."
                  sx={{
                    "::placeholder": {
                      fontSize: "14px",
                      color: colorMode === "light" ? "grey" : "white",
                    },
                  }}
                  borderRadius={"5px"}
                  size={"sm"}
                  w={"100%"}
                  border={
                    colorMode === "light"
                      ? "1px solid rgba(208, 212, 228, 1)"
                      : "1px solid rgba(75, 79, 104, 1)"
                  }
                />
              </InputGroup>
            </>
          )}
          <Flex alignItems={"center"} justifyContent={"center"}>
            <Tooltip placement="top" borderRadius={"7px"} label={"New Board"}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (!guestToken) {
                    openNewBoardModal();
                  }
                }}
                icon={<BsPlusLg />}
                size={"sm"}
                colorScheme={colorMode === "light" ? "blue" : "blue"}
              />
            </Tooltip>
          </Flex>
        </Flex>
        {selectedMutualSpace ? (
          selectedMutualSpace.MutualBoards?.map((board) => (
            <Box mt={3}>
              <Button
                className={`sidebar-nav-button ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                } ${selectedSpaceId === board._id ? "activeLink" : ""}`}
                key={board._id}
                w={"100%"}
                bg={colorMode === "light" ? "" : ""}
                size={"sm"}
                _hover={{
                  bg:
                    colorMode === "light"
                      ? "rgba(240, 240, 241, 1)"
                      : "rgba(61, 67, 75, 1)",
                  transform: "none",
                }}
                onClick={() =>
                  navigateToBoard(selectedMutualSpace._id, board._id)
                }
              >
                {isMobileView ? (
                  <Box w={"100%"}>
                    <Icon className="navicon" as={GrTemplate} />
                  </Box>
                ) : (
                  <>
                    <Flex w={"100%"} alignItems={"center"} gap={"7px"}>
                      <Icon className="navicon" as={GrTemplate} />
                      <Text className="navtext">{board.boardName}</Text>
                    </Flex>
                  </>
                )}
              </Button>
            </Box>
          ))
        ) : (
          <Text>No mutual space selected</Text>
        )}
      </Flex>{" "}
    </Flex>
  );
};

export default MutualSpaceSideBarSection;
