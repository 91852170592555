import { createContext, useState, useContext } from "react";

const ReleaseBuildingPanelContext = createContext();

export const useReleaseBuildingPanel = () =>
  useContext(ReleaseBuildingPanelContext);

export const ReleaseBuildingPanelProvider = ({ children }) => {
  const [releaseBuildingPanel, setReleaseBuildingPanel] = useState(null);

  return (
    <ReleaseBuildingPanelContext.Provider
      value={{ releaseBuildingPanel, setReleaseBuildingPanel }}
    >
      {children}
    </ReleaseBuildingPanelContext.Provider>
  );
};
