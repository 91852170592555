import React, { useState, useEffect } from "react";
import {
  Box,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Flex,
  Button,
  Icon,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa6";
import { BsClockHistory } from "react-icons/bs";
import { MdOutlineSortByAlpha } from "react-icons/md";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const DocumentsTable = ({
  isMobileView,
  colorMode,
  userId,
  userName,
  isSidebarCollapsed,
  guestToken,
  openDetailsPanelSettings,
}) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_ENDPOINT}api/users`);
        const filteredUsers = response.data.data.filter(
          (user) =>
            Array.isArray(user.usersWithPermissions) &&
            user.usersWithPermissions.some((perm) => perm.userId === userId)
        );
        setUsers(filteredUsers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [userId]);

  return (
    <Box className="main-table-container">
      <Box
        className={`top-of-table ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Box>
          <Flex alignItems={"center"} gap={"10px"}>
            <Menu
              border={
                colorMode === "light"
                  ? "2px solid rgba(153, 178, 198, 0.23)"
                  : "2px solid rgba(43, 46, 54, 1)"
              }
              bg={colorMode === "light" ? "white" : "black"}
            >
              {({ isOpen }) => (
                <>
                  <Flex alignItems="center" gap={2}>
                    <Tooltip
                      placement="bottom"
                      borderRadius={"7px"}
                      label={"Filter"}
                    >
                      <MenuButton
                        size={isMobileView ? "xs" : "sm"}
                        as={Button}
                        rightIcon={
                          <FaChevronRight
                            style={{
                              transition: "transform 0.2s",
                              transform: isOpen ? "rotate(90deg)" : "none",
                            }}
                          />
                        }
                      >
                        Filter
                      </MenuButton>
                    </Tooltip>
                  </Flex>
                  <MenuList
                    p={2}
                    bg={colorMode === "light" ? "white" : "black"}
                    zIndex={"9999"}
                  >
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                    >
                      <Icon as={BsClockHistory} fontSize="17px" />

                      <Text>Newest</Text>
                    </MenuItem>
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                    >
                      <Icon as={MdOutlineSortByAlpha} fontSize="17px" />

                      <Text>A - Z</Text>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentsTable;
