import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Flex,
  IconButton,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tag,
  Avatar,
  TagLabel,
  Tooltip,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import {
  BsPlayCircle,
  BsPauseCircle,
  BsTrash,
  BsHeadphones,
} from "react-icons/bs";
import { MusicPlayerContext } from "../../../../Context/MusicPlayerContext";
import { API_ENDPOINT } from "../../../../Config/config";
import axios from "axios";
import { useCurrentRecording } from "../../../../Context/CurrentRecordingContext";
import { useRecordingsPanel } from "../../../../Context/RecordingsPanelContext";
import { PiNotepadThin } from "react-icons/pi";
import { DownloadIcon } from "@chakra-ui/icons";
import RecordingsDrawer from "./RecordingsDrawer";
import CurrentRecordingStatusItem from "../../../DetailsPanel/RecordingsPanel/CurrentRecordingStatusItem";
import FoldersTagRecordings from "./FoldersTagRecordings";

const MainRecordingsTablePlayer = ({
  recordingAsset,
  colorMode,
  guestToken,
  tableId,
  mutualSpaceId,
  boardId,
  board,
  fetchRecordingData,
  openDetailsPanelSettings,
  setActiveRecordingId,
  isActive,
  userId,
  userName,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const toast = useToast();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleInnerClick = (event) => {
    event.stopPropagation();
  };
  const { setRecordingsPanel } = useRecordingsPanel();

  const { setCurrentRecording, currentRecording } = useCurrentRecording();

  const handleRecordingClick = (event) => {
    event.stopPropagation();
    setRecordingsPanel("Recordings");

    const enhancedRecordingAsset = {
      ...recordingAsset,
      mutualSpaceId,
      boardId,
      tableId,
      fetchRecordingData,
    };
    setCurrentRecording(enhancedRecordingAsset);
    setActiveRecordingId(recordingAsset._id);
    openDetailsPanelSettings();
  };

  const { playTrack, stopTrack, currentTrack, seekTrack } =
    useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [localDuration, setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack &&
    currentTrack.src === recordingAsset.fileUrl &&
    !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(recordingAsset.fileUrl);
    }
  };

  const handleDeleteRecording = async () => {
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/recording/${recordingAsset._id}`
      );
      if (response.status === 200) {
        toast({
          title: "Recording Deleted",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
        setDeleteModalOpen(false);
        fetchRecordingData();
      } else {
        throw new Error("Failed to delete due to server status");
      }
    } catch (error) {
      toast({
        title: "Failed to Delete",
        description: "An error occurred while deleting the recording.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      console.error("Error deleting recording:", error);
    }
  };

  const [statusColors, setStatusColors] = useState({});
  const [customStatuses, setCustomStatuses] = useState([]);

  const fetchCustomStatuses = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/customstatuses`
      );
      if (response.data && response.data.customStatuses) {
        setCustomStatuses(response.data.customStatuses);
        const colors = {};
        response.data.customStatuses.forEach((status) => {
          colors[status.status] = status.color || "transparent";
        });
        setStatusColors(colors);
      } else {
        setCustomStatuses([]);
        setStatusColors({});
      }
    } catch (error) {
      console.error("Error fetching custom statuses:", error);
      toast({
        title: "Error",
        description: "Failed to load custom statuses.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCustomStatuses();
  }, [mutualSpaceId, boardId, tableId, recordingAsset._id]);

  return (
    <Flex
      onClick={onDrawerOpen} // Add this line
      className={`MainBeatsTablePlayer-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      style={{
        backgroundColor: isActive ? "rgba(212, 240, 252, 0.21)" : "",
      }}
    >
      <Flex>
        {isCurrentBeatPlaying ? (
          <>
            <IconButton
              onClick={(event) => {
                handleInnerClick(event);
                togglePlayPause(); // Call the original saveProducerName function
              }}
              icon={<BsPauseCircle className="main-player-button" />}
            />
          </>
        ) : (
          <>
            <IconButton
              onClick={(event) => {
                handleInnerClick(event);
                togglePlayPause(); // Call the original saveProducerName function
              }}
              icon={<BsPlayCircle className="main-player-button" />}
            />
          </>
        )}
      </Flex>
      <Flex>
        <Flex flexDirection={"column"}>
          <Text className="player-name">{recordingAsset.title}</Text>
          <Text className="player-producer">
            {recordingAsset.recordingArtist || "Artist"}
          </Text>
        </Flex>
      </Flex>
      <Flex w={"100%"}>
        <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
          <Text className="slider-time">{formatTime(localCurrentTime)}</Text>
          <Slider
            aria-label="track-progress"
            value={isCurrentBeatPlaying ? localCurrentTime : 0}
            max={isCurrentBeatPlaying ? localDuration : 0}
            onChange={(val) => isCurrentBeatPlaying && seekTrack(val)}
            focusThumbOnChange={false}
            isDisabled={!isCurrentBeatPlaying}
            size={"sm"}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Text className="slider-time" ml={1}>
            {formatTime(
              isCurrentBeatPlaying ? localDuration - localCurrentTime : 0
            )}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems={"center"} gap={"10px"}>
        {recordingAsset?.linkedBeats && (
          <Flex alignItems={"center"} gap={"10px"}>
            <Tag w={"100%"} size="sm" colorScheme="blue" borderRadius="full">
              <Avatar
                bg={"none"}
                color={colorMode === "light" ? "blue.300" : "blue.300"}
                size="xs"
                ml={-1}
                icon={<BsHeadphones fontSize={"13px"} />}
              />
              <TagLabel className="taggedlabel">Beat</TagLabel>
            </Tag>
          </Flex>
        )}
        {recordingAsset?.linkedlyrics?.length > 0 && (
          <Flex alignItems={"center"} gap={"10px"}>
            <Tag w={"100%"} size="sm" colorScheme="yellow" borderRadius="full">
              <Avatar
                bg={"none"}
                color={colorMode === "light" ? "gray" : "yellow"}
                size="xs"
                ml={-1}
                icon={<PiNotepadThin fontSize={"20px"} />}
              />
              <TagLabel className="taggedlabel">Lyrics</TagLabel>
            </Tag>
          </Flex>
        )}
        {recordingAsset?.folders && (
          <>
            <FoldersTagRecordings
              tableId={tableId}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              currentRecording={recordingAsset}
              colorMode={colorMode}
              recordingId={recordingAsset?._id}
              fetchRecordingData={fetchRecordingData}
            />
          </>
        )}
      </Flex>
      <Flex>
        <CurrentRecordingStatusItem
          userId={userId}
          currentRecording={recordingAsset}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          recordingId={recordingAsset?._id}
          fetchRecordingData={fetchRecordingData}
          guestToken={guestToken}
        />
      </Flex>
      <Flex alignItems={"center"} gap={"10px"}>
        <Tooltip placement="top" borderRadius={"5px"} label={"Download"}>
          <IconButton
            icon={<DownloadIcon />}
            size={"xs"}
            isDisabled={!!guestToken}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Tooltip>
        <Tooltip placement="top" borderRadius={"5px"} label={"Delete"}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setDeleteModalOpen(true);
            }}
            icon={<BsTrash />}
            size={"xs"}
            colorScheme="red"
            isDisabled={!!guestToken}
          />
        </Tooltip>
        <Flex>
          <Checkbox isDisabled={!!guestToken} size={"lg"} />
        </Flex>
      </Flex>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Recording</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this Recording?</ModalBody>
          <ModalFooter>
            <Flex w={"100%"} gap={"20px"} alignItems={"center"}>
              <Button
                colorScheme="gray"
                w={"100%"}
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                w={"100%"}
                colorScheme="red"
                onClick={handleDeleteRecording}
              >
                Delete Recording
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        size="sm"
        onClose={onDrawerClose}
      >
        <DrawerOverlay />
        <DrawerContent
          p={1}
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <DrawerCloseButton />
          <DrawerHeader>Details</DrawerHeader>
          <DrawerBody>
            <RecordingsDrawer
              tableId={tableId}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              recordingAsset={recordingAsset}
              colorMode={colorMode}
              recordingId={recordingAsset._id}
              fetchRecordingData={fetchRecordingData}
              userId={userId}
              userName={userName}
              guestToken={guestToken}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default MainRecordingsTablePlayer;
