import React, { useEffect, useState } from "react";
import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  SimpleGrid,
  Text,
  VStack,
  HStack,
  Link,
  useToast,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { BsPlusLg, BsTrash } from "react-icons/bs";
import { API_ENDPOINT } from "../../../../../Config/config";
import LinkedItemPlayer from "../../../../Players/MutualSpacePlayers/LinkedItemPlayer";
import MutualSpaceLinkedArtworkItem from "../../../../ArtworkItems/MutualSpaceArtwork/MutualSpaceLinkedArtworkItem";
import LinkedItemRecording from "../../../../Players/MutualSpacePlayers/LinkedItemRecording";
import LinkedLyrics from "../../../../LyricItems/MutualspaceLyrics/LinkedLyrics";
import BoardLinkedFolderItem from "./BoardLinkedFolderItem";

const LinkedItem = ({
  task,
  taskId,
  board,
  boardId,
  userId,
  colorMode,
  mutualSpaceId,
  isSidebarCollapsed,
  fetchTasks,
  key,
  itemId,
  item,
  fetchItems,
  guestToken,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [beatAssets, setBeatAssets] = useState([]);
  const [artworkAssets, setArtworkAssets] = useState([]);
  const [recordingAssets, setRecordingAssets] = useState([]);
  const [lyricAssets, setLyricAssets] = useState([]);
  const [folderAssets, setFolderAssets] = useState([]);

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedArtworkAsset, setSelectedArtworkAsset] = useState(null);
  const [assetType, setAssetType] = useState(""); // This will track which asset type is selected
  const [selectedRecordingAsset, setSelectedRecordingAsset] = useState(null);
  const [selectedLyricAsset, setSelectedLyricAsset] = useState(null);
  const [selectedFolderAsset, setSelectedFolderAsset] = useState(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteLyricModalOpen, setIsDeleteLyricModalOpen] = useState(false);
  const [isDeleteArtworkModalOpen, setIsDeleteArtworkModalOpen] =
    useState(false);
  const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);

  const [isDeleteRecordingModalOpen, setIsDeleteRecordingModalOpen] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [isBeatHovering, setIsBeatHovering] = useState(null);
  const [isLyricHovering, setIsLyricHovering] = useState(null);
  const [isArtworkHovering, setIsArtworkHovering] = useState(null);
  const [isFolderHovering, setIsFolderHovering] = useState(null);
  const [isRecordingHovering, setIsRecordingHovering] = useState(null);

  const clearSelection = () => {
    setSelectedAsset(null);
    setAssetType("");
  };

  const handleAssetLink = (type) => {
    setAssetType(type);
  };

  const selectAsset = (asset) => {
    setSelectedAsset(asset);
  };

  const fetchBeatAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/beats`
      );
      setBeatAssets(response.data.beats);
    } catch (error) {
      console.error("Failed to fetch beat assets:", error);
    }
  };

  useEffect(() => {
    fetchBeatAssets();
  }, []);

  const fetchRecordingAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/recordings`
      );
      setRecordingAssets(response.data.recordings);
    } catch (error) {
      console.error("Failed to fetch beat assets:", error);
    }
  };

  useEffect(() => {
    fetchRecordingAssets();
  }, []);

  const fetchArtworkAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/artworks`
      );
      setArtworkAssets(response.data.artworks);
    } catch (error) {
      console.error("Failed to fetch beat assets:", error);
    }
  };

  useEffect(() => {
    fetchArtworkAssets();
  }, []);

  const fetchFolderAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/folders`
      );
      setFolderAssets(response.data.folders);
    } catch (error) {
      console.error("Failed to fetch beat assets:", error);
    }
  };

  useEffect(() => {
    fetchFolderAssets();
  }, []);

  const fetchLyricAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/lyrics`
      );
      setLyricAssets(response.data.lyrics);
    } catch (error) {
      console.error("Failed to fetch beat assets:", error);
    }
  };

  useEffect(() => {
    fetchLyricAssets();
  }, []);

  const linkBeatToItem = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/addbeat`,
        { beatAsset: selectedAsset }
      );
      toast({
        title: "Beat Linked",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-left",
      });
      onClose();
      clearSelection();
      fetchItems();
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error linking the beat.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Failed to link beat asset:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const linkFolderToItem = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/addfolder`,
        { folderAsset: selectedFolderAsset }
      );
      toast({
        title: "Folder Linked",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-left",
      });
      onClose();
      clearSelection();
      fetchItems();
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error linking the Folder.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Failed to link Folder asset:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const linkArtworkToItem = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/addartwork`,
        { artworkAsset: selectedArtworkAsset }
      );
      toast({
        title: "Artwork Linked",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-left",
      });
      onClose();
      clearSelection();
      fetchItems();
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error linking the Artwork.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Failed to link Artwork asset:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const linkRecordingToItem = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/addrecording`,
        { recordingAsset: selectedRecordingAsset }
      );
      toast({
        title: "Recording Linked",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-left",
      });
      onClose();
      clearSelection();
      fetchItems();
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error linking the recording.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Failed to link recording asset:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const linkLyricToItem = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/addlyric`,
        { lyricAsset: selectedLyricAsset }
      );
      toast({
        title: "Lyric Linked",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-left",
      });
      onClose();
      clearSelection();
      fetchItems();
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error linking the Lyric.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Failed to link Lyric asset:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeLinkedBeat = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/removebeat`
      );
      toast({
        title: "Beat Removed",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-left",
      });
      fetchItems(); // This should refetch the items to update the UI after deletion
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error removing the beat.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Failed to remove beat asset:", error);
    } finally {
      setIsLoading(false);
      setIsDeleteModalOpen(false); // Close the modal after the operation
    }
  };

  const removeLinkedLyric = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/removelyric`
      );
      toast({
        title: "Lyric Removed",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-left",
      });
      fetchItems(); // This should refetch the items to update the UI after deletion
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error removing the Lyric.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Failed to remove Lyric asset:", error);
    } finally {
      setIsLoading(false);
      setIsDeleteLyricModalOpen(false); // Close the modal after the operation
    }
  };

  const removeLinkedArtwork = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/removeartwork`
      );
      toast({
        title: "Artwork Removed",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-left",
      });
      fetchItems(); // This should refetch the items to update the UI after deletion
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error removing the Artwork.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Failed to remove Artwork asset:", error);
    } finally {
      setIsLoading(false);
      setIsDeleteArtworkModalOpen(false); // Close the modal after the operation
    }
  };

  const removeLinkedFolder = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/removefolder`
      );
      toast({
        title: "Folder Removed",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-left",
      });
      fetchItems(); // This should refetch the items to update the UI after deletion
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error removing the Folder.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Failed to remove Folder asset:", error);
    } finally {
      setIsLoading(false);
      setIsDeleteFolderModalOpen(false); // Close the modal after the operation
    }
  };

  const removeLinkedRecording = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/removerecording`
      );
      toast({
        title: "Recording Removed",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-left",
      });
      fetchItems(); // This should refetch the items to update the UI after deletion
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error removing the Recording.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Failed to remove Recording asset:", error);
    } finally {
      setIsLoading(false);
      setIsDeleteRecordingModalOpen(false); // Close the modal after the operation
    }
  };

  return (
    <Box>
      {item?.linked?.beatName ? (
        <Flex
          onMouseEnter={() => setIsBeatHovering(true)}
          onMouseLeave={() => setIsBeatHovering(false)}
          justifyContent={"start"}
          w={"100%"}
          gap={"3px"}
        >
          <LinkedItemPlayer beat={item.linked} colorMode={colorMode} />
          {isBeatHovering && (
            <>
              <IconButton
                isDisabled={!!guestToken}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteModalOpen(true);
                }}
                icon={<BsTrash />}
                size={"xs"}
                colorScheme="red"
                variant={"outline"}
              />
            </>
          )}
        </Flex>
      ) : item?.linked?.imageUrl ? (
        <Flex
          onMouseEnter={() => setIsArtworkHovering(true)}
          onMouseLeave={() => setIsArtworkHovering(false)}
          justifyContent={"start"}
          w={"100%"}
          gap={"3px"}
        >
          <MutualSpaceLinkedArtworkItem
            artwork={item.linked}
            colorMode={colorMode}
          />
          {isArtworkHovering && (
            <>
              <IconButton
                isDisabled={!!guestToken}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteArtworkModalOpen(true);
                }}
                icon={<BsTrash />}
                size={"xs"}
                colorScheme="red"
                variant={"outline"}
              />
            </>
          )}
        </Flex>
      ) : item?.linked?.recordingName ? (
        <Flex
          onMouseEnter={() => setIsRecordingHovering(true)}
          onMouseLeave={() => setIsRecordingHovering(false)}
          justifyContent={"start"}
          w={"100%"}
          gap={"3px"}
        >
          <LinkedItemRecording recording={item.linked} colorMode={colorMode} />
          {isRecordingHovering && (
            <>
              <IconButton
                isDisabled={!!guestToken}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteRecordingModalOpen(true);
                }}
                icon={<BsTrash />}
                size={"xs"}
                colorScheme="red"
                variant={"outline"}
              />
            </>
          )}
        </Flex>
      ) : item?.linked?.lyricsText ? (
        <Flex
          onMouseEnter={() => setIsLyricHovering(true)}
          onMouseLeave={() => setIsLyricHovering(false)}
          justifyContent={"start"}
          w={"100%"}
          gap={"3px"}
        >
          <LinkedLyrics lyric={item.linked} colorMode={colorMode} />
          {isLyricHovering && (
            <>
              <IconButton
                isDisabled={!!guestToken}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteLyricModalOpen(true);
                }}
                icon={<BsTrash />}
                size={"xs"}
                colorScheme="red"
                variant={"outline"}
              />
            </>
          )}
        </Flex>
      ) : item?.linked?.folderName ? (
        <Flex
          onMouseEnter={() => setIsFolderHovering(true)}
          onMouseLeave={() => setIsFolderHovering(false)}
          justifyContent={"start"}
          w={"100%"}
          gap={"3px"}
        >
          <BoardLinkedFolderItem folder={item.linked} colorMode={colorMode} />
          {isFolderHovering && (
            <>
              <IconButton
                isDisabled={!!guestToken}
                size={"xs"}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteFolderModalOpen(true);
                }}
                icon={<BsTrash />}
                colorScheme="red"
                variant={"outline"}
              />
            </>
          )}
        </Flex>
      ) : (
        <IconButton
          onClick={() => {
            onOpen();
          }}
          borderRadius={"100%"}
          icon={<BsPlusLg />}
          size={"xs"}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Link Assets</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {assetType === "" ? (
              <>
                <SimpleGrid columns={{ base: 2, md: 2 }} spacing={3} p={0}>
                  <Button
                    isDisabled={!!guestToken}
                    onClick={() => handleAssetLink("Beat")}
                  >
                    Link Beat
                  </Button>
                  <Button
                    isDisabled={!!guestToken}
                    onClick={() => handleAssetLink("Artwork")}
                  >
                    Link Artwork
                  </Button>
                  <Button
                    isDisabled={!!guestToken}
                    onClick={() => handleAssetLink("Recording")}
                  >
                    Link Recording
                  </Button>
                  <Button
                    isDisabled={!!guestToken}
                    onClick={() => handleAssetLink("Lyrics")}
                  >
                    Link Lyrics
                  </Button>
                  <Button
                    isDisabled={!!guestToken}
                    onClick={() => handleAssetLink("Lyrics")}
                  >
                    Link Document
                  </Button>
                  <Button
                    isDisabled={!!guestToken}
                    onClick={() => handleAssetLink("Folders")}
                  >
                    Link Folder
                  </Button>
                </SimpleGrid>
              </>
            ) : assetType === "Beat" ? (
              !selectedAsset ? (
                <SimpleGrid columns={3} spacing={4}>
                  {beatAssets.map((asset) => (
                    <Box
                      key={asset._id}
                      p={5}
                      shadow="md"
                      borderWidth="1px"
                      cursor="pointer"
                      onClick={() => selectAsset(asset)}
                    >
                      <Text mt={4}>{asset.title}</Text>
                      <Text fontSize="sm">Uploaded by: {asset.user.name}</Text>
                      <Text fontSize="sm">Status: {asset.status}</Text>
                    </Box>
                  ))}
                </SimpleGrid>
              ) : (
                <VStack spacing={4} align="stretch">
                  <Text fontSize="xl" fontWeight="bold">
                    {selectedAsset.title}
                  </Text>
                  <Text>Uploaded by: {selectedAsset.user.name}</Text>
                  <Text>Status: {selectedAsset.status}</Text>
                  <Text>Table ID: {selectedAsset.tableId}</Text>
                  <HStack>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={linkBeatToItem}
                      isLoading={isLoading}
                      loadingText="Linking..."
                    >
                      Link Beat
                    </Button>
                    <Button size="sm" onClick={clearSelection}>
                      Back to List
                    </Button>
                  </HStack>
                </VStack>
              )
            ) : assetType === "Artwork" ? (
              !selectedArtworkAsset ? (
                <SimpleGrid columns={3} spacing={4}>
                  {artworkAssets.map((asset) => (
                    <Box
                      key={asset._id}
                      p={5}
                      shadow="md"
                      borderWidth="1px"
                      cursor="pointer"
                      onClick={() => setSelectedArtworkAsset(asset)}
                    >
                      <Text mt={4}>{asset.title}</Text>
                      <Text fontSize="sm">Uploaded by: {asset.user.name}</Text>
                      <Text fontSize="sm">Status: {asset.status}</Text>
                    </Box>
                  ))}
                </SimpleGrid>
              ) : (
                <VStack spacing={4} align="stretch">
                  <Text fontSize="xl" fontWeight="bold">
                    {selectedArtworkAsset.title}
                  </Text>
                  <Text>Uploaded by: {selectedArtworkAsset.user.name}</Text>
                  <Text>Status: {selectedArtworkAsset.status}</Text>
                  <Text>Table ID: {selectedArtworkAsset.tableId}</Text>
                  <HStack>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={linkArtworkToItem}
                      isLoading={isLoading}
                      loadingText="Linking..."
                    >
                      Link Artwork
                    </Button>
                    <Button size="sm" onClick={clearSelection}>
                      Back to List
                    </Button>
                  </HStack>
                </VStack>
              )
            ) : assetType === "Recording" ? (
              !selectedRecordingAsset ? (
                <SimpleGrid columns={3} spacing={4}>
                  {recordingAssets.map((asset) => (
                    <Box
                      key={asset._id}
                      p={5}
                      shadow="md"
                      borderWidth="1px"
                      cursor="pointer"
                      onClick={() => setSelectedRecordingAsset(asset)}
                    >
                      <Text mt={4}>{asset.title}</Text>
                      <Text fontSize="sm">Uploaded by: {asset.user.name}</Text>
                      <Text fontSize="sm">Status: {asset.status}</Text>
                    </Box>
                  ))}
                </SimpleGrid>
              ) : (
                <VStack spacing={4} align="stretch">
                  <Text fontSize="xl" fontWeight="bold">
                    {selectedRecordingAsset.title}
                  </Text>
                  <Text>Uploaded by: {selectedRecordingAsset.user.name}</Text>
                  <Text>Status: {selectedRecordingAsset.status}</Text>
                  <Text>Table ID: {selectedRecordingAsset.tableId}</Text>
                  <HStack>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={linkRecordingToItem}
                      isLoading={isLoading}
                      loadingText="Linking..."
                    >
                      Link Recording
                    </Button>
                    <Button size="sm" onClick={clearSelection}>
                      Back to List
                    </Button>
                  </HStack>
                </VStack>
              )
            ) : assetType === "Lyrics" ? (
              !selectedLyricAsset ? (
                <SimpleGrid columns={3} spacing={4}>
                  {lyricAssets.map((asset) => (
                    <Box
                      key={asset._id}
                      p={5}
                      shadow="md"
                      borderWidth="1px"
                      cursor="pointer"
                      onClick={() => setSelectedLyricAsset(asset)}
                    >
                      <Text mt={4}>{asset.title}</Text>
                      <Text fontSize="sm">Uploaded by: {asset.user.name}</Text>
                      <Text fontSize="sm">Status: {asset.status}</Text>
                    </Box>
                  ))}
                </SimpleGrid>
              ) : (
                <VStack spacing={4} align="stretch">
                  <Text fontSize="xl" fontWeight="bold">
                    {selectedLyricAsset.title}
                  </Text>
                  <Text>Uploaded by: {selectedLyricAsset.user.name}</Text>
                  <Text>Status: {selectedLyricAsset.status}</Text>
                  <Text>Table ID: {selectedLyricAsset.tableId}</Text>
                  <HStack>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={linkLyricToItem}
                      isLoading={isLoading}
                      loadingText="Linking..."
                    >
                      Link Lyric
                    </Button>
                    <Button size="sm" onClick={clearSelection}>
                      Back to List
                    </Button>
                  </HStack>
                </VStack>
              )
            ) : assetType === "Folders" ? (
              !selectedFolderAsset ? (
                <SimpleGrid columns={3} spacing={4}>
                  {folderAssets.map((asset) => (
                    <Box
                      key={asset._id}
                      p={5}
                      shadow="md"
                      borderWidth="1px"
                      cursor="pointer"
                      onClick={() => setSelectedFolderAsset(asset)}
                    >
                      <Text mt={4}>{asset.title}</Text>
                      <Text fontSize="sm">Uploaded by: {asset.user.name}</Text>
                      <Text fontSize="sm">Status: {asset.status}</Text>
                    </Box>
                  ))}
                </SimpleGrid>
              ) : (
                <VStack spacing={4} align="stretch">
                  <Text fontSize="xl" fontWeight="bold">
                    {selectedFolderAsset.title}
                  </Text>
                  <Text>Uploaded by: {selectedFolderAsset.user.name}</Text>
                  <Text>Status: {selectedFolderAsset.status}</Text>
                  <Text>Table ID: {selectedFolderAsset.tableId}</Text>
                  <HStack>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={linkFolderToItem}
                      isLoading={isLoading}
                      loadingText="Linking..."
                    >
                      Link Folder
                    </Button>
                    <Button size="sm" onClick={clearSelection}>
                      Back to List
                    </Button>
                  </HStack>
                </VStack>
              )
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Remove Linked Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove this linked Beat?
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
              <Button
                variant={"outline"}
                w={"100%"}
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                w={"100%"}
                colorScheme="red"
                onClick={removeLinkedBeat}
                isLoading={isLoading}
              >
                Delete
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteLyricModalOpen}
        onClose={() => setIsDeleteLyricModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Remove Linked Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove this linked Lyric?
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
              <Button
                variant={"outline"}
                w={"100%"}
                onClick={() => setIsDeleteLyricModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                w={"100%"}
                colorScheme="red"
                onClick={removeLinkedLyric}
                isLoading={isLoading}
              >
                Delete
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteArtworkModalOpen}
        onClose={() => setIsDeleteArtworkModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Remove Linked Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove this linked Artwork?
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
              <Button
                variant={"outline"}
                w={"100%"}
                onClick={() => setIsDeleteArtworkModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                w={"100%"}
                colorScheme="red"
                onClick={removeLinkedArtwork}
                isLoading={isLoading}
              >
                Delete
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteFolderModalOpen}
        onClose={() => setIsDeleteFolderModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Remove Linked Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove this linked Folder?
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
              <Button
                variant={"outline"}
                w={"100%"}
                onClick={() => setIsDeleteFolderModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                w={"100%"}
                colorScheme="red"
                onClick={removeLinkedFolder}
                isLoading={isLoading}
              >
                Delete
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteRecordingModalOpen}
        onClose={() => setIsDeleteRecordingModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Remove Linked Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove this linked Recording?
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
              <Button
                variant={"outline"}
                w={"100%"}
                onClick={() => setIsDeleteRecordingModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                w={"100%"}
                colorScheme="red"
                onClick={removeLinkedRecording}
                isLoading={isLoading}
              >
                Delete
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LinkedItem;
