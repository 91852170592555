import React, { useEffect, useState } from "react";
import { Flex, Input, useColorModeValue, useToast } from "@chakra-ui/react";

import "./ArtworkNameInput.css";

const ArtworkNameInput = ({
  userId,
  currentArtwork,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  artworkId,
  fetchArtworkAssets,
  guestToken,
}) => {
  const toast = useToast();
  const inputBg = useColorModeValue("gray.50", "rgba(66, 69, 99, 0.23)");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const [artworkName, setArtworkName] = useState(currentArtwork?.title || "");

  return (
    <Flex w={"100%"}>
      <Input
        isDisabled={!!guestToken}
        value={artworkName}
        onChange={(e) => setArtworkName(e.target.value)}
        // onBlur={() => updateRecordingName()} // Calls with current state value
        borderRadius={"6px"}
        bg={inputBg}
        borderColor={borderColor}
        _hover={{
          bg: hoverBg,
          borderColor: "gray.400",
        }}
        size={"sm"}
      />
    </Flex>
  );
};

export default ArtworkNameInput;
