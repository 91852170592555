import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  Flex,
  Input,
  Icon,
  Tooltip,
  MenuList,
  MenuItem,
  MenuButton,
  Menu,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import "./PlanningTaskItem.css";
import { BsThreeDots, BsTrash } from "react-icons/bs";
import ItemType from "./ItemType";
import ItemStatus from "./ItemStatus";
import ItemPriority from "./ItemPriority";
import ItemLabel from "./ItemLabel";

const PlanningTaskItem = ({
  tableId,
  table,
  colorMode,
  fetchTables,
  userId,
}) => {
  const [items, setItems] = useState([]);
  const scrollTableRef = useRef(null);
  const fixedColRef = useRef(null);
  const newItemRef = useRef("");
  const [isHovering, setIsHovering] = useState(null);
  const [isTypeHovering, setIsTypeHovering] = useState(null);
  const [isStatusHovering, setIsStatusHovering] = useState(null);
  const [isPriorityHovering, setIsPriorityHovering] = useState(null);
  const [isLabelHovering, setIsLabelHovering] = useState(null);
  const toast = useToast();
  const [isloading, setLoading] = useState(true);
  const [isTableOpen, setIsTableOpen] = useState(true);
  const toggleTablesOpen = () => setIsTableOpen(!isTableOpen);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editableTableName, setEditableTableName] = useState(table?.tablename);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState(null);
  const [users, setUsers] = useState([]);
  const [editableItemNames, setEditableItemNames] = useState({});
  const [customStatuses, setCustomStatuses] = useState([]);
  const [newStatusInput, setNewStatusInput] = useState("");
  const [customPriorities, setCustomPriorities] = useState([]);
  const [newPriorityInput, setNewPriorityInput] = useState("");
  const [customLabels, setCustomLabels] = useState([]);
  const [newLabelInput, setNewLabelInput] = useState("");

  useEffect(() => {
    const names = {};
    items.forEach((item) => {
      names[item?._id] = item?.name;
    });
    setEditableItemNames(names);
  }, [items]);

  const showDeleteConfirmation = (index) => {
    setDeleteItemIndex(index);
    setIsConfirmDeleteModalOpen(true);
  };
  const onScroll = () => {
    if (fixedColRef.current) {
      fixedColRef.current.scrollTop = scrollTableRef.current?.scrollTop;
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_ENDPOINT}api/users`);
        const allUsers = response.data.data;
        setUsers(allUsers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [userId]);

  const deleteTable = async () => {
    try {
      await axios.delete(`${API_ENDPOINT}api/tasktable/${tableId}`);
      toast({
        title: "Table Deleted",
        description: "The table has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteModalOpen(false);
      fetchTables();
    } catch (error) {
      console.error("Error deleting task table:", error);
      toast({
        title: "Error Deleting Table",
        description: "There was an error deleting the table. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddNewItem = async () => {
    const newItemName = newItemRef.current.value.trim();
    if (newItemName === "") {
      toast({
        title: "Error",
        description: "The item name cannot be empty.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const newItem = { name: newItemName };

    try {
      await axios.post(`${API_ENDPOINT}api/tasktable/${tableId}/additem`, {
        itemsToAdd: [newItem],
      });
      toast({
        title: "Item Added",
        description: "The new item was successfully added.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      newItemRef.current.value = "";
      fetchTables();
    } catch (error) {
      console.error("Error adding new item:", error);
      toast({
        title: "Error",
        description: "There was an error adding the item. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchItems = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/tasktable/${tableId}/items`
      );
      if (response.data && response.data.items) {
        setItems(response.data.items);
      }
    } catch (error) {
      console.error("Error fetching items:", error);
      toast({
        title: "Error",
        description: "Unable to fetch items.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchItems();
  }, [table._id]);

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "In Progress":
        return colorMode === "light"
          ? "rgba(254, 243, 39, 0.76)"
          : "rgba(249, 185, 89, 0.44)";
      case "Done":
        return colorMode === "light"
          ? "rgba(181, 246, 211, 1)"
          : "rgba(67, 135, 112, 1)";
      default:
        return "transparent";
    }
  };

  const getPriorityBackgroundColor = (type) => {
    switch (type) {
      case "High":
        return colorMode === "light" ? "#FC8181" : "#C53030";
      case "Medium":
        return colorMode === "light" ? "#FBD38D" : "#DD6B20";
      case "Low":
        return colorMode === "light"
          ? "rgba(0, 144, 255, 0.2)"
          : "rgba(0, 144, 255, 0.2)";
      case "Post":
        return colorMode === "light"
          ? "rgba(238, 180, 128, 1)"
          : "rgba(238, 180, 128, 1)";
      default:
        return "transparent";
    }
  };

  const getLabelBackgroundColor = (type) => {
    switch (type) {
      case "Distribution":
        return colorMode === "light"
          ? "rgba(254, 243, 39, 0.76)"
          : "rgba(245, 218, 78, 1)";
      case "Publishing":
        return colorMode === "light"
          ? "rgba(181, 246, 211, 1)"
          : "rgba(67, 135, 112, 1)";
      case "Social Media":
        return colorMode === "light"
          ? "rgba(181, 246, 211, 1)"
          : "rgba(67, 135, 112, 1)";
      case "Licensing":
        return colorMode === "light"
          ? "rgba(251, 170, 74, 1)"
          : "rgba(251, 170, 74, 1)";
      default:
        return "transparent";
    }
  };

  const updateItemStatus = async (itemIndex, newStatus) => {
    const item = items[itemIndex];
    if (!item || !item._id) {
      toast({
        title: "Error",
        description: "Item ID not found.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.put(
        `${API_ENDPOINT}api/tasktable/${tableId}/item/${item._id}/status`,
        { newStatus }
      );

      toast({
        title: "Status Updated",
        description: "The item's status was successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      const updatedItems = items.map((itm, idx) =>
        idx === itemIndex ? { ...itm, status: newStatus } : itm
      );
      setItems(updatedItems);
    } catch (error) {
      console.error("Error updating item status:", error);
      toast({
        title: "Error",
        description: "Failed to update the item's status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateItemPriority = async (itemIndex, newPriority) => {
    const item = items[itemIndex];
    if (!item || !item._id) {
      toast({
        title: "Error",
        description: "Item ID not found.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      await axios.put(
        `${API_ENDPOINT}api/tasktable/${tableId}/item/${item._id}/priority`,
        { newPriority }
      );
      toast({
        title: "Type Updated",
        description: "The item's type was successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      const updatedItems = items.map((itm, idx) =>
        idx === itemIndex ? { ...itm, priority: newPriority } : itm
      );
      setItems(updatedItems);
    } catch (error) {
      console.error("Error updating item type:", error);
      toast({
        title: "Error",
        description: "Failed to update the item's type.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateItemLabel = async (itemIndex, newLabel) => {
    const item = items[itemIndex];
    if (!item || !item._id) {
      toast({
        title: "Error",
        description: "Item ID not found.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      await axios.put(
        `${API_ENDPOINT}api/tasktable/${tableId}/item/${item._id}/label`,
        { newLabel }
      );
      toast({
        title: "Type Updated",
        description: "The item's type was successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      const updatedItems = items.map((itm, idx) =>
        idx === itemIndex ? { ...itm, label: newLabel } : itm
      );
      setItems(updatedItems);
    } catch (error) {
      console.error("Error updating item type:", error);
      toast({
        title: "Error",
        description: "Failed to update the item's type.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteItem = async (itemIndex) => {
    const itemToDelete = items[itemIndex];

    if (itemToDelete && itemToDelete._id) {
      try {
        await axios.delete(
          `${API_ENDPOINT}api/tasktable/${tableId}/item/${itemToDelete._id}`
        );
        toast({
          title: "Item Deleted",
          description: "The item was successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setItems((prevItems) =>
          prevItems.filter((_, index) => index !== itemIndex)
        );
      } catch (error) {
        console.error("Error deleting item:", error);
        toast({
          title: "Error",
          description: "There was an error deleting the item.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      console.error("Error: Item or item ID not found.");
      toast({
        title: "Error",
        description: "Item or item ID not found.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddStatus = () => {
    if (
      newStatusInput.trim() &&
      !customStatuses.includes(newStatusInput.trim())
    ) {
      setCustomStatuses((prev) => [...prev, newStatusInput.trim()]);
      setNewStatusInput("");
    }
  };

  const handleAddPriority = () => {
    if (
      newPriorityInput.trim() &&
      !customPriorities.includes(newPriorityInput.trim())
    ) {
      setCustomPriorities((prev) => [...prev, newPriorityInput.trim()]);
      setNewPriorityInput("");
    }
  };

  const handleAddLabel = () => {
    if (newLabelInput.trim() && !customLabels.includes(newLabelInput.trim())) {
      setCustomLabels((prev) => [...prev, newLabelInput.trim()]);
      setNewLabelInput("");
    }
  };

  return (
    <Box
      className={`planning-task-table-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        mt={2}
        p={2}
        _hover={{ opacity: "80%", bg: "rgba(150, 158, 167, 0.12)" }}
        fontSize={"14px"}
        color={"rgba(150, 158, 167, 1)"}
        alignItems={"center"}
        justifyContent={"space-between"}
        cursor={"pointer"}
        onClick={toggleTablesOpen}
        borderRadius={"7px"}
      >
        <Flex alignItems={"center"} gap={"0px"}>
          <Icon
            mt={1}
            fontSize={"25px"}
            color={
              colorMode === "light"
                ? "rgba(91, 157, 249, 1)"
                : "rgba(91, 157, 249, 1)"
            }
            as={isTableOpen ? FiChevronDown : FiChevronRight}
          />{" "}
          <Input
            onClick={(e) => e.stopPropagation()}
            value={editableTableName}
            onChange={(e) => setEditableTableName(e.target.value)}
            borderRadius={"6px"}
            className={`inputsyles ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
            border={"none"}
            size={"sm"}
          />
        </Flex>
        <Flex alignItems={"center"} gap={"10px"}>
          <Flex>
            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Delete Table"}
            >
              <Button
                size={"sm"}
                colorScheme="red"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteModalOpen(true); // Prevent triggering parent's onClick
                }}
              >
                Delete
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        {isTableOpen && (
          <Box
            className={`planningtask-table-surround ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
          >
            <Box
              ref={fixedColRef}
              className={`planningtaskfixed-col ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              <Table variant="simple" size="sm">
                <Thead
                  className={`planningleftsideborderstyle ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  <Tr>
                    <Th
                      className={`planntablethreadone ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Item
                    </Th>
                  </Tr>
                </Thead>

                <Tbody
                  className={`leftsideborderstyle ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  {items.map((item, index) => (
                    <>
                      <Tr
                        onMouseEnter={() => setIsHovering(index)}
                        onMouseLeave={() => setIsHovering(null)}
                        key={index}
                        className={`${isHovering === index ? "hovered" : ""} ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                      >
                        <Td
                          className={`correspondingcolstsus ${
                            colorMode === "light" ? "lightmode" : "darkmode"
                          }`}
                        >
                          <Flex alignItems={"center"} gap={"10px"}>
                            {isHovering === index && (
                              <Tooltip
                                label="Delete Item"
                                hasArrow
                                placement="top"
                                closeOnClick={false}
                              >
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    showDeleteConfirmation(index); // Prevent triggering parent's onClick
                                  }}
                                  colorScheme="red"
                                  icon={<BsTrash />}
                                  size={"xs"}
                                />
                              </Tooltip>
                            )}

                            <Input
                              borderRadius={"7px"}
                              value={item.name}
                              // onBlur={() => handleItemNameChange(item?._id)}
                              size={"sm"}
                              border={"none"}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    </>
                  ))}
                  <Tr>
                    <Td>
                      <Input ref={newItemRef} placeholder="Add new" size="xs" />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
            <Box
              ref={scrollTableRef}
              onScroll={onScroll}
              className="planningscroll-table"
            >
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th
                      className={`planning-table-title ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                      onMouseEnter={() => setIsTypeHovering(true)}
                      onMouseLeave={() => setIsTypeHovering(false)}
                    >
                      <Flex
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"5px"}
                      >
                        <Text textAlign={"center"}>Type</Text>

                        {isTypeHovering && (
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label="Options"
                              icon={<BsThreeDots fontSize={"14px"} />}
                              variant="outline"
                              size={"xxs"}
                              border={"none"}
                              _hover={{ bg: "none", opacity: "40%" }}
                              onClick={(e) => e.stopPropagation()} // Prevent click from reaching other elements
                            />
                            <MenuList>
                              <MenuItem
                                fontSize={"13px"}
                                icon={<BsTrash color="red" />}
                              >
                                Delete Column
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}
                      </Flex>
                    </Th>
                    <Th
                      className={`planning-table-title  ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                      onMouseEnter={() => setIsStatusHovering(true)}
                      onMouseLeave={() => setIsStatusHovering(false)}
                    >
                      <Flex
                        w={"100%"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"10px"}
                      >
                        <Text textAlign={"center"}>Status</Text>

                        {isStatusHovering && (
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label="Options"
                              icon={<BsThreeDots fontSize={"14px"} />}
                              variant="outline"
                              size={"xxs"}
                              border={"none"}
                              _hover={{ bg: "none", opacity: "40%" }}
                              onClick={(e) => e.stopPropagation()} // Prevent click from reaching other elements
                            />
                            <MenuList>
                              <MenuItem
                                fontSize={"13px"}
                                icon={<BsTrash color="red" />}
                              >
                                Delete Column
                              </MenuItem>
                              {/* Add other actions here */}
                            </MenuList>
                          </Menu>
                        )}
                      </Flex>
                    </Th>
                    <Th
                      className={`planning-table-title  ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                      onMouseEnter={() => setIsPriorityHovering(true)}
                      onMouseLeave={() => setIsPriorityHovering(false)}
                    >
                      <Flex
                        w={"100%"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"10px"}
                      >
                        <Text textAlign={"center"}>Priority</Text>

                        {isPriorityHovering && (
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label="Options"
                              icon={<BsThreeDots fontSize={"14px"} />}
                              variant="outline"
                              size={"xxs"}
                              border={"none"}
                              _hover={{ bg: "none", opacity: "40%" }}
                              onClick={(e) => e.stopPropagation()}
                            />
                            <MenuList>
                              <MenuItem
                                fontSize={"13px"}
                                icon={<BsTrash color="red" />}
                              >
                                Delete Column
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}
                      </Flex>
                    </Th>
                    <Th
                      className={`planning-table-title  ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                      onMouseEnter={() => setIsLabelHovering(true)}
                      onMouseLeave={() => setIsLabelHovering(false)}
                    >
                      <Flex
                        w={"100%"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"10px"}
                      >
                        <Text textAlign={"center"}>Label</Text>

                        {isLabelHovering && (
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label="Options"
                              icon={<BsThreeDots fontSize={"14px"} />}
                              variant="outline"
                              size={"xxs"}
                              border={"none"}
                              _hover={{ bg: "none", opacity: "40%" }}
                              onClick={(e) => e.stopPropagation()}
                            />
                            <MenuList>
                              <MenuItem
                                fontSize={"13px"}
                                icon={<BsTrash color="red" />}
                              >
                                Delete Column
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}
                      </Flex>
                    </Th>
                    <Th
                      className={`planning-table-title  ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Notes
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.map((item, index) => (
                    <Tr
                      onMouseEnter={() => setIsHovering(index)}
                      onMouseLeave={() => setIsHovering(null)}
                      key={index}
                      className={`${isHovering === index ? "hovered" : ""} ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      <Td
                        className={`typeinput ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                      >
                        <ItemType
                          index={index}
                          item={item}
                          colorMode={colorMode}
                          tableId={tableId}
                          items={items}
                          setItems={setItems}
                        />
                      </Td>
                      <Td
                        className={`statusinput ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                        backgroundColor={getStatusBackgroundColor(item?.status)}
                      >
                        <ItemStatus
                          item={item}
                          index={index}
                          updateItemStatus={updateItemStatus}
                          customStatuses={customStatuses}
                          setNewStatusInput={setNewStatusInput}
                          newStatusInput={newStatusInput}
                          handleAddStatus={handleAddStatus}
                        />
                      </Td>
                      <Td
                        className={`priorityinput ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                        backgroundColor={getPriorityBackgroundColor(
                          item?.priority
                        )}
                      >
                        <ItemPriority
                          item={item}
                          index={index}
                          updateItemPriority={updateItemPriority}
                          customPriorities={customPriorities}
                          setNewPriorityInput={setNewPriorityInput}
                          newPriorityInput={newPriorityInput}
                          handleAddPriority={handleAddPriority}
                        />
                      </Td>
                      <Td
                        className={`LabelInput ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                      >
                        <ItemLabel
                          item={item}
                          index={index}
                          updateItemLabel={updateItemLabel}
                          getLabelBackgroundColor={getLabelBackgroundColor}
                          customLabels={customLabels}
                          setNewLabelInput={setNewLabelInput}
                          newLabelInput={newLabelInput}
                          handleAddLabel={handleAddLabel}
                        />
                      </Td>
                      <Td
                        className={`notesInput ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                      >
                        <Input size={"sm"} w={"300px"} />
                      </Td>
                    </Tr>
                  ))}
                  <Tr>
                    <Td>
                      <Button
                        onClick={handleAddNewItem}
                        size="xs"
                        variant="solid"
                      >
                        + Add Item
                      </Button>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        )}
      </Flex>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Table</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this table?</ModalBody>
          <ModalFooter>
            <Flex alignItems={"center"} gap={"10px"} w={"100%"}>
              <Button
                colorScheme="blue"
                w={"100%"}
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button w={"100%"} colorScheme="red" onClick={deleteTable}>
                Delete
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => setIsConfirmDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this item?</ModalBody>
          <ModalFooter>
            <Flex alignItems={"center"} gap={"10px"} w={"100%"}>
              <Button
                w={"100%"}
                colorScheme="blue"
                onClick={() => setIsConfirmDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                w={"100%"}
                colorScheme="red"
                onClick={() => {
                  handleDeleteItem(deleteItemIndex); // Delete the item
                  setIsConfirmDeleteModalOpen(false); // Close the modal
                }}
              >
                Delete
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PlanningTaskItem;
