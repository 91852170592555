import { createContext, useState, useContext } from "react";

// Create the context
const LinkBeatContext = createContext();

// Custom hook for easy context usage
export const useLinkBeat = () => useContext(LinkBeatContext);

// Provider component
export const LinkBeatProvider = ({ children }) => {
  const [linkBeat, setLinkBeat] = useState(null); // New state and setter function

  return (
    <LinkBeatContext.Provider value={{ linkBeat, setLinkBeat }}>
      {children}
    </LinkBeatContext.Provider>
  );
};
