import { Box, Text, Flex, Icon } from "@chakra-ui/react";
import "./Hero2.css";
import { FaTasks } from "react-icons/fa";
import "./BoxFeaturesLanding.css";
import { GiSandsOfTime } from "react-icons/gi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const BoxFeaturesLanding = ({ colorMode, isMobileView }) => {
  return (
    <Flex
      className={`BoxFeaturesLanding-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      flexDirection={"column"}
    >
      <Flex
        flexDirection={isMobileView ? "column" : "row"}
        alignItems={"center"}
        gap={"40px"}
      >
        <Box
          className={`indiebox ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Icon className="BoxFeaturesLanding-icon" as={GiSandsOfTime} />
          <Text className="BoxFeaturesLanding-text">Asset Tracking</Text>
          <Text className="BoxFeaturesLanding-descript">
            Keep track of assets to see what goes un-used and share them with
            collaborators for use in other projects!
          </Text>
        </Box>{" "}
        <Box
          className={`indiebox ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Icon className="BoxFeaturesLanding-icon" as={FaTasks} />
          <Text className="BoxFeaturesLanding-text">Task Prioritization</Text>
          <Text className="BoxFeaturesLanding-descript">
            Create tasks for things like scheduling studio sessions, and
            registering rights information to PROs!
          </Text>
        </Box>
        <Box
          className={`indiebox ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Icon
            className="BoxFeaturesLanding-icon"
            as={IoMdCheckmarkCircleOutline}
          />
          <Text className="BoxFeaturesLanding-text">Release Building</Text>
          <Text className="BoxFeaturesLanding-descript">
            Compile all assets needed for a commercial release to the DSPs,
            together in one place!
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default BoxFeaturesLanding;
