import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  useToast,
  SimpleGrid,
  VStack,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../../Config/config";

const LinkRecordingModal = ({
  userId,
  currentBeat,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  beatId,
  setIsLinkRecordingsOpen,
  fetchBeatData,
}) => {
  const toast = useToast();
  const [recordingAssets, setRecordingAssets] = useState([]);
  const [selectedRecordingAsset, setSelectedRecordingAsset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchRecordingAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/recordings`
      );
      setRecordingAssets(response.data.recordings);
    } catch (error) {
      console.error("Failed to fetch beat assets:", error);
    }
  };

  useEffect(() => {
    fetchRecordingAssets();
  }, []);

  const clearSelection = () => {
    setSelectedRecordingAsset(null);
  };

  const linkRecordingToBeat = async () => {
    if (!selectedRecordingAsset) {
      toast({
        title: "Selection Error",
        description: "No lyric selected to link.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/beat/${beatId}/addRecording`,
        {
          recordingAsset: selectedRecordingAsset,
        }
      );
      toast({
        title: "Recording Linked Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setSelectedRecordingAsset(null);
      setIsLinkRecordingsOpen(false);
      fetchBeatData();
    } catch (error) {
      toast({
        title: "Linking Failed",
        description:
          error.response?.data?.message || "Failed to link the recording.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <SimpleGrid columns={3} spacing={4}>
        {!selectedRecordingAsset && (
          <>
            {" "}
            {recordingAssets.map((asset) => (
              <Box
                key={asset._id}
                p={5}
                shadow="md"
                borderWidth="1px"
                cursor="pointer"
                onClick={() => setSelectedRecordingAsset(asset)}
              >
                <Text mt={4}>{asset.title}</Text>
                <Text fontSize="sm">Uploaded by: {asset.user.name}</Text>
                <Text fontSize="sm">Status: {asset.status}</Text>
              </Box>
            ))}
          </>
        )}
      </SimpleGrid>
      {selectedRecordingAsset && (
        <VStack spacing={4} align="stretch">
          <Text fontSize="xl" fontWeight="bold">
            {selectedRecordingAsset.title}
          </Text>
          <Text>Uploaded by: {selectedRecordingAsset.user.name}</Text>
          <Text>Status: {selectedRecordingAsset.status}</Text>
          <HStack>
            <Button
              colorScheme="blue"
              mr={3}
              isLoading={isLoading}
              loadingText="Linking..."
              onClick={linkRecordingToBeat}
            >
              Link Recording
            </Button>
            <Button size="sm" onClick={clearSelection}>
              Back to List
            </Button>
          </HStack>
        </VStack>
      )}
    </Box>
  );
};

export default LinkRecordingModal;
