import React, { createContext, useState, useContext } from "react";

const MutualSpaceContext = createContext();

export const useMutualSpace = () => {
  return useContext(MutualSpaceContext);
};

export const MutualSpaceProvider = ({ children }) => {
  const [selectedMutualSpace, setSelectedMutualSpace] = useState(null);

  const updateSelectedMutualSpace = (mutualSpace) => {
    setSelectedMutualSpace(mutualSpace);
  };

  return (
    <MutualSpaceContext.Provider
      value={{ selectedMutualSpace, updateSelectedMutualSpace }}
    >
      {children}
    </MutualSpaceContext.Provider>
  );
};
