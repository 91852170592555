import { createContext, useState, useContext } from "react";

const CurrentBeatContext = createContext();

export const useCurrentBeat = () => useContext(CurrentBeatContext);

export const CurrentBeatProvider = ({ children }) => {
  const [currentBeat, setCurrentBeat] = useState(null);

  return (
    <CurrentBeatContext.Provider value={{ currentBeat, setCurrentBeat }}>
      {children}
    </CurrentBeatContext.Provider>
  );
};
