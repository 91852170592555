import React, { useState } from "react";
import {
  Box,
  Flex,
  Tooltip,
  Button,
  Text,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  Badge,
  useToast,
  Checkbox,
  Avatar,
  Tag,
  TagLabel,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import "./MainLyrics.css";
import { BsCheck2All, BsHeadphones, BsTrash } from "react-icons/bs";
import axios from "axios";
import { TbMicrophone } from "react-icons/tb";
import { MdDeleteForever, MdOutlineLyrics } from "react-icons/md";
import { useCurrentLyric } from "../../../../Context/CurrentLyricContext";
import { useLyricsPanel } from "../../../../Context/LyricsPanelContext";
import { useCurrentBeat } from "../../../../Context/CurrentBeatContext";
import { useBeatPanel } from "../../../../Context/BeatsPanelContext";
import { FaRegComment } from "react-icons/fa6";
import { DownloadIcon, LinkIcon } from "@chakra-ui/icons";
import { API_ENDPOINT } from "../../../../Config/config";
import StatusLyricsItem from "./StatusLyricsItem";
import { BiUserPlus } from "react-icons/bi";
import AddSongWriterModal from "../../../DetailsPanel/LyricsPanel/AddSongWriterModal";
import LyricsDrawer from "./LyricsDrawer";
import BeatLinkedItem from "../../../DetailsPanel/RecordingsPanel/BeatLinkedItem";
import LinkedItemRecording from "../../../Players/MutualSpacePlayers/LinkedItemRecording";
import MainLyricLinkedBeat from "./MainLyricLinkedBeat";
import MainLinkedRecording from "./MainLinkedRecording";

const MainLyrics = ({
  lyricAsset,
  userName,
  userId,
  colorMode,
  mutualSpaceId,
  tableId,
  boardId,
  openDetailsPanelSettings,
  guestToken,
  fetchLyricData,
  onCheckboxChange,
}) => {
  const [activeTab, setActiveTab] = useState("beat");
  const { setLyricsPanel } = useLyricsPanel();
  const [isLoading, setIsLoading] = useState(false);
  const { currentLyric, setCurrentLyric } = useCurrentLyric();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toast = useToast();
  const { setCurrentBeat, currentBeat } = useCurrentBeat();
  const { setBeatPanel } = useBeatPanel();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const toLyricPanel = (event) => {
    event.stopPropagation();
    setLyricsPanel("Lyrics");

    const enhancedLyricAsset = {
      ...lyricAsset,
      mutualSpaceId,
      boardId,
      tableId,
      fetchLyricData,
    };
    setCurrentLyric(enhancedLyricAsset);

    openDetailsPanelSettings();
    setBeatPanel(null);
    setCurrentBeat(null);
  };

  const deleteLyricAsset = async () => {
    try {
      const url = `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/lyric/${lyricAsset._id}`;
      const response = await axios.delete(url);
      if (response.status === 200) {
        toast({
          title: "Lyric Deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
        setIsDeleteModalOpen(false);
        fetchLyricData();
      }
    } catch (error) {
      toast({
        title: "Failed to Delete.",
        description: "Could not delete the lyric. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const downloadLyricAsset = async (e) => {
    try {
      const url = `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/lyric/${lyricAsset._id}/download`;
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = `${lyricAsset.title}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      toast({
        title: "Download Success.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    } catch (error) {
      toast({
        title: "Failed to Download.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const songWriters = Array.isArray(lyricAsset?.songWriters)
    ? lyricAsset.songWriters
    : lyricAsset?.songWriters?.usersWritingTo || [];

  const [isAddSongwriterOpen, setIsAddSongwriterOpen] = useState(false);

  return (
    <Box
      onClick={onDrawerOpen} // Add this line
      boxShadow={"md"}
      className={`lyricAsset-conainter ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex gap={"10px"} alignItems={"end"} w={"100%"}>
        <Flex w={"100%"}>
          <StatusLyricsItem
            lyricAsset={lyricAsset}
            lyricId={lyricAsset._id}
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            mutualSpaceId={mutualSpaceId}
            tableId={tableId}
            boardId={boardId}
            openDetailsPanelSettings={openDetailsPanelSettings}
            guestToken={guestToken}
            fetchLyricData={fetchLyricData}
          />
        </Flex>
        <Flex gap={"10px"} alignItems={"center"}>
          <Tooltip placement="top" borderRadius={"5px"} label={"Download"}>
            <IconButton
              colorScheme="orange"
              icon={<DownloadIcon />}
              size={"xs"}
              isDisabled={!!guestToken}
              onClick={(e) => {
                e.stopPropagation();
                downloadLyricAsset();
              }}
            />
          </Tooltip>
          <Tooltip placement="top" borderRadius={"7px"} label={"Delete Lyric"}>
            <IconButton
              colorScheme="red"
              icon={<BsTrash />}
              size={"xs"}
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleteModalOpen(true); // Prevent triggering parent's onClick
              }}
              isDisabled={!!guestToken}
            />
          </Tooltip>
          <Tooltip placement="top" borderRadius={"7px"} label={"Bulk Delete"}>
            <Checkbox
              size="lg"
              colorScheme="red"
              onChange={(e) => {
                onCheckboxChange(lyricAsset, e.target.checked);
              }}
              isDisabled={!!guestToken}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Flex
        className={`lyricAsset-pad ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex>
          <Text className="lyricAssetlyricText">{lyricAsset.lyricsText}</Text>
        </Flex>
      </Flex>

      <Flex
        className="lyricAsset-players-tabs"
        alignItems={"center"}
        gap={"8px"}
        flexDirection={"column"}
      >
        <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
          {lyricAsset?.linkedBeats && lyricAsset.linkedBeats.length > 0 ? (
            <Flex w={"100%"}>
              {lyricAsset.linkedBeats.map((linkedBeat) => (
                <Flex w={"100%"} key={linkedBeat._id}>
                  <MainLyricLinkedBeat
                    beat={linkedBeat}
                    colorMode={colorMode}
                  />
                </Flex>
              ))}
            </Flex>
          ) : (
            <Button
              isDisabled={!!guestToken}
              variant={"outline"}
              w={"100%"}
              size={"xs"}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Flex alignItems={"center"} gap={"7px"}>
                <Icon fontSize={"9px"} as={LinkIcon} />
                Link Beat
              </Flex>
            </Button>
          )}
        </Flex>
        <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
          {lyricAsset?.linkedRecordings &&
          lyricAsset.linkedRecordings.length > 0 ? (
            <Flex w={"100%"}>
              {lyricAsset.linkedRecordings.map((linkedRecording) => (
                <Flex w={"100%"} key={linkedRecording._id}>
                  <MainLinkedRecording
                    recording={linkedRecording}
                    colorMode={colorMode}
                  />
                </Flex>
              ))}
            </Flex>
          ) : (
            <Button
              isDisabled={!!guestToken}
              variant={"outline"}
              w={"100%"}
              size={"xs"}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Flex alignItems={"center"} gap={"7px"}>
                <Icon fontSize={"9px"} as={LinkIcon} />
                Link Recording
              </Flex>
            </Button>
          )}
        </Flex>
      </Flex>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={MdDeleteForever} />
              <Text>Delete Lyric</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete the lyric {lyricAsset.title}?
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
              <Button
                w={"100%"}
                colorScheme="gray"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button onClick={deleteLyricAsset} w={"100%"} colorScheme="red">
                Delete Lyric
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isAddSongwriterOpen}
        onClose={() => setIsAddSongwriterOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Icon className="granticon" as={BiUserPlus} />
              <Text className="accessheader">Add Songwriter</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddSongWriterModal
              lyricAsset={currentLyric}
              userName={userName}
              userId={userId}
              colorMode={colorMode}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              lyricId={lyricAsset._id}
              guestToken={guestToken}
              fetchLyricData={fetchLyricData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        size="sm"
        onClose={onDrawerClose}
      >
        <DrawerOverlay />
        <DrawerContent
          p={1}
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <DrawerCloseButton />
          <DrawerHeader>Details</DrawerHeader>
          <DrawerBody>
            <LyricsDrawer
              tableId={tableId}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              lyricAsset={lyricAsset}
              colorMode={colorMode}
              lyricId={lyricAsset._id}
              fetchLyricData={fetchLyricData}
              userId={userId}
              userName={userName}
              guestToken={guestToken}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MainLyrics;
