import { createContext, useState, useContext } from "react";

const RecordingsKanbanContext = createContext();

export const useRecordingsKanban = () => useContext(RecordingsKanbanContext);

export const RecordingsKanbanProvider = ({ children }) => {
  const [recordingsKanban, setRecordingsKanban] = useState(null);

  return (
    <RecordingsKanbanContext.Provider
      value={{ recordingsKanban, setRecordingsKanban }}
    >
      {children}
    </RecordingsKanbanContext.Provider>
  );
};
