import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import "./Footer.css";

const Footer = ({ isLandingRoute, navigate, colorMode }) => {
  const toAbout = () => {
    navigate("/about");
  };

  return (
    <Flex
      as="footer"
      className={`footer-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Box className="footer-surround">
        <Box>
          <Text className="claimtext">@2024 IndieMutual</Text>
        </Box>
      </Box>
      <Box className="footer-surround">
        {/* <Flex justifyContent={"end"} gap={"30px"} alignItems={"center"}>
          <Link onClick={toAbout}>
            <Text className="mainfooter-link-text">About</Text>
          </Link>
          <Link onClick={toPricing}>
            <Text className="mainfooter-link-text">Pricing</Text>
          </Link>
          <Link onClick={toPrivacy}>
            <Text className="mainfooter-link-text">Privacy Policy</Text>
          </Link>
          <Link onClick={toContact}>
            <Text className="mainfooter-link-text">Contact</Text>
          </Link>
        </Flex> */}
      </Box>
    </Flex>
  );
};

export default Footer;
