import React, { useState, useContext, useEffect } from "react";
import "./MobileMainBeatsPlayer.css";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  useDisclosure, // Import useDisclosure hook for modal
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  BsPlayCircle,
  BsPencil,
  BsSendArrowDown,
  BsPauseCircle,
} from "react-icons/bs";
import { DownloadIcon, LinkIcon } from "@chakra-ui/icons";
import { FaMicrophone, FaChevronRight, FaTrashCan } from "react-icons/fa6";
import { useAddBeatToRelease } from "../../../Context/AddBeatToRelease";
import { useBeatPanel } from "../../../Context/BeatsPanelContext";
import { useAddLyricToRelease } from "../../../Context/AddLyricToRelease";
import { useLinkBeat } from "../../../Context/LinkBeat";
import { API_ENDPOINT } from "../../../Config/config";
import { useCurrentBeat } from "../../../Context/CurrentBeatContext";
import { MusicPlayerContext } from "../../../Context/MusicPlayerContext";
import { FaExchangeAlt } from "react-icons/fa";
import MobileBeatsPanel from "../../DetailsPanel/BeatsPanel/MobileBeatsPanel";

const MobileMainBeatsPlayer = ({
  colorMode,
  beat,
  fetchBeats,
  userName,
  userId,
  onDeleteBeat,
  guestToken,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Use the hook for drawer control
  const { setAddBeat } = useAddBeatToRelease();
  const { setBeatPanel } = useBeatPanel();
  const { setAddLyric } = useAddLyricToRelease();
  const { setLinkBeat } = useLinkBeat();
  const { setCurrentBeat, currentBeat } = useCurrentBeat();
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);
  const [pendingStatus, setPendingStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(
    beat.status || "Set Status"
  );

  const { playTrack, stopTrack, currentTrack, seekTrack } =
    useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [localDuration, setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack && currentTrack.src === beat.fileUrl && !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(beat.fileUrl);
    }
  };

  const handleBeatClick = (event) => {
    setBeatPanel("Beats");

    setCurrentBeat(beat);
    setAddBeat(null);
    setLinkBeat(null);
    onOpen();
  };

  return (
    <Box
      className={`mobile-main-player ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      onClick={(e) => {
        e.stopPropagation();
        handleBeatClick();
      }}
    >
      <Flex
        w={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={"10px"}
      >
        <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
          <Box>
            {isCurrentBeatPlaying ? (
              <>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePlayPause(); // Call the original saveProducerName function
                  }}
                  icon={<BsPauseCircle className="main-player-button" />}
                />
              </>
            ) : (
              <>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePlayPause(); // Call the original saveProducerName function
                  }}
                  icon={<BsPlayCircle className="main-player-button" />}
                />
              </>
            )}
          </Box>
          <Box>
            <Flex flexDirection={"column"}>
              <Text className="mobile-player-name">{beat.title}</Text>
              <Text className="mobile-player-producer">
                {" "}
                {beat.producer || "Producer"}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex w={"100%"}>
          <Box className="mobile-right">
            <Menu
              border={
                colorMode === "light"
                  ? "2px solid rgba(153, 178, 198, 0.23)"
                  : "2px solid rgba(43, 46, 54, 1)"
              }
              bg={colorMode === "light" ? "white" : "black"}
            >
              {({ isOpen }) => (
                <>
                  <Flex alignItems="center" gap={2}>
                    <Tooltip
                      placement="bottom"
                      borderRadius={"7px"}
                      label={"Set Status"}
                    >
                      <MenuButton
                        onClick={(event) => event.stopPropagation()} // Stop event propagation
                        fontSize={"10px"}
                        size={"xs"}
                        as={Button}
                        rightIcon={
                          <FaChevronRight
                            style={{
                              transition: "transform 0.2s",
                              transform: isOpen ? "rotate(90deg)" : "none",
                            }}
                          />
                        }
                      >
                        {selectedStatus}
                      </MenuButton>
                    </Tooltip>
                  </Flex>
                  <MenuList
                    p={2}
                    bg={colorMode === "light" ? "white" : "black"}
                    zIndex={"9999"}
                  >
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(249, 185, 89, .23)"
                              : "rgba(249, 185, 89, .23)",
                        },
                      }}
                      bg={colorMode === "light" ? "white" : "black"}
                      className="blank"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Icon as={BsPencil} fontSize="17px" />
                      <Text className="rager">Writting To</Text>
                    </MenuItem>
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Icon as={FaMicrophone} fontSize="17px" />

                      <Text className="rager">Recorded On</Text>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </Flex>
      </Flex>
      <Box className="mobile-slider-section">
        <Flex w={"100%"}>
          <Box w={"100%"}>
            <Slider
              w={"100%"}
              aria-label="track-progress"
              value={isCurrentBeatPlaying ? localCurrentTime : 0}
              max={isCurrentBeatPlaying ? localDuration : 0}
              onChange={(val) => isCurrentBeatPlaying && seekTrack(val)}
              focusThumbOnChange={false}
              isDisabled={!isCurrentBeatPlaying}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>
        </Flex>
      </Box>

      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          borderTopRightRadius={"12px"}
          borderTopLeftRadius={"12px"}
          h={"800px"}
        >
          <DrawerCloseButton ml={5} />
          <MobileBeatsPanel
            currentBeat={currentBeat}
            setCurrentBeat={setCurrentBeat}
            colorMode={colorMode}
          />
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MobileMainBeatsPlayer;
