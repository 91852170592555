import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  useToast,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Icon,
  MenuItem,
  MenuButton,
  Menu,
  MenuList,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import { BsPlusLg, BsFolder, BsFolderPlus } from "react-icons/bs";
import axios from "axios";
import { MdOutlineLyrics } from "react-icons/md";
import "./ArtworkDrawer.css";
import { AddIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { TbUserPlus } from "react-icons/tb";
import { AiOutlineUser } from "react-icons/ai";
import { API_ENDPOINT } from "../../../../Config/config";
import ArtworkNameInput from "./ArtworkNameInput";
import ArtworkTimeline from "./ArtworkTimeline";
import MainArtworkComments from "./MainArtworkComments";
import CurrentArtworkStatus from "../../../DetailsPanel/ArtworkPanel/CurrentArtworkStatus";
import { GiSandsOfTime } from "react-icons/gi";
import AddArtworkToFolder from "./AddArtworkToFolder";
import { PiFolderPlus } from "react-icons/pi";

const ArtworkDrawer = ({
  tableId,
  mutualSpaceId,
  boardId,
  artworkAsset,
  colorMode,
  artworkId,
  fetchArtworkAssets,
  userName,
  userId,
  guestToken,
}) => {
  const toast = useToast();
  const inputBg = useColorModeValue("gray.50", "rgba(66, 69, 99, 0.23)");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const [isAddToFolderOpen, setIsAddToFolderOpen] = useState(false);
  const [isAddToReleaseOpen, setIsAddToReleaseOpen] = useState(false);

  return (
    <Box className="ArtworkDrawer-container">
      <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"5px"}
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={TbUserPlus} />
            <Text className="slighttext">Title</Text>
          </Flex>
          <ArtworkNameInput
            userId={userId}
            currentArtwork={artworkAsset}
            colorMode={colorMode}
            userName={userName}
            mutualSpaceId={mutualSpaceId}
            boardId={boardId}
            tableId={tableId}
            artworkId={artworkId}
            fetchArtworkAssets={fetchArtworkAssets}
            guestToken={guestToken}
          />
        </Flex>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"5px"}
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={GiSandsOfTime} />
            <Text className="slighttext">Status</Text>
          </Flex>
          <CurrentArtworkStatus
            currentArtwork={artworkAsset}
            artworkId={artworkId}
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            mutualSpaceId={mutualSpaceId}
            tableId={tableId}
            boardId={boardId}
            fetchArtworkAssets={fetchArtworkAssets}
            guestToken={guestToken}
          />
        </Flex>
      </Flex>

      <Flex alignItems={"center"} gap={"20px"}>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"5px"}
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"12px"} as={BsFolder} />
            <Text className="slighttext">Folders</Text>
          </Flex>

          {artworkAsset?.folders && artworkAsset.folders.length > 0 ? (
            <Flex height={"100%"} w={"100%"}>
              {artworkAsset.folders.map((folder) => (
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className={`writing-to-item ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                  key={folder._id}
                >
                  <Avatar
                    icon={<BsFolder fontSize="15px" />}
                    mr={-4}
                    size={"xs"}
                  />
                </Flex>
              ))}
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                className={`writing-to-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                <Avatar
                  onClick={() => setIsAddToFolderOpen(true)}
                  size={"xs"}
                  icon={<BsFolderPlus fontSize="15px" />}
                  _hover={{ cursor: "pointer", opacity: "80%" }}
                />
              </Flex>
            </Flex>
          ) : (
            <Button
              isDisabled={!!guestToken}
              onClick={() => setIsAddToFolderOpen(true)}
              fontSize={"10px"}
              size={"sm"}
              w={"100%"}
            >
              Add to Folder
            </Button>
          )}
        </Flex>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"5px"}
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"12px"} as={CheckCircleIcon} />
            <Text className="slighttext">Releases</Text>
          </Flex>

          {artworkAsset?.releases && artworkAsset.releases.length > 0 ? (
            <Flex height={"100%"} w={"100%"}>
              {artworkAsset.releases.map((release) => (
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className={`writing-to-item ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                  key={release._id}
                >
                  <Avatar
                    icon={<BsFolder fontSize="15px" />}
                    mr={-4}
                    size={"xs"}
                  />
                </Flex>
              ))}
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                className={`writing-to-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                <Avatar
                  onClick={() => setIsAddToReleaseOpen(true)}
                  size={"xs"}
                  icon={<BsFolderPlus fontSize="15px" />}
                  _hover={{ cursor: "pointer", opacity: "80%" }}
                />
              </Flex>
            </Flex>
          ) : (
            <Button
              isDisabled={!!guestToken}
              onClick={() => setIsAddToReleaseOpen(true)}
              fontSize={"10px"}
              size={"sm"}
              w={"100%"}
            >
              Add to Release
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex w={"100%"}>
        <ArtworkTimeline
          userId={userId}
          currentArtwork={artworkAsset}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          artworkId={artworkId}
          fetchArtworkAssets={fetchArtworkAssets}
        />
      </Flex>
      <Flex
        className={`beatdrawer-comments ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        w={"100%"}
      >
        <MainArtworkComments
          userId={userId}
          artworkAsset={artworkAsset}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          artworkId={artworkId}
          fetchArtworkAssets={fetchArtworkAssets}
          guestToken={guestToken}
        />
      </Flex>
      <Modal
        isOpen={isAddToFolderOpen}
        onClose={() => setIsAddToFolderOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Icon className="granticon" as={PiFolderPlus} />
              <Text className="accessheader">Add to Folder</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddArtworkToFolder
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              artworkAsset={artworkAsset}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              artworkId={artworkId}
              setIsAddToFolderOpen={setIsAddToFolderOpen}
              fetchArtworkAssets={fetchArtworkAssets}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ArtworkDrawer;
