import { createContext, useState, useContext } from "react";

const LyricsPanelContext = createContext();

export const useLyricsPanel = () => useContext(LyricsPanelContext);

export const LyricsPanelProvider = ({ children }) => {
  const [lyricsPanel, setLyricsPanel] = useState(null);

  return (
    <LyricsPanelContext.Provider value={{ lyricsPanel, setLyricsPanel }}>
      {children}
    </LyricsPanelContext.Provider>
  );
};
