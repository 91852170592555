import React, { useEffect, useState } from "react";
import { Box, Flex, Icon, Text, useToast } from "@chakra-ui/react";
import "./RecordingTimeLine.css";
import { GoClock } from "react-icons/go";
import { BiX } from "react-icons/bi";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const RecordingTimeLine = ({
  userId,
  currentRecording,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  recordingId,
  fetchRecordingData,
}) => {
  const formatAssignedDate = (dateString) => {
    const date = parseISO(dateString);
    return formatDistanceToNowStrict(date);
  };
  const [statusColors, setStatusColors] = useState({});
  const [customStatuses, setCustomStatuses] = useState([]);
  const toast = useToast();

  const fetchCustomStatuses = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/customstatuses`
      );
      if (response.data && response.data.customStatuses) {
        setCustomStatuses(response.data.customStatuses);
        const colors = {};
        response.data.customStatuses.forEach((status) => {
          colors[status.status] = status.color || "transparent";
        });
        setStatusColors(colors);
      } else {
        setCustomStatuses([]);
        setStatusColors({});
      }
    } catch (error) {
      console.error("Error fetching custom statuses:", error);
      toast({
        title: "Error",
        description: "Failed to load custom statuses.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCustomStatuses();
  }, [mutualSpaceId, boardId, tableId]);

  return (
    <Box
      className={`writing-to-main-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      {currentRecording.status ? (
        <Flex gap={"10px"} alignItems={"center"}>
          <Icon fontSize={"25px"} as={GoClock} />
          <Text className="timelinetext">
            This recording has been{" "}
            <span style={{ color: statusColors[currentRecording.status] }}>
              {currentRecording.status}
            </span>{" "}
            for {formatAssignedDate(currentRecording.statusSetDate)}
          </Text>
        </Flex>
      ) : (
        <Flex gap={"10px"} alignItems={"center"}>
          <Icon fontSize={"25px"} as={BiX} />
          <Text className="timelinetext">
            {" "}
            No Timeline Dates for this Recording.
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default RecordingTimeLine;
