import {
  Avatar,
  Box,
  Button,
  Flex,
  useToast,
  Text,
  Tooltip,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";
import MutualSpaceTable from "../../Components/Table/MutualSpaceTable/MutualSpaceTable";
import "./MutualSpace.css";

const MutualSpace = ({
  colorMode,
  isMobileView,
  userId,
  userName,
  isSidebarCollapsed,
  guestToken,
}) => {
  const { mutualSpaceId } = useParams();
  const [mutualSpace, setMutualSpace] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toast = useToast();

  const [editableMutualSpaceName, setEditableMutualSpaceName] = useState(
    mutualSpace?.mutualSpaceName
  );

  useEffect(() => {
    setEditableMutualSpaceName(mutualSpace?.mutualSpaceName);
  }, [mutualSpace]);

  const fetchMutualSpace = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}`
      );
      setMutualSpace(response.data.data);
    } catch (error) {
      console.error("Error fetching mutual space:", error);
      toast({
        title: "Error fetching mutual space",
        description: "Unable to fetch mutual space data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchMutualSpace();
  }, [mutualSpaceId]);

  const MutualSpaceIcon = ({ mutualSpaceName }) => {
    const initial = mutualSpaceName.charAt(0);

    const charToColor = (char) => {
      const alphabetPosition = char.toUpperCase().charCodeAt(0) - 64;
      const hue = (alphabetPosition * 137) % 360; // 137 is a "magic" number that helps create a visually appealing sequence
      const saturation = 50 + (alphabetPosition % 51); // Vary between 50% and 100%
      const lightness = 50 + ((alphabetPosition * 3) % 26); // Vary between 50% and 75%
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    const color = charToColor(initial);

    return (
      <Avatar
        h={"110px"}
        w={"110px"}
        borderRadius={"23px"}
        size={"xl"}
        name={initial}
        bg={color}
        fontWeight={"900"}
        border={colorMode === "light" ? "6px solid white" : "6px solid white"}
        boxShadow={"md"}
      />
    );
  };

  const updateMutualSpaceName = async () => {
    if (editableMutualSpaceName !== mutualSpace.mutualSpaceName) {
      try {
        const response = await axios.put(
          `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}`,
          { newMutualSpaceName: editableMutualSpaceName }
        );

        toast({
          title: "Success",
          description: "Table name updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Failed to update table name", error);
        setEditableMutualSpaceName(mutualSpace.mutualSpaceName);
        toast({
          title: "Error",
          description: "Failed to update table name.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const [isHovered, setIsHovered] = useState(false); // New hover state
  const inputBg = useColorModeValue("rgba(247, 250, 252, 0.83)", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");

  return (
    <Box
      className={`Mutualindiespace-page-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex flexDirection={"column"}>
        <Flex
          onMouseEnter={() => setIsHovered(true)} // Set hover state to true when mouse enters
          onMouseLeave={() => setIsHovered(false)} // Set hover state to false when mouse leaves
          className={`image-section-top ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          {/* <ImageTopSpace
            mutualSpaceId={mutualSpaceId}
            fetchMutualSpace={fetchMutualSpace}
          /> */}

          <Flex
            position={"absolute"}
            left={"0%"}
            p={2}
            justifyContent={"end"}
            w={"100%"}
          >
            {isHovered && ( // Conditional rendering based on hover state
              <Button>Edit Cover</Button>
            )}
          </Flex>
          <Flex className="metadetails">
            <Flex>
              {mutualSpace && (
                <MutualSpaceIcon
                  mutualSpaceName={mutualSpace.mutualSpaceName}
                />
              )}
            </Flex>
            <Flex alignItems={"center"} justifyContent={"space-between"} pb={2}>
              <Flex alignItems={"center"} gap={"20px"}>
                <Tooltip
                  placement="top"
                  borderRadius={"7px"}
                  label={"Click to Edit"}
                >
                  <Input
                    fontSize={"21px"}
                    fontWeight={"400"}
                    size={"sm"}
                    value={editableMutualSpaceName}
                    borderRadius={"7px"}
                    bg={inputBg}
                    _hover={{
                      bg: hoverBg,
                      borderColor: "gray.400",
                    }}
                    borderColor={borderColor}
                    onChange={(e) =>
                      !guestToken && setEditableMutualSpaceName(e.target.value)
                    }
                    onBlur={() => !guestToken && updateMutualSpaceName()}
                    isDisabled={!!guestToken}
                  />
                </Tooltip>
              </Flex>
              <Flex alignItems={"center"} gap={"10px"}>
                {!guestToken && (
                  <>
                    <Tooltip
                      placement="top"
                      borderRadius={"7px"}
                      label={"Delete"}
                    >
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsDeleteModalOpen(true);
                        }}
                        size={"sm"}
                        variant={"outline"}
                        colorScheme="red"
                      >
                        <Flex alignItems={"center"} gap={"5px"}>
                          <Text>Delete</Text>
                        </Flex>
                      </Button>
                    </Tooltip>
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <MutualSpaceTable
          isMobileView={isMobileView}
          colorMode={colorMode}
          mutualSpace={mutualSpace}
          mutualSpaceId={mutualSpaceId}
          userName={userName}
          userId={userId}
          fetchMutualSpace={fetchMutualSpace}
          isSidebarCollapsed={isSidebarCollapsed}
          guestToken={guestToken}
        />
      </Flex>
    </Box>
  );
};

export default MutualSpace;
