import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  MenuItem,
  Menu,
  Text,
  Button,
  MenuButton,
  MenuList,
  Flex,
  Input,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import React from "react";
import { BsPlus } from "react-icons/bs";

const ItemStatus = ({
  item,
  index,
  updateItemStatus,
  customStatuses,
  newStatusInput,
  setNewStatusInput,
  handleAddStatus,
}) => {
  return (
    <div>
      <Menu>
        <MenuButton
          as={Button}
          size="sm"
          rightIcon={<ChevronDownIcon />}
          w={"100%"}
          variant={"outline"}
          border={"none"}
          className="main-menu-button-text"
          bg={"none"}
          _hover={{ bg: "none" }}
        >
          {item?.status ? <Text>{item?.status}</Text> : <Text>Set Status</Text>}
        </MenuButton>
        <MenuList>
          <Flex alignItems={"center"} gap={"5px"} p={1}>
            <Input
              borderRadius={"7px"}
              placeholder="Add Status..."
              size={"xs"}
              width={"100%"}
              value={newStatusInput}
              onChange={(e) => setNewStatusInput(e.target.value)}
            />
            <Tooltip
              label="Add Type"
              hasArrow
              placement="top"
              closeOnClick={false}
            >
              <IconButton
                onClick={() => handleAddStatus()}
                icon={<BsPlus />}
                size={"xs"}
                colorScheme="green"
              />
            </Tooltip>
          </Flex>
          <MenuItem onClick={() => updateItemStatus(index, "In Progress")}>
            In Progress
          </MenuItem>
          <MenuItem onClick={() => updateItemStatus(index, "Done")}>
            Done
          </MenuItem>
          {customStatuses.map((status, idx) => (
            <MenuItem key={idx} onClick={() => updateItemStatus(index, status)}>
              {status}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  );
};

export default ItemStatus;
