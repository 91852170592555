import React, { useState, useRef } from "react";
import "./MessengerList.css";
import {
  Box,
  Text,
  Icon,
  Button,
  Flex,
  Center,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import "./MobileMessengerList.css";
import { FaHashtag } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import { useDirectMessageDisplay } from "../../Context/DirectMessageDisplayContext";

const MobileMessengerList = ({
  colorMode,
  userName,
  userId,
  isMobileView,
  onChannelSelect,
  onDirectMessageSelect,
  isLoading,
  channels,
  fetchChannels,
  guestToken,
  directMessages,
  fetchDirectMessages,
}) => {
  const [isDirectMessagesOpen, setIsDirectMessagesOpen] = useState(true); // Step 2: New state for toggling the assets section
  const toggleDirectMessagesOpen = () =>
    setIsDirectMessagesOpen(!isDirectMessagesOpen);
  const [isChannelsOpen, setIsChannelsOpen] = useState(true); // Step 2: New state for toggling the assets section
  const toggleChannelsOpen = () => setIsChannelsOpen(!isChannelsOpen);
  const { setDirectMessages } = useDirectMessageDisplay();

  return (
    <Box
      className={`MobileMessengerList ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      boxShadow={"md"}
    >
      <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
        <Menu>
          <MenuButton as={Button} fontSize={"13px"} w={"100%"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={FaHashtag} />
              <Text>Channels</Text>
            </Flex>
          </MenuButton>
          <MenuList>
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : (
              channels.map((channel) => (
                <MenuItem
                  key={channel._id}
                  onClick={() => {
                    onChannelSelect(channel);
                    setDirectMessages(null);
                    onDirectMessageSelect(false);
                  }}
                >
                  {channel.channelName}
                </MenuItem>
              ))
            )}
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            onClick={(event) => {
              event.stopPropagation();
              setDirectMessages(true);
              onDirectMessageSelect(null); // Or some meaningful value to indicate DirectMessages should be shown
            }}
            as={Button}
            fontSize={"13px"}
            w={"100%"}
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={FaMessage} />
              <Text>Direct Messages</Text>
            </Flex>
          </MenuButton>
          <MenuList>
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : (
              directMessages.map((directMessage) => {
                const otherUser = directMessage.users.find(
                  (user) => user.userId !== userId
                );
                const otherUserName = otherUser
                  ? otherUser.reciever || otherUser.sender
                  : "Unknown";

                return (
                  <MenuItem
                    key={directMessage._id}
                    onClick={() => {
                      onDirectMessageSelect(directMessage);
                      setDirectMessages(true);
                    }}
                  >
                    {otherUserName}
                  </MenuItem>
                );
              })
            )}
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};

export default MobileMessengerList;
