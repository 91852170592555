import React, { useContext, useEffect, useState } from "react";
import {
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { BsPlayCircle, BsPauseCircle } from "react-icons/bs";
import { MusicPlayerContext } from "../../../Context/MusicPlayerContext";

const RecordingsPanelPlayer = ({ colorMode, currentRecording }) => {
  const { playTrack, stopTrack, currentTrack } = useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack &&
    currentTrack.src === currentRecording.fileUrl &&
    !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(currentRecording.fileUrl);
    }
  };

  return (
    <Flex
      className={`BeatsPanelPlayercontainer ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      alignItems={"center"}
      gap={"8px"}
    >
      <Flex h={"100%"} alignItems={"center"} justifyContent={"center"}>
        {isCurrentBeatPlaying ? (
          <>
            <IconButton
              onClick={(event) => {
                togglePlayPause(); // Call the original saveProducerName function
              }}
              icon={<BsPauseCircle className="main-player-button" />}
            />
          </>
        ) : (
          <>
            <IconButton
              onClick={(event) => {
                togglePlayPause(); // Call the original saveProducerName function
              }}
              icon={<BsPlayCircle className="main-player-button" />}
            />
          </>
        )}
      </Flex>
      <Flex gap={"3px"} w={"100%"} flexDirection={"column"}>
        <Flex flexDirection={"column"}>
          <Text className="beatpanelplayer-name">
            {currentRecording?.title}
          </Text>
        </Flex>
        <Flex flexDirection={"column"} gap={"1px"}>
          <Text className="beatproducer">
            {currentRecording?.artist || "Artist"}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RecordingsPanelPlayer;
