import React, { useRef, useState, useEffect } from "react";
import { Box, Flex, Input, Icon, useToast } from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";
import "./MobilePlanningTaskItem.css";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";

const MobilePlanningTaskItem = ({
  tableId,
  table,
  colorMode,
  fetchTables,
  userId,
}) => {
  const [items, setItems] = useState([]);
  const scrollTableRef = useRef(null);
  const fixedColRef = useRef(null);
  const newItemRef = useRef("");
  const toast = useToast();
  const [isloading, setLoading] = useState(true);
  const [isTableOpen, setIsTableOpen] = useState(true);
  const toggleTablesOpen = () => setIsTableOpen(!isTableOpen);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // New state for delete modal visibility
  const [editableTableName, setEditableTableName] = useState(table?.tablename);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState(null);
  const [users, setUsers] = useState([]);
  const [editableItemNames, setEditableItemNames] = useState({});

  useEffect(() => {
    const names = {};
    items.forEach((item) => {
      names[item?._id] = item?.name;
    });
    setEditableItemNames(names);
  }, [items]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_ENDPOINT}api/users`);
        const allUsers = response.data.data;
        setUsers(allUsers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [userId]);

  const deleteTable = async () => {
    try {
      await axios.delete(`${API_ENDPOINT}api/tasktable/${tableId}`);
      toast({
        title: "Table Deleted",
        description: "The table has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteModalOpen(false); // Close the modal after successful deletion
      fetchTables(); // Refresh the list of tables
    } catch (error) {
      console.error("Error deleting task table:", error);
      toast({
        title: "Error Deleting Table",
        description: "There was an error deleting the table. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddNewItem = async () => {
    const newItemName = newItemRef.current.value.trim();
    if (newItemName === "") {
      toast({
        title: "Error",
        description: "The item name cannot be empty.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const newItem = { name: newItemName }; // Add additional fields as needed

    try {
      await axios.post(`${API_ENDPOINT}api/tasktable/${tableId}/additem`, {
        itemsToAdd: [newItem], // The backend expects an array
      });
      toast({
        title: "Item Added",
        description: "The new item was successfully added.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      newItemRef.current.value = "";
      fetchTables();
    } catch (error) {
      console.error("Error adding new item:", error);
      toast({
        title: "Error",
        description: "There was an error adding the item. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchItems = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/tasktable/${tableId}/items`
      );
      if (response.data && response.data.items) {
        setItems(response.data.items);
      }
    } catch (error) {
      console.error("Error fetching items:", error);
      toast({
        title: "Error",
        description: "Unable to fetch items.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchItems();
  }, [table._id]);

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "In Progress":
        return colorMode === "light"
          ? "rgba(254, 243, 39, 0.76)"
          : "rgba(249, 185, 89, 0.44)";
      case "Done":
        return colorMode === "light"
          ? "rgba(181, 246, 211, 1)"
          : "rgba(67, 135, 112, 1)";
      default:
        return "transparent"; // Default case if no status matches
    }
  };

  const getPriorityBackgroundColor = (type) => {
    switch (type) {
      case "High":
        return colorMode === "light" ? "#FC8181" : "#C53030";
      case "Medium":
        return colorMode === "light" ? "#FBD38D" : "#DD6B20";
      case "Low":
        return colorMode === "light"
          ? "rgba(0, 144, 255, 0.2)"
          : "rgba(0, 144, 255, 0.2)";
      case "Post":
        return colorMode === "light"
          ? "rgba(238, 180, 128, 1)"
          : "rgba(238, 180, 128, 1)";
      default:
        return "transparent"; // Default case if no type matches
    }
  };

  const getLabelBackgroundColor = (type) => {
    switch (type) {
      case "Distribution":
        return colorMode === "light"
          ? "rgba(254, 243, 39, 0.76)"
          : "rgba(245, 218, 78, 1)";
      case "Publishing":
        return colorMode === "light"
          ? "rgba(181, 246, 211, 1)"
          : "rgba(67, 135, 112, 1)";
      case "Social Media":
        return colorMode === "light"
          ? "rgba(181, 246, 211, 1)"
          : "rgba(67, 135, 112, 1)";
      case "Licensing":
        return colorMode === "light"
          ? "rgba(251, 170, 74, 1)"
          : "rgba(251, 170, 74, 1)";
      default:
        return "transparent"; // Default case if no type matches
    }
  };

  const updateItemStatus = async (itemIndex, newStatus) => {
    const item = items[itemIndex];
    if (!item || !item._id) {
      toast({
        title: "Error",
        description: "Item ID not found.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.put(
        `${API_ENDPOINT}api/tasktable/${tableId}/item/${item._id}/status`,
        { newStatus } // Send the new status as part of the request body
      );

      toast({
        title: "Status Updated",
        description: "The item's status was successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      const updatedItems = items.map((itm, idx) =>
        idx === itemIndex ? { ...itm, status: newStatus } : itm
      );
      setItems(updatedItems);
    } catch (error) {
      console.error("Error updating item status:", error);
      toast({
        title: "Error",
        description: "Failed to update the item's status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateItemPriority = async (itemIndex, newPriority) => {
    const item = items[itemIndex];
    if (!item || !item._id) {
      toast({
        title: "Error",
        description: "Item ID not found.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.put(
        `${API_ENDPOINT}api/tasktable/${tableId}/item/${item._id}/priority`,
        { newPriority } // Send the new type as part of the request body
      );

      toast({
        title: "Type Updated",
        description: "The item's type was successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Update the local state to reflect the change
      const updatedItems = items.map((itm, idx) =>
        idx === itemIndex ? { ...itm, priority: newPriority } : itm
      );
      setItems(updatedItems);
    } catch (error) {
      console.error("Error updating item type:", error);
      toast({
        title: "Error",
        description: "Failed to update the item's type.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateItemLabel = async (itemIndex, newLabel) => {
    const item = items[itemIndex];
    if (!item || !item._id) {
      toast({
        title: "Error",
        description: "Item ID not found.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.put(
        `${API_ENDPOINT}api/tasktable/${tableId}/item/${item._id}/label`,
        { newLabel } // Send the new type as part of the request body
      );

      toast({
        title: "Type Updated",
        description: "The item's type was successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Update the local state to reflect the change
      const updatedItems = items.map((itm, idx) =>
        idx === itemIndex ? { ...itm, label: newLabel } : itm
      );
      setItems(updatedItems);
    } catch (error) {
      console.error("Error updating item type:", error);
      toast({
        title: "Error",
        description: "Failed to update the item's type.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteItem = async (itemIndex) => {
    const itemToDelete = items[itemIndex];

    if (itemToDelete && itemToDelete._id) {
      try {
        await axios.delete(
          `${API_ENDPOINT}api/tasktable/${tableId}/item/${itemToDelete._id}`
        );

        toast({
          title: "Item Deleted",
          description: "The item was successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        setItems((prevItems) =>
          prevItems.filter((_, index) => index !== itemIndex)
        );
      } catch (error) {
        console.error("Error deleting item:", error);
        toast({
          title: "Error",
          description: "There was an error deleting the item.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      console.error("Error: Item or item ID not found.");
      toast({
        title: "Error",
        description: "Item or item ID not found.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [customStatuses, setCustomStatuses] = useState([]);
  const [newStatusInput, setNewStatusInput] = useState("");
  const [customPriorities, setCustomPriorities] = useState([]);
  const [newPriorityInput, setNewPriorityInput] = useState("");

  const [customLabels, setCustomLabels] = useState([]);
  const [newLabelInput, setNewLabelInput] = useState("");

  const handleAddStatus = () => {
    if (
      newStatusInput.trim() &&
      !customStatuses.includes(newStatusInput.trim())
    ) {
      setCustomStatuses((prev) => [...prev, newStatusInput.trim()]);
      setNewStatusInput(""); // Clear the input field
    }
  };

  const handleAddPriority = () => {
    if (
      newPriorityInput.trim() &&
      !customPriorities.includes(newPriorityInput.trim())
    ) {
      setCustomPriorities((prev) => [...prev, newPriorityInput.trim()]);
      setNewPriorityInput(""); // Clear the input field
    }
  };

  const handleAddLabel = () => {
    if (newLabelInput.trim() && !customLabels.includes(newLabelInput.trim())) {
      setCustomLabels((prev) => [...prev, newLabelInput.trim()]);
      setNewLabelInput(""); // Clear the input field
    }
  };

  return (
    <Box
      className={`mobileplanning-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        p={2}
        _hover={{ opacity: "80%", bg: "rgba(150, 158, 167, 0.12)" }}
        fontSize={"14px"}
        color={"rgba(150, 158, 167, 1)"}
        alignItems={"center"}
        justifyContent={"space-between"}
        cursor={"pointer"} // Add cursor style to indicate clickability
        onClick={toggleTablesOpen} // Step 4: Attach the onClick handler
        borderRadius={"7px"}
      >
        <Flex alignItems={"center"} gap={"0px"}>
          <Icon
            mt={1}
            fontSize={"25px"}
            color={
              colorMode === "light"
                ? "rgba(91, 157, 249, 1)"
                : "rgba(91, 157, 249, 1)"
            }
            as={isTableOpen ? FiChevronDown : FiChevronRight}
          />{" "}
          <Input
            onClick={(e) => e.stopPropagation()} // Prevent triggering parent's onClick
            value={editableTableName}
            onChange={(e) => setEditableTableName(e.target.value)} // Update local state on change
            // onBlur={handleChangeTableName} // Save the new name on blur
            borderRadius={"6px"}
            className={`mobileinputsyles ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
            border={"none"}
            size={"sm"}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default MobilePlanningTaskItem;
