import React, { useState, useEffect } from "react";
import {
  Box,
  Input,
  Text,
  useToast,
  useColorModeValue,
  Button,
  Flex,
  IconButton,
  Tooltip,
  Avatar,
  Icon,
} from "@chakra-ui/react";
import "./AddArtistModal";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { BiUserPlus } from "react-icons/bi";

const AddArtistModal = ({
  colorMode,
  release,
  fetchReleases,
  releaseId,
  userId,
  onClose,
  existingArtists,
}) => {
  const toast = useToast();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const inputBg = useColorModeValue("gray.50", "gray.700");

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_ENDPOINT}api/users`);
      const usersData = response.data.data;
      const filteredUsers = usersData.filter((user) => user._id !== userId);
      setUsers(filteredUsers);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleEmailSearch = () => {
    const matchedUsers = users.filter(
      (user) => user.email.toLowerCase() === searchTerm.toLowerCase()
    );
    setSearchResults(matchedUsers);
    setHasSearched(true);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    const matchedUser = users.find(
      (user) => user.name.toLowerCase() === value.toLowerCase()
    );

    if (!matchedUser) {
      setSelectedUser(null);
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    setSelectedUser(null);
    setSearchResults([]);
    setHasSearched(false);
  };

  const handleGrantAccess = async (user) => {
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/release/${releaseId}/addArtist`,
        {
          artistName: user.name,
          artistId: user._id,
        }
      );

      if (response.data.status === "success") {
        toast({
          title: "Artist added successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        fetchReleases();
        onClose();
      } else {
        toast({
          title: "Error",
          description: response.data.message || "Failed to add artist.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error adding artist:", error);
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to add artist.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <div>
      <Flex
        className="GrantAccesstoAssets"
        flexDirection={"column"}
        gap={"20px"}
      >
        <Flex alignItems={"center"} gap={"10px"}>
          <Icon className="granticon" as={BiUserPlus} />
          <Text className="accessheader">Add Artist to Release</Text>
        </Flex>
        <Flex flexDirection={"column"} gap={"10px"}>
          {existingArtists.length > 0 && (
            <Flex flexDirection={"column"} gap={"10px"}>
              <Text>Existing Artists:</Text>
              {existingArtists.map((artist) => (
                <Flex key={artist.artistId} alignItems={"center"} gap={"10px"}>
                  <Avatar size={"sm"} name={artist.artistName} />
                  <Text>{artist.artistName}</Text>
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
        <Flex className="middleofmodal">
          {!hasSearched && (
            <Flex w={"100%"} flexDirection={"column"} gap={"10px"}>
              <Text className="assetstable-enterdetails">
                Search artists below
              </Text>
              <Input
                placeholder="Search by email..."
                bg={inputBg}
                borderColor={borderColor}
                value={searchTerm}
                onChange={handleSearchChange}
                boxShadow={"md"}
                size={"sm"}
                borderRadius={"5px"}
                w={"100%"}
              />
            </Flex>
          )}
          {hasSearched &&
            (searchResults.length > 0 ? (
              searchResults.map((user) => (
                <Flex w={"100%"} flexDirection={"column"} gap={"10px"}>
                  <Text className="assetstable-enterdetails">
                    Search results for {searchTerm}
                  </Text>
                  <Flex
                    className={`assets-table-searched-user-result ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                    key={user._id}
                  >
                    <Flex alignItems={"center"} gap={"10px"}>
                      <Avatar size={"sm"} name={user.name} />
                      <Text className="usersname">{user.name}</Text>
                    </Flex>

                    <Flex alignItems={"center"} gap={"10px"}>
                      <Tooltip
                        placement="top"
                        borderRadius={"7px"}
                        label={"Grant Access"}
                      >
                        <Button
                          onClick={() => handleGrantAccess(user)}
                          isLoading={loading}
                          size={"xs"}
                          colorScheme="cyan"
                          variant={"outline"}
                        >
                          Grant User
                        </Button>
                      </Tooltip>
                    </Flex>
                  </Flex>
                </Flex>
              ))
            ) : (
              <Text>No user with the email "{searchTerm}" found</Text>
            ))}
        </Flex>
        <Flex>
          <Flex
            alignItems="center"
            height="100%"
            zIndex={"9999"}
            gap={"20px"}
            w={"100%"}
          >
            <Button
              fontSize={"sm"}
              size={"sm"}
              w={"100%"}
              colorScheme="red"
              onClick={handleClear}
            >
              Cancel
            </Button>
            <Button
              fontSize={"sm"}
              size={"sm"}
              w={"100%"}
              colorScheme="green"
              onClick={handleEmailSearch}
            >
              Search
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default AddArtistModal;
