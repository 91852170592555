import React, { useState, useRef } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Input,
  Skeleton,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  Tooltip,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import { BsDownload, BsImage, BsTrash } from "react-icons/bs";
import { API_ENDPOINT } from "../../../../Config/config";
import "./MainArtwork.css";
import { useArtworksPanel } from "../../../../Context/ArtworkPanelContext";
import { useCurrentArtwork } from "../../../../Context/CurrentArtworkContext";
import CurrentArtworkStatus from "../../../DetailsPanel/ArtworkPanel/CurrentArtworkStatus";
import ArtworkDrawer from "./ArtworkDrawer";

const MainArtwork = ({
  artworkAsset,
  userName,
  userId,
  colorMode,
  board,
  boardId,
  mutualSpaceId,
  tableId,
  fetchArtworkAssets,
  openDetailsPanelSettings,
  onCheckboxChange,
  guestToken,
}) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const { artworksPanel, setArtworksPanel } = useArtworksPanel();
  const { currentArtwork, setCurrentArtwork } = useCurrentArtwork();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const handleDeleteArtwork = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/artwork/${artworkAsset._id}`
      );
      if (response.status === 200) {
        toast({
          title: "Artwork deleted.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        fetchArtworkAssets();
        setDeleteModalOpen(false);
      }
    } catch (error) {
      console.error("Error deleting artwork:", error);
      toast({
        title: "Error",
        description: "There was an error deleting the artwork.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadArtwork = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/artwork/${artworkAsset._id}/download`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${artworkAsset.title}.jpg`); // Modify as needed
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      toast({
        title: "Download started.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-left",
      });
    } catch (error) {
      console.error("Error downloading artwork:", error);
      toast({
        title: "Error",
        description: "There was an error downloading the artwork.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const toLArtworksPanel = (event) => {
    event.stopPropagation();
    setArtworksPanel("Artworks");

    const enhancedArtworkAsset = {
      ...artworkAsset,
      mutualSpaceId,
      boardId,
      tableId,
      fetchArtworkAssets,
    };
    setCurrentArtwork(enhancedArtworkAsset);
    openDetailsPanelSettings();
  };

  return (
    <Box
      onClick={onDrawerOpen}
      className={`mainartwork-conainter ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        w={"100%"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={"10px"}
      >
        <Flex w={"100%"}>
          <CurrentArtworkStatus
            currentArtwork={artworkAsset}
            artworkId={artworkAsset._id}
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            mutualSpaceId={mutualSpaceId}
            tableId={tableId}
            boardId={boardId}
            openDetailsPanelSettings={openDetailsPanelSettings}
            fetchArtworkAssets={fetchArtworkAssets} // Pass the prop here
            guestToken={guestToken}
          />
        </Flex>
        <Flex alignItems={"center"} gap={"10px"}>
          <IconButton
            isDisabled={!!guestToken}
            icon={<BsDownload />}
            size={"xs"}
            colorScheme="cyan"
            variant={"outline"}
            onClick={handleDownloadArtwork}
          />
          <IconButton
            isDisabled={!!guestToken}
            onClick={() => setDeleteModalOpen(true)}
            icon={<BsTrash />}
            size={"xs"}
            colorScheme="red"
            variant={"outline"}
            isLoading={isLoading}
          />
          <Tooltip placement="top" borderRadius={"7px"} label={"Bulk Delete"}>
            <Checkbox
              isDisabled={!!guestToken}
              size="lg"
              colorScheme="red"
              onChange={(e) => {
                onCheckboxChange(artworkAsset, e.target.checked);
              }}
            />
          </Tooltip>
        </Flex>
      </Flex>

      <Flex className="asset-art-surround">
        <Image className="artworkAsset-img" src={artworkAsset.imageUrl} />
      </Flex>

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Artwork</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete the Artwork?</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              onClick={handleDeleteArtwork}
              colorScheme="red"
            >
              Delete Artwork
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        size="sm"
        onClose={onDrawerClose}
      >
        <DrawerOverlay />
        <DrawerContent
          p={1}
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <DrawerCloseButton />
          <DrawerHeader>Details</DrawerHeader>
          <DrawerBody>
            <ArtworkDrawer
              tableId={tableId}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              artworkAsset={artworkAsset}
              colorMode={colorMode}
              artworkId={artworkAsset._id}
              fetchArtworkAssets={fetchArtworkAssets}
              userId={userId}
              userName={userName}
              guestToken={guestToken}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MainArtwork;
