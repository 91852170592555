import { Box, Text, Image, Flex } from "@chakra-ui/react";
import React from "react";
import "./IndividualFolderItem.css";
import { useNavigate } from "react-router-dom";
import clipfolderImg from "../../../Images/foldericon.png";

const IndividualFolderItem = ({ folder, colorMode, folderId }) => {
  const navigate = useNavigate();

  const handleFolderClick = () => {
    navigate(`/folder/${folderId}`);
  };

  return (
    <Flex flexDirection={"column"} onClick={handleFolderClick}>
      <Flex
        className={`IndividFolders-container ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        bg={colorMode === "light" ? "gray.50" : "gray.800"}
        border={
          colorMode === "light"
            ? ".5px solid rgba(187, 186, 187, 1)"
            : "1px solid rgba(128, 128, 128, 1)"
        }
      >
        <Box p={3} mt={2} className="clipfoldercontainer">
          <Image className="clipfoldimg" src={clipfolderImg} />
        </Box>
        <Flex
          w={"100%"}
          p={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text className="folderonename">{folder?.folderName}</Text>
          <Text className="folderfilesnumber">
            {folder?.files.length} Files
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default IndividualFolderItem;
