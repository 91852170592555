import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Text,
  Center,
  VStack,
  HStack,
  Progress,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  RadioGroup,
  Radio,
  useToast,
} from "@chakra-ui/react";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";
import "./ReleaseBuilding.css";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const steps = [
  { label: "Step 1", content: "Step 1 Content" },
  { label: "Step 2", content: "Step 2 Content" },
  { label: "Step 3", content: "Step 3 Content" },
  { label: "Step 4", content: "Step 4 Content" },
  { label: "Step 5", content: "Step 5 Content" },
  { label: "Step 6", content: "Step 6 Content" },
  { label: "Step 7", content: "Step 7 Content" },
];

const ReleaseBuilding = ({
  userId,
  userName,
  setIsNewModalOpen,
  fetchReleases,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [releaseName, setReleaseName] = useState("");
  const [releaseType, setReleaseType] = useState("");
  const [selectedLyrics, setSelectedLyrics] = useState([]);
  const [selectedArtwork, setSelectedArtwork] = useState([]);
  const [selectedRecordings, setSelectedRecordings] = useState([]);
  const [schedule, setSchedule] = useState("");
  const toast = useToast();
  const [isSavingRelease, setIsSavingRelease] = useState(false); // State to manage delete loading

  const nextStep = () => {
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const saveRelease = async () => {
    setIsSavingRelease(true);

    try {
      const response = await axios.post(`${API_ENDPOINT}api/release/save`, {
        userId,
        name: userName,
        releaseName,
        releaseType,
        selectedLyrics,
        selectedArtwork,
        selectedRecordings,
        schedule,
      });

      if (response.status === 200) {
        toast({
          title: "Release saved!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
        setIsNewModalOpen(false);
      }
      fetchReleases();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to save release",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSavingRelease(false);
    }
  };

  return (
    <Flex flexDirection="column" w="100%" h={"100%"} p={4}>
      <Progress value={(currentStep / (steps.length - 1)) * 100} mb={4} />
      <HStack spacing={4} justifyContent="center" mb={4}>
        {steps.map((step, index) => (
          <Flex
            key={index}
            w={8}
            h={8}
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            bg={currentStep === index ? "blue.500" : "gray.200"}
            color={currentStep === index ? "white" : "black"}
          >
            {index + 1}
          </Flex>
        ))}
      </HStack>
      <Box mb={4}>
        <Text>{steps[currentStep].content}</Text>
        {currentStep === 0 && (
          <FormControl mb={4}>
            <FormLabel>Release Name</FormLabel>
            <Input
              value={releaseName}
              onChange={(e) => setReleaseName(e.target.value)}
              placeholder="Enter Release Name"
            />
          </FormControl>
        )}
        {currentStep === 1 && (
          <FormControl mb={4}>
            <FormLabel>Release Type</FormLabel>
            <RadioGroup
              value={releaseType}
              onChange={(value) => setReleaseType(value)}
            >
              <HStack spacing={4}>
                <Radio value="single">Single</Radio>
                <Radio value="album">Album</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
        )}
        {currentStep === 2 && (
          <FormControl mb={4}>
            <FormLabel>Select Lyrics</FormLabel>
          </FormControl>
        )}
        {currentStep === 3 && (
          <FormControl mb={4}>
            <FormLabel>Select Artwork</FormLabel>
          </FormControl>
        )}
        {currentStep === 4 && (
          <FormControl mb={4}>
            <FormLabel>Select Recordings</FormLabel>
          </FormControl>
        )}
        {currentStep === 5 && (
          <FormControl mb={4}>
            <FormLabel>Schedule</FormLabel>
          </FormControl>
        )}
        {currentStep === 6 && (
          <FormControl mb={4}>
            <FormLabel>Final Review</FormLabel>
          </FormControl>
        )}
      </Box>
      <Flex justifyContent="space-between">
        <Button
          onClick={prevStep}
          isDisabled={currentStep === 0}
          leftIcon={<ArrowBackIcon />}
        >
          Previous
        </Button>
        {currentStep === steps.length - 1 ? (
          <Button
            onClick={saveRelease}
            isLoading={isSavingRelease}
            colorScheme="teal"
            rightIcon={<ArrowForwardIcon />}
          >
            Save
          </Button>
        ) : (
          <Button
            onClick={nextStep}
            isDisabled={currentStep === steps.length - 1}
            rightIcon={<ArrowForwardIcon />}
          >
            Next
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default ReleaseBuilding;
