import React, { useState, useEffect } from "react";
import "./CurrentBeatComments.css";
import { Flex, useToast } from "@chakra-ui/react";
import CommentSendInput from "./CommentSendInput";
import AllBeatComments from "./AllBeatComments";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const CurrentBeatComments = ({
  userId,
  beatAsset,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  beatId,
  guestToken,
}) => {
  const [comments, setComments] = useState([]);
  const toast = useToast();

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/beat/${beatId}/comments`
      );
      if (response.status === 200) {
        setComments(response.data.comments || []); // Ensures comments is always an array
      } else {
        throw new Error("Failed to fetch comments due to server error");
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "Unable to fetch comments.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchComments();
  }, [mutualSpaceId, boardId, tableId, beatId, toast]);

  return (
    <Flex className="CurrentBeatComments-main">
      <Flex>
        <AllBeatComments
          userId={userId}
          beatAsset={beatAsset}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          beatId={beatId}
          comments={comments}
        />
      </Flex>
      <Flex>
        <CommentSendInput
          userId={userId}
          beatAsset={beatAsset}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          beatId={beatId}
          fetchComments={fetchComments}
          guestToken={guestToken}
        />
      </Flex>
    </Flex>
  );
};

export default CurrentBeatComments;
