import {
  Box,
  Flex,
  Input,
  Text,
  MenuButton,
  Menu,
  MenuList,
  Icon,
  Button,
  Tooltip,
  MenuItem,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Badge,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsLink } from "react-icons/bs";
import {
  FaMicrophone,
  FaChevronRight,
  FaComment,
  FaCircleCheck,
} from "react-icons/fa6";
import "./LyricsPanel.css";
import { TbMicrophone2 } from "react-icons/tb";
import { GrInProgress } from "react-icons/gr";
import { GiMusicalNotes } from "react-icons/gi";
import { API_ENDPOINT } from "../../../Config/config";
import axios from "axios";
import { MdOutlineLyrics } from "react-icons/md";
import { VscReferences } from "react-icons/vsc";
import LyricComments from "../../Comment/LyricComments";

const MobileLyricsPanel = ({
  colorMode,
  currentLyric,
  lyricId,
  userName,
  userId,
  guestToken,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState("comments"); // Default to showing comments
  const [songwriter, setSongwriter] = useState(currentLyric?.songwriter || "");
  const [artist, setArtist] = useState(currentLyric?.artist || "");
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);
  const [pendingStatus, setPendingStatus] = useState("");
  const [setIsSaving] = useState(false); // State to track if save is in progress
  const [setStatus] = useState(""); // To track the current status

  const handleSongwriterBlur = async () => {
    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/lyrics/${currentLyric._id}/songwriter`,
        {
          songwriter,
        }
      );
      console.log(response.data.message);
    } catch (error) {
      console.error("Error updating songwriter", error);
    }
  };

  const handleArtistBlur = async () => {
    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/lyrics/${currentLyric._id}/artist`,
        {
          artist,
        }
      );
      console.log(response.data.message);
    } catch (error) {
      console.error("Error updating artist", error);
    }
  };

  const [lyricStatus, setLyricStatus] = useState(
    currentLyric?.status || "Set Status"
  );

  useEffect(() => {
    setSongwriter(currentLyric?.songwriter || "");
    setArtist(currentLyric?.artist || "");
    setLyricStatus(currentLyric?.status || "");
  }, [currentLyric]);

  const getBackgroundColor = () => {
    switch (currentLyric?.status) {
      case "Draft":
        return colorMode === "light"
          ? "rgba(242, 60, 73, 1)" // Light mode color for Draft
          : "rgba(242, 60, 73, 0.84)"; // Dark mode color for Draft
      case "Recorded":
        return colorMode === "light"
          ? "rgba(26, 213, 152, 0.9)" // Light mode color for Recorded
          : "rgba(26, 213, 152, 0.4)"; // Dark mode color for Recorded
      case "Reference":
        return colorMode === "light"
          ? "rgba(33, 126, 253, 1)" // Light mode color for Reference
          : "rgba(0, 144, 255, 0.4)"; // Dark mode color for Reference
      default:
        return colorMode === "light"
          ? "default-light-color" // Default color for light mode
          : "default-dark-color"; // Default color for dark mode
    }
  };

  const getColorColor = () => {
    switch (currentLyric?.status) {
      case "Draft":
      case "Reference":
        return colorMode === "light" ? "white" : "dark-text-color";
      case "Recorded":
        return colorMode === "light" ? "rgba(5, 15, 30, 1)" : "white";
      default:
        return colorMode === "light"
          ? "default-light-text-color"
          : "default-dark-text-color";
    }
  };

  const formatDate = (uploadDate) => {
    const uploadedAt = new Date(uploadDate);
    const now = new Date();
    const timeDiff = now.getTime() - uploadedAt.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    if (daysDiff < 1) {
      const hoursDiff = timeDiff / (1000 * 3600);
      if (hoursDiff < 1) {
        const minutesDiff = timeDiff / (1000 * 60);
        return `${Math.floor(minutesDiff)} minutes ago`;
      }
      return `${Math.floor(hoursDiff)} hours ago`;
    } else if (daysDiff < 10) {
      return `${Math.floor(daysDiff)} days ago`;
    } else {
      return `on ${uploadedAt.toLocaleString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      })} @ ${uploadedAt.toLocaleTimeString()}`;
    }
  };

  const handleSaveStatus = async () => {
    setIsSaving(true);
    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/lyrics/${lyricId}/status`,
        { status: pendingStatus }
      );
      if (response.status === 200) {
        console.log("Status updated successfully");
        setStatus(response.data.status); // Update the actual status
      }
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setIsSaving(false);
      setIsStatusChangeModalOpen(false); // Close the modal after handling the request
    }
  };

  const handleStatusMenuClick = (newStatus) => {
    setPendingStatus(newStatus);
    setIsStatusChangeModalOpen(true);
  };

  return (
    <>
      <Box className="beatsmobile-panel-container">
        <Box
          className={`beats-panel-topone ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Flex w={"100%"} flexDirection={"column"} gap={"2px"}>
            <Box
              onClick={onOpen}
              className={`Lyrics-section ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              {currentLyric?.lyricsText}
            </Box>
          </Flex>

          <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
            <Flex w={"100%"} flexDirection={"column"} gap={"5px"}>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon className={"panel-title-icon"} as={GiMusicalNotes} />
                <Text className="panel-title-text">Songwriter</Text>
              </Flex>
              <Tooltip
                placement="top"
                borderRadius={"7px"}
                label={"Producer Name"}
              >
                <Input
                  color={colorMode === "light" ? "black" : "white"}
                  value={songwriter}
                  onChange={(e) => setSongwriter(e.target.value)}
                  onBlur={handleSongwriterBlur}
                  bg={colorMode === "light" ? "white" : "rgba(26, 32, 44, 1)"}
                  w={"100%"}
                  borderRadius={"6px"}
                  size={"sm"}
                  placeholder="Songwriter"
                />
              </Tooltip>
            </Flex>

            <Flex w={"100%"} flexDirection={"column"} gap={"5px"}>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon className={"panel-title-icon"} as={GiMusicalNotes} />
                <Text className="panel-title-text">Artist</Text>
              </Flex>
              <Tooltip placement="top" borderRadius={"7px"} label={"Artist"}>
                <Input
                  color={colorMode === "light" ? "black" : "white"}
                  value={artist}
                  onChange={(e) => setArtist(e.target.value)}
                  onBlur={handleArtistBlur}
                  bg={colorMode === "light" ? "white" : "rgba(26, 32, 44, 1)"}
                  w={"100%"}
                  borderRadius={"6px"}
                  size={"sm"}
                  placeholder="Artist"
                />
              </Tooltip>
            </Flex>
          </Flex>

          <Flex flexDirection={"column"} gap={"5px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon className={"panel-title-icon"} as={GrInProgress} />
              <Text className="panel-title-text">Lyric Status</Text>
            </Flex>
            <Menu
              border={
                colorMode === "light"
                  ? "2px solid rgba(153, 178, 198, 0.23)"
                  : "2px solid rgba(43, 46, 54, 1)"
              }
              bg={colorMode === "light" ? "white" : "black"}
            >
              {({ isOpen }) => (
                <>
                  <Flex alignItems="center" gap={2}>
                    <Tooltip
                      placement="bottom"
                      borderRadius={"7px"}
                      label={"Set Status"}
                    >
                      <MenuButton
                        backgroundColor={getBackgroundColor()} // Apply conditional background color
                        color={getColorColor()}
                        w={"100%"}
                        size={"xs"}
                        as={Button}
                        rightIcon={
                          <FaChevronRight
                            style={{
                              transition: "transform 0.2s",
                              transform: isOpen ? "rotate(90deg)" : "none",
                            }}
                          />
                        }
                      >
                        {lyricStatus}
                      </MenuButton>
                    </Tooltip>
                  </Flex>
                  <MenuList
                    p={2}
                    bg={colorMode === "light" ? "white" : "black"}
                    zIndex={"9999"}
                  >
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(249, 185, 89, .23)"
                              : "rgba(249, 185, 89, .23)",
                        },
                      }}
                      bg={colorMode === "light" ? "white" : "black"}
                      className="blank"
                      onClick={() => handleStatusMenuClick("Recorded")}
                    >
                      <Icon as={FaMicrophone} fontSize="17px" />
                      <Text className="rager">Recorded</Text>
                    </MenuItem>
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                      onClick={() => handleStatusMenuClick("Reference")}
                    >
                      <Icon as={VscReferences} fontSize="17px" />

                      <Text className="rager">Reference</Text>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>

          <Flex flexDirection={"column"} gap={"5px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon className={"panel-title-icon"} as={BsLink} />
              <Text className="panel-title-text">Linked</Text>
            </Flex>
            <Flex
              padding={"1px"}
              borderRadius={"5px"}
              className="takethis"
              border={
                colorMode === "light"
                  ? ".5px solid rgba(48, 51, 77, 0.24)"
                  : ".5px solid rgba(48, 51, 77, 1)"
              }
              flexDirection={"column"}
              gap={"5px"}
              w={"100%"}
            >
              {/* Conditionally render linked items or a button if none are linked */}
              {currentLyric?.beat ||
              currentLyric?.recording ||
              currentLyric?.release ? (
                <>
                  {currentLyric?.beat && (
                    <Badge
                      w={"100%"}
                      colorScheme="yellow"
                      variant="subtle"
                      className="cardtyeuio"
                      _hover={{ opacity: "80%", cursor: "pointer" }}
                    >
                      <Flex alignItems={"center"} gap={"5px"}>
                        <Icon as={MdOutlineLyrics} />
                        <Text>Beat</Text>
                      </Flex>
                    </Badge>
                  )}

                  {currentLyric?.recording && (
                    <Badge
                      w={"100%"}
                      colorScheme="green"
                      variant="subtle"
                      className="cardtyeuio"
                      _hover={{ opacity: "80%", cursor: "pointer" }}
                    >
                      <Flex alignItems={"center"} gap={"5px"}>
                        <Icon as={TbMicrophone2} />
                        <Text>Recording</Text>
                      </Flex>
                    </Badge>
                  )}

                  {currentLyric?.release && (
                    <Badge
                      w={"100%"}
                      colorScheme="orange"
                      variant="subtle"
                      className="cardtyeuio"
                      _hover={{ opacity: "80%", cursor: "pointer" }}
                    >
                      <Flex alignItems={"center"} gap={"5px"}>
                        <Icon as={FaCircleCheck} />
                        <Text>Release</Text>
                      </Flex>
                    </Badge>
                  )}
                </>
              ) : (
                <Button colorScheme="blue">
                  <Flex alignItems={"center"} gap={"5px"}>
                    <Icon as={BsLink} />
                    <Text>Link Lyric</Text>
                  </Flex>
                </Button>
              )}
            </Flex>
          </Flex>

          <Flex alignItems={"center"} gap={"5px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Text className="panel-title-text">Uploaded By</Text>

              <Avatar name={currentLyric?.user.name} size={"xs"} />
              <Text className="panel-title-text">
                {formatDate(currentLyric?.uploadDate)}
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Box
          className={`beatspanel-bottomone ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Flex
            className={`toppedtabs ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
            alignItems={"center"}
            gap={"10px"}
          >
            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Beat Comments"}
            >
              <Button
                colorScheme={activeTab === "comments" ? "blue" : "gray"}
                size={"xs"}
                w={"100%"}
                onClick={() => setActiveTab("comments")}
              >
                <Flex gap={"5px"} alignItems={"center"}>
                  <Icon as={FaComment} />
                  <Text>Comments</Text>
                </Flex>
              </Button>
            </Tooltip>
          </Flex>
          <Box className="tabedcontent">
            {activeTab === "comments" && (
              <>
                <LyricComments
                  userId={userId}
                  userName={userName}
                  lyricId={currentLyric?._id}
                  colorMode={colorMode}
                  guestToken={guestToken}
                />{" "}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lyrics</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* You can place the actual lyrics or any content you want here */}
            <Text>Here are the lyrics...</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {/* Add any additional buttons here if needed */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        bg="black"
        isOpen={isStatusChangeModalOpen}
        onClose={() => setIsStatusChangeModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Change Status</ModalHeader>
          <ModalBody bg={colorMode === "light" ? "white" : "black"}>
            Are you sure you want to change the status to "{pendingStatus}"?
          </ModalBody>
          <ModalFooter>
            <Button
              w={"100%"}
              colorScheme="red"
              mr={3}
              onClick={() => setIsStatusChangeModalOpen(false)}
            >
              Cancel
            </Button>
            <Button w={"100%"} colorScheme="green" onClick={handleSaveStatus}>
              Change Status
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MobileLyricsPanel;
