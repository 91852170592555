import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  useToast,
  SimpleGrid,
  VStack,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const LinkBeatsModal = ({
  mutualSpaceId,
  boardId,
  tableId,
  recordingId,
  setIsLinkBeatsOpen,
  fetchRecordingData,
}) => {
  const toast = useToast();
  const [beatAssets, setBeatAssets] = useState([]);
  const [selectedBeatAsset, setSelectedBeatAsset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBeatAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/beats`
      );
      setBeatAssets(response.data.beats);
    } catch (error) {
      console.error("Failed to fetch beat assets:", error);
    }
  };

  useEffect(() => {
    fetchBeatAssets();
  }, []);

  const clearSelection = () => {
    setSelectedBeatAsset(null);
  };

  const linkBeatToRecording = async () => {
    if (!selectedBeatAsset) {
      toast({
        title: "Selection Error",
        description: "No beat selected to link.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/recording/${recordingId}/addBeat`,
        {
          beatAsset: selectedBeatAsset,
        }
      );
      toast({
        title: "Beat Linked Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setSelectedBeatAsset(null);
      setIsLinkBeatsOpen(false);

      fetchRecordingData();
    } catch (error) {
      toast({
        title: "Linking Failed",
        description:
          error.response?.data?.message || "Failed to link the beat.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <SimpleGrid columns={3} spacing={4}>
        {!selectedBeatAsset && (
          <>
            {beatAssets.map((asset) => (
              <Box
                key={asset._id}
                p={5}
                shadow="md"
                borderWidth="1px"
                cursor="pointer"
                onClick={() => setSelectedBeatAsset(asset)}
              >
                <Text mt={4}>{asset.title}</Text>
                <Text fontSize="sm">Uploaded by: {asset.user.name}</Text>
                <Text fontSize="sm">Status: {asset.status}</Text>
              </Box>
            ))}
          </>
        )}
      </SimpleGrid>
      {selectedBeatAsset && (
        <VStack spacing={4} align="stretch">
          <Text fontSize="xl" fontWeight="bold">
            {selectedBeatAsset.title}
          </Text>
          <Text>Uploaded by: {selectedBeatAsset.user.name}</Text>
          <Text>Status: {selectedBeatAsset.status}</Text>
          <HStack>
            <Button
              colorScheme="blue"
              mr={3}
              isLoading={isLoading}
              loadingText="Linking..."
              onClick={linkBeatToRecording}
            >
              Link Beat
            </Button>
            <Button size="sm" onClick={clearSelection}>
              Back to List
            </Button>
          </HStack>
        </VStack>
      )}
    </Box>
  );
};

export default LinkBeatsModal;
