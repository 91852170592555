import { createContext, useState, useContext } from "react";

const CurrentRecordingContext = createContext();

export const useCurrentRecording = () => useContext(CurrentRecordingContext);

export const CurrentRecordingProvider = ({ children }) => {
  const [currentRecording, setCurrentRecording] = useState(null);

  return (
    <CurrentRecordingContext.Provider
      value={{ currentRecording, setCurrentRecording }}
    >
      {children}
    </CurrentRecordingContext.Provider>
  );
};
