import React from "react";
import { Flex, Tag, Avatar, TagLabel } from "@chakra-ui/react";
import "./LinkedLyrics.css";
import { PiNotepadThin } from "react-icons/pi";

const LinkedLyrics = ({ lyric, colorMode }) => {
  return (
    <Flex alignItems={"center"} gap={"10px"}>
      <Tag size="sm" colorScheme="yellow" borderRadius="full">
        <Avatar
          bg={"none"}
          color={colorMode === "light" ? "gray" : "yellow"}
          size="xs"
          ml={-1}
          mr={1}
          icon={<PiNotepadThin fontSize={"20px"} />}
        />
        <TagLabel className="artworktag">{lyric.title}</TagLabel>
      </Tag>
    </Flex>
  );
};

export default LinkedLyrics;
