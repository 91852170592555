import React, { useState } from "react";
import "./IndividualAssetTable.css";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Avatar,
  Tooltip,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";
import BeatAssetUpload from "./BeatAssetUpload";
import LyricsAssetUpload from "./LyricsAssetUpload";
import RecordingAssetUpload from "./RecordingAssetUpload";
import ArtworkAssetUpload from "./ArtworkAssetUpload";
import BeatAssetsTable from "./BeatAssetsTable";
import LyricsAssetsTable from "./LyricsAssetsTable";
import RecordingAssetsTable from "./RecordingAssetsTable";
import ArtworkAssetsTable from "./ArtworkAssetsTable";
import { FaUserPlus } from "react-icons/fa6";
import GrantAccesstoAssets from "./GrantAccesstoAssets";
import { SearchIcon } from "@chakra-ui/icons";

const IndividualAssetTable = ({
  selectedTableData,
  isTableDataLoading,
  colorMode,
  userName,
  userId,
  openDetailsPanelSettings,
  guestToken,
  selectedTableId,
  isMobileView,
}) => {
  const [isUserAccessModalOpen, setIsUserAccessModalOpen] = useState(false);

  const [isNewAssetModalOpen, setIsNewAssetModalOpen] = useState(false);
  const [editableAssetTableName, setEditableAssetTableName] = useState(
    selectedTableData?.tableName
  );

  const inputBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const boxShadow = useColorModeValue("sm", "dark-lg");

  const buttonText = {
    "Instrumental / Beat": "Audio File",
    Recording: "Audio File",
    Lyrics: "Lyrics",
    Artwork: "Artwork",
  };

  const currentButtonText = buttonText[selectedTableData?.tableType] || "Add";

  const renderUploadComponent = (tableType) => {
    switch (tableType) {
      case "Instrumental / Beat":
        return (
          <BeatAssetUpload
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            selectedTableDataId={selectedTableData._id}
          />
        );
      case "Lyrics":
        return (
          <LyricsAssetUpload
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            selectedTableDataId={selectedTableData._id}
            isMobileView={isMobileView}
          />
        );
      case "Recording":
        return (
          <RecordingAssetUpload
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            selectedTableDataId={selectedTableData._id}
            isMobileView={isMobileView}
          />
        );
      case "Artwork":
        return (
          <ArtworkAssetUpload
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            selectedTableDataId={selectedTableData._id}
            isMobileView={isMobileView}
          />
        );
      default:
        return null;
    }
  };

  const renderAssetTable = (tableType) => {
    switch (tableType) {
      case "Instrumental / Beat":
        return (
          <BeatAssetsTable
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            selectedTableDataId={selectedTableData._id}
            openDetailsPanelSettings={openDetailsPanelSettings}
            guestToken={guestToken}
            isMobileView={isMobileView}
          />
        );
      case "Lyrics":
        return (
          <LyricsAssetsTable
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            selectedTableDataId={selectedTableData._id}
            guestToken={guestToken}
            openDetailsPanelSettings={openDetailsPanelSettings}
            isMobileView={isMobileView}
          />
        );
      case "Recording":
        return (
          <RecordingAssetsTable
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            selectedTableDataId={selectedTableData._id}
            guestToken={guestToken}
            openDetailsPanelSettings={openDetailsPanelSettings}
          />
        );
      case "Artwork":
        return (
          <ArtworkAssetsTable
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            selectedTableDataId={selectedTableData._id}
            guestToken={guestToken}
            openDetailsPanelSettings={openDetailsPanelSettings}
            isMobileView={isMobileView}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Flex gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>
        <Flex w={"100%"} gap={"10px"} alignItems={"center"}>
          <Input
            w={"30%"}
            borderRadius={"5px"}
            size={"sm"}
            value={editableAssetTableName}
            bg={inputBg}
            borderColor={borderColor}
            onClick={(e) => {
              e.stopPropagation();
              // setDeleteModalOpen(true);
            }}
          />
          <Flex w={"65%"}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={SearchIcon} color="gray.500" />}
              />
              <Input
                size={"sm"}
                borderRadius={"7px"}
                w={"100%"}
                placeholder="Search Beats"
                bg={inputBg}
                borderColor={borderColor}
                _hover={{
                  bg: hoverBg,
                  borderColor: "gray.400",
                }}
                _focus={{
                  boxShadow: `${boxShadow}`,
                  borderColor: "gray.400",
                }}
              />
            </InputGroup>
          </Flex>
        </Flex>
        <Flex alignItems={"center"} gap={"10px"}>
          {selectedTableData.usersWithAccess &&
          selectedTableData.usersWithAccess.length > 0 ? (
            selectedTableData.usersWithAccess.map((user) => (
              <Tooltip
                placement="top"
                key={user.userId}
                label={user.userName}
                borderRadius={"7px"}
              >
                <Avatar
                  _hover={{
                    transform: "scale(1.05)",
                    boxShadow: "lg",
                  }}
                  _active={{
                    transform: "scale(0.95)",
                  }}
                  ml={-5}
                  size={"sm"}
                  name={user.userName}
                />
              </Tooltip>
            ))
          ) : (
            <Text></Text>
          )}

          <Button
            onClick={(e) => {
              e.stopPropagation();
              setIsUserAccessModalOpen(true);
            }}
            variant={"outline"}
            size={"sm"}
            fontSize={"13px"}
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={FaUserPlus} />
              <Text>Access</Text>
            </Flex>
          </Button>

          <Button
            onClick={() => setIsNewAssetModalOpen(true)}
            size={"sm"}
            fontSize={"13px"}
            variant={"outline"}
            mr={1}
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={BsPlusLg} />
              <Text>{currentButtonText}</Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      <Box className="maxss">
        {renderAssetTable(selectedTableData?.tableType)}
      </Box>
      <Modal
        isOpen={isNewAssetModalOpen}
        onClose={() => setIsNewAssetModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Asset Upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {renderUploadComponent(selectedTableData?.tableType)}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isUserAccessModalOpen}
        onClose={() => setIsUserAccessModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalCloseButton />
          <ModalBody>
            <GrantAccesstoAssets
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              selectedTableDataId={selectedTableData._id}
              selectedTableDataName={selectedTableData.tableName}
              setIsUserAccessModalOpen={setIsUserAccessModalOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default IndividualAssetTable;
