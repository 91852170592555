import { createContext, useState, useContext } from "react";

// Create the context
const LinkArtworkContext = createContext();

// Custom hook for easy context usage
export const useLinkArtwork = () => useContext(LinkArtworkContext);

// Provider component
export const LinkArtworkProvider = ({ children }) => {
  const [linkArtwork, setLinkArtwork] = useState(null); // New state and setter function

  return (
    <LinkArtworkContext.Provider value={{ linkArtwork, setLinkArtwork }}>
      {children}
    </LinkArtworkContext.Provider>
  );
};
