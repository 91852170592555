import React from "react";
import { Box } from "@chakra-ui/react";
import "./MobileMessageTable.css";
import { useDirectMessageDisplay } from "../../../Context/DirectMessageDisplayContext";
import MobileChannelMessages from "./MobileChannelMessages";
import MobileDirectMessages from "./MobileDirectMessages";

const MobileMessageTable = ({
  colorMode,
  userName,
  userId,
  isMobileView,
  selectedChannel,
  selectedDirectMessage,
  refreshSelectedChannel,
}) => {
  const { directMessages } = useDirectMessageDisplay();

  return (
    <Box className="MobileMessageTable">
      {!directMessages && (
        <MobileChannelMessages
          userName={userName}
          userId={userId}
          isMobileView={isMobileView}
          colorMode={colorMode}
          selectedChannel={selectedChannel}
          refreshSelectedChannel={refreshSelectedChannel}
        />
      )}
      {selectedDirectMessage && (
        <>
          <MobileDirectMessages
            userName={userName}
            userId={userId}
            isMobileView={isMobileView}
            colorMode={colorMode}
            selectedDirectMessage={selectedDirectMessage}
          />
        </>
      )}
    </Box>
  );
};

export default MobileMessageTable;
