import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Badge,
} from "@chakra-ui/react";
import {
  BsPlayCircle,
  BsPauseCircle,
  BsCheck2All,
  BsPlus,
  BsHeadphones,
} from "react-icons/bs";
import { DownloadIcon, LinkIcon } from "@chakra-ui/icons";
import { FaTrashCan } from "react-icons/fa6";
import "./AssetRecordingPlayer.css";
import { API_ENDPOINT } from "../../Config/config";
import { MusicPlayerContext } from "../../Context/MusicPlayerContext";
import { useLinkRecording } from "../../Context/LinkRecordingContext";
import { useRecordingsPanel } from "../../Context/RecordingsPanelContext";
import { useCurrentRecording } from "../../Context/CurrentRecordingContext";
import { useAddRecordingToRelease } from "../../Context/AddRecordingToReleaseContext";
import { MdOutlineLyrics } from "react-icons/md";

const AssetRecordingPlayer = ({
  recordingAsset,
  userName,
  userId,
  colorMode,
  selectedTableDataId,
  openDetailsPanelSettings,
  guestToken,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const toast = useToast();
  const [isDeleting, setIsDeleting] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleInnerClick = (event) => {
    event.stopPropagation();
  };
  const { setLinkRecording } = useLinkRecording();
  const { setRecordingsPanel } = useRecordingsPanel();
  const { setCurrentRecording } = useCurrentRecording();
  const { setAddRecording } = useAddRecordingToRelease();

  const { playTrack, stopTrack, currentTrack, seekTrack } =
    useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [localDuration, setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack &&
    currentTrack.src === recordingAsset.fileUrl &&
    !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(recordingAsset.fileUrl);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const AddRecordingToRelease = (event) => {
    event.stopPropagation();

    setAddRecording("AddRecordingToRelease");
    setLinkRecording(null);
    setRecordingsPanel(null);
  };

  const handleRecordingClick = (event) => {
    event.stopPropagation();
    setRecordingsPanel("Recordings");
    openDetailsPanelSettings();
    setCurrentRecording(recordingAsset);
    setLinkRecording(null);
    setAddRecording(null);
  };

  const toLinkRecording = (event) => {
    event.stopPropagation();

    setLinkRecording("LinkRecording");
    setRecordingsPanel(null);
    setAddRecording(null);
  };

  const deleteRecording = async () => {
    setIsDeleting(true); // Enable loading state
    try {
      const response = await fetch(
        `${API_ENDPOINT}api/assets/table/${selectedTableDataId}/recording/${recordingAsset._id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        toast({
          title: "Recording deleted.",
          description: "The recording has been successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // Here you might want to update your component state to reflect the deleted recording
      } else {
        toast({
          title: "Error deleting recording.",
          description:
            "There was an issue deleting the recording. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Network error.",
        description: "Could not connect to the server. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false); // Disable loading state regardless of the outcome
    }
  };

  return (
    <Box
      onClick={handleRecordingClick}
      className={`beat-player-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      boxShadow="md"
    >
      <Flex alignItems={"center"} gap={"8px"}>
        <Flex>
          {isCurrentBeatPlaying ? (
            <>
              <IconButton
                onClick={(event) => {
                  handleInnerClick(event);
                  togglePlayPause(); // Call the original saveProducerName function
                }}
                icon={<BsPauseCircle className="main-player-button" />}
              />
            </>
          ) : (
            <>
              <IconButton
                onClick={(event) => {
                  handleInnerClick(event);
                  togglePlayPause(); // Call the original saveProducerName function
                }}
                icon={<BsPlayCircle className="main-player-button" />}
              />
            </>
          )}
        </Flex>
        <Flex>
          <Flex flexDirection={"column"}>
            <Text className="player-name">{recordingAsset.title}</Text>
            <Text className="player-producer">
              {recordingAsset.artist || "Artist"}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex className="fullslidercontainer" alignItems={"center"} gap={"5px"}>
        <Text className="slider-time">{formatTime(localCurrentTime)}</Text>
        <Slider
          aria-label="track-progress"
          value={isCurrentBeatPlaying ? localCurrentTime : 0}
          max={isCurrentBeatPlaying ? localDuration : 0}
          onChange={(val) => isCurrentBeatPlaying && seekTrack(val)}
          focusThumbOnChange={false}
          isDisabled={!isCurrentBeatPlaying}
          className="slider-progress"
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Text className="slider-time" ml={1}>
          {formatTime(
            isCurrentBeatPlaying ? localDuration - localCurrentTime : 0
          )}
        </Text>
      </Flex>
      <Flex alignItems={"center"} gap={"5px"}>
        {recordingAsset.lyric && (
          <Badge borderRadius={"4px"} colorScheme="yellow" variant="subtle">
            <Flex alignItems={"center"} gap={"3px"}>
              <Icon as={MdOutlineLyrics} />
              <Text fontSize={"9px"}>Lyrics</Text>
            </Flex>
          </Badge>
        )}
        {recordingAsset.beat && (
          <Badge borderRadius={"4px"} colorScheme="blue" variant="subtle">
            <Flex alignItems={"center"} gap={"3px"}>
              <Icon as={BsHeadphones} />
              <Text fontSize={"9px"}>Beat</Text>
            </Flex>
          </Badge>
        )}
        {recordingAsset.release && (
          <Badge borderRadius={"4px"} colorScheme="green" variant="subtle">
            <Flex alignItems={"center"} gap={"3px"}>
              <Icon as={BsCheck2All} />
              <Text fontSize={"9px"}>Released</Text>
            </Flex>
          </Badge>
        )}
      </Flex>
      <Flex alignItems={"center"} gap={"10px"}>
        <Flex>
          {!(
            recordingAsset.lyric &&
            recordingAsset.beat &&
            recordingAsset.release
          ) && (
            <Tooltip
              placement="bottom"
              borderRadius={"7px"}
              label={"Link Beat"}
            >
              <Button onClick={toLinkRecording} variant={"outline"} size={"xs"}>
                <Icon className="linkbtntext" mr={1.5} as={LinkIcon} />
                <Text className="linkbtntext">Link</Text>
              </Button>
            </Tooltip>
          )}
        </Flex>
        <Flex>
          {!recordingAsset.release && (
            <>
              <Tooltip
                placement="bottom"
                borderRadius={"7px"}
                label={"Add to Release"}
              >
                <Button
                  onClick={AddRecordingToRelease}
                  variant={"outline"}
                  size={"xs"}
                >
                  <Text className="addtoreleasebtn">Add To Release</Text>
                </Button>
              </Tooltip>
            </>
          )}
        </Flex>

        <Flex>
          <Tooltip
            placement="bottom"
            borderRadius={"7px"}
            label={"Add to Folder"}
          >
            <Button variant={"outline"} size={"xs"}>
              <Flex alignItems={"center"} gap={"2px"}>
                <Icon as={BsPlus} />
                <Text className="addtoreleasebtn">Folder</Text>
              </Flex>{" "}
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
      <Flex alignItems={"center"} gap={"10px"}>
        <Tooltip
          placement="bottom"
          borderRadius={"7px"}
          label={"Download Recording"}
        >
          <IconButton variant={"outline"} icon={<DownloadIcon />} size={"xs"} />
        </Tooltip>
        {!guestToken && (
          <>
            <Tooltip
              placement="bottom"
              borderRadius={"7px"}
              label={"Delete Recording"}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteModalOpen(true);
                }}
                variant={"outline"}
                icon={<FaTrashCan />}
                size={"xs"}
                colorScheme="red"
              />
            </Tooltip>
          </>
        )}
      </Flex>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Recording</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this Recording?</ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
              <Button
                w={"100%"}
                colorScheme="gray"
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                w={"100%"}
                colorScheme="red"
                isLoading={isDeleting}
                onClick={() => {
                  deleteRecording();
                  setDeleteModalOpen(false); // Close the modal after initiating the delete request
                }}
              >
                Delete Recording
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AssetRecordingPlayer;
