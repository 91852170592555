import React from "react";
import {
  Box,
  Button,
  Center,
  CheckboxIcon,
  Flex,
  Icon,
  Text,
} from "@chakra-ui/react";
import "./HomeAddUsers.css";
import { BsCheckCircle } from "react-icons/bs";
import HomeReleasesTable from "../Table/HomeReleases/HomeReleasesTable";

const HomeAddUsers = ({
  isMobileView,
  userName,
  colorMode,
  userId,
  guestToken,
}) => {
  return (
    <Flex className="container-HomeAddUsers">
      <Box
        className={`surround-HomeAddUsers ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex flexDirection={"column"} p={1}>
          <Flex w={"100%"} borderBottom={".5px solid grey"} pb={2}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon fontSize={"14px"} as={BsCheckCircle} />
              <Text className="release-releases">Releases</Text>
            </Flex>
          </Flex>
          <HomeReleasesTable
            guestToken={guestToken}
            userId={userId}
            colorMode={colorMode}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default HomeAddUsers;
