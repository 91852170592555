import React, { createContext, useState, useContext } from "react";

const SelectedRecordingContext = createContext();

export const useSelectedRecording = () => useContext(SelectedRecordingContext);

export const SelectedRecordingProvider = ({ children }) => {
  const [selectedRecording, setSelectedRecording] = useState(null);

  return (
    <SelectedRecordingContext.Provider
      value={{ selectedRecording, setSelectedRecording }}
    >
      {children}
    </SelectedRecordingContext.Provider>
  );
};
