import React, { useState } from "react";
import {
  Flex,
  Icon,
  Text,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useToast,
} from "@chakra-ui/react";
import { FaHashtag } from "react-icons/fa6";
import { BsTrash } from "react-icons/bs";
import { FiUserPlus } from "react-icons/fi";
import "./ChannelItem.css";
import { API_ENDPOINT } from "../../Config/config";
import axios from "axios";

const ChannelItem = ({
  channelName,
  colorMode,
  channelId,
  fetchChannels,
  onChannelSelect,
  guestToken,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDeleteChannelModalOpen, setIsDeleteChannelModalOpen] =
    useState(false);
  const toast = useToast();

  const [isAddUserToChannelModalOpen, setIsAddUserToChannelModalOpen] =
    useState(false);

  const deleteChannel = async () => {
    try {
      await axios.delete(`${API_ENDPOINT}api/channels/${channelId}`);
      toast({
        title: "Channel deleted",
        description: "The channel was successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteChannelModalOpen(false);
      fetchChannels();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete the channel.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error deleting channel:", error);
    }
  };

  const handleSelectChannel = (e) => {
    e.stopPropagation(); // Prevent modal from opening
    onChannelSelect({ channelId, channelName }); // Use destructured prop here
  };

  return (
    <Flex
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleSelectChannel}
      className={`menu-drop-item ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      alignItems={"center"}
      gap={"8px"}
      justifyContent="space-between"
    >
      <Flex alignItems={"center"} gap={"8px"}>
        <Icon fontSize={"17px"} as={FaHashtag} />
        <Text className="channelmenu-text">{channelName}</Text>
      </Flex>
      {isHovered && (
        <Flex alignItems={"center"} gap={"6px"}>
          <Tooltip label="Add something here" placement="top">
            <Icon
              _hover={{ opacity: "50%", cursor: "pointer" }}
              as={FiUserPlus}
              fontSize={"13px"}
            />
          </Tooltip>
          <Tooltip label="Add something here" placement="top">
            <Icon
              onClick={(event) => {
                event.stopPropagation();
                {
                  !guestToken && setIsDeleteChannelModalOpen(true);
                }
              }}
              as={BsTrash}
              color={"red.300"}
              fontSize={"13px"}
              _hover={{ opacity: "50%", cursor: "pointer" }}
            />
          </Tooltip>
        </Flex>
      )}
      <Modal
        bg="black"
        isOpen={isDeleteChannelModalOpen}
        onClose={() => setIsDeleteChannelModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Add A Channel</ModalHeader>
          <ModalBody mb={2} bg={colorMode === "light" ? "white" : "black"}>
            Are you Sure you want to delete this channel? All messages and
            corresponding data will be deleted. This action cannot be undone!
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
              <Button
                w={"100%"}
                colorScheme="red"
                variant={"outline"}
                onClick={() => setIsDeleteChannelModalOpen(false)}
              >
                Cancel
              </Button>
              <Button onClick={deleteChannel} w={"100%"} colorScheme="red">
                Delete Channel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ChannelItem;
