import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Icon,
  Input,
  Text,
  useToast,
  Tooltip,
  Avatar,
  IconButton,
} from "@chakra-ui/react";
import { FaHashtag } from "react-icons/fa";
import { format, isSameDay, parseISO } from "date-fns";
import { FaUserPlus } from "react-icons/fa6";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import "./MobileChannelMessages.css";
import MobileMessageItem from "./MobileMessageItem";
import MobileSendMessageInput from "./MobileSendMessageInput";

const MobileChannelMessages = ({
  colorMode,
  userName,
  userId,
  isMobileView,
  selectedChannel,
  refreshSelectedChannel,
}) => {
  const toast = useToast();

  const isSameDayAsPrevious = (currentIndex) => {
    if (currentIndex === 0) return false; // The first message is always a new day.

    const currentMessageDate = parseISO(
      selectedChannel.messages[currentIndex].createdAt
    );
    const previousMessageDate = parseISO(
      selectedChannel.messages[currentIndex - 1].createdAt
    );

    return isSameDay(currentMessageDate, previousMessageDate);
  };

  const [editableChannelName, setEditableChannelName] = useState("");

  useEffect(() => {
    setEditableChannelName(selectedChannel?.channelName || "");
  }, [selectedChannel]);

  const handleChannelNameUpdate = async () => {
    if (editableChannelName !== selectedChannel.channelName) {
      try {
        await axios.patch(
          `${API_ENDPOINT}api/channels/${selectedChannel._id}/name`,
          {
            newChannelName: editableChannelName,
          }
        );
        toast({
          title: "Success",
          description: "Channel Name Succeessfully Changed",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Failed to update channel name", error);
        toast({
          title: "Failed",
          description: "Failed to update channel name.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedChannel?.messages]);

  const [activeItemId, setActiveItemId] = useState(null);

  const requestToggleOptions = (itemId) => {
    if (activeItemId === itemId) {
      setActiveItemId(null);
    } else {
      setActiveItemId(itemId);
    }
  };

  return (
    <Box
      className={`main-MobileChannelMessages ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      {selectedChannel && (
        <Flex
          className={`forbottmborder ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
          p={1}
        >
          <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
            <Icon as={FaHashtag} />
            <Input
              size={"sm"}
              w={"70%"}
              value={editableChannelName}
              border={"none"}
              onChange={(e) => setEditableChannelName(e.target.value)}
              onBlur={handleChannelNameUpdate} // Call handleChannelNameUpdate when input loses focus
            />
          </Flex>
          <Flex alignItems={"center"} gap={"7px"}>
            <Flex className="mobileavatarlists">
              {selectedChannel?.usersWithAccess?.map((user) => (
                <Tooltip key={user.userId} label={user.userName} hasArrow>
                  <Avatar
                    ml={-2.5}
                    _hover={{
                      transform: "scale(1.05)",
                      boxShadow: "lg",
                    }}
                    _active={{
                      transform: "scale(0.95)",
                    }}
                    name={user.userName}
                    size="sm"
                  />
                </Tooltip>
              ))}
            </Flex>
            <Flex>
              <IconButton
                variant={"outline"}
                colorScheme="orange"
                icon={<FaUserPlus />}
                size={"sm"}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
      <Flex className="mobilemasstableh">
        {selectedChannel?.messages && selectedChannel?.messages.length > 0 ? (
          selectedChannel?.messages.map((message, index) => (
            <>
              <Box key={message?._id} className="actualitem">
                {!isSameDayAsPrevious(index) && (
                  <Box my={1} px={1}>
                    <Text
                      fontSize="sm"
                      textAlign="center"
                      color={colorMode === "light" ? "gray.600" : "gray.400"}
                    >
                      {format(parseISO(message?.createdAt), "EEEE, MMMM do")}
                    </Text>
                    <Box
                      my={2}
                      height="1px"
                      bg={colorMode === "light" ? "gray.200" : "gray.700"}
                    />
                  </Box>
                )}
                <MobileMessageItem
                  key={message._id}
                  message={message}
                  colorMode={colorMode}
                  channelId={selectedChannel?._id}
                  userName={userName}
                  userId={userId}
                  refreshSelectedChannel={refreshSelectedChannel}
                  showInteractionOptions={activeItemId === message._id}
                  onRequestToggleOptions={() =>
                    requestToggleOptions(message._id)
                  }
                />
              </Box>
            </>
          ))
        ) : (
          <>none</>
        )}
        <div ref={messagesEndRef} />
      </Flex>
      <Box>
        {selectedChannel && (
          <MobileSendMessageInput
            userName={userName}
            colorMode={colorMode}
            userId={userId}
            isMobileView={isMobileView}
            selectedChannelId={selectedChannel?._id}
            refreshSelectedChannel={refreshSelectedChannel} // Forwarding the method
          />
        )}
      </Box>
    </Box>
  );
};

export default MobileChannelMessages;
