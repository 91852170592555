import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Avatar,
  useColorModeValue,
  Flex,
  IconButton,
  Input,
  Icon,
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Tooltip,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { FiDownload, FiUserPlus } from "react-icons/fi";
import newFolderIcon from "../../Svggs/newiconsfolder.svg";
import { DownloadIcon, SearchIcon } from "@chakra-ui/icons";
import { BsPlusLg, BsTrash } from "react-icons/bs";
import UserInviteFolders from "../../Components/Table/FoldersTable/UserInviteFolders";
import "./IndividualFolder.css";

const IndividualFolder = ({ colorMode, userId, isMobileView }) => {
  const { folderId } = useParams();
  const [folder, setFolder] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const toast = useToast();
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const boxShadow = useColorModeValue("sm", "dark-lg");

  const openDeleteModal = (file) => {
    setFileToDelete(file);
    setIsDeleteModalOpen(true);
  };

  const openAddFileModal = () => {
    setIsAddFileModalOpen(true);
  };

  const addFile = async () => {
    if (!selectedFile) {
      toast({
        title: "Error",
        description: "No file selected",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      await axios.post(
        `${API_ENDPOINT}api/folders/${folderId}/files/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      fetchFolderData();
      setIsAddFileModalOpen(false);
      toast({
        title: "Success",
        description: "File added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error adding file",
        description: "Something went wrong",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const deleteFile = async () => {
    if (!fileToDelete) return;

    try {
      await axios.delete(
        `${API_ENDPOINT}api/folders/${folderId}/files/${encodeURIComponent(
          fileToDelete.fileKey
        )}`
      );
      fetchFolderData();
      setIsDeleteModalOpen(false);
      toast({
        title: "File deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      toast({
        title: "Error deleting file.",
        description: "Unable to delete file, please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchFolderData = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}api/folder/${folderId}`);
      setFolder(response.data.folder);
    } catch (error) {
      console.error("Error fetching folder data:", error);
    }
  };

  useEffect(() => {
    fetchFolderData();
  }, [folderId]);

  const [theFolderName, setTheFolderName] = useState(folder?.folderName);

  useEffect(() => {
    setTheFolderName(folder?.folderName);
  }, [folder]);

  const formatDate = (uploadDate) => {
    const uploadedAt = new Date(uploadDate);
    const now = new Date();
    const timeDiff = now.getTime() - uploadedAt.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    if (daysDiff < 1) {
      const hoursDiff = timeDiff / (1000 * 3600);
      if (hoursDiff < 1) {
        const minutesDiff = timeDiff / (1000 * 60);
        return `${Math.floor(minutesDiff)} minutes ago`;
      }
      return `${Math.floor(hoursDiff)} hours ago`;
    } else if (daysDiff < 10) {
      return `${Math.floor(daysDiff)} days ago`;
    } else {
      return `on ${uploadedAt.toLocaleString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      })} @ ${uploadedAt.toLocaleTimeString()}`;
    }
  };

  const downloadFile = async (fileKey, fileName) => {
    try {
      toast({
        title: "Download started",
        description: "Your download has started.",
        status: "info",
        duration: 9000,
        isClosable: true,
      });

      const response = await axios.get(
        `${API_ENDPOINT}api/folders/${folderId}/files/${encodeURIComponent(
          fileKey
        )}/download`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast({
        title: "Download completed",
        description: "Your file has been downloaded.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error downloading file:", error);
      toast({
        title: "Download failed",
        description: "There was an issue downloading your file.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const downloadFolder = async () => {
    try {
      toast({
        title: "Download in progress",
        description: "Your folder download has started.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });

      const response = await axios.get(
        `${API_ENDPOINT}api/folders/${folderId}/download`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      const contentDisposition = response.headers["content-disposition"];
      let filename = folder?.folderName
        ? `${folder.folderName}.zip`
        : "download.zip"; // Default filename
      if (contentDisposition) {
        const matches = contentDisposition.match(/filename="?(.+)"?/);
        if (matches.length === 2) filename = matches[1];
      }

      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast({
        title: "Download completed",
        description: "Your folder has been successfully downloaded.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error downloading folder:", error);
      toast({
        title: "Download failed",
        description: "Failed to download the folder.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      className={`IndieFolderpage-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        className={`IndieFolderpage-surround ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        w={"100%"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={"10px"}
        border={
          colorMode === "light"
            ? ".5px solid rgba(187, 186, 187, 1)"
            : ".5px solid rgba(128, 128, 128, 1)"
        }
      >
        <Flex w={"100%"} flexDirection={"column"} gap={"20px"}>
          <Flex
            w={"100%"}
            mb={1}
            justifyContent={"start"}
            alignItems={"end"}
            gap={"10px"}
          >
            <Box className="fullfolder-container">
              <Image src={newFolderIcon} />
            </Box>
            <Flex alignItems={"end"} gap={"10px"}>
              <Input
                w={"50%"}
                size={"xs"}
                borderRadius={"5px"}
                value={theFolderName}
                border={"none"}
                bg={"none"}
                fontSize={"22px"}
              />
              <Text fontSize={"11px"}> {folder?.files.length} Files</Text>
            </Flex>
          </Flex>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={"10px"}
            w={"100%"}
          >
            <Flex w={"100%"}>
              <InputGroup w={"100%"}>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={SearchIcon} color="gray.500" />}
                />
                <Input
                  size={"sm"}
                  borderRadius={"7px"}
                  w={"100%"}
                  placeholder="Search Files"
                  bg={inputBg}
                  borderColor={borderColor}
                  _hover={{
                    bg: hoverBg,
                    borderColor: "gray.400",
                  }}
                  _focus={{
                    boxShadow: `${boxShadow}`,
                    borderColor: "gray.400",
                  }}
                />
              </InputGroup>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          h={"100%"}
          w={"100%"}
          flexDirection={"column"}
          justifyContent={"end"}
          alignItems={"end"}
          gap={"30px"}
        >
          <Flex
            justifyContent={"end"}
            alignItems={"start"}
            w={"100%"}
            h={"100%"}
          >
            <Flex alignItems={"center"} gap={"10px"}>
              <Flex justifyContent={"center"} alignItems={"center"}>
                {folder?.usersWithAccess &&
                  folder.usersWithAccess.length > 0 && (
                    <Flex alignItems="center" gap="2">
                      {folder.usersWithAccess.map((user) => (
                        <Tooltip
                          key={user.userId}
                          label={user.userName}
                          hasArrow
                        >
                          <Avatar ml={-3} size="sm" name={user.userName} />
                        </Tooltip>
                      ))}
                    </Flex>
                  )}
              </Flex>
              <Button onClick={onOpen} variant={"outline"} size={"sm"}>
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={FiUserPlus} />
                  <Text>Access</Text>
                </Flex>
              </Button>
            </Flex>
          </Flex>

          <Flex alignItems={"center"} gap={"10px"}>
            <Button colorScheme="red" size={"sm"}>
              Delete
            </Button>
            <Button onClick={downloadFolder} variant={"outline"} size={"sm"}>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={DownloadIcon} />
                <Text>Download</Text>
              </Flex>
            </Button>
            <Button onClick={openAddFileModal} variant={"outline"} size={"sm"}>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={BsPlusLg} />
                <Text>Add File</Text>
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Box
        className={`indiefoldertableheights ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        border={
          colorMode === "light"
            ? ".5px solid rgba(187, 186, 187, 1)"
            : ".5px solid rgba(128, 128, 128, 0.34)"
        }
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th
                className={`indiefolder-table-header ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
                position="sticky"
                top="0"
                bg={colorMode === "light" ? "white" : "rgba(28, 33, 57, 1)"}
                zIndex="sticky"
              >
                Name
              </Th>

              <Th
                className={`indiefolder-table-header ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
                textAlign={"start"}
                position="sticky"
                top="0"
                bg={colorMode === "light" ? "white" : "rgba(28, 33, 57, 1)"}
                zIndex="sticky"
              >
                Modified
              </Th>
              <Th
                textAlign={"center"}
                position="sticky"
                top="0"
                bg={colorMode === "light" ? "white" : "rgba(28, 33, 57, 1)"}
                zIndex="sticky"
              ></Th>
            </Tr>
          </Thead>
          <Tbody>
            {folder?.files.map((file) => (
              <Tr
                _hover={{ backgroundColor: "rgba(208, 212, 228, 0.32)" }}
                key={file?.fileName}
              >
                <Td>
                  <Text
                    maxW={"300px"}
                    isTruncated
                    className="indiefolder-table-data"
                  >
                    {file?.fileName}
                  </Text>
                </Td>

                <Td isNumeric>
                  <Flex alignItems={"start"}>
                    <Text className="indiefolder-table-data">
                      {formatDate(file?.uploadedAt)}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"10px"}
                  >
                    <Tooltip
                      placement="top"
                      borderRadius={"7px"}
                      label={"Download File"}
                    >
                      <IconButton
                        size={"xs"}
                        aria-label="Download file"
                        icon={<FiDownload />}
                        colorScheme="blue"
                        variant={"outline"}
                        onClick={() =>
                          downloadFile(file.fileKey, file.fileName)
                        } // Update this line
                      />
                    </Tooltip>

                    <Tooltip
                      placement="top"
                      borderRadius={"7px"}
                      label={"Delete File"}
                    >
                      <IconButton
                        size={"xs"}
                        aria-label="Delete file"
                        icon={<BsTrash />}
                        colorScheme="red"
                        variant={"outline"}
                        onClick={() => openDeleteModal(file)} // Pass the current file
                      />
                    </Tooltip>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Delete File</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this file?</ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
              <Button
                w={"100%"}
                variant="outline"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button w={"100%"} colorScheme="red" onClick={deleteFile}>
                Delete File
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isAddFileModalOpen}
        onClose={() => setIsAddFileModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Add File to Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="file"
              onChange={(event) => setSelectedFile(event.target.files[0])}
            />
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
              <Button
                w={"100%"}
                variant="outline"
                onClick={() => setIsAddFileModalOpen(false)}
              >
                Cancel
              </Button>
              <Button colorScheme="blue" w={"100%"} onClick={addFile}>
                Add File
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalCloseButton />
          <ModalBody>
            <UserInviteFolders
              folderName={folder?.folderName}
              colorMode={colorMode}
              userId={userId}
              folderId={folderId}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default IndividualFolder;
