import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  useToast,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Input,
  Icon,
  Tooltip,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Spinner,
  Center,
  IconButton,
} from "@chakra-ui/react";
import axios from "axios";
import "./MainBeatAssetTable.css";
import { API_ENDPOINT } from "../../../../Config/config";
import MainBeatInstrumentalUpload from "../MainUploads/MainBeatInstrumentalUpload";
import AssetBeatPlayer from "../../../Players/AssetBeatPlayer/AssetBeatPlayer";
import MainBeatsTablePlayer from "../Players/MainBeatsTablePlayer";
import { BsFilter, BsPlus, BsPlusLg, BsSearch } from "react-icons/bs";
import { FaSort } from "react-icons/fa";
import { useCurrentBeat } from "../../../../Context/CurrentBeatContext";
import MobileMainBeatsPlayer from "../../../Players/MobilePlayers/MobileMainBeatsPlayer";

const MainBeatAssetTable = ({
  colorMode,
  board,
  boardId,
  mutualSpaceId,
  userId,
  isSidebarCollapsed,
  tableId,
  userName,
  guestToken,
  openDetailsPanelSettings,
  closedetailsPanelSetting,
  fetchBoardDetails,
  isMobileView,
}) => {
  const [beatAssets, setBeatAssets] = useState([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { currentBeat, setCurrentBeat } = useCurrentBeat();

  const inputBg = useColorModeValue(
    "rgba(247, 250, 252, 0.43)",
    "rgba(66, 69, 99, 0.23)"
  );
  const taskBg = useColorModeValue("gray.50", "gray.700");

  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const boxShadow = useColorModeValue("sm", "dark-lg");

  const fetchBeatData = async () => {
    setIsLoading(true);
    if (tableId) {
      try {
        const response = await axios.get(
          `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/beats`
        );
        const fetchedBeats = response.data.beats;
        setBeatAssets(fetchedBeats);

        if (fetchedBeats.length > 0) {
          const enhancedBeatAsset = {
            ...fetchedBeats[0],
            mutualSpaceId,
            boardId,
            tableId,
          };
          setCurrentBeat(enhancedBeatAsset);
        } else {
          closedetailsPanelSetting();
        }
      } catch (error) {
        toast({
          title: "Error fetching beats",
          description:
            error.response?.data?.message || "Could not fetch beats.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error fetching beats:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchBeatData();
  }, [tableId, mutualSpaceId, boardId, toast, setCurrentBeat]);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [activeBeatId, setActiveBeatId] = useState(null);

  return (
    <Flex
      className={`MainBeatAssetTable-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      {isLoading ? (
        <Center mt={2}>
          <Spinner />
        </Center>
      ) : beatAssets.length > 0 ? (
        <>
          <Flex alignItems={"center"} w={"100%"} gap={"8px"}>
            <Flex w={"100%"} alignItems={"center"} gap={"8px"}>
              <Flex>
                {isMobileView ? (
                  <>
                    <IconButton
                      isDisabled={!!guestToken}
                      onClick={() => setIsUploadModalOpen(true)}
                      icon={<BsPlusLg />}
                      size="sm"
                    />
                  </>
                ) : (
                  <>
                    <Tooltip
                      placement="top"
                      borderRadius={"7px"}
                      label={"Upload"}
                    >
                      <Button
                        isDisabled={!!guestToken}
                        onClick={() => setIsUploadModalOpen(true)}
                        size={"sm"}
                      >
                        <Flex alignItems={"center"} gap={"3px"}>
                          <Text>Audio File </Text>
                          <Icon as={BsPlus} />
                        </Flex>
                      </Button>
                    </Tooltip>
                  </>
                )}
              </Flex>
              <Flex w="100%">
                <InputGroup size="sm" w="100%">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={BsSearch} color="gray.300" />}
                  />
                  <Input
                    bg={inputBg}
                    borderColor={borderColor}
                    placeholder="Search..."
                    sx={{
                      "::placeholder": {
                        fontSize: "12px",
                      },
                    }}
                    _hover={{
                      bg: hoverBg,
                      borderColor: "gray.400",
                    }}
                    borderRadius={"5px"}
                    size={"sm"}
                    w={"100%"}
                  />
                </InputGroup>
              </Flex>
            </Flex>
          </Flex>
          <Box
            className={`MainBeatAssetTable-surround ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
          >
            <SimpleGrid mt={2} columns={{ base: 1, md: 1 }} spacing={4}>
              {beatAssets.map((beatAsset) => (
                <Box key={beatAsset._id}>
                  {isMobileView ? (
                    <>
                      <MobileMainBeatsPlayer
                        userName={userName}
                        userId={userId}
                        colorMode={colorMode}
                        beat={beatAsset}
                        board={board}
                        boardId={boardId}
                        mutualSpaceId={mutualSpaceId}
                        tableId={tableId}
                        isSidebarCollapsed={isSidebarCollapsed}
                        guestToken={guestToken}
                        openDetailsPanelSettings={openDetailsPanelSettings}
                        isActive={activeBeatId === beatAsset._id}
                        setActiveBeatId={setActiveBeatId}
                        fetchBeatData={fetchBeatData}
                        isMobileView={isMobileView}
                      />
                    </>
                  ) : (
                    <>
                      <MainBeatsTablePlayer
                        userName={userName}
                        userId={userId}
                        colorMode={colorMode}
                        beatAsset={beatAsset}
                        board={board}
                        boardId={boardId}
                        mutualSpaceId={mutualSpaceId}
                        tableId={tableId}
                        isSidebarCollapsed={isSidebarCollapsed}
                        guestToken={guestToken}
                        openDetailsPanelSettings={openDetailsPanelSettings}
                        isActive={activeBeatId === beatAsset._id}
                        setActiveBeatId={setActiveBeatId}
                        fetchBeatData={fetchBeatData}
                        isMobileView={isMobileView}
                      />
                    </>
                  )}
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </>
      ) : (
        <>
          <Box
            bg={taskBg}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={"12px"}
            w={"100%"}
            padding={"20px"}
          >
            <Flex alignItems={"center"} flexDirection={"column"} gap={"10px"}>
              <Text
                className={`getstartedbelow ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
                textAlign={"center"}
              >
                Upload an Audio File!
              </Text>
              <Flex>
                <Button
                  onClick={() => setIsUploadModalOpen(true)}
                  size={"sm"}
                  colorScheme="blue"
                >
                  <Flex alignItems={"center"} gap={"5px"}>
                    <Icon as={BsPlusLg} />
                    <Text>Upload</Text>
                  </Flex>
                </Button>
              </Flex>
            </Flex>
          </Box>
        </>
      )}

      <Modal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalCloseButton />
          <ModalBody>
            <MainBeatInstrumentalUpload
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              mutualSpaceId={mutualSpaceId}
              board={board}
              boardId={boardId}
              tableId={tableId}
              setIsUploadModalOpen={setIsUploadModalOpen}
              fetchBeatData={fetchBeatData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MainBeatAssetTable;
