import React from "react";
import "./NoAssetTables.css";
import { Box, Text, Button, Flex, Icon } from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";

const NoAssetTables = ({ colorMode, setIsUploadModalOpen }) => {
  return (
    <Box
      bg={colorMode === "light" ? "gray.50" : "gray.800"}
      border={
        colorMode === "light"
          ? ".5px solid rgba(187, 186, 187, 1)"
          : "1px solid rgba(128, 128, 128, 1)"
      }
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius={"12px"}
      w={"100%"}
      padding={"20px"}
    >
      <Flex alignItems={"center"} flexDirection={"column"} gap={"10px"}>
        <Text
          className={`getstartedbelow ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
          textAlign={"center"}
        >
          Create Your First Table!
        </Text>
        <Flex>
          <Button
            onClick={() => setIsUploadModalOpen(true)}
            size={"sm"}
            colorScheme="blue"
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={BsPlusLg} />
              <Text>Create</Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NoAssetTables;
