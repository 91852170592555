import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Text,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Center,
  useToast,
} from "@chakra-ui/react";
import "./AssetsTable.css";
import { BsPlusLg, BsTable } from "react-icons/bs";
import AssetsTableUpload from "../../Forms/AssetsTableForms/AssetsTableUpload";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import IndividualAssetTable from "./IndividualAssetTable";
import { FaTimes } from "react-icons/fa";
import NoAssetTables from "../../EmptyStates/NoAssetTables";

const AssetsTable = ({
  isMobileView,
  colorMode,
  userName,
  userId,
  openDetailsPanelSettings,
  guestToken,
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [assetTables, setAssetTables] = useState([]);
  const [isAssetsTablesLoading, setIsAssetsTablesLoading] = useState(false);
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const toast = useToast();
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedTableData, setSelectedTableData] = useState({});
  const [isHovering, setIsHovering] = useState(false); // State to track hover
  const [isDeleteTableModalOpen, setIsDeleteTableModalOpen] = useState(false);

  const fetchTables = async () => {
    setIsAssetsTablesLoading(true);
    const url = guestToken
      ? `${API_ENDPOINT}api/assets/tables/user/${userId}?mock=true`
      : `${API_ENDPOINT}api/assets/tables/user/${userId}`;

    try {
      const response = await axios.get(url);
      setAssetTables(response.data.assetTables || []);
      setIsAssetsTablesLoading(false);

      if (response.data.assetTables && response.data.assetTables.length > 0) {
        const firstTableId = response.data.assetTables[0]._id;
        handleTableClick(firstTableId);
      } else {
        setSelectedTable(null);
      }
    } catch (error) {
      toast({
        title: "Error fetching tables",
        description: "Unable to fetch tables for the user.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsAssetsTablesLoading(false);
    }
  };

  useEffect(() => {
    fetchTables();
  }, [userId, guestToken]);

  const handleTableClick = async (tableId) => {
    setSelectedTable(tableId);
    setIsTableDataLoading(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/assets/table/${tableId}`
      );
      setSelectedTableData(response.data.table);
      setIsTableDataLoading(false);
    } catch (error) {
      toast({
        title: "Error fetching table data",
        description: error.toString(),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsTableDataLoading(false);
    }
  };

  const deleteAssetTable = async () => {
    if (!selectedTable) return;

    try {
      await axios.delete(`${API_ENDPOINT}api/assets/table/${selectedTable}`);
      toast({
        title: "Table deleted",
        description: "The table has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteTableModalOpen(false);
      setAssetTables((prevTables) =>
        prevTables.filter((table) => table._id !== selectedTable)
      );
      setSelectedTable(null);
      fetchTables();
    } catch (error) {
      toast({
        title: "Deletion failed",
        description: error.toString(),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box className="AssetsTable-main-container">
      <Flex
        borderBottom={".5px solid grey"}
        alignItems={"center"}
        justifyContent={"space-between"}
        pb={2}
      >
        <Flex alignItems={"center"} gap={"15px"}>
          <Flex alignItems={"center"} gap={"8px"}>
            <Icon fontSize={"25px"} as={BsTable} />
            <Text className="planning-title-text">Assets</Text>
          </Flex>
          <Flex alignItems={"center"} gap={"8px"}>
            {assetTables.map((table, index) => (
              <Button
                onMouseEnter={() => setIsHovering(index)}
                onMouseLeave={() => setIsHovering(null)}
                onClick={() => handleTableClick(table?._id)}
                isActive={selectedTable === table?._id}
                key={table._id}
                size={"sm"}
                variant={"outline"}
              >
                <Flex alignItems={"center"} gap={"5px"}>
                  <Text className="sortbtntext">{table?.tableName}</Text>
                  {isHovering === index && (
                    <Tooltip
                      placement="top"
                      borderRadius={"7px"}
                      label={"Delete Table"}
                      zIndex={"9999"}
                    >
                      <Icon
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedTable(table?._id);
                          setIsDeleteTableModalOpen(true);
                        }}
                        as={FaTimes}
                        color="red"
                        _hover={{ cursor: "pointer", opacity: "70%" }}
                      />
                    </Tooltip>
                  )}
                </Flex>
              </Button>
            ))}
          </Flex>
        </Flex>
        <Flex>
          <Tooltip placement="top" borderRadius={"7px"} label={"New Table"}>
            <Button
              onClick={() => setIsUploadModalOpen(true)}
              size={"sm"}
              variant={"outline"}
            >
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={BsPlusLg} />
                <Text>Table</Text>
              </Flex>
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
      <Flex className="asset-tables-anchor">
        {isAssetsTablesLoading ? (
          <Center mt={3}>
            <Spinner />
          </Center>
        ) : assetTables.length > 0 ? (
          <Box w={"100%"}>
            {selectedTable &&
              (isTableDataLoading ? (
                <Center mt={3}>
                  <Spinner />
                </Center>
              ) : (
                <Box mt={1} w={"100%"}>
                  <IndividualAssetTable
                    colorMode={colorMode}
                    selectedTable={selectedTable}
                    selectedTableId={selectedTable._id}
                    isTableDataLoading={isTableDataLoading}
                    selectedTableData={selectedTableData}
                    userName={userName}
                    userId={userId}
                    openDetailsPanelSettings={openDetailsPanelSettings}
                    guestToken={guestToken}
                    isMobileView={isMobileView}
                  />
                </Box>
              ))}
          </Box>
        ) : (
          <Box w={"100%"}>
            <NoAssetTables
              setIsUploadModalOpen={setIsUploadModalOpen}
              colorMode={colorMode}
            />
          </Box>
        )}
      </Flex>
      <Modal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalCloseButton />
          <ModalBody>
            <AssetsTableUpload
              userName={userName}
              userId={userId}
              setIsUploadModalOpen={setIsUploadModalOpen}
              colorMode={colorMode}
              fetchTables={fetchTables}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteTableModalOpen}
        onClose={() => setIsDeleteTableModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Table</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this Table?</ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
              <Button
                w={"100%"}
                colorScheme="blue"
                onClick={() => setIsDeleteTableModalOpen(false)}
              >
                Cancel
              </Button>
              <Button w={"100%"} colorScheme="red" onClick={deleteAssetTable}>
                Delete
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AssetsTable;
