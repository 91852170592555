import React from "react";
import "./ReleaseLyricsItem.css";
import { Flex, Icon, Text, Box } from "@chakra-ui/react";
import { BiNotepad } from "react-icons/bi";
import { FaCircleCheck } from "react-icons/fa6";

const ReleaseLyricsItem = ({ release, colorMode, lyric }) => {
  return (
    <Flex
      className={`realeaselyrics-main ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      w={"100%"}
      gap={"10px"}
    >
      <Box className="itemheights">
        <Text className="release-labeltext-panel">
          {release.lyric?.lyricsText}
        </Text>
      </Box>
    </Flex>
  );
};

export default ReleaseLyricsItem;
