import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  Flex,
  Input,
  Icon,
  Tooltip,
  MenuList,
  MenuItem,
  MenuButton,
  Menu,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Checkbox,
  Text,
} from "@chakra-ui/react";
import {
  BsCalendar,
  BsFillBookmarkFill,
  BsFillFileTextFill,
  BsFillPeaceFill,
  BsPlus,
  BsTrash,
} from "react-icons/bs";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import axios from "axios";
import { API_ENDPOINT } from "../../../../Config/config";
import "./TaskTableItem.css";
import PriorityItem from "./PriorityItem/PriorityItem";
import DateItem from "./DateItem/DateItem";
import { DownloadIcon, LinkIcon } from "@chakra-ui/icons";
import StatusItem from "./StatusItem/StatusItem";
import LinkedItem from "./LinkedItem/LinkedItem";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import OwnerItem from "./OwnerItem/OwnerItem";
import { FaX } from "react-icons/fa6";
import LabelItem from "./LabelItem/LabelItem";

const TaskTableItem = ({
  task,
  taskId,
  board,
  boardId,
  userId,
  colorMode,
  mutualSpaceId,
  isSidebarCollapsed,
  fetchTasks,
  guestToken,
}) => {
  const [items, setItems] = useState([]);
  const scrollTableRef = useRef(null);
  const fixedColRef = useRef(null);
  const newItemRef = useRef("");
  const [isHovering, setIsHovering] = useState(null);
  const toast = useToast();
  const [isTableOpen, setIsTableOpen] = useState(true);
  const toggleTablesOpen = () => setIsTableOpen(!isTableOpen);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteDateColumnModalOpen, setIsDeleteDateColumnModalOpen] =
    useState(false);

  const onScroll = () => {
    if (fixedColRef.current) {
      fixedColRef.current.scrollTop = scrollTableRef.current?.scrollTop;
    }
  };

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);

  const [isDeleteItemModalOpen, setIsDeleteItemModalOpen] = useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState(null);

  const showDeleteConfirmation = (index) => {
    setDeleteItemIndex(index);
    setIsConfirmDeleteModalOpen(true);
  };

  const showItemDeleteConfirmation = (index) => {
    setDeleteItemIndex(index);
    setIsDeleteItemModalOpen(true);
  };

  const [editableItemNames, setEditableItemNames] = useState({});

  useEffect(() => {
    const names = {};
    items.forEach((item) => {
      names[item?._id] = item?.name;
    });
    setEditableItemNames(names);
  }, [items]);

  const [checkedItems, setCheckedItems] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleCheckboxChange = (itemId) => {
    setCheckedItems((prev) => {
      const newCheckedItems = { ...prev, [itemId]: !prev[itemId] };
      const areAllChecked = items.every((item) => newCheckedItems[item?._id]);
      setSelectAllChecked(areAllChecked);
      return newCheckedItems;
    });
  };

  const handleSelectAllCheckbox = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    const newCheckedItems = {};
    items.forEach((item) => {
      newCheckedItems[item?._id] = isChecked;
    });
    setCheckedItems(newCheckedItems);
  };

  useEffect(() => {
    const areAllChecked = items.every((item) => checkedItems[item?._id]);
    setSelectAllChecked(areAllChecked);
  }, [items, checkedItems]);

  const [editableTableName, setEditableTableName] = useState(
    task?.taskTableName
  );

  const [showMenuthree, setShowMenuthree] = useState(false);
  const [showMenuFour, setShowMenuFour] = useState(false);

  const handleDeleteItem = async () => {
    const itemId = items[deleteItemIndex]?._id;
    if (!itemId) {
      toast({
        title: "Error",
        description: "Item not found",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}`
      );
      if (response.status === 200) {
        toast({
          title: "Item Deleted",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottem-left",
        });
        fetchItems();
      } else {
        throw new Error("Failed to delete the item");
      }
      setIsDeleteItemModalOpen(false);
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description:
          error.response?.data?.message || "Failed to delete the item.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleUpdateItemName = async (itemId, newName) => {
    if (!newName.trim()) {
      toast({
        title: "Error",
        description: "Item name cannot be empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/name`,
        { newName }
      );

      if (response.status === 200) {
        toast({
          title: "Item Name Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        fetchItems();
      } else {
        throw new Error("Failed to update the item name");
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description:
          error.response?.data?.message || "Failed to update item name.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddNewItem = async () => {
    const newItemName = newItemRef.current.value.trim();
    if (!newItemName) {
      toast({
        title: "Error",
        description: "Item name cannot be empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/additem`,
        { newItem: { name: newItemName } }
      );
      if (response.data) {
        toast({
          title: "Item added!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        newItemRef.current.value = "";
        fetchItems();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to add item",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchItems = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/items`
      );
      setItems(response.data.data);
      console.log(items);
    } catch (error) {
      toast({
        title: "Error fetching items",
        description: error.response?.data?.message || "Failed to fetch items",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchItems();
  }, [taskId, boardId, mutualSpaceId]);

  const deleteTask = async () => {
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}`
      );
      if (response.status === 200) {
        toast({
          title: "Task Deleted",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
      } else {
        throw new Error("Failed to delete the task");
      }
      setIsDeleteModalOpen(false);
      fetchTasks();
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description:
          error.response?.data?.message || "Failed to delete the task.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "In Progress":
        return colorMode === "light"
          ? "rgba(253, 188, 49, 1)"
          : "rgba(253, 188, 49, 1)";
      case "Done":
        return colorMode === "light"
          ? "rgba(31, 199, 120, 1)"
          : "rgba(61, 160, 107, 1)";
      default:
        return "transparent"; // Default case if no status matches
    }
  };

  const getPriorityBackgroundColor = (type) => {
    switch (type) {
      case "High":
        return colorMode === "light"
          ? "rgba(255, 75, 85, 1)"
          : "rgba(240, 63, 78, 0.84)";
      case "Medium":
        return colorMode === "light" ? "rgba(255, 96, 61, 1)" : "#DD6B20";
      case "Low":
        return colorMode === "light"
          ? "rgba(253, 188, 49, 1)"
          : "rgba(253, 188, 49, 1)";

      default:
        return "transparent";
    }
  };

  const updateItemDate = async (itemId, date) => {
    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/date`,
        {
          date: "",
        }
      );
      if (response.status === 200) {
        toast({
          title: "Date Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        fetchItems();
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description:
          error.response?.data?.message || "Failed to update the date.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateItemLabel = async (itemId, label) => {
    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/label`,
        {
          label: "",
        }
      );
      if (response.status === 200) {
        toast({
          title: "Label Added",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        fetchItems();
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description:
          error.response?.data?.message || "Failed to update the label.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const [showDateColumn, setShowDateColumn] = useState(false);
  const [showLabelColumn, setShowLabelColumn] = useState(false);

  useEffect(() => {
    const hasDate = items.some((item) => item.date !== undefined);
    setShowDateColumn(hasDate);
  }, [items]);

  useEffect(() => {
    const hasDate = items.some((item) => item.label !== undefined);
    setShowLabelColumn(hasDate);
  }, [items]);

  const handleUpdateTaskTableName = async () => {
    if (!editableTableName.trim()) {
      toast({
        title: "Error",
        description: "Task table name cannot be empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/name`,
        { newTaskTableName: editableTableName }
      );

      if (response.status === 200) {
        toast({
          title: "Name Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        fetchTasks();
      } else {
        throw new Error("Failed to update the task table name");
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description:
          error.response?.data?.message || "Failed to update task table name.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      className={`TaskTableItem-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        w={"100%"}
        mb={1}
        p={2}
        _hover={{ opacity: "80%", bg: "rgba(150, 158, 167, 0.12)" }}
        fontSize={"14px"}
        color={"rgba(150, 158, 167, 1)"}
        alignItems={"center"}
        justifyContent={"space-between"}
        cursor={"pointer"}
        onClick={toggleTablesOpen}
        borderRadius={"7px"}
      >
        <Flex alignItems={"center"} gap={"0px"}>
          <Icon
            mt={1}
            fontSize={"25px"}
            color={
              colorMode === "light"
                ? "rgba(91, 157, 249, 1)"
                : "rgba(91, 157, 249, 1)"
            }
            as={isTableOpen ? FiChevronDown : FiChevronRight}
          />
          <Input
            onClick={(e) => e.stopPropagation()}
            value={editableTableName}
            onChange={(e) => setEditableTableName(e.target.value)}
            onBlur={handleUpdateTaskTableName}
            borderRadius={"6px"}
            className={`inewnputsyles ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
            border={"none"}
            size={"sm"}
            isDisabled={!!guestToken}
          />
        </Flex>
        <Flex alignItems={"center"} gap={"10px"}>
          <Tooltip placement="top" borderRadius={"7px"} label={"Download"}>
            <IconButton
              isDisabled={!!guestToken}
              onClick={(e) => {
                e.stopPropagation();
              }}
              icon={<DownloadIcon />}
              colorScheme="cyan"
              variant={"outline"}
              size={"xs"}
            />
          </Tooltip>
          <Tooltip placement="top" borderRadius={"7px"} label={"Delete"}>
            <IconButton
              isDisabled={!!guestToken}
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleteModalOpen(true);
              }}
              icon={<BsTrash />}
              colorScheme="red"
              size={"xs"}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Flex w={"100%"}>
        {isTableOpen && (
          <Box
            className={`TaskTableItem-table-surround ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
          >
            <Box ref={fixedColRef} className="fixed-col">
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th
                      className={`forcheckboxborder  ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      <Checkbox />
                    </Th>
                    <Th
                      className={`TaskTableItem-head  ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Item
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.map((item, index) => (
                    <>
                      <Tr
                        onMouseEnter={() => setIsHovering(index)}
                        onMouseLeave={() => setIsHovering(null)}
                        className={`${
                          isHovering === index ? "isnewitemrowhover" : ""
                        } ${colorMode === "light" ? "lightmode" : "darkmode"}`}
                      >
                        <Td
                          className={`forcheckboxborder  ${
                            colorMode === "light" ? "lightmode" : "darkmode"
                          }`}
                        >
                          <Checkbox
                            isChecked={checkedItems[item?._id]}
                            onChange={() => handleCheckboxChange(item?._id)}
                          />
                        </Td>
                        <Td
                          className={`inputitem-mainbody-section ${
                            colorMode === "light" ? "lightmode" : "darkmode"
                          }`}
                        >
                          <Flex gap={"5px"} className="inputitem-body-column">
                            {!guestToken && isHovering === index && (
                              <Tooltip
                                label="Delete Item"
                                hasArrow
                                placement="top"
                                closeOnClick={false}
                              >
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    showItemDeleteConfirmation(index);
                                  }}
                                  colorScheme="red"
                                  icon={<BsTrash />}
                                  size={"xs"}
                                  ml={1}
                                />
                              </Tooltip>
                            )}
                            <Input
                              border={"none"}
                              size="xs"
                              value={editableItemNames[item?._id] || ""}
                              onChange={(e) =>
                                setEditableItemNames({
                                  ...editableItemNames,
                                  [item?._id]: e.target.value,
                                })
                              }
                              onBlur={() =>
                                handleUpdateItemName(
                                  item?._id,
                                  editableItemNames[item?._id]
                                )
                              }
                              isDisabled={!!guestToken}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    </>
                  ))}

                  <Tr>
                    <Td></Td>
                    <Td>
                      <Input
                        isDisabled={!!guestToken}
                        ref={newItemRef}
                        placeholder="Add new"
                        size="xs"
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
            <Box
              ref={scrollTableRef}
              onScroll={onScroll}
              className="scroll-tablerel"
            >
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th
                      className={`TaskTableItem-head ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      <Flex
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"5px"}
                      >
                        <Text>Owner</Text>
                      </Flex>
                    </Th>

                    <Th
                      className={`TaskTableItem-head ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      <Flex
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"5px"}
                      >
                        <Text>Status</Text>
                      </Flex>
                    </Th>
                    <Th
                      className={`TaskTableItem-head ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      <Flex
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"5px"}
                      >
                        <Text>Priority</Text>
                      </Flex>
                    </Th>
                    <Th
                      className={`TaskTableItem-head ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      <Flex
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"10px"}
                      >
                        <Text>Linked</Text>
                      </Flex>
                    </Th>
                    {showDateColumn && (
                      <Th
                        onMouseEnter={() => setShowMenuFour(true)}
                        onMouseLeave={() => setShowMenuFour(false)}
                        className={`TaskTableItem-head ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                      >
                        <Flex
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={"10px"}
                        >
                          <Text>Date</Text>
                          {!guestToken && showMenuFour && (
                            <>
                              <Icon
                                as={FaX}
                                color={"red.300"}
                                fontSize={"10px"}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // setIsDeleteDateColumnModalOpen(index);
                                }}
                              />
                            </>
                          )}
                        </Flex>
                      </Th>
                    )}

                    {showLabelColumn && (
                      <Th
                        onMouseEnter={() => setShowMenuthree(true)}
                        onMouseLeave={() => setShowMenuthree(false)}
                        className={`TaskTableItem-head ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                      >
                        <Flex
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={"10px"}
                        >
                          <Text>Label</Text>
                          {!guestToken && showMenuthree && (
                            <>
                              <Icon
                                as={FaX}
                                color={"red.300"}
                                fontSize={"10px"}
                              />
                            </>
                          )}
                        </Flex>
                      </Th>
                    )}

                    <Th
                      className={`TaskTableItem-head ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      <Flex w={"100%"} justifyContent={"start"}>
                        <Menu
                          p={1}
                          border={
                            colorMode === "light"
                              ? "2px solid rgba(153, 178, 198, 0.23)"
                              : "2px solid rgba(43, 46, 54, 1)"
                          }
                          bg={
                            colorMode === "light"
                              ? "white"
                              : "rgba(5, 15, 30, 1)"
                          }
                        >
                          <MenuButton
                            h={"15px"}
                            w={"15px"}
                            size={"xxs"}
                            as={IconButton}
                            icon={<BsPlus />}
                            variant="outline"
                            borderRadius={"4px"}
                          />
                          <Box>
                            <MenuList>
                              <Box px={4} py={2}>
                                <Text fontSize="sm" fontWeight="bold">
                                  Columns
                                </Text>
                              </Box>
                              <Flex>
                                <MenuItem
                                  isDisabled={!!guestToken}
                                  onClick={() => {
                                    items.forEach((item) =>
                                      updateItemLabel(item._id)
                                    );
                                  }}
                                  w={"50%"}
                                >
                                  <Box
                                    size="12px"
                                    as={BsFillBookmarkFill}
                                    color="purple.500"
                                    mr={3}
                                  />
                                  Label
                                </MenuItem>
                                <MenuItem isDisabled={!!guestToken} w={"50%"}>
                                  <Box
                                    size="12px"
                                    as={BsFillPeaceFill}
                                    color="teal.500"
                                    mr={3}
                                  />
                                  Priority
                                </MenuItem>
                              </Flex>
                              <Flex>
                                <MenuItem
                                  isDisabled={!!guestToken}
                                  onClick={() => {
                                    items.forEach((item) =>
                                      updateItemDate(item._id)
                                    );
                                  }}
                                  w={"50%"}
                                >
                                  <Box
                                    size="12px"
                                    as={BsCalendar}
                                    color="green.500"
                                    mr={3}
                                  />
                                  Date
                                </MenuItem>
                                <MenuItem isDisabled={!!guestToken} w={"50%"}>
                                  <Box
                                    size="12px"
                                    as={BsFillFileTextFill}
                                    color="blue.500"
                                    mr={3}
                                  />
                                  Text
                                </MenuItem>
                              </Flex>
                              <Flex>
                                <MenuItem isDisabled={!!guestToken} w={"50%"}>
                                  <Box
                                    size="12px"
                                    as={MdOutlineAccessTimeFilled}
                                    color="red.300"
                                    mr={3}
                                  />
                                  Status
                                </MenuItem>
                                <MenuItem isDisabled={!!guestToken} w={"50%"}>
                                  <Box
                                    size="12px"
                                    as={LinkIcon}
                                    color="orange.500"
                                    mr={3}
                                  />
                                  Linked
                                </MenuItem>
                              </Flex>
                            </MenuList>
                          </Box>
                        </Menu>
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.map((item, index) => (
                    <Tr
                      onMouseEnter={() => setIsHovering(index)}
                      onMouseLeave={() => setIsHovering(null)}
                      className={`${
                        isHovering === index ? "isnewitemrowhover" : ""
                      } ${colorMode === "light" ? "lightmode" : "darkmode"}`}
                    >
                      <Td
                        className={`owner-mainbody-section ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                      >
                        <Flex className="owner-body-column">
                          <OwnerItem
                            key={index}
                            board={board}
                            colorMode={colorMode}
                            mutualSpaceId={mutualSpaceId}
                            userId={userId}
                            boardId={boardId}
                            task={task}
                            taskId={task._id}
                            isSidebarCollapsed={isSidebarCollapsed}
                            fetchTasks={fetchTasks}
                            fetchItems={fetchItems}
                            itemId={item._id}
                            item={item}
                            guestToken={guestToken}
                          />
                        </Flex>
                      </Td>
                      <Td
                        className={`status-mainbody-section ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                        backgroundColor={getStatusBackgroundColor(item?.status)}
                      >
                        <Flex className="status-body-column">
                          <StatusItem
                            key={index}
                            board={board}
                            colorMode={colorMode}
                            mutualSpaceId={mutualSpaceId}
                            userId={userId}
                            boardId={boardId}
                            task={task}
                            taskId={task._id}
                            isSidebarCollapsed={isSidebarCollapsed}
                            fetchTasks={fetchTasks}
                            fetchItems={fetchItems}
                            itemId={item._id}
                            item={item}
                            guestToken={guestToken}
                          />
                        </Flex>
                      </Td>
                      <Td
                        className={`status-mainbody-section ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                        backgroundColor={getPriorityBackgroundColor(
                          item?.priority
                        )}
                      >
                        <Flex className="status-body-column">
                          <PriorityItem
                            key={index}
                            board={board}
                            colorMode={colorMode}
                            mutualSpaceId={mutualSpaceId}
                            userId={userId}
                            boardId={boardId}
                            task={task}
                            taskId={task._id}
                            isSidebarCollapsed={isSidebarCollapsed}
                            fetchTasks={fetchTasks}
                            fetchItems={fetchItems}
                            itemId={item._id}
                            item={item}
                            guestToken={guestToken}
                          />
                        </Flex>
                      </Td>
                      <Td
                        className={`date-mainbody-section ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                      >
                        <Flex className="date-body-column">
                          <LinkedItem
                            key={index}
                            board={board}
                            colorMode={colorMode}
                            mutualSpaceId={mutualSpaceId}
                            userId={userId}
                            boardId={boardId}
                            task={task}
                            taskId={task._id}
                            isSidebarCollapsed={isSidebarCollapsed}
                            fetchTasks={fetchTasks}
                            itemId={item._id}
                            item={item}
                            fetchItems={fetchItems}
                            guestToken={guestToken}
                          />
                        </Flex>
                      </Td>
                      {showDateColumn && (
                        <Td
                          className={`date-mainbody-section ${
                            colorMode === "light" ? "lightmode" : "darkmode"
                          }`}
                        >
                          <Flex className="date-body-column">
                            <DateItem
                              key={index}
                              board={board}
                              colorMode={colorMode}
                              mutualSpaceId={mutualSpaceId}
                              userId={userId}
                              boardId={boardId}
                              task={task}
                              taskId={task._id}
                              isSidebarCollapsed={isSidebarCollapsed}
                              fetchTasks={fetchTasks}
                              itemId={item._id}
                              item={item}
                              fetchItems={fetchItems}
                            />
                          </Flex>
                        </Td>
                      )}
                      {showLabelColumn && (
                        <Td
                          className={`date-mainbody-section ${
                            colorMode === "light" ? "lightmode" : "darkmode"
                          }`}
                        >
                          <Flex className="date-body-column">
                            <LabelItem
                              key={index}
                              board={board}
                              colorMode={colorMode}
                              mutualSpaceId={mutualSpaceId}
                              userId={userId}
                              boardId={boardId}
                              task={task}
                              taskId={task._id}
                              isSidebarCollapsed={isSidebarCollapsed}
                              fetchTasks={fetchTasks}
                              itemId={item._id}
                              item={item}
                              fetchItems={fetchItems}
                            />
                          </Flex>
                        </Td>
                      )}
                      <Td
                        className={`tobeadded-mainbody-section ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                      >
                        <Flex className="tobeadded-body-column"></Flex>
                      </Td>
                    </Tr>
                  ))}

                  <Tr>
                    <Td>
                      <Button
                        size="xs"
                        variant="solid"
                        fontSize={"10px"}
                        onClick={handleAddNewItem}
                      >
                        +
                      </Button>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        )}
      </Flex>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Delete Task</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this Task?</ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
              <Button
                variant={"outline"}
                w={"100%"}
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button w={"100%"} colorScheme="red" onClick={deleteTask}>
                Delete
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteItemModalOpen}
        onClose={() => setIsDeleteItemModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Delete Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this Item?</ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
              <Button
                variant={"outline"}
                w={"100%"}
                onClick={() => setIsConfirmDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button onClick={handleDeleteItem} w={"100%"} colorScheme="red">
                Delete Item
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteDateColumnModalOpen}
        onClose={() => setIsDeleteDateColumnModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Delete Column</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this Column?</ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
              <Button
                variant={"outline"}
                w={"100%"}
                onClick={() => setIsDeleteDateColumnModalOpen(false)}
              >
                Cancel
              </Button>
              <Button w={"100%"} colorScheme="red">
                Delete Column
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default TaskTableItem;
