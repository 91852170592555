import React, { useState, useRef } from "react";
import {
  Button,
  Flex,
  Text,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  IconButton,
  MenuDivider,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { BiMicrophone } from "react-icons/bi";
import { BsHeadphones, BsImage } from "react-icons/bs";
import { MdOutlineLyrics } from "react-icons/md";
import { FaTasks, FaEllipsisV } from "react-icons/fa";
import { EditIcon } from "@chakra-ui/icons";
import { BiShare, BiTrash } from "react-icons/bi";
import { API_ENDPOINT } from "../../../../Config/config";
import axios from "axios";
import { useBeatPanel } from "../../../../Context/BeatsPanelContext";
import { useLyricsPanel } from "../../../../Context/LyricsPanelContext";
import { useRecordingsPanel } from "../../../../Context/RecordingsPanelContext";
import { useArtworksPanel } from "../../../../Context/ArtworkPanelContext";

const AssetTableButtons = ({
  board,
  handleTableChange,
  colorMode,
  activeTab,
  boardId,
  fetchBoardDetails,
  mutualSpaceId,
  guestToken,
  openDetailsPanelSettings,
}) => {
  const [showMenuButton, setShowMenuButton] = useState({});
  const [menuOpen, setMenuOpen] = useState({});
  const [isEditing, setIsEditing] = useState({});
  const toast = useToast();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentTableId, setCurrentTableId] = useState(null);

  const [buttonLabels, setButtonLabels] = useState(() => {
    const labels = {};
    board?.tables?.forEach((table) => {
      labels[table._id] = table.tableName;
    });
    return labels;
  });
  const handleRename = (id) => {
    setIsEditing((prev) => ({ ...prev, [id]: true }));
    setMenuOpen((prev) => ({ ...prev, [id]: false })); // Optionally close the menu when starting editing
  };

  const handleLabelChange = (e, id) => {
    setButtonLabels((prev) => ({ ...prev, [id]: e.target.value }));
  };

  const handleLabelSave = (e, id) => {
    if (e.key === "Enter") {
      setIsEditing((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleBlur = (id) => {
    setIsEditing((prev) => ({ ...prev, [id]: false }));
  };

  const deleteTable = async (tableId) => {
    try {
      const url = `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}`;
      const response = await axios.delete(url);

      toast({
        title: "Table Deleted",
        description:
          "The table and all its associated assets have been deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsDeleteModalOpen(false);
      fetchBoardDetails();
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Could not delete table.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error deleting table:", error);
    }
  };

  return (
    <Flex justifyItems={"center"} gap={"10px"}>
      {board?.tables?.map((table, index) => (
        <Menu
          w={"100%"}
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          onOpen={() => setMenuOpen((prev) => ({ ...prev, [table._id]: true }))}
          onClose={() =>
            setMenuOpen((prev) => ({ ...prev, [table._id]: false }))
          }
        >
          <Button
            onMouseEnter={() =>
              setShowMenuButton((prev) => ({ ...prev, [table._id]: true }))
            }
            onMouseLeave={() =>
              setShowMenuButton((prev) => ({ ...prev, [table._id]: false }))
            }
            key={table._id}
            size={"sm"}
            variant={activeTab === table.tableName ? "solid" : "outline"}
            onClick={() => {
              handleTableChange(table.tableName, table._id);
            }}
          >
            <Flex
              w={"100%"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"5px"}
            >
              {table.tableType === "Instrumental / Beats" && (
                <Icon as={BsHeadphones} />
              )}
              {table.tableType === "Lyrics" && <Icon as={MdOutlineLyrics} />}
              {table.tableType === "Artwork" && <Icon as={BsImage} />}
              {table.tableType === "Recordings" && <Icon as={BiMicrophone} />}

              {isEditing[table._id] ? (
                <Input
                  autoFocus
                  value={buttonLabels[table._id] ?? table.tableName}
                  onChange={(e) => handleLabelChange(e, table._id)}
                  onKeyDown={(e) => handleLabelSave(e, table._id)}
                  onBlur={() => handleBlur(table._id)}
                  size="xs"
                  className={`tab-btn-styles ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                  w={"100px"}
                />
              ) : (
                <Text
                  className={`tab-btn-styles ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  {buttonLabels[table._id] ?? table.tableName}
                </Text>
              )}
              {(showMenuButton[table._id] || menuOpen[table._id]) && (
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FaEllipsisV />}
                  size="xs"
                  variant="outline"
                />
              )}
            </Flex>
          </Button>
          <MenuList bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}>
            <MenuItem
              isDisabled={!!guestToken}
              onClick={() => handleRename(table._id)}
              fontSize={"13px"}
              bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
              _hover={{
                bg:
                  colorMode === "light"
                    ? "rgba(241, 244, 249, 1)"
                    : "rgba(42, 49, 70, 1)",
              }}
            >
              <Flex alignItems={"center"} gap={"10px"}>
                <Icon as={EditIcon} />
                <Text>Rename</Text>
              </Flex>
            </MenuItem>
            <MenuItem
              fontSize={"13px"}
              bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
              _hover={{
                bg:
                  colorMode === "light"
                    ? "rgba(241, 244, 249, 1)"
                    : "rgba(42, 49, 70, 1)",
              }}
            >
              <Flex alignItems={"center"} gap={"10px"}>
                <Icon as={BiShare} />
                <Text>Share</Text>
              </Flex>
            </MenuItem>
            <MenuDivider />

            <MenuItem
              isDisabled={!!guestToken}
              fontSize={"13px"}
              bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
              _hover={{
                bg:
                  colorMode === "light"
                    ? "rgba(241, 244, 249, 1)"
                    : "rgba(42, 49, 70, 1)",
              }}
              color={colorMode === "light" ? "red.500" : "red.300"}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentTableId(table._id);
                setIsDeleteModalOpen(true);
              }}
            >
              <Flex alignItems={"center"} gap={"10px"}>
                <Icon as={BiTrash} />
                <Text>Delete</Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      ))}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setCurrentTableId(null); // Reset the table ID when modal closes
        }}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Delete Table</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this table?</ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
              <Button
                w={"100%"}
                variant="outline"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                w={"100%"}
                onClick={() => {
                  deleteTable(currentTableId);
                }}
              >
                Delete Table
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default AssetTableButtons;
