import { createContext, useState, useContext } from "react";

// Create the context
const AddArtworkToReleaseContext = createContext();

// Custom hook for easy context usage
export const useAddArtworkToRelease = () =>
  useContext(AddArtworkToReleaseContext);

// Provider component
export const AddArtworkToReleaseProvider = ({ children }) => {
  const [addArtwork, setAddArtwork] = useState(null); // New state and setter function

  return (
    <AddArtworkToReleaseContext.Provider value={{ addArtwork, setAddArtwork }}>
      {children}
    </AddArtworkToReleaseContext.Provider>
  );
};
