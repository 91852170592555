import React, { useState, useEffect } from "react";
import "./ArtworkTable.css";
import {
  Box,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Flex,
  Button,
  Icon,
  Text,
  Avatar,
  AvatarBadge,
  Tooltip,
  SimpleGrid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa6";
import { BsClockHistory, BsImage, BsPlusLg } from "react-icons/bs";
import { MdOutlineSortByAlpha } from "react-icons/md";
import { AddIcon } from "@chakra-ui/icons";
import MainArtworkItem from "../../ArtworkItems/MainArtworkItem";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCurrentArtwork } from "../../../Context/CurrentArtworkContext";
import { API_ENDPOINT } from "../../../Config/config";
import { useEmptyArtwork } from "../../../Context/EmptyStates/EmptyArtworkContext";
import ArtworkUploadForm from "../../Forms/ArtworkForms/ArtworkUploadForm";
import MobileArtworkPanel from "../../DetailsPanel/ArtworkPanel/MobileArtworkPanel";
import MobileArtworkItem from "../../ArtworkItems/MobileArtworkItem";
import UserAvatars from "../../List/Users/UserAvatars";
import NoArtwork from "../../EmptyStates/NoArtwork";

const ArtworkTable = ({
  colorMode,
  isMobileView,
  userId,
  userName,
  guestToken,
  openDetailsPanelSettings,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Hook to control modal
  const [sortOption, setSortOption] = useState("Newest");
  const [sortDirection, setSortDirection] = useState("asc");
  const [artworks, setArtworks] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add this line
  const navigate = useNavigate();
  const { currentArtwork, setCurrentArtwork } = useCurrentArtwork();
  const { isArtworkEmpty, setIsArtworkEmpty } = useEmptyArtwork();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isFilteredByUser, setIsFilteredByUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const fetchArtworks = async () => {
    setIsLoading(true); // Start loading

    try {
      let response;
      if (guestToken) {
        response = await axios.get(`${API_ENDPOINT}api/artwork`);
      } else {
        response = await axios.get(`${API_ENDPOINT}api/artwork/user/${userId}`);
      }

      let fetchedArtworks = response.data.data;

      if (guestToken) {
        fetchedArtworks = fetchedArtworks.filter(
          (artwork) => artwork.mock === "true"
        );
      }

      const sortedArtworks = fetchedArtworks.sort(
        (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
      );

      setArtworks(sortedArtworks);
    } catch (error) {
      console.error("Error fetching artworks:", error);
    } finally {
      setIsLoading(false); // End loading regardless of result
    }
  };

  useEffect(() => {
    fetchArtworks();
  }, [userId, guestToken]); // Add GuestToken as a dependency

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_ENDPOINT}api/users`);
        const filteredUsers = response.data.data.filter(
          (user) =>
            Array.isArray(user.usersWithPermissions) &&
            user.usersWithPermissions.some((perm) => perm.userId === userId)
        );
        setUsers(filteredUsers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [userId]);

  const fetchArtworkByUser = async (userId) => {
    try {
      setLoading(true);
      setSelectedUserId(userId);
      setIsFilteredByUser(true); // Indicate that the filter is active

      const response = await axios.get(
        `${API_ENDPOINT}api/artwork/user/${userId}`
      );
      setArtworks(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user-specific artwork:", error);
      setLoading(false);
    }
  };

  const handleAvatarClick = (userId) => {
    fetchArtworkByUser(userId);
    setIsFilteredByUser(true);
    setSelectedUserId(userId);
  };

  const getUserNameById = (userId) => {
    const user = users.find((u) => u._id === userId);
    return user ? user.name : "User";
  };

  const clearUserFilter = () => {
    setIsFilteredByUser(false);
    setSelectedUserId(null);
    fetchArtworks();
  };

  const toPermissions = () => {
    navigate("/permissions"); // Update with correct route
  };

  return (
    <Box className="main-table-container">
      <Box
        className={`top-of-table ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Box>
          <Flex alignItems={"center"} gap={"10px"}>
            <Menu
              border={
                colorMode === "light"
                  ? "2px solid rgba(153, 178, 198, 0.23)"
                  : "2px solid rgba(43, 46, 54, 1)"
              }
              bg={colorMode === "light" ? "white" : "black"}
            >
              {({ isOpen }) => (
                <>
                  <Flex alignItems="center" gap={2}>
                    <Tooltip
                      placement="bottom"
                      borderRadius={"7px"}
                      label={"Filter"}
                    >
                      <MenuButton
                        size={isMobileView ? "xs" : "sm"}
                        as={Button}
                        rightIcon={
                          <FaChevronRight
                            style={{
                              transition: "transform 0.2s",
                              transform: isOpen ? "rotate(90deg)" : "none",
                            }}
                          />
                        }
                      >
                        Filter
                      </MenuButton>
                    </Tooltip>
                  </Flex>
                  <MenuList
                    p={2}
                    bg={colorMode === "light" ? "white" : "black"}
                    zIndex={"9999"}
                  >
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                    >
                      <Icon as={BsClockHistory} fontSize="17px" />

                      <Text>Newest</Text>
                    </MenuItem>
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                    >
                      <Icon as={MdOutlineSortByAlpha} fontSize="17px" />

                      <Text>A - Z</Text>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
            <Box></Box>
          </Flex>
        </Box>
        <Box className="avatarssurround">
          {!guestToken && (
            <>
              <Flex>
                <UserAvatars users={users} onAvatarClick={handleAvatarClick} />
                <Box className="aliagingn" p={0}>
                  <Tooltip
                    placement="bottom"
                    borderRadius={"7px"}
                    label={"Access User Beats"}
                  >
                    <Avatar
                      onClick={toPermissions}
                      size={"sm"}
                      ml={-3} // Apply negative margin from the second avatar onwards
                      _hover={{
                        transform: "translateY(-2px)",
                        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                        cursor: "pointer",
                        opacity: "60%",
                      }}
                    >
                      <AvatarBadge bg="green.500" boxSize="1.25em">
                        <AddIcon color="white" />
                      </AvatarBadge>
                    </Avatar>
                  </Tooltip>
                </Box>
              </Flex>
            </>
          )}
        </Box>
        <Flex alignItems={"center"} gap={"10px"}>
          <Box>
            <Tooltip
              placement="bottom"
              borderRadius={"7px"}
              label={"Upload New Artwork"}
            >
              <Button
                onClick={onOpen}
                variant={"outline"}
                size={isMobileView ? "xs" : "sm"}
              >
                <Flex gap={"5px"} alignItems={"center"}>
                  <Icon as={BsPlusLg} />
                  <Text>Upload</Text>
                </Flex>
              </Button>
            </Tooltip>
          </Box>
        </Flex>
      </Box>
      <Box h={"93%"}>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : artworks.length === 0 ? (
          <Box>
            <NoArtwork onOpen={onOpen} colorMode={colorMode} />
          </Box>
        ) : (
          <SimpleGrid
            className="controlheightartwork"
            columns={{ base: 1, md: 4 }}
            spacing={2}
            mt={2}
            p={2}
          >
            {artworks.map((artwork, index) => (
              <Box key={artwork.id} p={1}>
                {isMobileView ? (
                  <>
                    <MobileArtworkItem
                      isLoading={isLoading}
                      artwork={artwork}
                      colorMode={colorMode}
                      onClose={onClose}
                      fetchArtworks={fetchArtworks}
                    />
                  </>
                ) : (
                  <>
                    <MainArtworkItem
                      isLoading={isLoading}
                      artwork={artwork}
                      colorMode={colorMode}
                      onClose={onClose}
                      fetchArtworks={fetchArtworks}
                      openDetailsPanelSettings={openDetailsPanelSettings}
                    />
                  </>
                )}
              </Box>
            ))}
          </SimpleGrid>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Upload Artwork</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ArtworkUploadForm
              onClose={onClose}
              fetchArtworks={fetchArtworks}
              userName={userName}
              userId={userId}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ArtworkTable;
