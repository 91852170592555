import { Box, Flex, Icon, Input, Text } from "@chakra-ui/react";
import React from "react";
import "./Settings.css";
import { ImProfile } from "react-icons/im";
import { TbSettingsPin } from "react-icons/tb";
import { GoShieldLock } from "react-icons/go";

const Settings = ({ isMobileView, colorMode, userData }) => {
  return (
    <Box boxShadow={"md"} className="main-page-container">
      <Box className="settings-surround">
        <Box mb={3}>
          <Flex
            className="settings-title-text"
            alignItems={"center"}
            gap={"5px"}
          >
            <Text>Settings</Text>
            <Icon as={TbSettingsPin} />
          </Flex>
        </Box>
        <Box className="settings-sections">
          <Box
            className={`section ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
          >
            <Box>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={ImProfile} />
                <Text>Personal</Text>
              </Flex>
            </Box>
            <Flex className="settings-input-list">
              <Box w={"100%"}>
                <Text className="form-label-text">Name</Text>
                <Input
                  boxShadow={"md"}
                  size={"sm"}
                  bg={"white"}
                  borderRadius={"6px"}
                  placeholder={userData?.name}
                />
              </Box>
              <Box w={"100%"}>
                <Text className="form-label-text">Email</Text>
                <Input
                  boxShadow={"md"}
                  size={"sm"}
                  bg={"white"}
                  borderRadius={"6px"}
                  placeholder={userData?.email}
                />
              </Box>
              <Box w={"100%"}>
                <Text className="form-label-text">Phone</Text>
                <Input
                  boxShadow={"md"}
                  size={"sm"}
                  bg={"white"}
                  borderRadius={"6px"}
                />
              </Box>
            </Flex>
            <Flex className="settings-input-list">
              <Box w={"100%"}>
                <Text className="form-label-text">Country</Text>
                <Input
                  boxShadow={"md"}
                  size={"sm"}
                  bg={"white"}
                  borderRadius={"6px"}
                />
              </Box>
              <Box w={"100%"}>
                <Text className="form-label-text">City</Text>
                <Input
                  boxShadow={"md"}
                  size={"sm"}
                  bg={"white"}
                  borderRadius={"6px"}
                />
              </Box>
              <Box w={"100%"}>
                <Text className="form-label-text">State</Text>
                <Input
                  boxShadow={"md"}
                  size={"sm"}
                  bg={"white"}
                  borderRadius={"6px"}
                />
              </Box>
            </Flex>
          </Box>
          <Box
            className={`section ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
          >
            <Box>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={GoShieldLock} />
                <Text>Pasword</Text>
              </Flex>
            </Box>
            <Flex className="passwordinputdisabled">
              <Flex alignItems={"center"} justifyContent={"center"} w={"100%"}>
                <Input
                  boxShadow={"md"}
                  placeholder="*********"
                  w={"97%"}
                  size={"sm"}
                  bg={"white"}
                  borderRadius={"6px"}
                />
              </Flex>
            </Flex>
            <Flex mt={3} className="settings-input-list">
              <Box w={"100%"}>
                <Text className="form-label-text">New Password</Text>
                <Input
                  boxShadow={"md"}
                  size={"sm"}
                  bg={"white"}
                  borderRadius={"6px"}
                />
              </Box>
            </Flex>
            <Flex className="settings-input-list">
              <Box w={"100%"}>
                <Text className="form-label-text">Confirm New Password</Text>
                <Input
                  boxShadow={"md"}
                  size={"sm"}
                  bg={"white"}
                  borderRadius={"6px"}
                />
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
