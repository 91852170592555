import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Icon,
  Text,
  useToast,
  Center,
  Spinner,
  SimpleGrid,
  Image,
  Tooltip,
  Avatar,
} from "@chakra-ui/react";
import "./HomeMutualSpaceItem.css";
import clipArtImage from "../../Images/forimags.png";
import MainHomeBoardItem from "./MainHomeBoardItem";

const HomeMutualSpaceItem = ({ mutualSpace, colorMode, isMobileView }) => {
  const getUsersWithAccess = (mutualBoards) => {
    const usersMap = {};
    mutualBoards?.forEach((board) => {
      if (board?.usersWithAccess) {
        board?.usersWithAccess.forEach((user) => {
          usersMap[user.userId] = user.name;
        });
      }
    });
    return Object.entries(usersMap).map(([userId, name]) => ({ userId, name }));
  };

  const usersWithAccess = getUsersWithAccess(mutualSpace.MutualBoards);

  return (
    <Box
      className={`Main-HomeMutualSpaceItem-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        flexDirection={isMobileView ? "column" : "column"}
        gap={"10px"}
        h={"100%"}
      >
        <Flex justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
          <Text
            className={`main-cont-text ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
          >
            Mutual Boards
          </Text>
        </Flex>
        {isMobileView ? (
          <Flex gap={"20px"} h={"100%"}>
            {mutualSpace.MutualBoards?.map((board) => (
              <Box
                h={"100%"}
                w={"100%"}
                key={board._id}
                className="board-container"
              >
                <MainHomeBoardItem
                  mutualSpace={mutualSpace}
                  mutualSpaceId={mutualSpace._id}
                  boardId={board._id}
                  colorMode={colorMode}
                  board={board}
                />
              </Box>
            ))}
          </Flex>
        ) : (
          <>
            {" "}
            <SimpleGrid
              p={1}
              w={"100%"}
              mt={2}
              columns={{ base: 1, md: 3 }}
              spacing={6}
            >
              {mutualSpace.MutualBoards?.map((board) => (
                <Box w={"100%"} key={board._id} className="board-container">
                  <MainHomeBoardItem
                    mutualSpace={mutualSpace}
                    mutualSpaceId={mutualSpace._id}
                    boardId={board._id}
                    colorMode={colorMode}
                    board={board}
                  />
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default HomeMutualSpaceItem;
