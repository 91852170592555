import React, { useState, useRef } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import { BsImage } from "react-icons/bs";
import "./ArtworkAssetUpload.css";
import { API_ENDPOINT } from "../../../Config/config";

const ArtworkAssetUpload = ({
  selectedTableDataId,
  userName,
  userId,
  colorMode,
}) => {
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [imageName, setImageName] = useState(null);
  const [imageFile, setImageFile] = useState(null); // Added state to keep track of the file itself

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImageFile(file); // Keep track of the file
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const clearImage = () => {
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const uploadArtwork = async () => {
    if (!imageFile) return; // Ensure there's a file to upload

    setIsLoading(true);
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("userId", userId);
    formData.append("name", userName);
    formData.append("title", imageFile.name); // Example: using the file name as the title
    formData.append("description", "Uploaded via the web"); // Example: static description

    try {
      await axios.post(
        `${API_ENDPOINT}api/assets/table/${selectedTableDataId}/artwork`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast({
        title: "Artwork uploaded successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to upload artwork:", error);
      toast({
        title: "Error uploading artwork.",
        description: "Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      clearImage(); // Clear the preview and file input after upload
    }
  };

  return (
    <Box>
      <Input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
        ref={fileInputRef}
        id="image-upload"
      />
      <label htmlFor="image-upload">
        {!imagePreview && (
          <Box className="hdysiai">
            <Button
              variant={"outline"}
              as="span"
              colorScheme="blue"
              className="upload-beat-btn"
            >
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon className="modal-title-icon" as={BsImage} />
                <Text className="modal-title-text"> Upload Artwork</Text>
              </Flex>
            </Button>
          </Box>
        )}
      </label>
      {imagePreview && (
        <Box>
          <Flex className="previewimagecontainer">
            <Image
              className="artworkpreviewimg"
              src={imagePreview}
              alt="Preview"
            />
          </Flex>
          <Flex mt={4} alignItems={"center"} gap={"10px"}>
            <Button
              colorScheme="red"
              onClick={clearImage}
              w={"96%"}
              size={"sm"}
              variant={"outline"}
            >
              Clear
            </Button>
            <Button
              colorScheme="teal"
              isLoading={isLoading}
              w={"96%"}
              size={"sm"}
              onClick={uploadArtwork} // Trigger the upload process
            >
              {isLoading ? <Spinner size="sm" /> : "Submit"}
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default ArtworkAssetUpload;
