import { createContext, useState, useContext } from "react";

const NotificationsPanelContext = createContext();

export const useNotificationsPanel = () =>
  useContext(NotificationsPanelContext);

export const NotificationsPanelProvider = ({ children }) => {
  const [notificationsPanel, setNotificationsPanel] = useState(null);

  return (
    <NotificationsPanelContext.Provider
      value={{ notificationsPanel, setNotificationsPanel }}
    >
      {children}
    </NotificationsPanelContext.Provider>
  );
};
