import React, { useState } from "react";
import {
  Box,
  Flex,
  Tooltip,
  Button,
  Text,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  Badge,
  useToast,
} from "@chakra-ui/react";
import { BsCheck2All, BsHeadphones, BsTrash } from "react-icons/bs";
import { API_ENDPOINT } from "../../../Config/config";
import axios from "axios";
import { TbMicrophone } from "react-icons/tb";
import { MdDeleteForever } from "react-icons/md";
import { useCurrentLyric } from "../../../Context/CurrentLyricContext";
import { useAddBeatToLyric } from "../../../Context/AddBeatToLyricContext";
import { useAddRecordingToLyric } from "../../../Context/AddRecordingToLyricContext";
import { useAddLyricToRelease } from "../../../Context/AddLyricToRelease";
import { useLyricsPanel } from "../../../Context/LyricsPanelContext";
import "./AssetLyricsItem.css";
import LyricStatusItem from "../LyricStatusItem";
import LyricBeatPlayer from "../../Players/LyricBeatPlayer/LyricBeatPlayer";
import LyricRecordingPlayer from "../../Players/LyricRecordingPlayer/LyricRecordingPlayer";

const AssetLyricsItem = ({
  lyricAsset,
  userName,
  userId,
  colorMode,
  selectedTableDataId,
  openDetailsPanelSettings,
  guestToken,
  lyricAssetId,
  isMobileView,
  fetchLyricAssets,
}) => {
  const [activeTab, setActiveTab] = useState("beat");
  const { setAddLyric } = useAddLyricToRelease();
  const { setLyricsPanel } = useLyricsPanel();
  const [isLoading, setIsLoading] = useState(false);
  const { currentLyric, setCurrentLyric } = useCurrentLyric();
  const { setAddBeatToLyric } = useAddBeatToLyric();
  const { setAddRecordingToLyric } = useAddRecordingToLyric();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toast = useToast();

  const toAddLyricToRelease = (event) => {
    event.stopPropagation(); // Stop the event from propagating further

    setAddLyric("AddLyricToRelease");
    setLyricsPanel(null);
    setAddRecordingToLyric(null);
  };

  const toLyricPanel = (event) => {
    event.stopPropagation();
    setCurrentLyric(lyricAsset);

    setLyricsPanel("Lyrics");
    setAddLyric(null);
    setAddBeatToLyric(null);
    openDetailsPanelSettings();
    setAddRecordingToLyric(null);
  };

  const handleDeleteLyric = async () => {
    setIsLoading(true); // Start loading
    try {
      await axios.delete(
        `${API_ENDPOINT}api/assets/table/${selectedTableDataId}/lyric/${lyricAssetId}`
      );
      // Assuming `lyricAssetId` is the ID of the lyric asset you want to delete
      toast({
        title: "Lyric deleted.",
        description: "The lyric has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteModalOpen(false); // Close the modal after deletion
      fetchLyricAssets();
    } catch (error) {
      console.error("Error deleting the lyric:", error);
      toast({
        title: "Error deleting lyric.",
        description:
          "There was an issue deleting your lyric. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <Box
      onClick={toLyricPanel}
      boxShadow={"md"}
      className={`lyricAsset-conainter ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex>
        <LyricStatusItem />
      </Flex>
      <Flex
        className={`lyricAsset-pad ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Text className="lyricAssetlyricText">{lyricAsset.lyricsText}</Text>
      </Flex>
      <Flex
        alignItems={"center"}
        gap={"10px"}
        className="lyricAsset-players-tabs"
      >
        <Tooltip placement="top" borderRadius={"7px"} label={"Paired Beat"}>
          <Button
            colorScheme={activeTab === "beat" ? "blue" : "gray"}
            onClick={() => setActiveTab("beat")}
            w={"100%"}
            size={"xs"}
          >
            <Flex alignItems={"center"} gap={"3px"}>
              <Icon as={BsHeadphones} />
              <Text>Beat</Text>
            </Flex>
          </Button>
        </Tooltip>
        <Tooltip
          placement="top"
          borderRadius={"7px"}
          label={"Paired Recording"}
        >
          <Button
            colorScheme={activeTab === "recording" ? "blue" : "gray"}
            onClick={() => setActiveTab("recording")}
            w={"100%"}
            size={"xs"}
          >
            <Flex alignItems={"center"} gap={"3px"}>
              <Icon as={TbMicrophone} />
              <Text> Recording</Text>
            </Flex>
          </Button>
        </Tooltip>
      </Flex>
      <Flex className="lyricAsset-players-tabs">
        {activeTab === "beat" &&
          (lyricAsset?.beat ? (
            <LyricBeatPlayer
              colorMode={colorMode}
              lyricAssetId={lyricAssetId}
              currentLyric={currentLyric}
              beat={lyricAsset.beat}
              isMobileView={isMobileView}
              setCurrentLyric={setCurrentLyric}
            />
          ) : (
            <Button w={"100%"}>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={BsHeadphones} />
                <Text>Add Beat</Text>
              </Flex>
            </Button>
          ))}
        {activeTab === "recording" &&
          (lyricAsset?.recording ? (
            <LyricRecordingPlayer
              lyricAssetId={lyricAssetId}
              recording={lyricAsset.recording}
              colorMode={colorMode}
              isMobileView={isMobileView}
              currentLyric={currentLyric}
              setCurrentLyric={setCurrentLyric}
            />
          ) : (
            <Button w={"100%"}>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={TbMicrophone} />
                <Text>Add Recording</Text>
              </Flex>
            </Button>
          ))}
      </Flex>
      <Flex
        className="lyricAsset-players-tabs"
        alignItems={"center"}
        gap={"8px"}
      >
        <Tooltip placement="bottom" borderRadius={"7px"} label={"Delete Lyric"}>
          <IconButton
            colorScheme="red"
            variant={"outline"}
            w={"50%"}
            icon={<BsTrash />}
            size={"xs"}
            onClick={(e) => {
              e.stopPropagation();
              setIsDeleteModalOpen(true); // Prevent triggering parent's onClick
            }}
          />
        </Tooltip>
        {!lyricAsset.release ? (
          <>
            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Add Lyric to Release"}
            >
              <Button colorScheme="green" size={"xs"} w={"100%"}>
                <Flex alignItems={"center"} gap={"5px"}>
                  <Text>Add to Release</Text>
                </Flex>
              </Button>
            </Tooltip>
          </>
        ) : (
          <Tooltip
            placement="top"
            borderRadius={"7px"}
            label={"Lyric is Released!"}
          >
            <Badge
              p={1}
              w={"100%"}
              colorScheme="green"
              variant="subtle"
              borderRadius={"7px"}
              h={"100%"}
            >
              <Flex justifyContent={"center"} alignItems={"center"} gap={"3px"}>
                <Icon as={BsCheck2All} />
                <Text fontSize={"9px"}>Released</Text>
              </Flex>
            </Badge>
          </Tooltip>
        )}
      </Flex>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={MdDeleteForever} />
              <Text>Delete Lyric</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete the lyric {lyricAsset.title}?
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
              <Button
                w={"100%"}
                colorScheme="gray"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={handleDeleteLyric} // Add the onClick handler here
                w={"100%"}
                colorScheme="red"
              >
                Delete Lyric
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AssetLyricsItem;
