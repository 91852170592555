import React from "react";
import "./NoBeats.css";
import { Box, Flex, Icon, Text, Button } from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";
import "./NoChannels.css";

const NoChannels = ({
  colorMode,
  isMobileView,
  onOpen,
  setIsAddChannelModalOpen,
}) => {
  return (
    <Box
      className={`nochannels-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        gap={"8px"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text
          className={`nochannels-text ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
          textAlign={"center"}
        >
          Create a channel!
        </Text>
        <Flex mb={1} justifyContent={"center"} w={"100%"}>
          <Button
            size={"sm"}
            onClick={() => setIsAddChannelModalOpen(true)}
            colorScheme="blue"
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={BsPlusLg} />
              <Text>New </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NoChannels;
