import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  CircularProgress,
  useToast,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../../Config/config";
import "./ArtworkUploadModal.css";

const ArtworkUploadModal = ({
  setIsArtworkModalOpen,
  release,
  colorMode,
  fetchReleases,
}) => {
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const [artworksData, setArtworksData] = useState([]);
  const [selectedArtwork, setSelectedArtwork] = useState(null);
  const [addingArtworks, setAddingArtworks] = useState(false);

  useEffect(() => {
    const fetchArtworksData = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT}api/mutualspace/artwork/all`
        );
        if (response.data.status === "success") {
          setArtworksData(response.data.artwork);
        } else {
          toast({
            title: "Error fetching artworks",
            description: response.data.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error fetching artworks",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchArtworksData();
  }, [toast]);

  const handleSelectArtwork = (artwork) => {
    setSelectedArtwork(artwork);
  };

  const handleBack = () => {
    setSelectedArtwork(null);
  };

  const handleAddArtworkToRelease = async () => {
    setAddingArtworks(true); // Set the loading state
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/release/${release._id}/addArtwork`,
        selectedArtwork // Use the selected artwork object
      );
      if (response.data.status === "success") {
        toast({
          title: "Success",
          description: "Artwork added to release successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setIsArtworkModalOpen(false); // Close the modal if needed
        fetchReleases();
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setAddingArtworks(false); // Reset the loading state
    }
  };

  return (
    <Box h={"100%"} overflowX={"auto"}>
      {loading ? (
        <CircularProgress isIndeterminate color="green.300" />
      ) : selectedArtwork ? (
        <Box p={4} borderWidth={1} borderRadius="lg" mb={4}>
          <Text fontSize="xl" fontWeight="bold">
            {selectedArtwork.title}
          </Text>
          <Image
            boxSize={"180px"}
            src={selectedArtwork.imageUrl}
            alt={selectedArtwork.title}
            style={{ maxWidth: "100%" }}
          />
          <Text mt={2}>
            <strong>Upload Date:</strong>{" "}
            {new Date(selectedArtwork.uploadDate).toLocaleDateString()}
          </Text>
          <Text mt={2}>
            <strong>Status:</strong> {selectedArtwork.status}
          </Text>
          <Flex mt={4} justifyContent="space-between">
            <Button onClick={handleBack}>Back</Button>
            <Button
              colorScheme="blue"
              onClick={handleAddArtworkToRelease}
              isLoading={addingArtworks}
            >
              Add Artwork to Release
            </Button>
          </Flex>
        </Box>
      ) : (
        <Box className="RecordingUploadModal-main-container">
          <SimpleGrid mt={2} columns={{ base: 1, md: 2 }} spacing={4}>
            {artworksData.map((artwork) => (
              <Box
                key={artwork._id}
                p={4}
                borderWidth={1}
                borderRadius="lg"
                mb={4}
                onClick={() => handleSelectArtwork(artwork)}
                cursor="pointer"
              >
                <Text fontSize="xl" fontWeight="bold">
                  {artwork.title}
                </Text>
                <Image
                  boxSize={"100px"}
                  src={artwork.imageUrl}
                  alt={artwork.title}
                />
                <Text mt={2}>
                  <strong>Upload Date:</strong>{" "}
                  {new Date(artwork.uploadDate).toLocaleDateString()}
                </Text>
                <Text mt={2}>
                  <strong>Status:</strong> {artwork.status}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
};

export default ArtworkUploadModal;
