import { Box } from "@chakra-ui/react";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Box boxShadow={"md"} className="main-page-container">
      <Box>PrivacyPolicy</Box>
    </Box>
  );
};

export default PrivacyPolicy;
