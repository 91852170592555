import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Input,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  SimpleGrid,
  Portal,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { BsPlus } from "react-icons/bs";
import "./BeatStatusItem.css";

const BeatStatusItem = ({ beatAsset, selectedTableDataId, colorMode }) => {
  const [newStatusInput, setNewStatusInput] = useState("");
  const [customStatuses, setCustomStatuses] = useState([]);
  const [statusColors, setStatusColors] = useState({});

  const toast = useToast();

  // const updateBeatStatus = async (newStatus) => {
  //   try {
  //     await axios.put(
  //       `${API_ENDPOINT}api/assets/table/${selectedTableDataId}/beat/${beatAsset._id}/status`,
  //       {
  //         newStatus,
  //       }
  //     );
  //     toast({
  //       title: "Status Updated",
  //       description: "The beat status has been successfully updated.",
  //       status: "success",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   } catch (error) {
  //     console.error("Error updating beat status:", error);
  //     toast({
  //       title: "Error",
  //       description: "There was an error updating the beat status.",
  //       status: "error",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   }
  // };

  // const handleAddCustomStatus = async () => {
  //   const trimmedStatus = newStatusInput.trim();
  //   if (!trimmedStatus) return;

  //   try {
  //     await axios.post(
  //       `${API_ENDPOINT}api/assets/table/${selectedTableDataId}/customstatuses`,
  //       {
  //         status: trimmedStatus,
  //       }
  //     );
  //     setCustomStatuses([
  //       ...customStatuses,
  //       { status: trimmedStatus, color: null },
  //     ]);
  //     setNewStatusInput("");
  //     toast({
  //       title: "Success",
  //       description: "New status added successfully.",
  //       status: "success",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //     await updateBeatStatus(trimmedStatus);
  //   } catch (error) {
  //     console.error("Error adding custom status:", error);
  //     toast({
  //       title: "Error",
  //       description: "Could not add new status.",
  //       status: "error",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   }
  // };

  // const fetchCustomStatuses = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${API_ENDPOINT}api/assets/table/${selectedTableDataId}/customstatuses`
  //     );
  //     setCustomStatuses(response.data.customStatuses || []);
  //     const colors = {};
  //     response.data.customStatuses.forEach((status) => {
  //       colors[status.status] = status.color || "transparent";
  //     });
  //     setStatusColors(colors);
  //   } catch (error) {
  //     console.error("Error fetching custom statuses:", error);
  //     toast({
  //       title: "Error",
  //       description: "Failed to load custom statuses.",
  //       status: "error",
  //       duration: 9000,
  //       isClosable: true,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   fetchCustomStatuses();
  // }, [selectedTableDataId, toast]);

  const colorOptions = [
    "#E53E3E", // Red.500
    "#3182CE", // Blue.500
    "#38A169", // Green.500
    "#DD6B20", // Orange.500
    "#805AD5", // Purple.500
    colorMode === "light" ? "rgba(251, 170, 74, 1)" : "rgba(252, 187, 107, 1)", // Yellow.500
    "#4FD1C5", // Teal.500
    "#ED64A6", // Pink.500
    "#718096", // Gray.500
    "#F687B3", // Pink.300 - Lighter for contrast in dark mode
  ];

  // const handleUpdateColor = async (status, color) => {
  //   try {
  //     await axios.patch(
  //       `${API_ENDPOINT}api/assets/table/${selectedTableDataId}/customstatuses/${status}`,
  //       { color }
  //     );
  //     setCustomStatuses(
  //       customStatuses.map((cs) =>
  //         cs.status === status ? { ...cs, color } : cs
  //       )
  //     );
  //     toast({
  //       title: "Color Updated",
  //       description: `The color for the status "${status}" has been successfully updated.`,
  //       status: "success",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   } catch (error) {
  //     console.error("Error updating status color:", error);
  //     toast({
  //       title: "Error",
  //       description: "There was an error updating the status color.",
  //       status: "error",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   }
  // };

  return (
    <Box>
      <Menu>
        <MenuButton
          onClick={(event) => event.stopPropagation()} // Stop event propagation
          size="xs"
          as={Button}
          rightIcon={<ChevronDownIcon />}
          variant="outline"
          w={"100%"}
          border={"none"}
          className="beatAsset-menu-button-text"
          backgroundColor={statusColors[beatAsset.status] || "transparent"}
          _hover={{ backgroundColor: beatAsset?.status?.color }}
        >
          {beatAsset.status || "Set Status"}
        </MenuButton>
        <MenuList
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <Flex alignItems={"center"} gap={"5px"} p={1}>
            <Input
              borderRadius={"3px"}
              placeholder="Add Status..."
              size={"xs"}
              width={"100%"}
              onChange={(e) => setNewStatusInput(e.target.value)}
              value={newStatusInput}
              onClick={(e) => e.stopPropagation()}
            />
            <Tooltip
              label="Add Status"
              hasArrow
              placement="top"
              closeOnClick={false}
            >
              <IconButton
                icon={<BsPlus />}
                // onClick={(e) => {
                //   e.stopPropagation();
                //   handleAddCustomStatus();
                // }}
                size={"xs"}
                colorScheme="green"
              />
            </Tooltip>
          </Flex>
          {customStatuses.map((status, index) => (
            <MenuItem
              bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
              key={index}
              // onClick={() => updateBeatStatus(status.status)}
            >
              <Flex alignItems={"center"} gap={"10px"}>
                <Popover>
                  <PopoverTrigger>
                    <Box
                      as="button" // Ensures the box can be focused and interacted with like a button
                      w="20px"
                      h="20px"
                      border="1px solid" // Optional: adds a border to the square
                      borderColor="gray.300" // Optional: sets the border color
                      backgroundColor={status.color || "transparent"}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent w="auto">
                      <SimpleGrid columns={5} spacing={2} p={2}>
                        {colorOptions.map((color, colorIndex) => (
                          <Box
                            key={colorIndex}
                            w="20px"
                            h="20px"
                            bg={color}
                            // onClick={() =>
                            //   handleUpdateColor(status.status, color)
                            // }
                          />
                        ))}
                      </SimpleGrid>
                    </PopoverContent>
                  </Portal>
                </Popover>
                <Text className="statusbeat">{status.status}</Text>
              </Flex>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default BeatStatusItem;
