import React, { useContext, useEffect, useState } from "react";
import {
  Text,
  IconButton,
  Flex,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { BsPlayCircle, BsPauseCircle } from "react-icons/bs";
import { MusicPlayerContext } from "../../../Context/MusicPlayerContext";
import "./LyricRecordingPlayer.css";
import { TbTrash } from "react-icons/tb";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const LyricRecordingPlayer = ({
  recording,
  colorMode,
  isMobileView,
  refreshRecordingLink,
  currentLyric,
  setCurrentLyric,
  lyricId,
}) => {
  const { playTrack, stopTrack, currentTrack } = useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack &&
    currentTrack.src === recording.fileUrl &&
    !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(recording.fileUrl);
    }
  };

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const handleOpenConfirm = () => setIsConfirmOpen(true);
  const handleCloseConfirm = () => setIsConfirmOpen(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteRecording = async () => {
    setIsDeleting(true);
    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/lyrics/${lyricId}/remove-recording`
      );
      if (response.status === 200) {
        console.log("Recording removed successfully");
      }
      setCurrentLyric({ ...currentLyric, recording: null });
    } catch (error) {
      console.error("Error removing beat:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Flex
      className={`recordinglyricPlayercontainer ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      alignItems={"center"}
      gap={"5px"}
    >
      <Flex alignItems={"center"} gap={isMobileView ? "7px" : "5px"}>
        <Flex p={1} h={"100%"} alignItems={"center"} justifyContent={"center"}>
          {isCurrentBeatPlaying ? (
            <>
              <IconButton
                size={"sm"}
                onClick={(event) => {
                  togglePlayPause(); // Call the original saveProducerName function
                }}
                icon={<BsPauseCircle className="main-player-button" />}
              />
            </>
          ) : (
            <>
              <IconButton
                size={"sm"}
                onClick={(event) => {
                  togglePlayPause(); // Call the original saveProducerName function
                }}
                icon={<BsPlayCircle className="main-player-button" />}
              />
            </>
          )}
        </Flex>
        <Flex gap={"3px"} flexDirection={"column"}>
          <Flex flexDirection={"column"} gap={"1px"}>
            <Text className="linkedrecordingname">{recording?.title}</Text>
            <Text className="linkedrecordingartist">Artist</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent={"center"} w={"100%"}>
        <Tooltip
          placement="top"
          borderRadius={"7px"}
          label={"Remove Recording"}
        >
          <IconButton
            onClick={handleOpenConfirm} // Open confirmation modal
            icon={<TbTrash />}
            size={"xs"}
            colorScheme="red"
          />
        </Tooltip>
      </Flex>
      <Modal isOpen={isConfirmOpen} onClose={handleCloseConfirm}>
        <ModalOverlay />
        <ModalContent
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Remove Recording</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove this recording linked to this lyric?
          </ModalBody>
          <ModalFooter>
            <Button
              w={"100%"}
              colorScheme="gray"
              mr={3}
              onClick={handleCloseConfirm}
            >
              Cancel
            </Button>
            <Button
              w={"100%"}
              colorScheme="red"
              onClick={() => {
                handleCloseConfirm();
                handleDeleteRecording();
              }}
              isLoading={isDeleting}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default LyricRecordingPlayer;
