import React, { useState, useRef } from "react";
import {
  Button,
  Flex,
  Text,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  IconButton,
  MenuDivider,
  Input,
  useToast,
} from "@chakra-ui/react";
import { FaTasks, FaEllipsisV } from "react-icons/fa";
import { EditIcon } from "@chakra-ui/icons";
import { BiFolder, BiShare, BiTrash } from "react-icons/bi";
import { API_ENDPOINT } from "../../../../Config/config";
import axios from "axios";

const FolderButton = ({
  board,
  closedetailsPanelSetting,
  handleFolderChange,
  colorMode,
  activeTab,
  activeFolderId,
  guestToken,
  boardId,
  mutualSpaceId,
  fetchBoardDetails,
}) => {
  const [showMenuButton, setShowMenuButton] = useState({});
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false); // New state for delete confirmation modal
  const [menuOpen, setMenuOpen] = useState({});
  const [isEditing, setIsEditing] = useState({});
  const [buttonLabels, setButtonLabels] = useState(() => {
    const labels = {};
    board?.folders?.forEach((folder) => {
      labels[folder._id] = folder.folderName;
    });
    return labels;
  });
  const toast = useToast();
  const [currentFolderId, setCurrentFolderId] = useState(null);

  const handleRename = (id) => {
    setIsEditing((prev) => ({ ...prev, [id]: true }));
    setMenuOpen((prev) => ({ ...prev, [id]: false })); // Optionally close the menu when starting editing
  };

  const handleLabelChange = (e, id) => {
    setButtonLabels((prev) => ({ ...prev, [id]: e.target.value }));
  };

  const handleLabelSave = (e, id) => {
    if (e.key === "Enter") {
      setIsEditing((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleBlur = (id) => {
    setIsEditing((prev) => ({ ...prev, [id]: false }));
  };

  const handleDeleteFolder = async () => {
    try {
      const deleteUrl = `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/folder/${currentFolderId}`;
      const response = await axios.delete(deleteUrl);
      if (response.status === 200) {
        toast({
          title: "Folder Deleted",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setDeleteModalOpen(false);
        fetchBoardDetails();
      } else {
        toast({
          title: "Error",
          description: "Failed to delete the folder.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: `Failed to delete the folder. ${
          error.response?.data?.message || error.message
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex alignItems={"center"} gap={"10px"}>
      {board?.folders?.map((folder, index) => (
        <Menu
          w={"100%"}
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          onOpen={() =>
            setMenuOpen((prev) => ({ ...prev, [folder._id]: true }))
          }
          onClose={() =>
            setMenuOpen((prev) => ({ ...prev, [folder._id]: false }))
          }
        >
          <Button
            key={folder._id}
            size={"sm"}
            variant={
              activeTab === "Folder" && activeFolderId === folder._id
                ? "solid"
                : "outline"
            }
            onClick={() => {
              closedetailsPanelSetting();
              handleFolderChange(folder.folderName, folder._id);
            }}
            onMouseEnter={() =>
              setShowMenuButton((prev) => ({ ...prev, [folder._id]: true }))
            }
            onMouseLeave={() =>
              setShowMenuButton((prev) => ({ ...prev, [folder._id]: false }))
            }
          >
            <Flex
              w={"100%"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"5px"}
            >
              <Icon as={BiFolder} />
              {isEditing[folder._id] ? (
                <Input
                  autoFocus
                  value={buttonLabels[folder._id] ?? folder.folderName}
                  onChange={(e) => handleLabelChange(e, folder._id)}
                  onKeyDown={(e) => handleLabelSave(e, folder._id)}
                  onBlur={() => handleBlur(folder._id)}
                  size="xs"
                  className={`tab-btn-styles ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                  w={"100px"}
                />
              ) : (
                <Text
                  className={`tab-btn-styles ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  {buttonLabels[folder._id] ?? folder.folderName}
                </Text>
              )}
              {(showMenuButton[folder._id] || menuOpen[folder._id]) && (
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FaEllipsisV />}
                  size="xs"
                  variant="outline"
                />
              )}
            </Flex>
          </Button>
          <MenuList bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}>
            <MenuItem
              isDisabled={!!guestToken}
              onClick={() => handleRename(folder._id)}
              fontSize={"13px"}
              bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
              _hover={{
                bg:
                  colorMode === "light"
                    ? "rgba(241, 244, 249, 1)"
                    : "rgba(42, 49, 70, 1)",
              }}
            >
              <Flex alignItems={"center"} gap={"10px"}>
                <Icon as={EditIcon} />
                <Text>Rename</Text>
              </Flex>
            </MenuItem>
            <MenuItem
              fontSize={"13px"}
              bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
              _hover={{
                bg:
                  colorMode === "light"
                    ? "rgba(241, 244, 249, 1)"
                    : "rgba(42, 49, 70, 1)",
              }}
            >
              <Flex alignItems={"center"} gap={"10px"}>
                <Icon as={BiShare} />
                <Text>Share</Text>
              </Flex>
            </MenuItem>
            <MenuDivider />

            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setCurrentFolderId(folder._id);
                setDeleteModalOpen(true);
              }}
              isDisabled={!!guestToken}
              fontSize={"13px"}
              bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
              _hover={{
                bg:
                  colorMode === "light"
                    ? "rgba(241, 244, 249, 1)"
                    : "rgba(42, 49, 70, 1)",
              }}
              color={colorMode === "light" ? "red.500" : "red.300"}
            >
              <Flex alignItems={"center"} gap={"10px"}>
                <Icon as={BiTrash} />
                <Text>Delete</Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      ))}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this folder?</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button onClick={handleDeleteFolder} colorScheme="red">
              Delete Folder
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default FolderButton;
