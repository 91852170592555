import React, { useState, useEffect, useRef } from "react";
import "./MobileSendMessageInput.css";
import Picker from "@emoji-mart/react";
import {
  Box,
  useColorModeValue,
  IconButton,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { BsSend, BsEmojiSmileFill, BsPlusLg } from "react-icons/bs";
import data from "@emoji-mart/data";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { RiFontSize } from "react-icons/ri";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const MobileSendMessageInput = ({
  colorMode,
  userName,
  userId,
  isMobileViewm,
  selectedChannelId,
  refreshSelectedChannel,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [message, setMessage] = useState("");
  const toast = useToast();
  const pickerRef = useRef(null);
  const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);
  const bgColor = useColorModeValue("white", "");

  const handleEmojiSelect = (emoji) => {
    const newText = `${message}${emoji.native}`;
    setMessage(newText);
    setShowEmojiPicker(false);
  };

  const CustomToolbartwo = () => (
    <Box p={1} id="toolbar" display="flex" justifyContent="space-between">
      <Box display="flex" gap="2">
        <Tooltip label="Attach" aria-label="Bold">
          <IconButton icon={<BsPlusLg />} borderRadius={"full"} size="sm" />
        </Tooltip>
        <Tooltip label="Font" aria-label="Bold">
          <IconButton icon={<RiFontSize />} size="sm" />
        </Tooltip>
        <Tooltip label="Emoji" aria-label="Bold">
          <IconButton
            onClick={toggleEmojiPicker}
            icon={<BsEmojiSmileFill />}
            size="sm"
          />
        </Tooltip>
      </Box>
      <Tooltip label="Send" aria-label="Send">
        <IconButton onClick={sendMessage} icon={<BsSend />} size="sm" />
      </Tooltip>
    </Box>
  );

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  const formats = ["bold", "italic", "strike", "code-block", "list", "bullet"];

  useEffect(() => {
    function handleClickOutside(event) {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    }
    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  const stripHtml = (html) => {
    const temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  const sendMessage = async () => {
    const plainTextMessage = stripHtml(message);

    if (!plainTextMessage.trim() || !selectedChannelId) {
      toast({
        title: "Cannot send message",
        description: "Please select a channel and enter a message.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      await axios.post(
        `${API_ENDPOINT}api/channels/${selectedChannelId}/messages`,
        {
          userId,
          name: userName,
          messageContent: plainTextMessage,
        }
      );
      setMessage("");
      refreshSelectedChannel();
      toast({
        title: "Message Sent",
        description: "Your message was successfully sent.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to send message:", error);
      toast({
        title: "Failed to send message",
        description: "An error occurred while sending your message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      boxShadow={"md"}
      border={
        colorMode === "light"
          ? ".5px solid rgba(187, 186, 187, 1)"
          : "1px solid rgba(128, 128, 128, 1)"
      }
      bg={bgColor}
      borderRadius={"7px"}
    >
      {showEmojiPicker && (
        <Box
          position="absolute"
          zIndex={"9999"}
          bottom="50px"
          right="0px"
          ref={pickerRef}
        >
          <Picker data={data} onEmojiSelect={handleEmojiSelect} />
        </Box>
      )}

      <ReactQuill
        theme="snow"
        value={message}
        onChange={(value) => setMessage(value)} // Corrected line
        modules={modules}
        formats={formats}
        placeholder="Start a new message"
        className="mobileReactQuill"
      />
      <Box
        bg={
          colorMode === "light"
            ? "rgba(248, 248, 248, 1)"
            : "rgba(40, 49, 70, 1)"
        }
        borderBottomLeftRadius={"7px"}
        borderBottomRightRadius={"7px"}
      >
        <CustomToolbartwo />
      </Box>
    </Box>
  );
};

export default MobileSendMessageInput;
