import React from "react";
import { Box } from "@chakra-ui/react";
import "./MainLanding.css";
import MainLandingHero from "../../Components/Hero/MainLandingHero";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT } from "../../Config/config";
import axios from "axios";
import Hero2 from "./Hero2";
import HomeHero3 from "./HomeHero3";
import BoxFeaturesLanding from "./BoxFeaturesLanding";
import MainHomeHero4 from "./MainHomeHero4";

const MainLanding = ({
  isMobileView,
  toggleSidebar,
  colorMode,
  setCookie,
  guestToken,
}) => {
  const navigate = useNavigate();

  const handleDemoAccess = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}api/guest-access`);
      const { token } = response.data;

      setCookie("GuestToken", token, { path: "/" });

      navigate("/dash");
    } catch (error) {
      console.error("Error accessing demo account:", error);
    }
  };

  return (
    <Box
      className={`mainlanding-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <MainLandingHero
        handleDemoAccess={handleDemoAccess}
        colorMode={colorMode}
        isMobileView={isMobileView}
      />
      <Hero2 colorMode={colorMode} isMobileView={isMobileView} />
      {!isMobileView && (
        <>
          <HomeHero3 colorMode={colorMode} isMobileView={isMobileView} />
        </>
      )}{" "}
      <BoxFeaturesLanding colorMode={colorMode} isMobileView={isMobileView} />
      <MainHomeHero4 colorMode={colorMode} isMobileView={isMobileView} />
    </Box>
  );
};

export default MainLanding;
