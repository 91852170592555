import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  useColorModeValue,
  Tooltip,
  FormLabel,
  FormControl,
  Flex,
  List,
  ListItem,
  Text,
  Spinner,
  Image,
  useToast,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import "./SearchSpotify.css";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const SearchSpotify = ({
  colorMode,
  userId,
  userName,
  fetchReleases,
  setIsNewModalOpen,
}) => {
  const bg = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("gray.600", "white");
  const hoverBg = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const inputBg = useColorModeValue("gray.50", "gray.700");

  const [showDropdown, setShowDropdown] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const toast = useToast();

  const handleFocus = () => {
    if (query.length > 0) {
      setShowDropdown(true);
    }
  };

  const handleBlur = () => {
    setTimeout(() => setShowDropdown(false), 100); // Delay to allow click event on dropdown
  };

  const handleChange = async (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length > 0) {
      setSearchLoading(true);
      try {
        const response = await axios.get(
          `${API_ENDPOINT}api/spotify/song-search`,
          {
            params: { q: value },
          }
        );
        setResults(response.data.tracks.items);
        setSearchLoading(false);
        setShowDropdown(true);
      } catch (err) {
        console.error("Error fetching search results:", err);
        setSearchLoading(false);
        setShowDropdown(false);
      }
    } else {
      setShowDropdown(false);
    }
  };

  const handleSelect = (track) => {
    console.log("Selected Track:", track); // Debug log

    setSelectedTrack(track);
    setShowDropdown(false);
  };

  const handleClear = () => {
    setSelectedTrack(null);
  };

  const saveRelease = async () => {
    setSaveLoading(true);
    try {
      const releaseData = {
        userId,
        name: userName,
        releaseName: selectedTrack.name,
        artist: selectedTrack.artists.map((artist) => artist.name).join(", "),
        releaseDate: selectedTrack.album.release_date,
        releaseType: "Spotify", // You can adjust the release type as needed
        selectedLyrics: [], // Adjust based on your data
        selectedArtwork: selectedTrack.album.images[0]?.url
          ? [selectedTrack.album.images[0].url]
          : [],
        selectedRecordings: [], // Adjust based on your data
        schedule: {}, // Adjust based on your data
        spotifyTrackId: selectedTrack.id,
        status: "Released",
      };

      const response = await axios.post(
        `${API_ENDPOINT}api/release/save`,
        releaseData
      );

      if (response.data.status === "success") {
        toast({
          title: "Release saved.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
        handleClear();
        fetchReleases();
        setIsNewModalOpen(false);
      } else {
        throw new Error("Failed to save release");
      }
    } catch (error) {
      console.error("Error saving release:", error);
      toast({
        title: "Error saving release.",
        description: "There was an error saving the release.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <Flex className="main-SearchSpotify" mt={4}>
      <InputGroup>
        <Input
          id="song-title"
          type="text"
          placeholder="Search By Song Title"
          bg={inputBg}
          borderColor={borderColor}
          value={query}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <InputRightElement
          children={
            <Tooltip label="Search" aria-label="A tooltip">
              <Button size="sm" variant="ghost">
                <SearchIcon color="green.500" />
              </Button>
            </Tooltip>
          }
        />
      </InputGroup>
      {showDropdown && (
        <Flex
          className={`SearchSpotify-dropdown ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          {searchLoading ? (
            <Flex justify="center" align="center" width="100%">
              <Spinner size="md" />
            </Flex>
          ) : (
            <List width="100%">
              {results.map((track) => (
                <ListItem
                  mt={3}
                  _hover={{
                    bg:
                      colorMode === "light"
                        ? "rgba(241, 244, 249, 1)"
                        : "rgba(42, 49, 70, 1)",
                  }}
                  key={track.id}
                  p={2}
                  borderBottom="1px solid gray"
                  onClick={() => handleSelect(track)}
                  cursor="pointer"
                >
                  <Flex align="center">
                    <Image
                      boxSize="50px"
                      src={track.album.images[0]?.url}
                      alt={track.name}
                      mr={4}
                    />
                    <Box>
                      <Text fontWeight="bold">{track.name}</Text>
                      <Text fontSize="sm">
                        {track.artists.map((artist) => artist.name).join(", ")}
                      </Text>
                      <Text fontSize="xs" color="gray.500">
                        {track.album.release_date}
                      </Text>
                    </Box>
                  </Flex>
                </ListItem>
              ))}
            </List>
          )}
        </Flex>
      )}

      {selectedTrack && (
        <Box
          mt={4}
          p={4}
          border="1px solid"
          borderColor={borderColor}
          borderRadius="md"
        >
          <Flex align="center">
            <Image
              boxSize="100px"
              src={selectedTrack.album.images[0]?.url}
              alt={selectedTrack.name}
              mr={4}
            />
            <Box>
              <Text fontWeight="bold" fontSize="lg">
                {selectedTrack.name}
              </Text>
              <Text fontSize="md">
                {selectedTrack.artists.map((artist) => artist.name).join(", ")}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {selectedTrack.album.release_date}
              </Text>
            </Box>
          </Flex>
          <Flex
            w={"100%"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Button mt={4} onClick={handleClear} leftIcon={<CloseIcon />}>
              Clear
            </Button>
            <Button
              colorScheme="green"
              mt={4}
              onClick={saveRelease}
              isLoading={saveLoading}
              loadingText="Saving"
            >
              Save Release
            </Button>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default SearchSpotify;
