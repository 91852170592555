import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Icon,
  Button,
  Tooltip,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Badge,
  Image,
} from "@chakra-ui/react";
import { BsActivity, BsHeadphones } from "react-icons/bs";
import { FaComment, FaCircleCheck, FaLink, FaComments } from "react-icons/fa6";
import "./ArtworksPanel.css";
import { FaTimes } from "react-icons/fa";
import ArtworkComments from "../../Comment/ArtworkComments";
import { BiSolidUserRectangle } from "react-icons/bi";
import { TbMicrophone } from "react-icons/tb";
import CurrentArtworkStatus from "./CurrentArtworkStatus";

const ArtworksPanel = ({ colorMode, currentArtwork, userId, userName }) => {
  return (
    <Flex
      className={`main-artworkspanel-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        className={`artworkspanel-panel-topone ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        {currentArtwork?._id}
        <CurrentArtworkStatus
          userId={userId}
          artworkAsset={currentArtwork}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={currentArtwork?.mutualSpaceId}
          boardId={currentArtwork?.boardId}
          tableId={currentArtwork?.tableId}
          artworkId={currentArtwork?._id}
          fetchArtworkAssets={currentArtwork?.fetchArtworkAssets}
        />
      </Flex>
      <Flex
        className={`artworkspanel-bottomone ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex
          pb={2}
          borderBottom={".5px solid grey"}
          gap={"8px"}
          alignItems={"center"}
          w={"100%"}
        >
          <Icon as={FaComments} />
          <Text className="commenttitle">Comments</Text>
        </Flex>

        {/* <CurrentBeatComments
        userId={userId}
        currentBeat={currentBeat}
        colorMode={colorMode}
        userName={userName}
        mutualSpaceId={currentBeat?.mutualSpaceId}
        boardId={currentBeat?.boardId}
        tableId={currentBeat?.tableId}
        beatId={currentBeat?._id}
      /> */}
      </Flex>
    </Flex>
  );
};

export default ArtworksPanel;
