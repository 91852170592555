import { Box } from "@chakra-ui/react";
import React from "react";

const SessionFiles = ({ colorMode }) => {
  return (
    <Box
      boxShadow={"md"}
      className={`main-page-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Box>SessionFiles</Box>
    </Box>
  );
};

export default SessionFiles;
