import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Tooltip,
  Input,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { BsPlus } from "react-icons/bs";

const LyricStatusItem = () => {
  const [customTypes, setCustomTypes] = useState([]);
  const [newLyricInput, setNewBLyricInput] = useState("");
  const [lyricStatus, setNewLyricStatus] = useState("");

  return (
    <Box w={"100%"}>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              onClick={(event) => event.stopPropagation()} // Stop event propagation
              size="xs"
              as={Button}
              rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              variant="outline"
              w={"100%"}
              className="main-menu-button-text"
            >
              Lyric Status
            </MenuButton>
            <MenuList>
              <Flex alignItems={"center"} gap={"8px"} p={1.5}>
                <Input
                  borderRadius={"3px"}
                  placeholder="Add Type..."
                  size={"xs"}
                  width={"100%"}
                  value={newLyricInput}
                  onChange={(e) => setNewLyricStatus(e.target.value)}
                  onClick={(event) => event.stopPropagation()} // Stop event propagation
                />
                <Tooltip
                  label="Add Type"
                  hasArrow
                  placement="top"
                  closeOnClick={false}
                >
                  <IconButton
                    icon={<BsPlus />}
                    size={"xs"}
                    colorScheme="green"
                  />
                </Tooltip>
              </Flex>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};

export default LyricStatusItem;
