import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  useToast,
  SimpleGrid,
  VStack,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../../Config/config";

const LinkLyricsModal = ({
  userId,
  currentBeat,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  beatId,
  fetchBeatData,
  setIsLinkLyricsOpen,
}) => {
  const toast = useToast();
  const [lyricAssets, setLyricAssets] = useState([]);
  const [selectedLyricAsset, setSelectedLyricAsset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const fetchLyricAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/lyrics`
      );
      setLyricAssets(response.data.lyrics);
    } catch (error) {
      console.error("Failed to fetch beat assets:", error);
    }
  };

  useEffect(() => {
    fetchLyricAssets();
  }, []);

  const [assetType, setAssetType] = useState("");

  const clearSelection = () => {
    setSelectedLyricAsset(null);
  };

  const linkLyricToBeat = async () => {
    if (!selectedLyricAsset) {
      toast({
        title: "Selection Error",
        description: "No lyric selected to link.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/beat/${beatId}/addlyric`,
        {
          lyricAsset: selectedLyricAsset,
        }
      );
      toast({
        title: "Lyric Linked Successfully",
        description: `The lyric '${selectedLyricAsset.title}' has been linked successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setSelectedLyricAsset(null);
      setIsLinkLyricsOpen(false);
      fetchBeatData();
    } catch (error) {
      toast({
        title: "Linking Failed",
        description:
          error.response?.data?.message || "Failed to link the lyric.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <SimpleGrid columns={3} spacing={4}>
        {!selectedLyricAsset && (
          <>
            {" "}
            {lyricAssets.map((asset) => (
              <Box
                key={asset._id}
                p={5}
                shadow="md"
                borderWidth="1px"
                cursor="pointer"
                onClick={() => setSelectedLyricAsset(asset)}
              >
                <Text mt={4}>{asset.title}</Text>
                <Text fontSize="sm">Uploaded by: {asset.user.name}</Text>
                <Text fontSize="sm">Status: {asset.status}</Text>
              </Box>
            ))}
          </>
        )}
      </SimpleGrid>
      {selectedLyricAsset && (
        <VStack spacing={4} align="stretch">
          <Text fontSize="xl" fontWeight="bold">
            {selectedLyricAsset.title}
          </Text>
          <Text>Uploaded by: {selectedLyricAsset.user.name}</Text>
          <Text>Status: {selectedLyricAsset.status}</Text>
          <HStack>
            <Button
              colorScheme="blue"
              mr={3}
              isLoading={isLoading}
              loadingText="Linking..."
              onClick={linkLyricToBeat}
            >
              Link Lyric
            </Button>
            <Button size="sm" onClick={clearSelection}>
              Back to List
            </Button>
          </HStack>
        </VStack>
      )}
    </Box>
  );
};

export default LinkLyricsModal;
