import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Input,
  useColorModeValue,
  IconButton,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import Picker from "@emoji-mart/react";
import { BsSend, BsEmojiSmileFill, BsPaperclip } from "react-icons/bs";
import axios from "axios";
import "./ArtworkCommentInput.css";
import data from "@emoji-mart/data";
import { RiFontSize } from "react-icons/ri";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import { API_ENDPOINT } from "../../../../Config/config";

const ArtworkCommentInput = ({
  userId,
  artworkAsset,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  artworkId,
  fetchComments,
  guestToken,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [message, setMessage] = useState(""); // This state holds the current message input
  const toast = useToast();
  const pickerRef = useRef(null);
  const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);
  const bgColor = useColorModeValue("white", "");
  const [file, setFile] = useState(null); // State to hold the selected file

  const sendMessage = async () => {
    if (!message.trim()) {
      toast({
        title: "Cannot send empty message",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const strippedMessage = stripHtml(message);

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/artwork/${artworkId}/comments`,
        {
          userId,
          name: userName,
          comment: strippedMessage,
        }
      );

      if (response.status === 200) {
        toast({
          title: "Comment posted!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        clearMessage();
        fetchComments();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to send comment.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const CustomToolbartwo = () => (
    <Box p={2} id="toolbar" display="flex" justifyContent="space-between">
      <Box display="flex" gap="2">
        <>
          <Tooltip label="Attach File" aria-label="Attach File">
            <Box>
              <IconButton
                isDisabled={!!guestToken}
                icon={<BsPaperclip />}
                as="label"
                htmlFor="file-input"
                size="xs"
              />
              <Input
                isDisabled={!!guestToken}
                id="file-input"
                type="file"
                hidden
                onChange={handleFileChange}
              />
            </Box>
          </Tooltip>
        </>
        <Tooltip label="Emoji" aria-label="Bold">
          <IconButton
            isDisabled={!!guestToken}
            onClick={toggleEmojiPicker}
            icon={<BsEmojiSmileFill />}
            size="xs"
          />
        </Tooltip>
      </Box>
      <Tooltip label="Send" aria-label="Send">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            sendMessage(); // Call sendMessage when the button is clicked
          }}
          icon={<BsSend />}
          size="xs"
          isDisabled={!!guestToken}
        />
      </Tooltip>
    </Box>
  );

  const handleEmojiSelect = (emoji) => {
    const newText = `${message}${emoji.native}`;
    setMessage(newText);
    setShowEmojiPicker(false);
  };

  const quillRef = useRef(null); // Add this line at the beginning of your component

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Update the file state to the selected file
  };

  const clearMessage = () => {
    setMessage("");
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  const formats = ["bold", "italic", "strike", "code-block", "list", "bullet"];

  useEffect(() => {
    function handleClickOutside(event) {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    }
    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  const stripHtml = (html) => {
    const temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  const placeholderStyle = {
    color: colorMode === "light" ? "grey" : "white",
  };

  return (
    <Box
      boxShadow={"md"}
      border={
        colorMode === "light"
          ? ".5px solid rgba(187, 186, 187, 1)"
          : ".5px solid rgba(128, 128, 128, 0.25)"
      }
      bg={bgColor}
      borderRadius={"7px"}
      w={"100%"}
    >
      {showEmojiPicker && (
        <Box
          position="absolute"
          zIndex={"9999"}
          bottom="50px"
          right="0px"
          ref={pickerRef}
        >
          <Picker
            isDisabled={!!guestToken}
            data={data}
            onEmojiSelect={handleEmojiSelect}
          />
        </Box>
      )}

      <ReactQuill
        isDisabled={!!guestToken}
        ref={quillRef}
        theme="snow"
        value={message}
        onChange={(value) => setMessage(value)} // Corrected line
        modules={modules}
        formats={formats}
        placeholder="Start a new message"
        style={{ height: "50px", width: "260px" }}
        className={
          colorMode === "light" ? "quill-editor-light" : "quill-editor-dark"
        }
      />
      <Box
        borderBottomLeftRadius={"10px"}
        borderBottomRightRadius={"10px"}
        bg={
          colorMode === "light"
            ? "rgba(248, 248, 248, 1)"
            : "rgba(40, 49, 70, 1)"
        }
      >
        <CustomToolbartwo />
      </Box>
    </Box>
  );
};

export default ArtworkCommentInput;
