import {
  Box,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  useToast,
  Image,
} from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import avatarMock from "../../../Svggs/avatarmock.svg";
import { DeleteIcon } from "@chakra-ui/icons";
import { FaRegThumbsUp } from "react-icons/fa";

const ItemMessageDirect = ({
  message,
  colorMode,
  directMessageId,
  userId,
  userName,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false); // New state for delete confirmation modal
  const [isEditing, setIsEditing] = useState(false);
  const toast = useToast();

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const formatESTDate = (dateString) => {
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/New_York",
    }).format(new Date(dateString));
  };

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);
  const pickerRef = useRef(null); // Ref for the emoji picker

  useEffect(() => {
    function handleClickOutside(event) {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    }

    // Add when the emoji picker is shown
    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  const deleteMessage = async () => {
    try {
      // Replace `API_ENDPOINT` with your actual endpoint URL and adjust the path as necessary
      const response = await axios.delete(
        `${API_ENDPOINT}api/direct-messages/${directMessageId}/message/${message._id}`
      );
      if (response.status === 200) {
        toast({
          title: "Message deleted.",
          description: "The message has been successfully deleted.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setDeleteModalOpen(false);
      } else {
        toast({
          title: "Failed to delete message.",
          description: "An error occurred while trying to delete the message.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error deleting message:", error);
      toast({
        title: "Failed to delete message.",
        description: "An error occurred while trying to delete the message.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Box
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        position="relative"
      >
        <HStack
          p={2}
          className={`content-item ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
          borderRadius="lg"
          spacing={3}
          align="start"
        >
          {isEditing ? (
            <>Edit</>
          ) : (
            <>
              <Flex flexDirection={"column"}>
                <Flex gap={"8px"} alignItems={"center"}>
                  <Flex>
                    <Image src={avatarMock} className="avatarmock" />
                  </Flex>

                  <Flex flexDirection={"column"}>
                    <Flex alignItems={"center"} gap={"8px"}>
                      <Text className="sendfontstyle">
                        {message.sender.name}
                      </Text>
                      <Text className="sendingtime" color="gray.500">
                        {formatESTDate(message.createdAt)}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text className="messagecontent">
                        {message.messageContent}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex>
                  <Flex mt={1} className="spacedemojis">
                    {/* {Object.entries(reactionCounts).map(([emoji, count]) => (
                      <Button
                        borderRadius={"9px"}
                        variant={"outline"}
                        bg={
                          colorMode === "light"
                            ? "rgba(252, 252, 252, 1)"
                            : "rgba(12, 18, 44, 1)"
                        }
                        key={emoji}
                        size="xs"
                        mr={1}
                      >
                        <Flex alignItems={"center"} gap={"4px"}>
                          <Flex>{emoji}</Flex>
                          <Flex>{count}</Flex>
                        </Flex>
                      </Button>
                    ))} */}
                  </Flex>
                </Flex>
              </Flex>
              {isHovering && (
                <>
                  <HStack
                    className={`hoverboxitem ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                    boxShadow={"md"}
                    position="absolute"
                    top={-3}
                    right="5" // adjust this value based on your layout and icon size
                    spacing={2}
                  >
                    <Button
                      size="sm"
                      leftIcon={<FaRegThumbsUp />}
                      variant="ghost"
                      onClick={toggleEmojiPicker}
                    >
                      React
                    </Button>
                    <IconButton
                      aria-label="Delete message"
                      icon={<DeleteIcon />}
                      size="sm"
                      variant="ghost"
                      colorScheme="red"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteModalOpen(true);
                      }}
                    />
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<BsThreeDotsVertical />}
                        size="sm"
                        variant="ghost"
                      />
                      <MenuList>
                        <MenuItem>Mark unread</MenuItem>
                        <MenuItem command="L">Copy link</MenuItem>
                        <MenuItem command="E">Edit message</MenuItem>
                      </MenuList>
                    </Menu>
                  </HStack>
                  {showEmojiPicker && (
                    <Box
                      position="absolute"
                      zIndex={"9999"}
                      bottom="50px"
                      right="0px"
                      ref={pickerRef}
                    >
                      <Picker data={data} />
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </HStack>
      </Box>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this Message?</ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
              <Button
                w={"100%"}
                colorScheme="gray"
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button w={"100%"} colorScheme="red" onClick={deleteMessage}>
                Delete
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ItemMessageDirect;
