import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  useToast,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Input,
  Icon,
  Tooltip,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { BsFilter, BsPlus, BsSearch } from "react-icons/bs";
import { FaSort } from "react-icons/fa";
import axios from "axios";
import "./MainRecordingAssetTable.css";
import { API_ENDPOINT } from "../../../../Config/config";
import MainRecordingsTablePlayer from "../Players/MainRecordingsTablePlayer";
import MainRecordingUpload from "../MainUploads/MainRecordingUpload";
import { useCurrentRecording } from "../../../../Context/CurrentRecordingContext";

const MainRecordingAssetTable = ({
  colorMode,
  board,
  boardId,
  mutualSpaceId,
  userId,
  isSidebarCollapsed,
  tableId,
  userName,
  guestToken,
  openDetailsPanelSettings,
  closedetailsPanelSetting,
}) => {
  const [recordingAssets, setRecordingAssets] = useState([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const inputBg = useColorModeValue(
    "rgba(247, 250, 252, 0.43)",
    "rgba(66, 69, 99, 0.23)"
  );
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const boxShadow = useColorModeValue("sm", "dark-lg");

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const { currentRecording, setCurrentRecording } = useCurrentRecording();

  const fetchRecordingData = async () => {
    setIsLoading(true);

    if (tableId) {
      try {
        const response = await axios.get(
          `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/recordings`
        );
        const fetchedRecordings = response.data.recordings;
        setRecordingAssets(fetchedRecordings);

        if (fetchedRecordings.length > 0) {
          const enhancedRecordingAsset = {
            ...fetchedRecordings[0],
            mutualSpaceId,
            boardId,
            tableId,
          };
          setCurrentRecording(enhancedRecordingAsset);
        } else {
          closedetailsPanelSetting();
        }
      } catch (error) {
        toast({
          title: "Error fetching beats",
          description:
            error.response?.data?.message || "Could not fetch beats.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error fetching beats:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchRecordingData();
  }, [tableId, mutualSpaceId, boardId, toast, setCurrentRecording]);

  const [activeRecordingId, setActiveRecordingId] = useState(null);

  return (
    <Flex
      className={`MainBeatAssetTable-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex alignItems={"center"} w={"100%"} gap={"8px"}>
        <Flex w={"100%"} alignItems={"center"} gap={"8px"}>
          <Flex>
            <Tooltip placement="top" borderRadius={"7px"} label={"New Table"}>
              <Button
                isDisabled={!!guestToken}
                onClick={() => setIsUploadModalOpen(true)}
                size={"sm"}
              >
                <Flex alignItems={"center"} gap={"3px"}>
                  <Icon as={BsPlus} />
                  <Text>Recording</Text>
                </Flex>
              </Button>
            </Tooltip>
          </Flex>
          <Flex w="100%">
            <InputGroup size="sm" w="100%">
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={BsSearch} color="gray.300" />}
              />
              <Input
                bg={inputBg}
                borderColor={borderColor}
                placeholder="Search..."
                sx={{
                  "::placeholder": {
                    fontSize: "12px",
                  },
                }}
                _hover={{
                  bg: hoverBg,
                  borderColor: "gray.400",
                }}
                borderRadius={"5px"}
                size={"sm"}
                w={"100%"}
              />
            </InputGroup>
          </Flex>
        </Flex>
      </Flex>

      <Box
        className={`MainBeatAssetTable-surround ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        {isLoading ? (
          <Center mt={2}>
            <Spinner />
          </Center>
        ) : (
          <SimpleGrid mt={2} columns={{ base: 1, md: 1 }} spacing={4}>
            {recordingAssets.length > 0 ? (
              recordingAssets.map((recordingAsset) => (
                <Box key={recordingAsset._id}>
                  <MainRecordingsTablePlayer
                    userName={userName}
                    userId={userId}
                    colorMode={colorMode}
                    recordingAsset={recordingAsset}
                    board={board}
                    boardId={boardId}
                    mutualSpaceId={mutualSpaceId}
                    tableId={tableId}
                    isSidebarCollapsed={isSidebarCollapsed}
                    guestToken={guestToken}
                    fetchRecordingData={fetchRecordingData}
                    openDetailsPanelSettings={openDetailsPanelSettings}
                    isActive={activeRecordingId === recordingAsset._id}
                    setActiveRecordingId={setActiveRecordingId}
                  />
                </Box>
              ))
            ) : (
              <Text>No Recordings found.</Text>
            )}
          </SimpleGrid>
        )}
      </Box>
      <Modal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalCloseButton />
          <ModalBody>
            <MainRecordingUpload
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              mutualSpaceId={mutualSpaceId}
              board={board}
              boardId={boardId}
              tableId={tableId}
              setIsUploadModalOpen={setIsUploadModalOpen}
              fetchRecordingData={fetchRecordingData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MainRecordingAssetTable;
