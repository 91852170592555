import { Box } from "@chakra-ui/react";
import React from "react";
import PlanningTable from "../../Components/Table/PlanningTable/PlanningTable";
import MobilePlanningTable from "../../Components/Table/PlanningTable/MobilePlanningTable";

const Planning = ({
  colorMode,
  userId,
  userName,
  isMobileView,
  guestToken,
}) => {
  return (
    <Box
      className={`main-page-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      {isMobileView ? (
        <>
          <MobilePlanningTable
            colorMode={colorMode}
            userId={userId}
            userName={userName}
            guestToken={guestToken}
          />
        </>
      ) : (
        <>
          <PlanningTable
            colorMode={colorMode}
            userId={userId}
            userName={userName}
            guestToken={guestToken}
          />
        </>
      )}
    </Box>
  );
};

export default Planning;
