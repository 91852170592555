import { createContext, useState, useContext } from "react";

const AddBeatToLyricContext = createContext();

export const useAddBeatToLyric = () => useContext(AddBeatToLyricContext);

export const AddBeatToLyricProvider = ({ children }) => {
  const [addBeatToLyric, setAddBeatToLyric] = useState(null);

  return (
    <AddBeatToLyricContext.Provider
      value={{ addBeatToLyric, setAddBeatToLyric }}
    >
      {children}
    </AddBeatToLyricContext.Provider>
  );
};
