import { createContext, useState, useContext } from "react";

const BoardDataAndReleasesContext = createContext();

export const useBoardDataAndReleases = () =>
  useContext(BoardDataAndReleasesContext);

export const BoardDataAndReleasesProvider = ({ children }) => {
  const [boardDataAndReleases, setBoardDataAndReleases] = useState(null);

  return (
    <BoardDataAndReleasesContext.Provider
      value={{ boardDataAndReleases, setBoardDataAndReleases }}
    >
      {children}
    </BoardDataAndReleasesContext.Provider>
  );
};
