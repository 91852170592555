import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Text,
  Tooltip,
  SimpleGrid,
  Spinner,
  Center,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import "./HomeReleasesTable.css";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import HomeReleaseItem from "./HomeReleaseItem";

const HomeReleasesTable = ({ colorMode, userId, guestToken }) => {
  const [releases, setReleases] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const fetchReleases = async () => {
    setIsLoading(true);
    try {
      const endpoint = guestToken
        ? `${API_ENDPOINT}api/release/all`
        : `${API_ENDPOINT}api/release/user/${userId}`;

      const response = await axios.get(endpoint);
      let fetchedReleases = response.data.data;

      if (guestToken) {
        fetchedReleases = fetchedReleases.filter(
          (release) => release.mock === "true"
        );
      }

      setReleases(fetchedReleases);
    } catch (error) {
      toast({
        title: "Error fetching releases",
        description:
          error.response?.data?.message || "Could not fetch releases.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error fetching releases:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReleases();
  }, [toast]);

  return (
    <Box>
      <Flex alignItems={"center"} gap={"10px"} mt={3}>
        <Button w={"100%"} size={"sm"}>
          Released
        </Button>
        <Button w={"100%"} size={"sm"}>
          In Progress
        </Button>
      </Flex>

      {isLoading ? (
        <Center mt={2}>
          <Spinner size="xl" />
        </Center>
      ) : releases.length > 0 ? (
        releases.map((release) => (
          <Flex mt={4} gap={"5px"} flexDirection={"column"}>
            <HomeReleaseItem
              colorMode={colorMode}
              release={release}
              releaseId={release._id}
            />
          </Flex>
        ))
      ) : (
        <Center>
          <Text>No releases found</Text>
        </Center>
      )}
    </Box>
  );
};

export default HomeReleasesTable;
