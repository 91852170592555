import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import "./SideBar.css";
import { TbMail } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { useReleasePanel } from "../../Context/ReleasePanelContext";
import { useBeatPanel } from "../../Context/BeatsPanelContext";
import { useLyricsPanel } from "../../Context/LyricsPanelContext";
import { useRecordingsPanel } from "../../Context/RecordingsPanelContext";
import { useArtworksPanel } from "../../Context/ArtworkPanelContext";
import { useCookies } from "react-cookie";
import { GoHome } from "react-icons/go";
import MutualSpaceSideBarSection from "./MutualSpaceSideBarSection";
import ReleasesTabSection from "./ReleasesTabSection";

const SideBar = ({
  isMobileView,
  isSidebarCollapsed,
  colorMode,
  isCollapsed,
  setIsCollapsed,
  userName,
  userId,
  guestToken,
}) => {
  const navigate = useNavigate();
  const { setReleasePanel } = useReleasePanel();
  const { setBeatPanel } = useBeatPanel();
  const { setLyricsPanel } = useLyricsPanel();
  const { setRecordingsPanel } = useRecordingsPanel();
  const { setArtworksPanel } = useArtworksPanel();
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const location = useLocation();
  const [selectedNav, setSelectedNav] = useState("/dash");

  useEffect(() => {
    setSelectedNav(location.pathname);
  }, [location]);

  const closedetailsPanelSetting = () => {
    if (!isCollapsed) {
      setIsCollapsed(true);
    }
  };

  const todash = () => {
    closedetailsPanelSetting();
    navigate("/dash");
    setReleasePanel(null);
    setBeatPanel(null);
    setLyricsPanel(null);
    setRecordingsPanel(null);
    setArtworksPanel(null);
    setBeatPanel(null);
  };

  const toMesssages = () => {
    navigate("/messages");
    closedetailsPanelSetting();
    setReleasePanel(null);
    setBeatPanel(null);
    setLyricsPanel(null);
    setRecordingsPanel(null);
    setArtworksPanel(null);
  };

  const sidebarWidth = isMobileView
    ? isSidebarCollapsed
      ? "13%" // Mobile view and sidebar is collapsed
      : "15%" // Mobile view and sidebar is not collapsed (you can adjust this if needed)
    : isSidebarCollapsed
    ? "5.5%" // Desktop view and sidebar is collapsed
    : "20%"; // Desktop view

  return (
    <Box
      boxShadow={"md"}
      w={sidebarWidth}
      className={`sidebar-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        mt={isMobileView ? 2 : 0}
        p={isMobileView ? 0 : 2}
        direction={"column"}
        gap={isMobileView ? "15px" : isSidebarCollapsed ? "15px" : "5px"}
      >
        {isSidebarCollapsed ? (
          <Flex justifyContent={"center"}>
            <Tooltip placement="right" borderRadius={"7px"} label={"Home"}>
              <IconButton
                className={`sidebar-nav-button ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                } ${selectedNav === "/dash" ? "activeLink" : ""}`}
                onClick={todash}
                size={"lg"}
                borderRadius={"100%"}
                icon={<GoHome />}
              />
            </Tooltip>
          </Flex>
        ) : isMobileView ? (
          <Flex justifyContent={"center"}>
            <IconButton
              className={`sidebar-nav-button ${
                colorMode === "light" ? "lightmode" : "darkmode"
              } ${selectedNav === "/dash" ? "activeLink" : ""}`}
              onClick={todash}
              size={"md"}
              borderRadius={"100%"}
              icon={<GoHome />}
            />
          </Flex>
        ) : (
          <Flex>
            <Tooltip placement="right" borderRadius={"7px"} label={"Home"}>
              <Button
                w={"100%"}
                className={`sidebar-nav-button ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                } ${selectedNav === "/dash" ? "activeLink" : ""}`}
                onClick={todash}
                size={"sm"}
                bg={colorMode === "light" ? "" : ""}
                _hover={{
                  bg:
                    colorMode === "light"
                      ? "rgba(240, 240, 241, 1)"
                      : "rgba(61, 67, 75, 1)",
                }}
              >
                <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
                  <Icon className="navicon" as={GoHome} />
                  <Text className="navtext">Home</Text>
                </Flex>
              </Button>
            </Tooltip>
          </Flex>
        )}
        {isSidebarCollapsed ? (
          <Flex justifyContent={"center"}>
            <Tooltip placement="right" borderRadius={"7px"} label={"Messages"}>
              <IconButton
                className={`sidebar-nav-button ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                } ${selectedNav === "/messages" ? "activeLink" : ""}`}
                onClick={toMesssages}
                size={"lg"}
                borderRadius={"100%"}
                icon={<TbMail />}
              />
            </Tooltip>
          </Flex>
        ) : isMobileView ? (
          <Flex justifyContent={"center"}>
            <IconButton
              className={`sidebar-nav-button ${
                colorMode === "light" ? "lightmode" : "darkmode"
              } ${selectedNav === "/messages" ? "activeLink" : ""}`}
              onClick={toMesssages}
              size={"md"}
              borderRadius={"100%"}
              icon={<TbMail />}
            />
          </Flex>
        ) : (
          <Flex>
            <Tooltip placement="right" borderRadius={"7px"} label={"Messages"}>
              <Button
                w={"100%"}
                className={`sidebar-nav-button ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                } ${selectedNav === "/messages" ? "activeLink" : ""}`}
                onClick={toMesssages}
                size={"sm"}
                bg={colorMode === "light" ? "" : ""}
                _hover={{
                  bg:
                    colorMode === "light"
                      ? "rgba(240, 240, 241, 1)"
                      : "rgba(61, 67, 75, 1)",
                }}
              >
                <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
                  <Icon className="navicon" as={TbMail} />
                  <Text className="navtext">Messages</Text>
                </Flex>
              </Button>
            </Tooltip>
          </Flex>
        )}
        <ReleasesTabSection
          isSidebarCollapsed={isSidebarCollapsed}
          isMobileView={isMobileView}
          colorMode={colorMode}
          userId={userId}
          userName={userName}
          guestToken={guestToken}
          closedetailsPanelSetting={closedetailsPanelSetting}
          setSelectedNav={setSelectedNav}
          selectedNav={selectedNav}
        />
        <Flex
          borderTop={isMobileView ? ".5px solid grey" : ".5px solid grey"}
          flexDirection={"column"}
        >
          <MutualSpaceSideBarSection
            isSidebarCollapsed={isSidebarCollapsed}
            isMobileView={isMobileView}
            colorMode={colorMode}
            userId={userId}
            userName={userName}
            guestToken={guestToken}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default SideBar;
