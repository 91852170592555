import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  IconButton,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReleasePanel } from "../../Context/ReleasePanelContext";
import { useBeatPanel } from "../../Context/BeatsPanelContext";
import { useLyricsPanel } from "../../Context/LyricsPanelContext";
import { useRecordingsPanel } from "../../Context/RecordingsPanelContext";
import { useArtworksPanel } from "../../Context/ArtworkPanelContext";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";
import { useReleasesPageTable } from "../../Context/ReleasesPageTableContext";
import { useBoardDataAndReleases } from "../../Context/BoardDataAndReleasesContext";

const ReleasesTabSection = ({
  isSidebarCollapsed,
  isMobileView,
  colorMode,
  closedetailsPanelSetting,
  setSelectedNav,
  selectedNav,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setReleasePanel } = useReleasePanel();
  const { setBeatPanel } = useBeatPanel();
  const { setLyricsPanel } = useLyricsPanel();
  const { setRecordingsPanel } = useRecordingsPanel();
  const { setArtworksPanel } = useArtworksPanel();

  const toReleases = () => {
    navigate("/releases");

    closedetailsPanelSetting();
    setBeatPanel(null);
    setLyricsPanel(null);
    setRecordingsPanel(null);
    setArtworksPanel(null);
  };

  return (
    <Box>
      {isSidebarCollapsed ? (
        <Flex justifyContent={"center"}>
          <Tooltip placement="right" borderRadius={"7px"} label={"Releases"}>
            <IconButton
              className={`sidebar-nav-button ${
                colorMode === "light" ? "lightmode" : "darkmode"
              } ${selectedNav === "/releases" ? "activeLink" : ""}`}
              onClick={toReleases}
              size={"lg"}
              borderRadius={"100%"}
              icon={<IoMdCheckmarkCircleOutline />}
            />
          </Tooltip>
        </Flex>
      ) : isMobileView ? (
        <Flex justifyContent={"center"}>
          <IconButton
            className={`sidebar-nav-button ${
              colorMode === "light" ? "lightmode" : "darkmode"
            } ${selectedNav === "/releases" ? "activeLink" : ""}`}
            onClick={toReleases}
            size={"md"}
            borderRadius={"100%"}
            icon={<IoMdCheckmarkCircleOutline />}
          />
        </Flex>
      ) : (
        <Flex>
          <Tooltip placement="right" borderRadius={"7px"} label={"Releases"}>
            <Button
              w={"100%"}
              className={`sidebar-nav-button ${
                colorMode === "light" ? "lightmode" : "darkmode"
              } ${selectedNav === "/releases" ? "activeLink" : ""}`}
              onClick={toReleases}
              size={"sm"}
              bg={colorMode === "light" ? "" : ""}
              _hover={{
                bg:
                  colorMode === "light"
                    ? "rgba(240, 240, 241, 1)"
                    : "rgba(61, 67, 75, 1)",
              }}
            >
              <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
                <Icon className="navicon" as={IoMdCheckmarkCircleOutline} />
                <Text className="navtext">Releases</Text>
              </Flex>
            </Button>
          </Tooltip>
        </Flex>
      )}
    </Box>
  );
};

export default ReleasesTabSection;
