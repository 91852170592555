import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Icon,
  Text,
  useToast,
  Center,
  Spinner,
  SimpleGrid,
  Image,
  Tooltip,
  Avatar,
} from "@chakra-ui/react";
import "./MainHomeBoardItem.css";
import clipArtImage from "../../Images/forimags.png";
import { useMutualSpace } from "../../Context/MutualSpaceContext";
import { useNavigate } from "react-router-dom";

const MainHomeBoardItem = ({
  colorMode,
  board,
  mutualSpaceId,
  boardId,
  mutualSpace,
}) => {
  const navigate = useNavigate();
  const { updateSelectedMutualSpace } = useMutualSpace();

  const handleBoardClick = () => {
    console.log("Mutual Space Updated:", mutualSpace);
    updateSelectedMutualSpace(mutualSpace);
    navigate(`/mutualspace/${mutualSpaceId}/board/${boardId}`);
  };

  return (
    <Box
      className={`MainHomeBoardItem-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      onClick={handleBoardClick}
    >
      <Flex>
        <Text className="board-name">{board.boardName}</Text>
      </Flex>
      <Box className="clipcontainer">
        <Image className="clip-actual" src={clipArtImage} />
      </Box>
    </Box>
  );
};

export default MainHomeBoardItem;
