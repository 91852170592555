import React, { useContext, useEffect, useState } from "react";
import "./ReleaseRecordingItem.css";
import { Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { FaCircleCheck } from "react-icons/fa6";
import { MusicPlayerContext } from "../../../../Context/MusicPlayerContext";
import { BsPauseCircle, BsPlayCircle } from "react-icons/bs";

const ReleaseRecordingItem = ({ currentRelease, recording, colorMode }) => {
  const { playTrack, stopTrack, currentTrack, seekTrack } =
    useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [localDuration, setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack &&
    currentTrack.src === recording.fileUrl &&
    !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(recording.fileUrl);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <Flex
      className={`ReleaseRecordingItem-main ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      w={"100%"}
      gap={"10px"}
    >
      <Flex flexDirection={"column"} alignItems={"start"}>
        <Flex alignItems={"center"} gap={"8px"}>
          <Flex>
            {isCurrentBeatPlaying ? (
              <>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePlayPause(); // Call the original saveProducerName function
                  }}
                  icon={<BsPauseCircle className="main-player-button" />}
                />
              </>
            ) : (
              <>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePlayPause(); // Call the original saveProducerName function
                  }}
                  icon={<BsPlayCircle className="main-player-button" />}
                />
              </>
            )}
          </Flex>
          <Flex>
            <Flex flexDirection={"column"}>
              <Text className="player-name">{recording.title}</Text>
              <Text className="player-producer">
                {recording.artist || "Artist"}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReleaseRecordingItem;
