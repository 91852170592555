import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import "./MyFilesAndPermissions.css";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";

const MyFilesAndPermissions = ({
  colorMode,
  isMobileView,
  userId,
  userName,
}) => {
  const [activeTab, setActiveTab] = useState("assets");
  const [uploadedAccessList, setUploadedAccessList] = useState([]);
  const [uploadedFoldersAccessList, setUploadedFoldersAccessList] = useState(
    []
  );

  const [uploadedMutualSpacesAccessList, setUploadedMutualSpaceAccessList] =
    useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchUploadedAccessList = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT}api/assets/uploaded-access-list/${userId}`
        );
        setUploadedAccessList(response.data.uploadedAssetsAccessList);
      } catch (error) {
        console.error("Failed to fetch uploaded access list:", error);
      }
    };

    fetchUploadedAccessList();
  }, [userId]);

  const fetchUploadedFoldersAccessList = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/folders/uploaded-access-list/${userId}`
      );
      setUploadedFoldersAccessList(response.data.uploadedFoldersAccessList);
    } catch (error) {
      console.error("Failed to fetch uploaded folders access list:", error);
    }
  };

  useEffect(() => {
    fetchUploadedFoldersAccessList();
  }, [userId]);

  const fetchUploadedMutualSpaceAccessList = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspaces/uploaded-access-list/${userId}`
      );
      setUploadedMutualSpaceAccessList(
        response.data.uploadedMutualSpacesAccessList
      );
    } catch (error) {
      console.error("Failed to fetch uploaded folders access list:", error);
    }
  };

  useEffect(() => {
    fetchUploadedMutualSpaceAccessList();
  }, [userId]);

  const renderAssetsContent = () => {
    return uploadedAccessList.map((table) => (
      <Box key={table._id} borderBottom="0.5px solid gray">
        <Text fontWeight="bold">Table: {table.tableName}</Text>
        {table.usersWithAccess.length > 0 && (
          <Text mb="2">Users with Access:</Text>
        )}
        {table.usersWithAccess.length === 0 ? (
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            bg={colorMode === "light" ? "gray.50" : "gray.800"}
            border={
              colorMode === "light"
                ? "0.5px solid rgba(187, 186, 187, 1)"
                : "0.5px solid rgba(128, 128, 128, 1)"
            }
          >
            <Text>No permissions granted yet.</Text>
            <Button colorScheme="blue" variant="solid" mt={3}>
              Grant Access
            </Button>
          </Box>
        ) : (
          table.usersWithAccess.map((user) => (
            <Flex
              boxShadow={"md"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              className="useroftable"
              bg={colorMode === "light" ? "gray.50" : "gray.800"}
              border={
                colorMode === "light"
                  ? ".5px solid rgba(187, 186, 187, 1)"
                  : ".5px solid rgba(128, 128, 128, 1)"
              }
            >
              <Flex alignItems={"center"}>
                <Avatar name={user.userName} size={"sm"} />
                <Text key={user.userId} pl="4">
                  {user.userName}
                </Text>
              </Flex>
              <Flex>
                <Button colorScheme="red" variant={"outline"} size={"sm"}>
                  Remove Access
                </Button>
              </Flex>
            </Flex>
          ))
        )}
      </Box>
    ));
  };

  const renderFoldersContent = () => {
    return uploadedFoldersAccessList.map((table) => (
      <Box key={table._id} borderBottom="0.5px solid gray">
        <Text fontWeight="bold">Folder: {table.folderName}</Text>
        {table.usersWithAccess?.length > 0 && (
          <Text mb="2">Users with Access:</Text>
        )}
        {table.usersWithAccess.length === 0 ? (
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            bg={colorMode === "light" ? "gray.50" : "gray.800"}
            border={
              colorMode === "light"
                ? "0.5px solid rgba(187, 186, 187, 1)"
                : "0.5px solid rgba(128, 128, 128, 1)"
            }
          >
            <Text>No permissions granted yet.</Text>
            <Button colorScheme="blue" variant="solid" mt={3}>
              Grant Access
            </Button>
          </Box>
        ) : (
          table.usersWithAccess.map((user) => (
            <Flex
              boxShadow={"md"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              className="useroftable"
              bg={colorMode === "light" ? "gray.50" : "gray.800"}
              border={
                colorMode === "light"
                  ? ".5px solid rgba(187, 186, 187, 1)"
                  : ".5px solid rgba(128, 128, 128, 1)"
              }
            >
              <Flex alignItems={"center"}>
                <Avatar name={user.userName} size={"sm"} />
                <Text key={user.userId} pl="4">
                  {user.userName}
                </Text>
              </Flex>
              <Flex>
                <Button colorScheme="red" variant={"outline"} size={"sm"}>
                  Remove Access
                </Button>
              </Flex>
            </Flex>
          ))
        )}
      </Box>
    ));
  };

  const renderMutualSpaceContent = () => {
    return uploadedMutualSpacesAccessList.map((table) => (
      <Box key={table._id} borderBottom="0.5px solid gray">
        <Text fontWeight="bold">MutualSpace: {table?.mutualSpaceName}</Text>
        {table?.usersWithAccess?.length > 0 && (
          <Text mb="2">Users with Access:</Text>
        )}
        {table.usersWithAccess.length === 0 ? (
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            bg={colorMode === "light" ? "gray.50" : "gray.800"}
            border={
              colorMode === "light"
                ? "0.5px solid rgba(187, 186, 187, 1)"
                : "0.5px solid rgba(128, 128, 128, 1)"
            }
          >
            <Text>No permissions granted yet.</Text>
            <Button colorScheme="blue" variant="solid" mt={3}>
              Grant Access
            </Button>
          </Box>
        ) : (
          table.usersWithAccess.map((user) => (
            <Flex
              boxShadow={"md"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              className="useroftable"
              bg={colorMode === "light" ? "gray.50" : "gray.800"}
              border={
                colorMode === "light"
                  ? ".5px solid rgba(187, 186, 187, 1)"
                  : ".5px solid rgba(128, 128, 128, 1)"
              }
            >
              <Flex alignItems={"center"}>
                <Avatar name={user.name} size={"sm"} />
                <Text key={user.userId} pl="4">
                  {user.name}
                </Text>
              </Flex>
              <Flex>
                <Button colorScheme="red" variant={"outline"} size={"sm"}>
                  Remove Access
                </Button>
              </Flex>
            </Flex>
          ))
        )}
      </Box>
    ));
  };

  return (
    <Box className="MyFilesAndPermissions-main">
      <Flex w={"100%"} flexDirection={"column"} gap={"20px"}>
        <Flex
          w={"100%"}
          alignItems={"center"}
          justifyContent={"end"}
          gap={"10px"}
          marginBottom={"0px"}
          paddingBottom={"10px"}
          borderBottom={".5px solid grey"}
        >
          <Flex
            flexDirection={isMobileView ? "column" : "row"}
            w={"100%"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text mb={isMobileView ? 3 : 0}>
              Grant access and set permissions to your uploaded data
            </Text>
            <Flex gap={"10px"} alignItems={"center"}>
              <Button
                onClick={() => handleTabClick("assets")}
                colorScheme={activeTab === "assets" ? "blue" : "gray"}
              >
                Assets
              </Button>
              <Button
                onClick={() => handleTabClick("folders")}
                colorScheme={activeTab === "folders" ? "blue" : "gray"}
              >
                Folders
              </Button>
              <Button
                onClick={() => handleTabClick("mutualSpaces")}
                colorScheme={activeTab === "mutualSpaces" ? "blue" : "gray"}
              >
                MutualSpaces
              </Button>
            </Flex>
          </Flex>
        </Flex>

        {activeTab === "assets" && <>{renderAssetsContent()}</>}
        {activeTab === "folders" && <>{renderFoldersContent()}</>}
        {activeTab === "mutualSpaces" && <>{renderMutualSpaceContent()}</>}
      </Flex>
    </Box>
  );
};

export default MyFilesAndPermissions;
