import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  useColorMode,
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  Icon,
} from "@chakra-ui/react";
import "./MobileHeader.css";
import { TbLogout } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useReleasePanel } from "../../Context/ReleasePanelContext";
import { useBeatPanel } from "../../Context/BeatsPanelContext";
import { useLyricsPanel } from "../../Context/LyricsPanelContext";
import { useRecordingsPanel } from "../../Context/RecordingsPanelContext";
import { useArtworksPanel } from "../../Context/ArtworkPanelContext";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { SettingsIcon, ArrowForwardIcon } from "@chakra-ui/icons"; // Import additional icons as needed

const MobileHeader = ({
  toggleMainDetails,
  isAuthRoute,
  toggleSidebar,
  isLoginRoute,
  AuthToken,
  isLandingRoute,
  location,
  isSidebarCollapsed,
  colorMode,
  userEmail,
  userName,
  removeCookie,
}) => {
  const { setReleasePanel } = useReleasePanel();
  const { setBeatPanel } = useBeatPanel();
  const { setLyricsPanel } = useLyricsPanel();
  const { setRecordingsPanel } = useRecordingsPanel();
  const { setArtworksPanel } = useArtworksPanel();
  const [isLogoutConfirmOpen, setIsLogoutConfirmOpen] = useState(false);
  const openLogoutConfirm = () => setIsLogoutConfirmOpen(true);
  const closeLogoutConfirm = () => setIsLogoutConfirmOpen(false);

  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const toHome = () => {
    navigate("/");
    setReleasePanel(null);
    setBeatPanel(null);
    setLyricsPanel(null);
    setRecordingsPanel(null);
    setArtworksPanel(null);
  };

  const handleLogout = () => {
    removeCookie("AuthToken");
    removeCookie("UserId");
    removeCookie("Name");
    removeCookie("Email");
    navigate("/");
  };

  const toDash = () => {
    navigate("/dash");
  };

  const toLogin = () => {
    navigate("/login");
  };

  const { toggleColorMode } = useColorMode();

  const handleColorModeToggle = () => {
    toggleColorMode();
  };

  const toProfileSettings = () => {
    navigate("/settings");
  };

  return (
    <Box
      className={`${
        !AuthToken ? "mobile-header-container" : "authheadermobile-container"
      }  ${colorMode === "light" ? "lightmode" : "darkmode"}`}
    >
      <Flex className="mobile-header-surround">
        <Box>
          <Text
            onClick={AuthToken ? toDash : toHome}
            className="mobilelogomain"
          >
            IndieMutual
          </Text>
        </Box>
        <Box></Box>
        <Flex alignItems={"center"} gap={"5px"}>
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={handleColorModeToggle}
          >
            {colorMode === "light" ? (
              <MoonIcon color="black" />
            ) : (
              <SunIcon color="yellow.400" />
            )}
          </Button>
          {AuthToken && (
            <>
              <Menu zIndex={"9999"}>
                <MenuButton
                  ml={2}
                  borderRadius={"12px"}
                  boxShadow={"0 4px 8px 2px rgba(0, 0, 0, 0.1)"}
                  _hover={{
                    border:
                      colorMode === "light"
                        ? ".5px solid rgba(50, 51, 56, 0.34)"
                        : ".5px solid rgba(208, 212, 228, 0.34)",
                    cursor: "pointer",
                  }}
                  gap={"9px"}
                  alignItems={"center"}
                  size="sm"
                >
                  <Flex alignItems={"center"} gap={"5px"}>
                    <Avatar size={"xs"} name={userName} />
                  </Flex>
                </MenuButton>
                <MenuList
                  zIndex={"9999"}
                  boxShadow={"lg"} // Example box shadow, adjust as needed
                  border={
                    colorMode === "light"
                      ? "1px solid gray.200"
                      : "1px solid gray.700"
                  }
                >
                  <MenuItem
                    icon={<SettingsIcon />}
                    command="⌘S"
                    onClick={toProfileSettings}
                  >
                    Settings
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    icon={<ArrowForwardIcon />}
                    command="⌘L"
                  >
                    Log Out
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          )}
          {!AuthToken && (
            <>
              <Flex>
                <Button onClick={toLogin} size={"sm"}>
                  Login
                </Button>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isLogoutConfirmOpen} onClose={closeLogoutConfirm}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>
            <Flex gap={"10px"} alignItems={"center"}>
              <Text>Confirm Logout</Text>
              <Icon as={TbLogout} />
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to sign out?</ModalBody>
          <ModalFooter>
            <Button
              w={"100%"}
              colorScheme="gray"
              mr={3}
              onClick={closeLogoutConfirm}
            >
              Cancel
            </Button>
            <Button w={"100%"} colorScheme="red" onClick={handleLogout}>
              Yes, Sign Out
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MobileHeader;
