import { createContext, useState, useContext } from "react";

// Create the context
const AddRecordingToReleaseContext = createContext();

// Custom hook for easy context usage
export const useAddRecordingToRelease = () =>
  useContext(AddRecordingToReleaseContext);

// Provider component
export const AddRecordingToReleaseProvider = ({ children }) => {
  const [addRecording, setAddRecording] = useState(null); // New state and setter function

  return (
    <AddRecordingToReleaseContext.Provider
      value={{ addRecording, setAddRecording }}
    >
      {children}
    </AddRecordingToReleaseContext.Provider>
  );
};
