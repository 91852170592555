import React from "react";
import { Box } from "@chakra-ui/react";
import LyricsTable from "../../Components/Table/LyricsTable/LyricsTable";

const Lyrics = ({
  isMobileView,
  colorMode,
  userName,
  userId,
  openDetailsPanelSettings,
}) => {
  return (
    <Box
      boxShadow={"md"}
      className={`main-page-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Box className="page-surround">
        <LyricsTable
          userName={userName}
          userId={userId}
          colorMode={colorMode}
          isMobileView={isMobileView}
          openDetailsPanelSettings={openDetailsPanelSettings}
        />
      </Box>
    </Box>
  );
};

export default Lyrics;
