import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  Icon,
  Checkbox,
  SimpleGrid,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import { API_ENDPOINT } from "../../Config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Register.css";
import { IoIosArrowBack } from "react-icons/io";

const Register = ({ isMobileView, colorMode, setCookie, AuthToken }) => {
  const [email, setEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [skippedStepTwo, setSkippedStepTwo] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate(); // Create navigate function instance

  useEffect(() => {
    if (AuthToken) {
      navigate("/dash");
    }
  }, [AuthToken]);

  const nextStep = () => {
    if (currentStep === 1) {
      // Email validation regex pattern
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

      if (email.trim() === "" || !termsAccepted) {
        alert("Please enter an email and agree to the terms to continue.");
        return;
      }

      if (!emailRegex.test(email)) {
        alert("Please enter a valid email address.");
        return;
      }

      if (password !== confirmPassword) {
        alert("Passwords do not match.");
        return;
      }
    }

    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep === 2) {
      setSelectedRoles([]);
    }
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleRoleSelection = (role) => {
    setSkippedStepTwo(false);
    if (selectedRoles.includes(role)) {
      setSelectedRoles(selectedRoles.filter((item) => item !== role));
    } else {
      setSelectedRoles([...selectedRoles, role]);
    }
  };

  const isRoleSelected = (role) => {
    return selectedRoles.includes(role);
  };

  const handleFinalSubmit = async () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      setIsSubmitting(false); // Stop loading
      return;
    }

    setIsSubmitting(true);

    try {
      const registrationData = {
        name,
        email,
        password,
        selectedRoles,
      };

      const response = await axios.post(
        `${API_ENDPOINT}api/register`,
        registrationData
      );

      if (response.data.status === "success") {
        setCookie("AuthToken", response.data.token);
        setCookie("UserId", response.data.userId);
        setCookie("Email", response.data.email);
        setCookie("Name", response.data.name);
        navigate("/dash");
      } else {
        console.log("Registration failed:", response.data.message);
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
    setIsSubmitting(false);
  };

  return (
    <Box
      className={`register-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Box
        className={`registerstart ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        p={isMobileView ? 8 : 10}
        borderRadius="lg"
        boxShadow={"md"}
      >
        {currentStep === 1 && (
          <Flex
            gap={isMobileView ? "20px" : "10px"}
            direction="column"
            align="center"
            justify="center"
            w={"100%"}
          >
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              gap={"5px"}
            >
              <Text
                textAlign={"center"}
                className={`register-welcome-text ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                Welcome!
              </Text>
              <Text
                className={`sign-up-text ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                Create your account below!
              </Text>
            </Flex>
            <FormControl isRequired>
              <Flex w={"100%"} flexDirection={"column"}>
                <FormLabel className="registerformlabeltext">Name</FormLabel>
                <Input
                  _hover={{
                    bg: colorMode === "light" ? "gray.50" : "gray.700",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Flex>
            </FormControl>

            <FormControl isRequired>
              <Flex w={"100%"} flexDirection={"column"}>
                <FormLabel className="registerformlabeltext">Email</FormLabel>
                <Input
                  _hover={{
                    bg: colorMode === "light" ? "gray.50" : "gray.700",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Flex>
            </FormControl>
            <FormControl isRequired>
              <FormLabel className="registerformlabeltext">Password</FormLabel>
              <Flex w={"100%"} flexDirection={"column"}>
                <Input
                  _hover={{
                    bg: colorMode === "light" ? "gray.50" : "gray.700",
                  }}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Flex>
            </FormControl>
            <FormControl isRequired>
              <FormLabel className="registerformlabeltext">
                Confirm Password
              </FormLabel>
              <Flex w={"100%"} flexDirection={"column"}>
                <Input
                  _hover={{
                    bg: colorMode === "light" ? "gray.50" : "gray.700",
                  }}
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Flex>
            </FormControl>

            <Box mt={3}>
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              >
                <Text className="privacycheckboxtext">
                  I agree to IndieMutual's Terms and Policies.
                </Text>
              </Checkbox>
            </Box>
            <Button
              mt={3}
              mb={isMobileView ? 7 : 0}
              colorScheme="blue"
              onClick={nextStep}
              w={isMobileView ? "70%" : "30%"}
            >
              Continue
            </Button>
          </Flex>
        )}
        {currentStep === 2 && (
          <Flex gap={isMobileView ? "30px" : "20px"} flexDirection={"column"}>
            <Flex alignItems={"center"} flexDirection={"column"} gap={"5px"}>
              <Text className="signupforfree">What is your role?</Text>
            </Flex>
            <Flex gap={"15px"} flexDirection={"column"}>
              <SimpleGrid columns={{ base: 2, md: 2 }} spacing={5}>
                {["Producer", "Engineer", "A&R", "Songwriter"].map((role) => (
                  <Button
                    variant={"outline"}
                    boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)" // subtle smooth box shadow
                    key={role}
                    bg={isRoleSelected(role) ? "rgba(75, 117, 143, 0.67)" : ""}
                    color={isRoleSelected(role) ? "" : ""}
                    onClick={() => handleRoleSelection(role)}
                  >
                    {role}
                  </Button>
                ))}
                {["Recording Artist", "Manager", "Publisher", "Other"].map(
                  (role) => (
                    <Button
                      variant={"outline"}
                      boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)" // subtle smooth box shadow
                      key={role}
                      bg={
                        isRoleSelected(role) ? "rgba(75, 117, 143, 0.67)" : ""
                      }
                      color={isRoleSelected(role) ? "" : ""}
                      onClick={() => handleRoleSelection(role)}
                    >
                      {role}
                    </Button>
                  )
                )}
              </SimpleGrid>
            </Flex>
            <Flex
              mt={4}
              justifyContent={"space-between"}
              alignItems={"center"}
              w={"100%"}
              gap={"20px"}
            >
              <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
                <Button
                  w={isMobileView ? "100%" : "100%"}
                  onClick={prevStep}
                  variant={"outline"}
                  colorScheme="cyan"
                >
                  <Flex gap={"5px"} alignItems={"center"}>
                    <Icon as={IoIosArrowBack} />
                    <Text>Back</Text>
                  </Flex>
                </Button>
              </Flex>
              <Button
                variant={"solid"}
                colorScheme="green"
                w={isMobileView ? "100%" : "100%"}
                onClick={handleFinalSubmit}
                isLoading={isSubmitting}
              >
                Create Account!
              </Button>
            </Flex>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default Register;
