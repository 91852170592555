import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  Badge,
  useColorModeValue,
  useToast,
  Button,
  Icon,
  Tooltip,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  Heading,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import "./MutualSpaceTable.css";
import { useMutualSpace } from "../../../Context/MutualSpaceContext";
import { BsPlusLg } from "react-icons/bs";
import MutualBoardForm from "../../Forms/MutualBoardForms/MutualBoardForm";

const MutualSpaceTable = ({
  colorMode,
  mutualSpace,
  mutualSpaceId,
  userName,
  userId,
  isMobileView,
  fetchMutualSpace,
  isSidebarCollapsed,
  guestToken,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toast = useToast();
  const [editableMutualSpaceName, setEditableMutualSpaceName] = useState(
    mutualSpace?.mutualSpaceName
  );
  const taskBg = useColorModeValue("gray.50", "gray.700");
  const noDataText = useColorModeValue("gray.500", "gray.400");
  const [isNewBoardModalOpen, setIsNewBoardModalOpen] = useState(false);

  const { updateSelectedMutualSpace } = useMutualSpace();

  useEffect(() => {
    if (mutualSpace) {
      updateSelectedMutualSpace(mutualSpace);
    }
  }, [mutualSpace, updateSelectedMutualSpace]);

  const handleDeleteMutualSpace = async () => {
    try {
      await axios.delete(`${API_ENDPOINT}api/mutualspace/${mutualSpaceId}`);
      toast({
        title: "Mutual Space Deleted.",
        description: "The mutual space has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Failed to delete mutual space", error);
      toast({
        title: "Error",
        description: "Failed to delete the mutual space.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const updateMutualSpaceName = async () => {
    if (editableMutualSpaceName !== mutualSpace.mutualSpaceName) {
      try {
        const response = await axios.put(
          `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}`,
          { newMutualSpaceName: editableMutualSpaceName }
        );

        toast({
          title: "Success",
          description: "Table name updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Failed to update table name", error);
        setEditableMutualSpaceName(mutualSpace.mutualSpaceName);
        toast({
          title: "Error",
          description: "Failed to update table name.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    setEditableMutualSpaceName(mutualSpace?.mutualSpaceName);
  }, [mutualSpace]);

  return (
    <Box className="MutualSpaceTable-container" p={4}>
      <Text fontSize="2xl">Mutual Boards</Text>
      {mutualSpace &&
      mutualSpace?.MutualBoards &&
      mutualSpace?.MutualBoards.length > 0 ? (
        mutualSpace?.MutualBoards.map((board) => (
          <VStack
            key={board._id}
            align="start"
            spacing={4}
            p={5}
            bg={taskBg}
            borderRadius="lg"
          >
            <HStack spacing={4}>
              <Text fontWeight="bold" fontSize="xl">
                {board?.boardName}
              </Text>
              <Badge colorScheme="green" px={2} py={1}>
                Created by {board?.createdBy.name}
              </Badge>
            </HStack>
            <VStack align="start" spacing={2}>
              <Text fontSize="md">Tasks:</Text>
              {board?.tasks?.map((task) => (
                <HStack key={task?._id} spacing={4}>
                  <Text>{task?.taskTableName}</Text>
                </HStack>
              ))}
            </VStack>
            <VStack align="start" spacing={2}>
              <Text fontSize="md">Tables:</Text>
              {board?.tables?.map((table) => (
                <HStack key={table?._id} spacing={4}>
                  <Text>
                    {table?.tableName} ({table?.tableType})
                  </Text>
                </HStack>
              ))}
            </VStack>
          </VStack>
        ))
      ) : (
        <Flex
          justify="center"
          align="center"
          p={5}
          bg={taskBg}
          borderRadius="lg"
        >
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            gap={"10px"}
          >
            <Text color={noDataText} fontSize="lg">
              No MutualBoards created yet!
            </Text>
            <Flex>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsNewBoardModalOpen(true);
                }}
              >
                <Flex alignItems={"center"} gap={"8px"}>
                  <Icon as={BsPlusLg} />
                  <Text fontSize={"13px"}>Add Mutualboard</Text>
                </Flex>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
      <Modal
        isOpen={isNewBoardModalOpen}
        onClose={() => setIsNewBoardModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Heading size="lg" fontWeight="normal">
              New Board
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <MutualBoardForm
              isMobileView={isMobileView}
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              setIsNewBoardModalOpen={setIsNewBoardModalOpen}
              mutualSpaceId={mutualSpaceId}
              fetchMutualSpace={fetchMutualSpace}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MutualSpaceTable;
