import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import "./Messages.css";
import { API_ENDPOINT } from "../../Config/config";
import axios from "axios";
import MessengerList from "../../Components/Messaging/MessengerList";
import MessageTable from "../../Components/Table/MessageTable/MessageTable";
import MobileMessengerList from "../../Components/Messaging/MobileMessengerList";
import MobileMessageTable from "../../Components/Table/MessageTable/MobileMessageTable";
import SecondaryNoMessages from "./SecondaryNoMessages";

const Messages = ({
  isMobileView,
  colorMode,
  userId,
  userName,
  guestToken,
}) => {
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [channels, setChannels] = useState([]); // State to store fetched channels
  const [directMessages, setDirectMessages] = useState([]);
  const [selectedDirectMessage, setDirectMessageChannel] = useState(null);

  const handleChannelSelect = (channel) => {
    setSelectedChannel(channel);
  };

  const handleDirectMessageSelect = (directMessage) => {
    setDirectMessageChannel(directMessage);
  };

  const fetchChannels = async () => {
    setIsLoading(true);
    try {
      const endpoint = guestToken
        ? `${API_ENDPOINT}api/channels`
        : `${API_ENDPOINT}api/channels/by-user/${userId}`;
      const response = await axios.get(endpoint);
      const fetchedChannels = response.data.channels;

      const mockChannels = fetchedChannels.filter(
        (channel) => channel.mock === "true"
      );

      setChannels(mockChannels);

      if (mockChannels.length > 0) {
        setSelectedChannel(mockChannels[0]);
      }
    } catch (error) {
      console.error("Error fetching channels:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshSelectedChannel = async () => {
    if (!selectedChannel) return;
    try {
      const res = await axios.get(
        `${API_ENDPOINT}api/channels/${selectedChannel._id}`
      );
      setSelectedChannel(res.data.channel);
    } catch (error) {
      console.error("Error refreshing the selected channel: ", error);
    }
  };

  const fetchDirectMessages = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/direct-messages/by-user/${userId}`
      );
      setDirectMessages(response.data.directMessages);
    } catch (error) {
      console.error("Error fetching direct messages for user:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDirectMessages();
  }, []);

  useEffect(() => {
    if (guestToken) {
      fetchChannels();
    }
  }, [guestToken]);

  useEffect(() => {
    if (userId) {
      fetchChannels();
      fetchDirectMessages();
    }
  }, [userId]);

  const hasNoData =
    !isLoading && channels.length === 0 && directMessages.length === 0;

  return (
    <Box
      className={`main-messages-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      {isMobileView ? (
        <>
          <MobileMessengerList
            userName={userName}
            userId={userId}
            isMobileView={isMobileView}
            colorMode={colorMode}
            onChannelSelect={handleChannelSelect}
            onDirectMessageSelect={handleDirectMessageSelect}
            isLoading={isLoading}
            channels={channels}
            fetchChannels={fetchChannels}
            guestToken={guestToken}
            directMessages={directMessages}
            fetchDirectMessages={fetchDirectMessages}
          />
          <MobileMessageTable
            userName={userName}
            userId={userId}
            isMobileView={isMobileView}
            colorMode={colorMode}
            selectedChannel={selectedChannel}
            selectedDirectMessage={selectedDirectMessage}
            refreshSelectedChannel={refreshSelectedChannel}
          />
        </>
      ) : (
        <>
          <MessengerList
            userName={userName}
            userId={userId}
            isMobileView={isMobileView}
            colorMode={colorMode}
            onChannelSelect={handleChannelSelect}
            onDirectMessageSelect={handleDirectMessageSelect}
            isLoading={isLoading}
            channels={channels}
            fetchChannels={fetchChannels}
            guestToken={guestToken}
            directMessages={directMessages}
            fetchDirectMessages={fetchDirectMessages}
          />
          <MessageTable
            userName={userName}
            userId={userId}
            isMobileView={isMobileView}
            colorMode={colorMode}
            selectedChannel={selectedChannel}
            selectedDirectMessage={selectedDirectMessage}
            refreshSelectedChannel={refreshSelectedChannel}
            guestToken={guestToken}
          />
        </>
      )}
    </Box>
  );
};

export default Messages;
