import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";
import { Box, useToast, Spinner } from "@chakra-ui/react";
import MutualBoardTable from "../../Components/Table/MutualBoard/MutualBoardTable";
import "./MutualBoard.css";
import { useMutualBoard } from "../../Context/MutualBoardContext";
import { useMutualSpace } from "../../Context/MutualSpaceContext";

const MutualBoard = ({
  colorMode,
  isMobileView,
  userId,
  userName,
  isSidebarCollapsed,
  guestToken,
  openDetailsPanelSettings,
  closedetailsPanelSetting,
}) => {
  const { mutualSpaceId, boardId } = useParams();
  const [board, setBoard] = useState(null);
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [mutualSpace, setMutualSpace] = useState(null);

  const fetchMutualSpace = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}`
      );
      setMutualSpace(response.data.data);
    } catch (error) {
      console.error("Error fetching mutual space:", error);
      toast({
        title: "Error fetching mutual space",
        description: "Unable to fetch mutual space data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchMutualSpace();
  }, [mutualSpaceId]);

  const fetchBoardDetails = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}`
      );
      setBoard(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching board details:", error);
      toast({
        title: "Error fetching board",
        description: "Unable to fetch board data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const resetBoard = () => setBoard(null);

    if (mutualSpaceId && boardId) {
      fetchBoardDetails(mutualSpaceId, boardId);
    }

    return () => resetBoard();
  }, [mutualSpaceId, boardId]);

  return (
    <Box
      className={`MutualBoard-page-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <MutualBoardTable
        key={`${mutualSpaceId}-${boardId}`}
        isMobileView={isMobileView}
        colorMode={colorMode}
        board={board}
        boardId={boardId}
        mutualSpaceId={mutualSpaceId}
        userName={userName}
        userId={userId}
        fetchBoardDetails={fetchBoardDetails}
        fetchMutualSpace={fetchMutualSpace}
        isSidebarCollapsed={isSidebarCollapsed}
        guestToken={guestToken}
        openDetailsPanelSettings={openDetailsPanelSettings}
        closedetailsPanelSetting={closedetailsPanelSetting}
      />
    </Box>
  );
};

export default MutualBoard;
