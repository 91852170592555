import React, { createContext, useContext, useState } from "react";

const DirectMessageDisplayContext = createContext();

// Custom hook for consuming context
export const useDirectMessageDisplay = () =>
  useContext(DirectMessageDisplayContext);

// Provider Component
export const DirectMessageDisplayProvider = ({ children }) => {
  const [directMessages, setDirectMessages] = useState(null);

  return (
    <DirectMessageDisplayContext.Provider
      value={{ directMessages, setDirectMessages }}
    >
      {children}
    </DirectMessageDisplayContext.Provider>
  );
};
