import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Text,
  Tooltip,
  Center,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  IconButton,
  useToast,
  CircularProgress,
  CircularProgressLabel,
  useColorModeValue,
  Image,
  Tag,
  TagLabel,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { CalendarIcon, DownloadIcon, EditIcon } from "@chakra-ui/icons";
import { BiTrash, BiUpload, BiX } from "react-icons/bi";
import "./MainReleaseItem.css";
import EditingRelease from "./EditingModal/EditingRelease";
import { useReleasePanel } from "../../../Context/ReleasePanelContext";
import { useCurrentRelease } from "../../../Context/CurrentReleaseContext";
import { FaChevronRight, FaCircleCheck } from "react-icons/fa6";
import ReleaseLyricsItem from "../../DetailsPanel/ReleasePanel/CurrentLinkedItems/ReleaseLyricsItem";
import ReleaseRecordingItem from "../../DetailsPanel/ReleasePanel/CurrentLinkedItems/ReleaseRecordingItem";
import ReleaseStatusItem from "./ReleaseStatusItem";
import { GrSchedule } from "react-icons/gr";
import { TbCircleCheck, TbCircleCheckFilled } from "react-icons/tb";
import { BsSpotify } from "react-icons/bs";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import ReleaseDrawer from "./ReleaseDrawer";
import ScheduleDate from "./ScheduleDate";
import ReleaseDateItem from "./ReleaseDateItem";
import ArtistsItem from "./ArtistsItem";
import ArtworkUploadModal from "../../DetailsPanel/ReleasePanel/AsseetUploadModals/ArtworkUploadModal";

const MainReleaseItem = ({
  release,
  fetchReleases,
  releases,
  colorMode,
  isMobileView,
  openDetailsPanelSettings,
  isActive,
  setActiveReleaseId,
  userId,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const toast = useToast();
  const [releaseToDelete, setReleaseToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditReleaseModalOpen, setIsEditReleaseModalOpen] = useState(false);
  const [isSchedulingDateModalOpen, setIsSchedulingDateModalOpen] =
    useState(false); // New state
  const [isArtworkModalOpen, setIsArtworkModalOpen] = useState(false);

  const [releaseToEdit, setReleaseToEdit] = useState(null);
  const { setReleasePanel } = useReleasePanel();
  const { currentRelease, setCurrentRelease } = useCurrentRelease();
  const inputBg = useColorModeValue("gray.50", "rgba(66, 69, 99, 0.23)");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const handleReleaseClick = (event) => {
    event.stopPropagation();
    setReleasePanel("Releases");

    const enhancedRelease = {
      ...release,
      fetchReleases,
    };
    setCurrentRelease(enhancedRelease);
    setActiveReleaseId(release._id);
    openDetailsPanelSettings();
  };

  const handleDeleteRelease = async () => {
    setIsDeleting(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/release/delete/${releaseToDelete}`
      );
      if (response.status === 200) {
        toast({
          title: "Release deleted!.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });

        setDeleteModalOpen(false);
        fetchReleases();
      }
    } catch (error) {
      console.error("Error deleting release:", error);
      toast({
        title: "Error deleting release.",
        description:
          "There was an error deleting the release. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const downloadRelease = async () => {
    try {
      const url = `${API_ENDPOINT}api/release/${release._id}/download`;
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = `${release.releaseName}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      toast({
        title: "Download Success.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    } catch (error) {
      console.error("Error downloading release:", error);
      toast({
        title: "Failed to Download.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const calculateCompletionPercentage = (release) => {
    const pieces = [
      release.releaseName,
      release.releaseType,
      release.lyrics.length > 0,
      release.artwork.length > 0,
      release.recordings.length > 0,
      release.schedule,
    ];

    const filledPieces = pieces.filter(Boolean).length;
    return (filledPieces / 6) * 100;
  };

  const getCompletionColor = (percentage) => {
    if (percentage === 100) return "green.400";
    if (percentage >= 66) return "yellow.400";
    return "red.400";
  };

  const completionPercentage = calculateCompletionPercentage(release);
  const completionColor = getCompletionColor(completionPercentage);

  const [mainReleaseName, setMainReleaseName] = useState(
    release?.releaseName || ""
  );

  useEffect(() => {
    setMainReleaseName(release?.releaseName || "");
  }, [release]);

  const artworkUrl =
    release.artwork && release.artwork.length > 0
      ? typeof release.artwork[0] === "string"
        ? release.artwork[0]
        : release.artwork[0].imageUrl
      : "";

  const getFormattedReleaseDate = (schedule) => {
    const date = new Date(schedule);
    return {
      month: date.toLocaleString("en-US", { month: "long" }),
      day: date.getDate(),
    };
  };

  const releaseDateParts = currentRelease?.schedule
    ? getFormattedReleaseDate(currentRelease?.schedule)
    : null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    const day = date.getDate();
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // special case for 11th-13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const formattedDay = `${day}${daySuffix(day)}`;
    return `${formattedDate.split(" ")[0]} ${formattedDay}, ${
      formattedDate.split(" ")[1]
    }`;
  };

  return (
    <Flex className="MainReleaseItem-container">
      <Flex
        className={`MainReleaseItem-surround ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        key={release._id}
        mb={2}
        onClick={(e) => {
          e.stopPropagation();
          onDrawerOpen();
        }}
      >
        <Flex w={"100%"}>
          {release.artwork && release.artwork.length > 0 ? (
            <Box className="majorb">
              <Image className="flexingmb" src={artworkUrl} />
            </Box>
          ) : (
            <>
              <Box className="majorb" position="relative">
                <Image className="flexingmb" />
                <Center
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  opacity="0"
                  transition="opacity 0.2s"
                  _hover={{ opacity: 1, background: "rgba(0, 0, 0, 0.5)" }}
                >
                  <IconButton
                    icon={<BiUpload />}
                    aria-label="Upload Artwork"
                    variant="ghost"
                    color="white"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsArtworkModalOpen(true);
                    }}
                  />
                </Center>
              </Box>
            </>
          )}
        </Flex>
        <Flex className="statuspos">
          {release.releaseDate ? (
            <Flex alignItems={"center"}>
              <Tag
                w={"100%"}
                h={"30px"}
                borderRadius="full"
                bg={colorMode === "light" ? "green.200" : "green.700"}
              >
                <Icon
                  as={TbCircleCheckFilled}
                  fontSize={"20px"}
                  ml={-1}
                  mr={1}
                  color={colorMode === "light" ? "green" : "green.100"}
                />
                <TagLabel>Released</TagLabel>
              </Tag>
            </Flex>
          ) : (
            <>
              <ReleaseStatusItem
                colorMode={colorMode}
                release={release}
                releaseId={release._id}
                fetchReleases={fetchReleases}
              />
            </>
          )}
        </Flex>
        <Flex justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
          <Flex alignItems={"center"} gap={"10px"}>
            {release.releaseDate ? (
              <Flex alignItems={"center"}>
                <Button
                  size={"sm"}
                  variant={"outline"}
                  className={`ReleaseDateItem-surround ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  <Flex alignItems={"center"} gap={"7px"}>
                    <Icon as={CalendarIcon} />
                    <Text>{formatDate(release.releaseDate)}</Text>
                  </Flex>
                </Button>
              </Flex>
            ) : (
              <>
                <ReleaseDateItem
                  colorMode={colorMode}
                  release={release}
                  releaseId={release._id}
                  fetchReleases={fetchReleases}
                />
              </>
            )}
          </Flex>
          <Flex alignItems={"center"} gap={"10px"}>
            <Tooltip
              placement="top"
              borderRadius={"5px"}
              label={"Download pdf"}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  downloadRelease();
                }}
                icon={<DownloadIcon />}
                size={"sm"}
              />
            </Tooltip>
            <Tooltip placement="top" borderRadius={"5px"} label={"Delete"}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setReleaseToDelete(release._id);
                  setDeleteModalOpen(true);
                }}
                colorScheme="red"
                icon={<BiTrash />}
                size={"sm"}
              />
            </Tooltip>
          </Flex>
        </Flex>

        <Flex justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
          <Flex alignItems={"center"} gap={"10px"}>
            <ArtistsItem
              colorMode={colorMode}
              release={release}
              releaseId={release._id}
              fetchReleases={fetchReleases}
              userId={userId}
            />
          </Flex>
        </Flex>
      </Flex>

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Delete Release</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this Release?</ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
              <Button
                colorScheme="gray"
                w={"100%"}
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={handleDeleteRelease}
                isLoading={isDeleting}
                w={"100%"}
                colorScheme="red"
              >
                Delete Release
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isSchedulingDateModalOpen}
        onClose={() => setIsSchedulingDateModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          sx={{
            width: isMobileView ? "100%" : "80%", // Full width for mobile view, otherwise 80%
            height: isMobileView ? "auto" : "500px", // Full height for mobile view
            maxHeight: isMobileView ? "auto" : "500px", // Full height for mobile view
            maxWidth: isMobileView ? "100%" : "700px", // Full width for mobile view, otherwise max 700px
          }}
        >
          <ModalHeader>Edit Release</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ScheduleDate
              colorMode={colorMode}
              release={release}
              releaseId={release._id}
              fetchReleases={fetchReleases}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isEditReleaseModalOpen}
        onClose={() => setIsEditReleaseModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          sx={{
            width: isMobileView ? "100%" : "80%", // Full width for mobile view, otherwise 80%
            height: isMobileView ? "auto" : "500px", // Full height for mobile view
            maxHeight: isMobileView ? "auto" : "500px", // Full height for mobile view
            maxWidth: isMobileView ? "100%" : "700px", // Full width for mobile view, otherwise max 700px
          }}
        >
          <ModalHeader>Edit Release</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EditingRelease release={release} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={onDrawerClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent
          p={1}
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <DrawerCloseButton />
          <DrawerHeader>Linked Assets for {release.releaseName} </DrawerHeader>
          <DrawerBody>
            <ReleaseDrawer release={release} colorMode={colorMode} />
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onDrawerClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal
        isOpen={isArtworkModalOpen}
        onClose={() => setIsArtworkModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          sx={{
            width: "80%", // Full width for mobile view, otherwise 80%
            height: "500px", // Full height for mobile view
            maxHeight: "500px", // Full height for mobile view
            maxWidth: "700px", // Full width for mobile view, otherwise max 700px
          }}
        >
          <ModalHeader>Add Artwork</ModalHeader>
          <ModalCloseButton />
          <ModalBody h={"80%"}>
            <ArtworkUploadModal
              setIsArtworkModalOpen={setIsArtworkModalOpen}
              release={release}
              colorMode={colorMode}
              fetchReleases={fetchReleases}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MainReleaseItem;
