import {
  Box,
  Flex,
  Input,
  Text,
  MenuButton,
  Menu,
  MenuList,
  Icon,
  Button,
  Tooltip,
  MenuItem,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Badge,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "./LyricsPanel.css";
import { FaCircleCheck, FaComments } from "react-icons/fa6";
import CurrentLyricComments from "./CurrentLyricComments";
import { TbUserPlus } from "react-icons/tb";
import { BiHeadphone, BiMicrophone, BiUserPlus } from "react-icons/bi";
import StatusLyricsItem from "../../Table/MutualBoard/Lyrics/StatusLyricsItem";
import AddSongWriterModal from "./AddSongWriterModal";
import { BsFolder, BsFolderPlus } from "react-icons/bs";
import { PiFolderPlus } from "react-icons/pi";
import AddLyricsToFolder from "./AddLyricsToFolder";
import { MdOutlineLyrics } from "react-icons/md";
import AddBeatModal from "./AddBeatModal";
import AddRecordingModal from "./AddRecordingModal";
import LinkedItemRecording from "../../Players/MutualSpacePlayers/LinkedItemRecording";
import BeatLinkedItem from "../RecordingsPanel/BeatLinkedItem";
import LyricsTimeLine from "./LyricsTimeLine";

const LyricsPanel = ({
  colorMode,
  currentLyric,
  userId,
  userName,
  guestToken,
  closedetailsPanelSetting,
  isCollapsed,
}) => {
  const toast = useToast();

  const songWriters = Array.isArray(currentLyric?.songWriters)
    ? currentLyric.songWriters
    : currentLyric?.songWriters?.usersWritingTo || [];
  const [isAddToFolderOpen, setIsAddToFolderOpen] = useState(false);
  const [isAddToReleaseOpen, setIsAddToReleaseOpen] = useState(false);
  const [isAddBeatOpen, setIsAddBeatOpen] = useState(false);

  const [isAddSongwriterOpen, setIsAddSongwriterOpen] = useState(false);
  const [isAddRecordingModalOpen, setIsAddRecordingModalOpen] = useState(false);

  const charToColor = (char) => {
    const alphabetPosition = char.toUpperCase().charCodeAt(0) - 64;
    const hue = (alphabetPosition * 137) % 360; // 137 is a "magic" number
    const saturation = 50 + (alphabetPosition % 51); // Vary between 50% and 100%
    const lightness = 50 + ((alphabetPosition * 3) % 26); // Vary between 50% and 75%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const inputBg = useColorModeValue("gray.50", "rgba(66, 69, 99, 0.23)");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");

  const [lyricTitle, setlyricTitle] = useState();

  const [editableLyricsName, setEditableLyricsName] = useState(
    currentLyric?.title || ""
  );

  useEffect(() => {
    setlyricTitle(currentLyric?.title || "");
  }, [currentLyric]);

  return (
    <Flex
      className={`main-lyricspanel-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        className={`lyrics-panel-topone ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex w={"100%"}>
          <StatusLyricsItem
            lyricAsset={currentLyric}
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            mutualSpaceId={currentLyric?.mutualSpaceId}
            boardId={currentLyric?.boardId}
            tableId={currentLyric?.tableId}
            lyricId={currentLyric?._id}
            guestToken={guestToken}
            fetchLyricData={currentLyric?.fetchLyricData}
          />
        </Flex>
        <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
          <Flex w={"100%"}>
            <Flex
              w={"100%"}
              flexDirection={"column"}
              alignItems={"start"}
              gap={"3px"}
            >
              <Flex
                w={"100%"}
                justifyContent={"space-between"}
                alignItems={"end"}
              >
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon fontSize={"14px"} as={TbUserPlus} />
                  <Text className="slighttext">Songwriters</Text>
                </Flex>
              </Flex>
              <Flex className="avatarsrow">
                {songWriters.length > 0 ? (
                  <Flex className="fortunearrows">
                    {songWriters.map((songWriter, index) => (
                      <Flex
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        className={`writing-to-item ${
                          colorMode === "light" ? "lightmode" : "darkmode"
                        }`}
                        key={songWriter.user.userId}
                      >
                        <Avatar
                          mr={-4}
                          size={"xs"}
                          name={songWriter.user.name}
                        />
                      </Flex>
                    ))}
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      className={`writing-to-item ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      <Avatar
                        onClick={() => setIsAddSongwriterOpen(true)}
                        size={"xs"}
                        icon={<BiUserPlus fontSize="15px" />}
                        _hover={{ cursor: "pointer", opacity: "80%" }}
                      />
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    className={`writing-to-item ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                    _hover={{ cursor: "pointer", opacity: "80%" }}
                  >
                    <Avatar
                      onClick={() => setIsAddSongwriterOpen(true)}
                      size={"xs"}
                      icon={<BiUserPlus fontSize="15px" />}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>

          <Flex w={"100%"}>
            <Flex w={"100%"} flexDirection={"column"} gap={"3px"}>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon fontSize={"12px"} as={BsFolder} />
                <Text className="slighttext">Folders</Text>
              </Flex>

              {currentLyric?.folders && currentLyric.folders.length > 0 ? (
                <Flex height={"100%"} w={"100%"}>
                  {currentLyric.folders.map((folder) => (
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      className={`writing-to-item ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                      key={folder._id}
                    >
                      <Avatar
                        icon={<BsFolder fontSize="15px" />}
                        mr={-4}
                        size={"xs"}
                        bg={charToColor(folder.folderName)}
                      />
                    </Flex>
                  ))}
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    className={`writing-to-item ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    <Avatar
                      onClick={() => setIsAddToFolderOpen(true)}
                      size={"xs"}
                      icon={<BsFolderPlus fontSize="15px" />}
                      _hover={{ cursor: "pointer", opacity: "80%" }}
                    />
                  </Flex>
                </Flex>
              ) : (
                <Button
                  onClick={() => setIsAddToFolderOpen(true)}
                  fontSize={"10px"}
                  size={"sm"}
                >
                  Add to Folder
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
          <Flex w={"100%"}>
            <Flex w={"100%"} flexDirection={"column"} gap={"4px"}>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon fontSize={"12px"} as={BiHeadphone} />
                <Text className="slighttext">Beat</Text>
              </Flex>

              {currentLyric?.linkedBeats &&
              currentLyric.linkedBeats.length > 0 ? (
                <Flex height={"100%"} w={"100%"}>
                  {currentLyric.linkedBeats.map((linkedBeat) => (
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      className={`writing-to-item ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                      key={linkedBeat._id}
                    >
                      <BeatLinkedItem beat={linkedBeat} colorMode={colorMode} />
                    </Flex>
                  ))}
                </Flex>
              ) : (
                <Button
                  onClick={() => setIsAddBeatOpen(true)}
                  fontSize={"10px"}
                  size={"sm"}
                >
                  Link to Beat
                </Button>
              )}
            </Flex>
          </Flex>
          <Flex w={"100%"}>
            <Flex w={"100%"} flexDirection={"column"} gap={"4px"}>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon fontSize={"12px"} as={BiMicrophone} />
                <Text className="slighttext">Recording</Text>
              </Flex>

              {currentLyric?.linkedRecordings &&
              currentLyric.linkedRecordings.length > 0 ? (
                <Flex height={"100%"} w={"100%"}>
                  {currentLyric.linkedRecordings.map((linkedRecording) => (
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      className={`writing-to-item ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                      key={linkedRecording._id}
                    >
                      <LinkedItemRecording
                        recording={linkedRecording}
                        colorMode={colorMode}
                      />
                    </Flex>
                  ))}
                </Flex>
              ) : (
                <Button
                  onClick={() => setIsAddRecordingModalOpen(true)}
                  fontSize={"10px"}
                  size={"sm"}
                >
                  Link to Recording
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          <LyricsTimeLine
            currentLyric={currentLyric}
            userName={userName}
            userId={userId}
            colorMode={colorMode}
            mutualSpaceId={currentLyric?.mutualSpaceId}
            boardId={currentLyric?.boardId}
            tableId={currentLyric?.tableId}
            lyricId={currentLyric?._id}
            guestToken={guestToken}
            fetchLyricData={currentLyric?.fetchLyricData}
          />
        </Flex>
      </Flex>
      <Flex
        className={`lyricspanel-bottomone ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex
          pb={2}
          borderBottom={".5px solid grey"}
          gap={"8px"}
          alignItems={"center"}
          w={"100%"}
        >
          <Icon as={FaComments} />
          <Text size={"sm"}>Comments</Text>
        </Flex>

        <CurrentLyricComments
          userId={userId}
          currentLyric={currentLyric}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={currentLyric?.mutualSpaceId}
          boardId={currentLyric?.boardId}
          tableId={currentLyric?.tableId}
          lyricId={currentLyric?._id}
        />
      </Flex>

      <Modal
        isOpen={isAddSongwriterOpen}
        onClose={() => setIsAddSongwriterOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Icon className="granticon" as={BiUserPlus} />
              <Text className="accessheader">Add to Folder</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddSongWriterModal
              currentLyric={currentLyric}
              userName={userName}
              userId={userId}
              colorMode={colorMode}
              mutualSpaceId={currentLyric?.mutualSpaceId}
              boardId={currentLyric?.boardId}
              tableId={currentLyric?.tableId}
              lyricId={currentLyric?._id}
              guestToken={guestToken}
              fetchLyricData={currentLyric?.fetchLyricData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isAddToFolderOpen}
        onClose={() => setIsAddToFolderOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Icon className="granticon" as={PiFolderPlus} />
              <Text className="accessheader">Add to Folder</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddLyricsToFolder
              currentLyric={currentLyric}
              userName={userName}
              userId={userId}
              colorMode={colorMode}
              mutualSpaceId={currentLyric?.mutualSpaceId}
              boardId={currentLyric?.boardId}
              tableId={currentLyric?.tableId}
              lyricId={currentLyric?._id}
              guestToken={guestToken}
              fetchLyricData={currentLyric?.fetchLyricData}
              setIsAddToFolderOpen={setIsAddToFolderOpen}
            />
            Add to folder
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAddBeatOpen} onClose={() => setIsAddBeatOpen(false)}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Icon className="granticon" as={PiFolderPlus} />
              <Text className="accessheader">Add to Folder</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddBeatModal
              currentLyric={currentLyric}
              userName={userName}
              userId={userId}
              colorMode={colorMode}
              mutualSpaceId={currentLyric?.mutualSpaceId}
              boardId={currentLyric?.boardId}
              tableId={currentLyric?.tableId}
              lyricId={currentLyric?._id}
              guestToken={guestToken}
              fetchLyricData={currentLyric?.fetchLyricData}
              setIsAddBeatOpen={setIsAddBeatOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isAddRecordingModalOpen}
        onClose={() => setIsAddRecordingModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Icon className="granticon" as={PiFolderPlus} />
              <Text className="accessheader">Add to Folder</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddRecordingModal
              currentLyric={currentLyric}
              userName={userName}
              userId={userId}
              colorMode={colorMode}
              mutualSpaceId={currentLyric?.mutualSpaceId}
              boardId={currentLyric?.boardId}
              tableId={currentLyric?.tableId}
              lyricId={currentLyric?._id}
              guestToken={guestToken}
              fetchLyricData={currentLyric?.fetchLyricData}
              setIsAddRecordingModalOpen={setIsAddRecordingModalOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default LyricsPanel;
