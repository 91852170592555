import { Box } from "@chakra-ui/react";
import React from "react";
import TaskPageTable from "../../Components/Table/TaskPageTable/TaskPageTable";

const Tasks = ({ colorMode, userId, userName }) => {
  return (
    <Box
      boxShadow={"md"}
      className={`main-page-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <TaskPageTable colorMode={colorMode} userId={userId} />
    </Box>
  );
};

export default Tasks;
