import React from "react";
import {
  Avatar,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import "./LoggedInHeader.css";
import { TbMenuDeep } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { useReleasePanel } from "../../Context/ReleasePanelContext";
import { useBeatPanel } from "../../Context/BeatsPanelContext";
import { useLyricsPanel } from "../../Context/LyricsPanelContext";
import { useRecordingsPanel } from "../../Context/RecordingsPanelContext";
import { useArtworksPanel } from "../../Context/ArtworkPanelContext";
import { useProfileSettings } from "../../Context/ProfileSettingsContext";
import { useReleasesKanban } from "../../Context/ReleasesKanbanContext";
import { useAddBeatToLyric } from "../../Context/AddBeatToLyricContext";
import { useAddRecordingToLyric } from "../../Context/AddRecordingToLyricContext";
import { useLinkRecording } from "../../Context/LinkRecordingContext";
import { useAddRecordingToRelease } from "../../Context/AddRecordingToReleaseContext";
import { useLinkArtwork } from "../../Context/LinkArtworkContext";
import { useLinkBeat } from "../../Context/LinkBeat";
import { useBuildRelease } from "../../Context/BuildReleaseContext";
import { useAddArtworkToRelease } from "../../Context/AddArtworkToRelease";
import { useAddBeatToRelease } from "../../Context/AddBeatToRelease";
import { useAddLyricToRelease } from "../../Context/AddLyricToRelease";
import { useReleaseBuildingPanel } from "../../Context/ReleaseBuildingPanelContext";
import { ArrowForwardIcon } from "@chakra-ui/icons"; // Import additional icons as needed
import {
  IoMdCheckmarkCircleOutline,
  IoMdNotificationsOutline,
} from "react-icons/io";

const LoggedInHeader = ({
  toggleMainDetails,
  isAuthRoute,
  toggleSidebar,
  AuthToken,
  isLandingRoute,
  isSidebarCollapsed,
  colorMode,
  userEmail,
  userName,
  isCollapsed,
  setIsCollapsed,
  removeCookie,
  guestToken,
}) => {
  const { setReleasePanel } = useReleasePanel();
  const { setBeatPanel } = useBeatPanel();
  const { setLyricsPanel } = useLyricsPanel();
  const { setRecordingsPanel } = useRecordingsPanel();
  const { setArtworksPanel } = useArtworksPanel();
  const { setProfileSettings } = useProfileSettings();
  const { setReleasesKanban } = useReleasesKanban();
  const { setAddBeatToLyric } = useAddBeatToLyric();
  const { setAddRecordingToLyric } = useAddRecordingToLyric();
  const { toggleColorMode } = useColorMode();
  const { setLinkRecording } = useLinkRecording();
  const { setAddRecording } = useAddRecordingToRelease();
  const { setLinkArtwork } = useLinkArtwork();
  const { setAddBeat } = useAddBeatToRelease();
  const { setAddLyric } = useAddLyricToRelease();
  const { setLinkBeat } = useLinkBeat();
  const { setAddArtwork } = useAddArtworkToRelease();
  const { setBuildRelease } = useBuildRelease();
  const { setReleaseBuildingPanel } = useReleaseBuildingPanel();

  const handleColorModeToggle = () => {
    toggleColorMode();
  };
  const navigate = useNavigate();

  const openDetailsPanelSettings = () => {
    if (isCollapsed) {
      setIsCollapsed(false);
    }
  };

  const toDash = () => {
    openDetailsPanelSettings();
    navigate("/dash");
    setBeatPanel(null);
    setLyricsPanel(null);
    setArtworksPanel(null);
    setRecordingsPanel(null);
    setReleasesKanban(null);
    setAddBeatToLyric(null);
    setAddRecordingToLyric(null);
    setLinkRecording(null);
    setAddRecording(null);
    setLinkArtwork(null);
  };

  const handleLogout = () => {
    removeCookie("AuthToken");
    removeCookie("UserId");
    removeCookie("Name");
    removeCookie("Email");
    setReleasePanel(null);
    setBeatPanel(null);
    setLyricsPanel(null);
    setArtworksPanel(null);
    setAddBeat(null);
    setAddLyric(null);
    setLinkBeat(null);
    setAddArtwork(null);
    setBuildRelease(null);
    setReleaseBuildingPanel(null);
    setAddBeatToLyric(null);
    setAddRecordingToLyric(null);
    setLinkRecording(null);
    setAddRecording(null);
    setLinkArtwork(null);
    navigate("/");
  };

  return (
    <Flex
      className={`main-LoggedInHeader  ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex alignItems={"center"} gap={"10px"}>
        <IconButton
          onClick={toggleSidebar}
          icon={<TbMenuDeep />}
          size={"xs"}
          colorScheme="purple"
        />
        <Text onClick={toDash} className={"loggedInLogo"}>
          IndieMutual
        </Text>
      </Flex>
      <Flex alignItems={"center"} gap={"10px"}>
        <Tooltip
          placement="bottom"
          borderRadius={"7px"}
          label={"Notifications"}
        >
          <Button size={"sm"} variant={"outline"}>
            {colorMode === "light" ? (
              <IoMdNotificationsOutline fontSize={"18px"} color="blue" />
            ) : (
              <IoMdNotificationsOutline fontSize={"18px"} color="white" />
            )}
          </Button>
        </Tooltip>
        <Tooltip placement="bottom" borderRadius={"7px"} label={"Color Mode"}>
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={handleColorModeToggle}
          >
            {colorMode === "light" ? (
              <MoonIcon color="black" fontSize={"16px"} />
            ) : (
              <SunIcon color="yellow.400" fontSize={"18px"} />
            )}
          </Button>
        </Tooltip>

        <Menu>
          <Tooltip borderRadius={"8px"} label="Your Account" placement="bottom">
            <MenuButton
              as={Flex}
              borderRadius={"12px"}
              border={
                colorMode === "light"
                  ? ".5px solid transparent"
                  : ".5px solid transparent"
              }
              _hover={{
                border:
                  colorMode === "light"
                    ? ".5px solid rgba(50, 51, 56, 0.34)"
                    : ".5px solid rgba(208, 212, 228, 0.34)",
                cursor: "pointer",
              }}
              p={1.5}
              gap={"9px"}
              alignItems={"center"}
            >
              <Flex alignItems={"center"} gap={"5px"}>
                <Avatar size={"xs"} name={userName} />
              </Flex>
            </MenuButton>
          </Tooltip>
          <MenuList
            boxShadow={"lg"} // Example box shadow, adjust as needed
            p={1}
            border={
              colorMode === "light"
                ? "2px solid rgba(153, 178, 198, 0.23)"
                : "2px solid rgba(43, 46, 54, 1)"
            }
            bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          >
            {/* <MenuItem
              bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
              icon={<SettingsIcon />}
              onClick={toProfileSettings}
            >
              Settings
            </MenuItem> */}
            <MenuItem
              bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
              onClick={handleLogout}
              _hover={{
                bg:
                  colorMode === "light"
                    ? "rgba(241, 244, 249, 1)"
                    : "rgba(42, 49, 70, 1)",
              }}
              icon={<ArrowForwardIcon />}
            >
              Log Out
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default LoggedInHeader;
