import { Box } from "@chakra-ui/react";
import React from "react";

const Pricing = () => {
  return (
    <Box boxShadow={"md"} className="main-page-container">
      <Box>Pricing</Box>
    </Box>
  );
};

export default Pricing;
