import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import { API_ENDPOINT } from "../Config/config"; // Adjust the path as needed

const MutualBoardContext = createContext();

export const useMutualBoard = () => useContext(MutualBoardContext);

export const MutualBoardProvider = ({ children }) => {
  const [currentMutualBoard, setCurrentMutualBoard] = useState(null);
  const [boards, setBoards] = useState([]);

  const fetchBoards = useCallback(async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}api/boards/all`);
      console.log("Fetched Boards:", response.data.data); // Verify fetched data

      setBoards(response.data.data);
    } catch (error) {
      console.error("Failed to fetch boards:", error);
    }
  }, []);

  useEffect(() => {
    fetchBoards();
  }, [fetchBoards]);

  const value = {
    currentMutualBoard,
    setCurrentMutualBoard,
    boards,
    fetchBoards, // Providing a way to manually trigger board updates
  };

  return (
    <MutualBoardContext.Provider value={value}>
      {children}
    </MutualBoardContext.Provider>
  );
};
