import React from "react";
import "./Home.css";
import { Box, Flex } from "@chakra-ui/react";
import HomeGreeting from "../../Components/Greeting/HomeGreeting";
import HomeAddUsers from "../../Components/Greeting/HomeAddUsers";

const Home = ({ userId, colorMode, userName, isMobileView, guestToken }) => {
  return (
    <Box
      className={`Home-page-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        flexDirection={isMobileView ? "column" : "row"}
        className="surrounded-home"
      >
        <HomeGreeting
          isMobileView={isMobileView}
          colorMode={colorMode}
          userName={userName}
          userId={userId}
          guestToken={guestToken}
        />
        <HomeAddUsers
          isMobileView={isMobileView}
          colorMode={colorMode}
          userName={userName}
          userId={userId}
          guestToken={guestToken}
        />
      </Flex>
    </Box>
  );
};

export default Home;
