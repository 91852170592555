import React, { useState } from "react";
import {
  Box,
  Button,
  Textarea,
  useToast,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const LyricUploadForm = ({
  onClose,
  colorMode,
  userName,
  userId,
  fetchLyrics,
}) => {
  const [lyricsText, setLyricsText] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const toast = useToast(); // Initialize toast
  const [isTextAreaNotEmpty, setIsTextAreaNotEmpty] = useState(false);

  const handleTextChange = (event) => {
    setLyricsText(event.target.value);
    setIsTextAreaNotEmpty(event.target.value.length > 0);
  };

  const saveLyrics = async () => {
    setIsLoading(true); // Start loading

    const title =
      lyricsText.length > 15 ? lyricsText.substring(0, 15) + "..." : lyricsText;

    try {
      const response = await axios.post(`${API_ENDPOINT}api/lyrics/upload`, {
        userId,
        name: userName,
        lyricsText,
        title,
      });
      if (response.status === 200) {
        console.log("Lyrics saved successfully:", response.data);
        toast({
          title: "Lyrics saved.",
          description: "Your lyrics were successfully saved.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
        fetchLyrics();
      }
    } catch (error) {
      console.error("Error saving lyrics:", error);
      toast({
        title: "Error saving lyrics.",
        description: "An error occurred while saving your lyrics.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleSaveClick = () => {
    if (lyricsText.trim().length > 0) {
      saveLyrics();
    }
  };

  const handleClearClick = () => {
    setLyricsText("");
    setIsTextAreaNotEmpty(false);
  };

  return (
    <Box
      className={`lyricsuploadcomm ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Box>
        <Textarea
          placeholder="Copy and Paste Lyrics Here"
          value={lyricsText}
          onChange={handleTextChange}
        />
      </Box>
      <Flex alignItems={"cenetr"} gap={"10px"} mt={2}>
        <Button
          w={"30%"}
          colorScheme="red"
          size="sm"
          onClick={handleClearClick}
        >
          Clear
        </Button>
        <Button
          w={"100%"}
          colorScheme="green"
          onClick={handleSaveClick}
          isLoading={isLoading}
          disabled={!isTextAreaNotEmpty}
          size="sm"
          spinner={<Spinner size="sm" />}
        >
          Save
        </Button>
      </Flex>
    </Box>
  );
};

export default LyricUploadForm;
