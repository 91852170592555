import React, { useState } from "react";
import { Flex, Icon, Text, Tooltip, Image } from "@chakra-ui/react";
import "./DirectMessagesItem.css";
import { BsTrash } from "react-icons/bs";
import avatarMock from "../../../Svggs/avatarmock.svg";

const DirectMessagesItem = ({
  colorMode,
  directMessageId,
  fetchDirectMessages,
  directMessage,
  onDirectMessageSelect,
  userId,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const otherUser = directMessage.users.find((user) => user.userId !== userId);

  const otherUserName = otherUser
    ? otherUser.reciever || otherUser.sender
    : "Unknown";

  const handleSelectDirectMessage = (e) => {
    e.stopPropagation();
    onDirectMessageSelect({ directMessageId });
  };

  return (
    <Flex
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleSelectDirectMessage}
      className={`menu-drop-item ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      alignItems={"center"}
      gap={"8px"}
      justifyContent="space-between"
    >
      <Flex alignItems={"center"} gap={"8px"}>
        <Image src={avatarMock} className="directitemmock" />
        <Text className="recievername-text">{otherUserName}</Text>{" "}
      </Flex>
      {isHovered && (
        <Flex alignItems={"center"} gap={"6px"}>
          <Tooltip label="Add something here" placement="top">
            <Icon
              onClick={(event) => {
                event.stopPropagation();
              }}
              as={BsTrash}
              color={"red.300"}
              fontSize={"13px"}
              _hover={{ opacity: "50%", cursor: "pointer" }}
            />
          </Tooltip>
        </Flex>
      )}
    </Flex>
  );
};

export default DirectMessagesItem;
