import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  useToast,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsPlusLg, BsFolder, BsFolderPlus } from "react-icons/bs";
import axios from "axios";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { API_ENDPOINT } from "../../../../Config/config";
import AddWritingToUser from "./AddWritingToUser";
import { FaX } from "react-icons/fa6";
import { TbFolderPlus, TbUserPlus } from "react-icons/tb";
import "./AllWritingto.css";
import LinkLyricsModal from "./LinkLyricsModal";
import { PiFolderPlus, PiNotepadThin } from "react-icons/pi";
import { BiFolderPlus, BiMicrophone, BiUserPlus } from "react-icons/bi";
import { MdOutlineLyrics } from "react-icons/md";
import { GrDocument } from "react-icons/gr";
import LinkRecordingModal from "./LinkRecordingModal";
import LinkedItemRecording from "../../../Players/MutualSpacePlayers/LinkedItemRecording";
import AddToFolderModal from "../AddToFolderModal";
import CurrentBeatLyricItem from "../CurrentBeatLyricItem";
import CurrentBeatStatusItem from "../CurrentBeatStatusItem/CurrentBeatStatusItem";
import { FaFolderPlus } from "react-icons/fa";
import TimeLineDates from "../TimelineDates/TimeLineDates";

const AllWritingto = ({
  userId,
  currentBeat,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  beatId,
  fetchBeatData,
}) => {
  const [isAddWritingToUserOpen, setIsAddWritingToUserOpen] = useState(false);

  const [isDeleteWritingToUserOpen, setIsDeleteWritingToUserOpen] =
    useState(false);
  const inputBg = useColorModeValue("gray.50", "rgba(66, 69, 99, 0.23)");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");

  const toast = useToast();

  const [isHovering, setIsHovering] = useState(null);
  const [selectedWritingToId, setSelectedWritingToId] = useState(null);

  const [isLinkLyricsOpen, setIsLinkLyricsOpen] = useState(false);
  const [isLinkRecordingsOpen, setIsLinkRecordingsOpen] = useState(false);
  const [isAddToFolderOpen, setIsAddToFolderOpen] = useState(false);
  const [producer, setProducer] = useState(currentBeat?.producer || "");

  const handleProducerChange = (event) => {
    setProducer(event.target.value);
  };

  const charToColor = (char) => {
    const alphabetPosition = char.toUpperCase().charCodeAt(0) - 64;
    const hue = (alphabetPosition * 137) % 360; // 137 is a "magic" number
    const saturation = 50 + (alphabetPosition % 51); // Vary between 50% and 100%
    const lightness = 50 + ((alphabetPosition * 3) % 26); // Vary between 50% and 75%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const updateProducer = async () => {
    try {
      const response = await axios.patch(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/beat/${beatId}/producer`,
        {
          producer: producer,
        }
      );

      if (response.status === 200) {
        toast({
          title: "Producer Updated",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
        fetchBeatData(); // Refresh beat data if needed
      }
    } catch (error) {
      toast({
        title: "Error Updating Producer",
        description: "There was an issue updating the producer name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setProducer(currentBeat?.producer || "");
  }, [currentBeat]);

  return (
    <Box
      className={`writing-to-main-container-one ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      borderRadius={"6px"}
    >
      <Flex alignItems={"center"} gap={"15px"} w={"100%"}>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"3px"}
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={TbUserPlus} />
            <Text className="slighttext">Producer</Text>
          </Flex>
          <Input
            bg={inputBg}
            borderColor={borderColor}
            _hover={{
              bg: hoverBg,
              borderColor: "gray.400",
            }}
            borderRadius={"6px"}
            size={"sm"}
            value={producer}
            onChange={handleProducerChange}
            onBlur={updateProducer}
          />
        </Flex>
        <Flex w={"100%"}>
          <CurrentBeatStatusItem
            userId={userId}
            currentBeat={currentBeat}
            colorMode={colorMode}
            userName={userName}
            mutualSpaceId={mutualSpaceId}
            boardId={boardId}
            tableId={tableId}
            beatId={beatId}
            fetchBeatData={fetchBeatData}
          />
        </Flex>
      </Flex>
      <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"3px"}
        >
          <Flex w={"100%"} justifyContent={"space-between"} alignItems={"end"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon fontSize={"14px"} as={TbUserPlus} />
              <Text className="slighttext">Songwriters</Text>
            </Flex>
          </Flex>
          <Flex className="avatarsrow">
            {currentBeat?.writingTo?.usersWritingTo ? (
              <Flex className="fortunearrows">
                {currentBeat.writingTo?.usersWritingTo.map(
                  (writingTo, index) => (
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      className={`writing-to-item ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                      key={writingTo._id}
                    >
                      <Avatar mr={-4} size={"xs"} name={writingTo.user.name} />
                    </Flex>
                  )
                )}
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className={`writing-to-item ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  <Avatar
                    onClick={() => setIsAddWritingToUserOpen(true)}
                    size={"xs"}
                    icon={<BiUserPlus fontSize="15px" />}
                    _hover={{ cursor: "pointer", opacity: "80%" }}
                  />
                </Flex>
              </Flex>
            ) : (
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                className={`writing-to-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
                _hover={{ cursor: "pointer", opacity: "80%" }}
              >
                <Avatar
                  onClick={() => setIsAddWritingToUserOpen(true)}
                  size={"xs"}
                  icon={<BiUserPlus fontSize="15px" />}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex w={"100%"}>
          <Flex w={"100%"} flexDirection={"column"} gap={"4px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon fontSize={"14px"} as={MdOutlineLyrics} />
              <Text className="slighttext">Lyrics</Text>
            </Flex>

            {currentBeat?.linkedlyrics &&
            currentBeat.linkedlyrics.length > 0 ? (
              <Flex w={"100%"}>
                {currentBeat.linkedlyrics.map((lyric) => (
                  <Box w={"100%"} key={lyric._id}>
                    <CurrentBeatLyricItem colorMode={colorMode} lyric={lyric} />
                  </Box>
                ))}
              </Flex>
            ) : (
              <Button
                onClick={() => setIsLinkLyricsOpen(true)}
                w={"100%"}
                fontSize={"10px"}
                size={"sm"}
              >
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={BsPlusLg} />
                  <Text>Lyrics</Text>
                </Flex>
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"3px"}
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={BiMicrophone} />
            <Text className="slighttext">Recording</Text>
          </Flex>

          {currentBeat?.linkedRecordings &&
          currentBeat.linkedRecordings.length > 0 ? (
            <Flex w={"100%"}>
              {currentBeat.linkedRecordings.map((recording) => (
                <Box w={"100%"} key={recording._id}>
                  <LinkedItemRecording
                    recording={recording}
                    colorMode={colorMode}
                  />
                </Box>
              ))}
            </Flex>
          ) : (
            <Button
              onClick={() => setIsLinkRecordingsOpen(true)}
              fontSize="10px"
              size="sm"
              w={"100%"}
            >
              Link Recording
            </Button>
          )}
        </Flex>
        <Flex w={"100%"}>
          <Flex
            w={"100%"}
            flexDirection={"column"}
            alignItems={"start"}
            gap={"3px"}
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon fontSize={"12px"} as={BsFolder} />
              <Text className="slighttext">Folders</Text>
            </Flex>

            {currentBeat?.folders && currentBeat.folders.length > 0 ? (
              <Flex height={"100%"} w={"100%"}>
                {currentBeat.folders.map((folder) => (
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    className={`writing-to-item ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                    key={folder._id}
                  >
                    <Avatar
                      icon={<BsFolder fontSize="15px" />}
                      mr={-4}
                      size={"xs"}
                      bg={charToColor(folder.folderName)}
                    />
                  </Flex>
                ))}
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className={`writing-to-item ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  <Avatar
                    onClick={() => setIsAddToFolderOpen(true)}
                    size={"xs"}
                    icon={<BsFolderPlus fontSize="15px" />}
                    _hover={{ cursor: "pointer", opacity: "80%" }}
                  />
                </Flex>
              </Flex>
            ) : (
              <Button
                onClick={() => setIsAddToFolderOpen(true)}
                fontSize={"10px"}
                size={"sm"}
                w={"100%"}
              >
                Add to Folder
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex w={"100%"}>
        <TimeLineDates
          userId={userId}
          currentBeat={currentBeat}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          beatId={beatId}
        />
      </Flex>
      <Modal
        isOpen={isAddWritingToUserOpen}
        onClose={() => setIsAddWritingToUserOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Add Songwriter or Artist</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddWritingToUser
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              currentBeat={currentBeat}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              beatId={beatId}
              setIsAddWritingToUserOpen={setIsAddWritingToUserOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isLinkLyricsOpen}
        onClose={() => setIsLinkLyricsOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Link Lyrics</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LinkLyricsModal
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              currentBeat={currentBeat}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              beatId={beatId}
              fetchBeatData={fetchBeatData}
              setIsLinkLyricsOpen={setIsLinkLyricsOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isLinkRecordingsOpen}
        onClose={() => setIsLinkRecordingsOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Link Recording</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LinkRecordingModal
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              currentBeat={currentBeat}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              beatId={beatId}
              setIsLinkRecordingsOpen={setIsLinkRecordingsOpen}
              fetchBeatData={fetchBeatData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isAddToFolderOpen}
        onClose={() => setIsAddToFolderOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Icon className="granticon" as={PiFolderPlus} />
              <Text className="accessheader">Add to Folder</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddToFolderModal
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              currentBeat={currentBeat}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              beatId={beatId}
              setIsAddToFolderOpen={setIsAddToFolderOpen}
              fetchBeatData={fetchBeatData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AllWritingto;
