import { createContext, useState, useContext } from "react";

// Create the context
const AddBeatToReleaseContext = createContext();

// Custom hook for easy context usage
export const useAddBeatToRelease = () => useContext(AddBeatToReleaseContext);

// Provider component
export const AddBeatToReleaseProvider = ({ children }) => {
  const [addBeat, setAddBeat] = useState(null); // New state and setter function

  return (
    <AddBeatToReleaseContext.Provider value={{ addBeat, setAddBeat }}>
      {children}
    </AddBeatToReleaseContext.Provider>
  );
};
