import { createContext, useState, useContext } from "react";

const ArtworksPanelContext = createContext();

export const useArtworksPanel = () => useContext(ArtworksPanelContext);

export const ArtworksPanelProvider = ({ children }) => {
  const [artworksPanel, setArtworksPanel] = useState(null);

  return (
    <ArtworksPanelContext.Provider value={{ artworksPanel, setArtworksPanel }}>
      {children}
    </ArtworksPanelContext.Provider>
  );
};
