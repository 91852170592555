import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Input,
  Text,
  MenuButton,
  Menu,
  MenuList,
  Icon,
  Button,
  Tooltip,
  MenuItem,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Badge,
} from "@chakra-ui/react";
import { BsPencil, BsLink, BsActivity } from "react-icons/bs";
import { FaMicrophone, FaChevronRight, FaComment } from "react-icons/fa6";
import { GrInProgress } from "react-icons/gr";
import { GiMusicalNotes } from "react-icons/gi";
import RecordingsPanelPlayer from "../../Players/RecordingsPanelPlayer/RecordingsPanelPlayer";
import { API_ENDPOINT } from "../../../Config/config";

const MobileRecordingsPanel = ({
  colorMode,
  currentRecording,
  setCurrentRecording,
}) => {
  const { isOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState("comments"); // Default to showing comments

  const formatDate = (uploadDate) => {
    const uploadedAt = new Date(uploadDate);
    const now = new Date();
    const timeDiff = now.getTime() - uploadedAt.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    if (daysDiff < 1) {
      const hoursDiff = timeDiff / (1000 * 3600);
      if (hoursDiff < 1) {
        const minutesDiff = timeDiff / (1000 * 60);
        return `${Math.floor(minutesDiff)} minutes ago`;
      }
      return `${Math.floor(hoursDiff)} hours ago`;
    } else if (daysDiff < 10) {
      return `${Math.floor(daysDiff)} days ago`;
    } else {
      return `on ${uploadedAt.toLocaleString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      })} @ ${uploadedAt.toLocaleTimeString()}`;
    }
  };

  const [artistName, setArtistName] = useState(
    currentRecording?.artist || "Artist"
  );
  const [pendingArtistName, setPendingArtistName] = useState(
    currentRecording?.artist || "Artist"
  );

  const handleArtistNameChange = (event) => {
    setPendingArtistName(event.target.value);
  };

  const handleArtistNameBlur = async () => {
    if (pendingArtistName !== artistName) {
      try {
        const response = await fetch(
          `${API_ENDPOINT}api/recording/${currentRecording._id}/artist`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ artistName: pendingArtistName }),
          }
        );

        if (response.ok) {
          // Upon successful update, synchronize producerName and pendingProducerName with the new value.
          setArtistName(pendingArtistName);
          setCurrentRecording({
            ...currentRecording,
            artist: pendingArtistName,
          });
          console.log("Artist name updated successfully");
        } else {
          console.error("Failed to update artist name");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    setArtistName(currentRecording?.artist || "");
    setPendingArtistName(currentRecording?.artist || "");
  }, [currentRecording]);

  return (
    <>
      <Box className="beatsmobile-panel-container">
        <Box
          className={`beats-panel-topone ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Flex w={"100%"} flexDirection={"column"} gap={"2px"}>
            <RecordingsPanelPlayer
              currentRecording={currentRecording}
              colorMode={colorMode}
            />
          </Flex>

          <Flex w={"100%"} flexDirection={"column"} gap={"5px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon className={"panel-title-icon"} as={GiMusicalNotes} />
              <Text className="panel-title-text">Recording Artist</Text>
            </Flex>
            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Producer Name"}
            >
              <Input
                bg={colorMode === "light" ? "white" : "rgba(26, 32, 44, 1)"}
                w={"100%"}
                borderRadius={"6px"}
                size={"sm"}
                placeholder="Recording Artist Name"
                value={pendingArtistName} // Use pendingProducerName here
                onChange={handleArtistNameChange}
                onBlur={handleArtistNameBlur}
              />
            </Tooltip>
          </Flex>
          <Flex flexDirection={"column"} gap={"5px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon className={"panel-title-icon"} as={GrInProgress} />
              <Text className="panel-title-text">Recording Status</Text>
            </Flex>
            <Menu
              border={
                colorMode === "light"
                  ? "2px solid rgba(153, 178, 198, 0.23)"
                  : "2px solid rgba(43, 46, 54, 1)"
              }
              bg={colorMode === "light" ? "white" : "black"}
            >
              {({ isOpen }) => (
                <>
                  <Flex alignItems="center" gap={2}>
                    <Tooltip
                      placement="bottom"
                      borderRadius={"7px"}
                      label={"Set Status"}
                    >
                      <MenuButton
                        colorScheme="orange"
                        w={"100%"}
                        size={"xs"}
                        as={Button}
                        rightIcon={
                          <FaChevronRight
                            style={{
                              transition: "transform 0.2s",
                              transform: isOpen ? "rotate(90deg)" : "none",
                            }}
                          />
                        }
                      >
                        Set Status
                      </MenuButton>
                    </Tooltip>
                  </Flex>
                  <MenuList
                    p={2}
                    bg={colorMode === "light" ? "white" : "black"}
                    zIndex={"9999"}
                  >
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(249, 185, 89, .23)"
                              : "rgba(249, 185, 89, .23)",
                        },
                      }}
                      bg={colorMode === "light" ? "white" : "black"}
                      className="blank"
                    >
                      <Icon as={BsPencil} fontSize="17px" />
                      <Text className="rager">Writting To</Text>
                    </MenuItem>
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                    >
                      <Icon as={FaMicrophone} fontSize="17px" />

                      <Text className="rager">Recorded On</Text>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>

          <Flex flexDirection={"column"} gap={"5px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon className={"panel-title-icon"} as={BsLink} />
              <Text className="panel-title-text">Linked</Text>
            </Flex>
            <Flex flexDirection={"column"} gap={"5px"} w={"100%"}>
              <Badge
                w={"100%"}
                colorScheme="yellow"
                variant="subtle"
                className="cardtyeuio"
                _hover={{ opacity: "80%", cursor: "pointer" }}
              >
                -
              </Badge>

              <Badge
                w={"100%"}
                colorScheme="green"
                variant="subtle"
                className="cardtyeuio"
                _hover={{ opacity: "80%", cursor: "pointer" }}
              >
                -
              </Badge>
            </Flex>
          </Flex>
          <Flex alignItems={"center"} gap={"5px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Text className="panel-title-text">Uploaded By</Text>

              <Avatar name={currentRecording?.user.name} size={"xs"} />
              <Text className="panel-title-text">
                {formatDate(currentRecording?.uploadDate)}
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Box
          className={`beatspanel-bottomone ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Flex
            className={`toppedtabs ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
            alignItems={"center"}
            gap={"10px"}
          >
            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Beat Comments"}
            >
              <Button
                colorScheme={activeTab === "comments" ? "blue" : "gray"}
                size={"xs"}
                w={"100%"}
                onClick={() => setActiveTab("comments")}
              >
                <Flex gap={"5px"} alignItems={"center"}>
                  <Icon as={FaComment} />
                  <Text>Comments</Text>
                </Flex>
              </Button>
            </Tooltip>

            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Recent Beat Activity"}
            >
              <Button
                colorScheme={activeTab === "activity" ? "blue" : "gray"}
                size={"xs"}
                w={"100%"}
                onClick={() => setActiveTab("activity")}
              >
                <Flex gap={"5px"} alignItems={"center"}>
                  <Icon as={BsActivity} />
                  <Text>Activity</Text>
                </Flex>
              </Button>
            </Tooltip>
          </Flex>
          <Box className="tabedcontent">
            {activeTab === "comments" && (
              <>{/* <Comments colorMode={colorMode} /> */}</>
            )}
            {activeTab === "activity" && <Flex>Activity Here</Flex>}
          </Box>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lyrics</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* You can place the actual lyrics or any content you want here */}
            <Text>Here are the lyrics...</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {/* Add any additional buttons here if needed */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MobileRecordingsPanel;
