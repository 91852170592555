import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  MenuItem,
  Menu,
  Text,
  Button,
  MenuButton,
  MenuList,
  Flex,
  Input,
  Tooltip,
  IconButton,
  Box,
  Portal,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import "./ItemType.css";
import { API_ENDPOINT } from "../../Config/config";
import axios from "axios";

const ItemType = ({ item, index, colorMode, tableId, items, setItems }) => {
  const [storedCustomTypes, setStoredCustomTypes] = useState([]);
  const toast = useToast();
  const [typeColors, setTypeColors] = useState({});

  const updateItemType = async (itemIndex, newType, color) => {
    const item = items[itemIndex];
    if (!item || !item._id) {
      toast({
        title: "Error",
        description: "Item ID not found.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.put(
        `${API_ENDPOINT}api/tasktable/${tableId}/item/${item._id}/type`,
        {
          newType,
          color,
        }
      );

      toast({
        title: "Type Updated",
        description: "The item's type was successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      const updatedItems = items.map((itm, idx) =>
        idx === itemIndex ? { ...itm, type: newType } : itm
      );
      setItems(updatedItems);
    } catch (error) {
      console.error("Error updating item type:", error);
      toast({
        title: "Error",
        description: "Failed to update the item's type.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [customTypes, setCustomTypes] = useState([]);
  const [newTypeInput, setNewTypeInput] = useState("");

  const handleAddType = async () => {
    const trimmedType = newTypeInput.trim();
    if (!trimmedType || customTypes.some((ct) => ct.type === trimmedType))
      return;

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/tasktable/${tableId}/customtypes`,
        {
          type: trimmedType,
        }
      );
      const newType = { type: trimmedType, color: null };
      // setStoredCustomTypes((prev) => [...prev, newType]); // Add the new type to the local state
      setStoredCustomTypes((prev) => [newType, ...prev]);

      toast({
        title: "Type Added",
        description: "A new type was successfully added.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setNewTypeInput("");
      updateItemType(index, trimmedType, null); // Optionally update the item type immediately
    } catch (error) {
      console.error("Error adding new type:", error);
      toast({
        title: "Error",
        description: "There was an error adding the new type.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchCustomTypes = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/tasktable/${tableId}/customtypes`
      );
      setStoredCustomTypes(response.data.customTypes);
      const initialTypeColors = response.data.customTypes.reduce(
        (acc, { type, color }) => {
          acc[type] = color || "transparent";
          return acc;
        },
        {}
      );
      setTypeColors(initialTypeColors);
    } catch (error) {
      console.error("Failed to fetch custom types:", error);
      toast({
        title: "Error fetching custom types",
        description: "Unable to fetch custom types. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCustomTypes();
  }, [tableId]);

  const colorOptions = [
    "#E53E3E", // Red.500
    "#3182CE", // Blue.500
    "#38A169", // Green.500
    "#DD6B20", // Orange.500
    "#805AD5", // Purple.500
    colorMode === "light" ? "rgba(251, 170, 74, 1)" : "rgba(252, 187, 107, 1)", // Yellow.500
    "#4FD1C5", // Teal.500
    "#ED64A6", // Pink.500
    "#718096", // Gray.500
    "#F687B3", // Pink.300 - Lighter for contrast in dark mode
  ];

  const handleColorSelect = async (type, color) => {
    try {
      const response = await axios.patch(
        `${API_ENDPOINT}api/tasktable/${tableId}/customtypes/${type}`,
        {
          color: color,
        }
      );
      if (response.data) {
        setStoredCustomTypes((prev) =>
          prev.map((ct) => (ct.type === type ? { ...ct, color: color } : ct))
        );

        setTypeColors((prevColors) => ({
          ...prevColors,
          [type]: color,
        }));
        toast({
          title: "Color Updated",
          description: "The color for the type was successfully updated.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating type color:", error);
      toast({
        title: "Error",
        description: "There was an error updating the color for the type.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Menu>
        <MenuButton
          onClick={(event) => event.stopPropagation()} // Stop event propagation
          size="xs"
          as={Button}
          key={index}
          rightIcon={<ChevronDownIcon />}
          variant="outline"
          w={"100%"}
          border={"none"}
          className="main-menu-button-text"
          backgroundColor={typeColors[item?.type] || "transparent"} // Apply the selected color
        >
          {item?.type ? <Text>{item?.type}</Text> : <Text>Set Type</Text>}
        </MenuButton>
        <MenuList>
          <Flex alignItems={"center"} gap={"5px"} p={1}>
            <Input
              borderRadius={"7px"}
              placeholder="Add Type..."
              size={"xs"}
              width={"100%"}
              value={newTypeInput}
              onChange={(e) => setNewTypeInput(e.target.value)}
            />
            <Tooltip
              label="Add Type"
              hasArrow
              placement="top"
              closeOnClick={false}
            >
              <IconButton
                icon={<BsPlus />}
                size={"xs"}
                colorScheme="green"
                onClick={handleAddType}
              />
            </Tooltip>
          </Flex>

          <Box className="menu-host">
            {storedCustomTypes.map(({ type, color }, idx) => (
              <>
                <MenuItem
                  key={`stored-${idx}`}
                  onClick={() => updateItemType(index, type)}
                >
                  <Flex alignItems="center" gap={"10px"} width="100%">
                    <Popover placement="left-start">
                      <PopoverTrigger>
                        <Box
                          as="button" // Ensures the box can be focused and interacted with like a button
                          w="20px"
                          h="20px"
                          border="1px solid" // Optional: adds a border to the square
                          borderColor="gray.300" // Optional: sets the border color
                          backgroundColor={color || "transparent"}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent w="auto">
                          <SimpleGrid columns={5} spacing={2} p={2}>
                            {colorOptions.map((color, colorIndex) => (
                              <Box
                                key={colorIndex}
                                w="20px"
                                h="20px"
                                bg={color}
                                onClick={() => handleColorSelect(type, color)}
                              />
                            ))}
                          </SimpleGrid>
                        </PopoverContent>
                      </Portal>
                    </Popover>
                    {type}
                  </Flex>
                </MenuItem>
              </>
            ))}
          </Box>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default ItemType;
