import React from "react";
import { Box, Flex, Icon, Text, Button } from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";

const NoPlanningTables = ({ onOpen, colorMode }) => {
  return (
    <Box
      className={`nobeats-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      bg={colorMode === "light" ? "gray.50" : "gray.800"}
      border={
        colorMode === "light"
          ? ".5px solid rgba(187, 186, 187, 1)"
          : "1px solid rgba(128, 128, 128, 1)"
      }
    >
      <Flex
        gap={"15px"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text
          className={`getstartedbelow ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
          textAlign={"center"}
        >
          Get started by creating a task table!
        </Text>
        <Flex>
          <Button size={"sm"} onClick={onOpen} colorScheme="blue">
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={BsPlusLg} />
              <Text>New</Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NoPlanningTables;
