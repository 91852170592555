import { createContext, useState, useContext } from "react";

// Create the context
const BuildReleaseContext = createContext();

// Custom hook for easy context usage
export const useBuildRelease = () => useContext(BuildReleaseContext);

// Provider component
export const BuildReleaseProvider = ({ children }) => {
  const [buildRelease, setBuildRelease] = useState(null); // New state and setter function

  return (
    <BuildReleaseContext.Provider value={{ buildRelease, setBuildRelease }}>
      {children}
    </BuildReleaseContext.Provider>
  );
};
