import React, { useState, useRef } from "react";
import {
  Button,
  FormControl,
  Input,
  Box,
  Text,
  Alert,
  AlertDescription,
  CloseButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  IconButton,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { FaPlay, FaPause, FaTimes } from "react-icons/fa";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { FaMicrophone } from "react-icons/fa6";

const RecordingFormUpload = ({
  userName,
  userId,
  fetchRecordings,
  onClose,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioTime, setAudioTime] = useState(0);
  const audioRef = useRef(new Audio());
  const [file, setFile] = useState(null);
  const [fileDetails, setFileDetails] = useState("");
  const [inputKey, setInputKey] = useState(Date.now());
  const [recordingTitle, setRecordingTitle] = useState(""); // State for the beat title

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setRecordingTitle(selectedFile.name.split(".").slice(0, -1).join("."));
      setFileDetails(
        `File name: ${selectedFile.name}, File type: ${selectedFile.type}, File size: ${selectedFile.size} bytes`
      );

      const audioURL = URL.createObjectURL(selectedFile);
      audioRef.current = new Audio(audioURL);
      // Reset playback state
      setIsPlaying(false);
      setAudioTime(0);
    }
  };

  const clearFile = () => {
    setFile(null);
    setFileDetails("");

    setInputKey(Date.now());
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const onUploadBoxClick = () => {
    fileInputRef.current.click();
  };

  const uploadRecording = async () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    setIsUploading(true); // Start loading

    const formData = new FormData();
    formData.append("files", file);
    formData.append("name", userName);
    formData.append("userId", userId);
    formData.append("recordingTitle", recordingTitle); // Add this line

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/recording/upload`, // Modify the URL to your recording upload endpoint
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Upload successful", response.data);
      fetchRecordings();
      onClose();
    } catch (error) {
      console.error("Error uploading recording:", error);
    } finally {
      setIsUploading(false); // End loading
    }
  };

  const renderAudioPlayer = () => {
    if (!file) return null;

    const handleSliderChange = (value) => {
      audioRef.current.currentTime = value;
      setAudioTime(value);
    };

    return (
      <Flex
        borderRadius={"12px"}
        gap={"15px"}
        boxShadow={"md"}
        className="player-preview"
        alignItems={"center"}
      >
        <IconButton
          icon={isPlaying ? <FaPause /> : <FaPlay />}
          colorScheme="blue"
          variant={"outline"}
          border={"none"}
          aria-label="Play/Pause"
          onClick={togglePlayPause}
          fontSize={"34px"}
        />
        <Slider
          width={"100%"}
          value={audioTime}
          max={audioRef.current.duration || 0}
          onChange={handleSliderChange}
          focusThumbOnChange={false}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>

        <IconButton
          onClick={clearFile}
          icon={<FaTimes />}
          size={"xs"}
          colorScheme="red"
        />
      </Flex>
    );
  };

  return (
    <Flex mt={2} flexDirection={"column"} gap={"20px"}>
      <FormControl display="none">
        <Input
          ref={fileInputRef} // Attach ref to input
          type="file"
          accept="audio/*"
          onChange={handleFileChange}
          key={inputKey}
        />
      </FormControl>
      {!file && (
        <Button
          variant={"outline"}
          className="upload-beat-btn"
          onClick={onUploadBoxClick}
          colorScheme="blue"
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon className="modal-title-icon" as={FaMicrophone} />
            <Text className="modal-title-text"> Upload Recording</Text>
          </Flex>
        </Button>
      )}

      <Flex w={"100%"}>
        {renderAudioPlayer()} {/* Call this function to render the player */}
      </Flex>

      {file && (
        <>
          <Alert status="info" variant="subtle" height="100%" borderRadius="md">
            <AlertDescription textAlign={"start"}>
              <Box className="linkin">
                <Text>File name: {file.name}</Text>
                <Text>File type: {file.type}</Text>
                <Text>File size: {file.size} bytes</Text>
              </Box>
            </AlertDescription>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={clearFile}
            />
          </Alert>
          <Box>
            <Button
              w={"100%"}
              colorScheme="teal"
              onClick={uploadRecording}
              isLoading={isUploading}
              loadingText="Uploading"
              isDisabled={isUploading}
            >
              Upload Recording
            </Button>
          </Box>
        </>
      )}
    </Flex>
  );
};

export default RecordingFormUpload;
