import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Text,
  Tooltip,
  SimpleGrid,
  Spinner,
  Center,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  IconButton,
  useToast,
  CircularProgress,
  CircularProgressLabel,
  useColorModeValue,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import "./HomeReleaseItem.css";
import ReleaseStatusItem from "../ReleasesTable/ReleaseStatusItem";
import { TbCircleCheckFilled } from "react-icons/tb";

const HomeReleaseItem = ({ release, colorMode, releaseId }) => {
  const calculateCompletionPercentage = (release) => {
    const pieces = [
      release.releaseName,
      release.releaseType,
      release.lyrics.length > 0,
      release.artwork.length > 0,
      release.recordings.length > 0,
      release.schedule,
    ];

    const filledPieces = pieces.filter(Boolean).length;
    return (filledPieces / 6) * 100;
  };

  const getCompletionColor = (percentage) => {
    if (percentage === 100) return "green.400";
    if (percentage >= 66) return "yellow.400";
    return "red.400";
  };

  const completionPercentage = calculateCompletionPercentage(release);
  const completionColor = getCompletionColor(completionPercentage);

  const artworkUrl =
    release.artwork && release.artwork.length > 0
      ? typeof release.artwork[0] === "string"
        ? release.artwork[0]
        : release.artwork[0].imageUrl
      : "";

  return (
    <Box
      className={`HomeReleaseItem-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex flexDirection={"column"} gap={"10px"}>
          <Flex>{release.releaseName}</Flex>
          <Flex>
            {release.releaseDate ? (
              <Flex alignItems={"center"}>
                <Tag
                  w={"100%"}
                  h={"30px"}
                  colorScheme="green"
                  borderRadius="full"
                >
                  <Icon
                    as={TbCircleCheckFilled}
                    fontSize={"20px"}
                    ml={-1}
                    mr={1}
                    color={colorMode === "light" ? "green.300" : "green.100"}
                  />
                  <TagLabel>Released</TagLabel>
                </Tag>
              </Flex>
            ) : (
              <>
                <ReleaseStatusItem
                  releaseId={releaseId}
                  colorMode={colorMode}
                  release={release}
                />
              </>
            )}
          </Flex>
        </Flex>
        <Flex>
          <CircularProgress
            value={completionPercentage}
            color={completionColor}
            size="60px"
          >
            <CircularProgressLabel>
              {completionPercentage.toFixed(0)}%
            </CircularProgressLabel>
          </CircularProgress>
        </Flex>
      </Flex>
    </Box>
  );
};

export default HomeReleaseItem;
