import { createContext, useState, useContext } from "react";

const RecordingsPanelContext = createContext();

export const useRecordingsPanel = () => useContext(RecordingsPanelContext);

export const RecordingsPanelProvider = ({ children }) => {
  const [recordingsPanel, setRecordingsPanel] = useState(null);

  return (
    <RecordingsPanelContext.Provider
      value={{ recordingsPanel, setRecordingsPanel }}
    >
      {children}
    </RecordingsPanelContext.Provider>
  );
};
