import React from "react";
import "./UserAvatars.css";
import {
  Box,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorMode,
  Text,
  Flex,
} from "@chakra-ui/react";

const UserAvatars = ({ users, onAvatarClick }) => {
  const { colorMode } = useColorMode();

  const userCount = users.length;
  const maxAvatarsToShow = 2;
  const remainingUsers = userCount - maxAvatarsToShow;

  return (
    <Box className="useavatarscontainer">
      {users.slice(0, maxAvatarsToShow).map((user) => (
        <Flex>
          <Avatar
            key={user._id}
            name={user.name}
            size={"sm"}
            onClick={() => onAvatarClick(user._id)}
            className="foreveravatar"
            ml={-3} // Apply negative margin from the second avatar onwards
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
              opacity: "60%",
            }}
          />
        </Flex>
      ))}
      {userCount > maxAvatarsToShow && (
        <Menu
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <MenuButton
            as={Box}
            size={"sm"}
            bg="green.500"
            color="white"
            display="flex"
            zIndex={"1000"}
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
            width="2rem"
            ml={-3} // Apply negative margin from the second avatar onwards
            height="2rem"
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
              opacity: "60%",
            }}
          >
            +{remainingUsers}
          </MenuButton>
          <MenuList p={2} bg={colorMode === "light" ? "white" : "black"}>
            {users.slice(maxAvatarsToShow).map((user) => (
              <MenuItem
                bg={colorMode === "light" ? "white" : "black"}
                p={2}
                sx={{
                  "&:hover": {
                    bg:
                      colorMode === "light"
                        ? "rgba(249, 185, 89, .23)"
                        : "rgba(249, 185, 89, .23)",
                  },
                }}
                key={user._id}
                onClick={() => onAvatarClick(user._id)}
              >
                <Avatar name={user.name} size="xs" mr="8px" />
                <Text className="glasswent">{user.name}</Text>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
    </Box>
  );
};

export default UserAvatars;
