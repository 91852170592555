import React from "react";
import "./MobileFooter.css";
import { Box, Text } from "@chakra-ui/react";

const MobileFooter = ({ navigate }) => {
  return (
    <Box as="footer" boxShadow={"md"} className="mobile-footer-container">
      <Box>
        <Text className="claimtext">@2024 IndieMutual</Text>
      </Box>
    </Box>
  );
};

export default MobileFooter;
