import { createContext, useState, useContext } from "react";

const ReleasesPageTableContext = createContext();

export const useReleasesPageTable = () => useContext(ReleasesPageTableContext);

export const ReleasesPageTableProvider = ({ children }) => {
  const [releasesPageTable, setReleasesPageTable] = useState(null);

  return (
    <ReleasesPageTableContext.Provider
      value={{ releasesPageTable, setReleasesPageTable }}
    >
      {children}
    </ReleasesPageTableContext.Provider>
  );
};
