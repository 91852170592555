import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  Text,
  MenuButton,
  Menu,
  MenuList,
  Icon,
  Button,
  Tooltip,
  MenuItem,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Badge,
} from "@chakra-ui/react";
import "./BeatsPanel.css";
import { BsPencil, BsLink } from "react-icons/bs";
import {
  FaMicrophone,
  FaChevronRight,
  FaComment,
  FaCircleCheck,
} from "react-icons/fa6";
import { MdOutlineLyrics } from "react-icons/md";
import { GrInProgress } from "react-icons/gr";
import { GiMusicalNotes } from "react-icons/gi";
import BeatsPanelPlayer from "../../Players/BeatsPanelPlayer/BeatsPanelPlayer";
import { TbMicrophone2 } from "react-icons/tb";
import { API_ENDPOINT } from "../../../Config/config";
import "./MobileBeatsPanel.css";
import Comments from "../../Comment/Comments";

const MobileBeatsPanel = ({
  colorMode,
  currentBeat,
  setCurrentBeat,
  userId,
  userName,
  guestToken,
}) => {
  const { isOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState("comments"); // Default to showing comments

  const [producerName, setProducerName] = useState(
    currentBeat?.producer || "Producer"
  );
  const [pendingProducerName, setPendingProducerName] = useState(
    currentBeat?.producer || "Producer"
  );

  const [beatStatus, setBeatStatus] = useState(
    currentBeat?.status || "Set Status"
  );

  const formatDate = (uploadDate) => {
    const uploadedAt = new Date(uploadDate);
    const now = new Date();
    const timeDiff = now.getTime() - uploadedAt.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    if (daysDiff < 1) {
      const hoursDiff = timeDiff / (1000 * 3600);
      if (hoursDiff < 1) {
        const minutesDiff = timeDiff / (1000 * 60);
        return `${Math.floor(minutesDiff)} minutes ago`;
      }
      return `${Math.floor(hoursDiff)} hours ago`;
    } else if (daysDiff < 10) {
      return `${Math.floor(daysDiff)} days ago`;
    } else {
      return `on ${uploadedAt.toLocaleString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      })} @ ${uploadedAt.toLocaleTimeString()}`;
    }
  };

  return (
    <>
      <Box className="beatsmobile-panel-container">
        <Box
          boxShadow={"md"}
          className={`mobilebeats-panel-topone ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Flex w={"100%"} flexDirection={"column"} gap={"2px"}>
            <BeatsPanelPlayer colorMode={colorMode} currentBeat={currentBeat} />
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default MobileBeatsPanel;
