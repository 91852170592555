import React, { useState, useRef } from "react";
import "./MessengerList.css";
import {
  Box,
  Text,
  Icon,
  Button,
  Flex,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Avatar,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import AddChannelMessages from "../Forms/MessagesForms/AddChannelMessages";
import AddGroupMessages from "../Forms/MessagesForms/AddGroupMessages";
import { BsPlusLg } from "react-icons/bs";
import InviteUsersMessages from "../Forms/MessagesForms/InviteUsersMessages";
import ChannelItem from "./ChannelItem";
import NoChannels from "../EmptyStates/NoChannels";
import { useDirectMessageDisplay } from "../../Context/DirectMessageDisplayContext";
import DirectMessagesItem from "./DirectMessages/DirectMessagesItem";
import NoDirectMessages from "../EmptyStates/NoDirectMessages";

const MessengerList = ({
  colorMode,
  userName,
  userId,
  isMobileView,
  onChannelSelect,
  onDirectMessageSelect,
  isLoading,
  channels,
  fetchChannels,
  guestToken,
  directMessages,
  fetchDirectMessages,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();
  const [isDirectMessagesOpen, setIsDirectMessagesOpen] = useState(true); // Step 2: New state for toggling the assets section
  const toggleDirectMessagesOpen = () =>
    setIsDirectMessagesOpen(!isDirectMessagesOpen);
  const [isChannelsOpen, setIsChannelsOpen] = useState(true); // Step 2: New state for toggling the assets section
  const toggleChannelsOpen = () => setIsChannelsOpen(!isChannelsOpen);
  const [isAddChannelModalOpen, setIsAddChannelModalOpen] = useState(false);
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const { setDirectMessages } = useDirectMessageDisplay();

  return (
    <Box
      className={`MessengerList-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      boxShadow={"md"}
    >
      <Flex
        className="droprefmenu"
        flexDirection={"column"}
        w={"100%"}
        ref={dropdownRef}
      >
        {guestToken ? (
          <>
            <Button
              _hover={{ transform: "none" }}
              w={"100%"}
              className="dropdown-button"
            >
              {" "}
              <Flex alignItems={"center"} w={"100%"} justifyContent={"center"}>
                <Text textAlign={"center"} className="titlesection">
                  Welcome Guest
                </Text>
              </Flex>
            </Button>
          </>
        ) : (
          <>
            <Button w={"100%"} className="dropdown-button">
              <Flex
                alignItems={"center"}
                w={"100%"}
                justifyContent={"center"}
                gap={"10px"}
              >
                <Avatar name={userName} size={"xs"} />
                <Text className="titlesection">{userName}'s Messages</Text>
              </Flex>
            </Button>
          </>
        )}
      </Flex>
      <Flex mt={5} gap={"5px"} p={1} flexDirection={"column"} w={"100%"}>
        <>
          <Flex
            w={"100%"}
            _hover={{ opacity: "80%" }}
            fontSize={"15px"}
            color={"rgba(150, 158, 167, 1)"}
            alignItems={"center"}
            justifyContent={"space-between"}
            cursor={"pointer"}
            onClick={toggleChannelsOpen}
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={isChannelsOpen ? FiChevronDown : FiChevronRight} />{" "}
              <Text>Channels</Text>
            </Flex>

            <Flex>
              {!guestToken && (
                <>
                  <Tooltip
                    placement="top"
                    borderRadius={"7px"}
                    label={"Add Channel"}
                  >
                    <Icon
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsAddChannelModalOpen(true);
                        setDirectMessages(null);
                        onDirectMessageSelect(false); // Or some meaningful value to indicate DirectMessages should be shown
                      }}
                      as={BsPlusLg}
                      size={"xs"}
                    />
                  </Tooltip>
                </>
              )}
            </Flex>
          </Flex>
          {isChannelsOpen && (
            <Flex mt={1} gap={"8px"} flexDirection={"column"} w={"100%"}>
              {isLoading ? (
                <Center mt={2}>
                  <Spinner />
                </Center>
              ) : channels.length > 0 ? (
                <Flex flexDirection={"column"} gap={"5px"}>
                  {channels.map((channel) => (
                    <ChannelItem
                      key={channel._id}
                      channelId={channel._id}
                      channelName={channel.channelName}
                      colorMode={colorMode}
                      fetchChannels={fetchChannels}
                      onChannelSelect={() => {
                        onChannelSelect(channel); // Your existing channel selection logic
                        setDirectMessages(null); // Ensure DirectMessages is hidden in MessageTable
                        onDirectMessageSelect(false);
                      }}
                      guestToken={guestToken}
                    />
                  ))}
                </Flex>
              ) : (
                <Box>
                  <NoChannels
                    setIsAddChannelModalOpen={setIsAddChannelModalOpen}
                    colorMode={colorMode}
                    isMobileView={isMobileView}
                  />
                </Box>
              )}
            </Flex>
          )}
        </>
      </Flex>
      <Flex mt={5} gap={"5px"} p={1} flexDirection={"column"} w={"100%"}>
        <>
          {" "}
          <Flex
            w={"100%"}
            _hover={{ opacity: "80%" }}
            fontSize={"14px"}
            color={"rgba(150, 158, 167, 1)"}
            alignItems={"center"}
            justifyContent={"space-between"}
            cursor={"pointer"}
            onClick={toggleDirectMessagesOpen}
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon
                as={isDirectMessagesOpen ? FiChevronDown : FiChevronRight}
              />
              <Text>Direct Messages</Text>
            </Flex>

            <Flex>
              {!guestToken && (
                <>
                  <Tooltip
                    placement="top"
                    borderRadius={"7px"}
                    label={"Add Group"}
                  >
                    <Icon
                      onClick={(event) => {
                        event.stopPropagation();
                        setDirectMessages(true);
                        onDirectMessageSelect(null); // Or some meaningful value to indicate DirectMessages should be shown
                      }}
                      as={BsPlusLg}
                      size={"xs"}
                    />
                  </Tooltip>
                </>
              )}
            </Flex>
          </Flex>
          {isDirectMessagesOpen && (
            <Flex mt={1} gap={"8px"} flexDirection={"column"} w={"100%"}>
              {isLoading ? (
                <Center mt={2}>
                  <Spinner />
                </Center>
              ) : directMessages.length > 0 ? (
                <Flex flexDirection={"column"} gap={"5px"}>
                  {directMessages.map((directMessage) => (
                    <DirectMessagesItem
                      userId={userId}
                      key={directMessage._id}
                      directMessageId={directMessage._id}
                      colorMode={colorMode}
                      fetchDirectMessages={fetchDirectMessages}
                      directMessage={directMessage}
                      onDirectMessageSelect={() => {
                        onDirectMessageSelect(directMessage); // Your existing channel selection logic
                        setDirectMessages(true); // Or some meaningful value to indicate DirectMessages should be shown
                      }}
                    />
                  ))}
                </Flex>
              ) : (
                <Box>
                  <NoDirectMessages
                    colorMode={colorMode}
                    isMobileView={isMobileView}
                  />
                </Box>
              )}
            </Flex>
          )}
        </>
      </Flex>
      <Modal
        bg="black"
        isOpen={isAddChannelModalOpen}
        onClose={() => setIsAddChannelModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Add A Channel</ModalHeader>
          <ModalBody mb={2} bg={colorMode === "light" ? "white" : "black"}>
            <AddChannelMessages
              userId={userId}
              userName={userName}
              setIsAddChannelModalOpen={setIsAddChannelModalOpen}
              fetchChannels={fetchChannels}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        bg="black"
        isOpen={isAddGroupModalOpen}
        onClose={() => setIsAddGroupModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Add A Group</ModalHeader>
          <ModalBody bg={colorMode === "light" ? "white" : "black"}>
            <AddGroupMessages userId={userId} userName={userName} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        bg="black"
        isOpen={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Add A Group</ModalHeader>
          <ModalBody bg={colorMode === "light" ? "white" : "black"}>
            <InviteUsersMessages userId={userId} userName={userName} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MessengerList;
