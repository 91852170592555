import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Icon,
  Button,
  Tooltip,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Badge,
  Image,
} from "@chakra-ui/react";
import {
  BsLink,
  BsActivity,
  BsHeadphones,
  BsCheck2Circle,
} from "react-icons/bs";
import { FaMicrophone, FaComment } from "react-icons/fa6";
import "./ArtworksPanel.css";
import { FaTimes } from "react-icons/fa";

const MobileArtworkPanel = ({ colorMode, currentArtwork }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState("comments"); // Default to showing comments
  const [isLinkedRecordingHovering, setIsLinkedRecordingHovering] =
    useState(false); // State to track hover
  const [
    isDeleteLinkedRecordingModalOpen,
    setIsDeleteLinkedRecordingModalOpen,
  ] = useState(false);

  const [isLinkedReleaseHovering, setIsLinkedReleaseHovering] = useState(false);
  const [isDeleteLinkedReleaseModalOpen, setIsDeleteLinkedReleaseModalOpen] =
    useState(false);
  const [isLinkedBeatHovering, setIsLinkedBeatHovering] = useState(false);
  const [isDeleteLinkedBeatModalOpen, setIsDeleteLinkedBeatModalOpen] =
    useState(false);

  const formatDate = (uploadDate) => {
    const uploadedAt = new Date(uploadDate);
    const now = new Date();
    const timeDiff = now.getTime() - uploadedAt.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    if (daysDiff < 1) {
      const hoursDiff = timeDiff / (1000 * 3600);
      if (hoursDiff < 1) {
        const minutesDiff = timeDiff / (1000 * 60);
        return `${Math.floor(minutesDiff)} minutes ago`;
      }
      return `${Math.floor(hoursDiff)} hours ago`;
    } else if (daysDiff < 10) {
      return `${Math.floor(daysDiff)} days ago`;
    } else {
      return `on ${uploadedAt.toLocaleString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      })} @ ${uploadedAt.toLocaleTimeString()}`;
    }
  };

  return (
    <>
      <Box className="beatsmobile-panel-container">
        <Box
          className={`beats-panel-topone ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Flex w={"100%"} flexDirection={"column"} gap={"2px"}>
            <Box
              onClick={onOpen}
              className={`artwork-section ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              <Image
                src={currentArtwork?.imageUrl}
                borderRadius={"7px"}
                className="panel-artwork-image"
              />
            </Box>
          </Flex>

          <Flex flexDirection={"column"} gap={"5px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon className={"panel-title-icon"} as={BsLink} />
              <Text className="panel-title-text">Linked</Text>
            </Flex>
            <Flex
              padding={"1px"}
              borderRadius={"5px"}
              className="takethis"
              border={
                colorMode === "light"
                  ? ".5px solid rgba(48, 51, 77, 0.24)"
                  : ".5px solid rgba(48, 51, 77, 1)"
              }
              flexDirection={"column"}
              gap={"5px"}
              w={"100%"}
            >
              <Badge
                onMouseEnter={() => setIsLinkedReleaseHovering(true)}
                onMouseLeave={() => setIsLinkedReleaseHovering(false)}
                w={"100%"}
                colorScheme="green"
                variant="subtle"
                className="cardtyeuio"
                _hover={{ opacity: "80%", cursor: "pointer" }}
              >
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Flex alignItems={"center"} gap={"5px"}>
                    <Icon as={BsCheck2Circle} />
                    <Text>Release</Text>
                  </Flex>
                  {isLinkedReleaseHovering && (
                    <Icon
                      as={FaTimes}
                      size={"xs"}
                      color="red"
                      _hover={{ cursor: "pointer", opacity: "70%" }}
                      onClick={() => setIsDeleteLinkedReleaseModalOpen(true)} // Trigger modal on click
                    />
                  )}
                </Flex>
              </Badge>
              <Badge
                onMouseEnter={() => setIsLinkedRecordingHovering(true)}
                onMouseLeave={() => setIsLinkedRecordingHovering(false)}
                w={"100%"}
                colorScheme="orange"
                variant="subtle"
                className="cardtyeuio"
                _hover={{ opacity: "80%", cursor: "pointer" }}
              >
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Flex alignItems={"center"} gap={"5px"}>
                    <Icon as={FaMicrophone} />
                    <Text>Recording</Text>
                  </Flex>
                  {isLinkedRecordingHovering && (
                    <Icon
                      as={FaTimes}
                      size={"xs"}
                      color="red"
                      _hover={{ cursor: "pointer", opacity: "70%" }}
                      onClick={() => setIsDeleteLinkedRecordingModalOpen(true)} // Trigger modal on click
                    />
                  )}
                </Flex>
              </Badge>
              <Badge
                onMouseEnter={() => setIsLinkedBeatHovering(true)}
                onMouseLeave={() => setIsLinkedBeatHovering(false)}
                w={"100%"}
                colorScheme="purple"
                variant="subtle"
                className="cardtyeuio"
                _hover={{ opacity: "80%", cursor: "pointer" }}
              >
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Flex alignItems={"center"} gap={"5px"}>
                    <Icon as={BsHeadphones} />
                    <Text>Beat</Text>
                  </Flex>
                  {isLinkedBeatHovering && (
                    <Icon
                      as={FaTimes}
                      size={"xs"}
                      color="red"
                      _hover={{ cursor: "pointer", opacity: "70%" }}
                      onClick={() => setIsDeleteLinkedBeatModalOpen(true)} // Trigger modal on click
                    />
                  )}
                </Flex>
              </Badge>
            </Flex>
          </Flex>
          <Flex alignItems={"center"} gap={"5px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Text className="panel-title-text">Uploaded By</Text>

              <Avatar name={currentArtwork?.user.name} size={"xs"} />
              <Text className="panel-title-text">
                {formatDate(currentArtwork?.uploadDate)}
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Box
          className={`beatspanel-bottomone ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
        >
          <Flex
            className={`toppedtabs ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
            alignItems={"center"}
            gap={"10px"}
          >
            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Beat Comments"}
            >
              <Button
                colorScheme={activeTab === "comments" ? "blue" : "gray"}
                size={"xs"}
                w={"100%"}
                onClick={() => setActiveTab("comments")}
              >
                <Flex gap={"5px"} alignItems={"center"}>
                  <Icon as={FaComment} />
                  <Text>Comments</Text>
                </Flex>
              </Button>
            </Tooltip>

            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Recent Beat Activity"}
            >
              <Button
                colorScheme={activeTab === "activity" ? "blue" : "gray"}
                size={"xs"}
                w={"100%"}
                onClick={() => setActiveTab("activity")}
              >
                <Flex gap={"5px"} alignItems={"center"}>
                  <Icon as={BsActivity} />
                  <Text>Activity</Text>
                </Flex>
              </Button>
            </Tooltip>
          </Flex>
          <Box className="tabedcontent">
            {activeTab === "comments" && (
              <>{/* <Comments colorMode={colorMode} /> */}</>
            )}
            {activeTab === "activity" && <Flex>Activity Here</Flex>}
          </Box>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lyrics</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* You can place the actual lyrics or any content you want here */}
            <Text>Here are the lyrics...</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {/* Add any additional buttons here if needed */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteLinkedRecordingModalOpen}
        onClose={() => setIsDeleteLinkedRecordingModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Un-link Recording</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to un-link this Recording?
          </ModalBody>
          <ModalFooter>
            <Button
              w={"100%"}
              colorScheme="red"
              mr={3}
              onClick={() => setIsDeleteLinkedRecordingModalOpen(false)}
            >
              Cancel
            </Button>
            <Button w={"100%"} colorScheme="green">
              Remove
            </Button>{" "}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteLinkedReleaseModalOpen}
        onClose={() => setIsDeleteLinkedReleaseModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Un-link Release</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to un-link this Release?</ModalBody>
          <ModalFooter>
            <Button
              w={"100%"}
              colorScheme="red"
              mr={3}
              onClick={() => setIsDeleteLinkedReleaseModalOpen(false)}
            >
              Cancel
            </Button>
            <Button w={"100%"} colorScheme="green">
              Remove
            </Button>{" "}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteLinkedBeatModalOpen}
        onClose={() => setIsDeleteLinkedBeatModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Un-link Beat</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to un-link this Beat?</ModalBody>
          <ModalFooter>
            <Button
              w={"100%"}
              colorScheme="red"
              mr={3}
              onClick={() => setIsDeleteLinkedBeatModalOpen(false)}
            >
              Cancel
            </Button>
            <Button w={"100%"} colorScheme="green">
              Remove
            </Button>{" "}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MobileArtworkPanel;
