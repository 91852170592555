import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tooltip,
  Avatar,
} from "@chakra-ui/react";
import SendMessageInput from "./SendMessageInput";
import MessageItem from "../../Messaging/MessageItem";
import { FaHashtag } from "react-icons/fa";
import { format, isSameDay, parseISO } from "date-fns";
import { FaUserPlus } from "react-icons/fa6";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import GrantChannelAccess from "./GrantChannelAccess";
import "./ChannelMessages.css";

const ChannelMessages = ({
  colorMode,
  userName,
  userId,
  isMobileView,
  selectedChannel,
  refreshSelectedChannel,
  guestToken,
}) => {
  const toast = useToast();
  const [isInviteModalOpen, setInviteModalOpen] = useState(false); // New state for delete confirmation modal

  const isSameDayAsPrevious = (currentIndex) => {
    if (currentIndex === 0) return false; // The first message is always a new day.

    const currentMessageDate = parseISO(
      selectedChannel.messages[currentIndex].createdAt
    );
    const previousMessageDate = parseISO(
      selectedChannel.messages[currentIndex - 1].createdAt
    );

    return isSameDay(currentMessageDate, previousMessageDate);
  };

  const [editableChannelName, setEditableChannelName] = useState("");

  useEffect(() => {
    setEditableChannelName(selectedChannel?.channelName || "");
  }, [selectedChannel]);

  const handleChannelNameUpdate = async () => {
    if (editableChannelName !== selectedChannel.channelName) {
      try {
        await axios.patch(
          `${API_ENDPOINT}api/channels/${selectedChannel._id}/name`,
          {
            newChannelName: editableChannelName,
          }
        );
        toast({
          title: "Success",
          description: "Channel Name Succeessfully Changed",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Failed to update channel name", error);
        toast({
          title: "Failed",
          description: "Failed to update channel name.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const messagesEndRef = useRef(null); // Create a ref for the messages container

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom whenever selectedChannel.messages changes
  }, [selectedChannel?.messages]);

  return (
    <Box className="mainchannelmessagecontainer">
      <Flex p={2} className="topsectionmessagetable">
        <Flex alignItems={"center"} gap={"5px"}>
          {selectedChannel && (
            <>
              <Icon as={FaHashtag} />
              <Input
                size={"sm"}
                value={editableChannelName}
                onChange={(e) => setEditableChannelName(e.target.value)}
                onBlur={handleChannelNameUpdate} // Call handleChannelNameUpdate when input loses focus
              />
            </>
          )}
        </Flex>
        <Flex alignItems={"center"} gap={"10px"}>
          {selectedChannel && (
            <>
              <Flex className="avatarlists">
                {selectedChannel?.usersWithAccess?.map((user) => (
                  <Tooltip key={user.userId} label={user.userName} hasArrow>
                    <Avatar
                      ml={-2.5}
                      _hover={{
                        transform: "scale(1.05)",
                        boxShadow: "lg",
                      }}
                      _active={{
                        transform: "scale(0.95)",
                      }}
                      name={user.userName}
                      size="sm"
                    />
                  </Tooltip>
                ))}
              </Flex>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  {
                    !guestToken && setInviteModalOpen(true);
                  }
                }}
                size={"sm"}
              >
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={FaUserPlus} />
                  <Text>Invite</Text>
                </Flex>
              </Button>
            </>
          )}
        </Flex>
      </Flex>
      <Flex className="masstableh">
        {selectedChannel?.messages?.length > 0 && (
          <>
            <Flex
              direction="column"
              bg={colorMode === "light" ? "gray.50" : "gray.800"}
              p={4}
              mt={2}
              mx={4}
              rounded="lg"
              align="start"
              className="channel-welcome"
            >
              <Flex align="center" mb={2}>
                <Icon
                  as={FaHashtag}
                  boxSize={8}
                  mr={2}
                  color={colorMode === "light" ? "gray.600" : "white"}
                />
                <Text
                  fontSize="4xl"
                  fontWeight="bold"
                  lineHeight="shorter"
                  color={colorMode === "light" ? "gray.800" : "white"}
                >
                  Welcome to #{selectedChannel?.channelName}!
                </Text>
              </Flex>
              <Text color={colorMode === "light" ? "gray.600" : "gray.400"}>
                This is the start of the #{selectedChannel?.channelName}{" "}
                channel.
              </Text>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  {
                    !guestToken && setInviteModalOpen(true);
                  }
                }}
                size="sm"
                mt={3}
                variant="outline"
                colorScheme={colorMode === "light" ? "blue" : "purple"}
                leftIcon={<FaUserPlus />}
              >
                Invite Users
              </Button>
            </Flex>
          </>
        )}

        {selectedChannel?.messages && selectedChannel?.messages.length > 0 ? (
          selectedChannel?.messages.map((message, index) => (
            <>
              <Box key={message?._id} className="actualitem">
                {!isSameDayAsPrevious(index) && (
                  <Box my={4} px={2}>
                    <Text
                      fontSize="sm"
                      textAlign="center"
                      color={colorMode === "light" ? "gray.600" : "gray.400"}
                    >
                      {format(parseISO(message?.createdAt), "EEEE, MMMM do")}
                    </Text>
                    <Box
                      my={2}
                      height="1px"
                      bg={colorMode === "light" ? "gray.200" : "gray.700"}
                    />
                  </Box>
                )}
                <MessageItem
                  key={message._id}
                  message={message}
                  colorMode={colorMode}
                  channelId={selectedChannel?._id}
                  userName={userName}
                  userId={userId}
                  refreshSelectedChannel={refreshSelectedChannel} // Forwarding the method
                  guestToken={guestToken}
                />
              </Box>
            </>
          ))
        ) : (
          <></>
        )}
        <div ref={messagesEndRef} />
      </Flex>
      <Box p={1} m={2}>
        {selectedChannel && (
          <SendMessageInput
            userName={userName}
            colorMode={colorMode}
            userId={userId}
            isMobileView={isMobileView}
            selectedChannelId={selectedChannel?._id}
            refreshSelectedChannel={refreshSelectedChannel} // Forwarding the method
            guestToken={guestToken}
          />
        )}
      </Box>
      <Modal
        isOpen={isInviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <GrantChannelAccess
              userName={userName}
              userId={userId}
              colorMode={colorMode}
              selectedChannelId={selectedChannel?._id}
              selectedChannelName={selectedChannel?.channelName}
              setInviteModalOpen={setInviteModalOpen}
              refreshSelectedChannel={refreshSelectedChannel}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ChannelMessages;
