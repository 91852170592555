import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  Text,
  Icon,
  Button,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  CircularProgress,
  CircularProgressLabel,
  Menu,
  MenuButton,
  useColorModeValue,
  useToast,
  MenuList,
  MenuItem,
  IconButton,
  Avatar,
} from "@chakra-ui/react";
import { BsActivity, BsHeadphones, BsImage, BsPercent } from "react-icons/bs";
import { FaMicrophone, FaComment, FaCircleCheck } from "react-icons/fa6";
import { MdOutlineLyrics, MdOutlineTitle, MdTitle } from "react-icons/md";
import Comments from "../../Comment/Comments";
import "./ReleaesPanel.css";
import { CalendarIcon } from "@chakra-ui/icons";
import { GrInProgress } from "react-icons/gr";
import LyricsUploadModal from "./AsseetUploadModals/LyricsUploadModal";
import RecordingUploadModal from "./AsseetUploadModals/RecordingUploadModal";
import ArtworkUploadModal from "./AsseetUploadModals/ArtworkUploadModal";
import { BiNotepad } from "react-icons/bi";
import ReleaseLyricsItem from "./CurrentLinkedItems/ReleaseLyricsItem";
import ReleaseArtworkItem from "./CurrentLinkedItems/ReleaseArtworkItem";
import ReleaseRecordingItem from "./CurrentLinkedItems/ReleaseRecordingItem";
import { TbUserPlus } from "react-icons/tb";
import { AddIcon } from "@chakra-ui/icons";
import { AiOutlineUser } from "react-icons/ai";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const ReleaesPanel = ({ colorMode, currentRelease, isMobileView }) => {
  const [isAddLyricsModalOpen, setIsAddLyricsModalOpen] = useState(false);
  const [isRecordingModalOpen, setIsRecordingModalOpen] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isArtworkModalOpen, setIsArtworkModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const inputBg = useColorModeValue("gray.50", "rgba(66, 69, 99, 0.23)");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const toast = useToast();

  const [mainReleaseName, setMainReleaseName] = useState(
    currentRelease?.releaseName || ""
  );

  useEffect(() => {
    setMainReleaseName(currentRelease?.releaseName || "");
  }, [currentRelease]);

  const calculateCompletionPercentage = (currentRelease) => {
    const pieces = [
      currentRelease.releaseName,
      currentRelease.releaseType,
      currentRelease.lyrics.length > 0,
      currentRelease.artwork.length > 0,
      currentRelease.recordings.length > 0,
      currentRelease.schedule,
    ];

    const filledPieces = pieces.filter(Boolean).length;
    return (filledPieces / 6) * 100;
  };

  const getCompletionColor = (percentage) => {
    if (percentage === 100) return "green.400";
    if (percentage >= 66) return "yellow.400";
    return "red.400";
  };

  const completionPercentage = calculateCompletionPercentage(currentRelease);
  const completionColor = getCompletionColor(completionPercentage);
  const [recordingArtists, setRecordingArtists] = useState([]);
  const [recordingArtist, setRecordingArtist] = useState();

  const fetchRecordingArtists = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/recordingArtists/all`
      );
      setRecordingArtists(response.data.recordingArtists || []);
    } catch (error) {
      console.error("Failed to fetch recording artists:", error);
    }
  };

  useEffect(() => {
    fetchRecordingArtists();
  }, []);

  const charToColorArtist = (char) => {
    const alphabetPosition = char.toUpperCase().charCodeAt(0) - 64;
    const hue = (alphabetPosition * 137) % 360; // 137 is a "magic" number
    const saturation = 50 + (alphabetPosition % 51); // Vary between 50% and 100%
    const lightness = 50 + ((alphabetPosition * 3) % 26); // Vary between 50% and 75%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const getFormattedReleaseDate = (schedule) => {
    const date = new Date(schedule);
    return {
      month: date.toLocaleString("en-US", { month: "long" }),
      day: date.getDate(),
    };
  };

  const releaseDateParts = currentRelease?.schedule
    ? getFormattedReleaseDate(currentRelease?.schedule)
    : null;

  return (
    <Flex
      className={`main-releasepanel-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        className={`mainrelease-panel-topone ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex flexDirection={"column"}>
          <Flex
            w={"100%"}
            flexDirection={"column"}
            alignItems={"start"}
            gap={"5px"}
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon fontSize={"14px"} as={TbUserPlus} />
              <Text className="slighttext">Recording Artist</Text>
            </Flex>
            <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
              {recordingArtist ? (
                <>
                  <Avatar
                    icon={<AiOutlineUser fontSize="15px" />}
                    size={"xs"}
                    borderRadius={"7px"}
                    bg={charToColorArtist(recordingArtist)}
                  />
                </>
              ) : (
                <>
                  <Avatar
                    icon={<AiOutlineUser fontSize="15px" />}
                    size={"xs"}
                    borderRadius={"7px"}
                  />
                </>
              )}
              <Input
                value={recordingArtist}
                onChange={(e) => setRecordingArtist(e.target.value)}
                borderRadius={"6px"}
                bg={inputBg}
                borderColor={borderColor}
                _hover={{
                  bg: hoverBg,
                  borderColor: "gray.400",
                }}
                size={"sm"}
              />
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<AddIcon />}
                  variant="outline"
                  size={"sm"}
                />
                <MenuList
                  fontSize={"14px"}
                  p={1}
                  border={
                    colorMode === "light"
                      ? "2px solid rgba(153, 178, 198, 0.23)"
                      : "2px solid rgba(43, 46, 54, 1)"
                  }
                  bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                >
                  {recordingArtists.map((artist, index) => (
                    <MenuItem
                      p={2}
                      bg={
                        colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"
                      }
                      _hover={{
                        bg:
                          colorMode === "light"
                            ? "rgba(241, 244, 249, 1)"
                            : "rgba(42, 49, 70, 1)",
                      }}
                      key={index}
                      onClick={() => {
                        setRecordingArtist(artist);
                      }}
                    >
                      {artist}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
          <Flex>
            <Text fontWeight="bold" fontSize="lg">
              {currentRelease.releaseName}
            </Text>
          </Flex>
        </Flex>

        <Flex height={"100%"} alignItems={"center"} gap={"10px"}>
          <Flex alignItems="center" gap="10px">
            <CircularProgress
              value={completionPercentage}
              color={completionColor}
              size="70px"
            >
              <CircularProgressLabel>
                {completionPercentage.toFixed(0)}%
              </CircularProgressLabel>
            </CircularProgress>
          </Flex>
          <Flex>
            <Flex h={"100%"}>
              {releaseDateParts ? (
                <Flex p={1} h={"100%"} w={"100%"} flexDirection={"column"}>
                  <Flex
                    className={`releasedate-month ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"100%"}
                  >
                    <Text
                      className={`date-month ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      {releaseDateParts.month}
                    </Text>
                  </Flex>
                  <Flex
                    className={`releasedate-day ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"100%"}
                  >
                    <Text
                      className={`date-day ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      {releaseDateParts.day}
                    </Text>
                  </Flex>
                </Flex>
              ) : (
                <Tooltip
                  placement="top"
                  borderRadius={"7px"}
                  label={"Schedule Release"}
                >
                  <Button size={"sm"} w={"100%"} h={"80%"}>
                    <Flex alignItems={"center"} gap={"5px"}>
                      <Icon as={CalendarIcon} />
                      <Text className="scheduletext">Schedule</Text>
                    </Flex>
                  </Button>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex h={"100%"}>
          {currentRelease.recordings && currentRelease.recordings.length > 0 ? (
            <>
              {currentRelease.recordings.map((recording) => (
                <ReleaseRecordingItem
                  key={recording._id}
                  recording={recording}
                  colorMode={colorMode}
                />
              ))}
            </>
          ) : (
            <>
              <Button
                height={"100%"}
                w={"100%"}
                size={"sm"}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsRecordingModalOpen(true);
                }}
              >
                Upload Recording
              </Button>
            </>
          )}
        </Flex>
      </Flex>
      <Flex
        className={`surroundreleasemainpanel-bottomone ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex
          pb={2}
          borderBottom={".5px solid grey"}
          gap={"8px"}
          alignItems={"center"}
          w={"100%"}
        >
          <Icon as={FaComment} />
          <Text className="commenttitle">Comments</Text>
        </Flex>

        {/* <CurrentRecordingComments
          userId={userId}
          currentRecording={currentRecording}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={currentRecording?.mutualSpaceId}
          boardId={currentRecording?.boardId}
          tableId={currentRecording?.tableId}
          recordingId={currentRecording?._id}
        /> */}
      </Flex>
      <Modal
        isOpen={isAddLyricsModalOpen}
        onClose={() => setIsAddLyricsModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          sx={{
            width: "80%", // Full width for mobile view, otherwise 80%
            height: "500px", // Full height for mobile view
            maxHeight: "500px", // Full height for mobile view
            maxWidth: "700px", // Full width for mobile view, otherwise max 700px
          }}
        >
          <ModalHeader>Add Lyrics</ModalHeader>
          <ModalCloseButton />
          <ModalBody h={"80%"}>
            <LyricsUploadModal
              setIsAddLyricsModalOpen={setIsAddLyricsModalOpen}
              currentRelease={currentRelease}
              colorMode={colorMode}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isRecordingModalOpen}
        onClose={() => setIsRecordingModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          sx={{
            width: "80%", // Full width for mobile view, otherwise 80%
            height: "500px", // Full height for mobile view
            maxHeight: "500px", // Full height for mobile view
            maxWidth: "700px", // Full width for mobile view, otherwise max 700px
          }}
        >
          <ModalHeader>Add Recording</ModalHeader>
          <ModalCloseButton />
          <ModalBody h={"80%"}>
            <RecordingUploadModal
              setIsRecordingModalOpen={setIsRecordingModalOpen}
              currentRelease={currentRelease}
              colorMode={colorMode}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isFolderModalOpen}
        onClose={() => setIsFolderModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          sx={{
            width: "80%", // Full width for mobile view, otherwise 80%
            height: "500px", // Full height for mobile view
            maxHeight: "500px", // Full height for mobile view
            maxWidth: "700px", // Full width for mobile view, otherwise max 700px
          }}
        >
          <ModalHeader>Add to Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Add to Folder Modal</ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isArtworkModalOpen}
        onClose={() => setIsArtworkModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          sx={{
            width: "80%", // Full width for mobile view, otherwise 80%
            height: "500px", // Full height for mobile view
            maxHeight: "500px", // Full height for mobile view
            maxWidth: "700px", // Full width for mobile view, otherwise max 700px
          }}
        >
          <ModalHeader>Add Artwork</ModalHeader>
          <ModalCloseButton />
          <ModalBody h={"80%"}>
            <ArtworkUploadModal
              setIsArtworkModalOpen={setIsArtworkModalOpen}
              currentRelease={currentRelease}
              colorMode={colorMode}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isScheduleModalOpen}
        onClose={() => setIsScheduleModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          sx={{
            width: "80%", // Full width for mobile view, otherwise 80%
            height: "500px", // Full height for mobile view
            maxHeight: "500px", // Full height for mobile view
            maxWidth: "700px", // Full width for mobile view, otherwise max 700px
          }}
        >
          <ModalHeader>Schedule Release</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Schedule Release Modal</ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ReleaesPanel;
