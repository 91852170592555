import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  useToast,
  SimpleGrid,
  VStack,
  HStack,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const AddLyricsToFolder = ({
  userId,
  currentLyric,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  lyricId,
  fetchLyricData,
  setIsAddToFolderOpen,
}) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [folderAssets, setFolderAssets] = useState([]);
  const [selectedFolderAsset, setSelectedFolderAsset] = useState(null);

  const fetchFolderAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/folders`
      );
      setFolderAssets(response.data.folders);
    } catch (error) {
      console.error("Failed to fetch beat assets:", error);
    }
  };

  useEffect(() => {
    fetchFolderAssets();
  }, []);

  const clearSelection = () => {
    setSelectedFolderAsset(null);
  };

  const handleAddToFolder = async () => {
    setIsLoading(true);
    try {
      const payload = {
        ...currentLyric, // Spread the entire currentBeat object
        lyricAssetId: currentLyric._id, // Explicitly include this for clarity
      };

      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/folder/${selectedFolderAsset._id}/addlyricasset`,
        payload
      );
      toast({
        title: "Lyric added to folder",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setIsAddToFolderOpen(false);
      fetchLyricData();
    } catch (error) {
      toast({
        title: "Failed to add lyric to folder",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <SimpleGrid columns={3} spacing={4}>
        {!selectedFolderAsset && (
          <>
            {folderAssets.map((asset) => (
              <Box
                key={asset._id}
                p={5}
                shadow="md"
                borderWidth="1px"
                cursor="pointer"
                onClick={() => setSelectedFolderAsset(asset)}
              >
                <Text mt={4}>{asset.folderName}</Text>
                <Text fontSize="sm">Uploaded by: {asset.user.name}</Text>
              </Box>
            ))}
          </>
        )}
      </SimpleGrid>
      {selectedFolderAsset && (
        <VStack spacing={4} align="stretch">
          <Flex
            className={`foldermodalresults ${
              colorMode === "dark" ? "darkmode" : ""
            }`}
          >
            <Text
              className={`text-gradient text-shadow text-hover-effect`}
              fontSize="xl"
            >
              {selectedFolderAsset.folderName}
            </Text>
            <Text className={`text-shadow`} fontSize="md">
              Uploaded by: {selectedFolderAsset.user.name}
            </Text>
            <Text className={`text-shadow`} fontSize="md">
              Upload Date:{" "}
              {new Date(selectedFolderAsset.uploadDate).toLocaleDateString()}
            </Text>
            <Text className={`text-shadow`} fontSize="md">
              Number of Files: {selectedFolderAsset.files.length}
            </Text>
          </Flex>
          <Flex alignItems={"center"} w={"100%"} gap={"20px"}>
            <Button w={"100%"} onClick={clearSelection}>
              Back to List
            </Button>
            <Button
              colorScheme="blue"
              w={"100%"}
              isLoading={isLoading}
              loadingText="Linking..."
              onClick={handleAddToFolder}
            >
              Add to Folder
            </Button>
          </Flex>
        </VStack>
      )}
    </Box>
  );
};

export default AddLyricsToFolder;
