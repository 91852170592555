import React from "react";
import "./FoldersTag.css";
import { Avatar, Flex, Tag, TagLabel } from "@chakra-ui/react";
import { PiFolderSimpleDashedFill } from "react-icons/pi";
import { BsFolder } from "react-icons/bs";

const FoldersTag = ({
  userId,
  currentBeat,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  beatId,
  fetchBeatData,
}) => {
  return (
    <div>
      <Flex alignItems={"center"} gap={"10px"}>
        <Tag w={"100%"} size="sm" colorScheme="gray" borderRadius="full">
          <Avatar
            bg={"none"}
            color={colorMode === "light" ? "grey.300" : "grey.300"}
            size="xs"
            ml={-1}
            icon={<BsFolder fontSize={"13px"} />}
          />
          <TagLabel className="taggedlabel">Folders</TagLabel>
        </Tag>
      </Flex>
    </div>
  );
};

export default FoldersTag;
