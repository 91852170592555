import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  useToast,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Input,
  Icon,
  Tooltip,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Spinner,
  Center,
} from "@chakra-ui/react";
import axios from "axios";
import { BsFilter, BsPlus, BsSearch } from "react-icons/bs";
import { FaSort } from "react-icons/fa";
import MainLyrics from "../Lyrics/MainLyrics";
import MainLyricsAssetUpload from "../MainUploads/MainLyricsAssetUpload";
import { useMutualSpace } from "../../../../Context/MutualSpaceContext";
import { API_ENDPOINT } from "../../../../Config/config";
import { useCurrentLyric } from "../../../../Context/CurrentLyricContext";
import "./MainLyricAssetsTable.css";

const MainLyricAssetsTable = ({
  colorMode,
  board,
  boardId,
  mutualSpaceId,
  userId,
  isSidebarCollapsed,
  tableId,
  userName,
  guestToken,
  openDetailsPanelSettings,
  closedetailsPanelSetting,
}) => {
  const [lyricAssets, setLyricAssets] = useState([]);
  const [selectedLyricAssets, setSelectedLyricAssets] = useState([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const inputBg = useColorModeValue(
    "rgba(247, 250, 252, 0.43)",
    "rgba(66, 69, 99, 0.23)"
  );
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const boxShadow = useColorModeValue("sm", "dark-lg");
  const { currentLyric, setCurrentLyric } = useCurrentLyric();

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const fetchLyricData = async () => {
    setIsLoading(true);

    if (tableId) {
      try {
        const response = await axios.get(
          `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/lyrics`
        );
        const fetchedLyrics = response.data.lyrics;
        setLyricAssets(fetchedLyrics);

        if (fetchedLyrics.length > 0) {
          const enhancedLyricAsset = {
            ...fetchedLyrics[0],
            mutualSpaceId,
            boardId,
            tableId,
          };
          setCurrentLyric(enhancedLyricAsset);
        } else {
          // If no lyrics data is fetched, trigger the closedetailsPanelSetting
          closedetailsPanelSetting();
        }
        setIsLoading(false);
      } catch (error) {
        toast({
          title: "Error fetching lyrics",
          description:
            error.response?.data?.message || "Could not fetch lyrics.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error fetching lyrics:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchLyricData();
  }, [tableId, mutualSpaceId, boardId, toast, setCurrentLyric]);

  const handleCheckboxChange = (lyricAsset, isChecked) => {
    setSelectedLyricAssets((prevSelected) =>
      isChecked
        ? [...prevSelected, lyricAsset]
        : prevSelected.filter((asset) => asset._id !== lyricAsset._id)
    );
  };

  const handleBulkDelete = async () => {
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/lyrics/bulk-delete`,
        {
          data: {
            lyricAssetIds: selectedLyricAssets.map((asset) => asset._id),
          },
        }
      );

      if (response.status === 200) {
        toast({
          title: "Lyric assets deleted successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
        setSelectedLyricAssets([]);
        fetchLyricData();
      }
    } catch (error) {
      toast({
        title: "Failed to delete lyric assets.",
        description: "Could not delete the selected lyrics. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      className={`MainBeatAssetTable-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex alignItems={"center"} w={"100%"} gap={"8px"}>
        <Flex w={"100%"} alignItems={"center"} gap={"8px"}>
          <Flex>
            <Tooltip placement="top" borderRadius={"7px"} label={"New Table"}>
              <Button
                isDisabled={!!guestToken}
                onClick={() => setIsUploadModalOpen(true)}
                size={"sm"}
              >
                <Flex alignItems={"center"} gap={"3px"}>
                  <Icon as={BsPlus} />
                  <Text>Lyrics</Text>
                </Flex>
              </Button>
            </Tooltip>
          </Flex>
          <Flex w="100%">
            <InputGroup size="sm" w="100%">
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={BsSearch} color="gray.300" />}
              />
              <Input
                bg={inputBg}
                borderColor={borderColor}
                placeholder="Search..."
                sx={{
                  "::placeholder": {
                    fontSize: "12px",
                  },
                }}
                _hover={{
                  bg: hoverBg,
                  borderColor: "gray.400",
                }}
                borderRadius={"5px"}
                size={"sm"}
                w={"100%"}
              />
            </InputGroup>
          </Flex>
        </Flex>
      </Flex>
      {selectedLyricAssets.length >= 2 && (
        <Flex className="mainbulkdelete">
          <Text>You've selected {selectedLyricAssets.length} lyric files:</Text>
          <Flex className="listedassets" flexDirection="column" mt={2}>
            {selectedLyricAssets.map((asset) => (
              <Text key={asset._id}>{asset.title}</Text>
            ))}
          </Flex>
          <Flex mt={2}>
            <Button onClick={() => setSelectedLyricAssets([])}>Cancel</Button>
            <Button colorScheme="red" onClick={handleBulkDelete} ml={2}>
              Delete Files
            </Button>
          </Flex>
        </Flex>
      )}

      <Box
        className={`MainBeatAssetTable-surround ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        {isLoading ? (
          <Center mt={2}>
            <Spinner />
          </Center>
        ) : (
          <SimpleGrid mt={2} columns={{ base: 1, md: 4 }} spacing={4}>
            {lyricAssets.length > 0 ? (
              lyricAssets.map((lyricAsset) => (
                <Box key={lyricAsset._id}>
                  <MainLyrics
                    userName={userName}
                    userId={userId}
                    colorMode={colorMode}
                    lyricAsset={lyricAsset}
                    board={board}
                    boardId={boardId}
                    mutualSpaceId={mutualSpaceId}
                    tableId={tableId}
                    isSidebarCollapsed={isSidebarCollapsed}
                    guestToken={guestToken}
                    openDetailsPanelSettings={openDetailsPanelSettings}
                    fetchLyricData={fetchLyricData}
                    onCheckboxChange={handleCheckboxChange}
                  />
                </Box>
              ))
            ) : (
              <Text>No Lyrics found.</Text>
            )}
          </SimpleGrid>
        )}
      </Box>
      <Modal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalCloseButton />
          <ModalBody>
            <MainLyricsAssetUpload
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              mutualSpaceId={mutualSpaceId}
              board={board}
              boardId={boardId}
              tableId={tableId}
              setIsUploadModalOpen={setIsUploadModalOpen}
              fetchLyricData={fetchLyricData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MainLyricAssetsTable;
