import { Box, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import ReleasesTable from "../../Components/Table/ReleasesTable/ReleasesTable";
import "./Releases.css";

const Releases = ({
  isMobileView,
  colorMode,
  userId,
  userName,
  guestToken,
  openDetailsPanelSettings,
  closedetailsPanelSetting,
}) => {
  return (
    <Box
      boxShadow={"md"}
      className={`releasesmain-page-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Box className="releasespage-surround">
        <ReleasesTable
          userName={userName}
          userId={userId}
          colorMode={colorMode}
          isMobileView={isMobileView}
          guestToken={guestToken}
          openDetailsPanelSettings={openDetailsPanelSettings}
          closedetailsPanelSetting={closedetailsPanelSetting}
        />
      </Box>{" "}
    </Box>
  );
};

export default Releases;
