import { Box, Flex, Text, Image, SimpleGrid, Icon } from "@chakra-ui/react";
import React from "react";
import "./About.css";
import projmgmt from "../../Svggs/projectmanagement .svg";
import { CheckCircleIcon } from "@chakra-ui/icons";
import SectionTwoAbout from "../../Components/AboutSections/SectionTwoAbout";

const About = ({ isMobileView, colorMode }) => {
  return (
    <Flex className="about-page-container">
      <Flex
        p={5}
        alignItems={"center"}
        flexDirection={"column"}
        w={"100%"}
        gap={"20px"}
        justifyContent={"center"}
        className={`topabout-body-section ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex w={"100%"} justifyContent={"center"}>
          <Text className="intro-text-heading" textAlign={"center"}>
            Real-time visibility into the creative process
          </Text>
        </Flex>

        <Flex w={"75%"} justifyContent={"center"}>
          <Text textAlign={"center"} className="subhead-text">
            Seamlessly manage tasks accross a collection of collaborators to get
            the most out of your music assets
          </Text>
        </Flex>
      </Flex>
      <Flex
        className={`about-body-section ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex
          className={`about-body-surround ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
          boxShadow={"md"}
        >
          <Flex
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
            paddingLeft={"120px"}
          >
            <Text className="headbullet">Who is it for?</Text>
            <SimpleGrid columns={{ base: 2, md: 1 }} spacing={7} mt={5}>
              <Flex alignItems={"center"} gap={"8px"} className="headitemstyly">
                <Icon as={CheckCircleIcon} color="green.500" />
                <Text>A&Rs</Text>
              </Flex>
              <Flex alignItems={"center"} gap={"8px"} className="headitemstyly">
                <Icon as={CheckCircleIcon} color="green.500" />
                <Text> Managers</Text>
              </Flex>
              <Flex alignItems={"center"} gap={"8px"} className="headitemstyly">
                <Icon as={CheckCircleIcon} color="green.500" />
                <Text> Publishers</Text>
              </Flex>
              <Flex alignItems={"center"} gap={"8px"} className="headitemstyly">
                <Icon as={CheckCircleIcon} color="green.500" />
                <Text> Recording Artists</Text>
              </Flex>
              <Flex alignItems={"center"} gap={"8px"} className="headitemstyly">
                <Icon as={CheckCircleIcon} color="green.500" />
                <Text> Songwriters</Text>
              </Flex>
              <Flex alignItems={"center"} gap={"8px"} className="headitemstyly">
                <Icon as={CheckCircleIcon} color="green.500" />
                <Text> Producers</Text>
              </Flex>
            </SimpleGrid>
          </Flex>
        </Flex>
        <Flex w={"100%"}>
          <Flex className="container-projmgmt">
            <Image className="projmgmt-actual" src={projmgmt} />
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        <SectionTwoAbout colorMode={colorMode} />
      </Flex>
    </Flex>
  );
};

export default About;
