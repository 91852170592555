import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  useToast,
  SimpleGrid,
  VStack,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const AddRecordingModal = ({
  userId,
  currentLyric,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  lyricId,
  fetchLyricData,
  setIsAddRecordingModalOpen,
}) => {
  const toast = useToast();
  const [recordingAssets, setRecordingAssets] = useState([]);
  const [selectedRecordingAsset, setSelectedRecordingAsset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchRecordingAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/recordings`
      );
      setRecordingAssets(response.data.recordings);
    } catch (error) {
      console.error("Failed to fetch beat assets:", error);
    }
  };

  useEffect(() => {
    fetchRecordingAssets();
  }, []);

  const clearSelection = () => {
    setSelectedRecordingAsset(null);
  };

  const linkRecordingAsset = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/lyric/${lyricId}/addrecording`,
        {
          recordingAsset: selectedRecordingAsset,
        }
      );
      toast({
        title: "Recording Linked",
        description: "The recording has been successfully linked to the lyric.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchLyricData();
      setIsAddRecordingModalOpen(false);
    } catch (error) {
      console.error("Error linking recording asset:", error);
      toast({
        title: "Failed to Link Recording",
        description:
          "There was an error linking the recording. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Box>
        <SimpleGrid columns={3} spacing={4}>
          {!selectedRecordingAsset && (
            <>
              {" "}
              {recordingAssets.map((asset) => (
                <Box
                  key={asset._id}
                  p={5}
                  shadow="md"
                  borderWidth="1px"
                  cursor="pointer"
                  onClick={() => setSelectedRecordingAsset(asset)}
                >
                  <Text mt={4}>{asset.title}</Text>
                  <Text fontSize="sm">Uploaded by: {asset.user.name}</Text>
                  <Text fontSize="sm">Status: {asset.status}</Text>
                </Box>
              ))}
            </>
          )}
        </SimpleGrid>
        {selectedRecordingAsset && (
          <VStack spacing={4} align="stretch">
            <Text fontSize="xl" fontWeight="bold">
              {selectedRecordingAsset.title}
            </Text>
            <Text>Uploaded by: {selectedRecordingAsset.user.name}</Text>
            <Text>Status: {selectedRecordingAsset.status}</Text>
            <HStack>
              <Button
                colorScheme="blue"
                mr={3}
                isLoading={isLoading}
                loadingText="Linking..."
                onClick={linkRecordingAsset}
              >
                Link Recording
              </Button>
              <Button size="sm" onClick={clearSelection}>
                Back to List
              </Button>
            </HStack>
          </VStack>
        )}
      </Box>
    </div>
  );
};

export default AddRecordingModal;
