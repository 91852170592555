import React, { useContext, useEffect, useState } from "react";
import { Text, IconButton, Flex } from "@chakra-ui/react";
import { BsPlayCircle, BsPauseCircle } from "react-icons/bs";
import "./BeatsPanelPlayer.css";
import { MusicPlayerContext } from "../../../Context/MusicPlayerContext";

const BeatsPanelPlayer = ({ colorMode, currentBeat }) => {
  const { playTrack, stopTrack, currentTrack, seekTrack } =
    useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [localDuration, setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack &&
    currentTrack.src === currentBeat.fileUrl &&
    !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(currentBeat.fileUrl);
    }
  };

  return (
    <Flex
      className={`BeatsPanelPlayercontainer ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      bg={colorMode === "light" ? "gray.50" : "gray.800"}
      border={
        colorMode === "light"
          ? ".5px solid rgba(187, 186, 187, 1)"
          : ".5px solid rgba(128, 128, 128, 1)"
      }
      alignItems={"center"}
      gap={"8px"}
    >
      <Flex h={"100%"} alignItems={"center"} justifyContent={"center"}>
        {isCurrentBeatPlaying ? (
          <>
            <IconButton
              onClick={(event) => {
                togglePlayPause(); // Call the original saveProducerName function
              }}
              icon={<BsPauseCircle className="main-player-button" />}
            />
          </>
        ) : (
          <>
            <IconButton
              onClick={(event) => {
                togglePlayPause(); // Call the original saveProducerName function
              }}
              icon={<BsPlayCircle className="main-player-button" />}
            />
          </>
        )}
      </Flex>
      <Flex gap={"3px"} w={"100%"} flexDirection={"column"}>
        <Flex flexDirection={"column"}>
          <Text className="beatpanelplayer-name">{currentBeat?.title}</Text>
        </Flex>
        <Flex flexDirection={"column"} gap={"1px"}>
          <Text className="beatproducer">
            {currentBeat?.producer || "Producer"}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BeatsPanelPlayer;
