import React, { useState } from "react";
import axios from "axios"; // Make sure to install axios with npm or yarn
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { API_ENDPOINT } from "../../../Config/config";

const MutualBoardForm = ({
  userName,
  userId,
  colorMode,
  isMobileView,
  setIsNewBoardModalOpen,
  onRefreshMutualSpaces,
  mutualSpaceId,
  fetchMutualSpaces,
  fetchMutualSpace,
}) => {
  const [boardName, setboardName] = useState("");

  const toast = useToast();

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/addboard`,
        {
          userId,
          name: userName,
          boardName,
        }
      );

      toast({
        title: "MutualSpace Created",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setIsNewBoardModalOpen(false);
      fetchMutualSpaces();
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.message ||
          "There was an error processing your request.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <FormControl id="workspace-name" mb={4}>
        <FormLabel className="form-label-text">Board name</FormLabel>
        <Input
          placeholder="New Board"
          value={boardName}
          onChange={(e) => setboardName(e.target.value)}
        />
      </FormControl>
      <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
        <Button
          w={"100%"}
          variant="outline"
          onClick={() => setIsNewBoardModalOpen(false)}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} w={"100%"} colorScheme="blue">
          Create Board
        </Button>
      </Flex>
    </Box>
  );
};

export default MutualBoardForm;
