import React, { useState } from "react";
import { Box, Flex, Button, Icon, Text } from "@chakra-ui/react";
import { VscTasklist } from "react-icons/vsc";

import "./MobilePlanningTable.css";
import MobileTaskPlanning from "./TaskPlanning/MobileTaskPlanning";

const MobilePlanningTable = ({ colorMode, userId, userName }) => {
  const [activeTab, setActiveTab] = useState("tasks");

  const renderTabContent = () => {
    switch (activeTab) {
      case "tasks":
        return (
          <Box>
            <MobileTaskPlanning
              colorMode={colorMode}
              userId={userId}
              userName={userName}
            />
          </Box>
        );
      case "kanban":
        return <Box>Kanban Board</Box>;
      case "timeline":
        return <Box>Timeline View</Box>;
      default:
        return <Box>Tasks Content</Box>;
    }
  };

  return (
    <Box className="mobileplanningcontainer">
      <Flex alignItems={"center"} justifyContent={"space-between"} w={"100%"}>
        <Box>
          <Text className="planning-title-text">Planning</Text>
        </Box>
        <Flex alignItems={"center"} gap={"10px"}>
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={() => setActiveTab("tasks")}
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={VscTasklist} />
              <Text>Tasks</Text>
            </Flex>
          </Button>
          {/* <Button
            size={"sm"}
            variant={"outline"}
            onClick={() => setActiveTab("kanban")}
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={BsKanban} />
              <Text>Kanban</Text>
            </Flex>
          </Button> */}
        </Flex>
      </Flex>
      <Flex>
        <Box className="mobilerenderedtabs">{renderTabContent()}</Box>
      </Flex>
    </Box>
  );
};

export default MobilePlanningTable;
