import React, { useState, useEffect } from "react";
import {
  Box,
  Input,
  Text,
  useToast,
  useColorModeValue,
  Button,
  Flex,
  IconButton,
  Tooltip,
  Avatar,
  Icon,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { BsX } from "react-icons/bs";
import { BiUserPlus } from "react-icons/bi";

const UserInviteFolders = ({
  userId,
  colorMode,
  onClose,
  folderId,
  folderName,
}) => {
  const toast = useToast(); // Initialize useToast hook
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const inputBg = useColorModeValue("gray.50", "gray.700");

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_ENDPOINT}api/users`);
      const usersData = response.data.data;
      const filteredUsers = usersData.filter((user) => user._id !== userId);
      setUsers(filteredUsers);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleEmailSearch = () => {
    const matchedUsers = users.filter(
      (user) => user.email.toLowerCase() === searchTerm.toLowerCase()
    );
    setSearchResults(matchedUsers);
    setHasSearched(true);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    const matchedUser = users.find(
      (user) => user.name.toLowerCase() === value.toLowerCase()
    );

    if (!matchedUser) {
      setSelectedUser(null);
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    setSelectedUser(null);
    setSearchResults([]);
    setHasSearched(false);
  };

  const grantUserAccess = async (user) => {
    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/folders/${folderId}/access/grant`, 
        {
          userId: user._id,
          userName: user.name,
        }
      );

      toast({
        title: "Access Granted",
        description: `${user.name} has been granted access to the folder.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose(); // Close the modal if necessary
    } catch (error) {
      console.error("Error granting access:", error);
      toast({
        title: "Error granting access",
        description: "There was an error granting access to the user.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex className="GrantAccesstoAssets" flexDirection={"column"} gap={"20px"}>
      <Flex alignItems={"center"} gap={"10px"}>
        <Icon className="granticon" as={BiUserPlus} />
        <Text className="accessheader">Grant Access</Text>
      </Flex>
      <Flex className="middleofmodal">
        {!hasSearched && (
          <Flex w={"100%"} flexDirection={"column"} gap={"10px"}>
            <Text className="assetstable-enterdetails">
              Search users by email to grant access to {folderName}.
            </Text>
            <Input
              placeholder="Search by email..."
              bg={inputBg}
              borderColor={borderColor}
              value={searchTerm}
              onChange={handleSearchChange}
              boxShadow={"md"}
              size={"sm"}
              borderRadius={"5px"}
              w={"100%"}
            />
          </Flex>
        )}
        {hasSearched &&
          (searchResults.length > 0 ? (
            searchResults.map((user) => (
              <Flex w={"100%"} flexDirection={"column"} gap={"10px"}>
                <Text className="assetstable-enterdetails">
                  Search results for {searchTerm}
                </Text>
                <Flex
                  className={`assets-table-searched-user-result ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                  key={user._id}
                >
                  <Flex alignItems={"center"} gap={"10px"}>
                    <Avatar size={"sm"} name={user.name} />
                    <Text className="usersname">{user.name}</Text>
                  </Flex>

                  <Flex alignItems={"center"} gap={"10px"}>
                    {" "}
                    <Tooltip
                      placement="top"
                      borderRadius={"7px"}
                      label={"Grant Access"}
                    >
                      <Button
                        onClick={() => grantUserAccess(user)}
                        isLoading={loading}
                        size={"xs"}
                        colorScheme="cyan"
                        variant={"outline"}
                      >
                        Grant Access
                      </Button>
                    </Tooltip>
                  </Flex>
                </Flex>
              </Flex>
            ))
          ) : (
            <Text>No user with the email "{searchTerm}" found</Text>
          ))}
      </Flex>
      <Flex>
        <Flex
          alignItems="center"
          height="100%"
          zIndex={"9999"}
          gap={"20px"}
          w={"100%"}
        >
          <Button
            fontSize={"sm"}
            size={"sm"}
            w={"100%"}
            colorScheme="red"
            onClick={handleClear}
          >
            Cancel
          </Button>
          <Button
            fontSize={"sm"}
            size={"sm"}
            w={"100%"}
            colorScheme="green"
            onClick={handleEmailSearch}
          >
            Search
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UserInviteFolders;
