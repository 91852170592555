import { createContext, useState, useContext } from "react";

const CurrentLyricContext = createContext();

export const useCurrentLyric = () => useContext(CurrentLyricContext);

export const CurrentLyricProvider = ({ children }) => {
  const [currentLyric, setCurrentLyric] = useState(null);

  return (
    <CurrentLyricContext.Provider value={{ currentLyric, setCurrentLyric }}>
      {children}
    </CurrentLyricContext.Provider>
  );
};
