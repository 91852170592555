import React from "react";
import "./NoBeats.css";
import { Box, Flex, Icon, Text, Button } from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";

const NoArtwork = ({ colorMode, onOpen }) => {
  return (
    <Box
      className={`nobeats-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        gap={"15px"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text
          className={`getstartedbelow ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
          textAlign={"center"}
        >
          Get started by uploading Artwork!
        </Text>
        <Flex>
          <Button size={"sm"} onClick={onOpen} colorScheme="blue">
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={BsPlusLg} />
              <Text>Upload</Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NoArtwork;
