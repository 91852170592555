import React from "react";
import { Box, Flex, Text, Image } from "@chakra-ui/react";
import avatarMock from "../../../Svggs/avatarmock.svg";
import "./NewDirectMessages.css";
import { format, isSameDay, parseISO } from "date-fns";
import ItemMessageDirect from "../../Messaging/DirectMessages/ItemMessageDirect";
import DirectMessageInput from "../../Messaging/DirectMessages/DirectMessageInput";

const DirectMessages = ({
  colorMode,
  userName,
  userId,
  isMobileView,
  selectedDirectMessage,
}) => {
  const otherUser = selectedDirectMessage.users.find(
    (user) => user.userId !== userId
  );
  const otherUserName = otherUser
    ? otherUser.reciever || otherUser.sender
    : "Unknown";

  const formatESTDate = (dateString) => {
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/New_York",
    }).format(new Date(dateString));
  };

  const isSameDayAsPrevious = (currentIndex) => {
    if (currentIndex === 0) return false; // The first message is always a new day.

    const currentMessageDate = parseISO(
      selectedDirectMessage.messages[currentIndex].createdAt
    );
    const previousMessageDate = parseISO(
      selectedDirectMessage.messages[currentIndex - 1].createdAt
    );

    return isSameDay(currentMessageDate, previousMessageDate);
  };

  return (
    <Box className="DirectMessages-container">
      <Flex flexDirection={"column"}>
        <Flex alignItems={"center"} gap={"5px"} p={3} ml={1}>
          <Image src={avatarMock} className="directava" />
          <Flex alignItems={"center"} gap={"5px"}>
            <Text className="overaltext">{otherUserName}</Text>
            <Flex alignItems={"center"} gap={"2px"}>
              <Text className="createdAt">Last Updated:{""}</Text>
              <Text className="createdAt">
                {formatESTDate(selectedDirectMessage.createdAt)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          boxShadow={"md"}
          p={2}
          alignItems={"center"}
          gap={"5px"}
          className="inputtop"
          position={"relative"}
        ></Flex>
      </Flex>
      <Flex className="masstableh" w={"100%"}>
        {selectedDirectMessage?.messages &&
        selectedDirectMessage?.messages.length > 0 ? (
          selectedDirectMessage?.messages.map((message, index) => (
            <Box w={"100%"}>
              <Box key={message?._id} className="actualitem">
                {!isSameDayAsPrevious(index) && (
                  <Box my={4} px={2}>
                    <Text
                      fontSize="sm"
                      textAlign="center"
                      color={colorMode === "light" ? "gray.600" : "gray.400"}
                    >
                      {format(parseISO(message?.createdAt), "EEEE, MMMM do")}
                    </Text>
                    <Box
                      my={2}
                      height="1px"
                      bg={colorMode === "light" ? "gray.200" : "gray.700"}
                    />
                  </Box>
                )}
                <ItemMessageDirect
                  key={message._id}
                  message={message}
                  colorMode={colorMode}
                  directMessageId={selectedDirectMessage?._id}
                  userName={userName}
                  userId={userId}
                />
              </Box>
            </Box>
          ))
        ) : (
          <>
            <Flex
              direction="column"
              bg={colorMode === "light" ? "gray.50" : "gray.800"}
              p={4}
              mt={2}
              mx={4}
              rounded="lg"
              align="start"
              className="channel-welcome"
            ></Flex>
          </>
        )}
      </Flex>
      <Box p={1} m={2}>
        <DirectMessageInput
          userName={userName}
          userId={userId}
          isMobileView={isMobileView}
          colorMode={colorMode}
          selectedDirectMessage={selectedDirectMessage}
          selectedDirectMessageId={selectedDirectMessage?._id}
        />
      </Box>
    </Box>
  );
};

export default DirectMessages;
