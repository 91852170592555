import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Input,
  Text,
  MenuButton,
  Menu,
  MenuList,
  Icon,
  Button,
  Tooltip,
  MenuItem,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Badge,
  useColorModeValue,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { FaComment } from "react-icons/fa6";
import { API_ENDPOINT } from "../../../Config/config";
import axios from "axios";
import { useCurrentRecording } from "../../../Context/CurrentRecordingContext";
import { useLinkRecording } from "../../../Context/LinkRecordingContext";
import { useRecordingsPanel } from "../../../Context/RecordingsPanelContext";
import { useAddRecordingToRelease } from "../../../Context/AddRecordingToReleaseContext";
import { TbUserPlus } from "react-icons/tb";
import CurrentRecordingComments from "./RecordingComments/CurrentRecordingComments";
import { AddIcon } from "@chakra-ui/icons";
import { MdOutlineLyrics } from "react-icons/md";
import { BsFolder, BsFolderPlus, BsHeadphones, BsPlusLg } from "react-icons/bs";
import CurrentRecordingLyricsItem from "./CurrentRecordingLyricsItem";
import CurrentRecordingStatusItem from "./CurrentRecordingStatusItem";
import LinkBeatsModal from "./LinkBeatsModal";
import BeatLinkedItem from "./BeatLinkedItem";
import { PiFolderPlus } from "react-icons/pi";
import AddRecordingToFolderModal from "./AddRecordingToFolderModal";
import { AiOutlineUser } from "react-icons/ai";
import "./RecordingsPanel.css";
import RecordingTimeLine from "./RecordingTimeLine";

const RecordingsPanel = ({ colorMode, currentRecording, userId, userName }) => {
  const { setCurrentRecording } = useCurrentRecording();
  const { setLinkRecording } = useLinkRecording();
  const { setRecordingsPanel } = useRecordingsPanel();
  const { setAddRecording } = useAddRecordingToRelease();
  const toast = useToast();

  const inputBg = useColorModeValue("gray.50", "rgba(66, 69, 99, 0.23)");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const boxShadow = useColorModeValue("sm", "dark-lg");
  const [recordingArtist, setRecordingArtist] = useState();
  const [recordingArtists, setRecordingArtists] = useState([]);
  const [isLinkBeatsOpen, setIsLinkBeatsOpen] = useState(false);
  const [isAddToFolderOpen, setIsAddToFolderOpen] = useState(false);

  const [editableTableName, setEditableTableName] = useState(
    currentRecording?.recordingArtist || ""
  );

  const updateRecordingArtist = async (artist = recordingArtist) => {
    if (!currentRecording?._id) {
      console.error("Recording ID is not available.");
      return;
    }

    try {
      const response = await axios.patch(
        `${API_ENDPOINT}api/mutualspace/${currentRecording.mutualSpaceId}/board/${currentRecording.boardId}/table/${currentRecording.tableId}/recording/${currentRecording._id}/artist`,
        { recordingArtist: artist }
      );

      console.log("Update successful:", response.data);
      currentRecording.fetchRecordingData();
      toast({
        title: "Artist Added",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    } catch (error) {
      console.error("Failed to update recording artist:", error);
      toast({
        title: "Error updating Artist",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setRecordingArtist(currentRecording?.recordingArtist || "");
  }, [currentRecording]);

  const fetchRecordingArtists = async () => {
    if (!currentRecording?.tableId) {
      console.error("Table ID is not available.");
      return;
    }

    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${currentRecording.mutualSpaceId}/board/${currentRecording.boardId}/table/${currentRecording.tableId}/recordingArtists`
      );
      setRecordingArtists(response.data.recordingArtists || []);
    } catch (error) {
      console.error("Failed to fetch recording artists:", error);
    }
  };

  useEffect(() => {
    fetchRecordingArtists();
  }, [currentRecording?.tableId]);

  const charToColor = (char) => {
    const alphabetPosition = char.toUpperCase().charCodeAt(0) - 64;
    const hue = (alphabetPosition * 137) % 360; // 137 is a "magic" number
    const saturation = 50 + (alphabetPosition % 51); // Vary between 50% and 100%
    const lightness = 50 + ((alphabetPosition * 3) % 26); // Vary between 50% and 75%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const charToColorArtist = (char) => {
    const alphabetPosition = char.toUpperCase().charCodeAt(0) - 64;
    const hue = (alphabetPosition * 137) % 360; // 137 is a "magic" number
    const saturation = 50 + (alphabetPosition % 51); // Vary between 50% and 100%
    const lightness = 50 + ((alphabetPosition * 3) % 26); // Vary between 50% and 75%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  return (
    <Flex
      className={`main-beatspanel-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        className={`recordings-panel-topone ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex gap={"10px"} w={"100%"} flexDirection={"column"}>
          <Flex
            w={"100%"}
            flexDirection={"column"}
            alignItems={"start"}
            gap={"5px"}
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon fontSize={"14px"} as={TbUserPlus} />
              <Text className="slighttext">Recording Artist</Text>
            </Flex>
            <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
              {currentRecording.recordingArtist ? (
                <>
                  <Avatar
                    icon={<AiOutlineUser fontSize="15px" />}
                    size={"xs"}
                    borderRadius={"7px"}
                    bg={charToColorArtist(currentRecording?.recordingArtist)}
                  />
                </>
              ) : (
                <>
                  <Avatar
                    icon={<AiOutlineUser fontSize="15px" />}
                    size={"xs"}
                    borderRadius={"7px"}
                  />
                </>
              )}
              <Input
                value={recordingArtist}
                onChange={(e) => setRecordingArtist(e.target.value)}
                onBlur={() => updateRecordingArtist()} // Calls with current state value
                borderRadius={"6px"}
                bg={inputBg}
                borderColor={borderColor}
                _hover={{
                  bg: hoverBg,
                  borderColor: "gray.400",
                }}
                size={"sm"}
              />
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<AddIcon />}
                  variant="outline"
                  size={"sm"}
                />
                <MenuList>
                  {recordingArtists.map((artist, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        setRecordingArtist(artist);
                        updateRecordingArtist(artist);
                      }}
                    >
                      {artist}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
        </Flex>
        <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
          <CurrentRecordingStatusItem
            userId={userId}
            currentRecording={currentRecording}
            colorMode={colorMode}
            userName={userName}
            mutualSpaceId={currentRecording?.mutualSpaceId}
            boardId={currentRecording?.boardId}
            tableId={currentRecording?.tableId}
            recordingId={currentRecording?._id}
            fetchRecordingData={currentRecording?.fetchRecordingData}
          />
          <Flex w={"100%"} flexDirection={"column"} gap={"4px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon fontSize={"14px"} as={MdOutlineLyrics} />
              <Text className="slighttext">Lyrics</Text>
            </Flex>
            {currentRecording?.linkedlyrics &&
            currentRecording.linkedlyrics.length > 0 ? (
              <Flex w={"100%"}>
                {currentRecording.linkedlyrics.map((lyric) => (
                  <Box w={"100%"} key={lyric._id}>
                    <CurrentRecordingLyricsItem
                      colorMode={colorMode}
                      lyric={lyric}
                      lyricsId={lyric._id}
                      userId={userId}
                      currentRecording={currentRecording}
                      userName={userName}
                      mutualSpaceId={currentRecording?.mutualSpaceId}
                      boardId={currentRecording?.boardId}
                      tableId={currentRecording?.tableId}
                      recordingId={currentRecording?._id}
                    />
                  </Box>
                ))}
              </Flex>
            ) : (
              <Button fontSize={"10px"} size={"sm"}>
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={BsPlusLg} />
                  <Text>Lyrics</Text>
                </Flex>
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex alignItems={"center"} gap={"15px"}>
          <Flex w={"100%"} flexDirection={"column"} gap={"4px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon fontSize={"12px"} as={BsFolder} />
              <Text className="slighttext">Folders</Text>
            </Flex>

            {currentRecording?.folders &&
            currentRecording.folders.length > 0 ? (
              <Flex height={"100%"} w={"100%"}>
                {currentRecording.folders.map((folder) => (
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    className={`writing-to-item ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                    key={folder._id}
                  >
                    <Avatar
                      icon={<BsFolder fontSize="15px" />}
                      mr={-4}
                      size={"xs"}
                      bg={charToColor(folder.folderName)}
                    />
                  </Flex>
                ))}
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className={`writing-to-item ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  <Avatar
                    onClick={() => setIsAddToFolderOpen(true)}
                    size={"xs"}
                    icon={<BsFolderPlus fontSize="15px" />}
                    _hover={{ cursor: "pointer", opacity: "80%" }}
                  />
                </Flex>
              </Flex>
            ) : (
              <Button
                onClick={() => setIsAddToFolderOpen(true)}
                fontSize={"10px"}
                size={"sm"}
              >
                Add to Folder
              </Button>
            )}
          </Flex>
          <Flex w={"100%"} flexDirection={"column"} gap={"4px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon fontSize={"14px"} as={BsHeadphones} />
              <Text className="slighttext">Beat</Text>
            </Flex>

            {currentRecording?.linkedBeats &&
            currentRecording.linkedBeats.length > 0 ? (
              <Flex w={"100%"}>
                {currentRecording.linkedBeats.map((beat) => (
                  <Box w={"100%"} key={beat._id}>
                    <BeatLinkedItem beat={beat} colorMode={colorMode} />
                  </Box>
                ))}
              </Flex>
            ) : (
              <Button
                onClick={() => setIsLinkBeatsOpen(true)}
                fontSize="10px"
                size="sm"
              >
                Link Beat
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex>
          <RecordingTimeLine
            userId={userId}
            currentRecording={currentRecording}
            colorMode={colorMode}
            userName={userName}
            mutualSpaceId={currentRecording?.mutualSpaceId}
            boardId={currentRecording?.boardId}
            tableId={currentRecording?.tableId}
            recordingId={currentRecording?._id}
            fetchRecordingData={currentRecording?.fetchRecordingData}
          />
        </Flex>
      </Flex>
      <Flex
        className={`beatspanel-bottomone ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex
          pb={2}
          borderBottom={".5px solid grey"}
          gap={"8px"}
          alignItems={"center"}
          w={"100%"}
        >
          <Icon as={FaComment} />
          <Text className="commenttitle">Comments</Text>
        </Flex>

        <CurrentRecordingComments
          userId={userId}
          currentRecording={currentRecording}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={currentRecording?.mutualSpaceId}
          boardId={currentRecording?.boardId}
          tableId={currentRecording?.tableId}
          recordingId={currentRecording?._id}
        />
      </Flex>
      <Modal isOpen={isLinkBeatsOpen} onClose={() => setIsLinkBeatsOpen(false)}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Link Beat</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LinkBeatsModal
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              currentRecording={currentRecording}
              mutualSpaceId={currentRecording?.mutualSpaceId}
              boardId={currentRecording?.boardId}
              tableId={currentRecording?.tableId}
              recordingId={currentRecording?._id}
              fetchRecordingData={currentRecording?.fetchRecordingData}
              setIsLinkBeatsOpen={setIsLinkBeatsOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isAddToFolderOpen}
        onClose={() => setIsAddToFolderOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Icon className="granticon" as={PiFolderPlus} />
              <Text className="accessheader">Add to Folder</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddRecordingToFolderModal
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              currentRecording={currentRecording}
              mutualSpaceId={currentRecording?.mutualSpaceId}
              boardId={currentRecording?.boardId}
              tableId={currentRecording?.tableId}
              recordingId={currentRecording?._id}
              fetchRecordingData={currentRecording?.fetchRecordingData}
              setIsAddToFolderOpen={setIsAddToFolderOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default RecordingsPanel;
