import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  IconButton,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Tag,
  Avatar,
  TagLabel,
} from "@chakra-ui/react";
import "./CurrentRecordingLyricsItem.css";
import { BsTrash } from "react-icons/bs";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { PiNotepadThin } from "react-icons/pi";

const CurrentRecordingLyricsItem = ({
  lyric,
  colorMode,
  lyricsId,
  recordingId,
  tableId,
  boardId,
  mutualSpaceId,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false); // New state for delete confirmation modal
  const toast = useToast(); // Place this within your component function

  const deleteLinkedLyrics = async () => {
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/recording/${recordingId}/lyrics/${lyricsId}`
      );
      if (response.status === 200) {
        toast({
          title: "Lyrics Deleted",
          description: "The linked lyrics were successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setDeleteModalOpen(false); // Close the modal upon successful deletion
      } else {
        throw new Error("Failed to delete lyrics");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete the linked lyrics.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      className={`CurrentRecordingLyricItem-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Tag p={1} w={"100%"} size="sm" colorScheme="yellow" borderRadius="full">
        <Avatar
          bg={"none"}
          color={colorMode === "light" ? "gray" : "yellow"}
          size="xs"
          ml={-1}
          icon={<PiNotepadThin fontSize={"20px"} />}
        />
        <TagLabel className="taggedlabel">Lyrics</TagLabel>
      </Tag>

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Linked Lyrics</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete the Linked Lyrics?
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={deleteLinkedLyrics}>
              Delete Lyrics
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CurrentRecordingLyricsItem;
