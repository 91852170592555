import React, { useState, useEffect, useRef } from "react";
import Picker from "@emoji-mart/react";
import {
  Box,
  useColorModeValue,
  IconButton,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import {
  BsEmojiSmileFill,
  BsTypeBold,
  BsTypeItalic,
  BsTypeStrikethrough,
  BsListUl,
  BsListOl,
  BsSend,
  BsPaperclip,
  BsCodeSlash,
  BsPlusLg,
} from "react-icons/bs";
import data from "@emoji-mart/data";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import { RiFontSize } from "react-icons/ri";
import "./DirectMessageInput.css";

const DirectMessageInput = ({
  colorMode,
  userName,
  userId,
  isMobileView,
  selectedDirectMessage,
  selectedDirectMessageId,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);
  const pickerRef = useRef(null);
  const toast = useToast();
  const [message, setMessage] = useState("");
  const bgColor = useColorModeValue("white", "");

  useEffect(() => {
    function handleClickOutside(event) {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    }
    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  const CustomToolbar = () => (
    <Box p={2} id="toolbar" display="flex" justifyContent="space-between">
      <Box display="flex" gap="2">
        <Tooltip label="Bold" aria-label="Bold">
          <IconButton icon={<BsTypeBold />} size="sm" />
        </Tooltip>
        <Tooltip label="Italic" aria-label="Italic">
          <IconButton icon={<BsTypeItalic />} size="sm" />
        </Tooltip>
        <Tooltip label="Strike" aria-label="Strike">
          <IconButton icon={<BsTypeStrikethrough />} size="sm" />
        </Tooltip>
        <Tooltip label="Code" aria-label="Code">
          <IconButton icon={<BsCodeSlash />} size="sm" />
        </Tooltip>
        <Tooltip label="Bullet list" aria-label="Bullet list">
          <IconButton icon={<BsListUl />} size="sm" />
        </Tooltip>
        <Tooltip label="Ordered list" aria-label="Ordered list">
          <IconButton icon={<BsListOl />} size="sm" />
        </Tooltip>
        <Tooltip label="Emoji" aria-label="Emoji">
          <IconButton icon={<BsEmojiSmileFill />} size="sm" />
        </Tooltip>
        <Tooltip label="Attach" aria-label="Attach">
          <IconButton icon={<BsPaperclip />} size="sm" />
        </Tooltip>
      </Box>
    </Box>
  );

  const CustomToolbartwo = () => (
    <Box p={2} id="toolbar" display="flex" justifyContent="space-between">
      <Box display="flex" gap="2">
        <Tooltip label="Attach" aria-label="Bold">
          <IconButton icon={<BsPlusLg />} borderRadius={"full"} size="sm" />
        </Tooltip>
        <Tooltip label="Font" aria-label="Bold">
          <IconButton icon={<RiFontSize />} size="sm" />
        </Tooltip>
        <Tooltip label="Emoji" aria-label="Bold">
          <IconButton
            onClick={toggleEmojiPicker}
            icon={<BsEmojiSmileFill />}
            size="sm"
          />
        </Tooltip>
      </Box>
      <Tooltip label="Send" aria-label="Send">
        <IconButton
          onClick={sendMessage} // Here we attach the sendMessage function
          icon={<BsSend />}
          size="sm"
        />
      </Tooltip>
    </Box>
  );

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  const formats = ["bold", "italic", "strike", "code-block", "list", "bullet"];

  const stripHtml = (html) => {
    const temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  const sendMessage = async () => {
    const plainTextMessage = stripHtml(message);

    if (!message.trim()) {
      toast({
        title: "Cannot send an empty message.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/direct-messages/${selectedDirectMessageId}/message`,
        {
          userId,
          name: userName,
          messageContent: plainTextMessage,
        }
      );

      if (response.data && response.data.newMessage) {
        toast({
          title: "Message sent successfully.",
          description: "Your message has been added to the conversation.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setMessage("");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Failed to send message.",
        description: "An error occurred while trying to send your message.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      boxShadow={"md"}
      border={
        colorMode === "light"
          ? ".5px solid rgba(187, 186, 187, 1)"
          : "1px solid rgba(128, 128, 128, 1)"
      }
      bg={bgColor}
      borderRadius={"7px"}
    >
      {showEmojiPicker && (
        <Box
          position="absolute"
          zIndex={"9999"}
          bottom="50px"
          right="0px"
          ref={pickerRef}
        >
          <Picker data={data} />
        </Box>
      )}
      <Box
        borderTopLeftRadius={"7px"}
        borderTopRightRadius={"7px"}
        bg={
          colorMode === "light"
            ? "rgba(248, 248, 248, 1)"
            : "rgba(26, 32, 44, 1)"
        }
      >
        <CustomToolbar />
      </Box>

      <ReactQuill
        theme="snow"
        value={message}
        onChange={setMessage}
        modules={modules}
        formats={formats}
        placeholder="Start a new message"
        style={{ height: "60px" }}
      />
      <Box borderBottomLeftRadius={"7px"} borderBottomRightRadius={"7px"}>
        <CustomToolbartwo />
      </Box>
    </Box>
  );
};

export default DirectMessageInput;
