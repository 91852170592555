import React, { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import {
  Box,
  Button,
  HStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";

const EditingMessage = ({
  messageContent,
  messageId,
  channelId,
  onSave,
  onCancel,
  colorMode,
}) => {
  const [content, setContent] = useState(messageContent);
  const toast = useToast();

  const bgColor = useColorModeValue("gray.100", "gray.700"); // Adjust colors for light or dark mode

  const isDarkMode = colorMode === "dark";

  const stripHtml = (htmlString) => {
    return htmlString.replace(/<[^>]*>?/gm, "");
  };

  const handleSave = async () => {
    const textContent = stripHtml(content); // Use the utility function to remove HTML tags

    try {
      const response = await axios.patch(
        `${API_ENDPOINT}api/channels/${channelId}/messages/${messageId}`,
        { messageContent: textContent } // Send the text content without HTML tags
      );

      if (response.status === 200) {
        onSave(content);
        toast({
          title: "Message Updated",
          description: "Your message has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Handle any other status codes
        toast({
          title: "Failed to Update Message",
          description: `An error occurred: ${response.statusText}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error saving edited message:", error);
      toast({
        title: "Update Error",
        description: "There was an error saving your edited message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <style jsx global>{`
        ${isDarkMode
          ? `
          .ql-toolbar.ql-snow {
            background-color: #333;
          }
          .ql-toolbar.ql-snow .ql-picker-label,
          .ql-toolbar.ql-snow .ql-picker-item,
          .ql-toolbar.ql-snow button {
            color: white;
          }
          .ql-snow .ql-stroke {
            stroke: white !important;
          }
          .ql-snow .ql-fill,
          .ql-snow .ql-stroke.ql-fill {
            fill: white !important;
          }
        `
          : ""}
      `}</style>

      <Box bg={bgColor} w={"100%"} borderRadius="lg" mb={3}>
        <ReactQuill theme="snow" value={content} onChange={setContent} />
        <HStack justify="end" mt={2}>
          <Button size="sm" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="green" onClick={handleSave}>
            Save
          </Button>
        </HStack>
      </Box>
    </>
  );
};

export default EditingMessage;
