import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useToast,
  Text,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { DatePicker } from "react-rainbow-components"; // Using a date picker library
import axios from "axios";
import { API_ENDPOINT } from "../../../../../Config/config";

const DateItem = ({
  task,
  taskId,
  board,
  boardId,
  userId,
  colorMode,
  mutualSpaceId,
  isSidebarCollapsed,
  fetchTasks,
  key,
  itemId,
  item,
  fetchItems,
  guestToken,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const toast = useToast(); // Using toast for notifications

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSaveDate = async () => {
    if (!selectedDate) {
      toast({
        title: "No date selected",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/adddate`,
        { date: selectedDate.toISOString() }
      );

      if (response.status === 201) {
        toast({
          title: "Date added successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        fetchItems(); // Refresh items
        onClose(); // Close the modal
      }
    } catch (error) {
      console.error("Error adding date:", error);
      toast({
        title: "Error adding date",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <Box textAlign={"center"} w={"100%"}>
      {item?.dates?.length > 0 ? (
        <Box
          borderRadius="md"
          p={2}
          textAlign="center"
          w="fit-content"
          mx="auto"
        >
          {formatDate(item.dates[0])}
        </Box>
      ) : (
        <Text>
          <IconButton
            size={"sm"}
            icon={<CalendarIcon />}
            onClick={onOpen}
            aria-label="Pick a date"
          />
        </Text>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select a Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DatePicker
              value={selectedDate}
              onChange={handleDateChange}
              placeholder="Select Date"
            />
            <Button mt={4} onClick={handleSaveDate}>
              Save
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DateItem;
