import React, { useState, useRef } from "react";
import "./BeatUploadForm.css";
import {
  Button,
  Input,
  Flex,
  useToast,
  VStack,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { BsHeadphones } from "react-icons/bs";

const BeatUploadForm = ({
  colorMode,
  userId,
  userName,
  onClose,
  fetchBeats,
}) => {
  const [files, setFiles] = useState([]);
  const [titles, setTitles] = useState([]); // Store titles for each file
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    const generatedTitles = selectedFiles.map((file) =>
      file.name.split(".").slice(0, -1).join(".")
    );
    setTitles(generatedTitles);
  };

  const uploadBeats = async () => {
    if (files.length === 0 || titles.some((title) => title.trim() === "")) {
      toast({
        title: "Error",
        description: "Please select files and ensure all files have titles.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsUploading(true);
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("files", file);
    });
    formData.append("titles", JSON.stringify(titles));
    formData.append("userId", userId);
    formData.append("userName", userName);
    try {
      await axios.post(`${API_ENDPOINT}api/beat/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      onClose();
      fetchBeats();
      toast({
        title: "Upload successful",
        description: "Beats have been uploaded successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error uploading beat:", error);
      toast({
        title: "Upload failed",
        description: "An error occurred during upload.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
      setFiles([]);
      setTitles([]);
    }
  };

  return (
    <Flex
      className="multiple-bulk-container"
      mt={2}
      flexDirection={"column"}
      gap={"20px"}
    >
      <Input
        ref={fileInputRef}
        type="file"
        accept="audio/*"
        onChange={handleFileChange}
        multiple
        hidden
      />
      <Button
        onClick={() => fileInputRef.current.click()}
        leftIcon={<BsHeadphones />}
      >
        Select Beats
      </Button>
      <VStack spacing={4} align="stretch">
        {titles.map((title, index) => (
          <HStack key={index}>
            <Tag
              size="lg"
              borderRadius="full"
              variant="solid"
              colorScheme="blue"
            >
              <TagLabel>{title}</TagLabel>
              <TagCloseButton
                onClick={() => {
                  const newTitles = titles.filter((_, i) => i !== index);
                  const newFiles = files.filter((_, i) => i !== index);
                  setTitles(newTitles);
                  setFiles(newFiles);
                }}
              />
            </Tag>
          </HStack>
        ))}
      </VStack>
      <Button colorScheme="blue" isLoading={isUploading} onClick={uploadBeats}>
        Upload
      </Button>
    </Flex>
  );
};

export default BeatUploadForm;
