import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Icon,
  Text,
  useToast,
  Center,
  Spinner,
  SimpleGrid,
} from "@chakra-ui/react";
import "./HomeGreeting.css";
import { BsPersonWorkspace } from "react-icons/bs";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";
import HomeMutualSpaceItem from "./HomeMutualSpaceItem";

const HomeGreeting = ({
  isMobileView,
  userName,
  colorMode,
  userId,
  guestToken,
}) => {
  const getPartOfDay = () => {
    const hours = new Date().getHours();
    if (hours < 12) return "Morning";
    if (hours < 18) return "Afternoon";
    return "Evening";
  };

  const getDateInfo = () => {
    const now = new Date();
    const dayOfWeek = now.toLocaleString("default", { weekday: "long" }); // E.g., "Monday"
    const monthName = now.toLocaleString("default", { month: "long" }); // E.g., "January"
    const monthNumber = now.getMonth() + 1; // getMonth() is zero-indexed
    const dateOfMonth = now.getDate(); // Day of the month
    return `${dayOfWeek}, ${monthName} ${dateOfMonth}`;
  };

  const partOfDay = getPartOfDay();
  const dateInfo = getDateInfo();

  const [mutualSpaces, setMutualSpaces] = useState([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const fetchMutualSpaces = async () => {
    try {
      const endpoint = guestToken
        ? `${API_ENDPOINT}api/mutualspaces/all`
        : `${API_ENDPOINT}api/mutualspaces/access/${userId}`;

      const response = await axios.get(endpoint);
      let spaces = response.data.data;

      if (guestToken) {
        spaces = spaces
          .filter((space) => space.mock === "true")
          .map((space) => ({
            ...space,
            MutualBoards: space.MutualBoards.filter(
              (board) => board.mock === "true"
            ),
          }));
      }

      if (spaces.length > 0) {
        spaces.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setMutualSpaces(spaces);
      }
    } catch (error) {
      console.error("Error fetching mutual space:", error);
      toast({
        title: "Error fetching mutual space",
        description: "Unable to fetch mutual space data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchMutualSpaces();
  }, [userId, guestToken]);

  return (
    <Box className="HomeGreeting-container">
      <Flex borderBottom={".5px solid grey"} ml={2} mt={1.5}>
        <Flex p={2} flexDirection={"column"}>
          <Text className="date-info-text" textAlign={"start"}>
            {dateInfo}
          </Text>
          <Text className="part-of-day-text" textAlign={"center"}>
            Good {partOfDay}, {userName}!
          </Text>
        </Flex>
      </Flex>
      {isMobileView ? (
        <>
          <Flex className="mobilegreetflexing">
            {isLoading ? (
              <Center mt={2}>
                <Spinner size="xl" />
              </Center>
            ) : mutualSpaces.length > 0 ? (
              <>
                {mutualSpaces.map((mutualSpace) => (
                  <Box key={mutualSpace._id}>
                    <HomeMutualSpaceItem
                      colorMode={colorMode}
                      mutualSpace={mutualSpace}
                      isMobileView={isMobileView}
                    />
                  </Box>
                ))}
              </>
            ) : (
              <Center>
                <Text>No mutualSpaces found</Text>
              </Center>
            )}
          </Flex>
        </>
      ) : (
        <>
          {" "}
          <Flex className="greetflexing">
            {isLoading ? (
              <Center mt={2}>
                <Spinner size="xl" />
              </Center>
            ) : mutualSpaces.length > 0 ? (
              <SimpleGrid mt={2} columns={{ base: 1, md: 1 }} spacing={4}>
                {mutualSpaces.map((mutualSpace) => (
                  <Box key={mutualSpace._id}>
                    <HomeMutualSpaceItem
                      colorMode={colorMode}
                      mutualSpace={mutualSpace}
                    />
                  </Box>
                ))}
              </SimpleGrid>
            ) : (
              <Center>
                <Text>No mutualSpaces found</Text>
              </Center>
            )}
          </Flex>
        </>
      )}
    </Box>
  );
};

export default HomeGreeting;
