import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import AssetArtworkItem from "../../ArtworkItems/AssetArtwork/AssetArtworkItem";

const ArtworkAssetsTable = ({
  userName,
  userId,
  colorMode,
  selectedTableDataId,
  openDetailsPanelSettings,
  guestToken,
}) => {
  const [artworkAssets, setArtworkAssets] = useState([]);

  const fetchArtworkAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/assets/table/${selectedTableDataId}/artwork`
      );
      setArtworkAssets(response.data.artworkAssets); // Set the fetched beat assets
    } catch (error) {
      console.error("Error fetching beat assets:", error);
    }
  };

  useEffect(() => {
    if (selectedTableDataId) {
      fetchArtworkAssets();
    }
  }, [selectedTableDataId]);

  return (
    <Box mt={4}>
      <SimpleGrid columns={{ base: 1, md: 5 }} spacing={3}>
        {artworkAssets.length > 0 ? (
          artworkAssets.map((artworkAsset) => (
            <Box key={artworkAsset?._id}>
              <AssetArtworkItem
                userName={userName}
                userId={userId}
                colorMode={colorMode}
                artworkAsset={artworkAsset}
                selectedTableDataId={selectedTableDataId}
                openDetailsPanelSettings={openDetailsPanelSettings}
                guestToken={guestToken}
                fetchArtworkAssets={fetchArtworkAssets}
                artworkAssetId={artworkAsset._id}
              />
            </Box>
          ))
        ) : (
          <Text>No beat assets found.</Text>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default ArtworkAssetsTable;
