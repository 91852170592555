import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Flex, Icon, Text, useColorMode } from "@chakra-ui/react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import {
  ChevronDownIcon,
  MoonIcon,
  SunIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import { useReleasePanel } from "../../Context/ReleasePanelContext";
import { useBeatPanel } from "../../Context/BeatsPanelContext";
import { useLyricsPanel } from "../../Context/LyricsPanelContext";
import { useRecordingsPanel } from "../../Context/RecordingsPanelContext";
import { useArtworksPanel } from "../../Context/ArtworkPanelContext";
import { useProfileSettings } from "../../Context/ProfileSettingsContext";
import { useReleasesKanban } from "../../Context/ReleasesKanbanContext";
import { useAddBeatToLyric } from "../../Context/AddBeatToLyricContext";
import { useAddRecordingToLyric } from "../../Context/AddRecordingToLyricContext";
import { useLinkRecording } from "../../Context/LinkRecordingContext";
import { useAddRecordingToRelease } from "../../Context/AddRecordingToReleaseContext";
import { useLinkArtwork } from "../../Context/LinkArtworkContext";
import FeaturesDropdown from "./FeaturesDropdown/FeaturesDropdown";
import ProductsDropdown from "./FeaturesDropdown/ProductsDropdown";

const Header = ({
  toggleMainDetails,
  isAuthRoute,
  toggleSidebar,
  AuthToken,
  isLandingRoute,
  isSidebarCollapsed,
  colorMode,
  userEmail,
  userName,
  isCollapsed,
  setIsCollapsed,
  removeCookie,
  guestToken,
}) => {
  const { setReleasePanel } = useReleasePanel();
  const { setBeatPanel } = useBeatPanel();
  const { setLyricsPanel } = useLyricsPanel();
  const { setRecordingsPanel } = useRecordingsPanel();
  const { setArtworksPanel } = useArtworksPanel();
  const { setProfileSettings } = useProfileSettings();
  const { setReleasesKanban } = useReleasesKanban();
  const { setAddBeatToLyric } = useAddBeatToLyric();
  const { setAddRecordingToLyric } = useAddRecordingToLyric();
  const { toggleColorMode } = useColorMode();
  const { setLinkRecording } = useLinkRecording();
  const { setAddRecording } = useAddRecordingToRelease();
  const { setLinkArtwork } = useLinkArtwork();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);

  const dropdownAreaRef = useRef(null);
  const hideTimeout = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(hideTimeout.current);
    setShowDropdown(true);
    setShowProductsDropdown(false); // Close Products dropdown if it's open
  };

  const handleProductsMouseEnter = () => {
    clearTimeout(hideTimeout.current);
    setShowProductsDropdown(true);
    setShowDropdown(false); // Close Features dropdown if it's open
  };

  const handleMouseLeave = () => {
    hideTimeout.current = setTimeout(() => {
      setShowDropdown(false);
    }, 100);
  };

  const handleProductsMouseLeave = () => {
    hideTimeout.current = setTimeout(() => {
      setShowProductsDropdown(false);
    }, 100);
  };

  const handleColorModeToggle = () => {
    toggleColorMode();
  };
  const navigate = useNavigate();

  const openDetailsPanelSettings = () => {
    if (isCollapsed) {
      setIsCollapsed(false);
    }
  };

  const toHome = () => {
    openDetailsPanelSettings();
    navigate("/");
    setReleasePanel(null);
    setBeatPanel(null);
    setLyricsPanel(null);
    setRecordingsPanel(null);
    setArtworksPanel(null);
    setProfileSettings(null);
    setReleasesKanban(null);
    setAddBeatToLyric(null);
    setAddRecordingToLyric(null);
    setLinkRecording(null);
    setAddRecording(null);
    setLinkArtwork(null);
  };

  const toLogin = () => {
    navigate("/login");
  };

  const toAbout = () => {
    navigate("/about");
  };

  return (
    <Flex
      className={`header-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      as="header"
    >
      <Flex alignItems={"center"}>
        <Flex alignItems={"end"} ml={5}>
          <Text onClick={toHome} className={"mainlogomain"}>
            IndieMutual
          </Text>
        </Flex>
        <Flex
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="features-hover-container"
          ml={"50px"}
          opacity={showProductsDropdown ? "50%" : ""}
          _hover={{ cursor: "pointer" }}
        >
          <Flex gap={"5px"} alignItems={"center"}>
            <Text className={"featuresdropdown"}>Features</Text>
            <Icon
              fontSize={"22px"}
              as={ChevronDownIcon}
              className={`rotate-icon ${showDropdown ? "rotate-up" : ""}`}
            />{" "}
          </Flex>
        </Flex>
        <Flex
          onMouseEnter={handleProductsMouseEnter}
          onMouseLeave={handleProductsMouseLeave}
          className="products-hover-container"
          ml={"50px"}
          opacity={showDropdown ? "50%" : ""}
          _hover={{ cursor: "pointer" }}
        >
          <Flex gap={"5px"} alignItems={"center"}>
            <Text className={"featuresdropdown"}>Products</Text>
            <Icon
              fontSize={"22px"}
              as={ChevronDownIcon}
              className={`rotate-icon ${
                showProductsDropdown ? "rotate-up" : ""
              }`}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex mr={5}>
        <Flex align={"center"} gap={"10px"}>
          <Button size={"sm"} variant={"ghost"} onClick={handleColorModeToggle}>
            {colorMode === "light" ? (
              <MoonIcon color="black" />
            ) : (
              <SunIcon color="yellow.400" />
            )}
          </Button>
          <Button onClick={toLogin} variant={"outline"} size={"sm"}>
            Login
          </Button>
        </Flex>
      </Flex>
      {showDropdown && (
        <FeaturesDropdown
          onMouseEnter={() => clearTimeout(hideTimeout.current)}
          onMouseLeave={handleMouseLeave}
          colorMode={colorMode}
          setShowDropdown={setShowDropdown}
        />
      )}

      {showProductsDropdown && (
        <ProductsDropdown
          onMouseEnter={() => clearTimeout(hideTimeout.current)}
          onMouseLeave={handleProductsMouseLeave}
          colorMode={colorMode}
        />
      )}
    </Flex>
  );
};

export default Header;
