// ProfileSettingsContext.js
import { createContext, useState, useContext } from "react";

const ProfileSettingsContext = createContext();

export const useProfileSettings = () => useContext(ProfileSettingsContext);

export const ProfileSettingsProvider = ({ children }) => {
  const [profileSettings, setProfileSettings] = useState(null);

  return (
    <ProfileSettingsContext.Provider
      value={{ profileSettings, setProfileSettings }}
    >
      {children}
    </ProfileSettingsContext.Provider>
  );
};
