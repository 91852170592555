import { createContext, useState, useContext } from "react";

const LyricsKanbanContext = createContext();

export const useLyricsKanban = () => useContext(LyricsKanbanContext);

export const LyricsKanbanProvider = ({ children }) => {
  const [lyricsKanban, setLyricsKanban] = useState(null);

  return (
    <LyricsKanbanContext.Provider value={{ lyricsKanban, setLyricsKanban }}>
      {children}
    </LyricsKanbanContext.Provider>
  );
};
