import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Avatar,
  useColorModeValue,
  Flex,
  IconButton,
  Input,
  Icon,
  Button,
  Image,
  useDisclosure,
  useToast,
  Tooltip,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { FiDownload } from "react-icons/fi";
import { DownloadIcon, SearchIcon } from "@chakra-ui/icons";
import { BsPlusLg, BsTrash } from "react-icons/bs";
import { API_ENDPOINT } from "../../../../Config/config";
import "./MutualBoardFolder.css";
import newFolderIcon from "../../../../Svggs/newiconsfolder.svg";

const MutualBoardFolder = ({
  colorMode,
  board,
  boardId,
  mutualSpaceId,
  userId,
  isSidebarCollapsed,
  tableId,
  userName,
  guestToken,
  folderId,
}) => {
  const [folder, setFolder] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const toast = useToast();
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const boxShadow = useColorModeValue("sm", "dark-lg");

  const openDeleteModal = (file) => {
    setFileToDelete(file);
    setIsDeleteModalOpen(true);
  };

  const openAddFileModal = () => {
    setIsAddFileModalOpen(true);
  };

  const fetchFolderData = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/folder/${folderId}`
      );
      setFolder(response.data.folder);
    } catch (error) {
      console.error("Error fetching folder data:", error);
    }
  };

  useEffect(() => {
    fetchFolderData();
  }, [folderId]);

  const [theFolderName, setTheFolderName] = useState(folder?.folderName);

  useEffect(() => {
    setTheFolderName(folder?.folderName);
  }, [folder]);

  const formatDate = (uploadDate) => {
    const uploadedAt = new Date(uploadDate);
    const now = new Date();
    const timeDiff = now.getTime() - uploadedAt.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    if (daysDiff < 1) {
      const hoursDiff = timeDiff / (1000 * 3600);
      if (hoursDiff < 1) {
        const minutesDiff = timeDiff / (1000 * 60);
        return `${Math.floor(minutesDiff)} minutes ago`;
      }
      return `${Math.floor(hoursDiff)} hours ago`;
    } else if (daysDiff < 10) {
      return `${Math.floor(daysDiff)} days ago`;
    } else {
      return `on ${uploadedAt.toLocaleString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      })} @ ${uploadedAt.toLocaleTimeString()}`;
    }
  };

  return (
    <Box
      className={`MutualBoardFolder-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        className={`MutualBoardFolder-surround ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        w={"100%"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={"10px"}
      >
        <Flex w={"100%"} flexDirection={"column"} gap={"20px"}>
          <Flex
            w={"100%"}
            mb={1}
            justifyContent={"start"}
            alignItems={"end"}
            gap={"10px"}
          >
            <Box className="fullfolder-container">
              <Image src={newFolderIcon} />
            </Box>
            <Flex alignItems={"end"} gap={"10px"}>
              <Input
                w={"50%"}
                size={"xs"}
                borderRadius={"5px"}
                value={theFolderName}
                border={"none"}
                bg={"none"}
                fontSize={"22px"}
                isDisabled={!!guestToken}
              />
              <Text fontSize={"11px"}> {folder?.files.length} Files</Text>
            </Flex>
          </Flex>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={"10px"}
            w={"100%"}
          >
            <Flex w={"100%"}>
              <InputGroup w={"100%"}>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={SearchIcon} color="gray.500" />}
                />
                <Input
                  size={"sm"}
                  borderRadius={"7px"}
                  w={"100%"}
                  placeholder="Search Files"
                  bg={inputBg}
                  borderColor={borderColor}
                  _hover={{
                    bg: hoverBg,
                    borderColor: "gray.400",
                  }}
                  _focus={{
                    boxShadow: `${boxShadow}`,
                    borderColor: "gray.400",
                  }}
                  isDisabled={!!guestToken}
                />
              </InputGroup>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          h={"100%"}
          w={"100%"}
          flexDirection={"column"}
          justifyContent={"end"}
          alignItems={"end"}
          gap={"30px"}
        >
          <Flex alignItems={"center"} gap={"10px"}>
            <Button isDisabled={!!guestToken} colorScheme="red" size={"sm"}>
              Delete
            </Button>
            <Button isDisabled={!!guestToken} variant={"outline"} size={"sm"}>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={DownloadIcon} />
                <Text>Download</Text>
              </Flex>
            </Button>
            <Button
              isDisabled={!!guestToken}
              onClick={openAddFileModal}
              variant={"outline"}
              size={"sm"}
            >
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={BsPlusLg} />
                <Text>Add File</Text>
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Box
        className={`indiefoldertableheights ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th
                className={`indiefolder-table-header ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
                position="sticky"
                top="0"
                bg={colorMode === "light" ? "white" : "rgba(28, 33, 57, 1)"}
                zIndex="sticky"
              >
                Name
              </Th>

              <Th
                className={`indiefolder-table-header ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
                textAlign={"start"}
                position="sticky"
                top="0"
                bg={colorMode === "light" ? "white" : "rgba(28, 33, 57, 1)"}
                zIndex="sticky"
              >
                Modified
              </Th>
              <Th
                textAlign={"center"}
                position="sticky"
                top="0"
                bg={colorMode === "light" ? "white" : "rgba(28, 33, 57, 1)"}
                zIndex="sticky"
              ></Th>
            </Tr>
          </Thead>
          <Tbody>
            {folder?.files.map((file) => (
              <Tr
                _hover={{ backgroundColor: "rgba(208, 212, 228, 0.32)" }}
                key={file?.fileName}
              >
                <Td>
                  <Text
                    maxW={"300px"}
                    isTruncated
                    className="indiefolder-table-data"
                  >
                    {file?.fileName}
                  </Text>
                </Td>

                <Td isNumeric>
                  <Flex alignItems={"start"}>
                    <Text className="indiefolder-table-data">
                      {formatDate(file?.uploadedAt)}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"10px"}
                  >
                    <Tooltip
                      placement="top"
                      borderRadius={"7px"}
                      label={"Download File"}
                    >
                      <IconButton
                        size={"xs"}
                        aria-label="Download file"
                        icon={<FiDownload />}
                        colorScheme="blue"
                        variant={"outline"}
                        // onClick={() =>
                        //   downloadFile(file.fileKey, file.fileName)
                        // }
                        isDisabled={!!guestToken}
                      />
                    </Tooltip>

                    <Tooltip
                      placement="top"
                      borderRadius={"7px"}
                      label={"Delete File"}
                    >
                      <IconButton
                        size={"xs"}
                        aria-label="Delete file"
                        icon={<BsTrash />}
                        colorScheme="red"
                        variant={"outline"}
                        onClick={() => openDeleteModal(file)} // Pass the current file
                        isDisabled={!!guestToken}
                      />
                    </Tooltip>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default MutualBoardFolder;
