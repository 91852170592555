import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Input,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  SimpleGrid,
  Portal,
  Icon,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { BsPlus } from "react-icons/bs";
import axios from "axios";
import { FaX } from "react-icons/fa6";
import { GiSandsOfTime } from "react-icons/gi";
import { API_ENDPOINT } from "../../../Config/config";

const CurrentArtworkStatus = ({
  userId,
  currentArtwork,
  artworkAsset,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  artworkId,
  fetchArtworkAssets,
  guestToken,
}) => {
  const [newStatusInput, setNewStatusInput] = useState("");
  const [customStatuses, setCustomStatuses] = useState([]);
  const [statusColors, setStatusColors] = useState({});
  const toast = useToast();

  const colorOptions = [
    "#E53E3E", // Red.500
    "#3182CE", // Blue.500
    "#38A169", // Green.500
    "#DD6B20", // Orange.500
    "#805AD5", // Purple.500
    colorMode === "light" ? "rgba(251, 170, 74, 1)" : "rgba(252, 187, 107, 1)", // Yellow.500
    "#4FD1C5", // Teal.500
    "#ED64A6", // Pink.500
    "#718096", // Gray.500
    "#F687B3", // Pink.300 - Lighter for contrast in dark mode
  ];

  const handleAddCustomStatus = async () => {
    const trimmedStatus = newStatusInput.trim();
    if (!trimmedStatus) return;

    try {
      console.log("Adding new status:", trimmedStatus); // Debugging log

      await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/customstatuses`,
        { status: trimmedStatus }
      );
      setNewStatusInput("");

      toast({
        title: "Status Added",
        description: "New custom status has been successfully added.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      console.log("Updating status to:", trimmedStatus); // Debugging log

      await updateStatus(trimmedStatus);
      fetchArtworkAssets();
    } catch (error) {
      console.error("Error adding custom status:", error);
      toast({
        title: "Error",
        description: "Could not add new status. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchCustomStatuses = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/customstatuses`
      );
      if (response.data && response.data.customStatuses) {
        setCustomStatuses(response.data.customStatuses);
        const colors = {};
        response.data.customStatuses.forEach((status) => {
          colors[status.status] = status.color || "transparent";
        });
        setStatusColors(colors);
      } else {
        setCustomStatuses([]);
        setStatusColors({});
      }
    } catch (error) {
      console.error("Error fetching custom statuses:", error);
      toast({
        title: "Error",
        description: "Failed to load custom statuses.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCustomStatuses();
  }, [mutualSpaceId, boardId, tableId]);

  const handleUpdateColor = async (status, color) => {
    try {
      const response = await axios.patch(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/customstatuses/${status}`,
        { color }
      );
      if (response.data) {
        toast({
          title: "Color Updated",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
      }
      fetchArtworkAssets();
    } catch (error) {
      console.error("Error updating status color:", error);
      toast({
        title: "Error",
        description: "There was an error updating the status color.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateStatus = async (newStatus) => {
    try {
      console.log("Setting new status:", newStatus); // Debugging log

      const response = await axios.put(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/artwork/${artworkId}/status`, // Adjusted endpoint to match the backend route
        { newStatus }
      );
      console.log("Status update response:", response); // Debugging log

      toast({
        title: "Status Updated",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      fetchArtworkAssets();
    } catch (error) {
      console.error("Error updating Writing To status:", error);
      toast({
        title: "Error",
        description: "There was an error updating the Writing To status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteCustomStatus = async (status) => {
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/customstatuses/${status}`
      );
      if (response.data) {
        toast({
          title: "Status Deleted",
          description: "The custom status has been successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
        fetchCustomStatuses(); // Re-fetch the list of custom statuses to update UI
      }
      fetchArtworkAssets();
    } catch (error) {
      console.error("Error deleting custom status:", error);
      toast({
        title: "Error",
        description: "Failed to delete the custom status. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex w={"100%"} flexDirection={"column"} alignItems={"start"} gap={"3px"}>
      <Menu>
        <MenuButton
          fontSize={"9px"}
          onClick={(event) => event.stopPropagation()} // Stop event propagation
          size="sm"
          as={Button}
          rightIcon={<ChevronDownIcon />}
          w={"100%"}
          border={"none"}
          color={
            colorMode === "light"
              ? !currentArtwork?.status
                ? "rgba(6, 21, 43, 1)"
                : "white"
              : !currentArtwork?.status
              ? "white"
              : "white"
          }
          backgroundColor={statusColors[currentArtwork?.status]}
          _hover={{
            backgroundColor: currentArtwork?.status?.color,
          }}
        >
          {currentArtwork?.status || "Status"}
        </MenuButton>
        <MenuList
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
        >
          <Flex alignItems={"center"} gap={"5px"} p={1}>
            <Input
              isDisabled={!!guestToken}
              borderRadius={"3px"}
              placeholder="Add Status..."
              size={"xs"}
              width={"100%"}
              onChange={(e) => setNewStatusInput(e.target.value)}
              value={newStatusInput}
              onClick={(e) => e.stopPropagation()}
            />
            <Tooltip
              label="Add Status"
              hasArrow
              placement="top"
              closeOnClick={false}
            >
              <IconButton
                isDisabled={!!guestToken}
                icon={<BsPlus />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddCustomStatus();
                }}
                size={"xs"}
                colorScheme="green"
              />
            </Tooltip>
          </Flex>
          {customStatuses.map((status, index) => (
            <MenuItem
              isDisabled={!!guestToken}
              onClick={() => updateStatus(status.status)}
              key={index}
            >
              <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
                <Popover>
                  <PopoverTrigger>
                    <Box
                      as="button" // Ensures the box can be focused and interacted with like a button
                      w="20px"
                      h="20px"
                      border="1px solid" // Optional: adds a border to the square
                      borderColor="gray.300" // Optional: sets the border color
                      backgroundColor={status.color || "transparent"}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent w="auto">
                      <SimpleGrid columns={5} spacing={2} p={2}>
                        {colorOptions.map((color, colorIndex) => (
                          <Box
                            key={colorIndex}
                            w="20px"
                            h="20px"
                            bg={color}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUpdateColor(status.status, color);
                            }}
                          />
                        ))}
                      </SimpleGrid>
                    </PopoverContent>
                  </Portal>
                </Popover>

                <Flex
                  w={"100%"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={"5px"}
                >
                  <Text className="statusbeat">{status.status}</Text>
                  <Icon
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCustomStatus(status.status);
                    }}
                    color={"red"}
                    as={FaX}
                    cursor="pointer"
                  />
                </Flex>
              </Flex>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default CurrentArtworkStatus;
