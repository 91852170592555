import React from "react";
import "./TracksSection.css";
import { Flex, Image, Box, Text } from "@chakra-ui/react";
import tracksimg from "../../Svggs/newdarktracksimage.svg";
import lightmodetracks from "../../Svggs/newwhitetracks.svg";

const TracksSection = ({ colorMode }) => {
  return (
    <Flex
      className={`TracksSection-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        className={`tracksherosideone ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Text className="BeatsnInstrumental">
          {" "}
          Beats & Instrumental <br />
          Tracks
        </Text>
      </Flex>
      <Box className="sidetwoimgecontainer">
        <Image
          src={colorMode === "light" ? lightmodetracks : tracksimg}
          className="sidetwoimgsection"
        />
      </Box>
    </Flex>
  );
};

export default TracksSection;
