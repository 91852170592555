import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Flex,
  Input,
  Icon,
  Tooltip,
  Avatar,
  MenuList,
  MenuItem,
  MenuButton,
  Menu,
  useToast,
  Text,
  Checkbox,
  IconButton,
} from "@chakra-ui/react";
import "./MobileMainTaskItem.css";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import "./MobileMainTaskItem.css";
import { BsTrash } from "react-icons/bs";

const MobileMainTaskItem = ({
  task,
  taskId,
  board,
  boardId,
  userId,
  colorMode,
  mutualSpaceId,
  isSidebarCollapsed,
  fetchTasks,
  guestToken,
}) => {
  const [items, setItems] = useState([]);
  const scrollTableRef = useRef(null);
  const fixedColRef = useRef(null);
  const newItemRef = useRef("");
  const [isHovering, setIsHovering] = useState(null);
  const toast = useToast();
  const [isTableOpen, setIsTableOpen] = useState(true);
  const toggleTablesOpen = () => setIsTableOpen(!isTableOpen);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onScroll = () => {
    if (fixedColRef.current) {
      fixedColRef.current.scrollTop = scrollTableRef.current?.scrollTop;
    }
  };

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);

  const [isDeleteItemModalOpen, setIsDeleteItemModalOpen] = useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState(null);

  const showDeleteConfirmation = (index) => {
    setDeleteItemIndex(index);
    setIsConfirmDeleteModalOpen(true);
  };

  const showItemDeleteConfirmation = (index) => {
    setDeleteItemIndex(index);
    setIsDeleteItemModalOpen(true);
  };

  const [editableItemNames, setEditableItemNames] = useState({});

  useEffect(() => {
    const names = {};
    items.forEach((item) => {
      names[item?._id] = item?.name;
    });
    setEditableItemNames(names);
  }, [items]);

  const [checkedItems, setCheckedItems] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleCheckboxChange = (itemId) => {
    setCheckedItems((prev) => {
      const newCheckedItems = { ...prev, [itemId]: !prev[itemId] };
      const areAllChecked = items.every((item) => newCheckedItems[item?._id]);
      setSelectAllChecked(areAllChecked);
      return newCheckedItems;
    });
  };

  const handleSelectAllCheckbox = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    const newCheckedItems = {};
    items.forEach((item) => {
      newCheckedItems[item?._id] = isChecked;
    });
    setCheckedItems(newCheckedItems);
  };

  useEffect(() => {
    const areAllChecked = items.every((item) => checkedItems[item?._id]);
    setSelectAllChecked(areAllChecked);
  }, [items, checkedItems]);

  const [editableTableName, setEditableTableName] = useState(
    task?.taskTableName
  );

  const [showMenu, setShowMenu] = useState(false);
  const [showMenutwo, setShowMenutwo] = useState(false);
  const [showMenuthree, setShowMenuthree] = useState(false);

  const handleDeleteItem = async () => {
    const itemId = items[deleteItemIndex]?._id;
    if (!itemId) {
      toast({
        title: "Error",
        description: "Item not found",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}`
      );
      if (response.status === 200) {
        toast({
          title: "Item Deleted",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottem-left",
        });
        fetchItems();
      } else {
        throw new Error("Failed to delete the item");
      }
      setIsDeleteItemModalOpen(false);
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description:
          error.response?.data?.message || "Failed to delete the item.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleUpdateItemName = async (itemId, newName) => {
    if (!newName.trim()) {
      toast({
        title: "Error",
        description: "Item name cannot be empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/name`,
        { newName }
      );

      if (response.status === 200) {
        toast({
          title: "Item Name Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        fetchItems();
      } else {
        throw new Error("Failed to update the item name");
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description:
          error.response?.data?.message || "Failed to update item name.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddNewItem = async () => {
    const newItemName = newItemRef.current.value.trim();
    if (!newItemName) {
      toast({
        title: "Error",
        description: "Item name cannot be empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/additem`,
        { newItem: { name: newItemName } }
      );
      if (response.data) {
        toast({
          title: "Item added!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        newItemRef.current.value = "";
        fetchItems();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to add item",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchItems = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/items`
      );
      setItems(response.data.data);
      console.log(items);
    } catch (error) {
      toast({
        title: "Error fetching items",
        description: error.response?.data?.message || "Failed to fetch items",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchItems();
  }, [taskId, boardId, mutualSpaceId]);

  const deleteTask = async () => {
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}`
      );
      if (response.status === 200) {
        toast({
          title: "Task Deleted",
          description: "The task has been successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else {
        throw new Error("Failed to delete the task");
      }
      setIsDeleteModalOpen(false);
      fetchTasks();
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description:
          error.response?.data?.message || "Failed to delete the task.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "In Progress":
        return colorMode === "light"
          ? "rgba(253, 188, 49, 1)"
          : "rgba(253, 188, 49, 1)";
      case "Done":
        return colorMode === "light"
          ? "rgba(31, 199, 120, 1)"
          : "rgba(61, 160, 107, 1)";
      default:
        return "transparent"; // Default case if no status matches
    }
  };

  const getPriorityBackgroundColor = (type) => {
    switch (type) {
      case "High":
        return colorMode === "light"
          ? "rgba(255, 75, 85, 1)"
          : "rgba(240, 63, 78, 0.84)";
      case "Medium":
        return colorMode === "light" ? "rgba(255, 96, 61, 1)" : "#DD6B20";
      case "Low":
        return colorMode === "light"
          ? "rgba(253, 188, 49, 1)"
          : "rgba(253, 188, 49, 1)";

      default:
        return "transparent";
    }
  };

  const updateItemDate = async (itemId, date) => {
    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/date`,
        {
          date: "",
        }
      );
      if (response.status === 200) {
        toast({
          title: "Date Updated",
          description: "The item's date has been successfully updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchItems();
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description:
          error.response?.data?.message || "Failed to update the date.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const [showDateColumn, setShowDateColumn] = useState(false);

  useEffect(() => {
    const hasDate = items.some((item) => item.date !== undefined);
    setShowDateColumn(hasDate);
  }, [items]);

  const handleUpdateTaskTableName = async () => {
    if (!editableTableName.trim()) {
      toast({
        title: "Error",
        description: "Task table name cannot be empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.put(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/name`,
        { newTaskTableName: editableTableName }
      );

      if (response.status === 200) {
        toast({
          title: "Task Table Name Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        fetchTasks();
      } else {
        throw new Error("Failed to update the task table name");
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description:
          error.response?.data?.message || "Failed to update task table name.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box className="main-task-mobile-container">
      <Flex
        _hover={{ opacity: "80%", bg: "rgba(150, 158, 167, 0.12)" }}
        fontSize={"14px"}
        color={"rgba(150, 158, 167, 1)"}
        alignItems={"center"}
        justifyContent={"space-between"}
        cursor={"pointer"} // Add cursor style to indicate clickability
        onClick={toggleTablesOpen} // Step 4: Attach the onClick handler
        borderRadius={"7px"}
        gap={"10px"}
      >
        <Flex alignItems={"center"} gap={"5px"}>
          <Icon
            mt={1}
            fontSize={"25px"}
            color={
              colorMode === "light"
                ? "rgba(91, 157, 249, 1)"
                : "rgba(91, 157, 249, 1)"
            }
            as={isTableOpen ? FiChevronDown : FiChevronRight}
          />
          <Input
            onClick={(e) => e.stopPropagation()}
            value={editableTableName}
            onChange={(e) => setEditableTableName(e.target.value)}
            onBlur={handleUpdateTaskTableName}
            borderRadius={"6px"}
            className={`inputsyles ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
            border={"none"}
            size={"sm"}
          />
        </Flex>
        <Tooltip
          placement="top"
          borderRadius={"7px"}
          label={"Delete Mutualspace"}
        >
          <Button
            size={"sm"}
            colorScheme="red"
            onClick={(e) => {
              e.stopPropagation();
              setIsDeleteModalOpen(true); // Prevent triggering parent's onClick
            }}
          >
            Delete
          </Button>
        </Tooltip>
      </Flex>
      <Flex w={"100%"}>
        {isTableOpen && (
          <Box
            className={`mobiletask-table-surround ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
          >
            <Box ref={fixedColRef}>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th
                      className={`mobile-item-title  ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      <Checkbox />
                    </Th>
                    <Th
                      className={`mobile-item-title  ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Item
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.map((item, index) => (
                    <>
                      <Tr
                        onMouseEnter={() => setIsHovering(index)}
                        onMouseLeave={() => setIsHovering(null)}
                        className={`${
                          isHovering === index ? "isnewitemrowhover" : ""
                        } ${colorMode === "light" ? "lightmode" : "darkmode"}`}
                      >
                        <Td
                          className={`first-table-column ${
                            colorMode === "light" ? "lightmode" : "darkmode"
                          }`}
                        >
                          <Checkbox
                            isChecked={checkedItems[item?._id]}
                            onChange={() => handleCheckboxChange(item?._id)}
                          />
                        </Td>
                        <Td
                          className={`first-table-column ${
                            colorMode === "light" ? "lightmode" : "darkmode"
                          }`}
                        >
                          <Flex gap={"5px"} className="first-table-column">
                            {!guestToken && (
                              <Tooltip
                                label="Delete Item"
                                hasArrow
                                placement="top"
                                closeOnClick={false}
                              >
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    showItemDeleteConfirmation(index);
                                  }}
                                  colorScheme="red"
                                  icon={<BsTrash />}
                                  size={"xs"}
                                  ml={1}
                                />
                              </Tooltip>
                            )}
                            <Input
                              border={"none"}
                              size="xs"
                              value={editableItemNames[item?._id] || ""}
                              onChange={(e) =>
                                setEditableItemNames({
                                  ...editableItemNames,
                                  [item?._id]: e.target.value,
                                })
                              }
                              onBlur={() =>
                                handleUpdateItemName(
                                  item?._id,
                                  editableItemNames[item?._id]
                                )
                              }
                              isDisabled={!!guestToken}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    </>
                  ))}

                  <Tr>
                    <Td></Td>
                    <Td>
                      <Input
                        isDisabled={!!guestToken}
                        ref={newItemRef}
                        placeholder="Add new"
                        size="xs"
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default MobileMainTaskItem;
