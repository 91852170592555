import React, { useState, useRef } from "react";
import {
  Button,
  Flex,
  Text,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  IconButton,
  MenuDivider,
  Input,
} from "@chakra-ui/react";
import { FaTasks, FaEllipsisV } from "react-icons/fa";
import { EditIcon } from "@chakra-ui/icons";
import { BiShare, BiTrash } from "react-icons/bi";

const TaskButton = ({
  closedetailsPanelSetting,
  setActiveTab,
  colorMode,
  activeTab,
  guestToken,
}) => {
  const [showMenuButton, setShowMenuButton] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State to control the menu visibility
  const [isEditing, setIsEditing] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("Tasks"); // State for the button label

  const handleRename = () => {
    setIsEditing(true);
    setMenuOpen(false); // Optionally close the menu when starting editing
  };

  const handleLabelChange = (e) => {
    setButtonLabel(e.target.value);
  };

  const handleLabelSave = (e) => {
    if (e.key === "Enter") {
      // Save on Enter key press
      setIsEditing(false);
    }
  };

  const handleBlur = () => {
    setIsEditing(false); // Exit edit mode when input loses focus
  };

  return (
    <Menu
      w={"100%"}
      p={1}
      border={
        colorMode === "light"
          ? "2px solid rgba(153, 178, 198, 0.23)"
          : "2px solid rgba(43, 46, 54, 1)"
      }
      bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
      onOpen={() => setMenuOpen(true)}
      onClose={() => setMenuOpen(false)}
    >
      <Button
        onMouseEnter={() => setShowMenuButton(true)}
        onMouseLeave={() => setShowMenuButton(false)}
        as={Button}
        size={"sm"}
        variant={activeTab === "tasks" ? "solid" : "outline"}
        onClick={(e) => {
          closedetailsPanelSetting();
          setActiveTab("tasks");
        }}
      >
        <Flex alignItems={"center"} w={"100%"} gap={"5px"}>
          <Icon as={FaTasks} />
          {isEditing ? (
            <Input
              autoFocus
              value={buttonLabel}
              onChange={handleLabelChange}
              onKeyDown={handleLabelSave}
              onBlur={handleBlur}
              size="xs"
              className={`tab-btn-styles ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
              w={"60px"}
            />
          ) : (
            <Text
              className={`tab-btn-styles ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              {buttonLabel}
            </Text>
          )}
          {(showMenuButton || menuOpen) && ( // Check for menuOpen state or mouse over
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<FaEllipsisV />}
              size="xs"
              variant="outline"
            />
          )}
        </Flex>
      </Button>
      <MenuList bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}>
        <MenuItem
          isDisabled={!!guestToken}
          onClick={handleRename}
          fontSize={"13px"}
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          _hover={{
            bg:
              colorMode === "light"
                ? "rgba(241, 244, 249, 1)"
                : "rgba(42, 49, 70, 1)",
          }}
        >
          <Flex alignItems={"center"} gap={"10px"}>
            <Icon as={EditIcon} />
            <Text>Rename</Text>
          </Flex>
        </MenuItem>
        <MenuItem
          fontSize={"13px"}
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          _hover={{
            bg:
              colorMode === "light"
                ? "rgba(241, 244, 249, 1)"
                : "rgba(42, 49, 70, 1)",
          }}
        >
          <Flex alignItems={"center"} gap={"10px"}>
            <Icon as={BiShare} />
            <Text>Share</Text>
          </Flex>
        </MenuItem>
        <MenuDivider />

        <MenuItem
          isDisabled={!!guestToken}
          fontSize={"13px"}
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          _hover={{
            bg:
              colorMode === "light"
                ? "rgba(241, 244, 249, 1)"
                : "rgba(42, 49, 70, 1)",
          }}
          color={colorMode === "light" ? "red.500" : "red.300"}
        >
          <Flex alignItems={"center"} gap={"10px"}>
            <Icon as={BiTrash} />
            <Text>Delete</Text>
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default TaskButton;
