import React from "react";
import "./MainLandingHero.css";
import { Box, Text, Button, Flex, Icon } from "@chakra-ui/react";
import { FaArrowRightFromBracket, FaUserCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const MainLandingHero = ({ colorMode, handleDemoAccess, isMobileView }) => {
  return (
    <Box
      className={`MainLandingHero-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      w={"100%"}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={isMobileView ? "20px" : "40px"}
        w={"100%"}
      >
        <Flex
          gap={isMobileView ? "20px" : "40px"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Text
            className={`headingmainre ${
              colorMode === "light" ? "lightmode" : "darkmode"
            }`}
          >
            The Task Management Platform For Music Releases
          </Text>
          {isMobileView ? (
            <>
              <Text
                className={`subheadmainre ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                Customizable management tools for your release strategy.
              </Text>
            </>
          ) : (
            <>
              <Text
                className={`subheadmainre ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                Plan and manage all of your assets in one place with
                customizable project management tools.
              </Text>
            </>
          )}
        </Flex>
        <Flex
          zIndex={"2"}
          w={"100%"}
          alignItems={"center"}
          justifyContent={isMobileView ? "center" : "center"}
          gap={isMobileView ? "10px" : "10px"}
        >
          <Button
            onClick={handleDemoAccess}
            fontSize={isMobileView ? "12px" : ""}
            size={isMobileView ? "md" : "lg"}
          >
            <Flex alignItems={"center"} gap={"8px"}>
              <Text>Preview As Guest</Text>
              <Icon as={FaUserCheck} />
            </Flex>{" "}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MainLandingHero;
