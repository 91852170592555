import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tag,
  Avatar,
  TagLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { BsPlayCircle, BsPauseCircle } from "react-icons/bs";
import { DownloadIcon } from "@chakra-ui/icons";
import { FaMicrophone, FaRegComments, FaTrashCan } from "react-icons/fa6";
import { MusicPlayerContext } from "../../../../Context/MusicPlayerContext";
import "./MainBeatsTablePlayer.css";
import { useCurrentBeat } from "../../../../Context/CurrentBeatContext";
import { useBeatPanel } from "../../../../Context/BeatsPanelContext";
import { PiNotepadThin } from "react-icons/pi";
import axios from "axios";
import { API_ENDPOINT } from "../../../../Config/config";
import { useCurrentLyric } from "../../../../Context/CurrentLyricContext";
import { useLyricsPanel } from "../../../../Context/LyricsPanelContext";
import { BiFolder, BiUserPlus } from "react-icons/bi";
import CurrentBeatStatusItem from "../../../DetailsPanel/BeatsPanel/CurrentBeatStatusItem/CurrentBeatStatusItem";
import BeatDrawer from "../BeatDrawer/BeatDrawer";
import FoldersTag from "./FoldersTag";
import { GiSandsOfTime } from "react-icons/gi";

const MainBeatsTablePlayer = ({
  beatAsset,
  colorMode,
  guestToken,
  tableId,
  mutualSpaceId,
  boardId,
  board,
  openDetailsPanelSettings,
  isActive,
  setActiveBeatId,
  fetchBeatData,
  isMobileView,
  userName,
  userId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setCurrentBeat } = useCurrentBeat();
  const { setBeatPanel } = useBeatPanel();
  const { currentLyric, setCurrentLyric } = useCurrentLyric();
  const { setLyricsPanel } = useLyricsPanel();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false); // New state for delete confirmation modal
  const toast = useToast();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const handleInnerClick = (event) => {
    event.stopPropagation();
  };

  const { playTrack, stopTrack, currentTrack, seekTrack } =
    useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [localDuration, setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack &&
    currentTrack.src === beatAsset.fileUrl &&
    !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(beatAsset.fileUrl);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const [statusColors, setStatusColors] = useState({});
  const [customStatuses, setCustomStatuses] = useState([]);

  const deleteBeatAsset = async () => {
    try {
      const url = `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/beat/${beatAsset._id}`;
      const response = await axios.delete(url);
      if (response.status === 200) {
        toast({
          title: "Track Deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
        setDeleteModalOpen(false);
        fetchBeatData();
      }
    } catch (error) {
      toast({
        title: "Failed to Delete.",
        description: "Could not delete the beat. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const downloadBeat = async () => {
    try {
      toast({
        title: "Download Starting...",
        description: "Your download will start shortly.",
        status: "info",
        duration: 3000,
        isClosable: true,
        position: "bottom-left",
      });

      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/beat/${beatAsset._id}/download`,
        {
          responseType: "blob",
        }
      );
      const file = new Blob([response.data], { type: "audio/mpeg" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", `${beatAsset.title || "download"}.mp3`);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(fileURL);
      document.body.removeChild(link);

      toast({
        title: "Download Finished",
        description: "Your download has completed successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-left",
      });
    } catch (error) {
      toast({
        title: "Download Failed",
        description:
          "There was an issue starting your download. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      console.error("Error downloading beat:", error);
    }
  };

  return (
    <Flex
      className={`MainBeatsTablePlayer-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      style={{
        backgroundColor: isActive ? "rgba(212, 240, 252, 0.37)" : "",
      }}
      onClick={onDrawerOpen} // Add this line
    >
      <Flex>
        {isCurrentBeatPlaying ? (
          <IconButton
            onClick={(event) => {
              handleInnerClick(event);
              togglePlayPause(); // Call the original saveProducerName function
            }}
            icon={<BsPauseCircle className="main-player-button" />}
          />
        ) : (
          <IconButton
            onClick={(event) => {
              handleInnerClick(event);
              togglePlayPause(); // Call the original saveProducerName function
            }}
            icon={<BsPlayCircle className="main-player-button" />}
          />
        )}
      </Flex>
      <Flex>
        <Flex flexDirection={"column"}>
          <Text className="player-name">{beatAsset.title}</Text>
          <Text className="player-producer">
            {beatAsset.producer || "Producer"}
          </Text>
        </Flex>
      </Flex>
      <Flex w={"100%"}>
        <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
          <Text className="slider-time">{formatTime(localCurrentTime)}</Text>
          <Slider
            aria-label="track-progress"
            value={isCurrentBeatPlaying ? localCurrentTime : 0}
            max={isCurrentBeatPlaying ? localDuration : 0}
            onChange={(val) => isCurrentBeatPlaying && seekTrack(val)}
            focusThumbOnChange={false}
            isDisabled={!isCurrentBeatPlaying}
            size={"sm"}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Text className="slider-time" ml={1}>
            {formatTime(
              isCurrentBeatPlaying ? localDuration - localCurrentTime : 0
            )}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems={"center"} gap={"10px"}>
        {beatAsset?.linkedlyrics && (
          <Flex alignItems={"center"} gap={"10px"}>
            <Tag w={"100%"} size="sm" colorScheme="yellow" borderRadius="full">
              <Avatar
                bg={"none"}
                color={colorMode === "light" ? "gray" : "yellow"}
                size="xs"
                ml={-1}
                icon={<PiNotepadThin fontSize={"20px"} />}
              />
              <TagLabel className="taggedlabel">Lyrics</TagLabel>
            </Tag>
          </Flex>
        )}
        {beatAsset?.linkedRecordings && (
          <Flex alignItems={"center"} gap={"10px"}>
            <Tag w={"100%"} size="sm" colorScheme="red" borderRadius="full">
              <Avatar
                bg={"none"}
                color={colorMode === "light" ? "red.300" : "red.300"}
                size="xs"
                ml={-1}
                icon={<FaMicrophone fontSize={"13px"} />}
              />
              <TagLabel className="taggedlabel">Recording</TagLabel>
            </Tag>
          </Flex>
        )}
        {beatAsset?.folders && (
          <>
            <FoldersTag
              tableId={tableId}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              currentBeat={beatAsset}
              colorMode={colorMode}
              beatId={beatAsset._id}
              fetchBeatData={fetchBeatData}
            />
          </>
        )}
      </Flex>

      <Flex>
        <CurrentBeatStatusItem
          tableId={tableId}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          currentBeat={beatAsset}
          colorMode={colorMode}
          beatId={beatAsset._id}
          fetchBeatData={fetchBeatData}
          guestToken={guestToken}
        />
      </Flex>
      <Flex alignItems={"center"} gap={"10px"}>
        <Tooltip placement="top" borderRadius={"5px"} label={"Download"}>
          <IconButton
            icon={<DownloadIcon />}
            size={"xs"}
            isDisabled={!!guestToken}
            onClick={(e) => {
              e.stopPropagation();
              downloadBeat();
            }}
          />
        </Tooltip>
        <Tooltip placement="top" borderRadius={"5px"} label={"Delete"}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setDeleteModalOpen(true);
            }}
            isDisabled={!!guestToken}
            icon={<FaTrashCan />}
            size={"xs"}
            colorScheme="red"
          />
        </Tooltip>
        <Tooltip placement="top" borderRadius={"5px"} label={"Bulk Delete"}>
          <Checkbox
            onClick={(e) => {
              e.stopPropagation();
            }}
            size={"lg"}
            isDisabled={!!guestToken}
          />
        </Tooltip>
      </Flex>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Track</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this Track?</ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
              <Button
                colorScheme="gray"
                w={"100%"}
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button w={"100%"} colorScheme="red" onClick={deleteBeatAsset}>
                Delete Track
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose}>
        <DrawerOverlay />
        <DrawerContent
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
          maxWidth="370px"
          width="370px"
        >
          <DrawerCloseButton />

          <DrawerBody>
            <BeatDrawer
              tableId={tableId}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              beatAsset={beatAsset}
              colorMode={colorMode}
              beatId={beatAsset._id}
              fetchBeatData={fetchBeatData}
              userId={userId}
              userName={userName}
              guestToken={guestToken}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default MainBeatsTablePlayer;
