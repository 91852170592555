import {
  Box,
  Text,
  Input,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  IconButton,
  Flex,
  Icon,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./Login.css";
import { TbLogin2 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT } from "../../Config/config";
import axios from "axios";

const Login = ({
  isMobileView,
  setCookie,
  colorMode,
  AuthToken,
  guestToken,
  removeCookie,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => setShowPassword(!showPassword);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast(); // Create a toast instance
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(`${API_ENDPOINT}api/login`, {
        email,
        password,
      });

      setCookie("AuthToken", response.data.token);
      setCookie("UserId", response.data.userId);
      setCookie("Email", response.data.email);
      setCookie("Name", response.data.name);

      if (guestToken) {
        removeCookie("GuestToken", { path: "/" });
      }

      navigate("/dash");
    } catch (error) {
      setIsLoading(false);

      if (
        error.response &&
        error.response.data.message === "User not found with this email"
      ) {
        toast({
          title: "User not found",
          description: "User not found with this email",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else if (
        error.response &&
        error.response.data.message === "Invalid password"
      ) {
        toast({
          title: "Incorrect password",
          description:
            "The Password entered does not match our records. Please Try again!",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  useEffect(() => {
    if (AuthToken) {
      navigate("/dash");
    }
  }, [AuthToken]);

  return (
    <Box
      className={`login-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      as="form"
      onSubmit={handleLogin}
    >
      <Box
        className={`loginstart ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        p={9}
        borderRadius="lg"
        boxShadow="xl"
      >
        <Box mb={2} className="login-top">
          <Flex gap={"8px"} alignItems={"center"}>
            <Icon fontSize={isMobileView ? "32px" : "50px"} as={TbLogin2} />
            <Text className="logintext">Login</Text>
          </Flex>
        </Box>
        <FormControl isRequired mb={4}>
          <FormLabel className="formlabeltext">Email</FormLabel>
          <Input
            placeholder="Enter your email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              "::placeholder": {
                color: colorMode === "light" ? "rgba(84, 81, 100, 1)" : "white",
                fontSize: "14px",
              },
            }}
          />
        </FormControl>
        <FormControl isRequired mb={6}>
          <FormLabel className="formlabeltext">Password</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                "::placeholder": {
                  color:
                    colorMode === "light" ? "rgba(84, 81, 100, 1)" : "white",
                  fontSize: "14px",
                },
              }}
            />

            <InputRightElement width="4.5rem">
              <IconButton
                h="1.75rem"
                size="sm"
                onClick={handleClick}
                icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                aria-label={showPassword ? "Hide password" : "Show password"}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button
          type="submit"
          isLoading={isLoading}
          spinner={<Spinner size="sm" />}
          colorScheme="blue"
          width="full"
          mb={4}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
