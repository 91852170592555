import React, { useState, useEffect } from "react";
import "./Comments.css";
import { Box, Button, Input, useToast, IconButton } from "@chakra-ui/react";
import MainCommentItem from "./MainCommentItem/MainCommentItem";
import { API_ENDPOINT } from "../../Config/config";
import axios from "axios";
import { BsXCircle } from "react-icons/bs";

const LyricComments = ({ colorMode, lyricId, userId, userName }) => {
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [comment, setComment] = useState("");

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}api/lyric/${lyricId}/comments`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setComments(data);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    if (lyricId) {
      fetchComments();
    }
  }, [lyricId]);

  const handlePost = async () => {
    if (!comment.trim()) return;
    setIsLoading(true);
    try {
      await axios.post(`${API_ENDPOINT}api/lyric/${lyricId}/comment`, {
        comment,
        userId, // Use userId from cookies
        name: userName, // Use userName from cookies
      });

      // Fetch updated comments
      const updatedResponse = await axios.get(
        `${API_ENDPOINT}api/lyric/${lyricId}/comments`
      );
      setComments(updatedResponse.data);

      setComment("");
      toast({
        title: "Comment Posted",
        description: "Your comment has been successfully posted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Unable to post comment.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCommentDelete = (deletedCommentId) => {
    setComments(comments.filter((comment) => comment.id !== deletedCommentId));
  };

  return (
    <Box
      className={`main-comment ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Box className="allcomments">
        <Box className="surroundcommentslise">
          {comments?.map((comment) => (
            <Box key={comment.id}>
              <MainCommentItem
                userId={userId}
                userName={userName}
                colorMode={colorMode}
                comment={comment}
                lyricId={lyricId}
                onDelete={handleCommentDelete}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box className="commentinput">
        <Input
          size={"sm"}
          bg={colorMode === "light" ? "white" : "rgba(26, 32, 44, 1)"}
          placeholder="Post Comment"
          boxShadow={"lg"}
          borderRadius={"7px"}
          onChange={(e) => setComment(e.target.value)}
        />
        {comment && (
          <Box className="ppepepe">
            <Button
              colorScheme="blue"
              size={"xs"}
              onClick={handlePost}
              isLoading={isLoading}
            >
              Post
            </Button>

            <IconButton
              ml="1"
              icon={<BsXCircle />}
              variant="outline"
              colorScheme="red"
              size={"xs"}
              // onClick={handleClear}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LyricComments;
