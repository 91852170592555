import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
  SimpleGrid,
  Icon,
  Image,
} from "@chakra-ui/react";
import "./MyFilesAndPermissions.css";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";
import "./PermissionsToFiles.css";
import { BsImage } from "react-icons/bs";
import { FaTable } from "react-icons/fa";
import avatarMock from "../../Svggs/avatarmock.svg";

const PermissionsToFiles = ({ colorMode, isMobileView, userId, userName }) => {
  const [activeTab, setActiveTab] = useState("assets");

  const [uploadedAccessList, setUploadedAccessList] = useState([]);
  const [uploadedFoldersAccessList, setUploadedFoldersAccessList] = useState(
    []
  );

  const [uploadedMutualSpacesAccessList, setUploadedMutualSpaceAccessList] =
    useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const fetchUploadedAssetsAccessOnlyList = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/access-only-assets/${userId}`
      );
      setUploadedAccessList(response.data.assets);
    } catch (error) {
      console.error("Failed to fetch uploaded folders access list:", error);
    }
  };

  useEffect(() => {
    fetchUploadedAssetsAccessOnlyList();
  }, [userId]);

  const fetchUploadedFoldersAccessList = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/access-only-folders/${userId}`
      );
      setUploadedFoldersAccessList(response.data.folders);
    } catch (error) {
      console.error("Failed to fetch uploaded folders access list:", error);
    }
  };

  useEffect(() => {
    fetchUploadedFoldersAccessList();
  }, [userId]);

  const fetchUploadedMutualSpaceAccessList = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/access-only-mutualspaces/${userId}`
      );
      setUploadedMutualSpaceAccessList(response.data.mutualSpaces);
    } catch (error) {
      console.error("Failed to fetch uploaded folders access list:", error);
    }
  };

  useEffect(() => {
    fetchUploadedMutualSpaceAccessList();
  }, [userId]);

  const renderAssetsOnlyContent = () => {
    return uploadedAccessList.map((table) => (
      <Box key={table._id}>
        {table.usersWithAccess.length === 0 ? (
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            bg={colorMode === "light" ? "gray.50" : "gray.800"}
            border={
              colorMode === "light"
                ? "0.5px solid rgba(187, 186, 187, 1)"
                : "0.5px solid rgba(128, 128, 128, 1)"
            }
          >
            <Text>No access granted yet.</Text>
            <Button colorScheme="blue" variant="solid" mt={3}>
              Request Access
            </Button>
          </Box>
        ) : (
          table.usersWithAccess.map((user) => (
            <Box
              bg={colorMode === "light" ? "gray.50" : "gray.800"}
              border={
                colorMode === "light"
                  ? "0.5px solid rgba(187, 186, 187, 1)"
                  : "0.5px solid rgba(128, 128, 128, 1)"
              }
              className="permissionsfileitem"
            >
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                w={"100%"}
              >
                <Flex gap={"2px"} flexDirection={"column"}>
                  <Icon className="fatableicon" as={FaTable} />
                  <Text fontWeight="bold">Table: {table.tableName}</Text>
                  <Text>{table.uploadDate}</Text>
                </Flex>
                <Flex alignItems={"center"} flexDirection={"column"}>
                  <Image src={avatarMock} className="directitemmock" />
                  <Text className="ownername" fontWeight="bold">
                    {table.user.name}
                  </Text>
                  <Text className="userowner">Owner</Text>
                </Flex>
              </Flex>
            </Box>
          ))
        )}
      </Box>
    ));
  };

  const renderFoldersOnlyContent = () => {
    return uploadedFoldersAccessList.length > 0 ? (
      uploadedFoldersAccessList.map((folder) => {
        const userHasAccess = folder.usersWithAccess.some(
          (userAccess) => userAccess.userId === userId
        );

        return (
          <Box
            key={folder._id}
            bg={colorMode === "light" ? "gray.50" : "gray.800"}
            border={
              colorMode === "light"
                ? "0.5px solid rgba(187, 186, 187, 1)"
                : "0.5px solid rgba(128, 128, 128, 1)"
            }
            className="permissionsfileitem"
            p={5}
            shadow="md"
            borderWidth="1px"
            mt={3}
          >
            <Text fontWeight="bold">Folder: {folder.folderName}</Text>
            {!userHasAccess && (
              <>
                <Text mt={2}>No access granted to this folder.</Text>
                <Button colorScheme="blue" variant="solid" mt={3}>
                  Request Access
                </Button>
              </>
            )}
          </Box>
        );
      })
    ) : (
      <Text p={5}>
        No folders available or no access granted to any folders.
      </Text>
    );
  };

  const renderMutualSpaceContent = () => {
    return uploadedMutualSpacesAccessList.map((table) => (
      <Box key={table._id}>
        {table.usersWithAccess.length === 0 ? (
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            bg={colorMode === "light" ? "gray.50" : "gray.800"}
            border={
              colorMode === "light"
                ? "0.5px solid rgba(187, 186, 187, 1)"
                : "0.5px solid rgba(128, 128, 128, 1)"
            }
          >
            <Text>No access granted yet.</Text>
            <Button colorScheme="blue" variant="solid" mt={3}>
              Request Access
            </Button>
          </Box>
        ) : (
          table.usersWithAccess.map((user) => (
            <Box
              bg={colorMode === "light" ? "gray.50" : "gray.800"}
              border={
                colorMode === "light"
                  ? "0.5px solid rgba(187, 186, 187, 1)"
                  : "0.5px solid rgba(128, 128, 128, 1)"
              }
              className="permissionsfileitem"
            >
              <Text fontWeight="bold">
                Mutualspace: {table.mutualSpaceName}
              </Text>
            </Box>
          ))
        )}
      </Box>
    ));
  };

  return (
    <Box className="MyFilesAndPermissions-main">
      <Flex w={"100%"} flexDirection={"column"} gap={"20px"}>
        <Flex
          w={"100%"}
          alignItems={"center"}
          justifyContent={"end"}
          gap={"10px"}
          marginBottom={"0px"}
          paddingBottom={"10px"}
          borderBottom={".5px solid grey"}
        >
          <Flex
            flexDirection={isMobileView ? "column" : "row"}
            w={"100%"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text mb={isMobileView ? 3 : 0}>
              See the files you have been granted access to
            </Text>
            <Flex gap={"10px"} alignItems={"center"}>
              <Button
                onClick={() => handleTabClick("assets")}
                colorScheme={activeTab === "assets" ? "blue" : "gray"}
              >
                Assets
              </Button>
              <Button
                onClick={() => handleTabClick("folders")}
                colorScheme={activeTab === "folders" ? "blue" : "gray"}
              >
                Folders
              </Button>
              <Button
                onClick={() => handleTabClick("mutualSpaces")}
                colorScheme={activeTab === "mutualSpaces" ? "blue" : "gray"}
              >
                MutualSpaces
              </Button>
            </Flex>
          </Flex>
        </Flex>

        {activeTab === "assets" && (
          <>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={3} p={0}>
              {renderAssetsOnlyContent()}
            </SimpleGrid>
          </>
        )}

        {activeTab === "folders" && (
          <>
            {uploadedFoldersAccessList.map((folder) => (
              <>
                {!folder.usersWithAccess.some(
                  (user) => user.userId === userId
                ) && (
                  <Box
                    p={5}
                    shadow="md"
                    borderWidth="1px"
                    bg={colorMode === "light" ? "gray.50" : "gray.800"}
                    border={
                      colorMode === "light"
                        ? "0.5px solid rgba(187, 186, 187, 1)"
                        : "0.5px solid rgba(128, 128, 128, 1)"
                    }
                  >
                    <Text>No access granted yet.</Text>
                    <Button colorScheme="blue" variant="solid" mt={3}>
                      Request Access
                    </Button>
                  </Box>
                )}
              </>
            ))}

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3} p={0}>
              {renderFoldersOnlyContent()}
            </SimpleGrid>
          </>
        )}

        {activeTab === "mutualSpaces" && (
          <>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3} p={0}>
              {renderMutualSpaceContent()}
            </SimpleGrid>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default PermissionsToFiles;
