import React, { useState } from "react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { Input, Box, Flex, Text, Button, useToast } from "@chakra-ui/react";
import "./AddChannelMessages.css";

const AddChannelMessages = ({
  userId,
  userName,
  setIsAddChannelModalOpen,
  fetchChannels,
}) => {
  const [channelName, setChannelName] = useState("");
  const toast = useToast(); // For showing feedback

  const handleCreateChannel = async () => {
    if (!channelName.trim()) {
      toast({
        title: "Channel name required",
        description: "Please enter a name for the channel.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(`${API_ENDPOINT}api/channels/add`, {
        channelName,
        userId,
        name: userName,
      });

      toast({
        title: "Channel Created",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Reset channel name input after successful creation
      setChannelName("");
      setIsAddChannelModalOpen(false);
      fetchChannels();
      // Optionally, navigate to the new channel's view or refresh the channel list here
    } catch (error) {
      console.error("Error creating channel:", error);
      toast({
        title: "Error",
        description: "Failed to create channel.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Flex flexDirection={"column"}>
        <Text mb={1} className="addchannelnametxt">
          Add Channel Name
        </Text>
        <Input
          value={channelName}
          onChange={(e) => setChannelName(e.target.value)}
        />
      </Flex>
      <Flex alignItems={"center"} gap={"10px"} mt={4} w={"100%"}>
        <Button
          onClick={() => setIsAddChannelModalOpen(false)}
          variant={"outline"}
          colorScheme="red"
          w={"100%"}
        >
          Cancel
        </Button>
        <Button onClick={handleCreateChannel} colorScheme="cyan" w={"100%"}>
          Create Channel
        </Button>
      </Flex>
    </Box>
  );
};

export default AddChannelMessages;
