import React from "react";
import "./CurrentBeatLyricItem.css";
import { Box, Flex, Text, Tag, Avatar, TagLabel } from "@chakra-ui/react";
import { PiNotepadThin } from "react-icons/pi";

const CurrentBeatLyricItem = ({ lyric, colorMode }) => {
  return (
    <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
      <Tag p={1} w={"100%"} size="sm" colorScheme="yellow" borderRadius="full">
        <Avatar
          bg={"none"}
          color={colorMode === "light" ? "gray" : "yellow"}
          size="xs"
          ml={-1}
          icon={<PiNotepadThin fontSize={"20px"} />}
        />
        <TagLabel className="taggedlabel">Lyrics</TagLabel>
      </Tag>
    </Flex>
  );
};

export default CurrentBeatLyricItem;
