import React, { createContext, useState, useEffect } from "react";

export const MusicPlayerContext = createContext();

export const MusicPlayerProvider = ({ children }) => {
  const [currentTrack, setCurrentTrack] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const seekTrack = (time) => {
    if (currentTrack) {
      currentTrack.currentTime = time;
    }
  };

  const playTrack = (trackUrl) => {
    let audio = currentTrack;

    if (!audio || audio.src !== trackUrl) {
      if (audio) {
        audio.pause();
      }
      audio = new Audio(trackUrl);
      setCurrentTrack(audio);
    }

    if (audio.paused) {
      audio
        .play()
        .then(() => {})
        .catch((error) => {
          console.error("Failed to play track:", error);
        });
    }
  };

  useEffect(() => {
    const updateTime = () => {
      setCurrentTime(currentTrack?.currentTime || 0);
      setDuration(currentTrack?.duration || 0);
    };

    if (currentTrack) {
      currentTrack.addEventListener("timeupdate", updateTime);
    }

    return () => {
      if (currentTrack) {
        currentTrack.removeEventListener("timeupdate", updateTime);
      }
    };
  }, [currentTrack]);

  const stopTrack = () => {
    if (currentTrack) {
      currentTrack.pause();
      currentTrack.currentTime = 0;
      setCurrentTrack(null);
    }
  };

  return (
    <MusicPlayerContext.Provider
      value={{
        currentTrack,
        playTrack,
        stopTrack,
        currentTime,
        duration,
        seekTrack,
      }}
    >
      {children}
    </MusicPlayerContext.Provider>
  );
};
