import { createContext, useState, useContext } from "react";

const CurrentReleaseContext = createContext();

export const useCurrentRelease = () => useContext(CurrentReleaseContext);

export const CurrentReleaseProvider = ({ children }) => {
  const [currentRelease, setCurrentRelease] = useState(null);

  return (
    <CurrentReleaseContext.Provider
      value={{ currentRelease, setCurrentRelease }}
    >
      {children}
    </CurrentReleaseContext.Provider>
  );
};
