import React, { useContext, useEffect, useState } from "react";
import { IconButton, Flex, Tag, TagLabel } from "@chakra-ui/react";
import { BsPlayCircle, BsPauseCircle } from "react-icons/bs";
import { MusicPlayerContext } from "../../../Context/MusicPlayerContext";
import "./LinkedItemPlayer.css";

const LinkedItemPlayer = ({ beat, colorMode }) => {
  const { playTrack, stopTrack, currentTrack, seekTrack } =
    useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [localDuration, setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack && currentTrack.src === beat.fileUrl && !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(beat.fileUrl);
    }
  };

  return (
    <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
      <Tag size="sm" colorScheme="blue" borderRadius="full">
        {isCurrentBeatPlaying ? (
          <>
            <IconButton
              ml={-1}
              mr={2}
              size={"xs"}
              onClick={(event) => {
                togglePlayPause();
              }}
              icon={<BsPauseCircle className="main-player-button" />}
            />
          </>
        ) : (
          <>
            <IconButton
              ml={-1}
              mr={2}
              size={"xs"}
              onClick={(event) => {
                togglePlayPause();
              }}
              icon={<BsPlayCircle className="main-player-button" />}
            />
          </>
        )}
        <TagLabel className="artworktag">{beat.title}</TagLabel>
      </Tag>
    </Flex>
  );
};

export default LinkedItemPlayer;
