import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  Flex,
  Input,
  Icon,
  Tooltip,
  useToast,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Center,
  SimpleGrid,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import axios from "axios";
import { BsPlus, BsSortAlphaDown } from "react-icons/bs";
import { API_ENDPOINT } from "../../../../Config/config";
import PlanningTaskItem from "../../../PlanningTaskItem/PlanningTaskItem";
import "./TaskPlanning.css";
import NoPlanningTables from "../../../EmptyStates/NoPlanningTables";
import { SearchIcon } from "@chakra-ui/icons";

const TaskPlanning = ({ colorMode, userId, userName, guestToken }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableName, setTableName] = useState("");
  const toast = useToast();
  const [tables, setTables] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const createTable = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_ENDPOINT}api/tasktable/create`, {
        tablename: tableName,
        items: [],
        userId: userId,
        name: userName,
      });
      toast({
        title: "Table created",
        description: "Your new task table has been successfully created.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setTableName("");
      onClose();
      setIsLoading(false);
      fetchTables();
    } catch (error) {
      console.error("Error creating task table:", error);
      toast({
        title: "Error",
        description: "There was an error creating your task table.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const fetchTables = async () => {
    setIsLoading(true);
    const url = guestToken
      ? `${API_ENDPOINT}api/tasktables/user/${userId}?mock=true`
      : `${API_ENDPOINT}api/tasktables/user/${userId}`;

    try {
      const response = await axios.get(url);
      setTables(response.data.taskTables || []);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching task tables:", error);
      toast({
        title: "Error fetching task tables",
        description:
          error.response?.data?.message ||
          "An error occurred while fetching task tables.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTables();
  }, [userId, guestToken]);

  const inputBg = useColorModeValue("rgba(247, 250, 252, 0.43)", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const boxShadow = useColorModeValue("sm", "dark-lg");

  return (
    <Box className="main-TaskPlanning">
      <Flex w={"100%"}>
        {tables.length > 0 && (
          <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
            <Tooltip placement="top" borderRadius={"7px"} label={"New Table"}>
              <Button
                bg={
                  colorMode === "light"
                    ? "rgba(177, 197, 246, 1)"
                    : "rgba(102, 80, 245, 1)"
                }
                size={"sm"}
                onClick={onOpen}
              >
                <Flex alignItems={"center"} gap={"3px"}>
                  <Text>New </Text>
                  <Icon as={BsPlus} />
                </Flex>
              </Button>
            </Tooltip>
            <Tooltip placement="top" borderRadius={"7px"} label={"Sort"}>
              <Button size={"sm"}>
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={BsSortAlphaDown} />
                  <Text>Sort</Text>
                </Flex>
              </Button>
            </Tooltip>
            <Flex w={"100%"}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={SearchIcon} color="gray.500" />}
                />
                <Input
                  size={"sm"}
                  borderRadius={"7px"}
                  w={"100%"}
                  placeholder="Search Tables"
                  bg={inputBg}
                  borderColor={borderColor}
                  _hover={{
                    bg: hoverBg,
                    borderColor: "gray.400",
                  }}
                  _focus={{
                    boxShadow: `${boxShadow}`,
                    borderColor: "gray.400",
                  }}
                />
              </InputGroup>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex w={"100%"}>
        {isLoading ? (
          <Center mt={2}>
            <Spinner />
          </Center>
        ) : tables.length > 0 ? (
          <SimpleGrid w={"100%"} columns={{ base: 1, md: 1 }} spacing={3}>
            {tables.map((table) => (
              <PlanningTaskItem
                tableId={table._id}
                table={table}
                colorMode={colorMode}
                fetchTables={fetchTables}
                userId={userId}
              />
            ))}
          </SimpleGrid>
        ) : (
          <Box w={"100%"}>
            <NoPlanningTables colorMode={colorMode} onOpen={onOpen} />
          </Box>
        )}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Create New Table</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Table Name"
              value={tableName}
              onChange={(e) => setTableName(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Flex alignItems={"center"} gap={"10px"} w={"100%"}>
              <Button w={"100%"} variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                w={"100%"}
                colorScheme="blue"
                onClick={createTable}
                isLoading={isLoading}
              >
                Create Table
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TaskPlanning;
