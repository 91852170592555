import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Avatar,
  Flex,
  IconButton,
  Input,
  Icon,
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FiDownload } from "react-icons/fi";
import "./MutualSpaceFolder.css";
import { DownloadIcon } from "@chakra-ui/icons";
import { BsPlusLg, BsShare, BsTrash } from "react-icons/bs";
import newFolderIcon from "../../Svggs/newiconsfolder.svg";

const MutualSpaceFolder = ({ colorMode }) => {
  const { mutualSpaceId, folderId } = useParams();
  const [folder, setFolder] = useState(null);
  const [usersWithAccess, setUsersWithAccess] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false); // New state for delete confirmation modal
  const [fileToDelete, setFileToDelete] = useState(null); // State to keep track of the file to be deleted
  const [isFileDeleteModalOpen, setFileDeleteModalOpen] = useState(false); // State for file delete confirmation modal

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    const fetchMutualSpaceData = async () => {
      try {
        const mutualSpaceResponse = await axios.get(
          `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}`
        );
        setUsersWithAccess(mutualSpaceResponse.data.data.usersWithAccess);
      } catch (error) {
        console.error("Error fetching mutual space data:", error);
        // Handle error (e.g., show an error message)
      }
    };

    const fetchFolderData = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/folder/${folderId}`
        );
        setFolder(response.data.folder);
      } catch (error) {
        console.error("Error fetching folder data:", error);
        // Handle error (e.g., show an error message)
      }
    };

    fetchMutualSpaceData();
    fetchFolderData();
  }, [mutualSpaceId, folderId]);

  const [theFolderName, setTheFolderName] = useState(folder?.folderName);

  useEffect(() => {
    setTheFolderName(folder?.folderName);
  }, [folder]);

  const formatDate = (uploadDate) => {
    const uploadedAt = new Date(uploadDate);
    const now = new Date();
    const timeDiff = now.getTime() - uploadedAt.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    if (daysDiff < 1) {
      const hoursDiff = timeDiff / (1000 * 3600);
      if (hoursDiff < 1) {
        const minutesDiff = timeDiff / (1000 * 60);
        return `${Math.floor(minutesDiff)} minutes ago`;
      }
      return `${Math.floor(hoursDiff)} hours ago`;
    } else if (daysDiff < 10) {
      return `${Math.floor(daysDiff)} days ago`;
    } else {
      return `on ${uploadedAt.toLocaleString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      })} @ ${uploadedAt.toLocaleTimeString()}`;
    }
  };

  const uploadFile = async () => {
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile); // Ensure 'file' matches the key expected by multer in the backend

    try {
      // Make sure to update the URL to match the backend route
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/folder/${folderId}/addfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        alert("File uploaded successfully!");
        onClose(); // Close the modal
        // Here you might want to refresh the folder's content to show the newly uploaded file
      } else {
        alert("Failed to upload file.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file.");
    }
  };

  const downloadFolder = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/folder/${folderId}/download`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "download.zip"; // Default name in case parsing fails
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      link.setAttribute("download", fileName); // Set the downloaded file name
      document.body.appendChild(link);
      link.click(); // Start download
      link.remove(); // Clean up
      window.URL.revokeObjectURL(url); // Free up memory
    } catch (error) {
      console.error("Error downloading folder:", error);
      alert("Failed to download folder.");
    }
  };

  const deleteFolder = async () => {
    try {
      await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/folder/${folderId}`
      );
      alert("Folder deleted successfully!");
      setDeleteModalOpen(false); // Close the modal after deletion
      // Redirect or update UI as needed
    } catch (error) {
      console.error("Error deleting folder:", error);
      alert("Failed to delete folder.");
    }
  };

  const deleteFile = async () => {
    if (!fileToDelete) return;
    try {
      // URL encode the fileKey to ensure slashes and other characters are properly interpreted
      const encodedFileKey = encodeURIComponent(fileToDelete.fileKey);
      await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/folder/${folderId}/file/${encodedFileKey}`
      );
      alert("File deleted successfully!");
      setFileDeleteModalOpen(false);
      // Optionally, refresh folder data here to reflect the deleted file
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Failed to delete file.");
    }
  };

  return (
    <Box
      className={`Folderpage-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        className={`for-top-folders ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        boxShadow={"md"}
        w={"100%"}
      >
        <Flex
          w={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}
          p={4}
        >
          <Flex flexDirection={"column"} gap={"10px"}>
            <Flex justifyContent={"start"} alignItems={"end"} gap={"10px"}>
              <Box className="full-container">
                <Image src={newFolderIcon} />
              </Box>
              <Box>
                <Text>{folder?.files.length} Files</Text>
              </Box>
            </Flex>
            <Input
              borderRadius={"7px"}
              boxShadow={"md"}
              value={theFolderName}
            />
          </Flex>
          <Box>
            <Flex alignItems={"center"} gap={"10px"}>
              <Button
                colorScheme="red"
                size={"sm"}
                onClick={() => setDeleteModalOpen(true)}
              >
                Delete
              </Button>
              <Button variant={"outline"} size={"sm"} onClick={downloadFolder}>
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={DownloadIcon} />
                  <Text>Download</Text>
                </Flex>
              </Button>
              <Button variant={"outline"} size={"sm"}>
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={BsShare} />
                  <Text>Share</Text>
                </Flex>
              </Button>
              <Button variant={"outline"} size={"sm"} onClick={onOpen}>
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={BsPlusLg} />
                  <Text>Add File</Text>
                </Flex>
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Box
        boxShadow={"md"}
        className={`tableheights ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th
                className={`folder-table-header ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
                position="sticky"
                top="0"
                bg={colorMode === "light" ? "white" : "rgba(28, 33, 57, 1)"}
                zIndex="sticky"
              >
                Name
              </Th>
              <Th
                className={`folder-table-header ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
                textAlign={"center"}
                position="sticky"
                top="0"
                bg={colorMode === "light" ? "white" : "rgba(28, 33, 57, 1)"}
                zIndex="sticky"
              >
                Who can access
              </Th>
              <Th
                className={`folder-table-header ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
                textAlign={"center"}
                position="sticky"
                top="0"
                bg={colorMode === "light" ? "white" : "rgba(28, 33, 57, 1)"}
                zIndex="sticky"
              >
                Modified
              </Th>
              <Th
                textAlign={"center"}
                position="sticky"
                top="0"
                bg={colorMode === "light" ? "white" : "rgba(28, 33, 57, 1)"}
                zIndex="sticky"
              ></Th>
            </Tr>
          </Thead>
          <Tbody>
            {folder?.files.map((file) => (
              <Tr
                _hover={{ backgroundColor: "rgba(208, 212, 228, 0.32)" }}
                key={file?.fileName}
              >
                <Td>
                  <Text maxW={"200px"} isTruncated className="table-data">
                    {file?.fileName}
                  </Text>
                </Td>
                <Td>
                  <Flex justifyContent={"center"} alignItems={"center"}>
                    {folder?.user.concat(usersWithAccess).map((user) => (
                      <Avatar key={user?.userId} size="xs" name={user?.name} />
                    ))}
                  </Flex>
                </Td>
                <Td isNumeric>
                  <Flex justifyContent={"center"} alignItems={"center"}>
                    <Text className="table-data">
                      {formatDate(file?.uploadedAt)}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"10px"}
                  >
                    <IconButton
                      size={"xs"}
                      aria-label="Download file"
                      icon={<FiDownload />}
                      onClick={() => window.open(file.signedUrl, "_blank")}
                      colorScheme="blue"
                      variant={"outline"}
                    />
                    <IconButton
                      size={"xs"}
                      aria-label="Delete file"
                      icon={<BsTrash />}
                      onClick={() => {
                        setFileToDelete({
                          fileName: file.fileName,
                          fileKey: file.fileKey,
                        });
                        setFileDeleteModalOpen(true);
                      }}
                      colorScheme="red"
                      variant={"outline"}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload File</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input type="file" onChange={handleFileChange} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={uploadFile}>
              Upload
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this folder?</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={deleteFolder}>
              Delete Folder
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isFileDeleteModalOpen}
        onClose={() => setFileDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete File</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this file?</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              onClick={() => setFileDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={deleteFile}>
              Delete File
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MutualSpaceFolder;
