import React, { useState } from "react";
import {
  Box,
  Button,
  Textarea,
  useToast,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../../Config/config";

const MainLyricsAssetUpload = ({
  colorMode,
  userId,
  mutualSpaceId,
  board,
  boardId,
  tableId,
  userName,
  setIsUploadModalOpen,
  fetchLyricData,
}) => {
  const [lyricsText, setLyricsText] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const toast = useToast(); // Initialize toast
  const [isTextAreaNotEmpty, setIsTextAreaNotEmpty] = useState(false);

  const handleTextChange = (event) => {
    setLyricsText(event.target.value);
    setIsTextAreaNotEmpty(event.target.value.length > 0);
  };

  const handleClearClick = () => {
    setLyricsText("");
    setIsTextAreaNotEmpty(false);
  };

  const title =
    lyricsText.length > 15 ? lyricsText.substring(0, 15) + "..." : lyricsText;

  const handleSaveClick = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/lyric`,
        {
          userId,
          name: userName,
          title, // Use the generated title
          lyricsText,
        }
      );
      toast({
        title: "Lyrics uploaded!",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setIsLoading(false);
      handleClearClick();
      setIsUploadModalOpen(false);
      fetchLyricData();
    } catch (error) {
      toast({
        title: "Failed to save lyric",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box>
        <Textarea
          placeholder="Copy and Paste Lyrics Here"
          value={lyricsText}
          onChange={handleTextChange}
        />
      </Box>
      <Flex alignItems={"cenetr"} gap={"10px"} mt={2}>
        <Button
          w={"30%"}
          colorScheme="red"
          size="sm"
          onClick={handleClearClick}
        >
          Clear
        </Button>
        <Button
          w={"100%"}
          colorScheme="green"
          isLoading={isLoading}
          disabled={!isTextAreaNotEmpty}
          size="sm"
          spinner={<Spinner size="sm" />}
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </Flex>
    </Box>
  );
};

export default MainLyricsAssetUpload;
