import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Text,
  Tooltip,
  SimpleGrid,
  Spinner,
  Center,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Modal,
  ModalOverlay,
} from "@chakra-ui/react";
import { BsPlusLg, BsSpotify } from "react-icons/bs";
import "./AddNewReleaseModal.css";
import SearchSpotify from "./SearchSpotify";
import ReleaseBuilding from "./ReleaseBuilding";
import { ArrowBackIcon } from "@chakra-ui/icons";

const AddNewReleaseModal = ({
  userId,
  userName,
  setIsNewModalOpen,
  fetchReleases,
  colorMode,
}) => {
  const [activeTab, setActiveTab] = useState(null);

  return (
    <Flex className="AddNewReleaseModal-main" flexDirection="column" w="100%">
      {activeTab === null ? (
        <Flex
          w={"100%"}
          mb={4}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Button
            fontSize={"13px"}
            w={"48%"}
            onClick={() => setActiveTab("spotify")}
          >
            <Flex gap={"5px"} alignItems={"center"}>
              <Icon color={"green.400"} as={BsSpotify} />
              <Text>Upload From Spotify</Text>
            </Flex>
          </Button>
          <Button w={"48%"} onClick={() => setActiveTab("build")}>
            Build Release
          </Button>
        </Flex>
      ) : (
        <>
          {activeTab === "spotify" && (
            <Flex w={"100%"} mb={4} flexDirection="column">
              <Flex gap={"20px"} alignItems={"center"}>
                <Button
                  fontSize={"13px"}
                  onClick={() => setActiveTab(null)}
                  variant="outline"
                  leftIcon={<ArrowBackIcon />}
                >
                  Back
                </Button>
                <Button
                  fontSize={"13px"}
                  w={"100%"}
                  onClick={() => setActiveTab(null)}
                >
                  <Flex gap={"5px"} alignItems={"center"}>
                    <Icon color={"green.400"} as={BsSpotify} />
                    <Text>Upload From Spotify</Text>
                  </Flex>
                </Button>
              </Flex>
              <SearchSpotify
                userName={userName}
                userId={userId}
                fetchReleases={fetchReleases}
                colorMode={colorMode}
                setIsNewModalOpen={setIsNewModalOpen}
              />
            </Flex>
          )}
          {activeTab === "build" && (
            <Flex w={"100%"} mb={4} flexDirection="column">
              <Flex gap={"20px"} alignItems={"center"}>
                <Button
                  fontSize={"13px"}
                  onClick={() => setActiveTab(null)}
                  variant="outline"
                  leftIcon={<ArrowBackIcon />}
                >
                  Back
                </Button>
                <Button w={"100%"} onClick={() => setActiveTab(null)}>
                  Build Release
                </Button>
              </Flex>

              <ReleaseBuilding
                setIsNewModalOpen={setIsNewModalOpen}
                userName={userName}
                userId={userId}
                fetchReleases={fetchReleases}
              />
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};

export default AddNewReleaseModal;
