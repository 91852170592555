import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Badge,
} from "@chakra-ui/react";
import {
  BsPlayCircle,
  BsSendArrowDown,
  BsCheckAll,
  BsPauseCircle,
  BsCheck2All,
  BsHeadphones,
} from "react-icons/bs";
import { CheckCircleIcon, DownloadIcon, LinkIcon } from "@chakra-ui/icons";
import { FaChevronRight, FaTrashCan } from "react-icons/fa6";
import { API_ENDPOINT } from "../../../Config/config";
import { TbMusicShare } from "react-icons/tb";
import { MdDeleteForever, MdOutlineLyrics } from "react-icons/md";
import { MusicPlayerContext } from "../../../Context/MusicPlayerContext";
import { useLinkRecording } from "../../../Context/LinkRecordingContext";
import { useRecordingsPanel } from "../../../Context/RecordingsPanelContext";
import { useCurrentRecording } from "../../../Context/CurrentRecordingContext";
import { useAddRecordingToRelease } from "../../../Context/AddRecordingToReleaseContext";

const MainRecordingPlayer = ({
  colorMode,
  recording,
  fetchRecordings,
  guestToken,
  openDetailsPanelSettings,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pendingStatus, setPendingStatus] = useState("");
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);
  const closeStatusChangeModal = () => setIsStatusChangeModalOpen(false);
  const [selectedStatus, setSelectedStatus] = useState(
    recording.status || "Set Status"
  );

  const { playTrack, stopTrack, currentTrack, seekTrack } =
    useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [localDuration, setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack &&
    currentTrack.src === recording.fileUrl &&
    !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(recording.fileUrl);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${API_ENDPOINT}api/recording/${recording._id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        onClose();
        fetchRecordings();
      } else {
        console.error("Failed to delete beat");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const updateStatus = async () => {
    try {
      const response = await fetch(
        `${API_ENDPOINT}api/recording/${recording._id}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: pendingStatus }),
        }
      );
      if (response.ok) {
        setSelectedStatus(pendingStatus);
        closeStatusChangeModal();
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getMenuButtonBgColor = () => {
    switch (selectedStatus) {
      case "Ready For Mix":
        return "rgba(249, 185, 89, .47)"; // Adjust the color as per your theme
      case "Mixing":
        return "rgba(0, 144, 255, 0.4)"; // Adjust the color as per your theme
      case "Release Ready":
        return "rgba(90, 238, 158, 0.47)"; // Adjust the color as per your theme
      default:
        return ""; // Default color or no color
    }
  };

  const handleMenuItemClick = (option) => {
    setPendingStatus(option);
    setIsStatusChangeModalOpen(true); // Open the status change modal
  };

  const handleInnerClick = (event) => {
    event.stopPropagation();
  };

  const { setLinkRecording } = useLinkRecording();
  const { setRecordingsPanel } = useRecordingsPanel();
  const { setCurrentRecording } = useCurrentRecording();
  const { setAddRecording } = useAddRecordingToRelease();

  const AddRecordingToRelease = (event) => {
    event.stopPropagation();

    setAddRecording("AddRecordingToRelease");
    setLinkRecording(null);
    setRecordingsPanel(null);
  };

  const handleRecordingClick = (event) => {
    event.stopPropagation();
    setRecordingsPanel("Recordings");
    openDetailsPanelSettings();
    setCurrentRecording(recording);
    setLinkRecording(null);
  };

  const toLinkRecording = (event) => {
    event.stopPropagation();

    setLinkRecording("LinkRecording");
    setRecordingsPanel(null);
    setAddRecording(null);
  };

  return (
    <Box
      onClick={handleRecordingClick}
      className={`beat-player-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      boxShadow="md"
    >
      <Box className="main-player-start">
        <Flex alignItems={"center"} gap={"10px"}>
          <Box>
            {isCurrentBeatPlaying ? (
              <>
                <IconButton
                  onClick={(event) => {
                    handleInnerClick(event);
                    togglePlayPause(); // Call the original saveProducerName function
                  }}
                  icon={<BsPauseCircle className="main-player-button" />}
                />
              </>
            ) : (
              <>
                <IconButton
                  onClick={(event) => {
                    handleInnerClick(event);
                    togglePlayPause(); // Call the original saveProducerName function
                  }}
                  icon={<BsPlayCircle className="main-player-button" />}
                />
              </>
            )}
          </Box>
          <Box>
            <Flex flexDirection={"column"}>
              <Text className="player-name">{recording.title}</Text>
              <Text className="player-producer">
                {recording.artist || "Artist"}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box ml={4} className="main-player-slider">
        <Flex
          gap={"10px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text className="slider-time">{formatTime(localCurrentTime)}</Text>
          <Slider
            aria-label="track-progress"
            value={isCurrentBeatPlaying ? localCurrentTime : 0}
            max={isCurrentBeatPlaying ? localDuration : 0}
            onChange={(val) => isCurrentBeatPlaying && seekTrack(val)}
            focusThumbOnChange={false}
            isDisabled={!isCurrentBeatPlaying}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Text className="slider-time" ml={1}>
            {formatTime(
              isCurrentBeatPlaying ? localDuration - localCurrentTime : 0
            )}
          </Text>
        </Flex>
      </Box>
      <Box className="main-player-middle">
        <Flex alignItems={"center"} gap={"10px"}>
          {!recording.release && (
            <>
              <Menu
                border={
                  colorMode === "light"
                    ? "2px solid rgba(153, 178, 198, 0.23)"
                    : "2px solid rgba(43, 46, 54, 1)"
                }
                bg={colorMode === "light" ? "white" : "black"}
              >
                {({ isOpen }) => (
                  <>
                    <Flex alignItems="center" gap={2}>
                      <Tooltip
                        placement="bottom"
                        borderRadius={"7px"}
                        label={"Set Status"}
                      >
                        <MenuButton
                          onClick={(event) => event.stopPropagation()} // Stop event propagation
                          backgroundColor={getMenuButtonBgColor()} // Apply conditional background color
                          size={"xs"}
                          as={Button}
                          rightIcon={
                            <FaChevronRight
                              style={{
                                transition: "transform 0.2s",
                                transform: isOpen ? "rotate(90deg)" : "none",
                              }}
                            />
                          }
                        >
                          {selectedStatus}{" "}
                        </MenuButton>
                      </Tooltip>
                    </Flex>
                    <MenuList
                      p={2}
                      bg={colorMode === "light" ? "white" : "black"}
                      zIndex={"9999"}
                    >
                      <MenuItem
                        onClick={() => handleMenuItemClick("Ready For Mix")}
                        p={2}
                        sx={{
                          "&:hover": {
                            bg:
                              colorMode === "light"
                                ? "rgba(249, 185, 89, .23)"
                                : "rgba(249, 185, 89, .23)",
                          },
                        }}
                        bg={colorMode === "light" ? "white" : "black"}
                        className="blank"
                      >
                        <Flex alignItems={"center"} gap={"5px"}>
                          <Icon as={BsCheckAll} />
                          <Text className="menu-text">Ready For Mix</Text>
                        </Flex>
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleMenuItemClick("Mixing")}
                        p={2}
                        sx={{
                          "&:hover": {
                            bg:
                              colorMode === "light"
                                ? "rgba(90, 238, 158, 0.23)"
                                : "rgba(90, 238, 158, 0.23)",
                          },
                        }}
                        className="blank"
                        bg={colorMode === "light" ? "white" : "black"}
                      >
                        <Flex alignItems={"center"} gap={"5px"}>
                          <Icon as={TbMusicShare} />
                          <Text className="menu-text">Mixing</Text>
                        </Flex>
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleMenuItemClick("Release Ready")}
                        p={2}
                        sx={{
                          "&:hover": {
                            bg:
                              colorMode === "light"
                                ? "rgba(90, 238, 158, 0.23)"
                                : "rgba(90, 238, 158, 0.23)",
                          },
                        }}
                        className="blank"
                        bg={colorMode === "light" ? "white" : "black"}
                      >
                        <Flex alignItems={"center"} gap={"5px"}>
                          <Icon as={CheckCircleIcon} />
                          <Text className="menu-text">Release Ready</Text>
                        </Flex>
                      </MenuItem>
                    </MenuList>
                  </>
                )}
              </Menu>
            </>
          )}
          <Flex alignItems={"center"} gap={"5px"}>
            {recording.lyric && (
              <Badge
                borderRadius={"4px"}
                colorScheme="yellow"
                variant="subtle"
                className="richmod"
              >
                <Flex alignItems={"center"} gap={"3px"}>
                  <Icon as={MdOutlineLyrics} />
                  <Text fontSize={"9px"}>Lyrics</Text>
                </Flex>
              </Badge>
            )}
            {recording.beat && (
              <Badge
                borderRadius={"4px"}
                colorScheme="red"
                variant="subtle"
                className="richmod"
              >
                <Flex alignItems={"center"} gap={"3px"}>
                  <Icon as={BsHeadphones} />
                  <Text fontSize={"9px"}>Beat</Text>
                </Flex>
              </Badge>
            )}
            {recording.release && (
              <Badge
                borderRadius={"4px"}
                colorScheme="green"
                variant="subtle"
                className="richmod"
              >
                <Flex alignItems={"center"} gap={"3px"}>
                  <Icon as={BsCheck2All} />
                  <Text fontSize={"9px"}>Released</Text>
                </Flex>
              </Badge>
            )}
          </Flex>
          {!(recording.lyric && recording.beat && recording.release) && (
            <Tooltip
              placement="bottom"
              borderRadius={"7px"}
              label={"Link Recording"}
            >
              <Button onClick={toLinkRecording} variant={"outline"} size={"xs"}>
                <Icon fontSize={"10px"} mr={1.5} as={LinkIcon} />
                Link
              </Button>
            </Tooltip>
          )}

          {!recording.release && (
            <>
              <Tooltip
                placement="bottom"
                borderRadius={"7px"}
                label={"Add Recording to Release"}
              >
                <Button
                  onClick={AddRecordingToRelease}
                  colorScheme="green"
                  variant={"outline"}
                  size={"xs"}
                >
                  <Flex alignItems={"center"} gap={"5px"}>
                    <Text className="addtoreleasebtn">Add To Release</Text>
                  </Flex>
                </Button>
              </Tooltip>
            </>
          )}
        </Flex>
      </Box>
      <Box className="main-player-end">
        <Flex alignItems={"center"} gap={"10px"}>
          <Tooltip
            placement="bottom"
            borderRadius={"7px"}
            label={"Send in Message"}
          >
            <IconButton
              variant={"outline"}
              icon={<BsSendArrowDown />}
              size={"xs"}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            borderRadius={"7px"}
            label={"Download Recording"}
          >
            <IconButton
              variant={"outline"}
              icon={<DownloadIcon />}
              size={"xs"}
            />
          </Tooltip>
          {!guestToken && (
            <>
              <Tooltip
                placement="bottom"
                borderRadius={"7px"}
                label={"Delete Recording"}
              >
                <IconButton
                  onClick={(event) => {
                    handleInnerClick(event);
                    onOpen(); // Call the original saveProducerName function
                  }}
                  variant={"outline"}
                  icon={<FaTrashCan />}
                  size={"xs"}
                  colorScheme="red"
                />
              </Tooltip>
            </>
          )}
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={MdDeleteForever} />
              <Text>Delete Recording</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete the beat {recording.title}?
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
              <Button w={"100%"} colorScheme="gray" onClick={onClose}>
                Cancel
              </Button>
              <Button w={"100%"} colorScheme="red" onClick={handleDelete}>
                Delete Recording
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isStatusChangeModalOpen} onClose={closeStatusChangeModal}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Update Status</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Update {recording.title} to {pendingStatus}?
          </ModalBody>
          <ModalFooter>
            <Button
              w={"100%"}
              colorScheme="red"
              mr={3}
              onClick={closeStatusChangeModal}
            >
              Cancel
            </Button>
            <Button w={"100%"} colorScheme="green" onClick={updateStatus}>
              Update Status
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MainRecordingPlayer;
