import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Text,
  Avatar,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
  useColorModeValue,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import "./MutualBoardTable.css";
import { FiUser } from "react-icons/fi";
import {
  BsFolder2,
  BsHeadphones,
  BsImage,
  BsKanban,
  BsPlusLg,
  BsTable,
  BsTrash,
} from "react-icons/bs";
import { CalendarIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { IoDocument } from "react-icons/io5";
import MainTaskTable from "./MainTaskTable/MainTaskTable";
import BoardTableUpload from "./BoardTableUpload";
import { MdOutlineLyrics } from "react-icons/md";
import { BiMicrophone } from "react-icons/bi";
import MainBeatAssetTable from "./MainAssetTables/MainBeatAssetTable";
import MainLyricAssetsTable from "./MainAssetTables/MainLyricAssetsTable";
import MainRecordingAssetTable from "./MainAssetTables/MainRecordingAssetTable";
import MainArtworkAssetTable from "./MainAssetTables/MainArtworkAssetTable";
import { useMutualBoard } from "../../../Context/MutualBoardContext";
import MutualBoardFolder from "./Folders/MutualBoardFolder";
import { FaTimeline } from "react-icons/fa6";
import UserInvite from "../../Invite/UserInvite";
import TaskButton from "./BoardTableButtons/TaskButton";
import AssetTableButtons from "./BoardTableButtons/AssetTableButtons";
import FolderButton from "./BoardTableButtons/FolderButton";
import { useBeatPanel } from "../../../Context/BeatsPanelContext";
import { useLyricsPanel } from "../../../Context/LyricsPanelContext";
import { useRecordingsPanel } from "../../../Context/RecordingsPanelContext";
import { useArtworksPanel } from "../../../Context/ArtworkPanelContext";
import { useCurrentBeat } from "../../../Context/CurrentBeatContext";
import { useCurrentLyric } from "../../../Context/CurrentLyricContext";
import { useCurrentRecording } from "../../../Context/CurrentRecordingContext";
import { useCurrentArtwork } from "../../../Context/CurrentArtworkContext";

const MutualBoardTable = ({
  colorMode,
  board,
  boardId,
  mutualSpaceId,
  userName,
  userId,
  fetchBoardDetails,
  isSidebarCollapsed,
  guestToken,
  openDetailsPanelSettings,
  closedetailsPanelSetting,
  fetchMutualSpace,
  isMobileView,
}) => {
  const [activeTab, setActiveTab] = useState("tasks");
  const toast = useToast();
  const inputBg = useColorModeValue(
    "rgba(247, 250, 252, 0.43)",
    "rgba(66, 69, 99, 0.23)"
  );

  const taskBg = useColorModeValue("gray.50", "gray.700");

  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [activeTableId, setActiveTableId] = useState(""); // Track active table ID
  const { setBeatPanel } = useBeatPanel();
  const { setLyricsPanel } = useLyricsPanel();
  const { setRecordingsPanel } = useRecordingsPanel();
  const { setArtworksPanel } = useArtworksPanel();
  const { currentBeat, setCurrentBeat } = useCurrentBeat();
  const { currentLyric, setCurrentLyric } = useCurrentLyric();
  const { currentRecording, setCurrentRecording } = useCurrentRecording();
  const { currentArtwork, setCurrentArtwork } = useCurrentArtwork();
  const [isDeleting, setIsDeleting] = useState(false);

  const [editableMutualBoardName, setEditableMutualBoardName] = useState(
    board?.boardName
  );

  useEffect(() => {
    setEditableMutualBoardName(board?.boardName);
  }, [board]);

  const [activeFolderId, setActiveFolderId] = useState(""); // Track active table ID

  const handleFolderChange = (folderName, folderId) => {
    setActiveFolderId(folderId);
    setActiveTab("Folder"); // Set the active tab to 'Folder'
  };

  const { fetchBoards } = useMutualBoard();

  const [isTableUploadModalOpen, setIsTableUploadModalOpen] = useState(false);

  const addNewTable = async (tableType) => {
    try {
      const url = `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/addtable`;

      const requestBody = {
        userId: userId,
        name: userName,
        tableName: `${tableType} Table`,
        tableType: tableType,
      };

      const response = await axios.post(url, requestBody);

      toast({
        title: "Table Created",
        description: `${tableType} table created successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      fetchBoardDetails();
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Could not create table.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error creating table:", error);
    }
  };

  const handleTableChange = (tableName, tableId) => {
    setActiveTableId(tableId);
    setActiveTab(tableName);

    switch (tableName) {
      case "Instrumental / Beats Table":
        setBeatPanel("Beats");
        setCurrentLyric(null);
        setCurrentRecording(null);
        setCurrentArtwork(null);
        setLyricsPanel(null);
        setRecordingsPanel(null);
        setArtworksPanel(null);
        break;
      case "Lyrics Table":
        setLyricsPanel("Lyrics");
        setCurrentBeat(null);
        setCurrentRecording(null);
        setCurrentArtwork(null);
        setBeatPanel(null);
        setRecordingsPanel(null);
        setArtworksPanel(null);

        break;
      case "Recordings Table":
        setRecordingsPanel("Recordings");
        setCurrentBeat(null);
        setCurrentLyric(null);
        setCurrentArtwork(null);
        setLyricsPanel(null);
        setBeatPanel(null);
        setArtworksPanel(null);
        break;
      case "Artwork Table":
        setArtworksPanel("Artworks");
        setCurrentBeat(null);
        setCurrentLyric(null);
        setCurrentRecording(null);
        setLyricsPanel(null);
        setBeatPanel(null);
        setRecordingsPanel(null);
        break;
      default:
        // Optionally handle other cases or do nothing
        break;
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "tasks":
        return (
          <MainTaskTable
            board={board}
            boardId={boardId}
            mutualSpaceId={mutualSpaceId}
            colorMode={colorMode}
            userId={userId}
            userName={userName}
            fetchBoardDetails={fetchBoardDetails}
            isSidebarCollapsed={isSidebarCollapsed}
            guestToken={guestToken}
            isMobileView={isMobileView}
          />
        );

      case "Instrumental / Beats Table":
        return (
          <>
            <MainBeatAssetTable
              board={board}
              boardId={boardId}
              mutualSpaceId={mutualSpaceId}
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              fetchBoardDetails={fetchBoardDetails}
              isSidebarCollapsed={isSidebarCollapsed}
              tableId={activeTableId}
              guestToken={guestToken}
              openDetailsPanelSettings={openDetailsPanelSettings}
              closedetailsPanelSetting={closedetailsPanelSetting}
              isMobileView={isMobileView}
            />
          </>
        );

      case "Lyrics Table":
        return (
          <>
            <MainLyricAssetsTable
              board={board}
              boardId={boardId}
              mutualSpaceId={mutualSpaceId}
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              fetchBoardDetails={fetchBoardDetails}
              isSidebarCollapsed={isSidebarCollapsed}
              tableId={activeTableId}
              guestToken={guestToken}
              openDetailsPanelSettings={openDetailsPanelSettings}
              closedetailsPanelSetting={closedetailsPanelSetting}
            />
          </>
        );

      case "Recordings Table":
        return (
          <>
            <MainRecordingAssetTable
              board={board}
              boardId={boardId}
              mutualSpaceId={mutualSpaceId}
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              fetchBoardDetails={fetchBoardDetails}
              isSidebarCollapsed={isSidebarCollapsed}
              tableId={activeTableId}
              guestToken={guestToken}
              openDetailsPanelSettings={openDetailsPanelSettings}
              closedetailsPanelSetting={closedetailsPanelSetting}
            />
          </>
        );

      case "Artwork Table":
        return (
          <>
            <MainArtworkAssetTable
              board={board}
              boardId={boardId}
              mutualSpaceId={mutualSpaceId}
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              fetchBoardDetails={fetchBoardDetails}
              isSidebarCollapsed={isSidebarCollapsed}
              tableId={activeTableId}
              guestToken={guestToken}
              closedetailsPanelSetting={closedetailsPanelSetting}
              openDetailsPanelSettings={openDetailsPanelSettings}
            />
          </>
        );

      case "Folder":
        return (
          <>
            <MutualBoardFolder
              board={board}
              boardId={boardId}
              mutualSpaceId={mutualSpaceId}
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              fetchBoardDetails={fetchBoardDetails}
              isSidebarCollapsed={isSidebarCollapsed}
              guestToken={guestToken}
              folderId={activeFolderId}
            />
          </>
        );

      default:
        return (
          <Box>
            <MainTaskTable
              board={board}
              boardId={boardId}
              mutualSpaceId={mutualSpaceId}
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              fetchBoardDetails={fetchBoardDetails}
              isSidebarCollapsed={isSidebarCollapsed}
            />
          </Box>
        );
    }
  };

  useEffect(() => {
    if (mutualSpaceId && boardId) {
      fetchBoardDetails(mutualSpaceId, boardId);
    }
  }, [mutualSpaceId, boardId]);

  const [showTablesMenu, setShowTablesMenu] = useState(false);

  const [isFolderUploadModalOpen, setIsFolderUploadModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [folderName, setFolderName] = useState("");

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const saveFolder = async () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("name", userName);
    formData.append("folderName", folderName);

    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/addfolder`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast({
        title: "Folder Uploaded",
        description: "Folder and files uploaded successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      fetchBoardDetails();
      setIsFolderUploadModalOpen(false);
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "Could not upload folder.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error uploading folder:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteBoard = async () => {
    try {
      setIsDeleting(true); // Show a loading indicator specific to the delete operation
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}`
      );
      setIsDeleteModalOpen(false); // Close the modal upon successful deletion
      toast({
        title: "Board Deleted",
        description: "The board has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to delete the board:", error);
      toast({
        title: "Error",
        description: "Failed to delete the board. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleBoardNameBlur = async () => {
    if (editableMutualBoardName === board.boardName || guestToken) {
      return;
    }

    try {
      const url = `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/boardname`;
      const requestBody = { newBoardName: editableMutualBoardName };

      const response = await axios.put(url, requestBody);

      toast({
        title: "Board Name Updated!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });

      fetchBoardDetails();
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "Failed to update board name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error updating board name:", error);
    }
  };

  return (
    <Flex
      className={`MutualBoardTable-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"} pb={2}>
        <Flex alignItems={"center"} gap={"20px"}>
          <Tooltip placement="top" borderRadius={"7px"} label={"Click to Edit"}>
            <Input
              fontSize={"22px"}
              size={"sm"}
              borderRadius={"5px"}
              bg={isMobileView ? "none" : taskBg}
              _hover={{
                bg: hoverBg,
                borderColor: "gray.400",
              }}
              borderColor={isMobileView ? "none" : borderColor}
              border={"none"}
              value={editableMutualBoardName}
              onChange={(e) =>
                !guestToken && setEditableMutualBoardName(e.target.value)
              }
              onBlur={handleBoardNameBlur}
              isDisabled={!!guestToken}
            />
          </Tooltip>
        </Flex>
        <Flex alignItems={"center"} gap={"10px"}>
          <Flex className="avatarshare">
            {board?.usersWithAccess &&
              board.usersWithAccess.map((user, index) => (
                <Tooltip label={user.name} key={user.userId}>
                  <Avatar
                    size={"sm"}
                    className="foravatarsize"
                    ml={index > 0 ? -3 : 0}
                    _hover={{
                      transform: "translateY(-2px)",
                      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                    }}
                    name={user.name}
                  />
                </Tooltip>
              ))}
          </Flex>
          <Flex alignItems={"center"} gap={"10px"}>
            {isMobileView ? (
              <>
                <IconButton
                  isDisabled={!!guestToken}
                  onClick={onOpen}
                  icon={<FiUser />}
                  size="sm"
                />
              </>
            ) : (
              <>
                {" "}
                <Button
                  isDisabled={!!guestToken}
                  onClick={onOpen}
                  size={"sm"}
                  variant={"outline"}
                >
                  <Flex alignItems={"center"} gap={"5px"}>
                    <Icon as={FiUser} />
                    <Text>Access</Text>
                  </Flex>
                </Button>
              </>
            )}
            {isMobileView ? (
              <>
                <IconButton
                  isDisabled={!!guestToken}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsDeleteModalOpen(true);
                  }}
                  variant={"outline"}
                  colorScheme={"red"}
                  icon={<BsTrash />}
                  size="sm"
                />
              </>
            ) : (
              <>
                {" "}
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsDeleteModalOpen(true);
                  }}
                  isDisabled={!!guestToken}
                  size={"sm"}
                  variant={"outline"}
                  colorScheme="red"
                >
                  <Flex alignItems={"center"} gap={"5px"}>
                    <Text>Delete</Text>
                  </Flex>
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        pb={3}
        borderBottom={".5px solid grey"}
        alignItems={"center"}
        gap={"10px"}
        className="button-sections-surround"
      >
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          className="buttonsections"
        >
          <Flex alignItems={"center"} gap={"10px"}>
            <TaskButton
              closedetailsPanelSetting={closedetailsPanelSetting}
              setActiveTab={setActiveTab}
              colorMode={colorMode}
              activeTab={activeTab}
              guestToken={guestToken}
            />

            <AssetTableButtons
              setActiveTab={setActiveTab}
              colorMode={colorMode}
              handleTableChange={handleTableChange}
              board={board}
              activeTab={activeTab}
              fetchBoardDetails={fetchBoardDetails}
              boardId={boardId}
              mutualSpaceId={mutualSpaceId}
              guestToken={guestToken}
              openDetailsPanelSettings={openDetailsPanelSettings}
            />

            <FolderButton
              closedetailsPanelSetting={closedetailsPanelSetting}
              setActiveTab={setActiveTab}
              colorMode={colorMode}
              handleFolderChange={handleFolderChange}
              board={board}
              activeTab={activeTab}
              activeFolderId={activeFolderId}
              guestToken={guestToken}
              boardId={boardId}
              mutualSpaceId={mutualSpaceId}
              fetchBoardDetails={fetchBoardDetails}
            />
          </Flex>
        </Flex>
        <Menu>
          <Tooltip placement="right" borderRadius={"7px"} label={"Add New"}>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<BsPlusLg />}
              size={"sm"}
              variant={"outline"}
            />
          </Tooltip>
          <MenuList
            fontSize={"13px"}
            p={1}
            border={
              colorMode === "light"
                ? "2px solid rgba(153, 178, 198, 0.23)"
                : "2px solid rgba(43, 46, 54, 1)"
            }
            bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
            zIndex="9999"
          >
            <MenuGroup
              bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
              title="Board Options"
              fontSize={"13px"}
            >
              <MenuDivider />
              <Box
                position="relative"
                onMouseEnter={() => setShowTablesMenu(true)}
                onMouseLeave={() => setShowTablesMenu(false)}
              >
                <MenuItem
                  isDisabled={!!guestToken}
                  bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                  icon={<BsTable />}
                  _hover={{
                    bg:
                      colorMode === "light"
                        ? "rgba(241, 244, 249, 1)"
                        : "rgba(42, 49, 70, 1)",
                  }}
                >
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    w={"100%"}
                  >
                    <Text>Tables</Text>
                    <Icon fontSize={"17px"} as={ChevronRightIcon} />
                  </Flex>
                </MenuItem>
                {showTablesMenu && (
                  <Box
                    position="absolute"
                    left="102%"
                    top="0"
                    w={"100%"}
                    boxShadow="md"
                    p={1}
                    border={
                      colorMode === "light"
                        ? "2px solid rgba(153, 178, 198, 0.23)"
                        : "2px solid rgba(43, 46, 54, 1)"
                    }
                    bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                  >
                    <MenuItem
                      isDisabled={!!guestToken}
                      bg={
                        colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"
                      }
                      onClick={() => addNewTable("Instrumental / Beats")}
                      _hover={{
                        bg:
                          colorMode === "light"
                            ? "rgba(241, 244, 249, 1)"
                            : "rgba(42, 49, 70, 1)",
                      }}
                    >
                      <Flex p={1} alignItems={"center"} gap={"7px"} w={"100%"}>
                        <Icon as={BsHeadphones} />
                        <Text fontSize={"12px"}>
                          Instrumental / Beats Table
                        </Text>
                      </Flex>
                    </MenuItem>
                    <MenuItem
                      isDisabled={!!guestToken}
                      bg={
                        colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"
                      }
                      onClick={() => addNewTable("Lyrics")}
                      _hover={{
                        bg:
                          colorMode === "light"
                            ? "rgba(241, 244, 249, 1)"
                            : "rgba(42, 49, 70, 1)",
                      }}
                    >
                      <Flex p={1} alignItems={"center"} gap={"7px"} w={"100%"}>
                        <Icon as={MdOutlineLyrics} />
                        <Text fontSize={"12px"}>Lyrics Table</Text>
                      </Flex>
                    </MenuItem>
                    <MenuItem
                      isDisabled={!!guestToken}
                      bg={
                        colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"
                      }
                      onClick={() => addNewTable("Artwork")}
                      _hover={{
                        bg:
                          colorMode === "light"
                            ? "rgba(241, 244, 249, 1)"
                            : "rgba(42, 49, 70, 1)",
                      }}
                    >
                      <Flex p={1} alignItems={"center"} gap={"7px"} w={"100%"}>
                        <Icon as={BsImage} />
                        <Text fontSize={"12px"}>Artwork Table</Text>
                      </Flex>
                    </MenuItem>
                    <MenuItem
                      isDisabled={!!guestToken}
                      bg={
                        colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"
                      }
                      onClick={() => addNewTable("Recordings")}
                      _hover={{
                        bg:
                          colorMode === "light"
                            ? "rgba(241, 244, 249, 1)"
                            : "rgba(42, 49, 70, 1)",
                      }}
                    >
                      <Flex p={1} alignItems={"center"} gap={"7px"} w={"100%"}>
                        <Icon as={BiMicrophone} />
                        <Text fontSize={"12px"}>Recordings Table</Text>
                      </Flex>
                    </MenuItem>
                  </Box>
                )}
              </Box>
              <MenuItem
                isDisabled={!!guestToken}
                bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                icon={<BsFolder2 />}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsFolderUploadModalOpen(true);
                }}
                _hover={{
                  bg:
                    colorMode === "light"
                      ? "rgba(241, 244, 249, 1)"
                      : "rgba(42, 49, 70, 1)",
                }}
              >
                Folder
              </MenuItem>
              <MenuItem
                bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                icon={<IoDocument />}
                isDisabled
                _hover={{
                  bg:
                    colorMode === "light"
                      ? "rgba(241, 244, 249, 1)"
                      : "rgba(42, 49, 70, 1)",
                }}
              >
                Document
              </MenuItem>
              <MenuItem
                bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                icon={<FaTimeline />}
                isDisabled
                _hover={{
                  bg:
                    colorMode === "light"
                      ? "rgba(241, 244, 249, 1)"
                      : "rgba(42, 49, 70, 1)",
                }}
              >
                Timeline
              </MenuItem>
              <MenuItem
                bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                icon={<CalendarIcon />}
                isDisabled
                _hover={{
                  bg:
                    colorMode === "light"
                      ? "rgba(241, 244, 249, 1)"
                      : "rgba(42, 49, 70, 1)",
                }}
              >
                Calendar
              </MenuItem>
              <MenuItem
                bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                icon={<BsKanban />}
                isDisabled
                _hover={{
                  bg:
                    colorMode === "light"
                      ? "rgba(241, 244, 249, 1)"
                      : "rgba(42, 49, 70, 1)",
                }}
              >
                Kanban
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </Flex>
      {renderTabContent()}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this Mutual Board?
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
              <Button
                w={"100%"}
                variant="outline"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                w={"100%"}
                onClick={deleteBoard}
                isLoading={isDeleting}
                loadingText="Deleting..."
                isDisabled={isDeleting}
              >
                Delete Board
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isTableUploadModalOpen}
        onClose={() => setIsTableUploadModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalCloseButton />
          <ModalBody>
            <BoardTableUpload
              userName={userName}
              userId={userId}
              setIsTableUploadModalOpen={setIsTableUploadModalOpen}
              colorMode={colorMode}
              board={board}
              boardId={boardId}
              mutualSpaceId={mutualSpaceId}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isFolderUploadModalOpen}
        onClose={() => setIsFolderUploadModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Upload Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection={"column"} gap={"15px"}>
              <Input
                type="text"
                placeholder="Folder Name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />

              <Input
                type="file"
                webkitdirectory=""
                directory=""
                onChange={handleFileChange}
                multiple
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
              <Button
                w={"100%"}
                variant="outline"
                onClick={() => setIsFolderUploadModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                w={"100%"}
                onClick={saveFolder}
                isLoading={isLoading}
                loadingText="Saving..."
                isDisabled={isLoading}
              >
                Save Folder
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalCloseButton />
          <ModalBody>
            <UserInvite
              mutualSpaceId={mutualSpaceId}
              colorMode={colorMode}
              userId={userId}
              onClose={onClose}
              boardName={board?.boardName}
              userName={userName}
              boardId={boardId}
              fetchBoardDetails={fetchBoardDetails}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MutualBoardTable;
