import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  useToast,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Icon,
  MenuItem,
  MenuButton,
  Menu,
  MenuList,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import { BsPlusLg, BsFolder, BsFolderPlus, BsHeadphones } from "react-icons/bs";
import "./RecordingsDrawer.css";
import axios from "axios";
import { MdOutlineLyrics, MdTitle } from "react-icons/md";
import { AddIcon } from "@chakra-ui/icons";
import { TbUserPlus } from "react-icons/tb";
import { AiOutlineUser } from "react-icons/ai";
import { BiMicrophone, BiUserPlus } from "react-icons/bi";
import { PiFolderPlus } from "react-icons/pi";
import { GiSandsOfTime } from "react-icons/gi";
import { API_ENDPOINT } from "../../../../Config/config";
import CurrentRecordingLyricsItem from "../../../DetailsPanel/RecordingsPanel/CurrentRecordingLyricsItem";
import CurrentRecordingStatusItem from "../../../DetailsPanel/RecordingsPanel/CurrentRecordingStatusItem";
import BeatLinkedItem from "../../../DetailsPanel/RecordingsPanel/BeatLinkedItem";
import RecordingTimeLine from "../../../DetailsPanel/RecordingsPanel/RecordingTimeLine";
import CurrentRecordingComments from "../../../DetailsPanel/RecordingsPanel/RecordingComments/CurrentRecordingComments";
import AddRecordingToFolderModal from "../../../DetailsPanel/RecordingsPanel/AddRecordingToFolderModal";
import LinkBeatsModal from "../../../DetailsPanel/RecordingsPanel/LinkBeatsModal";
import RecordingNameInput from "./RecordingNameInput";

const RecordingsDrawer = ({
  tableId,
  mutualSpaceId,
  boardId,
  recordingAsset,
  colorMode,
  recordingId,
  fetchRecordingData,
  userId,
  userName,
  guestToken,
}) => {
  const toast = useToast();
  const inputBg = useColorModeValue("gray.50", "rgba(66, 69, 99, 0.23)");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const [recordingArtist, setRecordingArtist] = useState();
  const [recordingArtists, setRecordingArtists] = useState([]);
  const [isLinkBeatsOpen, setIsLinkBeatsOpen] = useState(false);
  const [isAddToFolderOpen, setIsAddToFolderOpen] = useState(false);

  const updateRecordingArtist = async (artist = recordingArtist) => {
    if (!recordingId) {
      console.error("Recording ID is not available.");
      return;
    }

    try {
      const response = await axios.patch(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/recording/${recordingId}/artist`,
        { recordingArtist: artist }
      );

      console.log("Update successful:", response.data);
      fetchRecordingData();
      toast({
        title: "Artist Added",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    } catch (error) {
      console.error("Failed to update recording artist:", error);
      toast({
        title: "Error updating Artist",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setRecordingArtist(recordingAsset?.recordingArtist || "");
  }, [recordingAsset]);

  const fetchRecordingArtists = async () => {
    if (!recordingId) {
      console.error("Table ID is not available.");
      return;
    }

    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/recordingArtists`
      );
      setRecordingArtists(response.data.recordingArtists || []);
    } catch (error) {
      console.error("Failed to fetch recording artists:", error);
    }
  };

  useEffect(() => {
    fetchRecordingArtists();
  }, [tableId]);

  const charToColor = (char) => {
    const alphabetPosition = char.toUpperCase().charCodeAt(0) - 64;
    const hue = (alphabetPosition * 137) % 360; // 137 is a "magic" number
    const saturation = 50 + (alphabetPosition % 51); // Vary between 50% and 100%
    const lightness = 50 + ((alphabetPosition * 3) % 26); // Vary between 50% and 75%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const charToColorArtist = (char) => {
    const alphabetPosition = char.toUpperCase().charCodeAt(0) - 64;
    const hue = (alphabetPosition * 137) % 360; // 137 is a "magic" number
    const saturation = 50 + (alphabetPosition % 51); // Vary between 50% and 100%
    const lightness = 50 + ((alphabetPosition * 3) % 26); // Vary between 50% and 75%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  return (
    <Box className="RecordingsDrawer-container">
      <Flex gap={"10px"} w={"100%"} alignItems={"center"}>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"5px"}
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={TbUserPlus} />
            <Text className="slighttext">Recording Artist</Text>
          </Flex>
          <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
            {recordingAsset.recordingArtist ? (
              <>
                <Avatar
                  icon={<AiOutlineUser fontSize="15px" />}
                  size={"xs"}
                  borderRadius={"7px"}
                  bg={charToColorArtist(recordingAsset?.recordingArtist)}
                />
              </>
            ) : (
              <>
                <Avatar
                  icon={<AiOutlineUser fontSize="15px" />}
                  size={"xs"}
                  borderRadius={"7px"}
                />
              </>
            )}
            <Input
              isDisabled={!!guestToken}
              value={recordingArtist}
              onChange={(e) => setRecordingArtist(e.target.value)}
              onBlur={() => updateRecordingArtist()} // Calls with current state value
              borderRadius={"6px"}
              bg={inputBg}
              borderColor={borderColor}
              _hover={{
                bg: hoverBg,
                borderColor: "gray.400",
              }}
              size={"sm"}
            />
            <Menu>
              <MenuButton
                isDisabled={!!guestToken}
                as={IconButton}
                aria-label="Options"
                icon={<AddIcon />}
                variant="outline"
                size={"sm"}
              />
              <MenuList>
                {recordingArtists.map((artist, index) => (
                  <MenuItem
                    isDisabled={!!guestToken}
                    key={index}
                    onClick={() => {
                      setRecordingArtist(artist);
                      updateRecordingArtist(artist);
                    }}
                  >
                    {artist}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
      <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
        <Flex w={"100%"} flexDirection={"column"} gap={"5px"}>
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={GiSandsOfTime} />
            <Text className="slighttext">Status</Text>
          </Flex>
          <CurrentRecordingStatusItem
            userId={userId}
            currentRecording={recordingAsset}
            colorMode={colorMode}
            userName={userName}
            mutualSpaceId={mutualSpaceId}
            boardId={boardId}
            tableId={tableId}
            recordingId={recordingId}
            fetchRecordingData={fetchRecordingData}
            guestToken={guestToken}
          />
        </Flex>

        <Flex w={"100%"} flexDirection={"column"} gap={"4px"}>
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={MdOutlineLyrics} />
            <Text className="slighttext">Lyrics</Text>
          </Flex>
          {recordingAsset?.linkedlyrics &&
          recordingAsset.linkedlyrics.length > 0 ? (
            <Flex w={"100%"}>
              {recordingAsset.linkedlyrics.map((lyric) => (
                <Box w={"100%"} key={lyric._id}>
                  <CurrentRecordingLyricsItem
                    colorMode={colorMode}
                    lyric={lyric}
                    lyricsId={lyric._id}
                    userId={userId}
                    currentRecording={recordingAsset}
                    userName={userName}
                    mutualSpaceId={mutualSpaceId}
                    boardId={boardId}
                    tableId={tableId}
                    recordingId={recordingId}
                  />
                </Box>
              ))}
            </Flex>
          ) : (
            <Button fontSize={"10px"} size={"sm"}>
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={BsPlusLg} />
                <Text>Lyrics</Text>
              </Flex>
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex alignItems={"center"} gap={"15px"}>
        <Flex w={"100%"} flexDirection={"column"} gap={"4px"}>
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"12px"} as={BsFolder} />
            <Text className="slighttext">Folders</Text>
          </Flex>

          {recordingAsset?.folders && recordingAsset.folders.length > 0 ? (
            <Flex height={"100%"} w={"100%"}>
              {recordingAsset.folders.map((folder) => (
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className={`writing-to-item ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                  key={folder._id}
                >
                  <Avatar
                    isDisabled={!!guestToken}
                    icon={<BsFolder fontSize="15px" />}
                    mr={-4}
                    size={"xs"}
                    bg={charToColor(folder.folderName)}
                  />
                </Flex>
              ))}
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                className={`writing-to-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                <Avatar
                  isDisabled={!!guestToken}
                  onClick={() => setIsAddToFolderOpen(true)}
                  size={"xs"}
                  icon={<BsFolderPlus fontSize="15px" />}
                  _hover={{ cursor: "pointer", opacity: "80%" }}
                />
              </Flex>
            </Flex>
          ) : (
            <Button
              isDisabled={!!guestToken}
              onClick={() => setIsAddToFolderOpen(true)}
              fontSize={"10px"}
              size={"sm"}
            >
              Add to Folder
            </Button>
          )}
        </Flex>
        <Flex w={"100%"} flexDirection={"column"} gap={"4px"}>
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={BsHeadphones} />
            <Text className="slighttext">Beat</Text>
          </Flex>

          {recordingAsset?.linkedBeats &&
          recordingAsset.linkedBeats.length > 0 ? (
            <Flex w={"100%"}>
              {recordingAsset.linkedBeats.map((beat) => (
                <Box w={"100%"} key={beat._id}>
                  <BeatLinkedItem beat={beat} colorMode={colorMode} />
                </Box>
              ))}
            </Flex>
          ) : (
            <Button
              onClick={() => setIsLinkBeatsOpen(true)}
              fontSize="10px"
              size="sm"
            >
              Link Beat
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex>
        <RecordingTimeLine
          userId={userId}
          currentRecording={recordingAsset}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          recordingId={recordingId}
          fetchRecordingData={fetchRecordingData}
        />
      </Flex>
      <Flex
        className={`beatdrawer-comments ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        w={"100%"}
      >
        <CurrentRecordingComments
          userId={userId}
          recordingAsset={recordingAsset}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          recordingId={recordingId}
          fetchRecordingData={fetchRecordingData}
          guestToken={guestToken}
        />
      </Flex>
      <Modal isOpen={isLinkBeatsOpen} onClose={() => setIsLinkBeatsOpen(false)}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Link Beat</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LinkBeatsModal
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              currentRecording={recordingAsset}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              recordingId={recordingId}
              fetchRecordingData={fetchRecordingData}
              setIsLinkBeatsOpen={setIsLinkBeatsOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isAddToFolderOpen}
        onClose={() => setIsAddToFolderOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Icon className="granticon" as={PiFolderPlus} />
              <Text className="accessheader">Add to Folder</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddRecordingToFolderModal
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              currentRecording={recordingAsset}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              recordingId={recordingId}
              fetchRecordingData={fetchRecordingData}
              setIsAddToFolderOpen={setIsAddToFolderOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default RecordingsDrawer;
