import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config"; // Make sure this path is correct
import AssetBeatPlayer from "../../Players/AssetBeatPlayer/AssetBeatPlayer";
import "./BeatAssetsTable.css";
import AssetRecordingPlayer from "../../Players/AssetRecordingPlayer";

const RecordingAssetsTable = ({
  userName,
  userId,
  colorMode,
  selectedTableDataId,
  openDetailsPanelSettings,
  guestToken,
}) => {
  const [recordingAssets, setRecordingAssets] = useState([]);

  const fetchRecordingAssets = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/assets/table/${selectedTableDataId}/recordingAssets`
      );
      console.log(response.data);

      setRecordingAssets(response.data.recordingAssets);
    } catch (error) {
      console.error("Error fetching beat assets:", error);
    }
  };

  useEffect(() => {
    if (selectedTableDataId) {
      fetchRecordingAssets();
    }
  }, [selectedTableDataId]);

  return (
    <Box mt={4}>
      <SimpleGrid columns={{ base: 1, md: 1 }} spacing={4}>
        {recordingAssets.length > 0 ? (
          recordingAssets.map((recordingAsset) => (
            <Box key={recordingAsset?._id}>
              <AssetRecordingPlayer
                userName={userName}
                userId={userId}
                colorMode={colorMode}
                recordingAsset={recordingAsset}
                selectedTableDataId={selectedTableDataId}
                openDetailsPanelSettings={openDetailsPanelSettings}
                guestToken={guestToken}
              />
            </Box>
          ))
        ) : (
          <Text>No Recordings found.</Text>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default RecordingAssetsTable;
