import {
  Box,
  Flex,
  Image,
  List,
  ListItem,
  ListIcon,
  Text,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import "./MainHomeHero4.css";
import grouped from "../../Svggs/thenewdarktools.svg";
import lightgroup from "../../Svggs/section4lightsmode.svg";

const MainHomeHero4 = ({ colorMode, isMobileView }) => {
  return (
    <Box className="MainHomeHero4">
      <Flex className="mobilesaplecontainer">
        <Image
          className="allmobileimg"
          src={colorMode === "light" ? lightgroup : grouped}
        />
      </Flex>
      <Flex
        className="mobileviews"
        gap={"10px"}
        w={isMobileView ? "100%" : "65%"}
        flexDirection={"column"}
        alignItems="start"
      >
        <Text className="Customizable" mb="3">
          Customizable Management Tools
        </Text>
        <List spacing={6}>
          <SimpleGrid columns={{ base: 2, md: 1 }} spacing={4} mt={5}>
            <Flex alignItems={"center"} gap={"8px"} className="itemstyly">
              <Icon as={CheckCircleIcon} color="green.500" />
              <Text>Set Statuses</Text>
            </Flex>
            <Flex alignItems={"center"} gap={"8px"} className="itemstyly">
              <Icon as={CheckCircleIcon} color="green.500" />
              <Text> Link Assets</Text>
            </Flex>
            <Flex alignItems={"center"} gap={"8px"} className="itemstyly">
              <Icon as={CheckCircleIcon} color="green.500" />
              <Text> Grant Access</Text>
            </Flex>
            <ListItem className="itemstyly">
              <ListIcon as={CheckCircleIcon} color="green.500" />
              Control Permissions
            </ListItem>{" "}
            <ListItem className="itemstyly">
              <ListIcon as={CheckCircleIcon} color="green.500" />
              Share Files
            </ListItem>{" "}
            <ListItem className="itemstyly">
              <ListIcon as={CheckCircleIcon} color="green.500" />
              Create Workspaces
            </ListItem>{" "}
          </SimpleGrid>
        </List>
      </Flex>
    </Box>
  );
};

export default MainHomeHero4;
