import { createContext, useState, useContext } from "react";

// Create the context
const LinkRecordingContext = createContext();

// Custom hook for easy context usage
export const useLinkRecording = () => useContext(LinkRecordingContext);

// Provider component
export const LinkRecordingProvider = ({ children }) => {
  const [linkRecording, setLinkRecording] = useState(null); // New state and setter function

  return (
    <LinkRecordingContext.Provider value={{ linkRecording, setLinkRecording }}>
      {children}
    </LinkRecordingContext.Provider>
  );
};
