import React, { useState, useRef } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import { BsImage } from "react-icons/bs";
import { API_ENDPOINT } from "../../../../Config/config";

const MainArtworkUpload = ({
  colorMode,
  userId,
  mutualSpaceId,
  board,
  boardId,
  tableId,
  userName,
  setIsUploadModalOpen,
  fetchArtworkAssets,
}) => {
  // const [imagePreview, setImagePreview] = useState(null);
  // const fileInputRef = useRef(null);
  // const toast = useToast();
  // const [isLoading, setIsLoading] = useState(false);
  // const [imageName, setImageName] = useState(null);
  // const [imageFile, setImageFile] = useState(null);

  const [imagePreviews, setImagePreviews] = useState([]);
  const fileInputRef = useRef(null);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [imageNames, setImageNames] = useState([]);

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file && file.type.startsWith("image/")) {
  //     setImageFile(file);
  //     setImageName(file.name); // Add this line to set the image name
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImagePreview(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => file.type.startsWith("image/"));
    setImageFiles(validFiles);
    setImageNames(validFiles.map((file) => file.name));

    const fileReaders = validFiles.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader;
    });

    Promise.all(
      fileReaders.map(
        (reader) =>
          new Promise((resolve) => {
            reader.onloadend = () => resolve(reader.result);
          })
      )
    ).then((previews) => {
      setImagePreviews(previews);
    });
  };

  const clearImages = () => {
    setImagePreviews([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const uploadArtwork = async () => {
    if (imageFiles.length === 0) {
      toast({
        title: "Error",
        description: "No files selected.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    imageFiles.forEach((file) => formData.append("images", file));
    formData.append("userId", userId);
    formData.append("name", userName);
    formData.append("titles", JSON.stringify(imageNames));

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/artwork`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsUploadModalOpen(false);
      setImagePreviews([]);
      setImageFiles([]);
      fetchArtworkAssets();
      toast({
        title: "Artworks uploaded!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    } catch (error) {
      toast({
        title: "Error uploading artworks",
        description:
          error.response?.data?.message || "Failed to upload artworks.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error uploading artworks:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex p={1} flexDirection={"column"} w={"100%"} spacing={4}>
      <Flex mb={3} alignItems={"center"} gap={"10px"}>
        <Icon className="granticon" as={BsImage} />
        <Text className="accessheader">Upload Artwork</Text>
      </Flex>
      <Input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageChange}
        style={{ display: "none" }}
        ref={fileInputRef}
        id="image-upload"
      />
      <label htmlFor="image-upload">
        {imagePreviews.length === 0 && (
          <Box className="hdysiai">
            <Button
              variant={"outline"}
              as="span"
              colorScheme="blue"
              className="upload-beat-btn"
            >
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon className="modal-title-icon" as={BsImage} />
                <Text className="modal-title-text"> Upload Artworks</Text>
              </Flex>
            </Button>
          </Box>
        )}
      </label>
      {imagePreviews.length > 0 && (
        <Box>
          <SimpleGrid mt={2} columns={{ base: 1, md: 4 }}>
            {imagePreviews.map((preview, index) => (
              <Box className="previewimagecontainer">
                <Image
                  key={index}
                  className="artworkpreviewimg"
                  src={preview}
                  alt={`Preview ${index + 1}`}
                />
              </Box>
            ))}
          </SimpleGrid>
          <Flex mt={4} alignItems={"center"} gap={"10px"}>
            <Button
              colorScheme="red"
              onClick={clearImages}
              w={"96%"}
              size={"sm"}
              variant={"outline"}
            >
              Clear
            </Button>
            <Button
              colorScheme="teal"
              isLoading={isLoading}
              w={"96%"}
              size={"sm"}
              onClick={uploadArtwork}
            >
              {isLoading ? <Spinner size="sm" /> : "Submit"}
            </Button>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default MainArtworkUpload;
