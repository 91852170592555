import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  Flex,
  Input,
  Icon,
  Tooltip,
  InputGroup,
  InputLeftElement,
  useToast,
  useDisclosure,
  Modal,
  ModalBody,
  useColorModeValue,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Center,
} from "@chakra-ui/react";
import "./MainTaskTable.css";
import { FaSort } from "react-icons/fa";
import { BsFilter, BsPlus, BsPlusLg, BsSearch } from "react-icons/bs";
import axios from "axios";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { API_ENDPOINT } from "../../../../Config/config";
import TaskTableItem from "./TaskTableItem";
import MobileMainTaskItem from "../../MutualSpaceTable/MobileMainTaskItem";

const MainTaskTable = ({
  colorMode,
  board,
  boardId,
  mutualSpaceId,
  userId,
  isSidebarCollapsed,
  userName,
  guestToken,
  isMobileView,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [tasks, setTasks] = useState([]);
  const [taskTableName, setTaskTableName] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inputBg = useColorModeValue(
    "rgba(247, 250, 252, 0.43)",
    "rgba(66, 69, 99, 0.23)"
  );
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const boxShadow = useColorModeValue("sm", "dark-lg");

  const fetchTasks = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/tasks`
      );
      // Ensure tasks is always an array
      setTasks(response.data.data || []);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      toast({
        title: "Failed to Fetch Tasks",
        description: "An error occurred while attempting to fetch tasks.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      // Set tasks to an empty array on error to prevent UI crashes
      setTasks([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [boardId]);

  const handleCreateTask = async () => {
    if (!taskTableName.trim()) {
      toast({
        title: "Task Name Required",
        description: "Please enter a task name before submitting.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/addtask`,
        {
          userId,
          name: userName, // using the userName prop now
          taskTableName,
          items: [], // Starting with an empty items array, can be customized as needed
        }
      );

      setTasks([...tasks, response.data.task]); // Append the new task to the local state
      setTaskTableName(""); // Clear the input field
      onClose(); // Close the modal
      toast({
        title: "Task Created",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      fetchTasks();
    } catch (error) {
      console.error("Error creating task:", error);
      toast({
        title: "Failed to Create Task",
        description: "An error occurred while attempting to create the task.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const taskBg = useColorModeValue("gray.50", "gray.700");

  return (
    <Flex className="MainTaskTable-container" w={"100%"}>
      {isLoading ? (
        <Center w={"100%"} mt={2}>
          <Spinner />
        </Center>
      ) : tasks && tasks.length > 0 ? (
        <Box className="main-task-tableanchor-surround">
          <Flex alignItems={"center"} w={"100%"} gap={"8px"}>
            <Flex w={"100%"} alignItems={"center"} gap={"8px"}>
              <Flex>
                <Tooltip
                  placement="top"
                  borderRadius={"7px"}
                  label={"New Table"}
                >
                  <Button
                    isDisabled={!!guestToken}
                    onClick={onOpen}
                    size={"sm"}
                  >
                    <Flex alignItems={"center"} gap={"3px"}>
                      <Text>New </Text>
                      <Icon as={BsPlus} />
                    </Flex>
                  </Button>
                </Tooltip>
              </Flex>
              <Flex w="100%">
                <InputGroup size="sm" w="100%">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={BsSearch} color="gray.300" />}
                  />
                  <Input
                    bg={inputBg}
                    borderColor={borderColor}
                    placeholder="Search..."
                    sx={{
                      "::placeholder": {
                        fontSize: "12px",
                      },
                    }}
                    _hover={{
                      bg: hoverBg,
                      borderColor: "gray.400",
                    }}
                    borderRadius={"5px"}
                    size={"sm"}
                    w={"100%"}
                  />
                </InputGroup>
              </Flex>
            </Flex>
          </Flex>

          {tasks.map((task, index) => (
            <>
              {isMobileView ? (
                <>
                  <MobileMainTaskItem
                    key={index}
                    board={board}
                    colorMode={colorMode}
                    mutualSpaceId={mutualSpaceId}
                    userId={userId}
                    boardId={boardId}
                    task={task}
                    taskId={task._id}
                    isSidebarCollapsed={isSidebarCollapsed}
                    fetchTasks={fetchTasks}
                    guestToken={guestToken}
                  />
                </>
              ) : (
                <>
                  <TaskTableItem
                    key={index}
                    board={board}
                    colorMode={colorMode}
                    mutualSpaceId={mutualSpaceId}
                    userId={userId}
                    boardId={boardId}
                    task={task}
                    taskId={task._id}
                    isSidebarCollapsed={isSidebarCollapsed}
                    fetchTasks={fetchTasks}
                    guestToken={guestToken}
                  />
                </>
              )}
            </>
          ))}
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          w={"100%"}
          padding={"20px"}
          bg={taskBg}
        >
          <Flex alignItems={"center"} flexDirection={"column"} gap={"10px"}>
            <Text
              className={`getstartedbelow ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
              textAlign={"center"}
            >
              Create a Task Table!
            </Text>
            <Flex>
              <Button onClick={onOpen} size={"sm"} colorScheme="blue">
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={BsPlusLg} />
                  <Text>Task Table</Text>
                </Flex>
              </Button>
            </Flex>
          </Flex>
        </Box>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>New Task</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex gap={"15px"} flexDirection={"column"}>
              <Flex alignItems={"center"} gap={"8px"}>
                <Icon as={BsFillInfoCircleFill} />
                <Text className="task-table-name-label">
                  Give this new task a name below
                </Text>
              </Flex>

              <Input
                placeholder="Task Name"
                value={taskTableName}
                onChange={(e) => setTaskTableName(e.target.value)}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex alignItems={"center"} gap={"20px"} w={"100%"}>
              <Button w={"100%"} variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button w={"100%"} colorScheme="blue" onClick={handleCreateTask}>
                Create Task
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MainTaskTable;
