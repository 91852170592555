import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  useToast,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Icon,
  MenuItem,
  MenuButton,
  Menu,
  MenuList,
  useColorModeValue,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import "./LyricsDrawer.css";
import StatusLyricsItem from "./StatusLyricsItem";
import { GiSandsOfTime } from "react-icons/gi";
import { BsFolder, BsFolderPlus } from "react-icons/bs";
import { TbUserPlus } from "react-icons/tb";
import { BiHeadphone, BiMicrophone, BiUserPlus } from "react-icons/bi";
import LinkedItemRecording from "../../../Players/MutualSpacePlayers/LinkedItemRecording";
import BeatLinkedItem from "../../../DetailsPanel/RecordingsPanel/BeatLinkedItem";
import AddBeatModal from "../../../DetailsPanel/LyricsPanel/AddBeatModal";
import AddRecordingModal from "../../../DetailsPanel/LyricsPanel/AddRecordingModal";
import { PiFolderPlus } from "react-icons/pi";
import LyricsTimeLine from "../../../DetailsPanel/LyricsPanel/LyricsTimeLine";
import CurrentLyricComments from "../../../DetailsPanel/LyricsPanel/CurrentLyricComments";

const LyricsDrawer = ({
  userId,
  lyricAsset,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  lyricId,
  fetchLyricData,
  guestToken,
}) => {
  const toast = useToast();
  const inputBg = useColorModeValue("gray.50", "rgba(66, 69, 99, 0.23)");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const songWriters = Array.isArray(lyricAsset?.songWriters)
    ? lyricAsset.songWriters
    : lyricAsset?.songWriters?.usersWritingTo || [];
  const [isAddToFolderOpen, setIsAddToFolderOpen] = useState(false);
  const [isAddBeatOpen, setIsAddBeatOpen] = useState(false);
  const [isAddSongwriterOpen, setIsAddSongwriterOpen] = useState(false);
  const [isAddRecordingModalOpen, setIsAddRecordingModalOpen] = useState(false);

  const charToColor = (char) => {
    const alphabetPosition = char.toUpperCase().charCodeAt(0) - 64;
    const hue = (alphabetPosition * 137) % 360; // 137 is a "magic" number
    const saturation = 50 + (alphabetPosition % 51); // Vary between 50% and 100%
    const lightness = 50 + ((alphabetPosition * 3) % 26); // Vary between 50% and 75%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  return (
    <Box className="LyricsDrawer-container">
      <Flex flexDirection={"column"} gap={"5px"}>
        <Flex alignItems={"center"} gap={"5px"}>
          <Icon fontSize={"12px"} as={GiSandsOfTime} />
          <Text className="slighttext">Status</Text>
        </Flex>
        <StatusLyricsItem
          lyricAsset={lyricAsset}
          userName={userName}
          userId={userId}
          colorMode={colorMode}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          lyricId={lyricId}
          fetchLyricData={fetchLyricData}
          guestToken={guestToken}
        />
      </Flex>
      <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
        <Flex w={"100%"}>
          <Flex
            w={"100%"}
            flexDirection={"column"}
            alignItems={"start"}
            gap={"3px"}
          >
            <Flex
              w={"100%"}
              justifyContent={"space-between"}
              alignItems={"end"}
            >
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon fontSize={"14px"} as={TbUserPlus} />
                <Text className="slighttext">Songwriters</Text>
              </Flex>
            </Flex>
            <Flex className="avatarsrow">
              {songWriters.length > 0 ? (
                <Flex className="fortunearrows">
                  {songWriters.map((songWriter, index) => (
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      className={`writing-to-item ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                      key={songWriter.user.userId}
                    >
                      <Avatar mr={-4} size={"xs"} name={songWriter.user.name} />
                    </Flex>
                  ))}
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    className={`writing-to-item ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    <Avatar
                      isDisabled={!!guestToken}
                      onClick={() => setIsAddSongwriterOpen(true)}
                      size={"xs"}
                      icon={<BiUserPlus fontSize="15px" />}
                      _hover={{ cursor: "pointer", opacity: "80%" }}
                    />
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className={`writing-to-item ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                  _hover={{ cursor: "pointer", opacity: "80%" }}
                >
                  <Avatar
                    isDisabled={!!guestToken}
                    onClick={() => setIsAddSongwriterOpen(true)}
                    size={"xs"}
                    icon={<BiUserPlus fontSize="15px" />}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex w={"100%"}>
          <Flex w={"100%"} flexDirection={"column"} gap={"3px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon fontSize={"12px"} as={BsFolder} />
              <Text className="slighttext">Folders</Text>
            </Flex>

            {lyricAsset?.folders && lyricAsset.folders.length > 0 ? (
              <Flex height={"100%"} w={"100%"}>
                {lyricAsset.folders.map((folder) => (
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    className={`writing-to-item ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                    key={folder._id}
                  >
                    <Avatar
                      icon={<BsFolder fontSize="15px" />}
                      mr={-4}
                      size={"xs"}
                      bg={charToColor(folder.folderName)}
                    />
                  </Flex>
                ))}
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className={`writing-to-item ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                >
                  <Avatar
                    onClick={() => setIsAddToFolderOpen(true)}
                    size={"xs"}
                    icon={<BsFolderPlus fontSize="15px" />}
                    _hover={{ cursor: "pointer", opacity: "80%" }}
                  />
                </Flex>
              </Flex>
            ) : (
              <Button
                isDisabled={!!guestToken}
                onClick={() => setIsAddToFolderOpen(true)}
                fontSize={"10px"}
                size={"sm"}
              >
                Add to Folder
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
        <Flex w={"100%"}>
          <Flex w={"100%"} flexDirection={"column"} gap={"4px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon fontSize={"12px"} as={BiHeadphone} />
              <Text className="slighttext">Beat</Text>
            </Flex>

            {lyricAsset?.linkedBeats && lyricAsset.linkedBeats.length > 0 ? (
              <Flex height={"100%"} w={"100%"}>
                {lyricAsset.linkedBeats.map((linkedBeat) => (
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    className={`writing-to-item ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                    key={linkedBeat._id}
                  >
                    <BeatLinkedItem beat={linkedBeat} colorMode={colorMode} />
                  </Flex>
                ))}
              </Flex>
            ) : (
              <Button
                isDisabled={!!guestToken}
                onClick={() => setIsAddBeatOpen(true)}
                fontSize={"10px"}
                size={"sm"}
              >
                Link to Beat
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex w={"100%"}>
          <Flex w={"100%"} flexDirection={"column"} gap={"4px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon fontSize={"12px"} as={BiMicrophone} />
              <Text className="slighttext">Recording</Text>
            </Flex>

            {lyricAsset?.linkedRecordings &&
            lyricAsset.linkedRecordings.length > 0 ? (
              <Flex height={"100%"} w={"100%"}>
                {lyricAsset.linkedRecordings.map((linkedRecording) => (
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    className={`writing-to-item ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                    key={linkedRecording._id}
                  >
                    <LinkedItemRecording
                      recording={linkedRecording}
                      colorMode={colorMode}
                    />
                  </Flex>
                ))}
              </Flex>
            ) : (
              <Button
                onClick={() => setIsAddRecordingModalOpen(true)}
                fontSize={"10px"}
                size={"sm"}
              >
                Link to Recording
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        <LyricsTimeLine
          currentLyric={lyricAsset}
          userName={userName}
          userId={userId}
          colorMode={colorMode}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          lyricId={lyricId}
          fetchLyricData={fetchLyricData}
        />
      </Flex>
      <Flex
        className={`beatdrawer-comments ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        w={"100%"}
      >
        <CurrentLyricComments
          currentLyric={lyricAsset}
          userName={userName}
          userId={userId}
          colorMode={colorMode}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          lyricId={lyricId}
          fetchLyricData={fetchLyricData}
          guestToken={guestToken}
        />
      </Flex>
      <Modal isOpen={isAddBeatOpen} onClose={() => setIsAddBeatOpen(false)}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Icon className="granticon" as={PiFolderPlus} />
              <Text className="accessheader">Add to Folder</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddBeatModal
              currentLyric={lyricAsset}
              userName={userName}
              userId={userId}
              colorMode={colorMode}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              lyricId={lyricId}
              fetchLyricData={fetchLyricData}
              setIsAddBeatOpen={setIsAddBeatOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isAddRecordingModalOpen}
        onClose={() => setIsAddRecordingModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Icon className="granticon" as={PiFolderPlus} />
              <Text className="accessheader">Add to Folder</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddRecordingModal
              currentLyric={lyricAsset}
              userName={userName}
              userId={userId}
              colorMode={colorMode}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              lyricId={lyricId}
              fetchLyricData={fetchLyricData}
              setIsAddRecordingModalOpen={setIsAddRecordingModalOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LyricsDrawer;
