import { createContext, useState, useContext } from "react";

const EmptyArtworkContext = createContext();

export const useEmptyArtwork = () => useContext(EmptyArtworkContext);

export const EmptyArtworkProvider = ({ children }) => {
  const [isArtworkEmpty, setIsArtworkEmpty] = useState(null);

  return (
    <EmptyArtworkContext.Provider value={{ isArtworkEmpty, setIsArtworkEmpty }}>
      {children}
    </EmptyArtworkContext.Provider>
  );
};
