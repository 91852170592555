import React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  MenuItem,
  Menu,
  Text,
  Button,
  MenuButton,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../../../Config/config";

const PriorityItem = ({
  task,
  taskId,
  board,
  boardId,
  userId,
  colorMode,
  mutualSpaceId,
  isSidebarCollapsed,
  fetchTasks,
  key,
  itemId,
  item,
  fetchItems,
  guestToken,
}) => {
  const toast = useToast(); // Using toast for notifications

  const updateItemPriority = async (newPriority) => {
    try {
      await axios.put(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/task/${taskId}/item/${itemId}/priority`,
        { newPriority }
      );
      toast({
        title: "Priority Updated",
        description: `The item's priority was updated to ${newPriority}.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchItems();
    } catch (error) {
      console.error("Error updating item priority:", error);
      toast({
        title: "Error",
        description: "Failed to update the item's priority.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Menu>
      <MenuButton
        onClick={(event) => event.stopPropagation()} // Stop event propagation
        size="xs"
        as={Button}
        rightIcon={<ChevronDownIcon />}
        className={
          !item?.priority
            ? "Taskdefualt-menu-button-text"
            : "Task-menu-button-text"
        }
        variant={"outline"}
        border={"none"}
        bg={"none"}
        _hover={{ bg: "none" }}
        color={
          colorMode === "light"
            ? !item?.priority
              ? "rgba(6, 21, 43, 1)"
              : "white"
            : !item?.priority
            ? "white"
            : "white"
        }
      >
        {item?.priority ? (
          <Text>{item?.priority}</Text>
        ) : (
          <Text>Set Priority</Text>
        )}
      </MenuButton>
      <MenuList>
        <MenuItem
          isDisabled={!!guestToken}
          onClick={() => updateItemPriority("High")}
        >
          High
        </MenuItem>
        <MenuItem
          isDisabled={!!guestToken}
          onClick={() => updateItemPriority("Medium")}
        >
          Medium
        </MenuItem>
        <MenuItem
          isDisabled={!!guestToken}
          onClick={() => updateItemPriority("Low")}
        >
          Low
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default PriorityItem;
