import React, { useState, useEffect } from "react";
import "./ScheduleDate.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Import the default styles
import { Box, Button, Flex, IconButton, useToast } from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const ScheduleDate = ({ colorMode, release, releaseId, fetchReleases }) => {
  const [date, setDate] = useState(new Date());
  const toast = useToast();
  const [calendarVisible, setCalendarVisible] = useState(true);

  const bgColor = colorMode === "light" ? "white" : "#1A202C";
  const textColor = colorMode === "light" ? "black" : "white";
  const borderColor = colorMode === "light" ? "gray.200" : "gray.600";
  const shadowColor =
    colorMode === "light" ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.5)";
  const hoverBgColor = colorMode === "light" ? "#EDF2F7" : "#2D3748";
  const activeBgColor = colorMode === "light" ? "#3182CE" : "#63B3ED";
  const todayBgColor = colorMode === "light" ? "#E2E8F0" : "#4A5568";
  const weekendColor = colorMode === "light" ? "#E53E3E" : "#FC8181";

  const handleDateChange = (newDate) => {
    setDate(newDate);
    setCalendarVisible(false);
  };

  const scheduleReleaseDate = async () => {
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/release/${releaseId}/addSchedule`,
        {
          scheduleDate: date.toISOString(),
        }
      );
      toast({
        title: "Success",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchReleases(); // Refresh the release data after scheduling
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "Failed to schedule release date",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bg={bgColor}
      color={textColor}
      borderRadius="md"
      boxShadow={`0 2px 10px ${shadowColor}`}
      p={4}
      width="100%"
    >
      {calendarVisible ? (
        <Calendar
          onChange={handleDateChange}
          value={date}
          className="custom-calendar"
          tileClassName={({ date, view }) => {
            if (
              view === "month" &&
              (date.getDay() === 0 || date.getDay() === 6)
            ) {
              return "weekend";
            }
          }}
        />
      ) : (
        <Box textAlign="center">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <Box
              display="inline-block"
              textAlign="center"
              border="1px solid"
              borderColor={colorMode === "light" ? "gray.200" : "gray.600"}
              borderRadius="md"
              overflow="hidden"
              w={"150px"}
              mb={"20px"}
            >
              <Box bg="red.500" color="white" fontSize="xl" p={2}>
                {date
                  .toLocaleString("default", { month: "short" })
                  .toUpperCase()}
              </Box>
              <Box bg={bgColor} color={textColor} fontSize="4xl" p={4}>
                {date.getDate()}
              </Box>
            </Box>
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"} gap={"20px"}>
            <Button onClick={() => setCalendarVisible(true)} mt={4}>
              Back
            </Button>
            <Button
              colorScheme="teal"
              onClick={scheduleReleaseDate}
              mt={4}
              ml={4}
            >
              Schedule Release Date
            </Button>
          </Flex>
        </Box>
      )}
      <style jsx global>{`
        .react-calendar {
          width: 100%;
          border: 1px solid ${borderColor};
          border-radius: 8px;
          box-shadow: 0 2px 10px ${shadowColor};
          background-color: ${bgColor};
          color: ${textColor};
        }
        .react-calendar__navigation {
          background: ${bgColor};
          color: ${textColor};
          border-bottom: 1px solid ${borderColor};
          border-radius: 8px 8px 0 0;
        }
        .react-calendar__navigation button {
          color: ${textColor};
          min-width: 44px;
          background: none;
          font-size: 16px;
          margin-top: 8px;
          transition: background 0.3s;
        }
        .react-calendar__navigation button:enabled:hover,
        .react-calendar__navigation button:enabled:focus {
          background: ${hoverBgColor};
        }
        .react-calendar__month-view__weekdays {
          background: ${bgColor};
          color: ${textColor};
        }
        .react-calendar__month-view__days__day {
          background: ${bgColor};
          color: ${textColor};
        }
        .react-calendar__tile {
          max-width: initial;
          padding: 10px;
          background: ${bgColor};
          color: ${textColor};
          border-radius: 8px;
          transition: all 0.3s ease;
        }
        .react-calendar__tile:enabled:hover,
        .react-calendar__tile:enabled:focus {
          background: ${hoverBgColor};
          color: ${textColor};
          border-radius: 8px;
          transform: scale(1.05);
        }
        .react-calendar__tile--active {
          background: ${activeBgColor};
          color: white;
          border-radius: 8px;
        }
        .react-calendar__tile--now {
          background: ${todayBgColor};
          color: ${textColor};
          border-radius: 8px;
        }
        .weekend {
          color: ${weekendColor};
        }
        .react-calendar__tile--hasActive:enabled:hover,
        .react-calendar__tile--hasActive:enabled:focus {
          background: ${hoverBgColor};
        }
        .react-calendar__month-view__days__day--neighboringMonth {
          color: ${colorMode === "light" ? "#A0AEC0" : "#718096"};
        }
      `}</style>
    </Box>
  );
};

export default ScheduleDate;
