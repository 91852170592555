import React, { useState } from "react";
import { Box, Flex, Button, Icon, Text } from "@chakra-ui/react";
import "./PlanningTable.css";
import { VscTasklist } from "react-icons/vsc";
import { FaTimeline } from "react-icons/fa6";
import { BsKanban } from "react-icons/bs";
import TaskPlanning from "./TaskPlanning/TaskPlanning";
import { GoTasklist } from "react-icons/go";

const PlanningTable = ({ colorMode, userId, userName, guestToken }) => {
  const [activeTab, setActiveTab] = useState("tasks");

  const renderTabContent = () => {
    switch (activeTab) {
      case "tasks":
        return (
          <Box w={"100%"}>
            <TaskPlanning
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              guestToken={guestToken}
            />
          </Box>
        );
      case "kanban":
        return <Box>Kanban Board</Box>;
      case "timeline":
        return <Box>Timeline View</Box>;
      default:
        return <Box>Tasks Content</Box>;
    }
  };

  return (
    <Box className="PlanningTable-container">
      <Flex
        borderBottom={".5px solid grey"}
        alignItems={"center"}
        justifyContent={"space-between"}
        pb={2}
      >
        <Flex alignItems={"center"} gap={"8px"}>
          <Icon fontSize={"25px"} as={GoTasklist} />
          <Text className="planning-title-text">Planning</Text>
        </Flex>
        <Flex alignItems={"center"} gap={"10px"}>
          <Button
            size={"sm"}
            onClick={() => setActiveTab("tasks")}
            colorScheme={activeTab === "tasks" ? "blue" : "gray"}
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={VscTasklist} />
              <Text>Tasks</Text>
            </Flex>
          </Button>
          <Button size={"sm"} variant={"outline"} isDisabled>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={BsKanban} />
              <Text>Kanban</Text>
            </Flex>
          </Button>
          <Button size={"sm"} variant={"outline"} isDisabled>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={FaTimeline} />
              <Text>Timeline</Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      <Flex className="render-tab-content">{renderTabContent()}</Flex>
    </Box>
  );
};

export default PlanningTable;
