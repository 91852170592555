import React, { useContext, useState, useEffect } from "react";
import "./MobileMainBeatsPlayer.css";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Drawer,
  useDisclosure,
} from "@chakra-ui/react";
import {
  BsPlayCircle,
  BsSendArrowDown,
  BsPauseCircle,
  BsCheckAll,
} from "react-icons/bs";
import { CheckCircleIcon, DownloadIcon, LinkIcon } from "@chakra-ui/icons";
import { FaChevronRight, FaTrashCan } from "react-icons/fa6";
import { MusicPlayerContext } from "../../../Context/MusicPlayerContext";
import { TbMusicShare } from "react-icons/tb";
import MobileRecordingsPanel from "../../DetailsPanel/RecordingsPanel/MobileRecordingsPanel";
import { useCurrentRecording } from "../../../Context/CurrentRecordingContext";
import { useRecordingsPanel } from "../../../Context/RecordingsPanelContext";
import { useLinkRecording } from "../../../Context/LinkRecordingContext";

const MobileMainRecordingPlayer = ({ colorMode, recording }) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Use the hook for drawer control
  const { currentRecording, setCurrentRecording } = useCurrentRecording();
  const { setRecordingsPanel } = useRecordingsPanel();
  const { setLinkRecording } = useLinkRecording();

  const [setPendingStatus] = useState("");
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);
  const closeStatusChangeModal = () => setIsStatusChangeModalOpen(false);
  const [selectedStatus, setSelectedStatus] = useState(
    recording.status || "Set Status"
  );

  const { playTrack, stopTrack, currentTrack, seekTrack } =
    useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [localDuration, setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack &&
    currentTrack.src === recording.fileUrl &&
    !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(recording.fileUrl);
    }
  };

  const handleInnerClick = (event) => {
    event.stopPropagation();
  };

  const handleMenuItemClick = (option) => {
    setPendingStatus(option);
    setIsStatusChangeModalOpen(true); // Open the status change modal
  };

  const getMenuButtonBgColor = () => {
    switch (selectedStatus) {
      case "Ready For Mix":
        return "rgba(249, 185, 89, .47)"; // Adjust the color as per your theme
      case "Mixing":
        return "rgba(0, 144, 255, 0.4)"; // Adjust the color as per your theme
      case "Release Ready":
        return "rgba(90, 238, 158, 0.47)"; // Adjust the color as per your theme
      default:
        return ""; // Default color or no color
    }
  };

  const handleRecordingClick = (event) => {
    event.stopPropagation();
    setRecordingsPanel("Recordings");

    setCurrentRecording(recording);
    setLinkRecording(null);
    onOpen();
  };

  return (
    <Box
      onClick={handleRecordingClick}
      className={`mobile-main-player ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        w={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={"10px"}
      >
        <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
          <Box>
            {isCurrentBeatPlaying ? (
              <>
                <IconButton
                  onClick={(event) => {
                    handleInnerClick(event);
                    togglePlayPause(); // Call the original saveProducerName function
                  }}
                  icon={<BsPauseCircle className="main-player-button" />}
                />
              </>
            ) : (
              <>
                <IconButton
                  onClick={(event) => {
                    handleInnerClick(event);
                    togglePlayPause(); // Call the original saveProducerName function
                  }}
                  icon={<BsPlayCircle className="main-player-button" />}
                />
              </>
            )}
          </Box>
          <Box>
            <Flex flexDirection={"column"}>
              <Text className="mobile-player-name">{recording.title}</Text>
              <Text className="mobile-player-producer">
                {" "}
                {recording.artist || "Artist"}
              </Text>{" "}
            </Flex>
          </Box>
        </Flex>
        <Flex w={"100%"}>
          <Box className="mobile-right">
            <Menu
              border={
                colorMode === "light"
                  ? "2px solid rgba(153, 178, 198, 0.23)"
                  : "2px solid rgba(43, 46, 54, 1)"
              }
              bg={colorMode === "light" ? "white" : "black"}
            >
              {({ isOpen }) => (
                <>
                  <Flex alignItems="center" gap={2}>
                    <Tooltip
                      placement="bottom"
                      borderRadius={"7px"}
                      label={"Set Status"}
                    >
                      <MenuButton
                        onClick={(event) => event.stopPropagation()} // Stop event propagation
                        backgroundColor={getMenuButtonBgColor()} // Apply conditional background color
                        size={"xs"}
                        fontSize={"10px"}
                        as={Button}
                        rightIcon={
                          <FaChevronRight
                            style={{
                              transition: "transform 0.2s",
                              transform: isOpen ? "rotate(90deg)" : "none",
                            }}
                          />
                        }
                      >
                        {selectedStatus}
                      </MenuButton>
                    </Tooltip>
                  </Flex>
                  <MenuList
                    p={2}
                    bg={colorMode === "light" ? "white" : "black"}
                    zIndex={"9999"}
                  >
                    <MenuItem
                      onClick={() => handleMenuItemClick("Ready For Mix")}
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(249, 185, 89, .23)"
                              : "rgba(249, 185, 89, .23)",
                        },
                      }}
                      bg={colorMode === "light" ? "white" : "black"}
                      className="blank"
                    >
                      <Flex alignItems={"center"} gap={"5px"}>
                        <Icon as={BsCheckAll} />
                        <Text className="menu-text">Ready For Mix</Text>
                      </Flex>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleMenuItemClick("Mixing")}
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                    >
                      <Flex alignItems={"center"} gap={"5px"}>
                        <Icon as={TbMusicShare} />
                        <Text className="menu-text">Mixing</Text>
                      </Flex>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleMenuItemClick("Release Ready")}
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                    >
                      <Flex alignItems={"center"} gap={"5px"}>
                        <Icon as={CheckCircleIcon} />
                        <Text className="menu-text">Release Ready</Text>
                      </Flex>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </Flex>
      </Flex>
      <Box className="mobile-slider-section">
        <Flex w={"100%"}>
          <Box w={"100%"}>
            <Slider
              w={"100%"}
              focusThumbOnChange={false}
              value={isCurrentBeatPlaying ? localCurrentTime : 0}
              max={isCurrentBeatPlaying ? localDuration : 0}
              onChange={(val) => isCurrentBeatPlaying && seekTrack(val)}
              isDisabled={!isCurrentBeatPlaying}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>
        </Flex>
      </Box>
      <Flex justifyContent={"space-between"} alignItems={"center"} gap={"5px"}>
        <Button fontSize={"9px"} colorScheme="green" size={"xs"}>
          Add to Release
        </Button>
        <Button variant={"outline"} size={"xs"}>
          <Icon fontSize={"10px"} mr={1.5} as={LinkIcon} />
          Link
        </Button>

        <Flex alignItems={"center"} gap={"10px"}>
          <Tooltip
            placement="bottom"
            borderRadius={"7px"}
            label={"Send in Message"}
          >
            <IconButton
              variant={"outline"}
              icon={<BsSendArrowDown />}
              size={"xs"}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            borderRadius={"7px"}
            label={"Download Beat"}
          >
            <IconButton
              variant={"outline"}
              icon={<DownloadIcon />}
              size={"xs"}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            borderRadius={"7px"}
            label={"Delete Beat"}
          >
            <IconButton
              colorScheme="red"
              variant={"outline"}
              icon={<FaTrashCan />}
              size={"xs"}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          borderTopRightRadius={"12px"}
          borderTopLeftRadius={"12px"}
          h={"650px"}
        >
          <DrawerCloseButton ml={5} />
          <MobileRecordingsPanel
            currentRecording={currentRecording}
            setCurrentRecording={setCurrentRecording}
            colorMode={colorMode}
          />
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MobileMainRecordingPlayer;
