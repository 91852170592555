import React from "react";
import "./MainDetailsPanel.css";
import { Box } from "@chakra-ui/react";
import ReleaesPanel from "./ReleasePanel/ReleaesPanel";
import { useReleasePanel } from "../../Context/ReleasePanelContext";
import { useBeatPanel } from "../../Context/BeatsPanelContext";
import BeatsPanel from "./BeatsPanel/BeatsPanel";
import { useLyricsPanel } from "../../Context/LyricsPanelContext";
import LyricsPanel from "./LyricsPanel/LyricsPanel";
import { useArtworksPanel } from "../../Context/ArtworkPanelContext";
import { useRecordingsPanel } from "../../Context/RecordingsPanelContext";
import ArtworksPanel from "./ArtworkPanel/ArtworksPanel";
import RecordingsPanel from "./RecordingsPanel/RecordingsPanel";
import { useAddLyricToRelease } from "../../Context/AddLyricToRelease";
import AddLyricsToRelease from "./ReleasePanel/AddLyricsToRelease";
import { useAddBeatToRelease } from "../../Context/AddBeatToRelease";
import AddBeatToRelease from "./ReleasePanel/AddBeatToRelease";
import LinkBeat from "./BeatsPanel/LinkBeat";
import { useLinkBeat } from "../../Context/LinkBeat";
import { useAddArtworkToRelease } from "../../Context/AddArtworkToRelease";
import AddArtworkToRelease from "./ReleasePanel/AddArtworkToRelease";
import { useLinkArtwork } from "../../Context/LinkArtworkContext";
import LinkArtwork from "./ArtworkPanel/LinkArtwork";
import { useCurrentBeat } from "../../Context/CurrentBeatContext";
import { useCurrentLyric } from "../../Context/CurrentLyricContext";
import { useAddBeatToLyric } from "../../Context/AddBeatToLyricContext";
import AddBeatToLyricsPanel from "./LyricsPanel/AddBeatToLyricsPanel";
import AddRecordingToLyricsPanel from "./LyricsPanel/AddRecordingToLyricsPanel";
import { useAddRecordingToLyric } from "../../Context/AddRecordingToLyricContext";
import { useCurrentRelease } from "../../Context/CurrentReleaseContext";
import { useCurrentArtwork } from "../../Context/CurrentArtworkContext";
import LinkRecording from "./RecordingsPanel/LinkRecording";
import { useCurrentRecording } from "../../Context/CurrentRecordingContext";
import { useLinkRecording } from "../../Context/LinkRecordingContext";
import { useAddRecordingToRelease } from "../../Context/AddRecordingToReleaseContext";
import AddRecordingToRelease from "./RecordingsPanel/AddRecordingToRelease";

const MainDetailsPanel = ({
  isCollapsed,
  colorMode,
  AuthToken,
  guestToken,
  userId,
  userName,
  userEmail,
  closedetailsPanelSetting,
  openDetailsPanelSettings,
}) => {
  const { releasePanel } = useReleasePanel();
  const { beatPanel } = useBeatPanel();
  const { lyricsPanel } = useLyricsPanel();
  const { artworksPanel } = useArtworksPanel();
  const { recordingsPanel } = useRecordingsPanel();
  const { addLyric } = useAddLyricToRelease();
  const { addBeat } = useAddBeatToRelease();
  const { linkBeat } = useLinkBeat();
  const { linkRecording } = useLinkRecording();
  const { addArtwork } = useAddArtworkToRelease();
  const { addRecording } = useAddRecordingToRelease();
  const { linkArtwork } = useLinkArtwork();
  const { currentBeat, setCurrentBeat } = useCurrentBeat();
  const { currentLyric, setCurrentLyric } = useCurrentLyric();
  const { currentRecording, setCurrentRecording } = useCurrentRecording();
  const { currentRelease, setCurrentRelease } = useCurrentRelease();
  const { currentArtwork, setCurrentArtwork } = useCurrentArtwork();

  return (
    <Box
      w={isCollapsed ? "" : "480px"}
      className={`main-details-panel ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      {!isCollapsed && (
        <>
          {beatPanel === "Beats" && currentBeat && (
            <Box className="details-surround">
              <BeatsPanel
                closedetailsPanelSetting={closedetailsPanelSetting}
                openDetailsPanelSettings={openDetailsPanelSettings}
                currentBeat={currentBeat}
                setCurrentBeat={setCurrentBeat}
                colorMode={colorMode}
                userId={userId}
                userName={userName}
                guestToken={guestToken}
                isCollapsed={isCollapsed}
              />
            </Box>
          )}

          {lyricsPanel === "Lyrics" && currentLyric && (
            <Box className="details-surround">
              <LyricsPanel
                setCurrentLyric={setCurrentLyric}
                currentLyric={currentLyric}
                colorMode={colorMode}
                userId={userId}
                userName={userName}
                guestToken={guestToken}
                openDetailsPanelSettings={openDetailsPanelSettings}
                closedetailsPanelSetting={closedetailsPanelSetting}
                isCollapsed={isCollapsed}
              />
            </Box>
          )}

          {artworksPanel === "Artworks" && currentArtwork && (
            <Box className="details-surround">
              <ArtworksPanel
                currentArtwork={currentArtwork}
                colorMode={colorMode}
                AuthToken={AuthToken}
                userEmail={userEmail}
                userId={userId}
                userName={userName}
                guestToken={guestToken}
              />
            </Box>
          )}
          {recordingsPanel === "Recordings" && currentRecording && (
            <Box className="details-surround">
              <RecordingsPanel
                AuthToken={AuthToken}
                userEmail={userEmail}
                userId={userId}
                userName={userName}
                guestToken={guestToken}
                currentRecording={currentRecording}
                setCurrentRecording={setCurrentRecording}
                colorMode={colorMode}
              />
            </Box>
          )}
          {releasePanel === "Releases" && currentRelease && (
            <Box className="details-surround">
              <ReleaesPanel
                AuthToken={AuthToken}
                userEmail={userEmail}
                userId={userId}
                userName={userName}
                guestToken={guestToken}
                currentRelease={currentRelease}
                setCurrentRelease={setCurrentRelease}
                colorMode={colorMode}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default MainDetailsPanel;
