import { Box, Flex, Icon, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "./TimeLineDates.css";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import axios from "axios";
import { API_ENDPOINT } from "../../../../Config/config";
import { GoClock } from "react-icons/go";
import { BiX } from "react-icons/bi";

const TimeLineDates = ({
  userId,
  currentBeat,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  beatId,
}) => {
  const formatAssignedDate = (dateString) => {
    const date = parseISO(dateString);
    return formatDistanceToNowStrict(date);
  };
  const [statusColors, setStatusColors] = useState({});
  const [customStatuses, setCustomStatuses] = useState([]);
  const toast = useToast();

  const fetchCustomStatuses = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/customstatuses`
      );
      if (response.data && response.data.customStatuses) {
        setCustomStatuses(response.data.customStatuses);
        const colors = {};
        response.data.customStatuses.forEach((status) => {
          colors[status.status] = status.color || "transparent";
        });
        setStatusColors(colors);
      } else {
        setCustomStatuses([]);
        setStatusColors({});
      }
    } catch (error) {
      console.error("Error fetching custom statuses:", error);
      toast({
        title: "Error",
        description: "Failed to load custom statuses.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCustomStatuses();
  }, [mutualSpaceId, boardId, tableId]);

  const formattedDate = formatAssignedDate(currentBeat.statusSetDate);
  const [timeValue, timeUnit] = formattedDate.split(" ");

  return (
    <Box
      className={`timline-to-main-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      boxShadow={"md"}
    >
      <Flex gap={"10px"} alignItems={"center"}>
        <Flex flexDirection={"column"} alignItems="center">
          <Text
            style={{ color: statusColors[currentBeat.status] }}
            fontSize="18px"
            fontWeight="bold"
          >
            {timeValue}
          </Text>
          <Text
            fontWeight="bold"
            style={{ color: statusColors[currentBeat.status] }}
            fontSize="10px"
          >
            {timeUnit}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TimeLineDates;
