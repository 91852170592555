import { createContext, useState, useContext } from "react";

// Create the context
const AddLyricToReleaseContext = createContext();

// Custom hook for easy context usage
export const useAddLyricToRelease = () => useContext(AddLyricToReleaseContext);

// Provider component
export const AddLyricToReleaseProvider = ({ children }) => {
  const [addLyric, setAddLyric] = useState(null); // New state and setter function

  return (
    <AddLyricToReleaseContext.Provider value={{ addLyric, setAddLyric }}>
      {children}
    </AddLyricToReleaseContext.Provider>
  );
};
