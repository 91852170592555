import React, { useState, useRef } from "react";
import {
  Button,
  Input,
  useToast,
  VStack,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  Flex,
  Icon,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../../Config/config";
import { BiMicrophone } from "react-icons/bi";
import "./MainRecordingUpload.css";

const MainRecordingUpload = ({
  colorMode,
  userId,
  mutualSpaceId,
  board,
  boardId,
  tableId,
  userName,
  setIsUploadModalOpen,
  fetchRecordingData,
}) => {
  const [files, setFiles] = useState([]);
  const [titles, setTitles] = useState([]);
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    const generatedTitles = selectedFiles.map(
      (file) => file.name.replace(/\.[^/.]+$/, "") // Removes file extension
    );
    setTitles(generatedTitles);
  };

  const handleUpload = async () => {
    setIsUploading(true);
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("files", file);
    });
    formData.append("titles", JSON.stringify(titles));
    formData.append("userId", userId);
    formData.append("name", userName); // Include userName in the form data

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/recording`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsUploading(false);
      toast({
        title: "Recording Uploaded!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setFiles([]);
      setTitles([]);
      setIsUploadModalOpen();
      fetchRecordingData();
    } catch (error) {
      setIsUploading(false);
      toast({
        title: "Upload Failed",
        description:
          error.response?.data?.message || "An error occurred during upload.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleResetUploads = () => {
    setFiles([]);
    setTitles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // This resets the input element
    }
  };

  return (
    <Flex p={1} flexDirection={"column"} w={"100%"} spacing={4}>
      <Flex mb={3} alignItems={"center"} gap={"10px"}>
        <Icon className="granticon" as={BiMicrophone} />
        <Text className="accessheader">Recording Upload</Text>
      </Flex>
      <Flex>
        <Text className="recordingmodaltext">
          When uploading a recording file, the system will transcribe the lyrics
          from the recording. After the upload is completed, the recorded track
          will be stored and show in the table below, as well as a sperate lyric
          file linked to this recording!
        </Text>
      </Flex>
      <Input
        ref={fileInputRef}
        type="file"
        accept="audio/*"
        onChange={handleFileChange}
        multiple
        hidden
      />
      {!files.length > 0 && (
        <Button mt={2} h={"100px"} onClick={() => fileInputRef.current.click()}>
          Select Recording(s)
        </Button>
      )}

      <HStack mt={2} w={"100%"} spacing={4} wrap="wrap">
        {titles.map((title, index) => (
          <Tag
            w={"100%"}
            key={index}
            borderRadius="full"
            variant="solid"
            colorScheme="blue"
          >
            <Flex
              alignItems={"center"}
              w={"100%"}
              justifyContent={"space-between"}
            >
              <TagLabel>{title}</TagLabel>
              <TagCloseButton
                onClick={() => {
                  setTitles(titles.filter((_, i) => i !== index));
                  setFiles(files.filter((_, i) => i !== index));
                }}
              />
            </Flex>
          </Tag>
        ))}
      </HStack>
      <Flex mt={5} alignItems={"center"} w={"100%"} gap={"20px"}>
        {files.length > 0 && (
          <Button
            disabled={isUploading}
            w={"100%"}
            colorScheme="red"
            onClick={handleResetUploads}
          >
            Reset Uploads
          </Button>
        )}
        {files.length > 0 && (
          <Button
            w={"100%"}
            colorScheme="blue"
            isLoading={isUploading}
            disabled={isUploading}
            onClick={handleUpload}
          >
            Upload Recordings
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default MainRecordingUpload;
