import React, { useState, useEffect } from "react";
import {
  Box,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Flex,
  Button,
  Icon,
  Text,
  Avatar,
  AvatarBadge,
  Tooltip,
  SimpleGrid,
  useDisclosure, // Import useDisclosure hook for modal
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Center,
} from "@chakra-ui/react";
import "./BeatsTable.css";
import { FaChevronRight } from "react-icons/fa6";
import { BsClockHistory, BsHeadphones, BsPlusLg } from "react-icons/bs";
import { MdOutlineSortByAlpha } from "react-icons/md";
import { AddIcon } from "@chakra-ui/icons";
import MainBeatsPlayer from "../../Players/MainBeatsPlayer/MainBeatsPlayer";
import { useBeatsKanban } from "../../../Context/BeatsKanbanContext";
import BeatsKanbanBoard from "../../KanbanBoard/BeatsKanbanBoard";
import MobileMainBeatsPlayer from "../../Players/MobilePlayers/MobileMainBeatsPlayer";
import BeatsSearch from "../../Search/BeatsSearch/BeatsSearch";
import BeatUploadForm from "../../Forms/BeatForms/BeatUploadForm";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { useEmptyBeat } from "../../../Context/EmptyStates/EmptyBeatContext";
import { useBeatPanel } from "../../../Context/BeatsPanelContext";
import { useCurrentBeat } from "../../../Context/CurrentBeatContext";
import UserAvatars from "../../List/Users/UserAvatars";
import { useNavigate } from "react-router-dom";
import NoBeats from "../../EmptyStates/NoBeats";
import V2BeatsPlayer from "../../Players/MainBeatsPlayer/V2Player/V2BeatsPlayer";

const BeatsTable = ({
  isMobileView,
  colorMode,
  userId,
  userName,
  isSidebarCollapsed,
  guestToken,
  openDetailsPanelSettings,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Hook to control modal
  const { beatsKanban, setBeatsKanban } = useBeatsKanban();
  const [beats, setBeats] = useState([]);
  const { setIsBeatsEmpty } = useEmptyBeat();
  const { setBeatPanel } = useBeatPanel();
  const [isLoading, setIsLoading] = useState(false); // Add this line
  const { currentBeat, setCurrentBeat } = useCurrentBeat();
  const loggedInUserId = userId;
  const [refreshBeats, setRefreshBeats] = useState(false);
  const [sortOption, setSortOption] = useState("Newest");
  const [sortDirection, setSortDirection] = useState("asc");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null); // New state for tracking the selected user
  const [isFilteredByUser, setIsFilteredByUser] = useState(false);

  const fetchBeats = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_ENDPOINT}api/get/uploaded/beats`);
      let userBeats = response.data;

      if (guestToken) {
        userBeats = userBeats.filter((beat) => beat.mock === "true");
      } else {
        userBeats = userBeats.filter(
          (beat) => beat.user.userId === loggedInUserId
        );
      }

      userBeats.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));

      setBeats(userBeats);
    } catch (error) {
      console.error("Error fetching beats:", error);
    } finally {
      setIsLoading(false); // End loading regardless of result
    }
  };

  useEffect(() => {
    fetchBeats();
  }, [refreshBeats, sortOption, sortDirection, loggedInUserId]);

  const handleDeleteBeat = (id) => {
    setBeats(beats.filter((beat) => beat._id !== id));

    if (currentBeat && currentBeat._id === id) {
      setCurrentBeat(null); // Or set it to another beat if you prefer
    }
  };

  const onUpdateBeatStatus = (beatId, newStatus) => {
    setBeats((prevBeats) =>
      prevBeats.map((beat) =>
        beat._id === beatId ? { ...beat, status: newStatus } : beat
      )
    );
  };

  const handleAvatarClick = (userId) => {
    fetchBeatsByUser(userId);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_ENDPOINT}api/users`);
        const filteredUsers = response.data.data.filter(
          (user) =>
            Array.isArray(user.usersWithPermissions) &&
            user.usersWithPermissions.some((perm) => perm.userId === userId)
        );
        setUsers(filteredUsers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [userId]);

  const fetchBeatsByUser = async (userId) => {
    try {
      setSelectedUserId(userId); // Set the selected user ID

      const response = await axios.get(
        `${API_ENDPOINT}api/beats/user/${userId}`
      );
      setBeats(response.data.data); // Update beats with the user-specific beats
      setIsFilteredByUser(true);
    } catch (error) {
      console.error("Error fetching user-specific beats:", error);
    }
  };

  const clearUserFilter = () => {
    setIsFilteredByUser(false);
    setSelectedUserId(null);
    fetchBeats();
    setCurrentBeat();
  };

  const navigate = useNavigate();

  const toPermissions = () => {
    navigate("/permissions"); // Update with correct route
  };

  return (
    <Box className="main-table-container">
      <Box
        className={`top-of-table ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Box>
          <Flex alignItems={"center"} gap={"10px"}>
            <Menu
              border={
                colorMode === "light"
                  ? "2px solid rgba(153, 178, 198, 0.23)"
                  : "2px solid rgba(43, 46, 54, 1)"
              }
              bg={colorMode === "light" ? "white" : "black"}
            >
              {({ isOpen }) => (
                <>
                  <Flex alignItems="center" gap={2}>
                    <Tooltip
                      placement="bottom"
                      borderRadius={"7px"}
                      label={"Filter"}
                    >
                      <MenuButton
                        size={isMobileView ? "xs" : "sm"}
                        as={Button}
                        rightIcon={
                          <FaChevronRight
                            style={{
                              transition: "transform 0.2s",
                              transform: isOpen ? "rotate(90deg)" : "none",
                            }}
                          />
                        }
                      >
                        Filter
                      </MenuButton>
                    </Tooltip>
                  </Flex>
                  <MenuList
                    p={2}
                    bg={colorMode === "light" ? "white" : "black"}
                    zIndex={"9999"}
                  >
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                    >
                      <Icon as={BsClockHistory} fontSize="17px" />

                      <Text>Newest</Text>
                    </MenuItem>
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                    >
                      <Icon as={MdOutlineSortByAlpha} fontSize="17px" />

                      <Text>A - Z</Text>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
        </Box>
        <Box className="avatarssurround">
          {!guestToken && (
            <>
              <Flex>
                <UserAvatars users={users} onAvatarClick={handleAvatarClick} />
                <Box className="aliagingn" p={0}>
                  <Tooltip
                    placement="bottom"
                    borderRadius={"7px"}
                    label={"Access User Beats"}
                  >
                    <Avatar
                      onClick={toPermissions}
                      size={"sm"}
                      ml={-3} // Apply negative margin from the second avatar onwards
                      _hover={{
                        transform: "translateY(-2px)",
                        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                        cursor: "pointer",
                        opacity: "60%",
                      }}
                    >
                      <AvatarBadge bg="green.500" boxSize="1.25em">
                        <AddIcon color="white" />
                      </AvatarBadge>
                    </Avatar>
                  </Tooltip>
                </Box>
              </Flex>
            </>
          )}
        </Box>
        <Flex alignItems={"center"} gap={"10px"}>
          {!guestToken && (
            <>
              <Box>
                <Tooltip
                  placement="bottom"
                  borderRadius={"7px"}
                  label={"Upload New Beat"}
                >
                  <Button
                    onClick={onOpen}
                    variant={"outline"}
                    size={isMobileView ? "xs" : "sm"}
                  >
                    <Flex gap={"5px"} alignItems={"center"}>
                      <Icon as={BsPlusLg} />
                      <Text>Upload</Text>
                    </Flex>
                  </Button>
                </Tooltip>
              </Box>
            </>
          )}
        </Flex>
      </Box>
      <Box className="controlheight" h={"93%"}>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : beats.length === 0 ? (
          <Box>
            <NoBeats
              onOpen={onOpen}
              colorMode={colorMode}
              isMobileView={isMobileView}
            />
          </Box>
        ) : (
          <SimpleGrid columns={{ base: 1, md: 1 }} spacing={2} mt={2} p={2}>
            {beats.map((beat, index) => (
              <Box key={beat.id} p={1}>
                {isMobileView ? (
                  <MobileMainBeatsPlayer beat={beat} colorMode={colorMode} />
                ) : (
                  <V2BeatsPlayer
                    isSidebarCollapsed={isSidebarCollapsed}
                    onDeleteBeat={handleDeleteBeat}
                    fetchBeats={fetchBeats}
                    beat={beat}
                    isMobileView={isMobileView}
                    colorMode={colorMode}
                    userName={userName}
                    userId={userId}
                    onUpdateBeatStatus={onUpdateBeatStatus}
                    guestToken={guestToken}
                    openDetailsPanelSettings={openDetailsPanelSettings}
                  />
                )}
              </Box>
            ))}
          </SimpleGrid>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon className="modal-title-icon" as={BsHeadphones} />
              <Text className="modal-title-text">Beat Upload</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BeatUploadForm
              colorMode={colorMode}
              userName={userName}
              userId={userId}
              onClose={onClose}
              fetchBeats={fetchBeats}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BeatsTable;
