import { createContext, useState, useContext } from "react";

const ReleasesKanbanContext = createContext();

export const useReleasesKanban = () => useContext(ReleasesKanbanContext);

export const ReleasesKanbanProvider = ({ children }) => {
  const [releasesKanban, setReleasesKanban] = useState(null);

  return (
    <ReleasesKanbanContext.Provider
      value={{ releasesKanban, setReleasesKanban }}
    >
      {children}
    </ReleasesKanbanContext.Provider>
  );
};
