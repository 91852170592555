import React from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Image,
  List,
  ListItem,
  ListIcon,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react";
import "./SectionTwoAbout.css";

const SectionTwoAbout = ({ colorMode }) => {
  return (
    <Box
      className={`SectionTwoAbout-section ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex className="first-sectionp" flexDirection={"column"}>
        <Text className="two-title">
          One stop shop for organization and optimization
        </Text>
        <Text className="twosubtitle">
          More transparency into the status of assets to maximize monetization
          opportunities
        </Text>
      </Flex>
      <Flex className="slopsa">
        <Flex
          className={`mainslpone ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
          boxShadow={"md"}
        >
          one
        </Flex>
        <Flex
          className={`mainslpone ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
          boxShadow={"md"}
        >
          Two
        </Flex>
      </Flex>
    </Box>
  );
};

export default SectionTwoAbout;
