import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import "./FeaturesDropdown.css";
import { TiCloudStorage, TiDocument } from "react-icons/ti";
import {
  MdAddLink,
  MdOutlineLyrics,
  MdOutlineManageHistory,
} from "react-icons/md";
import { PiUserCirclePlus, PiUsersThreeFill } from "react-icons/pi";
import {
  FaCalendarCheck,
  FaComments,
  FaMicrophone,
  FaMusic,
  FaShareAlt,
  FaTasks,
} from "react-icons/fa";
import { SiAudiomack } from "react-icons/si";
import { BsFolder, BsImage } from "react-icons/bs";
import { RiFolderMusicLine, RiMailSendLine } from "react-icons/ri";
import { FaSpotify } from "react-icons/fa";
import { GiSandsOfTime } from "react-icons/gi";
import { VscVmActive } from "react-icons/vsc";
import { ArrowRightIcon } from "@chakra-ui/icons";
import { FaAngleRight } from "react-icons/fa6";

const FeaturesDropdown = ({
  colorMode,
  onMouseEnter,
  onMouseLeave,
  setShowDropdown,
}) => {
  const [iconColors, setIconColors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const icons = [
      "SiAudiomack",
      "FaMicrophone",
      "BsImage",
      "MdOutlineLyrics",
      "BsFolder",
      "RiFolderMusicLine",
      "TiDocument",
      "FaSpotify",
      "FaTasks",
      "GiSandsOfTime",
      "FaCalendarCheck",
      "MdAddLink",
      "RiMailSendLine",
      "FaComments",
      "PiUserCirclePlus",
      "FaShareAlt",
      "VscVmActive",
    ];
    const newIconColors = {};
    icons.forEach((icon, index) => {
      const color = `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")}`;
      newIconColors[icon] = color;
    });
    setIconColors(newIconColors);
  }, []);

  const toStorage = () => {
    navigate("/storage");
    setShowDropdown(false);
  };

  return (
    <Flex
      className={`FeaturesDropdown-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Flex
        className={`FeaturesDropdown-surround ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex
          gap={"60px"}
          justifyContent={"space-between"}
          h={"100%"}
          w={"100%"}
        >
          <Flex w={"100%"} flexDirection={"column"} gap={"10px"}>
            <Flex
              borderBottom={".5px solid rgba(65, 77, 85, 1)"}
              pb={3}
              className="titledspace"
              alignItems={"center"}
              gap={"10px"}
            >
              <Flex
                alignItems={"center"}
                w={"100%"}
                justifyContent={"space-between"}
              >
                <Flex gap={"5px"} alignItems={"center"}>
                  <Icon fontSize={"27px"} as={TiCloudStorage} />
                  <Text
                    className={`titledspacetext ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Storage
                  </Text>
                </Flex>
                {/* <Button onClick={toStorage} size={"sm"} variant={"ghost"}>
                  <Flex alignItems={"center"} gap={"5px"}>
                    More Details
                    <Icon as={FaAngleRight} />
                  </Flex>
                </Button> */}
              </Flex>
            </Flex>

            <Flex overflowX={"auto"} flexDirection={"column"} gap={"10px"}>
              <Box
                className={`Features-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <Icon
                    opacity={"50%"}
                    p={1}
                    className="feature-icon"
                    fontSize={"43px"}
                    as={SiAudiomack}
                    color={iconColors["SiAudiomack"]}
                  />
                  <Flex
                    flexDirection={"column"}
                    gap={"2px"}
                    justifyContent={"start"}
                    alignItems={"start"}
                  >
                    <Text
                      className={`texthead ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Tracks
                    </Text>
                    <Text
                      className={`textsub ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Beats & Instrumental Tracks{" "}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Box
                className={`Features-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <Icon
                    opacity={"50%"}
                    p={1}
                    className="feature-icon"
                    fontSize={"43px"}
                    as={FaMicrophone}
                    color={iconColors["FaMicrophone"]}
                  />
                  <Flex
                    flexDirection={"column"}
                    gap={"2px"}
                    justifyContent={"start"}
                    alignItems={"start"}
                  >
                    <Text
                      className={`texthead ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Recordings
                    </Text>
                    <Text
                      className={`textsub ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Recorded Music{" "}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Box
                className={`Features-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <Icon
                    opacity={"50%"}
                    p={1}
                    className="feature-icon"
                    fontSize={"43px"}
                    as={BsImage}
                    color={iconColors["BsImage"]}
                  />
                  <Flex
                    flexDirection={"column"}
                    gap={"2px"}
                    justifyContent={"start"}
                    alignItems={"start"}
                  >
                    <Text
                      className={`texthead ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Images
                    </Text>
                    <Text
                      className={`textsub ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Artwork & Image files{" "}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Box
                className={`Features-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <Icon
                    opacity={"50%"}
                    p={1}
                    className="feature-icon"
                    fontSize={"43px"}
                    as={MdOutlineLyrics}
                    color={iconColors["MdOutlineLyrics"]}
                  />
                  <Flex
                    flexDirection={"column"}
                    gap={"2px"}
                    justifyContent={"start"}
                    alignItems={"start"}
                  >
                    <Text
                      className={`texthead ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Lyrics
                    </Text>
                    <Text
                      className={`textsub ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Written Lyrics{" "}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Box
                className={`Features-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <Icon
                    opacity={"50%"}
                    p={1}
                    className="feature-icon"
                    fontSize={"43px"}
                    as={BsFolder}
                    color={iconColors["BsFolder"]}
                  />
                  <Flex
                    flexDirection={"column"}
                    gap={"2px"}
                    justifyContent={"start"}
                    alignItems={"start"}
                  >
                    <Text
                      className={`texthead ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Folders
                    </Text>
                    <Text
                      className={`textsub ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Full Folders & Individual Files{" "}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Box
                className={`Features-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <Icon
                    opacity={"50%"}
                    p={1}
                    className="feature-icon"
                    fontSize={"43px"}
                    as={RiFolderMusicLine}
                    color={iconColors["RiFolderMusicLine"]}
                  />
                  <Flex
                    flexDirection={"column"}
                    gap={"2px"}
                    justifyContent={"start"}
                    alignItems={"start"}
                  >
                    <Text
                      className={`texthead ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Session Files
                    </Text>
                    <Text
                      className={`textsub ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Engineering & Production Files
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Box
                className={`Features-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <Icon
                    opacity={"50%"}
                    p={1}
                    className="feature-icon"
                    fontSize={"45px"}
                    as={TiDocument}
                    color={iconColors["TiDocument"]}
                  />
                  <Flex
                    flexDirection={"column"}
                    gap={"2px"}
                    justifyContent={"start"}
                    alignItems={"start"}
                  >
                    <Text
                      className={`texthead ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Documents
                    </Text>
                    <Text
                      className={`textsub ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Metadata & Form Documents
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Box
                className={`Features-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <Icon
                    opacity={"50%"}
                    p={1}
                    className="feature-icon"
                    fontSize={"45px"}
                    as={FaSpotify}
                    color={"green.300"}
                  />
                  <Flex
                    flexDirection={"column"}
                    gap={"2px"}
                    justifyContent={"start"}
                    alignItems={"start"}
                  >
                    <Text
                      className={`texthead ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Releases
                    </Text>
                    <Text
                      className={`textsub ${
                        colorMode === "light" ? "lightmode" : "darkmode"
                      }`}
                    >
                      Commercially Released Music
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Flex>
          <Flex w={"100%"} flexDirection={"column"} gap={"10px"}>
            <Flex
              borderBottom={".5px solid rgba(65, 77, 85, 1)"}
              pb={3}
              className="titledspace"
              alignItems={"center"}
              gap={"10px"}
            >
              <Icon fontSize={"27px"} as={MdOutlineManageHistory} />
              <Text
                className={`titledspacetext ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                Management
              </Text>
            </Flex>
            <Box
              className={`Features-item ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                gap={"10px"}
              >
                <Icon
                  opacity={"50%"}
                  p={1}
                  className="feature-icon"
                  fontSize={"43px"}
                  as={FaTasks}
                  color={iconColors["FaTasks"]}
                />
                <Flex
                  flexDirection={"column"}
                  gap={"2px"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Text
                    className={`texthead ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Task Prioritization
                  </Text>
                  <Text
                    className={`textsub ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Optimize your to-do List
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box
              className={`Features-item ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                gap={"10px"}
              >
                <Icon
                  opacity={"50%"}
                  p={1}
                  className="feature-icon"
                  fontSize={"43px"}
                  as={GiSandsOfTime}
                  color={iconColors["GiSandsOfTime"]}
                />
                <Flex
                  flexDirection={"column"}
                  gap={"2px"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Text
                    className={`texthead ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Setting Statuses
                  </Text>
                  <Text
                    className={`textsub ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Track usability between assets
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box
              className={`Features-item ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                gap={"10px"}
              >
                <Icon
                  opacity={"50%"}
                  p={1}
                  className="feature-icon"
                  fontSize={"43px"}
                  as={FaCalendarCheck}
                  color={iconColors["FaCalendarCheck"]}
                />
                <Flex
                  flexDirection={"column"}
                  gap={"2px"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Text
                    className={`texthead ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Schedule
                  </Text>
                  <Text
                    className={`textsub ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Plan Release Dates
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box
              className={`Features-item ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                gap={"10px"}
              >
                <Icon
                  opacity={"50%"}
                  p={1}
                  className="feature-icon"
                  fontSize={"43px"}
                  as={MdAddLink}
                  color={iconColors["MdAddLink"]}
                />
                <Flex
                  flexDirection={"column"}
                  gap={"2px"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Text
                    className={`texthead ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Link Assets
                  </Text>
                  <Text
                    className={`textsub ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Pair Assets Together
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box
              className={`Features-item ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                gap={"10px"}
              >
                <Icon
                  opacity={"50%"}
                  p={1}
                  className="feature-icon"
                  fontSize={"43px"}
                  as={VscVmActive}
                  color={iconColors["VscVmActive"]}
                />
                <Flex
                  flexDirection={"column"}
                  gap={"2px"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Text
                    className={`texthead ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Activity Logs
                  </Text>
                  <Text
                    className={`textsub ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Track activity between assets
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <Flex w={"100%"} flexDirection={"column"} gap={"10px"}>
            <Flex
              borderBottom={".5px solid rgba(65, 77, 85, 1)"}
              pb={3}
              className="titledspace"
              alignItems={"center"}
              gap={"10px"}
            >
              <Icon fontSize={"27px"} as={PiUsersThreeFill} />
              <Text
                className={`titledspacetext ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                Collaboration
              </Text>
            </Flex>
            <Box
              className={`Features-item ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                gap={"10px"}
              >
                <Icon
                  opacity={"50%"}
                  p={1}
                  className="feature-icon"
                  fontSize={"43px"}
                  as={RiMailSendLine}
                  color={iconColors["RiMailSendLine"]}
                />
                <Flex
                  flexDirection={"column"}
                  gap={"2px"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Text
                    className={`texthead ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Messaging
                  </Text>
                  <Text
                    className={`textsub ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Communcate with your Team
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box
              className={`Features-item ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                gap={"10px"}
              >
                <Icon
                  opacity={"50%"}
                  p={1}
                  className="feature-icon"
                  fontSize={"43px"}
                  as={FaComments}
                  color={iconColors["FaComments"]}
                />
                <Flex
                  flexDirection={"column"}
                  gap={"2px"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Text
                    className={`texthead ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Comments
                  </Text>
                  <Text
                    className={`textsub ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Share feedback on Assets
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box
              className={`Features-item ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                gap={"10px"}
              >
                <Icon
                  opacity={"50%"}
                  p={1}
                  className="feature-icon"
                  fontSize={"43px"}
                  as={PiUserCirclePlus}
                  color={iconColors["PiUserCirclePlus"]}
                />
                <Flex
                  flexDirection={"column"}
                  gap={"2px"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Text
                    className={`texthead ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Access Control
                  </Text>
                  <Text
                    className={`textsub ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Grant Access to files
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box
              className={`Features-item ${
                colorMode === "light" ? "lightmode" : "darkmode"
              }`}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                gap={"10px"}
              >
                <Icon
                  opacity={"50%"}
                  p={1}
                  className="feature-icon"
                  fontSize={"43px"}
                  as={FaShareAlt}
                  color={iconColors["FaShareAlt"]}
                />
                <Flex
                  flexDirection={"column"}
                  gap={"2px"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Text
                    className={`texthead ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    File Sharing
                  </Text>
                  <Text
                    className={`textsub ${
                      colorMode === "light" ? "lightmode" : "darkmode"
                    }`}
                  >
                    Send quick links to files
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FeaturesDropdown;
