import React, { useState } from "react";
import {
  Box,
  Button,
  Text,
  Flex,
  Input,
  Icon,
  Tooltip,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";

const TaskPageTable = ({ colorMode, userId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableName, setTableName] = useState("");

  return (
    <Box p={1}>
      <Flex>
        <Tooltip placement="bottom" borderRadius={"7px"} label={"New Table"}>
          <Button
            bg={
              colorMode === "light"
                ? "rgba(177, 197, 246, 1)"
                : "rgba(102, 80, 245, 1)"
            }
            size={"sm"}
            onClick={onOpen}
          >
            <Flex alignItems={"center"} gap={"3px"}>
              <Text>New </Text>
              <Icon as={BsPlus} />
            </Flex>
          </Button>
        </Tooltip>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Table</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Table Name"
              value={tableName}
              onChange={(e) => setTableName(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue">Create Table</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TaskPageTable;
