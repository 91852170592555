import React, { useContext, useEffect, useState } from "react";
import {
  Text,
  IconButton,
  Flex,
  Icon,
  Tag,
  Avatar,
  TagLabel,
} from "@chakra-ui/react";
import { BsPlayCircle, BsPauseCircle, BsTrash } from "react-icons/bs";
import "./MainLyricLinkedBeat.css";
import { MusicPlayerContext } from "../../../../Context/MusicPlayerContext";

const MainLyricLinkedBeat = ({ beat, colorMode }) => {
  const { playTrack, stopTrack, currentTrack, seekTrack } =
    useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [localDuration, setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack && currentTrack.src === beat.fileUrl && !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(beat.fileUrl);
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
      <Tag
        p={1}
        justifyContent={"space-between"}
        w={"100%"}
        size="sm"
        colorScheme="blue"
        borderRadius="full"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Flex alignItems={"center"}>
          {isCurrentBeatPlaying ? (
            <>
              <Icon
                mr={2}
                onClick={(e) => {
                  e.stopPropagation();
                  togglePlayPause();
                }}
                fontSize={"20px"}
                as={BsPauseCircle}
                _hover={{ cursor: "pointer" }}
              />
            </>
          ) : (
            <>
              <Icon
                mr={2}
                onClick={(e) => {
                  e.stopPropagation();
                  togglePlayPause();
                }}
                fontSize={"20px"}
                as={BsPlayCircle}
                _hover={{ cursor: "pointer" }}
              />
            </>
          )}
          <TagLabel className="beattitletag">
            <Text>{beat?.title}</Text>
          </TagLabel>
        </Flex>
        <Flex>
          {isHovered && (
            <Icon
              _hover={{ cursor: "pointer", opacity: "50%" }}
              mr={3}
              as={BsTrash}
              size={"xs"}
              color={"red"}
            />
          )}
        </Flex>
      </Tag>
    </Flex>
  );
};

export default MainLyricLinkedBeat;
