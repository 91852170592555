import { createContext, useState, useContext } from "react";

const AddRecordingToLyricContext = createContext();

export const useAddRecordingToLyric = () =>
  useContext(AddRecordingToLyricContext);

export const AddRecordingToLyricProvider = ({ children }) => {
  const [addRecordingToLyric, setAddRecordingToLyric] = useState(null);

  return (
    <AddRecordingToLyricContext.Provider
      value={{ addRecordingToLyric, setAddRecordingToLyric }}
    >
      {children}
    </AddRecordingToLyricContext.Provider>
  );
};
