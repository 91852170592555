import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  Flex,
  Icon,
  Tooltip,
  useToast,
  useDisclosure,
  Spinner,
  Center,
  SimpleGrid,
} from "@chakra-ui/react";
import axios from "axios";
import { BsPlus } from "react-icons/bs";
import { API_ENDPOINT } from "../../../../Config/config";
import NoPlanningTables from "../../../EmptyStates/NoPlanningTables";
import MobilePlanningTaskItem from "../../../PlanningTaskItem/MobilePlanningTaskItem";

const MobileTaskPlanning = ({ colorMode, userId, userName }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableName, setTableName] = useState("");
  const toast = useToast();
  const [tables, setTables] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const createTable = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_ENDPOINT}api/tasktable/create`, {
        tablename: tableName,
        items: [], // Assuming the table starts with no items, modify as needed
        userId: userId,
        name: userName, // Corrected from user to name
      });
      toast({
        title: "Table created",
        description: "Your new task table has been successfully created.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Clear the table name input after successful creation
      setTableName("");
      onClose(); // Close the modal
      setIsLoading(false);
      fetchTables();
    } catch (error) {
      console.error("Error creating task table:", error);
      toast({
        title: "Error",
        description: "There was an error creating your task table.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  // const fetchTables = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get(`${API_ENDPOINT}api/tasktables`);
  //     setTables(response.data.taskTables || []); // Corrected from tables to taskTables
  //   } catch (error) {
  //     if (!error.response || error.response.status !== 404) {
  //       toast({
  //         title: "Error fetching folders",
  //         description:
  //           error.response?.data?.message ||
  //           "An error occurred while fetching folders.",
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //       });
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchTables();
  // }, []);

  const fetchTables = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/tasktables/user/${userId}`
      );
      setTables(response.data.taskTables || []);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching task tables for user:", error);
      toast({
        title: "Error fetching task tables",
        description:
          error.response?.data?.message ||
          "An error occurred while fetching task tables.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTables();
  }, []);

  return (
    <Box>
      <Flex>
        {tables.length > 0 && (
          <>
            <Tooltip
              placement="bottom"
              borderRadius={"7px"}
              label={"New Table"}
            >
              <Button
                bg={
                  colorMode === "light"
                    ? "rgba(177, 197, 246, 1)"
                    : "rgba(102, 80, 245, 1)"
                }
                size={"sm"}
                onClick={onOpen}
              >
                <Flex alignItems={"center"} gap={"3px"}>
                  <Text>New </Text>
                  <Icon as={BsPlus} />
                </Flex>
              </Button>
            </Tooltip>
          </>
        )}
      </Flex>
      <Box>
        <Box>
          {isLoading ? (
            <Center mt={2}>
              <Spinner />
            </Center>
          ) : tables.length > 0 ? (
            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={5} mt={5} p={1}>
              {tables.map((table) => (
                <>
                  <MobilePlanningTaskItem
                    tableId={table._id}
                    table={table}
                    colorMode={colorMode}
                    fetchTables={fetchTables}
                    userId={userId}
                  />
                </>
              ))}
            </SimpleGrid>
          ) : (
            <Box>
              <NoPlanningTables colorMode={colorMode} onOpen={onOpen} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MobileTaskPlanning;
