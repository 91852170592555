import { createContext, useState, useContext } from "react";

const EmptyLyricContext = createContext();

export const useEmptyLyric = () => useContext(EmptyLyricContext);

export const EmptyLyricProvider = ({ children }) => {
  const [isLyricsEmpty, setIsLyricsEmpty] = useState(true);

  return (
    <EmptyLyricContext.Provider value={{ isLyricsEmpty, setIsLyricsEmpty }}>
      {children}
    </EmptyLyricContext.Provider>
  );
};
