import {
  Box,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  useToast,
  Image,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { FaRegThumbsUp } from "react-icons/fa";
import React, { useState, useRef, useEffect } from "react";
import "./MessageItem.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";
import EditingMessage from "./EditingMessage";
import avatarMock from "../../Svggs/avatarmock.svg";

const MessageItem = ({
  message,
  colorMode,
  channelId,
  userId,
  userName,
  fetchChannels,
  refreshSelectedChannel,
  guestToken,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const toast = useToast();

  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const formatESTDate = (dateString) => {
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/New_York",
    }).format(new Date(dateString));
  };

  const handleDeleteMessage = async () => {
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/channels/${channelId}/messages/${message._id}`
      );
      if (response.status === 200) {
        toast({
          title: "Message deleted.",
          description: "The message was successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "There was an error deleting the message.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setIsDeleteModalOpen(false); // Close the modal after deletion
      refreshSelectedChannel();
    } catch (error) {
      console.error("Error deleting message:", error);
      toast({
        title: "Deletion error",
        description: "Failed to delete the message. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);

  const pickerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    }

    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  const handleSaveEditedMessage = async (newContent) => {
    setIsEditing(false);
  };

  const [editedContent, setEditedContent] = useState(message.messageContent);

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleEmojiSelect = async (emoji) => {
    const reactionData = {
      userId: userId,
      name: userName,
      reaction: emoji.native,
    };

    try {
      const response = await axios.patch(
        `${API_ENDPOINT}api/channels/${channelId}/messages/${message._id}/reactions`,
        reactionData
      );
      if (response.status === 200) {
        toast({
          title: "Reaction Added",
          description: "Your reaction has been added to the message.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setShowEmojiPicker(false);
        fetchChannels();
      } else {
        toast({
          title: "Failed to Add Reaction",
          description: "Unable to add your reaction to the message.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error adding reaction:", error);
      toast({
        title: "Reaction Error",
        description: "There was an error adding your reaction to the message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [reactionCounts, setReactionCounts] = useState({});

  useEffect(() => {
    const countReactions = (reactions) => {
      const counts = {};
      reactions.forEach((reaction) => {
        counts[reaction.reaction] = (counts[reaction.reaction] || 0) + 1;
      });
      return counts;
    };

    if (message.reactions) {
      setReactionCounts(countReactions(message.reactions));
    }
  }, [message.reactions]);

  return (
    <Box
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      position="relative"
    >
      <HStack
        p={2}
        className={`content-item ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        borderRadius="lg"
        spacing={3}
        align="start"
      >
        {isEditing ? (
          <EditingMessage
            messageContent={editedContent}
            messageId={message._id}
            channelId={channelId}
            onSave={handleSaveEditedMessage}
            onCancel={handleCancelEdit}
            colorMode={colorMode}
          />
        ) : (
          <>
            <Flex flexDirection={"column"}>
              <Flex gap={"8px"} alignItems={"center"}>
                <Flex>
                  <Image src={avatarMock} className="avatarmock" />
                </Flex>

                <Flex flexDirection={"column"}>
                  <Flex alignItems={"center"} gap={"8px"}>
                    <Text className="sendfontstyle">{message.sender.name}</Text>
                    <Text className="sendingtime" color="gray.500">
                      {formatESTDate(message.createdAt)}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text className="messagecontent">
                      {message.messageContent}
                    </Text>
                  </Flex>
                  <Flex>
                    {message?.fileUrl && (
                      <Box className="messagefilecontainer">
                        <Image className="message-file" src={message.fileUrl} />
                      </Box>
                    )}
                  </Flex>
                </Flex>
              </Flex>
              <Flex>
                <Flex mt={1} className="spacedemojis">
                  {Object.entries(reactionCounts).map(([emoji, count]) => (
                    <Button
                      borderRadius={"9px"}
                      variant={"outline"}
                      bg={
                        colorMode === "light"
                          ? "rgba(252, 252, 252, 1)"
                          : "rgba(12, 18, 44, 1)"
                      }
                      key={emoji}
                      size="xs"
                      mr={1}
                    >
                      <Flex alignItems={"center"} gap={"4px"}>
                        <Flex>{emoji}</Flex>
                        <Flex>{count}</Flex>
                      </Flex>
                    </Button>
                  ))}
                </Flex>
              </Flex>
            </Flex>
            {isHovering && (
              <>
                <HStack
                  className={`hoverboxitem ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                  boxShadow={"md"}
                  position="absolute"
                  top={-3}
                  right="5" // adjust this value based on your layout and icon size
                  spacing={2}
                >
                  <Button
                    size="sm"
                    leftIcon={<FaRegThumbsUp />}
                    variant="ghost"
                    onClick={(e) => {
                      e.stopPropagation();
                      {
                        !guestToken && toggleEmojiPicker(); // Prevent triggering parent's onClick
                      }
                    }}
                  >
                    React
                  </Button>
                  <IconButton
                    aria-label="Delete message"
                    icon={<DeleteIcon />}
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      {
                        !guestToken && setIsDeleteModalOpen(true); // Prevent triggering parent's onClick
                      }
                    }}
                  />
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<BsThreeDotsVertical />}
                      size="sm"
                      variant="ghost"
                    />
                    <MenuList>
                      <MenuItem>Mark unread</MenuItem>
                      <MenuItem command="L">Copy link</MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          {
                            !guestToken && handleEditClick(); // Prevent triggering parent's onClick
                          }
                        }}
                        command="E"
                      >
                        Edit message
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>
                {showEmojiPicker && (
                  <Box
                    position="absolute"
                    zIndex={"9999"}
                    bottom="50px"
                    right="0px"
                    ref={pickerRef}
                  >
                    <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </HStack>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Delete Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="anyvis">
            Are you sure you want to delete this message?
          </ModalBody>
          <ModalFooter w={"100%"}>
            <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
              <Button
                w={"100%"}
                variant="outline"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                w={"100%"}
                colorScheme="red"
                mr={3}
                onClick={handleDeleteMessage}
              >
                Delete
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MessageItem;
