import React, { useState } from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import "./Permissions.css";
import PermissionsToFiles from "../../Components/PermissionsAccess/PermissionsToFiles";
import MyFilesAndPermissions from "../../Components/PermissionsAccess/MyFilesAndPermissions";

const Permissions = ({ colorMode, isMobileView, userId, userName }) => {
  const [activeTab, setActiveTab] = useState("myFiles");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "myFiles":
        return (
          <>
            <MyFilesAndPermissions
              colorMode={colorMode}
              isMobileView={isMobileView}
              userId={userId}
              userName={userName}
            />
          </>
        );
      case "accessTo":
        return (
          <>
            <PermissionsToFiles
              colorMode={colorMode}
              isMobileView={isMobileView}
              userId={userId}
              userName={userName}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box boxShadow={"md"} className="permissiond-page-container">
      <Flex
        gap={"30px"}
        flexDirection={"column"}
        className="permissionssurrounding"
      >
        <Flex alignItems={"center"} gap={"10px"}>
          <Button
            onClick={() => handleTabClick("myFiles")}
            colorScheme={activeTab === "myFiles" ? "blue" : "gray"}
          >
            Access to My Files
          </Button>
          <Button
            onClick={() => handleTabClick("accessTo")}
            colorScheme={activeTab === "accessTo" ? "blue" : "gray"}
          >
            Files I have Access To
          </Button>
        </Flex>
        <Box>{renderContent()}</Box>
      </Flex>
    </Box>
  );
};

export default Permissions;
