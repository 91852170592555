import React, { useState } from "react";
import {
  Box,
  Button,
  Textarea,
  useToast,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";

const LyricsAssetUpload = ({
  selectedTableDataId,
  userName,
  userId,
  colorMode,
}) => {
  const [lyricsText, setLyricsText] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const toast = useToast(); // Initialize toast
  const [isTextAreaNotEmpty, setIsTextAreaNotEmpty] = useState(false);

  const handleTextChange = (event) => {
    setLyricsText(event.target.value);
    setIsTextAreaNotEmpty(event.target.value.length > 0);
  };

  const handleClearClick = () => {
    setLyricsText("");
    setIsTextAreaNotEmpty(false);
  };

  const handleSaveClick = async () => {
    setIsLoading(true); // Start loading

    const title =
      lyricsText.length > 15 ? lyricsText.substring(0, 15) + "..." : lyricsText;

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/assets/table/${selectedTableDataId}/lyric`,
        {
          userId,
          name: userName,
          lyricsText,
          status: "Draft",
          title,
        }
      );

      toast({
        title: "Lyrics saved successfully.",
        description: "Your lyrics have been added to the table.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setLyricsText(""); // Clear the textarea after saving
      setIsTextAreaNotEmpty(false);
    } catch (error) {
      console.error("Failed to save the lyrics:", error);
      toast({
        title: "Error saving lyrics.",
        description: "There was an issue saving your lyrics. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  };

  return (
    <Box>
      <Box>
        <Textarea
          placeholder="Copy and Paste Lyrics Here"
          value={lyricsText}
          onChange={handleTextChange}
        />
      </Box>
      <Flex alignItems={"cenetr"} gap={"10px"} mt={2}>
        <Button
          w={"30%"}
          colorScheme="red"
          size="sm"
          onClick={handleClearClick}
        >
          Clear
        </Button>
        <Button
          w={"100%"}
          colorScheme="green"
          isLoading={isLoading}
          disabled={!isTextAreaNotEmpty}
          size="sm"
          spinner={<Spinner size="sm" />}
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </Flex>
    </Box>
  );
};

export default LyricsAssetUpload;
