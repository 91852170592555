import React, { useState } from "react";
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  ModalFooter,
  useToast,
  Text,
  SimpleGrid,
  Flex,
  Icon,
} from "@chakra-ui/react";
import axios from "axios";
import { BiTable } from "react-icons/bi";

const BoardTableUpload = ({
  userName,
  userId,
  setIsTableUploadModalOpen,
  colorMode,
  fetchTables,
  board,
  boardId,
  mutualSpaceId,
}) => {
  const [tableName, setTableName] = useState("");
  const toast = useToast();
  const [tableType, setTableType] = useState(""); // Added state for tableType

  const bgColor = { light: "gray.100", dark: "gray.500" };
  const color = { light: "gray.800", dark: "white" };
  const selectedBgColor = "teal.400";
  const selectedColor = "white";
  const hoverBgColor = { light: "teal.500", dark: "teal.300" };

  return (
    <Flex>
      <FormControl>
        <Flex w={"100%"} flexDirection={"column"} gap={"20px"}>
          <Flex alignItems={"center"} gap={"10px"}>
            <Text className="accessheader">New Table</Text>
          </Flex>
          <Flex flexDirection={"column"}>
            <FormLabel className="formlabelmodaltext">Table Name</FormLabel>
            <Input
              placeholder="Enter table name"
              value={tableName}
              onChange={(e) => setTableName(e.target.value)}
            />
          </Flex>
          <Flex flexDirection={"column"}>
            <FormLabel className="formlabelmodaltext">
              What type of asset are you uploading?
            </FormLabel>
            <SimpleGrid columns={{ base: 2, md: 2 }} spacing={3} p={0}>
              {["Instrumental / Beat", "Lyrics", "Artwork", "Recording"].map(
                (type) => (
                  <Button
                    className="asset-upload-btn"
                    onClick={() => setTableType(type)}
                    key={type}
                    bg={
                      tableType === type ? selectedBgColor : bgColor[colorMode]
                    }
                    color={
                      tableType === type ? selectedColor : color[colorMode]
                    }
                    _hover={{
                      bg: hoverBgColor[colorMode],
                      color: "white",
                    }}
                    boxShadow={
                      tableType === type
                        ? "0 0 0 2px var(--chakra-colors-teal-500)"
                        : ""
                    }
                    transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                    borderRadius="md"
                  >
                    {type}
                  </Button>
                )
              )}
            </SimpleGrid>
          </Flex>
        </Flex>
        <Flex mt={4} w={"100%"} gap={"10px"}>
          <Button
            w={"100%"}
            colorScheme="red"
            variant={"outline"}
            onClick={() => setIsTableUploadModalOpen(false)}
          >
            Cancel
          </Button>
          <Button w={"100%"} colorScheme="blue">
            Create Table
          </Button>
        </Flex>
      </FormControl>
    </Flex>
  );
};

export default BoardTableUpload;
