import React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  MenuItem,
  Menu,
  Text,
  Button,
  MenuButton,
  MenuList,
  Flex,
  Input,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";

const ItemPriority = ({
  index,
  item,
  updateItemPriority,
  newPriorityInput,
  setNewPriorityInput,
  handleAddPriority,
  customPriorities,
}) => {
  return (
    <div>
      <Menu>
        <MenuButton
          onClick={(event) => event.stopPropagation()} // Stop event propagation
          size="sm"
          as={Button}
          key={index}
          rightIcon={<ChevronDownIcon />}
          className="main-menu-button-text"
          w={"100%"}
          variant={"outline"}
          border={"none"}
          bg={"none"}
          _hover={{ bg: "none" }}
        >
          {item?.priority ? (
            <Text>{item?.priority}</Text>
          ) : (
            <Text>Set Priority</Text>
          )}
        </MenuButton>
        <MenuList>
          <Flex alignItems={"center"} gap={"5px"} p={1}>
            <Input
              borderRadius={"7px"}
              placeholder="Add Priority..."
              size={"xs"}
              width={"100%"}
              value={newPriorityInput}
              onChange={(e) => setNewPriorityInput(e.target.value)}
            />
            <Tooltip
              label="Add Type"
              hasArrow
              placement="top"
              closeOnClick={false}
            >
              <IconButton
                onClick={() => handleAddPriority()}
                icon={<BsPlus />}
                size={"xs"}
                colorScheme="green"
              />
            </Tooltip>
          </Flex>
          <MenuItem onClick={() => updateItemPriority(index, "High")}>
            High
          </MenuItem>
          <MenuItem onClick={() => updateItemPriority(index, "Medium")}>
            Medium
          </MenuItem>
          <MenuItem onClick={() => updateItemPriority(index, "Low")}>
            Low
          </MenuItem>
          {customPriorities.map((priority, idx) => (
            <MenuItem
              key={idx}
              onClick={() => updateItemPriority(index, priority)}
            >
              {priority}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  );
};

export default ItemPriority;
