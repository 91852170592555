import React from "react";
import { Box } from "@chakra-ui/react";
import "./Assets.css";
import AssetsTable from "../../Components/Table/AssetsTable/AssetsTable";

const Assets = ({
  isMobileView,
  colorMode,
  userName,
  userId,
  guestToken,
  isSidebarCollapsed,
  closedetailsPanelSetting,
  openDetailsPanelSettings,
}) => {
  return (
    <Box
      className={`main-page-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <AssetsTable
        colorMode={colorMode}
        userName={userName}
        userId={userId}
        guestToken={guestToken}
        isMobileView={isMobileView}
        openDetailsPanelSettings={openDetailsPanelSettings}
      />
    </Box>
  );
};

export default Assets;
