import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  CircularProgress,
  useToast,
  SimpleGrid,
} from "@chakra-ui/react";
import axios from "axios";
import { API_ENDPOINT } from "../../../../Config/config";

const RecordingUploadModal = ({
  setIsRecordingModalOpen,
  currentRelease,
  colorMode,
}) => {
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const [recordingsData, setRecordingsData] = useState([]);
  const [selectedRecording, setSelectedRecording] = useState(null);
  const [addingRecordings, setAddingRecordings] = useState(false);

  useEffect(() => {
    const fetchRecordingsData = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT}api/mutualspace/recordings/all`
        );
        if (response.data.status === "success") {
          setRecordingsData(response.data.recordings);
        } else {
          toast({
            title: "Error fetching recordings",
            description: response.data.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error fetching recordings",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchRecordingsData();
  }, [toast]);

  const handleSelectRecording = (recording) => {
    setSelectedRecording(recording);
  };

  const handleBack = () => {
    setSelectedRecording(null);
  };

  const handleAddRecordingToRelease = async () => {
    setAddingRecordings(true); // Set the loading state
    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/release/${currentRelease._id}/addRecording`,
        selectedRecording // Use the selected recording object
      );
      if (response.data.status === "success") {
        toast({
          title: "Success",
          description: "Recording added to release successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setSelectedRecording(null); // Optionally go back to the list
        setIsRecordingModalOpen(false); // Close the modal if needed
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setAddingRecordings(false); // Reset the loading state
    }
  };

  return (
    <Box h={"100%"} overflowX={"auto"}>
      {loading ? (
        <CircularProgress isIndeterminate color="green.300" />
      ) : selectedRecording ? (
        <Box p={4} borderWidth={1} borderRadius="lg" mb={4}>
          <Text fontSize="xl" fontWeight="bold">
            {selectedRecording.title}
          </Text>
          <Text mt={2}>{selectedRecording.recordingName}</Text>
          <Text mt={2}>
            <strong>Upload Date:</strong>{" "}
            {new Date(selectedRecording.uploadDate).toLocaleDateString()}
          </Text>
          <Text mt={2}>
            <strong>Artist:</strong> {selectedRecording.recordingArtist}
          </Text>
          <Text mt={2}>
            <strong>Status:</strong> {selectedRecording.status}
          </Text>
          {/* Displaying linked beats */}
          {selectedRecording.linkedBeats &&
            selectedRecording.linkedBeats.length > 0 && (
              <Box mt={4}>
                <Text fontSize="lg" fontWeight="bold">
                  Linked Beats:
                </Text>
                {selectedRecording.linkedBeats.map((beat) => (
                  <Text key={beat._id} mt={2}>
                    <strong>Title:</strong> {beat.title}
                    <br />
                    <strong>Producer:</strong> {beat.producer}
                  </Text>
                ))}
              </Box>
            )}
          {/* Displaying linked lyrics */}
          {selectedRecording.linkedlyrics &&
            selectedRecording.linkedlyrics.length > 0 && (
              <Box mt={4}>
                <Text fontSize="lg" fontWeight="bold">
                  Linked Lyrics:
                </Text>
                {selectedRecording.linkedlyrics.map((lyric) => (
                  <Text key={lyric._id} mt={2}>
                    <strong>Title:</strong> {lyric.title}
                  </Text>
                ))}
              </Box>
            )}
          <Flex mt={4} justifyContent="space-between">
            <Button onClick={handleBack}>Back</Button>
            <Button
              colorScheme="blue"
              onClick={handleAddRecordingToRelease}
              isLoading={addingRecordings}
            >
              Add Recording to Release
            </Button>
          </Flex>
        </Box>
      ) : (
        <Box className="RecordingUploadModal-main-container">
          <SimpleGrid mt={2} columns={{ base: 1, md: 2 }} spacing={4}>
            {recordingsData.map((recording) => (
              <Box
                key={recording._id}
                p={4}
                borderWidth={1}
                borderRadius="lg"
                mb={4}
                onClick={() => handleSelectRecording(recording)}
                cursor="pointer"
              >
                <Text fontSize="xl" fontWeight="bold">
                  {recording.title}
                </Text>
                <Text mt={2}>
                  {recording.recordingName.length > 200
                    ? `${recording.recordingName.substring(0, 200)}...`
                    : recording.recordingName}
                </Text>
                <Text mt={2}>
                  <strong>Upload Date:</strong>{" "}
                  {new Date(recording.uploadDate).toLocaleDateString()}
                </Text>
                <Text mt={2}>
                  <strong>Artist:</strong> {recording.recordingArtist}
                </Text>
                <Text mt={2}>
                  <strong>Status:</strong> {recording.status}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
};

export default RecordingUploadModal;
