import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Badge,
} from "@chakra-ui/react";
import {
  BsPlayCircle,
  BsPauseCircle,
  BsCheck2All,
  BsPlus,
} from "react-icons/bs";
import { DownloadIcon, LinkIcon } from "@chakra-ui/icons";
import { FaMicrophone, FaTrashCan } from "react-icons/fa6";
import "./V2BeatsPlayer.css";
import { useBeatPanel } from "../../../../Context/BeatsPanelContext";
import { useCurrentBeat } from "../../../../Context/CurrentBeatContext";
import { useAddBeatToRelease } from "../../../../Context/AddBeatToRelease";
import { useLinkBeat } from "../../../../Context/LinkBeat";
import { useAddLyricToRelease } from "../../../../Context/AddLyricToRelease";
import { MusicPlayerContext } from "../../../../Context/MusicPlayerContext";
import { API_ENDPOINT } from "../../../../Config/config";
import BeatStatusItem from "./BeatStatusItem";
import { MdOutlineLyrics } from "react-icons/md";

const V2BeatsPlayer = ({
  colorMode,
  beat,
  fetchBeats,
  userName,
  userId,
  onDeleteBeat,
  isSidebarCollapsed,
  onUpdateBeatStatus,
  guestToken,
  openDetailsPanelSettings,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setAddBeat } = useAddBeatToRelease();
  const { setBeatPanel } = useBeatPanel();
  const { setAddLyric } = useAddLyricToRelease();
  const { setLinkBeat } = useLinkBeat();
  const { setCurrentBeat, currentBeat } = useCurrentBeat();

  const handleInnerClick = (event) => {
    event.stopPropagation();
  };

  const handleBeatClick = (event) => {
    event.stopPropagation();
    setBeatPanel("Beats");
    setCurrentBeat(beat);
    setAddBeat(null);
    setLinkBeat(null);
    openDetailsPanelSettings();
  };

  const AddBeatToRelease = (event) => {
    event.stopPropagation();
    openDetailsPanelSettings();

    setAddBeat("AddBeatToRelease");
    setBeatPanel(null);
    setAddLyric(null);
    setLinkBeat(null);
  };

  const toLinkBeat = (event) => {
    event.stopPropagation();
    openDetailsPanelSettings();

    setLinkBeat("LinkBeat");
    setCurrentBeat(beat);

    setAddBeat(null);
    setBeatPanel(null);
  };

  const { playTrack, stopTrack, currentTrack, seekTrack } =
    useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [localDuration, setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack && currentTrack.src === beat.fileUrl && !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(beat.fileUrl);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${API_ENDPOINT}api/beat/${beat._id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        fetchBeats();
        onClose();
        onDeleteBeat(beat._id);
      } else {
        console.error("Failed to delete beat");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box
      className={`beat-player-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      boxShadow="md"
      onClick={handleBeatClick}
    >
      <Flex alignItems={"center"} gap={"8px"}>
        <Flex>
          {isCurrentBeatPlaying ? (
            <>
              <IconButton
                onClick={(event) => {
                  handleInnerClick(event);
                  togglePlayPause(); // Call the original saveProducerName function
                }}
                icon={<BsPauseCircle className="main-player-button" />}
              />
            </>
          ) : (
            <>
              <IconButton
                onClick={(event) => {
                  handleInnerClick(event);
                  togglePlayPause(); // Call the original saveProducerName function
                }}
                icon={<BsPlayCircle className="main-player-button" />}
              />
            </>
          )}
        </Flex>
        <Flex>
          <Flex flexDirection={"column"}>
            <Text className="player-name">{beat.title}</Text>
            <Text className="player-producer">
              {beat.producer || "Producer"}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex className="fullslidercontainer" alignItems={"center"} gap={"5px"}>
        <Text className="slider-time">{formatTime(localCurrentTime)}</Text>
        <Slider
          aria-label="track-progress"
          value={isCurrentBeatPlaying ? localCurrentTime : 0}
          max={isCurrentBeatPlaying ? localDuration : 0}
          onChange={(val) => isCurrentBeatPlaying && seekTrack(val)}
          focusThumbOnChange={false}
          isDisabled={!isCurrentBeatPlaying}
          className="slider-progress"
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Text className="slider-time" ml={1}>
          {formatTime(
            isCurrentBeatPlaying ? localDuration - localCurrentTime : 0
          )}
        </Text>
      </Flex>

      <Flex alignItems={"center"} gap={"5px"}>
        {beat.lyric && (
          <Badge borderRadius={"4px"} colorScheme="yellow" variant="subtle">
            <Flex alignItems={"center"} gap={"3px"}>
              <Icon as={MdOutlineLyrics} />
              <Text fontSize={"9px"}>Lyrics</Text>
            </Flex>
          </Badge>
        )}
        {beat.recording && (
          <Badge borderRadius={"4px"} colorScheme="blue" variant="subtle">
            <Flex alignItems={"center"} gap={"3px"}>
              <Icon as={FaMicrophone} />
              <Text fontSize={"9px"}>Recording</Text>
            </Flex>
          </Badge>
        )}
        {beat.release && (
          <Badge borderRadius={"4px"} colorScheme="green" variant="subtle">
            <Flex alignItems={"center"} gap={"3px"}>
              <Icon as={BsCheck2All} />
              <Text fontSize={"9px"}>Released</Text>
            </Flex>
          </Badge>
        )}
      </Flex>
      <Flex>
        <BeatStatusItem beat={beat} colorMode={colorMode} />
      </Flex>
      <Flex alignItems={"center"} gap={"10px"}>
        <Flex>
          {!(beat.lyric && beat.recording && beat.release) && (
            <Tooltip
              placement="bottom"
              borderRadius={"7px"}
              label={"Link Beat"}
            >
              <Button onClick={toLinkBeat} variant={"outline"} size={"xs"}>
                <Icon className="linkbtntext" mr={1.5} as={LinkIcon} />
                <Text className="linkbtntext">Link</Text>
              </Button>
            </Tooltip>
          )}
        </Flex>
        <Flex>
          {!beat.release && (
            <>
              <Tooltip
                placement="bottom"
                borderRadius={"7px"}
                label={"Add to Release"}
              >
                <Button
                  onClick={AddBeatToRelease}
                  variant={"outline"}
                  size={"xs"}
                >
                  <Text className="addtoreleasebtn">Add To Release</Text>
                </Button>
              </Tooltip>
            </>
          )}
        </Flex>

        <Flex>
          <Tooltip
            placement="bottom"
            borderRadius={"7px"}
            label={"Add to Folder"}
          >
            <Button variant={"outline"} size={"xs"}>
              <Flex alignItems={"center"} gap={"2px"}>
                <Icon as={BsPlus} />
                <Text className="addtoreleasebtn">Folder</Text>
              </Flex>{" "}
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
      <Flex alignItems={"center"} gap={"10px"}>
        <Tooltip
          placement="bottom"
          borderRadius={"7px"}
          label={"Download Beat"}
        >
          <IconButton variant={"outline"} icon={<DownloadIcon />} size={"xs"} />
        </Tooltip>
        {!guestToken && (
          <>
            <Tooltip
              placement="bottom"
              borderRadius={"7px"}
              label={"Delete Beat"}
            >
              <IconButton
                onClick={(event) => {
                  handleInnerClick(event);
                  onOpen(); // Call the original saveProducerName function
                }}
                variant={"outline"}
                icon={<FaTrashCan />}
                size={"xs"}
                colorScheme="red"
              />
            </Tooltip>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default V2BeatsPlayer;
