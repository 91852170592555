import React, { useState, useEffect, useRef } from "react";
import "./SendMessageInput.css";
import Picker from "@emoji-mart/react";
import {
  Box,
  Input,
  useColorModeValue,
  IconButton,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import {
  BsSend,
  BsEmojiSmileFill,
  BsListUl,
  BsListOl,
  BsPaperclip,
  BsCodeSlash,
  BsTypeBold,
  BsTypeItalic,
  BsTypeStrikethrough,
} from "react-icons/bs";
import data from "@emoji-mart/data";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { RiFontSize } from "react-icons/ri";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles

const SendMessageInput = ({
  colorMode,
  userName,
  userId,
  isMobileViewm,
  selectedChannelId,
  refreshSelectedChannel,
  guestToken,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [message, setMessage] = useState(""); // This state holds the current message input
  const toast = useToast();
  const pickerRef = useRef(null);
  const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);
  const bgColor = useColorModeValue("white", "");
  const [file, setFile] = useState(null); // State to hold the selected file

  const CustomToolbar = () => (
    <Box p={2} id="toolbar" display="flex" justifyContent="space-between">
      <Box display="flex" gap="2">
        <Tooltip label="Bold" aria-label="Bold">
          <IconButton icon={<BsTypeBold />} size="sm" />
        </Tooltip>
        <Tooltip label="Italic" aria-label="Italic">
          <IconButton icon={<BsTypeItalic />} size="sm" />
        </Tooltip>
        <Tooltip label="Strike" aria-label="Strike">
          <IconButton icon={<BsTypeStrikethrough />} size="sm" />
        </Tooltip>
        <Tooltip label="Code" aria-label="Code">
          <IconButton icon={<BsCodeSlash />} size="sm" />
        </Tooltip>
        <Tooltip label="Bullet list" aria-label="Bullet list">
          <IconButton icon={<BsListUl />} size="sm" />
        </Tooltip>
        <Tooltip label="Ordered list" aria-label="Ordered list">
          <IconButton icon={<BsListOl />} size="sm" />
        </Tooltip>
        <Tooltip label="Emoji" aria-label="Emoji">
          <IconButton icon={<BsEmojiSmileFill />} size="sm" />
        </Tooltip>
        <Tooltip label="Attach" aria-label="Attach">
          <IconButton icon={<BsPaperclip />} size="sm" />
        </Tooltip>
      </Box>
    </Box>
  );

  const CustomToolbartwo = () => (
    <Box p={2} id="toolbar" display="flex" justifyContent="space-between">
      <Box display="flex" gap="2">
        {!guestToken && (
          <>
            <Tooltip label="Attach File" aria-label="Attach File">
              <Box>
                <IconButton
                  icon={<BsPaperclip />}
                  as="label"
                  htmlFor="file-input"
                  size="sm"
                />
                <Input
                  id="file-input"
                  type="file"
                  hidden
                  onChange={handleFileChange}
                />
              </Box>
            </Tooltip>
          </>
        )}
        <Tooltip label="Font" aria-label="Bold">
          <IconButton icon={<RiFontSize />} size="sm" />
        </Tooltip>
        <Tooltip label="Emoji" aria-label="Bold">
          <IconButton
            onClick={toggleEmojiPicker}
            icon={<BsEmojiSmileFill />}
            size="sm"
          />
        </Tooltip>
      </Box>
      <Tooltip label="Send" aria-label="Send">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            {
              !guestToken && sendMessage(); // Prevent triggering parent's onClick
            }
          }}
          icon={<BsSend />}
          size="sm"
        />
      </Tooltip>
    </Box>
  );

  const handleEmojiSelect = (emoji) => {
    const newText = `${message}${emoji.native}`;
    setMessage(newText);
    setShowEmojiPicker(false);
  };

  const quillRef = useRef(null); // Add this line at the beginning of your component

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Update the file state to the selected file
  };

  const clearMessage = () => {
    setMessage("");
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  const formats = ["bold", "italic", "strike", "code-block", "list", "bullet"];

  useEffect(() => {
    function handleClickOutside(event) {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    }
    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  const stripHtml = (html) => {
    const temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  const sendMessage = async () => {
    const plainTextMessage = stripHtml(message);

    if (!plainTextMessage.trim() && !file) {
      toast({
        title: "Cannot send empty message",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("name", userName);
    formData.append("messageContent", plainTextMessage);
    if (file) {
      formData.append("file", file); // Append file if available
    }

    try {
      await axios.post(
        `${API_ENDPOINT}api/channels/${selectedChannelId}/messages`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMessage("");
      setFile(null); // Reset file input after successful upload
      refreshSelectedChannel(); // Refresh the channel to show the new message
      toast({
        title: "Message sent successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Error sending message",
        description: "An error occurred while sending your message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box
        boxShadow={"md"}
        border={
          colorMode === "light"
            ? ".5px solid rgba(187, 186, 187, 1)"
            : "1px solid rgba(128, 128, 128, 1)"
        }
        bg={bgColor}
        borderRadius={"7px"}
      >
        {showEmojiPicker && (
          <Box
            position="absolute"
            zIndex={"9999"}
            bottom="50px"
            right="0px"
            ref={pickerRef}
          >
            <Picker data={data} onEmojiSelect={handleEmojiSelect} />
          </Box>
        )}
        <Box
          borderTopLeftRadius={"7px"}
          borderTopRightRadius={"7px"}
          bg={
            colorMode === "light"
              ? "rgba(248, 248, 248, 1)"
              : "rgba(40, 49, 70, 1)"
          }
        >
          <CustomToolbar />
        </Box>

        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={message}
          onChange={(value) => setMessage(value)} // Corrected line
          modules={modules}
          formats={formats}
          placeholder="Start a new message"
          style={{ height: "60px" }}
        />
        <Box borderBottomLeftRadius={"7px"} borderBottomRightRadius={"7px"}>
          <CustomToolbartwo />
        </Box>
      </Box>
    </>
  );
};

export default SendMessageInput;
