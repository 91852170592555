import React, { useState, useEffect } from "react";
import { Flex, Button, Text, Icon } from "@chakra-ui/react";
import "./BeatsPanel.css";
import AllWritingto from "./WritingToItems/AllWritingto";
import TimeLineDates from "./TimelineDates/TimeLineDates";
import CurrentBeatComments from "./CurrentBeatComments";
import TrackActivity from "./TrackActivity";
import { FaComments } from "react-icons/fa";

const BeatsPanel = ({
  colorMode,
  currentBeat,
  userId,
  userName,
  guestToken,
  closedetailsPanelSetting,
}) => {
  return (
    <Flex
      className={`main-beatspanel-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        className={`beats-panel-topone ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex h={"100%"} gap={"10px"} w={"100%"} flexDirection={"column"}>
          <AllWritingto
            userId={userId}
            currentBeat={currentBeat}
            colorMode={colorMode}
            userName={userName}
            mutualSpaceId={currentBeat?.mutualSpaceId}
            boardId={currentBeat?.boardId}
            tableId={currentBeat?.tableId}
            beatId={currentBeat?._id}
            fetchBeatData={currentBeat?.fetchBeatData}
          />
        </Flex>
      </Flex>
      <Flex
        className={`beatspanel-bottomone ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Flex
          pb={2}
          borderBottom={".5px solid grey"}
          gap={"8px"}
          alignItems={"center"}
          w={"100%"}
        >
          <Icon as={FaComments} />
          <Text className="commenttitle">Comments</Text>
        </Flex>

        <CurrentBeatComments
          userId={userId}
          currentBeat={currentBeat}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={currentBeat?.mutualSpaceId}
          boardId={currentBeat?.boardId}
          tableId={currentBeat?.tableId}
          beatId={currentBeat?._id}
        />
      </Flex>
    </Flex>
  );
};

export default BeatsPanel;
