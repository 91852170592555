import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  useToast,
  SimpleGrid,
  VStack,
  HStack,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import "./AddArtworkToFolder.css";
import { API_ENDPOINT } from "../../../../Config/config";

const AddArtworkToFolder = () => {
  return <div>AddArtworkToFolder</div>;
};

export default AddArtworkToFolder;
