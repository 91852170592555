import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Avatar,
} from "@chakra-ui/react";
import "./ArtistsItem.css";
import { BiUserPlus } from "react-icons/bi";
import AddArtistModal from "./AddArtistModal";

const ArtistsItem = ({
  colorMode,
  release,
  fetchReleases,
  releaseId,
  userId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Hook to control modal

  return (
    <Flex>
      {release.artists ? (
        <Button
          size={"sm"}
          variant={"outline"}
          className={`ReleaseDateItem-surround ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        >
          <Flex alignItems={"center"} gap={"7px"}>
            {release.artists.map((artist) => (
              <Avatar
                mr={-4}
                key={artist.artistId}
                size={"xs"}
                name={artist.artistName}
              />
            ))}{" "}
            <Text ml={3}>Artists</Text>
          </Flex>
        </Button>
      ) : (
        <Button
          size={"sm"}
          variant={"outline"}
          className={`ReleaseDateItem-surround ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        >
          <Flex alignItems={"center"} gap={"7px"}>
            <Icon fontSize={"16px"} as={BiUserPlus} />
            <Text>{release.artist || "Artist"}</Text>
          </Flex>
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <AddArtistModal
              colorMode={colorMode}
              release={release}
              releaseId={release._id}
              fetchReleases={fetchReleases}
              userId={userId}
              onClose={onClose}
              existingArtists={release.artists || []} // Pass existing artists
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ArtistsItem;
