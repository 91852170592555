import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Text,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Badge,
  Drawer, // Import Drawer components
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import React from "react";
import "./MainArtworkItem.css";
import { BsTrash, BsSendArrowDown, BsCheck2All } from "react-icons/bs";
import { useAddArtworkToRelease } from "../../Context/AddArtworkToRelease";
import { useArtworksPanel } from "../../Context/ArtworkPanelContext";
import { AddIcon, DownloadIcon, LinkIcon } from "@chakra-ui/icons";
import { useLinkArtwork } from "../../Context/LinkArtworkContext";
import { API_ENDPOINT } from "../../Config/config";
import axios from "axios";
import { useCurrentArtwork } from "../../Context/CurrentArtworkContext";
import MobileArtworkPanel from "../DetailsPanel/ArtworkPanel/MobileArtworkPanel";

const MobileArtworkItem = ({ colorMode, artwork, fetchArtworks }) => {
  const { setAddArtwork } = useAddArtworkToRelease();
  const { setArtworksPanel } = useArtworksPanel();
  const { isOpen, onOpen, onClose } = useDisclosure(); // For modal control
  const { setLinkArtwork } = useLinkArtwork();
  const toast = useToast();
  const { currentArtwork, setCurrentArtwork } = useCurrentArtwork();

  const toAddArtworkToRelease = (event) => {
    event.stopPropagation(); // Stop the event from propagating further

    setAddArtwork("AddArtworkToRelease");
    setArtworksPanel(null);
  };

  const backtoArtwork = (event) => {
    event.stopPropagation(); // Stop the event from propagating further
    setCurrentArtwork(artwork);
    setArtworksPanel("Artworks");
    setAddArtwork(null);
    setLinkArtwork(null);
    onOpen();
  };

  const toLinkArtwork = (event) => {
    event.stopPropagation(); // Stop the event from propagating further

    setLinkArtwork("LinkArtwork");
    setArtworksPanel(null);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/artwork/${artwork._id}`
      );
      if (response.status === 200) {
        toast({
          title: "Artwork deleted successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
        fetchArtworks();
      }
    } catch (error) {
      toast({
        title: "Error deleting artwork",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      onClick={backtoArtwork}
      className={`artwork-conainter ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Box className="artwork-topsec">
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Flex gap={"5px"} alignItems={"center"}>
            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Delete Artwork"}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering parent's onClick
                  onOpen(); // Open the modal
                }}
                icon={<BsTrash />}
                size={"xs"}
                colorScheme="red"
                variant={"outline"}
              />
            </Tooltip>
            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Download Artwork"}
            >
              <IconButton icon={<DownloadIcon />} size={"xs"} />
            </Tooltip>

            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Message Artwork"}
            >
              <IconButton icon={<BsSendArrowDown />} size={"xs"} />
            </Tooltip>
          </Flex>
          <Tooltip
            placement="bottom"
            borderRadius={"7px"}
            label={"Link Artwork"}
          >
            <Button onClick={toLinkArtwork} variant={"outline"} size={"xs"}>
              <Icon fontSize={"10px"} mr={1.5} as={LinkIcon} />
              Link
            </Button>
          </Tooltip>
        </Flex>
      </Box>
      <Box className="artwork-img-container">
        <Image className="artwork-img" src={artwork.imageUrl} />
      </Box>
      <Box className="artworkbottom">
        {!artwork.release ? (
          <>
            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Add Artwork to Release"}
            >
              <Button
                onClick={toAddArtworkToRelease}
                colorScheme="green"
                w={"100%"}
                size={"xs"}
              >
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={AddIcon} />
                  <Text>Add to Release</Text>
                </Flex>
              </Button>
            </Tooltip>
          </>
        ) : (
          <Box className="badgeforartwork">
            <Tooltip
              placement="top"
              borderRadius={"7px"}
              label={"Artwork is Released!"}
            >
              <Badge
                p={1}
                w={"100%"}
                colorScheme="green"
                variant="subtle"
                className="richmod"
                borderRadius={"7px"}
                h={"100%"}
              >
                <Flex
                  w={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"3px"}
                >
                  <Icon as={BsCheck2All} />
                  <Text fontSize={"9px"}>Released</Text>
                </Flex>
              </Badge>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Delete Artwork</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this artwork?</ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
              <Button w={"100%"} colorScheme="gray" onClick={onClose}>
                Cancel
              </Button>
              <Button w={"100%"} colorScheme="red" onClick={handleDelete}>
                Yes, Delete Artwork!
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          borderTopRightRadius={"12px"}
          borderTopLeftRadius={"12px"}
          h={"650px"}
        >
          <DrawerCloseButton ml={5} />
          <MobileArtworkPanel
            currentArtwork={currentArtwork}
            colorMode={colorMode}
          />
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MobileArtworkItem;
