import { createContext, useState, useContext } from "react";

const EmptyRecordingContext = createContext();

export const useEmptyRecording = () => useContext(EmptyRecordingContext);

export const EmptyRecordingProvider = ({ children }) => {
  const [isRecordingEmpty, setIsRecordingEmpty] = useState(null);

  return (
    <EmptyRecordingContext.Provider
      value={{ isRecordingEmpty, setIsRecordingEmpty }}
    >
      {children}
    </EmptyRecordingContext.Provider>
  );
};
