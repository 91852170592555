import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Input,
  Text,
  Image,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import "./DirectMessages.css";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import avatarMock from "../../../Svggs/avatarmock.svg";
import NewDirectMessageInput from "../../Messaging/DirectMessages/NewDirectMessageInput";

const NewDirectMessages = ({
  colorMode,
  userName,
  userId,
  isMobileView,
  refreshSelectedChannel,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const inputRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setIsDropdownOpen(false);
  };

  const handleTagClose = () => {
    setSelectedUser(null);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 150);
  };

  const handleFocus = () => {
    setIsDropdownOpen(true);
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_ENDPOINT}api/users`);
      const usersData = response.data.data;
      const filteredUsers = usersData.filter((user) => user._id !== userId);
      setUsers(filteredUsers);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Box className="DirectMessages-container">
      <Flex flexDirection={"column"}>
        <Text mt={2} p={1} ml={2} className="newmessagetext">
          New Mesage
        </Text>
        <Flex
          boxShadow={"md"}
          p={2}
          alignItems={"center"}
          gap={"5px"}
          className="inputtop"
          onFocus={handleFocus}
          onBlur={handleBlur}
          position={"relative"}
        >
          <Text ml={1}>To:</Text>
          {selectedUser && (
            <Tag
              size="lg"
              borderRadius="7px"
              variant="solid"
              colorScheme="blue"
              m={1}
            >
              <Image className="avatarselected" src={avatarMock} />
              <TagLabel ml={2}>{selectedUser.name}</TagLabel>
              <TagCloseButton onClick={handleTagClose} />
            </Tag>
          )}
          {!selectedUser && (
            <>
              <Input border={"none"} w={"100%"} />
            </>
          )}
        </Flex>
        {isDropdownOpen && (
          <Flex
            position="absolute"
            className="dropdowncontainer"
            flexDirection={"column"}
            top={"155px"}
            right={"15px"}
            width={"72%"}
            bg={colorMode === "light" ? "rgba(248, 248, 248, 1)" : "gray.700"}
            boxShadow="md"
            zIndex="10"
            border={
              colorMode === "light"
                ? ".5px solid rgba(187, 186, 187, 1)"
                : "1px solid rgba(128, 128, 128, 1)"
            }
          >
            {loading ? (
              <Text>Loading...</Text>
            ) : (
              users.map((user) => (
                <Flex
                  key={user._id}
                  onClick={() => handleUserClick(user)} // Here was the issue. It should match the handler's name.
                  alignItems={"center"}
                  gap={"10px"}
                  p={2}
                  _hover={{ bg: "rgba(25, 102, 161, 0.32)", cursor: "pointer" }}
                >
                  <Image src={avatarMock} className="avatarmock" />
                  <Text className="overaltext">{user.name}</Text>
                </Flex>
              ))
            )}
          </Flex>
        )}
      </Flex>
      <Box p={4}>
        {selectedUser && (
          <Flex flexDirection={"column"}>
            <Flex alignItems={"center"} gap={"10px"}>
              <Image src={avatarMock} className="previemessavatar" />
              <Text className="overaltext">{selectedUser.name}</Text>
            </Flex>
            <Flex mt={2}>
              <Text
                className="selectedbiotext"
                color={colorMode === "light" ? "rgba(96, 96, 96, 1)" : "ewhite"}
              >
                <Text as="strong" fontWeight="bold">
                  Send a message to {selectedUser.name}.
                </Text>{" "}
                Draft messages, list your to-dos, or keep links and files handy.
                You can also talk to yourself here, but please bear in mind
                you’ll have to supply both sides of the conversation.
              </Text>
            </Flex>
          </Flex>
        )}
      </Box>
      <Box p={1} m={2}>
        <NewDirectMessageInput
          userName={userName}
          userId={userId}
          isMobileView={isMobileView}
          colorMode={colorMode}
          refreshSelectedChannel={refreshSelectedChannel}
          selectedUserId={selectedUser?._id}
          selectedUserName={selectedUser?.name}
        />
      </Box>
    </Box>
  );
};

export default NewDirectMessages;
