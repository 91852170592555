import { createContext, useState, useContext } from "react";

const CurrentArtworkContext = createContext();

export const useCurrentArtwork = () => useContext(CurrentArtworkContext);

export const CurrentArtworkProvider = ({ children }) => {
  const [currentArtwork, setCurrentArtwork] = useState(null);

  // Function to update the current artwork
  const updateCurrentArtwork = (updatedArtwork) => {
    setCurrentArtwork(updatedArtwork);
  };

  return (
    <CurrentArtworkContext.Provider
      value={{ currentArtwork, setCurrentArtwork, updateCurrentArtwork }}
    >
      {children}
    </CurrentArtworkContext.Provider>
  );
};
