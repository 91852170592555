import { Flex, useToast } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_ENDPOINT } from "../../../../Config/config";
import AllRecordingComments from "../../../Table/MutualBoard/BeatDrawer/AllRecordingComments";
import RecordingsCommentInput from "../../../Table/MutualBoard/BeatDrawer/RecordingsCommentInput";

const CurrentRecordingComments = ({
  userId,
  recordingAsset,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  recordingId,
  fetchRecordingData,
  guestToken,
}) => {
  const [comments, setComments] = useState([]);
  const toast = useToast();

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/recording/${recordingId}/comments`
      );
      if (response.status === 200) {
        setComments(response.data.comments || []); // Ensures comments is always an array
      } else {
        throw new Error("Failed to fetch comments due to server error");
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "Unable to fetch comments.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchComments();
  }, [mutualSpaceId, boardId, tableId, recordingId, toast]);

  return (
    <Flex className="CurrentBeatComments-main">
      <Flex>
        <AllRecordingComments
          userId={userId}
          recordingAsset={recordingAsset}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          recordingId={recordingId}
          comments={comments}
        />
      </Flex>
      <Flex>
        <RecordingsCommentInput
          userId={userId}
          recordingAsset={recordingAsset}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          recordingId={recordingId}
          fetchComments={fetchComments}
          guestToken={guestToken}
        />
      </Flex>
    </Flex>
  );
};

export default CurrentRecordingComments;
