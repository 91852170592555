// SelectedBeatContext.js
import React, { createContext, useState, useContext } from "react";

const SelectedBeatContext = createContext();

export const useSelectedBeat = () => useContext(SelectedBeatContext);

export const SelectedBeatProvider = ({ children }) => {
  const [selectedBeat, setSelectedBeat] = useState(null);

  return (
    <SelectedBeatContext.Provider value={{ selectedBeat, setSelectedBeat }}>
      {children}
    </SelectedBeatContext.Provider>
  );
};
