import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Avatar,
  Flex,
  IconButton,
  Input,
  Icon,
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { FiDownload, FiUserPlus } from "react-icons/fi";
import newFolderIcon from "../../Svggs/newiconsfolder.svg";
import { BsPlusLg, BsTrash } from "react-icons/bs";
import UserInviteFolders from "../../Components/Table/FoldersTable/UserInviteFolders";
import "./MobileIndividualFolder.css";
import { DownloadIcon } from "@chakra-ui/icons";

const MobileIndividualFolder = ({ colorMode, userId, isMobileView }) => {
  const { folderId } = useParams();
  const [folder, setFolder] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const toast = useToast();
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure(); // Hook to control modal

  const openDeleteModal = (file) => {
    setFileToDelete(file);
    setIsDeleteModalOpen(true);
  };

  const openAddFileModal = () => {
    setIsAddFileModalOpen(true);
  };

  const addFile = async () => {
    if (!selectedFile) {
      toast({
        title: "Error",
        description: "No file selected",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      await axios.post(
        `${API_ENDPOINT}api/folders/${folderId}/files/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      fetchFolderData(); // Refresh folder data
      setIsAddFileModalOpen(false); // Close modal
      toast({
        title: "Success",
        description: "File added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error adding file",
        description: "Something went wrong",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const deleteFile = async () => {
    if (!fileToDelete) return;

    try {
      await axios.delete(
        `${API_ENDPOINT}api/folders/${folderId}/files/${encodeURIComponent(
          fileToDelete.fileKey
        )}`
      );
      fetchFolderData();
      setIsDeleteModalOpen(false);
      toast({
        title: "File deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      toast({
        title: "Error deleting file.",
        description: "Unable to delete file, please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchFolderData = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}api/folder/${folderId}`);
      setFolder(response.data.folder);
    } catch (error) {
      console.error("Error fetching folder data:", error);
      // Handle error (e.g., show an error message)
    }
  };

  useEffect(() => {
    fetchFolderData();
  }, [folderId]);

  const [theFolderName, setTheFolderName] = useState(folder?.folderName);

  useEffect(() => {
    setTheFolderName(folder?.folderName);
  }, [folder]);

  const formatDate = (uploadDate) => {
    const uploadedAt = new Date(uploadDate);
    const now = new Date();
    const timeDiff = now.getTime() - uploadedAt.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    if (daysDiff < 1) {
      const hoursDiff = timeDiff / (1000 * 3600);
      if (hoursDiff < 1) {
        const minutesDiff = timeDiff / (1000 * 60);
        return `${Math.floor(minutesDiff)} minutes ago`;
      }
      return `${Math.floor(hoursDiff)} hours ago`;
    } else if (daysDiff < 10) {
      return `${Math.floor(daysDiff)} days ago`;
    } else {
      return `on ${uploadedAt.toLocaleString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      })} @ ${uploadedAt.toLocaleTimeString()}`;
    }
  };

  const downloadFile = async (fileKey, fileName) => {
    try {
      toast({
        title: "Download started",
        description: "Your download has started.",
        status: "info",
        duration: 9000,
        isClosable: true,
      });

      const response = await axios.get(
        `${API_ENDPOINT}api/folders/${folderId}/files/${encodeURIComponent(
          fileKey
        )}/download`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast({
        title: "Download completed",
        description: "Your file has been downloaded.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error downloading file:", error);
      toast({
        title: "Download failed",
        description: "There was an issue downloading your file.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const downloadFolder = async () => {
    try {
      // Indicate download process start
      toast({
        title: "Download in progress",
        description: "Your folder download has started.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });

      const response = await axios.get(
        `${API_ENDPOINT}api/folders/${folderId}/download`, // Make sure this endpoint matches your actual API route
        {
          responseType: "blob", // This is important to handle the binary content of the ZIP file
        }
      );

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Attempt to extract a filename from the Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      let filename = folder?.folderName
        ? `${folder.folderName}.zip`
        : "download.zip"; // Default filename
      if (contentDisposition) {
        const matches = contentDisposition.match(/filename="?(.+)"?/);
        if (matches.length === 2) filename = matches[1];
      }

      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast({
        title: "Download completed",
        description: "Your folder has been successfully downloaded.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error downloading folder:", error);
      toast({
        title: "Download failed",
        description: "Failed to download the folder.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      className={`MobileIndividualFolder-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex
        className="top-MobileIndividualFolder"
        bg={colorMode === "light" ? "white" : ""}
        borderBottom={
          colorMode === "light"
            ? ".5px solid rgba(187, 186, 187, 1)"
            : "1px solid rgba(128, 128, 128, 1)"
        }
        boxShadow={"md"}
        w={"100%"}
        borderRadius={"7px"}
      >
        <Flex
          flexDirection={"column"}
          p={3}
          gap={"20px"}
          w={"100%"}
          alignItems={"center"}
        >
          <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
            <Box className="mobilefolderimgcontainer">
              <Image className="mobile-folder-img" src={newFolderIcon} />
            </Box>
            <Box w={"100%"}>
              <Input
                w={"100%"}
                borderRadius={"7px"}
                boxShadow={"md"}
                value={theFolderName}
              />
            </Box>
          </Flex>
          <Flex w={"100%"} alignItems={"end"} justifyContent={"space-between"}>
            <Flex
              flexDirection={"column"}
              gap={"5px"}
              w={"100%"}
              alignItems={"start"}
            >
              <Text className="accesstest">Users With Access</Text>
              {folder?.usersWithAccess && folder.usersWithAccess.length > 0 && (
                <Flex alignItems="center" gap="2">
                  {folder.usersWithAccess.map((user) => (
                    <Tooltip key={user.userId} label={user.userName} hasArrow>
                      <Avatar mr={-3} size="xs" name={user.userName} />
                    </Tooltip>
                  ))}
                </Flex>
              )}
            </Flex>
            <Flex alignItems={"center"} gap={"5px"}>
              <IconButton size={"sm"} onClick={onOpen} icon={<FiUserPlus />} />
              <IconButton
                colorScheme="teal"
                size={"sm"}
                onClick={downloadFolder}
                icon={<DownloadIcon />}
              />
              <Button
                onClick={openAddFileModal}
                variant={"outline"}
                size={"sm"}
              >
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={BsPlusLg} />
                  <Text>Add File</Text>
                </Flex>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        className="bottom-MobileIndividualFolder"
        boxShadow={"md"}
        w={"100%"}
        borderRadius={"5px"}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th
                className={`mobilefolder-table-header ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
                position="sticky"
                top="0"
                bg={colorMode === "light" ? "white" : "rgba(28, 33, 57, 1)"}
                zIndex="sticky"
              >
                File
              </Th>

              <Th
                textAlign={"center"}
                position="sticky"
                top="0"
                bg={colorMode === "light" ? "white" : "rgba(28, 33, 57, 1)"}
                zIndex="sticky"
              ></Th>
            </Tr>
          </Thead>
          <Tbody>
            {folder?.files.map((file) => (
              <Tr
                _hover={{ backgroundColor: "rgba(208, 212, 228, 0.32)" }}
                key={file?.fileName}
              >
                <Td>
                  <Text className="mobiletable-data">{file?.fileName}</Text>
                </Td>
                {!isMobileView && (
                  <>
                    <Td>
                      <Flex justifyContent={"center"} alignItems={"center"}>
                        {folder.usersWithAccess ? (
                          <>
                            {folder?.usersWithAccess.map((user) => (
                              <Tooltip
                                key={user.userId}
                                label={user.userName}
                                hasArrow
                              >
                                <Avatar size="xs" name={user.userName} />
                              </Tooltip>
                            ))}
                          </>
                        ) : (
                          <>
                            <Button
                              onClick={onOpen}
                              variant={"outline"}
                              size={"xs"}
                            >
                              <Flex alignItems={"center"} gap={"5px"}>
                                <Icon as={FiUserPlus} />
                                <Text>Invite</Text>
                              </Flex>
                            </Button>
                          </>
                        )}
                      </Flex>
                    </Td>
                  </>
                )}

                <Td>
                  <Flex
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"10px"}
                  >
                    <IconButton
                      size={"xs"}
                      aria-label="Download file"
                      icon={<FiDownload />}
                      colorScheme="blue"
                      variant={"outline"}
                      onClick={() => downloadFile(file.fileKey, file.fileName)} // Update this line
                    />
                    <IconButton
                      size={"xs"}
                      aria-label="Delete file"
                      icon={<BsTrash />}
                      colorScheme="red"
                      variant={"outline"}
                      onClick={() => openDeleteModal(file)} // Pass the current file
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete File</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this file?</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={deleteFile}>
              Delete File
            </Button>
            <Button variant="ghost" onClick={() => setIsDeleteModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isAddFileModalOpen}
        onClose={() => setIsAddFileModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add File to Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="file"
              onChange={(event) => setSelectedFile(event.target.files[0])}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={addFile}>
              Add File
            </Button>
            <Button
              variant="ghost"
              onClick={() => setIsAddFileModalOpen(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UserInviteFolders
              colorMode={colorMode}
              userId={userId}
              folderId={folderId} // Pass folderId to UserInviteFolders
              onClose={onClose}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Invite
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MobileIndividualFolder;
