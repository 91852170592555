import React, { useState, useEffect } from "react";
import {
  Box,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Flex,
  Button,
  Icon,
  Text,
  Avatar,
  AvatarBadge,
  Tooltip,
  SimpleGrid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa6";
import { BsClockHistory, BsPlusLg } from "react-icons/bs";
import { MdOutlineSortByAlpha } from "react-icons/md";
import { AddIcon } from "@chakra-ui/icons";
import { useRecordingsKanban } from "../../../Context/RecordingsKanbanContext";
import MainRecordingPlayer from "../../Players/MainRecordingsPlayer/MainRecordingPlayer";
import MobileMainRecordingPlayer from "../../Players/MobilePlayers/MobileMainRecordingPlayer";
import RecordingFormUpload from "../../Forms/RecordingForms/RecordingFormUpload";
import { useCurrentRecording } from "../../../Context/CurrentRecordingContext";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { useEmptyRecording } from "../../../Context/EmptyStates/EmptyRecordingContext";
import RecordingsSearch from "../../Search/RecordingsSearch/RecordingsSearch";
import { useNavigate } from "react-router-dom";
import UserAvatars from "../../List/Users/UserAvatars";
import NoRecordings from "../../EmptyStates/NoRecordings";

const RecordingsTable = ({
  colorMode,
  isMobileView,
  userName,
  userId,
  guestToken,
  openDetailsPanelSettings,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Hook to control modal
  const { recordingsKanban, setRecordingsKanban } = useRecordingsKanban();
  const [recordings, setRecordings] = useState([]); // State to store the beats
  const [sortOption, setSortOption] = useState("Newest"); // Default sort option set to "Newest"
  const [sortDirection, setSortDirection] = useState("asc");
  const [isLoading, setIsLoading] = useState(true); // Add this line
  const { currentRecording, setCurrentRecording } = useCurrentRecording();
  const { isRecordingEmpty, setIsRecordingEmpty } = useEmptyRecording();
  const [isFilteredByUser, setIsFilteredByUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const fetchRecordings = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/get/uploaded/recordings`
      );
      let userRecordings = response.data;

      if (guestToken) {
        userRecordings = userRecordings.filter(
          (recording) => recording.mock === "true"
        );
      } else {
        userRecordings = userRecordings.filter(
          (recording) => recording.user.userId === userId
        );
      }

      userRecordings.sort(
        (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
      );

      setRecordings(userRecordings);
    } catch (error) {
      console.error("Error fetching beats:", error);
    } finally {
      setIsLoading(false); // End loading regardless of result
    }
  };

  useEffect(() => {
    fetchRecordings();
  }, [userId, guestToken]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_ENDPOINT}api/users`);
        const filteredUsers = response.data.data.filter(
          (user) =>
            Array.isArray(user.usersWithPermissions) &&
            user.usersWithPermissions.some((perm) => perm.userId === userId)
        );
        setUsers(filteredUsers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [userId]);

  const fetchRecordingsByUser = async (userId) => {
    try {
      setSelectedUserId(userId); // Set the selected user ID

      const response = await axios.get(
        `${API_ENDPOINT}api/users/recordings/${userId}`
      );
      setRecordings(response.data.data); // Update beats with the user-specific beats
      setIsFilteredByUser(true);
    } catch (error) {
      console.error("Error fetching user-specific beats:", error);
    }
  };

  const handleAvatarClick = (userId) => {
    fetchRecordingsByUser(userId);
  };

  const navigate = useNavigate();

  const toPermissions = (e) => {
    navigate("/permissions");
    e.preventDefault();
  };

  return (
    <Box className="main-table-container">
      <Box
        className={`top-of-table ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        <Box>
          <Flex alignItems={"center"} gap={"10px"}>
            <Menu
              border={
                colorMode === "light"
                  ? "2px solid rgba(153, 178, 198, 0.23)"
                  : "2px solid rgba(43, 46, 54, 1)"
              }
              bg={colorMode === "light" ? "white" : "black"}
            >
              {({ isOpen }) => (
                <>
                  <Flex alignItems="center" gap={2}>
                    <Tooltip
                      placement="bottom"
                      borderRadius={"7px"}
                      label={"Filter"}
                    >
                      <MenuButton
                        size={isMobileView ? "xs" : "sm"}
                        as={Button}
                        rightIcon={
                          <FaChevronRight
                            style={{
                              transition: "transform 0.2s",
                              transform: isOpen ? "rotate(90deg)" : "none",
                            }}
                          />
                        }
                      >
                        Filter
                      </MenuButton>
                    </Tooltip>
                  </Flex>
                  <MenuList
                    p={2}
                    bg={colorMode === "light" ? "white" : "black"}
                    zIndex={"9999"}
                  >
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                    >
                      <Icon as={BsClockHistory} fontSize="17px" />
                      <Text>Newest</Text>
                    </MenuItem>
                    <MenuItem
                      p={2}
                      sx={{
                        "&:hover": {
                          bg:
                            colorMode === "light"
                              ? "rgba(90, 238, 158, 0.23)"
                              : "rgba(90, 238, 158, 0.23)",
                        },
                      }}
                      className="blank"
                      bg={colorMode === "light" ? "white" : "black"}
                    >
                      <Icon as={MdOutlineSortByAlpha} fontSize="17px" />

                      <Text>A - Z</Text>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
        </Box>
        <Box className="avatarssurround">
          {!guestToken && (
            <>
              <Flex>
                <UserAvatars users={users} onAvatarClick={handleAvatarClick} />
                <Box className="aliagingn" p={0}>
                  <Tooltip
                    placement="bottom"
                    borderRadius={"7px"}
                    label={"Access User Beats"}
                  >
                    <Avatar
                      onClick={toPermissions}
                      size={"sm"}
                      ml={-3} // Apply negative margin from the second avatar onwards
                      _hover={{
                        transform: "translateY(-2px)",
                        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                        cursor: "pointer",
                        opacity: "60%",
                      }}
                    >
                      <AvatarBadge bg="green.500" boxSize="1.25em">
                        <AddIcon color="white" />
                      </AvatarBadge>
                    </Avatar>
                  </Tooltip>
                </Box>
              </Flex>
            </>
          )}
        </Box>
        <Flex alignItems={"center"} gap={"10px"}>
          <Box>
            <Tooltip
              placement="bottom"
              borderRadius={"7px"}
              label={"Upload New Recording"}
            >
              <Button
                onClick={onOpen}
                variant={"outline"}
                size={isMobileView ? "xs" : "sm"}
              >
                <Flex gap={"5px"} alignItems={"center"}>
                  <Icon as={BsPlusLg} />
                  <Text>Upload</Text>
                </Flex>
              </Button>
            </Tooltip>
          </Box>
        </Flex>
      </Box>
      <Box className="controlheight" h={"93%"}>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : recordings.length === 0 ? (
          <Box>
            <NoRecordings colorMode={colorMode} onOpen={onOpen} />
          </Box>
        ) : (
          <SimpleGrid columns={1} spacing={2} mt={2} p={2}>
            {recordings.map((recording, index) => (
              <Box key={recording.id} p={1}>
                {isMobileView ? (
                  <>
                    <MobileMainRecordingPlayer
                      userName={userName}
                      userId={userId}
                      colorMode={colorMode}
                      isMobileView={isMobileView}
                      fetchRecordings={fetchRecordings}
                      recording={recording}
                    />
                  </>
                ) : (
                  <>
                    <MainRecordingPlayer
                      recording={recording}
                      isMobileView={isMobileView}
                      colorMode={colorMode}
                      userName={userName}
                      userId={userId}
                      fetchRecordings={fetchRecordings}
                      guestToken={guestToken}
                      openDetailsPanelSettings={openDetailsPanelSettings}
                    />
                  </>
                )}
              </Box>
            ))}
          </SimpleGrid>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "black"}
        >
          <ModalHeader>Upload New Recording</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RecordingFormUpload
              isMobileView={isMobileView}
              colorMode={colorMode}
              userName={userName}
              userId={userId}
              fetchRecordings={fetchRecordings}
              onClose={onClose}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default RecordingsTable;
