import React, { createContext, useContext, useState, useCallback } from "react";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";

const RefreshBeatsContext = createContext();

export const useRefreshBeats = () => useContext(RefreshBeatsContext);

export const RefreshBeatsProvider = ({ children }) => {
  const [beats, setBeats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBeats = useCallback(async (userId, guestToken) => {
    setIsLoading(true);

    try {
      const response = await axios.get(`${API_ENDPOINT}api/get/uploaded/beats`);
      let userBeats = response.data;

      if (guestToken) {
        userBeats = userBeats.filter((beat) => beat.mock === "true");
      } else {
        userBeats = userBeats.filter((beat) => beat.user.userId === userId);
      }

      userBeats.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
      setBeats(userBeats);
    } catch (error) {
      console.error("Error fetching beats:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <RefreshBeatsContext.Provider value={{ beats, fetchBeats, isLoading }}>
      {children}
    </RefreshBeatsContext.Provider>
  );
};
