import React, { useState, useRef } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import { API_ENDPOINT } from "../../../Config/config";
import { BsImage } from "react-icons/bs";

const ArtworkUploadForm = ({ userName, userId, onClose, fetchArtworks }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [setImageName] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImageName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const clearImage = () => {
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = async () => {
    const file = fileInputRef.current.files[0];
    if (!file) return;
    setIsLoading(true);

    const formData = new FormData();
    formData.append("image", file);
    formData.append("name", userName);
    formData.append("userId", userId);
    formData.append("title", file.name); // Add the file name as the title

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/artwork/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast({
          title: "Artwork uploaded successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        fetchArtworks();
        onClose();
      }
    } catch (error) {
      toast({
        title: "Error uploading artwork",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box className="artuploads">
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
        ref={fileInputRef}
        id="image-upload"
      />
      <label htmlFor="image-upload">
        {!imagePreview && (
          <Box className="hdysiai">
            <Button
              variant={"outline"}
              as="span"
              colorScheme="blue"
              className="upload-beat-btn"
            >
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon className="modal-title-icon" as={BsImage} />
                <Text className="modal-title-text"> Upload Artwork</Text>
              </Flex>
            </Button>
          </Box>
        )}
      </label>
      {imagePreview && (
        <>
          <Box className="previewiprey" position="relative" mt={4}>
            <Box className="prevsurroundcont">
              <Image
                className="prewopharell"
                src={imagePreview}
                alt="Preview"
              />
            </Box>
            <Box className="monalisa">
              <Button
                colorScheme="red"
                onClick={clearImage}
                w={"96%"}
                size={"sm"}
              >
                Clear
              </Button>
              <Button
                colorScheme="teal"
                onClick={handleSubmit}
                disabled={isLoading}
                w={"96%"}
                size={"sm"}
              >
                {isLoading ? <Spinner size="sm" /> : "Submit"}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ArtworkUploadForm;
