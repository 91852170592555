import { createContext, useState, useContext } from "react";

const BeatsKanbanContext = createContext();

export const useBeatsKanban = () => useContext(BeatsKanbanContext);

export const BeatsKanbanProvider = ({ children }) => {
  const [beatsKanban, setBeatsKanban] = useState(null);

  return (
    <BeatsKanbanContext.Provider value={{ beatsKanban, setBeatsKanban }}>
      {children}
    </BeatsKanbanContext.Provider>
  );
};
