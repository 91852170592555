import React, { useContext, useEffect, useState } from "react";
import {
  Text,
  IconButton,
  Flex,
  Icon,
  Tag,
  Avatar,
  TagLabel,
} from "@chakra-ui/react";
import { BsPlayCircle, BsPauseCircle, BsHeadphones } from "react-icons/bs";
import { MusicPlayerContext } from "../../../Context/MusicPlayerContext";
import "./BeatLinkedItem.css";

const BeatLinkedItem = ({ beat, colorMode }) => {
  const { playTrack, stopTrack, currentTrack, seekTrack } =
    useContext(MusicPlayerContext);
  const [localCurrentTime, setLocalCurrentTime] = useState(0);
  const [localDuration, setLocalDuration] = useState(0);
  const isCurrentBeatPlaying =
    currentTrack && currentTrack.src === beat.fileUrl && !currentTrack.paused;

  useEffect(() => {
    if (isCurrentBeatPlaying) {
      const updateTime = () => {
        setLocalCurrentTime(currentTrack.currentTime);
        setLocalDuration(currentTrack.duration);
      };

      currentTrack.addEventListener("timeupdate", updateTime);
      return () => currentTrack.removeEventListener("timeupdate", updateTime);
    }
  }, [isCurrentBeatPlaying, currentTrack]);

  const togglePlayPause = () => {
    if (isCurrentBeatPlaying) {
      stopTrack();
    } else {
      playTrack(beat.fileUrl);
    }
  };

  return (
    <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
      <Tag p={1} w={"100%"} size="sm" colorScheme="blue" borderRadius="full">
        {isCurrentBeatPlaying ? (
          <>
            <Icon
              mr={2}
              onClick={(e) => {
                e.stopPropagation();
                togglePlayPause();
              }}
              fontSize={"20px"}
              as={BsPauseCircle}
              _hover={{ cursor: "pointer" }}
            />
          </>
        ) : (
          <>
            <Icon
              mr={2}
              onClick={(e) => {
                e.stopPropagation();
                togglePlayPause();
              }}
              fontSize={"20px"}
              as={BsPlayCircle}
              _hover={{ cursor: "pointer" }}
            />
          </>
        )}
        <TagLabel className="beattitletag">
          <Text>{beat?.title}</Text>
        </TagLabel>
      </Tag>
    </Flex>
  );
};

export default BeatLinkedItem;
