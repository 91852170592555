import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Menu,
  Text,
  Button,
  MenuButton,
  MenuList,
  Flex,
  Input,
  Tooltip,
  IconButton,
  Box,
  Portal,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { BsPlus } from "react-icons/bs";
import axios from "axios";
import { FaX } from "react-icons/fa6";
import { API_ENDPOINT } from "../../../../../Config/config";

const LabelItem = ({
  task,
  taskId,
  board,
  boardId,
  userId,
  colorMode,
  mutualSpaceId,
  isSidebarCollapsed,
  fetchTasks,
  key,
  itemId,
  item,
  fetchItems,
  guestToken,
}) => {
  const toast = useToast(); // Using toast for notifications
  const [newLabel, setNewLabel] = useState("");
  const [newLabelInput, setNewLabelInput] = useState("");
  const [customLabels, setCustomLabels] = useState([]);
  const [labelColors, setLabelColors] = useState({});
  const [allCustomLabels, setAllCustomLabels] = useState([]);

  const colorOptions = [
    "#FF6347", // Tomato
    "#4682B4", // SteelBlue
    "#3CB371", // MediumSeaGreen
    "#FFA500", // Orange
    "#9932CC", // DarkOrchid
    colorMode === "light" ? "#FFD700" : "#FFA500", // Gold for light, Orange for dark
    "#20B2AA", // LightSeaGreen
    "#FFC0CB", // Pink
    "#C0C0C0", // Silver
    "#FF69B4", // HotPink - Brighter in dark mode
  ];

  return (
    <Flex w={"100%"} flexDirection={"column"} alignItems={"start"} gap={"3px"}>
      <Menu>
        <MenuButton
          fontSize={"9px"}
          onClick={(event) => event.stopPropagation()} // Stop event propagation
          size="sm"
          as={Button}
          rightIcon={<ChevronDownIcon />}
          w={"100%"}
          border={"none"}
          bg={"none"}
          color={
            colorMode === "light"
              ? !item?.label
                ? "rgba(6, 21, 43, 1)"
                : "white"
              : !item?.label
              ? "white"
              : "white"
          }
          backgroundColor={labelColors[item?.label]}
          _hover={{
            backgroundColor: item?.label?.color,
          }}
        >
          {item?.label || "Set Label"}
        </MenuButton>
        <MenuList
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
        >
          <Flex alignItems={"center"} gap={"5px"} p={1}>
            <Input
              borderRadius={"3px"}
              placeholder="Add Status..."
              size={"xs"}
              width={"100%"}
              onChange={(e) => setNewLabelInput(e.target.value)}
              value={newLabelInput}
              onClick={(e) => e.stopPropagation()}
            />
            <Tooltip
              label="Add Status"
              hasArrow
              placement="top"
              closeOnClick={false}
            >
              <IconButton
                icon={<BsPlus />}
                onClick={(e) => {
                  e.stopPropagation();
                  // handleAddCustomLabel();
                }}
                size={"xs"}
                colorScheme="green"
              />
            </Tooltip>
          </Flex>
          {allCustomLabels.map((label, index) => (
            <MenuItem key={index}>
              <Flex w={"100%"} alignItems={"center"} gap={"10px"}>
                <Popover>
                  <PopoverTrigger>
                    <Box
                      as="button" // Ensures the box can be focused and interacted with like a button
                      w="20px"
                      h="20px"
                      border="1px solid" // Optional: adds a border to the square
                      borderColor="gray.300" // Optional: sets the border color
                      backgroundColor={label.color || "transparent"}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent w="auto">
                      <SimpleGrid columns={5} spacing={2} p={2}>
                        {colorOptions.map((color, colorIndex) => (
                          <Box
                            key={colorIndex}
                            w="20px"
                            h="20px"
                            bg={color}
                            onClick={(e) => {
                              e.stopPropagation();
                              // handleUpdateColor(label.label, color);
                            }}
                          />
                        ))}
                      </SimpleGrid>
                    </PopoverContent>
                  </Portal>
                </Popover>

                <Flex
                  w={"100%"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={"5px"}
                >
                  <Text className="statusbeat">{label.label}</Text>
                  <Icon
                    // onClick={() => handleDeleteCustomLabel(label.label)}
                    color={"red"}
                    as={FaX}
                    cursor="pointer"
                  />
                </Flex>
              </Flex>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default LabelItem;
