import React from "react";
import { Flex, Tag, Avatar, TagLabel } from "@chakra-ui/react";
import { BsFolder } from "react-icons/bs";

const BoardLinkedFolderItem = ({ folder, colorMode }) => {
  return (
    <Flex alignItems={"center"} gap={"10px"}>
      <Tag size="sm" colorScheme="purple" borderRadius="full">
        <Avatar
          bg={"none"}
          color={colorMode === "light" ? "gray" : "purple.300"}
          size="xs"
          ml={-1}
          mr={1}
          icon={<BsFolder fontSize={"20px"} />}
        />
        <TagLabel className="artworktag">{folder.folderName}</TagLabel>
      </Tag>
    </Flex>
  );
};

export default BoardLinkedFolderItem;
