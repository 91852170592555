import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Text,
  SimpleGrid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Center,
  Input,
  useColorModeValue,
  useToast,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { BsFolder, BsPlusLg } from "react-icons/bs";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import UserInviteFolders from "./UserInviteFolders";
import IndividualFolderItem from "../../Folders/IndividualFolderItem/IndividualFolderItem";
import NoMainFolder from "../../EmptyStates/NoMainFolder";
import "./FoldersTable.css";
import { SearchIcon } from "@chakra-ui/icons";

const FoldersTable = ({
  isMobileView,
  colorMode,
  userId,
  userName,
  isSidebarCollapsed,
  guestToken,
  openDetailsPanelSettings,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Hook to control modal
  const [isLoading, setIsLoading] = useState(false); // Add this line
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false); // State for Upload Folder modal
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [folders, setFolders] = useState([]); // State to store the tables
  const toast = useToast();

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files)); // Convert FileList to array
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_ENDPOINT}api/users`);
        const filteredUsers = response.data.data.filter(
          (user) =>
            Array.isArray(user.usersWithPermissions) &&
            user.usersWithPermissions.some((perm) => perm.userId === userId)
        );
        setUsers(filteredUsers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [userId]);

  const saveFolder = async () => {
    if (!folderName) {
      toast({
        title: "Folder Name Required",
        description: "Please enter a folder name before uploading.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("folderName", folderName);
    formData.append("userId", userId);
    formData.append("name", userName);

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${API_ENDPOINT}api/folders/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsLoading(false);
      setIsUploadModalOpen(false);
      setFolderName("");
      setSelectedFiles([]);
      fetchFolders();
      toast({
        title: "Folder Uploaded",
        description: "Your folder has been successfully uploaded.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.error("Error uploading folder:", error);
      setIsLoading(false);
      toast({
        title: "Error",
        description:
          "Failed to upload folder. " + (error.response?.data?.message || ""),
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const fetchFolders = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/folders/user/${userId}`
      );
      setFolders(response.data.folders || []);
    } catch (error) {
      toast({
        title: "Error fetching folders",
        description:
          error.response?.data?.message ||
          "An error occurred while fetching folders.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFolders();
  }, [userId]);

  return (
    <Box className="folders-table-container">
      <Flex
        borderBottom={".5px solid grey"}
        alignItems={"center"}
        justifyContent={"space-between"}
        pb={1}
      >
        <Flex
          justifyContent={"space-between"}
          w={"100%"}
          alignItems={"center"}
          gap={"20px"}
        >
          <Flex alignItems={"center"} gap={"8px"}>
            <Icon fontSize={"25px"} as={BsFolder} />
            <Text className="planning-title-text">Folders</Text>
          </Flex>

          <Flex alignItems={"center"} gap={"10px"}>
            <Button
              onClick={() => setIsUploadModalOpen(true)}
              size={"sm"}
              variant={"outline"}
            >
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={BsPlusLg} />
                <Text>Upload</Text>
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Box className="folderstableanchor">
        {isLoading ? (
          <Center mt={2}>
            <Spinner />
          </Center>
        ) : folders.length > 0 ? (
          <SimpleGrid columns={{ base: 1, md: 5 }} spacing={5} p={0}>
            {folders?.map((folder) => (
              <>
                <IndividualFolderItem
                  folderId={folder._id}
                  folder={folder}
                  colorMode={colorMode}
                  fetchFolders={fetchFolders}
                />
              </>
            ))}
          </SimpleGrid>
        ) : (
          <Box>
            <NoMainFolder
              colorMode={colorMode}
              setIsUploadModalOpen={setIsUploadModalOpen}
            />
          </Box>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UserInviteFolders
              colorMode={colorMode}
              userId={userId}
              onClose={onClose}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Invite
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Upload Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection={"column"} gap={"15px"}>
              <Input
                type="text"
                placeholder="Folder Name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />

              <Input
                type="file"
                webkitdirectory=""
                directory=""
                onChange={handleFileChange}
                multiple
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex w={"100%"} alignItems={"center"} gap={"15px"}>
              <Button
                w={"100%"}
                variant="outline"
                onClick={() => setIsUploadModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                w={"100%"}
                onClick={saveFolder}
                isLoading={isLoading}
                loadingText="Saving..."
                isDisabled={isLoading}
              >
                Save Folder
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FoldersTable;
