import React from "react";
import { Text, Flex, Image, Tag, Icon } from "@chakra-ui/react";
import "./HomeHero3.css";
import { IoMdCheckmarkCircle } from "react-icons/io";
import newimageforside from "../../Svggs/beatsdarkmodeimg.svg";
import side2light from "../../Svggs/beatslightmodenewset.svg";

const HomeHero3 = ({ colorMode, isMobileView }) => {
  return (
    <Flex
      className={`HomeHero3-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      flexDirection={"column"}
    >
      <Flex className="inside-margins" h={"100%"} w={"100%"}>
        <Flex h={"100%"} p={1} w={isMobileView ? "50%" : "70%"}>
          <Flex w={"100%"} flexDirection={"column"} h={"100%"}>
            <Flex
              mt={5}
              p={isMobileView ? 2 : 10}
              flexDirection={"column"}
              gap={"10px"}
            >
              <Flex>
                <Tag
                  size="lg"
                  borderRadius="4px"
                  variant="solid"
                  colorScheme="blue"
                >
                  Management Tools
                </Tag>
              </Flex>
              <Flex flexDirection={"column"} gap={"10px"}>
                <Text className="manage-text">Manage </Text>
                <Text className="thedescript">
                  Set statuses to track assets and ensure efficient use of all
                  beats, lyrics, and recordings. Organize collections for
                  upcoming releases, assign specific tasks, and grant access to
                  your team to start collaborating!
                </Text>
              </Flex>
              <Flex
                justifyContent={"space-between"}
                boxShadow={"md"}
                mt={5}
                w={"100%"}
                bg={colorMode === "light" ? "gray.50" : ""}
                className="toolbar"
              >
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={IoMdCheckmarkCircle} />
                  <Text>Assign Tasks</Text>
                </Flex>{" "}
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={IoMdCheckmarkCircle} />
                  <Text>Link and Pair Assets</Text>
                </Flex>
                <Flex alignItems={"center"} gap={"5px"}>
                  <Icon as={IoMdCheckmarkCircle} />
                  <Text>Control Access & Permissions</Text>
                </Flex>{" "}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex h={"100%"} p={0} w={isMobileView ? "50%" : "55%"}>
          <Flex className="homehero3-img-container">
            <Image
              className="homehero3-img"
              src={colorMode === "light" ? side2light : newimageforside}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HomeHero3;
