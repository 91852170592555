import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { BsPlusLg } from "react-icons/bs";

const NoMainFolder = ({ setIsUploadModalOpen, colorMode }) => {
  return (
    <Box
      className={`nobeats-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
      bg={colorMode === "light" ? "gray.50" : "gray.800"}
      border={
        colorMode === "light"
          ? ".5px solid rgba(187, 186, 187, 1)"
          : "1px solid rgba(128, 128, 128, 1)"
      }
    >
      <Flex
        gap={"15px"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text
          className={`getstartedbelow ${
            colorMode === "light" ? "lightmode" : "darkmode"
          }`}
          textAlign={"center"}
        >
          Upload Your First Folder!
        </Text>
        <Flex>
          <Button
            size={"sm"}
            onClick={() => setIsUploadModalOpen(true)}
            colorScheme="blue"
          >
            <Flex alignItems={"center"} gap={"5px"}>
              <Icon as={BsPlusLg} />
              <Text>New Folder</Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NoMainFolder;
