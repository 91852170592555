import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useColorMode, ColorModeScript, Box } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import "./App.css";
import Home from "./Pages/Home/Home";
import Beats from "./Pages/Beats/Beats";
import Artwork from "./Pages/Artwork/Artwork";
import Recordings from "./Pages/Recordings/Recordings";
import Releases from "./Pages/Releases/Releases";
import Lyrics from "./Pages/Lyrics/Lyrics";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import SideBar from "./Components/SideBar/SideBar";
import MainDetailsPanel from "./Components/DetailsPanel/MainDetailsPanel";
import MobileHeader from "./Components/Header/MobileHeader";
import { ReleasePanelProvider } from "./Context/ReleasePanelContext";
import SessionFiles from "./Pages/SessionFiles/SessionFiles";
import { ArtworksPanelProvider } from "./Context/ArtworkPanelContext";
import { BeatPanelProvider } from "./Context/BeatsPanelContext";
import { LyricsPanelProvider } from "./Context/LyricsPanelContext";
import { RecordingsPanelProvider } from "./Context/RecordingsPanelContext";
import { SessionFilesPanelProvider } from "./Context/SessionFilePanelContext";
import Settings from "./Pages/Settings/Settings";
import { ProfileSettingsProvider } from "./Context/ProfileSettingsContext";
import { PlanningPanelProvider } from "./Context/PlanningPanelContext";
import MainLanding from "./Pages/MainLanding/MainLanding";
import { useNavigate } from "react-router-dom";
import About from "./Pages/About/About";
import MobileFooter from "./Components/Footer/MobileFooter";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Pricing from "./Pages/Pricing/Pricing";
import Contact from "./Pages/Contact/Contact";
import { BeatsKanbanProvider } from "./Context/BeatsKanbanContext";
import { LyricsKanbanProvider } from "./Context/LyricsKanbanContext";
import { RecordingsKanbanProvider } from "./Context/RecordingsKanbanContext";
import { AddLyricToReleaseProvider } from "./Context/AddLyricToRelease";
import { NotificationsPanelProvider } from "./Context/NotificationsPanelContext";
import { AddBeatToReleaseProvider } from "./Context/AddBeatToRelease";
import { LinkBeatProvider } from "./Context/LinkBeat";
import { AddArtworkToReleaseProvider } from "./Context/AddArtworkToRelease";
import { LinkArtworkProvider } from "./Context/LinkArtworkContext";
import { ReleasesKanbanProvider } from "./Context/ReleasesKanbanContext";
import axios from "axios";
import { API_ENDPOINT } from "./Config/config";
import { BuildReleaseProvider } from "./Context/BuildReleaseContext";
import { EmptyBeatProvider } from "./Context/EmptyStates/EmptyBeatContext";
import { CurrentBeatProvider } from "./Context/CurrentBeatContext";
import { CurrentLyricProvider } from "./Context/CurrentLyricContext";
import { EmptyLyricProvider } from "./Context/EmptyStates/EmptyLyricContext";
import { EmptyArtworkProvider } from "./Context/EmptyStates/EmptyArtworkContext";
import { CurrentArtworkProvider } from "./Context/CurrentArtworkContext";
import { CurrentRecordingProvider } from "./Context/CurrentRecordingContext";
import { EmptyRecordingProvider } from "./Context/EmptyStates/EmptyRecordingContext";
import { MusicPlayerProvider } from "./Context/MusicPlayerContext";
import { AddBeatToLyricProvider } from "./Context/AddBeatToLyricContext";
import { AddRecordingToLyricProvider } from "./Context/AddRecordingToLyricContext";
import { ReleaseBuildingPanelProvider } from "./Context/ReleaseBuildingPanelContext";
import { CurrentReleaseProvider } from "./Context/CurrentReleaseContext";
import { LinkRecordingProvider } from "./Context/LinkRecordingContext";
import { AddRecordingToReleaseProvider } from "./Context/AddRecordingToReleaseContext";
import Messages from "./Pages/Messages/Messages";
import Permissions from "./Pages/Permissions/Permissions";
import { SelectedRecordingProvider } from "./Context/SelectedRecordingContext";
import { SelectedBeatProvider } from "./Context/SelectedBeatContext";
import { RefreshBeatsProvider } from "./Context/RefreshBeats/RefreshBeatsContext";
import MutualSpace from "./Pages/MutualSpace/MutualSpace";
import { MutualSpaceProvider } from "./Context/MutualSpaceContext";
import MutualSpaceFolder from "./Pages/MutualSpaceFolder/MutualSpaceFolder";
import MobileMutualSpaceFolders from "./Pages/MutualSpaceFolder/MobileMutualSpaceFolders";
import Folders from "./Pages/Folders/Folders";
import Documents from "./Pages/Documents/Documents";
import IndividualFolder from "./Pages/IndividualFolder/IndividualFolder";
import Tasks from "./Pages/Tasks/Tasks";
import Planning from "./Pages/Planning/Planning";
import Assets from "./Pages/Assets/Assets";
import { DirectMessageDisplayProvider } from "./Context/DirectMessageDisplayContext";
import MobileIndividualFolder from "./Pages/IndividualFolder/MobileIndividualFolder";
import LoggedInHeader from "./Components/Header/LoggedInHeader";
import MutualBoard from "./Pages/MutualBoard/MutualBoard";
import { MutualBoardProvider } from "./Context/MutualBoardContext";
import { BoardDataAndReleasesProvider } from "./Context/BoardDataAndReleasesContext";
import { ReleasesPageTableProvider } from "./Context/ReleasesPageTableContext";
import Storage from "./Pages/Storage/Storage";

function MainContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { colorMode } = useColorMode();

  const [cookies, setCookie, removeCookie] = useCookies([
    "AuthToken",
    "UserId",
    "Name",
    "Email",
    "GuestToken",
  ]);

  const AuthToken = cookies.AuthToken;
  const userId = cookies.UserId;
  const userName = cookies.Name;
  const userEmail = cookies.Email;
  const guestToken = cookies.GuestToken;

  useEffect(() => {
    // Define paths that don't require authentication
    const publicPaths = ["/", "/login", "/register", "/about", "/storage"];

    // Redirect to login if not authenticated and on a non-public path
    if (!publicPaths.includes(location.pathname) && !userId && !guestToken) {
      navigate("/");
    } else if (publicPaths.includes(location.pathname) && guestToken) {
      // If navigating to any publicPaths, and there's a guestToken, remove it
      removeCookie("GuestToken", { path: "/" });
    }
  }, [location, userId, guestToken, navigate, removeCookie]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const response = await axios.get(`${API_ENDPOINT}api/user/${userId}`);
          setUserData(response.data.data);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const toggleSidebarClosed = () => {
    setIsSidebarCollapsed(true);
  };

  const toggleSidebarOpen = () => {
    setIsSidebarCollapsed(false);
  };

  const toggleMainDetails = () => {
    setIsCollapsed(!isCollapsed);
  };

  const isAuthRoute =
    location.pathname === "/login" || location.pathname === "/register";

  const isLandingRoute =
    location.pathname === "/landing-main" ||
    location.pathname === "/about" ||
    location.pathname === "/privacy-policy" ||
    location.pathname === "/pricing" ||
    location.pathname === "/contact" ||
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    location.pathname === "/" ||
    location.pathname === "/storage";

  const isLoginRoute = location.pathname === "/login";

  const closedetailsPanelSetting = () => {
    if (!isCollapsed) {
      setIsCollapsed(true);
    }
  };

  const openDetailsPanelSettings = () => {
    if (isCollapsed) {
      setIsCollapsed(false);
    }
  };

  useEffect(() => {
    const homeDashRoute = ["/dash"];
    if (homeDashRoute.includes(location.pathname)) {
      setIsCollapsed(true);
    }
  }, [location]);

  return (
    <>
      <Box
        className={`App ${colorMode === "light" ? "lightmode" : "darkmode"}`}
      >
        {isMobileView ? (
          <>
            <MobileHeader
              userName={userName}
              userEmail={userEmail}
              toggleSidebar={toggleSidebar}
              isSidebarCollapsed={isSidebarCollapsed}
              colorMode={colorMode}
              location={location}
              isLandingRoute={isLandingRoute}
              AuthToken={AuthToken}
              isAuthRoute={isAuthRoute}
              isLoginRoute={isLoginRoute}
              isCollapsed={isCollapsed}
              toggleMainDetails={toggleMainDetails}
              isMobileView={isMobileView}
              removeCookie={removeCookie}
            />
          </>
        ) : AuthToken ? (
          <>
            <LoggedInHeader
              removeCookie={removeCookie}
              userName={userName}
              userEmail={userEmail}
              toggleSidebar={toggleSidebar}
              isSidebarCollapsed={isSidebarCollapsed}
              colorMode={colorMode}
              location={location}
              isLandingRoute={isLandingRoute}
              AuthToken={AuthToken}
              isAuthRoute={isAuthRoute}
              isLoginRoute={isLoginRoute}
              isCollapsed={isCollapsed}
              toggleMainDetails={toggleMainDetails}
              isMobileView={isMobileView}
              setIsCollapsed={setIsCollapsed}
              guestToken={guestToken}
            />
          </>
        ) : (
          <Header
            removeCookie={removeCookie}
            userName={userName}
            userEmail={userEmail}
            toggleSidebar={toggleSidebar}
            isSidebarCollapsed={isSidebarCollapsed}
            colorMode={colorMode}
            location={location}
            isLandingRoute={isLandingRoute}
            AuthToken={AuthToken}
            isAuthRoute={isAuthRoute}
            isLoginRoute={isLoginRoute}
            isCollapsed={isCollapsed}
            toggleMainDetails={toggleMainDetails}
            isMobileView={isMobileView}
            setIsCollapsed={setIsCollapsed}
            guestToken={guestToken}
          />
        )}

        <Box className={isLandingRoute ? "layoutlanding" : "layout"}>
          {!isAuthRoute && !isLandingRoute && (
            <SideBar
              colorMode={colorMode}
              toggleSidebar={toggleSidebar}
              isSidebarCollapsed={isSidebarCollapsed}
              setIsSidebarCollapsed={setIsSidebarCollapsed}
              isMobileView={isMobileView}
              toggleMainDetails={toggleMainDetails}
              setIsCollapsed={setIsCollapsed}
              isCollapsed={isCollapsed}
              userName={userName}
              userId={userId}
              toggleSidebarClosed={toggleSidebarClosed}
              toggleSidebarOpen={toggleSidebarOpen}
              guestToken={guestToken}
            />
          )}
          <Routes>
            <Route
              path="/"
              exact
              element={
                <MainLanding
                  setCookie={setCookie}
                  toggleSidebar={toggleSidebar}
                  isSidebarCollapsed={isSidebarCollapsed}
                  isMobileView={isMobileView}
                  colorMode={colorMode}
                  guestToken={guestToken}
                />
              }
            />
            <Route
              path="/messages"
              exact
              element={
                <Messages
                  toggleSidebar={toggleSidebar}
                  isSidebarCollapsed={isSidebarCollapsed}
                  isMobileView={isMobileView}
                  colorMode={colorMode}
                  guestToken={guestToken}
                  userName={userName}
                  userId={userId}
                />
              }
            />
            <Route
              path="/mutualspace/:mutualSpaceId"
              element={
                <MutualSpace
                  guestToken={guestToken}
                  userName={userName}
                  userId={userId}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                  isSidebarCollapsed={isSidebarCollapsed}
                />
              }
            />
            <Route
              path="/mutualspace/:mutualSpaceId/board/:boardId"
              element={
                <MutualBoard
                  guestToken={guestToken}
                  userName={userName}
                  userId={userId}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                  isSidebarCollapsed={isSidebarCollapsed}
                  openDetailsPanelSettings={openDetailsPanelSettings}
                  closedetailsPanelSetting={closedetailsPanelSetting}
                />
              }
            />
            <Route
              path="/permissions"
              exact
              element={
                <Permissions
                  toggleSidebar={toggleSidebar}
                  isSidebarCollapsed={isSidebarCollapsed}
                  isMobileView={isMobileView}
                  colorMode={colorMode}
                  guestToken={guestToken}
                  userName={userName}
                  userId={userId}
                />
              }
            />

            <Route
              path="/beats"
              element={
                <Beats
                  closedetailsPanelSetting={closedetailsPanelSetting}
                  openDetailsPanelSettings={openDetailsPanelSettings}
                  guestToken={guestToken}
                  userName={userName}
                  userId={userId}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                  isSidebarCollapsed={isSidebarCollapsed}
                />
              }
            />
            <Route
              path="/assets"
              element={
                <Assets
                  closedetailsPanelSetting={closedetailsPanelSetting}
                  openDetailsPanelSettings={openDetailsPanelSettings}
                  guestToken={guestToken}
                  userName={userName}
                  userId={userId}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                  isSidebarCollapsed={isSidebarCollapsed}
                />
              }
            />
            <Route
              path="/folders"
              element={
                <Folders
                  closedetailsPanelSetting={closedetailsPanelSetting}
                  openDetailsPanelSettings={openDetailsPanelSettings}
                  guestToken={guestToken}
                  userName={userName}
                  userId={userId}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                  isSidebarCollapsed={isSidebarCollapsed}
                />
              }
            />
            <Route
              path="/documents"
              element={
                <Documents
                  closedetailsPanelSetting={closedetailsPanelSetting}
                  openDetailsPanelSettings={openDetailsPanelSettings}
                  guestToken={guestToken}
                  userName={userName}
                  userId={userId}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                  isSidebarCollapsed={isSidebarCollapsed}
                />
              }
            />
            <Route
              path="/dash"
              element={
                <Home
                  guestToken={guestToken}
                  userId={userId}
                  userName={userName}
                  userEmail={userEmail}
                  setUserData={setUserData}
                  isMobileView={isMobileView}
                  colorMode={colorMode}
                />
              }
            />
            <Route
              path="/landing-main"
              element={
                <MainLanding
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                />
              }
            />
            <Route
              path="/artwork"
              element={
                <Artwork
                  userName={userName}
                  userId={userId}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                  guestToken={guestToken}
                  closedetailsPanelSetting={closedetailsPanelSetting}
                  openDetailsPanelSettings={openDetailsPanelSettings}
                />
              }
            />
            <Route
              path="/recordings"
              element={
                <Recordings
                  userName={userName}
                  userId={userId}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                  guestToken={guestToken}
                  closedetailsPanelSetting={closedetailsPanelSetting}
                  openDetailsPanelSettings={openDetailsPanelSettings}
                />
              }
            />
            <Route
              path="/releases"
              element={
                <Releases
                  guestToken={guestToken}
                  userName={userName}
                  userId={userId}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                  closedetailsPanelSetting={closedetailsPanelSetting}
                  openDetailsPanelSettings={openDetailsPanelSettings}
                />
              }
            />
            <Route
              path="/lyrics"
              element={
                <Lyrics
                  userName={userName}
                  userId={userId}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                  guestToken={guestToken}
                  closedetailsPanelSetting={closedetailsPanelSetting}
                  openDetailsPanelSettings={openDetailsPanelSettings}
                />
              }
            />
            <Route
              path="/login"
              element={
                <Login
                  AuthToken={AuthToken}
                  colorMode={colorMode}
                  setCookie={setCookie}
                  isMobileView={isMobileView}
                  guestToken={guestToken}
                  removeCookie={removeCookie}
                />
              }
            />
            <Route
              path="/register"
              element={
                <Register
                  AuthToken={AuthToken}
                  setCookie={setCookie}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                />
              }
            />
            <Route
              path="/session-files"
              element={
                <SessionFiles
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                />
              }
            />
            <Route
              path="/settings"
              element={
                <Settings
                  userData={userData}
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                />
              }
            />
            <Route
              path="/about"
              element={
                <About colorMode={colorMode} isMobileView={isMobileView} />
              }
            />
            <Route
              path="/storage"
              exact
              element={
                <Storage
                  toggleSidebar={toggleSidebar}
                  isSidebarCollapsed={isSidebarCollapsed}
                  isMobileView={isMobileView}
                  colorMode={colorMode}
                  guestToken={guestToken}
                  userName={userName}
                  userId={userId}
                />
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <PrivacyPolicy
                  colorMode={colorMode}
                  isMobileView={isMobileView}
                />
              }
            />
            <Route
              path="/pricing"
              element={
                <Pricing colorMode={colorMode} isMobileView={isMobileView} />
              }
            />
            <Route
              path="/contact"
              element={
                <Contact colorMode={colorMode} isMobileView={isMobileView} />
              }
            />
            <Route
              path="/mutualspace/:mutualSpaceId/folder/:folderId"
              element={
                isMobileView ? (
                  <MobileMutualSpaceFolders
                    guestToken={guestToken}
                    userName={userName}
                    userId={userId}
                    colorMode={colorMode}
                    isMobileView={isMobileView}
                  />
                ) : (
                  <MutualSpaceFolder
                    guestToken={guestToken}
                    userName={userName}
                    userId={userId}
                    colorMode={colorMode}
                    isMobileView={isMobileView}
                  />
                )
              }
            />

            <Route
              path="/folder/:folderId"
              element={
                isMobileView ? (
                  <MobileIndividualFolder
                    colorMode={colorMode}
                    isCollapsed={isCollapsed}
                    toggleMainDetails={toggleMainDetails}
                    isMobileView={isMobileView}
                    AuthToken={AuthToken}
                    userEmail={userEmail}
                    userName={userName}
                    guestToken={guestToken}
                    userId={userId}
                    closedetailsPanelSetting={closedetailsPanelSetting}
                    openDetailsPanelSettings={openDetailsPanelSettings}
                  />
                ) : (
                  <IndividualFolder
                    colorMode={colorMode}
                    isCollapsed={isCollapsed}
                    toggleMainDetails={toggleMainDetails}
                    isMobileView={isMobileView}
                    AuthToken={AuthToken}
                    userEmail={userEmail}
                    userName={userName}
                    guestToken={guestToken}
                    userId={userId}
                    closedetailsPanelSetting={closedetailsPanelSetting}
                    openDetailsPanelSettings={openDetailsPanelSettings}
                  />
                )
              }
            />
            <Route
              path="/tasks"
              element={
                <Tasks
                  colorMode={colorMode}
                  isCollapsed={isCollapsed}
                  toggleMainDetails={toggleMainDetails}
                  isMobileView={isMobileView}
                  AuthToken={AuthToken}
                  userEmail={userEmail}
                  userName={userName}
                  guestToken={guestToken}
                  userId={userId}
                  closedetailsPanelSetting={closedetailsPanelSetting}
                  openDetailsPanelSettings={openDetailsPanelSettings}
                />
              }
            />
            <Route
              path="/planning"
              element={
                <Planning
                  colorMode={colorMode}
                  isCollapsed={isCollapsed}
                  toggleMainDetails={toggleMainDetails}
                  isMobileView={isMobileView}
                  AuthToken={AuthToken}
                  userEmail={userEmail}
                  userName={userName}
                  guestToken={guestToken}
                  userId={userId}
                  closedetailsPanelSetting={closedetailsPanelSetting}
                  openDetailsPanelSettings={openDetailsPanelSettings}
                />
              }
            />
          </Routes>
          {!isMobileView && !isAuthRoute && !isLandingRoute && (
            <MainDetailsPanel
              colorMode={colorMode}
              isCollapsed={isCollapsed}
              toggleMainDetails={toggleMainDetails}
              isMobileView={isMobileView}
              AuthToken={AuthToken}
              userEmail={userEmail}
              userName={userName}
              guestToken={guestToken}
              userId={userId}
              closedetailsPanelSetting={closedetailsPanelSetting}
              openDetailsPanelSettings={openDetailsPanelSettings}
            />
          )}
        </Box>

        <>
          {!AuthToken && (
            <>
              {isMobileView ? (
                <>
                  <MobileFooter
                    colorMode={colorMode}
                    navigate={navigate}
                    isLandingRoute={isLandingRoute}
                    AuthToken={AuthToken}
                    isAuthRoute={isAuthRoute}
                    isLoginRoute={isLoginRoute}
                    isCollapsed={isCollapsed}
                    toggleMainDetails={toggleMainDetails}
                    isMobileView={isMobileView}
                  />
                </>
              ) : (
                <Footer
                  colorMode={colorMode}
                  navigate={navigate}
                  isLandingRoute={isLandingRoute}
                  AuthToken={AuthToken}
                  isAuthRoute={isAuthRoute}
                  isLoginRoute={isLoginRoute}
                  isCollapsed={isCollapsed}
                  toggleMainDetails={toggleMainDetails}
                  isMobileView={isMobileView}
                />
              )}
            </>
          )}
        </>
      </Box>
    </>
  );
}

function App() {
  const { colorMode } = useColorMode();

  return (
    <Box>
      <ReleasePanelProvider>
        <ArtworksPanelProvider>
          <LyricsPanelProvider>
            <RecordingsPanelProvider>
              <ProfileSettingsProvider>
                <BuildReleaseProvider>
                  <LyricsKanbanProvider>
                    <AddLyricToReleaseProvider>
                      <AddArtworkToReleaseProvider>
                        <CurrentRecordingProvider>
                          <RecordingsKanbanProvider>
                            <AddBeatToReleaseProvider>
                              <CurrentBeatProvider>
                                <LinkArtworkProvider>
                                  <CurrentLyricProvider>
                                    <EmptyBeatProvider>
                                      <EmptyLyricProvider>
                                        <EmptyArtworkProvider>
                                          <EmptyRecordingProvider>
                                            <ReleasesKanbanProvider>
                                              <NotificationsPanelProvider>
                                                <AddBeatToLyricProvider>
                                                  <LinkRecordingProvider>
                                                    <AddRecordingToLyricProvider>
                                                      <SelectedBeatProvider>
                                                        <AddRecordingToReleaseProvider>
                                                          <SelectedRecordingProvider>
                                                            <CurrentReleaseProvider>
                                                              <CurrentArtworkProvider>
                                                                <RefreshBeatsProvider>
                                                                  <MusicPlayerProvider>
                                                                    <MutualSpaceProvider>
                                                                      <MutualBoardProvider>
                                                                        <LinkBeatProvider>
                                                                          <DirectMessageDisplayProvider>
                                                                            <ReleaseBuildingPanelProvider>
                                                                              <BeatsKanbanProvider>
                                                                                <BoardDataAndReleasesProvider>
                                                                                  <ReleasesPageTableProvider>
                                                                                    <BeatPanelProvider>
                                                                                      <Router>
                                                                                        <Box
                                                                                          bg={
                                                                                            colorMode ===
                                                                                            "light"
                                                                                              ? "rgba(61, 184, 245, 0.072)"
                                                                                              : "rgba(48, 51, 77, 1)"
                                                                                          }
                                                                                        >
                                                                                          <ColorModeScript
                                                                                            initialColorMode={
                                                                                              colorMode
                                                                                            }
                                                                                          />
                                                                                          <MainContent />
                                                                                        </Box>
                                                                                      </Router>
                                                                                    </BeatPanelProvider>
                                                                                  </ReleasesPageTableProvider>
                                                                                </BoardDataAndReleasesProvider>
                                                                              </BeatsKanbanProvider>
                                                                            </ReleaseBuildingPanelProvider>
                                                                          </DirectMessageDisplayProvider>
                                                                        </LinkBeatProvider>
                                                                      </MutualBoardProvider>
                                                                    </MutualSpaceProvider>
                                                                  </MusicPlayerProvider>
                                                                </RefreshBeatsProvider>
                                                              </CurrentArtworkProvider>
                                                            </CurrentReleaseProvider>
                                                          </SelectedRecordingProvider>
                                                        </AddRecordingToReleaseProvider>
                                                      </SelectedBeatProvider>
                                                    </AddRecordingToLyricProvider>
                                                  </LinkRecordingProvider>
                                                </AddBeatToLyricProvider>
                                              </NotificationsPanelProvider>
                                            </ReleasesKanbanProvider>
                                          </EmptyRecordingProvider>
                                        </EmptyArtworkProvider>
                                      </EmptyLyricProvider>
                                    </EmptyBeatProvider>
                                  </CurrentLyricProvider>
                                </LinkArtworkProvider>
                              </CurrentBeatProvider>
                            </AddBeatToReleaseProvider>
                          </RecordingsKanbanProvider>
                        </CurrentRecordingProvider>
                      </AddArtworkToReleaseProvider>
                    </AddLyricToReleaseProvider>
                  </LyricsKanbanProvider>
                </BuildReleaseProvider>
              </ProfileSettingsProvider>
            </RecordingsPanelProvider>
          </LyricsPanelProvider>
        </ArtworksPanelProvider>
      </ReleasePanelProvider>
    </Box>
  );
}

export default App;
