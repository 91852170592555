import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import { useCurrentArtwork } from "../../../Context/CurrentArtworkContext";
import { useAddArtworkToRelease } from "../../../Context/AddArtworkToRelease";
import { useArtworksPanel } from "../../../Context/ArtworkPanelContext";
import { useLinkArtwork } from "../../../Context/LinkArtworkContext";

const AssetArtworkItem = ({
  artworkAsset,
  userName,
  userId,
  colorMode,
  selectedTableDataId,
  openDetailsPanelSettings,
  guestToken,
  artworkAssetId,
  isMobileView,
  fetchArtworkAssets,
}) => {
  const { setAddArtwork } = useAddArtworkToRelease();
  const { setArtworksPanel } = useArtworksPanel();
  const { setCurrentArtwork } = useCurrentArtwork();
  const { setLinkArtwork } = useLinkArtwork();

  const openArtworkPanel = (event) => {
    event.stopPropagation(); // Stop the event from propagating further
    setCurrentArtwork(artworkAsset);
    setArtworksPanel("Artworks");
    setAddArtwork(null);
    setLinkArtwork(null);
    openDetailsPanelSettings();
  };

  return (
    <Box
      onClick={openArtworkPanel}
      className={`artwork-conainter ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex>
        <Image className="artworkAsset-img" src={artworkAsset.imageUrl} />
      </Flex>
    </Box>
  );
};

export default AssetArtworkItem;
