import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  useToast,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Icon,
  MenuItem,
  MenuButton,
  Menu,
  MenuList,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import { BsPlusLg, BsFolder, BsFolderPlus } from "react-icons/bs";
import axios from "axios";
import { MdOutlineLyrics } from "react-icons/md";
import "./BeatDrawer.css";
import { API_ENDPOINT } from "../../../../Config/config";
import { AddIcon } from "@chakra-ui/icons";
import { TbUserPlus } from "react-icons/tb";
import { AiOutlineUser } from "react-icons/ai";
import CurrentBeatStatusItem from "../../../DetailsPanel/BeatsPanel/CurrentBeatStatusItem/CurrentBeatStatusItem";
import AddWritingToUser from "../../../DetailsPanel/BeatsPanel/WritingToItems/AddWritingToUser";
import { BiMicrophone, BiUserPlus } from "react-icons/bi";
import LinkLyricsModal from "../../../DetailsPanel/BeatsPanel/WritingToItems/LinkLyricsModal";
import CurrentBeatLyricItem from "../../../DetailsPanel/BeatsPanel/CurrentBeatLyricItem";
import LinkedItemRecording from "../../../Players/MutualSpacePlayers/LinkedItemRecording";
import LinkRecordingModal from "../../../DetailsPanel/BeatsPanel/WritingToItems/LinkRecordingModal";
import AddToFolderModal from "../../../DetailsPanel/BeatsPanel/AddToFolderModal";
import { PiFolderPlus } from "react-icons/pi";
import CurrentBeatComments from "../../../DetailsPanel/BeatsPanel/CurrentBeatComments";
import { GiSandsOfTime } from "react-icons/gi";
import TimeLineDates from "../../../DetailsPanel/BeatsPanel/TimelineDates/TimeLineDates";
import { FaCircleCheck } from "react-icons/fa6";

const BeatDrawer = ({
  userId,
  beatAsset,
  colorMode,
  userName,
  mutualSpaceId,
  boardId,
  tableId,
  beatId,
  fetchBeatData,
  guestToken,
}) => {
  const toast = useToast();
  const inputBg = useColorModeValue("gray.50", "rgba(66, 69, 99, 0.23)");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const [isLinkLyricsOpen, setIsLinkLyricsOpen] = useState(false);
  const [isLinkRecordingsOpen, setIsLinkRecordingsOpen] = useState(false);
  const [isAddToFolderOpen, setIsAddToFolderOpen] = useState(false);

  const handleProducerChange = (event) => {
    setProducer(event.target.value);
  };

  const [producer, setProducer] = useState(beatAsset?.producer || "");
  const [aNr, setAnR] = useState(beatAsset?.aNr || "A&R");
  const [originalAnR, setOriginalAnR] = useState(beatAsset?.aNr || "");
  const [aNrs, setAnRs] = useState([]);

  const [producers, setProducers] = useState([]);
  const [isAddWritingToUserOpen, setIsAddWritingToUserOpen] = useState(false);
  const [originalProducer, setOriginalProducer] = useState(
    beatAsset?.producer || ""
  );

  const updateProducer = async (selectedProducer) => {
    const producerToUpdate = selectedProducer || producer;
    try {
      const response = await axios.patch(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/beat/${beatId}/producer`,
        { producer: producerToUpdate }
      );

      if (response.status === 200) {
        toast({
          title: "Producer Updated",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
        fetchBeatData(); // Refresh beat data if needed
      }
    } catch (error) {
      toast({
        title: "Error Updating Producer",
        description: "There was an issue updating the producer name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    setProducer(beatAsset?.producer || "Producer");
  }, [beatAsset]);

  const charToColorArtist = (char) => {
    const alphabetPosition = char.toUpperCase().charCodeAt(0) - 64;
    const hue = (alphabetPosition * 137) % 360; // 137 is a "magic" number
    const saturation = 50 + (alphabetPosition % 51); // Vary between 50% and 100%
    const lightness = 50 + ((alphabetPosition * 3) % 26); // Vary between 50% and 75%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const fetchProducers = async () => {
    if (!tableId) {
      console.error("Table ID is not available.");
      return;
    }

    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/producers`
      );
      setProducers(response.data.producers || []);
    } catch (error) {
      console.error("Failed to fetch recording artists:", error);
    }
  };

  useEffect(() => {
    fetchProducers();
  }, [tableId]);

  const charToColor = (char) => {
    const alphabetPosition = char.toUpperCase().charCodeAt(0) - 64;
    const hue = (alphabetPosition * 137) % 360; // 137 is a "magic" number
    const saturation = 50 + (alphabetPosition % 51); // Vary between 50% and 100%
    const lightness = 50 + ((alphabetPosition * 3) % 26); // Vary between 50% and 75%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  return (
    <Box className="BeatDrawer-container">
      <Flex alignItems={"end"} gap={"10px"}>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"5px"}
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={GiSandsOfTime} />
            <Text className="slighttext">Status</Text>
          </Flex>
          <CurrentBeatStatusItem
            tableId={tableId}
            mutualSpaceId={mutualSpaceId}
            boardId={boardId}
            currentBeat={beatAsset}
            colorMode={colorMode}
            beatId={beatAsset._id}
            fetchBeatData={fetchBeatData}
            guestToken={guestToken}
          />
        </Flex>
        {beatAsset.status && (
          <Flex>
            <TimeLineDates
              userId={userId}
              currentBeat={beatAsset}
              colorMode={colorMode}
              userName={userName}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              beatId={beatId}
            />
          </Flex>
        )}
      </Flex>
      <Flex alignItems={"center"} gap={"15px"}>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"5px"}
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={TbUserPlus} />
            <Text className="slighttext">Producers</Text>
          </Flex>
          <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
            {beatAsset.producer ? (
              <>
                <Avatar
                  icon={<AiOutlineUser fontSize="15px" />}
                  size={"xs"}
                  borderRadius={"7px"}
                  bg={charToColorArtist(beatAsset?.producer)}
                />
              </>
            ) : (
              <>
                <Avatar
                  icon={<AiOutlineUser fontSize="15px" />}
                  size={"xs"}
                  borderRadius={"7px"}
                />
              </>
            )}
            <Input
              isDisabled={!!guestToken}
              value={producer}
              onChange={(e) => setProducer(e.target.value)}
              onFocus={() => setOriginalProducer(producer)}
              onBlur={() => {
                if (producer !== originalProducer) {
                  updateProducer();
                }
              }}
              borderRadius={"6px"}
              bg={inputBg}
              borderColor={borderColor}
              _hover={{
                bg: hoverBg,
                borderColor: "gray.400",
              }}
              size={"sm"}
            />
            <Menu>
              <MenuButton
                isDisabled={!!guestToken}
                as={IconButton}
                aria-label="Options"
                icon={<AddIcon />}
                variant="outline"
                size={"sm"}
              />
              <MenuList
                p={1}
                border={
                  colorMode === "light"
                    ? ".5px solid rgba(153, 178, 198, 0.23)"
                    : ".5px solid rgba(43, 46, 54, 1)"
                }
              >
                {producers.map((producer, index) => (
                  <MenuItem
                    isDisabled={!!guestToken}
                    fontSize={"12px"}
                    key={index}
                    onClick={() => {
                      setProducer(producer);
                      updateProducer(producer);
                    }}
                    bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                    _hover={{
                      bg:
                        colorMode === "light"
                          ? "rgba(241, 244, 249, 1)"
                          : "rgba(42, 49, 70, 1)",
                    }}
                  >
                    {producer}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
      <Flex alignItems={"center"} gap={"15px"}>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"5px"}
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={TbUserPlus} />
            <Text className="slighttext">A&R</Text>
          </Flex>
          <Flex w={"100%"} alignItems={"center"} gap={"5px"}>
            {beatAsset.aNr ? (
              <>
                <Avatar
                  icon={<AiOutlineUser fontSize="15px" />}
                  size={"xs"}
                  borderRadius={"7px"}
                  bg={charToColorArtist(beatAsset?.aNr)}
                />
              </>
            ) : (
              <>
                <Avatar
                  icon={<AiOutlineUser fontSize="15px" />}
                  size={"xs"}
                  borderRadius={"7px"}
                />
              </>
            )}
            <Input
              isDisabled={!!guestToken}
              value={aNr}
              onChange={(e) => setAnR(e.target.value)}
              onFocus={() => setOriginalAnR(aNr)}
              borderRadius={"6px"}
              bg={inputBg}
              borderColor={borderColor}
              _hover={{
                bg: hoverBg,
                borderColor: "gray.400",
              }}
              size={"sm"}
            />
            <Menu>
              <MenuButton
                isDisabled={!!guestToken}
                as={IconButton}
                aria-label="Options"
                icon={<AddIcon />}
                variant="outline"
                size={"sm"}
              />
              <MenuList
                p={1}
                border={
                  colorMode === "light"
                    ? ".5px solid rgba(153, 178, 198, 0.23)"
                    : ".5px solid rgba(43, 46, 54, 1)"
                }
              >
                {aNrs.map((aNr, index) => (
                  <MenuItem
                    isDisabled={!!guestToken}
                    fontSize={"12px"}
                    key={index}
                    onClick={() => {
                      setAnR(aNr);
                    }}
                    bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
                    _hover={{
                      bg:
                        colorMode === "light"
                          ? "rgba(241, 244, 249, 1)"
                          : "rgba(42, 49, 70, 1)",
                    }}
                  >
                    {aNr}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
      <Flex alignItems={"center"} gap={"15px"}>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"5px"}
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"12px"} as={BsFolder} />
            <Text className="slighttext">Folders</Text>
          </Flex>

          {beatAsset?.folders && beatAsset.folders.length > 0 ? (
            <Flex height={"100%"} w={"100%"}>
              {beatAsset.folders.map((folder) => (
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className={`writing-to-item ${
                    colorMode === "light" ? "lightmode" : "darkmode"
                  }`}
                  key={folder._id}
                >
                  <Avatar
                    icon={<BsFolder fontSize="15px" />}
                    mr={-4}
                    size={"xs"}
                    bg={charToColor(folder.folderName)}
                  />
                </Flex>
              ))}
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                className={`writing-to-item ${
                  colorMode === "light" ? "lightmode" : "darkmode"
                }`}
              >
                <Avatar
                  isDisabled={!!guestToken}
                  onClick={() => setIsAddToFolderOpen(true)}
                  size={"xs"}
                  icon={<BsFolderPlus fontSize="15px" />}
                  _hover={{ cursor: "pointer", opacity: "80%" }}
                />
              </Flex>
            </Flex>
          ) : (
            <Button
              isDisabled={!!guestToken}
              onClick={() => setIsAddToFolderOpen(true)}
              fontSize={"10px"}
              size={"sm"}
              w={"100%"}
            >
              Add to Folder
            </Button>
          )}
        </Flex>
        <Flex w={"100%"} flexDirection={"column"} gap={"5px"}>
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={FaCircleCheck} />
            <Text className="slighttext">Releases</Text>
          </Flex>

          {beatAsset?.linkedReleases && beatAsset.linkedReleases.length > 0 ? (
            <Flex w={"100%"}>
              {beatAsset.linkedReleases.map((lyric) => (
                <Box w={"100%"} key={lyric._id}>
                  Release
                </Box>
              ))}
            </Flex>
          ) : (
            <Button
              isDisabled={!!guestToken}
              w={"100%"}
              fontSize={"10px"}
              size={"sm"}
            >
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={BsPlusLg} />
                <Text>Add to Release</Text>
              </Flex>
            </Button>
          )}
        </Flex>{" "}
      </Flex>

      <Flex alignItems={"center"} gap={"15px"} w={"100%"}>
        <Flex w={"100%"} flexDirection={"column"} gap={"5px"}>
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={MdOutlineLyrics} />
            <Text className="slighttext">Lyrics</Text>
          </Flex>

          {beatAsset?.linkedlyrics && beatAsset.linkedlyrics.length > 0 ? (
            <Flex w={"100%"}>
              {beatAsset.linkedlyrics.map((lyric) => (
                <Box w={"100%"} key={lyric._id}>
                  <CurrentBeatLyricItem colorMode={colorMode} lyric={lyric} />
                </Box>
              ))}
            </Flex>
          ) : (
            <Button
              isDisabled={!!guestToken}
              onClick={() => setIsLinkLyricsOpen(true)}
              w={"100%"}
              fontSize={"10px"}
              size={"sm"}
            >
              <Flex alignItems={"center"} gap={"5px"}>
                <Icon as={BsPlusLg} />
                <Text>Lyrics</Text>
              </Flex>
            </Button>
          )}
        </Flex>
        <Flex
          w={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          gap={"5px"}
        >
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={BiMicrophone} />
            <Text className="slighttext">Recording</Text>
          </Flex>

          {beatAsset?.linkedRecordings &&
          beatAsset.linkedRecordings.length > 0 ? (
            <Flex w={"100%"}>
              {beatAsset.linkedRecordings.map((recording) => (
                <Box w={"100%"} key={recording._id}>
                  <LinkedItemRecording
                    recording={recording}
                    colorMode={colorMode}
                  />
                </Box>
              ))}
            </Flex>
          ) : (
            <Button
              isDisabled={!!guestToken}
              onClick={() => setIsLinkRecordingsOpen(true)}
              fontSize="10px"
              size="sm"
              w={"100%"}
            >
              Link Recording
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex
        className={`beatdrawer-comments ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
        w={"100%"}
      >
        <CurrentBeatComments
          userId={userId}
          beatAsset={beatAsset}
          colorMode={colorMode}
          userName={userName}
          mutualSpaceId={mutualSpaceId}
          boardId={boardId}
          tableId={tableId}
          beatId={beatId}
          guestToken={guestToken}
        />
      </Flex>
      <Modal
        isOpen={isAddWritingToUserOpen}
        onClose={() => setIsAddWritingToUserOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Add Songwriter or Artist</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddWritingToUser
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              currentBeat={beatAsset}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              beatId={beatId}
              setIsAddWritingToUserOpen={setIsAddWritingToUserOpen}
              fetchBeatData={fetchBeatData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isLinkLyricsOpen}
        onClose={() => setIsLinkLyricsOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Link Lyrics</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LinkLyricsModal
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              currentBeat={beatAsset}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              beatId={beatId}
              fetchBeatData={fetchBeatData}
              setIsLinkLyricsOpen={setIsLinkLyricsOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isLinkRecordingsOpen}
        onClose={() => setIsLinkRecordingsOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>Link Recording</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LinkRecordingModal
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              currentBeat={beatAsset}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              beatId={beatId}
              setIsLinkRecordingsOpen={setIsLinkRecordingsOpen}
              fetchBeatData={fetchBeatData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isAddToFolderOpen}
        onClose={() => setIsAddToFolderOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Icon className="granticon" as={PiFolderPlus} />
              <Text className="accessheader">Add to Folder</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddToFolderModal
              userId={userId}
              userName={userName}
              colorMode={colorMode}
              currentBeat={beatAsset}
              mutualSpaceId={mutualSpaceId}
              boardId={boardId}
              tableId={tableId}
              beatId={beatId}
              setIsAddToFolderOpen={setIsAddToFolderOpen}
              fetchBeatData={fetchBeatData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BeatDrawer;
