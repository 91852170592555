import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  useToast,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Input,
  Icon,
  Tooltip,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { BsFilter, BsPlus, BsSearch } from "react-icons/bs";
import { FaSort } from "react-icons/fa";
import axios from "axios";
import MainArtwork from "../Artwork/MainArtwork";
import MainArtworkUpload from "../MainUploads/MainArtworkUpload";
import { API_ENDPOINT } from "../../../../Config/config";
import { useCurrentArtwork } from "../../../../Context/CurrentArtworkContext";

const MainArtworkAssetTable = ({
  colorMode,
  board,
  boardId,
  mutualSpaceId,
  userId,
  isSidebarCollapsed,
  tableId,
  userName,
  guestToken,
  closedetailsPanelSetting,
  openDetailsPanelSettings,
}) => {
  const [artworkAssets, setArtworkAssets] = useState([]);
  const [selectedArtworkAssets, setSelectedArtworkAssets] = useState([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const inputBg = useColorModeValue(
    "rgba(247, 250, 252, 0.43)",
    "rgba(66, 69, 99, 0.23)"
  );
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const { currentArtwork, setCurrentArtwork } = useCurrentArtwork();

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const fetchArtworkAssets = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/artwork`
      );

      const fetchedArtworks = response.data.artworks;
      setArtworkAssets(fetchedArtworks);

      if (fetchedArtworks.length > 0) {
        const enhancedArtworksAsset = {
          ...fetchedArtworks[0],
          mutualSpaceId,
          boardId,
          tableId,
        };
        setCurrentArtwork(enhancedArtworksAsset);
      } else {
        closedetailsPanelSetting();
      }
      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Failed to fetch artwork assets",
        description:
          error.response?.data?.message ||
          "An error occurred while fetching artwork assets.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchArtworkAssets();
  }, [mutualSpaceId, boardId, tableId, toast, setCurrentArtwork]);

  const handleCheckboxChange = (artworkAsset, isChecked) => {
    setSelectedArtworkAssets((prevSelected) =>
      isChecked
        ? [...prevSelected, artworkAsset]
        : prevSelected.filter((asset) => asset._id !== artworkAsset._id)
    );
  };

  const handleBulkDelete = async () => {
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}api/mutualspace/${mutualSpaceId}/board/${boardId}/table/${tableId}/artworks/bulk-delete`,
        {
          data: {
            artworkAssetIds: selectedArtworkAssets.map((asset) => asset._id),
          },
        }
      );

      if (response.status === 200) {
        toast({
          title: "Artworks deleted successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
        setSelectedArtworkAssets([]);
        fetchArtworkAssets();
      }
    } catch (error) {
      toast({
        title: "Failed to delete lyric assets.",
        description: "Could not delete the selected lyrics. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      className={`MainBeatAssetTable-container ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Flex alignItems={"center"} w={"100%"} gap={"8px"}>
        <Flex w={"100%"} alignItems={"center"} gap={"8px"}>
          <Flex>
            <Tooltip placement="top" borderRadius={"7px"} label={"New Table"}>
              <Button
                isDisabled={!!guestToken}
                onClick={() => setIsUploadModalOpen(true)}
                size={"sm"}
              >
                <Flex alignItems={"center"} gap={"3px"}>
                  <Icon as={BsPlus} />
                  <Text>Artwork</Text>
                </Flex>
              </Button>
            </Tooltip>
          </Flex>
          <Flex w="100%">
            <InputGroup size="sm" w="100%">
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={BsSearch} color="gray.300" />}
              />
              <Input
                bg={inputBg}
                borderColor={borderColor}
                placeholder="Search..."
                sx={{
                  "::placeholder": {
                    fontSize: "12px",
                  },
                }}
                _hover={{
                  bg: hoverBg,
                  borderColor: "gray.400",
                }}
                borderRadius={"5px"}
                size={"sm"}
                w={"100%"}
              />
            </InputGroup>
          </Flex>
        </Flex>
      </Flex>
      {selectedArtworkAssets.length >= 2 && (
        <Flex className="mainbulkdelete">
          <Text>You've selected {selectedArtworkAssets.length} Artworks:</Text>
          <Flex className="listedassets" flexDirection="column" mt={2}>
            {selectedArtworkAssets.map((asset) => (
              <Text key={asset._id}>{asset.title}</Text>
            ))}
          </Flex>
          <Flex mt={2}>
            <Button onClick={() => setSelectedArtworkAssets([])}>Cancel</Button>
            <Button colorScheme="red" onClick={handleBulkDelete} ml={2}>
              Delete Artworks
            </Button>
          </Flex>
        </Flex>
      )}

      <Box
        className={`MainBeatAssetTable-surround ${
          colorMode === "light" ? "lightmode" : "darkmode"
        }`}
      >
        {isLoading ? (
          <Center mt={2}>
            <Spinner />
          </Center>
        ) : (
          <SimpleGrid mt={2} columns={{ base: 1, md: 4 }} spacing={4}>
            {artworkAssets.length > 0 ? (
              artworkAssets.map((artworkAsset) => (
                <Box key={artworkAsset._id}>
                  <MainArtwork
                    userName={userName}
                    userId={userId}
                    colorMode={colorMode}
                    artworkAsset={artworkAsset}
                    board={board}
                    boardId={boardId}
                    mutualSpaceId={mutualSpaceId}
                    tableId={tableId}
                    isSidebarCollapsed={isSidebarCollapsed}
                    guestToken={guestToken}
                    fetchArtworkAssets={fetchArtworkAssets}
                    openDetailsPanelSettings={openDetailsPanelSettings}
                    onCheckboxChange={handleCheckboxChange}
                  />
                </Box>
              ))
            ) : (
              <Text>No Artwork found.</Text>
            )}
          </SimpleGrid>
        )}
      </Box>
      <Modal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          p={1}
          border={
            colorMode === "light"
              ? "2px solid rgba(153, 178, 198, 0.23)"
              : "2px solid rgba(43, 46, 54, 1)"
          }
          bg={colorMode === "light" ? "white" : "rgba(5, 15, 30, 1)"}
        >
          <ModalCloseButton />
          <ModalBody>
            <MainArtworkUpload
              colorMode={colorMode}
              userId={userId}
              userName={userName}
              mutualSpaceId={mutualSpaceId}
              board={board}
              boardId={boardId}
              tableId={tableId}
              setIsUploadModalOpen={setIsUploadModalOpen}
              fetchArtworkAssets={fetchArtworkAssets}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MainArtworkAssetTable;
