import React from "react";
import "./EditingRelease.css";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

const EditingRelease = ({ release, colorMode }) => {
  return (
    <Box
      className={`EditingRelease-surround ${
        colorMode === "light" ? "lightmode" : "darkmode"
      }`}
    >
      <Text>{release._id}</Text>
      <Text>{release.releaseName}</Text>
      <Text>{release.releaseType}</Text>
      <Flex>
        {release.lyics ? (
          <>Lyrics</>
        ) : (
          <>
            <Button>Add Lyrics</Button>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default EditingRelease;
