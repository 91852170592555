import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Icon,
  Text,
  Tooltip,
  Center,
  IconButton,
  useToast,
  CircularProgress,
  CircularProgressLabel,
  useColorModeValue,
  Image,
  Tag,
  TagLabel,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import "./ReleaseDrawer.css";
import { BsImage } from "react-icons/bs";
import { TbWriting } from "react-icons/tb";
import ReleaseLyricsItem from "../../DetailsPanel/ReleasePanel/CurrentLinkedItems/ReleaseLyricsItem";
import ReleaseRecordingItem from "../../DetailsPanel/ReleasePanel/CurrentLinkedItems/ReleaseRecordingItem";

const ReleaseDrawer = ({ release, colorMode }) => {
  const artworkUrl =
    release.artwork && release.artwork.length > 0
      ? typeof release.artwork[0] === "string"
        ? release.artwork[0]
        : release.artwork[0].imageUrl
      : "";

  return (
    <Box>
      <Flex gap={"40px"} alignItems={"center"} w={"100%"}>
        <Flex gap={"5px"} w={"100%"} flexDirection={"column"}>
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={BsImage} />
            <Text className="slighttext">Artwork</Text>
          </Flex>
          <Flex className="release-drawer-artwork">
            {artworkUrl ? (
              <Image className="drawer-img" src={artworkUrl} />
            ) : (
              <Button
                h={"100%"}
                w={"100%"}
                onClick={() => {
                  /* Handle artwork upload action */
                }}
              >
                Upload Artwork
              </Button>
            )}
          </Flex>
        </Flex>

        <Flex gap={"5px"} w={"100%"} flexDirection={"column"}>
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={TbWriting} />
            <Text className="slighttext">Lyrics</Text>
          </Flex>
          <Flex className="release-drawer-lyrics">
            {release.lyrics && release.lyrics.length > 0 ? (
              <ReleaseLyricsItem colorMode={colorMode} release={release} />
            ) : (
              <Button
                h={"100%"}
                w={"100%"}
                onClick={() => {
                  /* Handle lyrics upload action */
                }}
              >
                Upload Lyrics
              </Button>
            )}{" "}
          </Flex>
        </Flex>
      </Flex>
      <Flex mt={5} w={"100%"}>
        <Flex gap={"5px"} w={"100%"} flexDirection={"column"}>
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon fontSize={"14px"} as={TbWriting} />
            <Text className="slighttext">Recordings</Text>
          </Flex>
          <Flex className="release-drawer-recording">
            {release.recordings && release.recordings.length > 0 ? (
              release.recordings.map((recording) => (
                <ReleaseRecordingItem
                  key={recording._id}
                  colorMode={colorMode}
                  recording={recording}
                />
              ))
            ) : (
              <Button
                h={"100%"}
                w={"100%"}
                onClick={() => {
                  /* Handle recording upload action */
                }}
              >
                Upload Recording
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ReleaseDrawer;
